import React, { Component } from "react";
import { Col, Row, Spin } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import WishlistCard from "../WishlistCard/WishlistCard";
import { cartActions } from "../../Cart/ducks";

class WishlistCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hits: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { hits } = this.props;
    if (hits && JSON.stringify(hits) !== JSON.stringify(prevProps.hits)) {
      this.setState({ hits: hits });
    }
  }

  wishlistDeleteItemHandler = (id) => {
    const { hits } = this.state;
    let prevHits = Object.assign([], hits);
    let index = prevHits.findIndex((product) => product.id === id);
    prevHits.splice(index, 1);
    this.setState({ hits: prevHits });
  };

  render() {
    const { removeWishlistItem } = this.props;
    const { hits } = this.state;
    return (
      <div className="">
        <Spin spinning={removeWishlistItem.pending}>
          {hits.length > 0 ? (
            hits.map((item, index) => (
              <Row>
                <Col key={index} span={24}>
                  <WishlistCard
                    // item={item}
                    wishlistDeleteItemHandler={this.wishlistDeleteItemHandler}
                    data={item}
                    key={item.objectID}
                  />
                </Col>
              </Row>
            ))
          ) : (
            <div style={{ textAlign: "center", padding: "65px" }}>
              <p>Look like you are not watching any item yet...</p>
            </div>
          )}
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    removeWishlistItem: state.Cart.removeWishlistItem,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WishlistCardList)
);
