import React from "react";

const WelComeCard = ({ sellerStatistics }) => {
  const user = localStorage.getItem("user");
  const userJson = JSON.parse(user);
  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h4 className="gx-mb-3">{`Welcome ${userJson && userJson.name}!`}</h4>
      <p className="gx-fs-sm gx-text-uppercase">You Have</p>
      <ul className="gx-list-group">
        <li>
          <i className="fad fa-comment-alt-dots mr-2"></i>
          <span>
            {sellerStatistics.loading ? 0 : sellerStatistics.data.message_count}{" "}
            Messages
          </span>
        </li>
        {/* <li>
          <i className="fad fa-bell mr-2"></i>
          <span>
            {sellerStatistics.loading
              ? 0
              : sellerStatistics.data.notification_count}{" "}
            Notifications
          </span>
        </li> */}
        {/* <li>
          <Icon type="profile" />
          <span>
            {sellerStatistics.loading
              ? 0
              : sellerStatistics.data.new_orders_count}{" "}
            new orders
          </span>
        </li>
        <li>
          <Icon type="bell" />
          <span>
            {sellerStatistics.loading
              ? 0
              : sellerStatistics.data.orders_in_transit_count}{" "}
            order in transit
          </span>
        </li>
        <li>
          <Icon type="bell" />
          <span>
            {sellerStatistics.loading
              ? 0
              : sellerStatistics.data.delivered_orders_count}{" "}
            delivered orders
          </span>
        </li>
        <li>
          <Icon type="bell" />
          <span>
            {sellerStatistics.loading
              ? 0
              : sellerStatistics.data.cancelled_orders_count}{" "}
            cancel orders
          </span>
        </li> */}
      </ul>
    </div>
  );
};

export default WelComeCard;
