import React, { Component } from "react";
import Widget from "components/Common/Widget/Widget";
import { Col, Row } from "antd";

class UserAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="gx-main-content-wrapper">
        <div>
          <Row>
            <Col xl={18} lg={18} md={24} sm={24} xs={24}>
              <Widget
              // title={<IntlMessages id="sidebar.documents.installation" />}
              >
                <h1 style={{ textAlign: "center", marginTop: "10px" }}>
                  Company Information and Policy
                </h1>
                <div
                  style={{ margin: "10px" }}
                  className="adocs-section-content ng-binding"
                >
                  <h3>Introduction</h3>
                  <p>
                    This User Agreement, the Tradesbarn User Privacy Notice, the
                    Mobile Device Terms and all policies posted on our sites set
                    out the terms on which Tradesbarn offers you access to and
                    use of our sites, services, applications and tools
                    (collectively ‘Services’). You can find an overview of our
                    policies here. All policies, the Mobile Device Terms and the
                    trade’s barn User Privacy Notice are incorporated into this
                    User Agreement. You agree to comply with all the above when
                    accessing or using our Services. If you reside In USA Or
                    Internationally, you are entering into a contract
                    withTradesbarn LLC 3660 E New York Street #1512 Aurora
                    Illinois 60504.
                  </p>
                  {/* <p>Following tools are needed to setup a react dev environment:</p> */}

                  {/* <ul>
              <li>
                <strong>Node JS</strong>
                <br />
                <p>
                  NodeJS works as a node package manager behind the scene. It is
                  recommended to download and install the latest version of Node
                  JS from its official site
                </p>
              </li>
              <li>
                <strong>Yarn</strong>
                <br />
                <p>
                  Download the latest version of the most loved dependency
                  management tool for react applications from its official site{" "}
                  <a href="https://yarnpkg.com">https://yarnpkg.com</a> and
                  install it on your machine.
                </p>
              </li>
            </ul> */}

                  <br />
                  <br />
                  <h3>About Tradesbarn</h3>
                  <p>
                    Tradesbarn LLC Registered in United States of America Baring
                    the Registration Number 1821801101 Tradesbarn is a
                    marketplace that allows users to offer, sell and buy just
                    about anything in a variety of pricing formats and
                    locations. B2B, B2C & Services Tradesbarn does not have
                    possession of anything listed or sold through trade’s barn,
                    and is not involved in the actual transaction between buyers
                    and sellers. The contract for the sale is directly between
                    buyer and seller. Tradesbarn is not a traditional
                    auctioneer. While we may provide pricing, postage, drafting
                    and other guidance in our Services, such guidance is solely
                    informational and you may decide to follow it or not.
                    Tradesbarn does not review users' listings or content. While
                    we may help facilitate the resolution of disputes through
                    various programs, tradesbarn has no control over and does
                    not guarantee the existence, quality, safety or legality of
                    items advertised; the truth or accuracy of users’ content,
                    listings or feedback; the ability of sellers to sell items;
                    the ability of buyers to pay for items; or that a buyer or
                    seller will actually complete a transaction or return an
                    item. Under the trade’s barn Money Back Guarantee, if we
                    resolve a case in the buyer's favor, we may:
                  </p>

                  <ol type="i">
                    {/* <li>
                <p>
                  Install node modules use{" "}
                  <span className="gx-bg-grey gx-py-1 gx-px-2 gx-rounded-base">
                    $ yarn
                  </span>
                  command
                </p>
              </li> */}
                    <li>
                      <p>
                        remove funds from the seller's Transaction Account Or
                        Credit Card Linked account to reimburse the buyer for
                        the cost of the item and the original postage cost in
                        accordance with the seller's authorization provided to
                        us under this User Agreement; or
                      </p>
                    </li>
                    <li>
                      <p>
                        Where there are insufficient funds in the seller's
                        Transaction Account or Credit Card Linked account,
                        directly refund the buyer for the cost of the item and
                        the original postage cost, and, in this case, the seller
                        must reimburse us in full, and in a timely manner, for
                        an amount equivalent to the sum we paid to the buyer.
                      </p>
                    </li>
                  </ol>
                  <p>
                    Sellers must have a payment method on file with tradesbarn
                    at all times. Sellers may change this payment method by
                    contacting tradesbarn. Where the seller is found at fault,
                    we will notify the seller and charge the method of payment
                    that the seller has on file.
                  </p>
                  <br />
                  <br />
                  <h3>Using Tradesbarn</h3>
                  <p>
                    In connection with using or accessing the Services you will
                    not:
                  </p>
                  <ul>
                    <li>
                      <p>
                        post, list or upload content or items in inappropriate
                        categories or areas on our sites
                      </p>
                    </li>
                    <li>
                      <p>
                        breach or circumvent any laws, third party rights or our
                        systems or policies
                      </p>
                    </li>
                    <li>
                      <p>
                        sell any counterfeit items or otherwise infringe the
                        copyright, trademark or other rights of third parties
                      </p>
                    </li>
                    <li>
                      <p>
                        use our Services if you are not able to form legally
                        binding contracts (for example if you are under 18), or
                        are temporarily or indefinitely suspended from using our
                        Services
                      </p>
                    </li>
                    <li>
                      <p>
                        fail to pay for items purchased by you, unless you have
                        a valid reason as set out in an Tradesbarn policy the
                        seller has materially changed the item's description
                        after you bid, a clear typographical error is made, or
                        you cannot contact the seller (see our unpaid item
                        policy)
                      </p>
                    </li>
                    <li>
                      <p>
                        fail to deliver items sold by you, unless you have a
                        valid reason as set out in an Tradesbarn policy, for
                        example the buyer fails to follow the posted terms, or
                        you cannot contact the buyer;
                      </p>
                    </li>
                    <li>
                      <p>
                        manipulate the price of any item or interfere with any
                        other user's listings
                      </p>
                    </li>
                    <li>
                      <p>
                        distribute or post spam, unsolicited or bulk electronic
                        communications, chain letters, or pyramid schemes;
                      </p>
                    </li>
                    <li>
                      <p>
                        post false, inaccurate, misleading, defamatory, or
                        libelous content;
                      </p>
                    </li>
                    <li>
                      <p>
                        take any action that may undermine the Feedback or
                        ratings systems (please see our rules about Feedback);
                      </p>
                    </li>
                    <li>
                      <p>
                        transfer your Tradesbarn account (including Feedback)
                        and user ID to another party without our consent;
                      </p>
                    </li>
                    <li>
                      <p>
                        distribute or post spam, unsolicited or bulk electronic
                        communications, chain letters, or pyramid schemes;
                      </p>
                    </li>
                    <li>
                      <p>
                        distribute viruses or any other technologies that may
                        harm Tradesbarn, or the interests or property of trade’s
                        barn users;
                      </p>
                    </li>
                    <li>
                      <p>
                        bypass our robot exclusion headers, interfere with the
                        working of our Services, or impose an unreasonable or
                        disproportionately large load on our infrastructure;
                      </p>
                    </li>
                    <li>
                      <p>
                        Use any robot, spider, scraper or other automated means
                        to access our Services for any purpose.
                      </p>
                    </li>
                    <li>
                      <p>
                        export or re-export any Tradesbarn application or tools
                        except in compliance with the export control laws of any
                        relevant jurisdictions and in accordance with posted
                        rules and restrictions;
                      </p>
                    </li>
                    <li>
                      <p>
                        copy, modify, or distribute rights or content from our
                        Services or Tradesbarn’s copyrights and trademarks;
                      </p>
                    </li>
                    <li>
                      <p>
                        copy, reproduce, reverse engineer, modify, create
                        derivative works from, distribute, or publicly display
                        any content (except for your information) from our
                        Services without the prior express written permission of
                        Tradesbarn and the appropriate third party, as
                        applicable;
                      </p>
                    </li>
                    <li>
                      <p>
                        commercialize any Tradesbarn application or any
                        information or software associated with such
                        application;
                      </p>
                    </li>

                    <li>
                      <p>
                        harvest or otherwise collect information about users,
                        such as email addresses, without their consent; or
                      </p>
                    </li>
                    <li>
                      <p>
                        Circumvent any technical measures we use to provide the
                        Services.
                      </p>
                    </li>
                  </ul>
                  <p>
                    If you are registering with trade’s barn as a business
                    entity, you represent that you have the authority to legally
                    bind that entity. If you are trading as a business on
                    Tradesbarn, you must comply with all applicable laws
                    relating to online trading for the site you are selling on
                    (please see Listings, Items and the Law for more information
                    on the legal requirements for selling You agree that we will
                    commence supplying our services to you as soon as you accept
                    this User Agreement. You can cancel this User Agreement
                    under the Consumer Contracts (Information, Cancellation and
                    Additional Charges) Regulations. We may recoup the cost of
                    any services provided up to the point of cancellation. We
                    may cancel unconfirmed accounts or accounts that have been
                    inactive for a long time or to modify or discontinue
                    Tradesbarn Services.
                  </p>
                  <br />
                  <br />
                  <h3>Abusing trade’s barn</h3>
                  <p>
                    Without limiting other remedies, we may limit, suspend, or
                    terminate our Services and user accounts, restrict or
                    prohibit access to, and your activities on, our Services,
                    cancel bids and remove listings, delay or remove hosted
                    content, remove any special status associated with the
                    account, reduce or eliminate any discounts, and take
                    technical and legal steps to keep you from using our
                    Services if:
                  </p>
                  <ul>
                    <li>
                      <p>
                        We think that you are creating problems or possible
                        legal liabilities;
                      </p>
                    </li>
                    <li>
                      <p>
                        We think that such restrictions will improve the
                        security of the Tradesbarn community or reduce our or
                        another Tradesbarn user's exposure to financial
                        liabilities;
                      </p>
                    </li>
                    <li>
                      <p>
                        We think that you are infringing the rights of third
                        parties;
                      </p>
                    </li>
                    <li>
                      <p>
                        we think that you are acting inconsistently with the
                        letter or spirit of this User Agreement or our policies
                        or abuse our employees or users;
                      </p>
                    </li>
                    <li>
                      <p>
                        Despite our reasonable endeavors, we are unable to
                        verify or authenticate any information you provide to
                        us; or
                      </p>
                    </li>
                    <li>
                      <p>
                        You fail to make full payment of any fees due for our
                        Services by your payment due date.
                      </p>
                    </li>
                  </ul>

                  <p>
                    When a buyer or seller issue arises we may consider the
                    user's performance history and the specific circumstances in
                    applying our policies. We may choose to be more lenient with
                    policy enforcement, and to do the right thing for both
                    buyers and sellers.
                  </p>
                  <br />
                  <br />
                  <h3>Fees</h3>
                  <p>
                    The fees we charge for using our Services are listed on our
                    Seller fees page. We may change our fees, or introduce new
                    fees from time to time by posting the changes on the trade’s
                    barn site or via the Messages section of my trade’s barn 30
                    days in advance. You may close your account without penalty
                    within 30 days of such notice being given.
                  </p>

                  <p>
                    You are required to have a payment method on file when
                    selling on trade’s barn. If your payment method fails or
                    your account is overdue, we may collect fees owed by
                    charging other payment methods on file, retaining collection
                    agencies or legal counsel, and for accounts over 180 days
                    overdue, deducting the amount owed from your Transaction
                    Account or Credit Card Linked account balance.
                  </p>
                  <br />
                  <br />
                  <h3>Listing Conditions</h3>
                  <p>
                    When listing an item, you agree to comply with the rules for
                    listing and selling practices policy and that:
                  </p>
                  <ul>
                    <li>
                      <p>
                        You are responsible for the accuracy, content and
                        legality of the item listed and agree to list in
                        accordance with the prohibited and restricted Items
                        policy;
                      </p>
                    </li>
                    <li>
                      <p>
                        Your listing may not be immediately searchable by
                        keyword or category for up to 24 hours. Tradesbarn does
                        not guarantee exact listing durations;
                      </p>
                    </li>
                    <li>
                      <p>
                        Content that violates any of Tradesbarn’s policies may
                        be deleted at Tradesbarn’s discretion;
                      </p>
                    </li>
                    <li>
                      <p>
                        Where your listing appears in search and browse results
                        may be based on certain factors such as listing format,
                        title, bidding activity, end time, keywords, price and
                        postage cost, Feedback, and detailed seller ratings.
                        Learn more about finding an item you listed for sale;
                      </p>
                    </li>
                    <li>
                      <p>
                        Tradesbarn’s duplicate listing policy may also affect
                        whether your listing appears in search results;
                      </p>
                    </li>
                    <li>
                      <p>
                        some optional upgrade features as 3D Item Viewing will
                        only be visible on certain Tradesbarn Services.
                      </p>
                    </li>
                  </ul>
                  <br />
                  <br />
                  <h3>Purchase Conditions</h3>
                  <ul>
                    <li>
                      <p>
                        When buying an item, you agree to comply with the rules
                        for buyers and that:
                      </p>
                    </li>
                    <li>
                      <p>
                        You are responsible for reading the full item listing
                        before making a commitment to buy;
                      </p>
                    </li>
                    <li>
                      <p>
                        You enter into a legally binding contract to purchase an
                        item when you commit to buy an item
                      </p>
                    </li>
                    <li>
                      <p>
                        for motor vehicles Real Estate or Bulk Buying Or Selling
                        listed, an offer is not binding, but expresses a buyer's
                        serious interest in the item;
                      </p>
                    </li>
                    <li>
                      <p>
                        For Professional Service Listed User Agreement Binds
                        solely with respect to that particular Service.
                      </p>
                    </li>
                    <li>
                      <p>International buying, selling and translation</p>
                    </li>
                  </ul>
                  <p>
                    Sellers and buyers are responsible for complying with all
                    laws and regulations applicable to international sales,
                    purchases, and postage of items.
                  </p>
                  <p>
                    Your items may be listed on one or more of Tradesbarn’s
                    sites, you may stop your listings from appearing on
                    international sites by excluding international postage
                    locations from your listings. By selecting international
                    postage you authorize Tradesbarn, in its discretion, to
                    display your listings on trade’s barn sites other than the
                    original listing site but acknowledge that your listings may
                    not appear on some or all of these sites. When you sell your
                    items internationally, you agree to be subject to that other
                    site’s policies, and you will be required to accept
                    Tradesbarn’s International Selling Agreement.
                  </p>
                  <p>
                    If you are a seller you authorize us to use automated tools
                    to translate your trade’s barn content, in whole or in part,
                    into local languages where such translation solutions are
                    available. If you are a buyer, you may have access to
                    tool(s) which will enable you to translate content at your
                    request. The accuracy or availability of any translation is
                    not guaranteed and you should check your translated listing
                    for accuracy.
                  </p>
                  <br />
                  <br />
                  <h3>Content</h3>
                  <p>
                    When providing us with content, you grant us a
                    non-exclusive, worldwide, perpetual (or for the duration of
                    any copyright or other rights in such content), irrevocable,
                    royalty-free, sub licensable (through multiple tiers) right
                    to use the content and authorize us to exercise any and all
                    copyright, trademark, publicity, database or other rights
                    you have in or to the content in any media known now or in
                    the future. Further, to the fullest extent permitted under
                    applicable law, you waive your moral rights in the content
                    and promise not to assert such rights against us, our
                    sublicenses or assignees. We may offer license or otherwise
                    make available for your use catalogues of stock images,
                    descriptions and product specifications, which are provided
                    by third-parties (including trade’s barn users). You may use
                    such catalogue content solely in connection with your
                    trade’s barn listings during the time your listings are on
                    Tradesbarn’s sites.
                  </p>
                  <p>
                    While we try to offer reliable data, we cannot promise that
                    catalogue content will always be accurate and up-to-date,
                    and you agree that you will not hold us our catalogue
                    providers responsible for inaccuracies in their catalogues.
                    If you choose to include catalogue content in your listings,
                    you continue to be responsible for ensuring that your
                    listings are accurate, do not include misleading information
                    and fully comply with this User Agreement and all Tradesbarn
                    policies. The catalogue may include copyrighted, trademarked
                    or other proprietary materials. You agree not to remove any
                    copyright, proprietary or identification markings included
                    within the catalogues or create any derivative works bases
                    on catalogue content (other than by including them in your
                    listings).
                  </p>
                  <br />
                  <br />
                  <h3>Restricting Funds</h3>
                  <p>
                    To protect against the risk of liability, Tradesbarn has at
                    times requested, and may continue to request, that
                    Transaction Account Or Credit Card Linked restrict access to
                    funds in a seller's Transaction Account Or Credit Card
                    Linked account based on certain factors, including, but not
                    limited to, selling history, seller performance, riskiness
                    of the listing category, or the filing of a case. This may
                    result in Transaction Account or Credit Card Linked
                    determining to restrict funds in your Transaction Account or
                    Credit Card Linked account in order to manage Transaction
                    Account or Credit Card Linked risk exposure pursuant to
                    Transaction Account or Credit Card Linked policies.
                  </p>
                  <br />
                  <br />
                  <h3>Additional Terms</h3>
                  <br />
                  <h4>Returns</h4>
                  <p>
                    Tradesbarn offers a managed returns process to simplify
                    returns for buyers and sellers.
                  </p>
                  <p>
                    With the Tradesbarn managed returns process, a seller
                    accepting returns can manage and track the return through an
                    automated process on the site. If the buyer returns an item
                    because it does not match the listing description, sellers
                    will usually be responsible for return postage costs.
                  </p>
                  <p>
                    Tradesbarn may automatically apply the Tradesbarn managed
                    returns process to certain categories of sellers and require
                    those sellers to use the process to manage their returns. If
                    the trade’s barn managed returns process is automatically
                    applied to your listings, you:
                  </p>
                  <ul>
                    <li>
                      <p>
                        agree to the trade’s barn managed returns process terms
                        and conditions;
                      </p>
                    </li>
                    <li>
                      <p>
                        You are responsible for reading the full item listing
                        before making a commitment to buy;
                      </p>
                    </li>
                    <li>
                      <p>
                        authorize Tradesbarn to include return postage charges
                        and auto-refunds on your seller invoice as a fee, which
                        may be charged to your automatic payment method on file,
                        if any; and
                      </p>
                    </li>
                    <li>
                      <p>
                        Acknowledge that the trade’s barn Money Back Guarantee
                        has special provisions to accommodate a case if an item
                        is successfully returned through Tradesbarns managed
                        return process and a timely refund isn’t issued.
                      </p>
                    </li>
                  </ul>
                  <p>See using the managed returns process for more details.</p>
                  <br />
                  <br />
                  <h4>Tradesbarn Guarantee</h4>
                  <p>
                    Most trade’s barn sales go smoothly, but if there’s a
                    problem with a purchase, the trade’s barn Guarantee helps
                    buyers and sellers communicate and resolve issues. The
                    trade’s barn Guarantee policy is part of this User Agreement
                    and incorporated by reference. Buyers and sellers agree to
                    comply with the policy and permit us to make a final
                    decision on any case.
                  </p>
                  <p>
                    Sellers must have a payment method on file with trade’s
                    barn. Sellers may change this payment method by contacting
                    Tradesbarn. If we resolve a case in the buyer's favor,
                    Tradesbarn may notify Transaction Account or Credit Card
                    Linked of the relevant case and remove funds from the
                    seller's Transaction Account or Credit Card Linked account
                    to reimburse the buyer for the full cost of the item, and
                    postage. Where there are insufficient funds in the seller's
                    Transaction Account or Credit Card Linked account, we will
                    directly refund the buyer and charge the seller's designated
                    payment method or put the amount on the seller’s invoice.
                  </p>
                  <p>For this purpose, you, in the capacity of a seller:</p>
                  <ul>
                    <li>
                      <p>
                        authorize and instruct us and Transaction Account Or
                        Credit Card Linked to collect or reverse variable
                        amounts (representing payments related to covered
                        claims) from your Transaction Account Or Credit Card
                        Linked account to carry out a buyer reimbursement;
                      </p>
                    </li>
                    <li>
                      <p>
                        Authorize and instruct us to request Transaction Account
                        Or Credit Card Linked to collect or reverse variable
                        amounts in other currency holdings (representing
                        payments related to eligible claims) from your
                        Transaction Account Or Credit Card Linked account to
                        carry out a buyer reimbursement where you do not have
                        sufficient funds available in the transaction currency;
                      </p>
                    </li>
                    <li>
                      <p>
                        Authorize and instruct us to request Transaction Account
                        or Credit Card Linked to restrict your access to funds
                        (the restriction will apply up to a sum equivalent to
                        the cost of the item and original postage costs paid by
                        the buyer) in your Transaction Account or Credit Card
                        Linked account at any point during the resolution
                        process. (This may result in Transaction Account or
                        Credit Card Linked determining to restrict funds in your
                        Transaction Account or Credit Card Linked account in
                        order to manage Transaction Account or Credit Card
                        Linker’s risk exposure pursuant to Transaction Account
                        or Credit Card Linked policies. See restricting funds.);
                      </p>
                    </li>
                    <li>
                      <p>
                        authorize and instruct us to charge the payment method
                        you select to recover the amount we pay to the buyer (in
                        cases where we refund the buyer directly); and
                      </p>
                    </li>
                    <li>
                      <p>
                        Authorize and instruct us to place the reimbursement
                        amount on the seller invoice, including but not limited
                        to the cost of return postage labels.
                      </p>
                    </li>
                  </ul>
                  <p>
                    You acknowledge and agree that your authorizations above
                    will be made on a recurring basis and on various dates as
                    required by us to implement the terms of the Tradesbarn,
                    guaranteepolicy. For future claims, where we resolve a
                    dispute in the buyer's favor, we will notify the seller and
                    continue to charge the seller's preferred method of payment.
                    If sellers do not provide Tradesbarn with a valid
                    reimbursement method, we may collect the outstanding sums
                    using other collection mechanisms, including retaining
                    collection agencies. We may suspend the trade’s barn
                    Guarantee policy without notice if we suspect abuse or
                    interference with the proper working of the policy.
                  </p>
                  <br />
                  <br />
                  <h4>Correcting mistakes in payments to buyers and sellers</h4>
                  <p>
                    We reserve the right to fix any processing errors we
                    discover. We will correct any processing errors by debiting
                    or crediting the payment method used for the Tradesbarn
                    Guarantee refund or reimbursement.
                  </p>

                  <br />
                  <br />
                  <h4>Delivery options</h4>
                  <p>
                    We may add certain delivery options to your listings, in
                    order to help improve your Global Buying and Selling
                    experience. For items that are eligible, we may choose to
                    make Tradesbarn Click & Collect available as a delivery
                    option on your listings on tradesbarn.com unless you opt out
                    at either an account or listing level.
                  </p>
                  <p>
                    By not opting out, you acknowledge that you have read and
                    agree and that these terms will apply to the relevant
                    listings. Those terms include, among other important
                    provisions:
                  </p>

                  <br />
                  <br />
                  <h4>Liability</h4>
                  <p>
                    We try to keep Tradesbarn and its Services safe, secure, and
                    functioning properly, but we cannot guarantee the continuous
                    operation of or access to our Services. Updates and other
                    notification functionality in Tradesbarn’s Services may not
                    occur in real time. Such functionality is subject to delays
                    beyond Tradesbarn’s control.
                  </p>
                  <p>
                    We (including our parent, subsidiaries, affiliates,
                    directors, agents and employees) shall not be liable to you
                    in contract, tort (including negligence) or otherwise for
                    any business losses, such as loss of data, profits, revenue,
                    business, opportunity, goodwill, reputation or business
                    interruption or for any losses which are not reasonably
                    foreseeable by us arising, directly or indirectly from:
                  </p>
                  <ul>
                    <li>
                      <p>Your use of or your inability to use our Services;</p>
                    </li>
                    <li>
                      <p>
                        Pricing, postage or other guidance provided by trade’s
                        barn;
                      </p>
                    </li>
                    <li>
                      <p>Delays or disruptions in our Services;</p>
                    </li>
                    <li>
                      <p>
                        Viruses or other malicious software obtained by
                        accessing, or linking to, our Services;
                      </p>
                    </li>
                    <li>
                      <p>
                        Glitches, bugs, errors, or inaccuracies of any kind in
                        our Services;
                      </p>
                    </li>
                    <li>
                      <p>
                        Damage to your hardware device from the use of any
                        Tradesbarn Service;
                      </p>
                    </li>
                    <li>
                      <p>
                        The content, actions, or inactions of third parties,
                        including items listed using our Services or the
                        destruction of allegedly fake items;
                      </p>
                    </li>
                    <li>
                      <p>
                        A suspension or other action taken with respect to your
                        account or breach of the Abusing Tradesbarn section;
                      </p>
                    </li>
                    <li>
                      <p>
                        The duration or manner in which your listings appear in
                        search results as set out in the Listing conditions
                        section; or
                      </p>
                    </li>
                    <li>
                      <p>
                        Your need to modify practices, content, or behavior or
                        your loss of or inability to do business, as a result of
                        changes to this User Agreement or our policies.
                      </p>
                    </li>
                  </ul>
                  <p>
                    Some jurisdictions do not allow the disclaimer of warranties
                    or exclusion of damages, so such disclaimers and exclusions
                    may not apply to you. This is especially so if you are a
                    consumer.You accept sole responsibility for the legality of
                    your actions under laws applying to you and the legality of
                    any items you list on any of our sites.
                  </p>
                  <p>
                    Although we use techniques that aim to verify the accuracy
                    and truth of the information provided by our users, user
                    verification on the internet is difficult. Tradesbarn cannot
                    and does not confirm, and is not responsible for ensuring,
                    the accuracy or truthfulness of users' purported identities
                    or the validity of the information which they provide to us
                    or post on our sites. Regardless of the previous paragraphs,
                    if we are found to be liable, our liability to you or to any
                    third party is limited to the greater of
                  </p>
                  <ol type="i">
                    <li>
                      <p>
                        Any amounts due under trade’s barn Money Back Guarantee
                        up to the price the item sold for on Tradesbarn and its
                        original postage costs,
                      </p>
                    </li>
                    <li>
                      <p>
                        the amount of fees in dispute not to exceed the total
                        fees which you paid to us in the 12 months prior to the
                        action giving rise to the liability, or
                      </p>
                    </li>
                    <li>
                      <p>USD 200.</p>
                    </li>
                  </ol>
                  <p>
                    Nothing in this User Agreement shall limit or exclude our
                    liability for fraudulent misrepresentation, for death or
                    personal injury resulting from our negligence or the
                    negligence of our agents or employees or for any other
                    liability that cannot be limited or excluded by law.
                  </p>
                  <br />
                  <br />
                  <h4>Compensation</h4>
                  <p>
                    You will compensate us in full (and our officers, directors,
                    agents, subsidiaries, joint ventures and employees) for any
                    losses or costs, including reasonable legal fees, we incur
                    arising out of any breach by you of this User Agreement,
                    your improper use of Tradesbarn’s Services or your breach of
                    any law or the rights of a third party.
                  </p>
                  <br />
                  <br />
                  <h4>Legal Disputes</h4>
                  <p>
                    If a dispute arises between you and Tradesbarn, we strongly
                    encourage you to first contact us directly to seek a
                    resolution by contacting Customer Support, Technical Issues
                    You Will Be Directed To Our Developers For Suitable Solution
                    We will consider reasonable requests to resolve the dispute
                    through alternative dispute resolution procedures, such as
                    mediation or arbitration, as alternatives to litigation. Any
                    claim, dispute or matter arising under or in connection with
                    this User Agreement shall be governed and construed in all
                    respects by the laws of Sri Lanka. You and Tradesbarn both
                    agree to submit to the non-exclusive jurisdiction of the Sri
                    Lankan Courts.
                  </p>
                  <p>
                    In simple terms, "non-exclusive jurisdiction of the Sri
                    Lankan courts" means that if you were able to bring a claim
                    arising from or in connection with this User Agreement
                    against us in Court, an acceptable court would be a court
                    located in Sri Lanka, but you may also elect to bring a
                    claim in the court of another country instead. Sri Lanka law
                    will apply in all cases.
                  </p>
                  <br />
                  <br />
                  <h4>General</h4>
                  <p>
                    If any provision of this User Agreement is held to be
                    invalid, void or for any reason unenforceable, such
                    provision shall be struck out and shall not affect the
                    validity and enforceability of the remaining provisions
                  </p>
                  <p>
                    We may assign our rights and obligations under this User
                    Agreement in accordance with the below (but without your
                    prior express consent), provided that we assign the User
                    Agreement on the same terms or terms that are no less
                    advantageous to you.
                  </p>
                  <p>
                    Headings are for reference purposes only and do not limit
                    the scope or extent of such section. Our failure to act with
                    respect to a breach by you or others does not waive our
                    right to act with respect to subsequent or similar breaches.
                    We do not guarantee we will take action against all breaches
                    of this User Agreement.
                  </p>
                  <p>
                    If you have a dispute with one or more users, you release us
                    (and our affiliates and subsidiaries, and our and their
                    respective officers, directors, employees and agents) from
                    claims, demands and damages (actual and consequential) of
                    every kind and nature, known and unknown, arising out of or
                    in any way connected with such disputes.
                  </p>
                  <p>
                    We may amend this User Agreement at any time by email, or
                    via the Messages section of my trade’s barn or by posting
                    the amended terms on www.tradesbarn.com all amended terms
                    shall automatically be effective 30 days after they are
                    initially posted. Your continued use of our Services after
                    the effective date of these amended terms constitutes your
                    acceptance of them.
                  </p>
                  <p>
                    This User Agreement may not be otherwise amended except
                    through mutual agreement by you and a trade’s barn
                    representative authorized to do so.
                  </p>
                  <p>
                    The policies posted on our sites may be changed from time to
                    time. Changes take effect when we post them on the trade’s
                    barn site.
                  </p>
                  <p>
                    No agency, partnership, joint venture, employee-employer or
                    franchiser-franchisee relationship is intended or created by
                    this User Agreement. A person who is not a party to this
                    User Agreement has no right under the Contracts (Rights of
                    Third Parties) to enforce any term of this User Agreement
                    but this does not affect any right or remedy of a third
                    party specified in this User Agreement or which exists or is
                    available apart from that Act.
                  </p>
                  <p>
                    Tradesbarn’s Copyright Owner to ensure that listed items do
                    not infringe upon the copyright, trademark or other
                    intellectual property rights of third parties. If you
                    believe that your intellectual property rights have been
                    infringed, please notify our team through our Copyrights
                    Owner Programmed and we will investigate.
                  </p>
                  <p>
                    The User Agreement, the Tradesbarn User Privacy Notice and
                    all polices posted on our site are the entire agreement
                    between you and Tradesbarn and supersede all prior
                    understandings and agreements of the parties.
                  </p>
                  <p>
                    The following Sections survive any termination of this User
                    Agreement: Fees (with respect to fees owed for our
                    services), Content, Liability, Compensation, Legal disputes
                    and the release contained in this General section. Legal
                    notices shall be served by registered mail to Tradesbarn
                    Colombo Sri Lanka. We shall send notices to you by email to
                    the email address you provide to Tradesbarn during the
                    registration process. Notice to you shall be deemed given 24
                    hours after the email is sent, unless the sending party is
                    notified that the email address is invalid. Alternatively,
                    we may give you legal notice by registered mail to the
                    address provided during the registration process. Notices
                    sent to either party by registered mail shall be deemed to
                    have been received by that party three days after the date
                    of mailing.
                  </p>
                </div>
              </Widget>
            </Col>
            <Col xl={6} lg={6} md={12} sm={24} xs={24}>
              <h2>content</h2>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default UserAgreement;
