import React, { Component } from "react";
import CardBox from "../../../components/Buyer/Card/CardBox/CardBox";
import { Col, Row } from "antd";

class PromotionCardSingle extends Component {
  render() {
    const { imageName = "full_row_image_1.jpg" } = this.props;
    return (
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <CardBox>
            {process.env.REACT_APP_IS_ENABLED_PROMOTION_MODULE ===
              "ENABLED" && (
              <img
                className="d-block mx-auto"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/promotions/${imageName}`)}
                alt="Moto 360"
              />
            )}
          </CardBox>
        </Col>
      </Row>
    );
  }
}

export default PromotionCardSingle;
