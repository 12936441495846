// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getProduct: createAction(types.GET_PRODUCT),
  getProductSuccess: createAction(types.GET_PRODUCT_SUCCESS),
  getProductFail: createAction(types.GET_PRODUCT_FAILED),
  getSelectProductDetail: createAction(types.SELECT_PRODUCT_DETAIL),
};
