import React from "react";
import { Card } from "antd";

const AccountHeader = ({ currentUser }) => {
  return (
    <Card className="gx-card" style={{ backgroundColor: "rgb(250, 250, 250)" }}>
      <h5 className="mb-3 ml-2">My Personal Information</h5>

      <div className="gx-card p-3 mb-0 shww-in-mob">
        <div className="pb-0">
          <div className="" style={{ textAlign: "center" }}>
            <img
              alt="avatar"
              // src="https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000091554878046%2F0-small.jpg?alt=media"
              className="gx-avatar-img gx-avatar-img-lg gx-border-1 mb-0"
              src={currentUser && currentUser.photoURL}
              onError={(e) => {
                e.target.onerror = null;
                // e.target.src = "https://placeimg.com/200/300/animals";
                e.target.src = require("assets/images/avatar/temp_avatar.png");
              }}
            />
          </div>
          <div className="" style={{ textAlign: "center" }}>
            <div className=" pt-3">
              <h3>{currentUser && currentUser.displayName}</h3>
              <div>
                Email Verification :&nbsp;
                <span className="fnshed-clr">
                  {currentUser && currentUser.emailVerified ? "Verified" : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gx-card p-3 mb-0 shww-in-web">
        <div className="gx-user-list pb-0">
          <img
            alt="avatar"
            // src="https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000091554878046%2F0-small.jpg?alt=media"
            className="gx-avatar-img gx-avatar-img-lg gx-border-1 mb-0"
            src={
              currentUser && currentUser.photoURL
                ? currentUser.photoURL
                : require("assets/images/avatar/temp_avatar.png")
            }
            onError={(e) => {
              e.target.onerror = null;
              // e.target.src = "https://placeimg.com/200/300/animals";
              e.target.src = require("assets/images/avatar/temp_avatar.png");
            }}
          />
          <div className="gx-description pt-3">
            <h3>{currentUser && currentUser.displayName}</h3>
            <div>
              Email Verification :&nbsp;
              <span className="fnshed-clr">
                {currentUser && currentUser.emailVerified ? "Verified" : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AccountHeader;
