import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import NavBar from "./CategaryDatail";
import "./MainNavBar.less";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { homeActions } from "./../../modules/Home/ducks";
import { MainNavbarCategories } from "../../DummyData/CategoryData";

class MainNavBar extends Component {
  render() {
    const path = this.props.match.url;
    const all_categories = this.props.allCategories;
    return (
      <div className="site-header navbar-bckg nav-sticky-pstn tr-main-menu">
        <nav className="site-menu background selecter-jr">
          <ul>
            <li className={`has-submenu ${path === "/" ? "active" : null}`}>
              <Link to="/">Home</Link>
            </li>
            {all_categories.loading
              ? MainNavbarCategories.map((data, index) => (
                  <NavBar key={index} data={data} path={path} />
                ))
              : all_categories?.data.map((data, index) => (
                  <NavBar key={index} data={data} path={path} />
                ))}
          </ul>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allCategories: state.Home.allCategories,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    homeActions: bindActionCreators(homeActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainNavBar)
);
