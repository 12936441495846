import React, { Component } from "react";
import { Card, Button, Drawer } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import ChatUserList from "../../components/chat/ChatUserList";
import conversationList from "./data/conversationList";
import Conversation from "../../components/chat/Conversation/index";
import users from "./data/chatUsers";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "../../components/CircularProgress/index";
import {
  GetConversation,
  GetConversationList,
  StopListner,
  SendMesage,
} from "../../services/message.service";
import singleProductActions from "../SingleProduct/ducks";
import ChatHeader from "./ChatHeader/ChatHeader";
import SendMessageBox from "./SendMessageBox/SendMessageBox";
import ChatUserInfo from "./ChatUserInfo/ChatUserInfo";
import "./ChatPage.less";

class ChatPage extends Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      userNotFound: "No user found",
      drawerState: false,
      selectedSectionId: "",
      selectedTabIndex: 1,
      searchChatUser: "",
      contactList: users.filter((user) => !user.recent),
      selectedUser: null,
      message: "",
      chatUsers: users.filter((user) => user.recent),
      conversationList: conversationList,
      conversation: null,
      currentUser: null,
      conversation_list: [],
    };
  }

  componentDidMount() {
    const currentUser = localStorage.getItem("user");
    const currentUserObj = JSON.parse(currentUser);
    this.GetConversationListHandler();
    this.setState({ currentUser: currentUserObj });
  }

  componentWillUnmount() {
    const { selectedSectionId } = this.state;

    //Need to call when Unmounting.
    StopListner("conversation", selectedSectionId);
  }

  GetConversationListHandler = async () => {
    // debugger;
    const { match } = this.props;
    const { userID } = match && match.params;
    /**
     * Get Conversations List
     */

    if (userID) {
      await GetConversationList((conversation_list) => {
        let selectedUser = conversation_list.filter(
          (conversation) => conversation.id === userID
        );

        this.setState({ conversation_list });
        this.onSelectUser(selectedUser[0]);
      });
    } else {
      await GetConversationList((conversation_list) => {
        this.setState({ conversation_list });
      });
    }
  };

  //Handler selected user Conversation load
  onSelectUser = async (user) => {
    const { match } = this.props;
    const { userID } = match && match.params;
    // debugger;
    /**
     * Get Conversation's Messages
     */
    if (user) {
      await GetConversation(userID, 100, (messages) => {
        this.setState({
          // loader: true,
          selectedSectionId: userID,
          drawerState: this.props.drawerState,
          selectedUser: user,
          conversation: messages.reverse(),
        });
      });
    } else {
      console.info("GetConversation - Failed");
    }
  };

  filterContact = (userName) => {
    if (userName === "") {
      return users.filter((user) => !user.recent);
    }
    return users.filter(
      (user) =>
        !user.recent &&
        user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  filterUsers = (userName) => {
    if (userName === "") {
      return users.filter((user) => user.recent);
    }
    return users.filter(
      (user) =>
        user.recent &&
        user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };

  /**
   * render the chat space
   * @component ChatHeader
   * @component SendMessageBox
   */
  Communication = () => {
    const { message, selectedUser, conversation, currentUser } = this.state;
    return (
      <div className="gx-chat-main">
        <ChatHeader
          onToggleDrawer={this.onToggleDrawer}
          selectedUser={selectedUser}
        />

        <CustomScrollbars className="gx-chat-list-scroll">
          <Conversation
            conversationData={conversation}
            selectedUser={selectedUser}
            currentUser={currentUser}
          />
        </CustomScrollbars>

        {/* Handle add comment section */}
        <SendMessageBox
          _handleKeyPress={this._handleKeyPress}
          updateMessageValue={this.updateMessageValue}
          submitComment={this.submitComment}
          message={message}
        />
      </div>
    );
  };

  onSelectUserFromList = async (user) => {
    const { match } = this.props;
    const isDashboard =
      match && match.url.includes("/seller/dashboard/message-center");

    const toNavigate = isDashboard
      ? `/seller/dashboard/message-center/${user.id}`
      : `/chat/${user.id}`;
    this.props.history.push(toNavigate);
    await GetConversation(user.id, 100, (messages) => {
      this.setState({
        // loader: true,
        selectedSectionId: user.id,
        drawerState: this.props.drawerState,
        selectedUser: user,
        conversation: messages.reverse(),
      });
    });
  };
  ChatUsers = () => {
    const { currentUser, conversation_list } = this.state;
    return (
      <div className="gx-chat-sidenav-main">
        <div className="gx-chat-sidenav-header">
          <ChatUserInfo currentUser={currentUser} />

          {/**
           * Search fucntion should be implemeted
           * @function -> updateSearchChatUser
           */}
          {/* <div className="gx-chat-search-wrapper">
            <SearchBox
              styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
              placeholder="Search or start new chat"
              onChange={this.updateSearchChatUser.bind(this)}
              value={this.state.searchChatUser}
            />
          </div> */}
        </div>

        <div className="gx-chat-sidenav-content">
          <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
            {this.state.chatUsers.length === 0 ? (
              <div className="gx-p-5">{this.state.userNotFound}</div>
            ) : (
              <>
                <ChatUserList
                  chatUsers={conversation_list ? conversation_list : []}
                  selectedSectionId={this.state.selectedSectionId}
                  onSelectUser={this.onSelectUserFromList}
                />
                <hr className="m-0"></hr>
              </>
            )}
          </CustomScrollbars>
        </div>
      </div>
    );
  };
  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.submitComment();
    }
  };

  handleChange = (event, value) => {
    this.setState({ selectedTabIndex: value });
  };

  handleChangeIndex = (index) => {
    this.setState({ selectedTabIndex: index });
  };

  showCommunication = () => {
    const { selectedUser, conversation_list } = this.state;
    return (
      <div className="gx-chat-box">
        {conversation_list && !conversation_list?.length ? (
          <div className="gx-comment-box">
            <div className="gx-fs-80">
              <i className="icon icon-chat gx-text-muted" />
            </div>
            <h1 className="gx-text-muted">
              {<IntlMessages id="chat.currentlyYouHaveNoChat" />}
            </h1>
          </div>
        ) : selectedUser === null ? (
          <div className="gx-comment-box">
            <div className="gx-fs-80">
              <i className="icon icon-chat gx-text-muted" />
            </div>
            <h1 className="gx-text-muted">
              {<IntlMessages id="chat.selectUserChat" />}
            </h1>
            <Button
              className="gx-d-block gx-d-lg-none"
              type="primary"
              onClick={this.onToggleDrawer}
            >
              {<IntlMessages id="chat.selectContactChat" />}
            </Button>
          </div>
        ) : (
          this.Communication()
        )}
      </div>
    );
  };

  submitComment = () => {
    if (this.state.message !== "") {
      /**
       * Send Message
       */
      SendMesage(
        this.state.selectedSectionId,
        this.state.message,
        this.messageResponseHandler
      );
      this.setState({ message: "" });
    }
  };

  /**
   * Message send call-back
   * @param {message send or fail } successStatus true/false
   * @param {sended message } message
   */
  messageResponseHandler = (successStatus, message) => {
    const { conversation } = this.state;
    let messageObj = {
      id: 0,
      message: message,
      message_type: "Message",
      product: {},
      type: "sent",
      url: undefined,
    };
    let updatedConversation = [...conversation, messageObj];
    this.setState({ conversation: updatedConversation });
  };

  updateMessageValue = (evt) => {
    this.setState({
      message: evt.target.value,
    });
  };

  updateSearchChatUser(evt) {
    let contactList = this.filterContact(evt.target.value);
    let chatUsers = this.filterUsers(evt.target.value);
    this.setState({
      searchChatUser: evt.target.value,
      contactList,
      chatUsers,
    });
  }

  onToggleDrawer = () => {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  };
  render() {
    const { match } = this.props;
    const { loader, drawerState } = this.state;
    const isDashboard =
      match && match.url.includes("/seller/dashboard/message-center");
    return (
      <React.Fragment>
        {isDashboard && <h3 className="mb-4">Message Center</h3>}
        <Card
          className={"gx-card " + (!isDashboard ? "mt-4" : "")}
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          {!isDashboard && <h5 className="mb-3 ml-2">Chats</h5>}

          <div className="gx-app-module gx-chat-module gx-card chat-box-jr">
            <div className="gx-chat-module-box">
              <div className="gx-d-block gx-d-lg-none">
                <Drawer
                  placement="left"
                  closable={false}
                  visible={drawerState}
                  onClose={this.onToggleDrawer}
                >
                  {this.ChatUsers()}
                </Drawer>
              </div>
              <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
                {this.ChatUsers()}
              </div>
              {loader ? (
                <div className="gx-loader-view">
                  <CircularProgress />
                </div>
              ) : (
                this.showCommunication()
              )}
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    SingleProduct: state.SingleProduct,
    selectedSeller: state.Chat.selectedSeller,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    singleProductActions: bindActionCreators(singleProductActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatPage)
);
