import React, { Component } from "react";
import { Button } from "antd";
import StarRatingComponent from "react-star-rating-component";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { cartActions } from "../../Cart/ducks";
import "./WishlistCard.less";
import history from "../../../_helpers/history";
import { DEFAULT_CURRENCY } from "../../../constants";

class WishlistCard extends Component {
  removeHandler = () => {
    this.props.cartActions.removeWishlistItem({
      wishlist_id: this.props.data.id,
      callback: this.props.wishlistDeleteItemHandler,
    });
  };

  buyNowHandler = (id) => {
    this.props.history.push(`/product/${id}`);
  };

  render() {
    const { data } = this.props;
    const { product, seller } = data;
    const {
      id,
      images,
      name,
      ratings_value,
      ratings_count,
      price,
      old_price,
      discount_percentage,
      brand,
    } = product;
    return (
      <React.Fragment>
        <div className="gx-card p-3 mb-2 wish-crd-hvr">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "180px" }}>
                  <Link to={`/product/${id}`}>
                    <div>
                      <img
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "5px",
                        }}
                        alt="Remy Sharp"
                        src={images[0]}
                      />
                    </div>
                  </Link>
                </td>
                <td>
                  <div className="pr-4">
                    <h6 className="mb-0">
                      <Link
                        className="crd-fnt-jr"
                        onClick={() => history.push(`/product/${id}`)}
                      >
                        {name}
                      </Link>
                    </h6>
                    {ratings_count > 0 ? (
                      <div className="ant-row-flex mt-2">
                        <React.Fragment>
                          <StarRatingComponent
                            name=""
                            value={ratings_value}
                            // value={4}
                            starCount={5}
                            editing={false}
                            emptyStarColor={"#4b505e"}
                          />

                          <span className="gx-d-inline-block ml-1">
                            {ratings_value}
                          </span>
                          <span className="mx-2">|</span>
                          <span className="gx-d-inline-block">{`${ratings_count} Reviews`}</span>
                        </React.Fragment>

                        {/* {sold ? (
                        <React.Fragment>
                          {ratings_count != 0 ? (
                            <span className="mx-2">|</span>
                          ) : (
                              ""
                            )}
                          <span className="gx-d-inline-block">{`${sold} sold`}</span>
                        </React.Fragment>
                      ) : null} */}
                      </div>
                    ) : null}
                    <span
                      className={
                        "h3 d-block " +
                        (ratings_count > 0 ? "mt-0 mb-2" : "my-2")
                      }
                    >
                      <span style={{ color: "#ff6900" }}>
                        {price ? `${DEFAULT_CURRENCY} ${price}` : null}
                      </span>
                      &nbsp;
                      {discount_percentage && old_price ? (
                        <del className="text-muted">{`${DEFAULT_CURRENCY} ${old_price}`}</del>
                      ) : null}
                      &nbsp; &nbsp;
                      <span style={{ color: "#129c30", fontSize: "20px" }}>
                        {discount_percentage !== 0
                          ? `${discount_percentage} % off`
                          : null}
                      </span>
                    </span>
                    {brand ? (
                      <div className="mt-1">
                        Brand :{" "}
                        <span style={{ color: "#129c30" }}>{brand}</span>
                      </div>
                    ) : null}
                    <div className="mt-1">
                      Seller :{" "}
                      <Link
                        onClick={() => history.push(`/seller/${seller.id}`)}
                      >
                        <span className="crd-fnt-jr-wish">{seller.name}</span>
                      </Link>
                    </div>
                  </div>
                </td>
                <td style={{ width: "180px" }}>
                  <div className="p-3">
                    <Button
                      block
                      className="gx-btn-outline-sepia ml-0 mr-0"
                      onClick={this.removeHandler}
                    >
                      {/* <IntlMessages id="eCommerce.readMore" /> */}
                      <i className="fad fa-minus-circle mr-1"></i>
                      Remove
                    </Button>
                    <Button
                      className="new-btn-clr ml-0 mr-0 mb-0"
                      block
                      onClick={() => this.buyNowHandler(id)}
                    >
                      {/* <IntlMessages id="eCommerce.addToCart" /> */}
                      {/* <i class="fad fa-usd-circle"></i>&nbsp; */}
                      Buy Now
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WishlistCard)
);
