import React from "react";
import { Layout } from "antd";
import HorizontalDark from "../containers/Topbar/HorizontalDark";
import AccountMainNavBar from "components/Buyer/AccountMainNavBar/AccountMainNavBar";
// import MessageSideBar from "components/Buyer/MessageSideBar/MessageSideBar";
import history from "../_helpers/history";
import TopBar from "../components/TopBar/TopBar";
import Sidebar from "../containers/Sidebar";
import SiteFooter from "../components/Buyer/FooterComponent/SiteFooter";

const { Content } = Layout;

const DefaultLayout = (ViewComponent) => {
  return class extends React.Component {
    componentDidMount() {
      // getter
      const user = localStorage.getItem("user");
      if (!user) {
        history.push(`login`);
      }
    }
    render() {
      return (
        <Layout className="gx-app-layout">
          <Sidebar />
          <Layout>
            <TopBar />
            <HorizontalDark />
            <AccountMainNavBar />
            <Content>
              <div
                // className="gx-main-content-wrapper"
                className="gx-container"
                style={{ minHeight: "600px" }}
              >
                {/* <Row style={{ paddingTop: 20 }}>
                  <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                    <MessageSideBar />
                  </Col>

                  <Col xl={18} lg={18} md={24} sm={24} xs={24}>
                    <> */}
                <ViewComponent />
                {/* </>
                  </Col>
                </Row> */}
              </div>
              <SiteFooter />
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export default DefaultLayout;
