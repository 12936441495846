//convert First later capital
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

//add type charactor at the end of report name
export const toAppendType = (type, date) => {
  if (type.charAt(0).toUpperCase() === "W") {
    return date + " " + type.substring(0, type.length - 2);
  } else {
    return date;
  }
};

const combine = ([head, ...[headTail, ...tailTail]]) => {
  if (!headTail) return head;

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}:${x}`));
  }, []);

  return combine([combined, ...tailTail]);
};

export const getVariationTableInitValues = (variations) => {
  var variationArr_1 = [];
  var variationArr_2 = [];
  var variationArr_3 = [];
  var variation_table = [];

  if (
    variations &&
    Array.isArray(variations) &&
    variations.length >= 1 &&
    variations[0] &&
    variations[0].values &&
    Array.isArray(variations[0].values)
  ) {
    variationArr_1 = variations[0].values;
  }
  if (
    variations &&
    Array.isArray(variations) &&
    variations.length >= 2 &&
    variations[1] &&
    variations[1].values &&
    Array.isArray(variations[1].values)
  ) {
    variationArr_2 = variations[1].values;
  }
  if (
    variations &&
    Array.isArray(variations) &&
    variations.length === 3 &&
    variations[2] &&
    variations[2].values &&
    Array.isArray(variations[2].values)
  ) {
    variationArr_3 = variations[2].values;
  }

  if (variationArr_2.length === 0 && variationArr_3.length === 0) {
    variation_table = variationArr_1;
  } else if (variationArr_3.length === 0) {
    variation_table = combine([variationArr_1, variationArr_2]);
  } else if (
    variationArr_1.length !== 0 &&
    variationArr_2.length !== 0 &&
    variationArr_3.length !== 0
  ) {
    variation_table = combine([variationArr_1, variationArr_2, variationArr_3]);
  }

  return variation_table;
};

//get price
export const getSelecetedVariationProductPrice = (
  variations,
  userSelections,
  noOfVaiations,
  variation_1,
  variation_2,
  variation_3,
  data
) => {
  var userSelectedVariations =
    userSelections && userSelections.variations
      ? userSelections.variations
      : {};
  var price = null;
  var isAllSelected = false;
  // debugger;
  var userSelectedVariationsLength = Object.keys(userSelectedVariations).length;
  // debugger;

  //variation 1 handle
  if (noOfVaiations === 1) {
    if (noOfVaiations === userSelectedVariationsLength) {
      isAllSelected = true;
      price = variations
        .filter(
          (variation) =>
            variation[variation_1] === userSelectedVariations[variation_1]
        )
        .map((variation) => variation.price);
    } else {
      price = [data.price];
    }
  }
  //variation 2 handle
  else if (noOfVaiations === 2) {
    if (noOfVaiations === userSelectedVariationsLength) {
      isAllSelected = true;
      price = variations
        .filter(
          (variation) =>
            variation[variation_1] === userSelectedVariations[variation_1] &&
            variation[variation_2] === userSelectedVariations[variation_2]
        )
        .map((variation) => variation.price);
    } else {
      price = [data.price];
    }
  } else if (noOfVaiations === 3) {
    if (noOfVaiations === userSelectedVariationsLength) {
      isAllSelected = true;
      price = variations
        .filter(
          (variation) =>
            variation[variation_1] === userSelectedVariations[variation_1] &&
            variation[variation_2] === userSelectedVariations[variation_2] &&
            variation[variation_3] === userSelectedVariations[variation_3]
        )
        .map((variation) => variation.price);
    } else {
      price = [data.price];
    }
  }
  //handle no variation
  else {
    price = [data && data.price];
  }
  var returnPrice = price && price.length !== 0 ? price[0] : null;
  var returnPriceObj = {
    isAllSelected: isAllSelected,
    productPrice: returnPrice,
  };
  return returnPriceObj;
};

/**
 * get selected product
 * @param {item variations} all_vars
 * @param {user selected variation} selected_vars
 */
export const getSelectedProductPrice = (all_vars, selected_vars, data) => {
  const matched_variation = all_vars.filter((all_var) =>
    Object.keys(selected_vars).every(
      (variation) =>
        all_var.hasOwnProperty(variation) &&
        selected_vars[variation] === all_var[variation]
    )
  );

  if (selected_vars) {
    return matched_variation[0];
  } else {
    let obj = {
      id: data.id,
      old_price: data.old_price,
      price: data.price,
      qty: data.qty,
    };
    return obj;
  }
};

/**
 * get selected product
 * @param {item variations} all_vars
 * @param {user selected variation} selected_vars
 * @param {no of variations} no_of_variations
 */
export const getSelectedProduct = (
  all_vars,
  selected_vars,
  no_of_variations
) => {
  if (selected_vars && selected_vars.length === no_of_variations) {
    const matched_variation = all_vars.filter((all_var) =>
      Object.keys(selected_vars).every(
        (variation) =>
          all_var.hasOwnProperty(variation) &&
          selected_vars[variation] === all_var[variation]
      )
    );

    if (matched_variation && matched_variation.length) {
      return matched_variation[0];
    } else {
      
      return null;
    }
  } else {
    return null;
  }
};

//get price
export const getSelecetedVariationProductOldPrice = (
  variations,
  userSelections,
  noOfVaiations,
  variation_1,
  variation_2,
  variation_3,
  data
) => {
  var userSelectedVariations =
    userSelections && userSelections.variations
      ? userSelections.variations
      : {};
  var oldPrice = null;
  var userSelectedVariationsLength = Object.keys(userSelectedVariations).length;

  if (noOfVaiations === 1) {
    if (noOfVaiations === userSelectedVariationsLength) {
      oldPrice = variations
        .filter(
          (variation) =>
            variation[variation_1] === userSelectedVariations[variation_1]
        )
        .map((variation) => variation.old_price);
    } else {
      oldPrice = [data.old_price];
    }
  } else if (noOfVaiations === 2) {
    if (noOfVaiations === userSelectedVariationsLength) {
      oldPrice = variations
        .filter(
          (variation) =>
            variation[variation_1] === userSelectedVariations[variation_1] &&
            variation[variation_2] === userSelectedVariations[variation_2]
        )
        .map((variation) => variation.old_price);
    } else {
      oldPrice = [data.old_price];
    }
  } else if (noOfVaiations === 3) {
    if (noOfVaiations === userSelectedVariationsLength) {
      oldPrice = variations
        .filter(
          (variation) =>
            variation[variation_1] === userSelectedVariations[variation_1] &&
            variation[variation_2] === userSelectedVariations[variation_2] &&
            variation[variation_3] === userSelectedVariations[variation_3]
        )
        .map((variation) => variation.old_price);
    } else {
      oldPrice = [data.old_price];
    }
  } else {
    oldPrice = [data && data.old_price];
  }
  var returnOldPrice =
    oldPrice && oldPrice.length !== 0 && oldPrice[0] ? `${oldPrice[0]}` : null;

  return returnOldPrice;
};

//get qty
export const getSelecetedVariationProductQty = (
  variations,
  userSelections,
  noOfVaiations,
  variation_1,
  variation_2,
  variation_3,
  data
) => {
  var userSelectedVariations =
    userSelections && userSelections.variations
      ? userSelections.variations
      : {};
  var qty = null;
  var userSelectedVariationsLength = Object.keys(userSelectedVariations).length;

  if (data) {
    if (noOfVaiations === 1) {
      if (noOfVaiations === userSelectedVariationsLength) {
        qty = variations
          .filter(
            (variation) =>
              variation[variation_1] === userSelectedVariations[variation_1]
          )
          .map((variation) => variation.qty);
      } else {
        qty = [data.total_qty];
      }
    } else if (noOfVaiations === 2) {
      if (noOfVaiations === userSelectedVariationsLength) {
        qty = variations
          .filter(
            (variation) =>
              variation[variation_1] === userSelectedVariations[variation_1] &&
              variation[variation_2] === userSelectedVariations[variation_2]
          )
          .map((variation) => variation.qty);
      } else {
        qty = [data.total_qty];
      }
    } else if (noOfVaiations === 3) {
      if (noOfVaiations === userSelectedVariationsLength) {
        qty = variations
          .filter(
            (variation) =>
              variation[variation_1] === userSelectedVariations[variation_1] &&
              variation[variation_2] === userSelectedVariations[variation_2] &&
              variation[variation_3] === userSelectedVariations[variation_3]
          )
          .map((variation) => variation.qty);
      } else {
        qty = [data.total_qty];
      }
    } else {
      qty = [data.total_qty];
    }
  }
  var returnQty = qty && qty.length !== 0 && qty[0] ? `${qty[0]}` : null;

  return returnQty;
};

// handle variation avalable types -> first selection
export const getAvailableVariationTypes__firstSelection = (
  variationLabelX,
  variationLabelY,
  variation_values_1,
  variation_values_2,
  variation_values_3,
  variations,
  variationLabel,
  value,
  variation_1,
  variation_2,
  variation_3,
  variationValues
) => {
  var updated_variation_values_1 = variation_values_1;
  var updated_variation_values_2 = variation_values_2;
  var updated_variation_values_3 = variation_values_3;
  // debugger;
  if (variationLabelX) {
    //find the variation x avalable types
    var availableX = variations
      .filter((variation) => variation[variationLabel] === value)
      .map((variation) => variation[variationLabelX]);
    //get unique variation-x types
    var uniqAvailableX = [...new Set(availableX)];

    if (variation_1 === variationLabelX) {
      updated_variation_values_1 = uniqAvailableX;
    }
    if (variation_2 === variationLabelX) {
      updated_variation_values_2 = uniqAvailableX;
    }
    if (variation_3 === variationLabelX) {
      updated_variation_values_3 = uniqAvailableX;
    }
  }
  //handle variation-y -> third variation availbality handle
  if (variationLabelY) {
    //find the variation y avalable types
    var availableY = variations
      .filter((variation) => variation[variationLabel] === value)
      .map((variation) => variation[variationLabelY]);
    //get unique variation-y types
    var uniqAvailableY = [...new Set(availableY)];

    if (variation_1 === variationLabelY) {
      updated_variation_values_1 = uniqAvailableY;
    }
    if (variation_2 === variationLabelY) {
      updated_variation_values_2 = uniqAvailableY;
    }
    if (variation_3 === variationLabelY) {
      updated_variation_values_3 = uniqAvailableY;
    }
  }
  if (variationValues) {
    if (variationLabel) {
      if (variation_1 === variationLabel) {
        updated_variation_values_1 = variationValues[variationLabel];
      }
      if (variation_2 === variationLabel) {
        updated_variation_values_2 = variationValues[variationLabel];
      }
      if (variation_3 === variationLabel) {
        updated_variation_values_3 = variationValues[variationLabel];
      }
    }
  }
  var availableVariationTypes = {
    updated_variation_values_1,
    updated_variation_values_2,
    updated_variation_values_3,
  };
  return availableVariationTypes;
};

// handle variation avalable types -> second selection
export const getAvailableVariationTypes__secondSelection = (
  variationLabelX,
  variationLabelY,
  variation_values_1,
  variation_values_2,
  variation_values_3,
  variations,
  variationLabel,
  value,
  variation_1,
  variation_2,
  variation_3,
  prevUserSelectedVariations,
  userSelections,
  variationTypes
) => {
  // debugger;
  var selectedVariations = [];
  selectedVariations.push(variationLabel);
  selectedVariations.push(prevUserSelectedVariations[0]);

  var notSelectedVariation = variationTypes.filter(
    (d) => !selectedVariations.includes(d)
  ); // gives ["c", "d"]

  var prevSelectedVariationValue =
    userSelections &&
    userSelections.variations &&
    userSelections.variations[prevUserSelectedVariations[0]];

  var updated_variation_values_1 = variation_values_1;
  var updated_variation_values_2 = variation_values_2;
  var updated_variation_values_3 = variation_values_3;

  if (notSelectedVariation) {
    var availableX = variations
      .filter(
        (variation) =>
          variation[variationLabel] === value &&
          variation[prevUserSelectedVariations[0]] ===
            prevSelectedVariationValue
      )
      .map((variation) => variation[notSelectedVariation]);
    //get unique variation-x types
    var uniqAvailableX = [...new Set(availableX)];

    if (variation_1 === notSelectedVariation[0]) {
      updated_variation_values_1 = uniqAvailableX;
    }
    if (variation_2 === notSelectedVariation[0]) {
      updated_variation_values_2 = uniqAvailableX;
    }
    if (variation_3 === notSelectedVariation[0]) {
      updated_variation_values_3 = uniqAvailableX;
    }
  }
  var availableVariationTypes = {
    updated_variation_values_1,
    updated_variation_values_2,
    updated_variation_values_3,
  };
  return availableVariationTypes;
};

//validate variation selections
export const getValidationVariationSelections = (
  variation_1,
  variation_2,
  variation_3,
  userSelections
) => {
  var errMessage_1 = null;
  var errMessage_2 = null;
  var errMessage_3 = null;

  // debugger;
  if (variation_1) {
    if (userSelections.variations && !userSelections.variations[variation_1]) {
      errMessage_1 = `Please select ${variation_1}`;
    } else if (!userSelections.variations) {
      errMessage_1 = `Please select ${variation_1}`;
    }
  }
  if (variation_2) {
    if (userSelections.variations && !userSelections.variations[variation_2]) {
      errMessage_2 = `Please select ${variation_2}`;
    } else if (!userSelections.variations) {
      errMessage_2 = `Please select ${variation_2}`;
    }
  }
  if (variation_3) {
    if (userSelections.variations && !userSelections.variations[variation_3]) {
      errMessage_3 = `Please select ${variation_3}`;
    } else if (!userSelections.variations) {
      errMessage_3 = `Please select ${variation_3}`;
    }
  }

  var validateMsgs = {
    errMessage_1,
    errMessage_2,
    errMessage_3,
  };
  return validateMsgs;
};

//check selected item in already in the cart
export const checkMyCart = (myCart, item) => {
  const { id, userSelections, variation_types } = item;
  var isAlreadyInMyCart = false;

  if (variation_types.length === 0) {
    // grab the myCart item which matchs selected item
    myCart.forEach((cartItem, index) => {
      if (cartItem.id === id) {
        myCart.splice(index, 1);
        myCart = [item].concat(myCart);
        isAlreadyInMyCart = true;
      }
    });
  } else {
    var selectedItemVariations = userSelections.variations;
    var sortedSelectedItemVariations = Object.keys(selectedItemVariations)
      .sort()
      .reduce((accumulator, currentValue) => {
        accumulator[currentValue] = selectedItemVariations[currentValue];
        return accumulator;
      }, {});
    var sortedSelectedItemVariationsString = JSON.stringify(
      sortedSelectedItemVariations
    );

    // grab the myCart item which matchs selected item
    myCart.forEach((cartItem, index) => {
      if (cartItem.id === id) {
        let selectedCartItemVariations = cartItem.userSelections.variations;
        let sortedSelectedCartItemVariations = Object.keys(
          selectedCartItemVariations
        )
          .sort()
          .reduce((accumulator, currentValue) => {
            accumulator[currentValue] =
              selectedCartItemVariations[currentValue];
            return accumulator;
          }, {});
        let sortedSelectedCartItemVariationsString = JSON.stringify(
          sortedSelectedCartItemVariations
        );
        if (
          sortedSelectedItemVariationsString ===
          sortedSelectedCartItemVariationsString
        ) {
          // delete myCart[index]
          myCart.splice(index, 1);
          myCart = [item].concat(myCart);
          isAlreadyInMyCart = true;
        }
      }
    });
  }

  //if exist same product find the same variations
  return { myCart, isAlreadyInMyCart };
};

//return sidebar category icon
export const getSidebarCategoryIcon = (name) => {
  switch (name) {
    case "Business & Industrial":
      return "fad fa-industry-alt";
    case "Collectibles & Art":
      return "fad fa-pencil-paintbrush";
    case "Electronics":
      return "fad fa-fan-table";
    case "Fashion":
      return "fad fa-tshirt";
    case "Home & Garden":
      return "fad fa-shovel";
    case "Motors":
      return "fad fa-tire";
    case "Music":
      return "fad fa-guitar";
    case "Others":
      return "fad fa-bags-shopping";
    case "Sporting Goods":
      return "fad fa-futbol";
    case "Toys & Hobbies":
      return "fad fa-dice";
    default:
      return "fad fa-cubes";
  }
};

//check item is selected in user-selected items
export const checkIfIsSelectedItems = (
  selectedItems,
  product_id,
  key,
  value
) => {
  //update order-summery
  let tempSelectedItems = Object.assign({}, selectedItems);
  var updatedSelectedRowsIndex = tempSelectedItems.selectedRows.findIndex(
    (item) => item.id === product_id
  );
  if (updatedSelectedRowsIndex === -1) {
    return null;
  } else {
    let updatedSelectedItem =
      tempSelectedItems.selectedRows[updatedSelectedRowsIndex];
    updatedSelectedItem = {
      ...updatedSelectedItem,
      userSelections: {
        ...updatedSelectedItem.userSelections,
        [key]: value,
      },
    };
    // cartActions.selectItem({selectedRows:tempSelectedItems.selectedRows})
    //  selectItem({selectedRows:tempSelectedItems.selectedRows})
    tempSelectedItems.selectedRows[updatedSelectedRowsIndex] =
      updatedSelectedItem;

    return tempSelectedItems.selectedRows;
  }
};

//export the csv file
export const exportCsv = (array, startDate, endDate, type) => {
  // debugger
  const link = document.createElement("a");
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";

  try {
    const keys = Object.keys(array[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    let csv = result;
    if (csv == null) return;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    let typeCap = toTitleCase(type);

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute(
      "download",
      `${typeCap} Report ${startDate}-${endDate}.csv`
    );
    link.click();
  } catch (err) {
    
  }
};

//export default shipping address
export const getDefaultShippingAddress = (shippingAddress) => {
  let user = localStorage.getItem("user");
  let defaultShippingAddress = null;

  // debugger;
  // check user is logged
  if (user) {
    let shippingAddressData = shippingAddress?.data?.data;
    let shippingAddressArray = shippingAddressData?.shipping_addresses ?? [];

    if (shippingAddressArray.length === 0) {
      return null;
    }
    let defaultShippingAddressId =
      shippingAddressData["default_shipping_address_id"];
    if (
      shippingAddressData &&
      shippingAddressData["default_shipping_address_id"] !== null
    ) {
      // defaultShippingAddress =
      //   shippingAddressData.shipping_addresses["default_shipping_address_id"];
      defaultShippingAddress = shippingAddressArray.filter(
        (obj) => obj.id === defaultShippingAddressId
      )[0];
    } else if (shippingAddressData?.shipping_addresses[0]) {
      // var defaultShippingAddress = null
      defaultShippingAddress = shippingAddressData.shipping_addresses[0];
    }
  }

  return defaultShippingAddress;
};
