// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getSellerStatistics: createAction(types.GET_SELLER_STATISTICS),
  getSellerStatisticsSuccess: createAction(types.GET_SELLER_STATISTICS_SUCCESS),
  getSellerStatisticsFail: createAction(types.GET_SELLER_STATISTICS_FAIL),

  getSellerRevenue: createAction(types.GET_SELLER_REVENUE),
  getSellerRevenueSuccess: createAction(types.GET_SELLER_REVENUE_SUCCESS),
  getSellerRevenueFail: createAction(types.GET_SELLER_REVENUE_FAIL),

  getSellerPopularProducts: createAction(types.GET_SELLER_POPULAR_PRODUCTS),
  getSellerPopularProductsSuccess: createAction(
    types.GET_SELLER_POPULAR_PRODUCTS_SUCCESS
  ),
  getSellerPopularProductsFail: createAction(
    types.GET_SELLER_POPULAR_PRODUCTS_FAIL
  ),

  getSellerOrders: createAction(types.GET_SELLER_ORDERS),
  getSellerOrdersSuccess: createAction(types.GET_SELLER_ORDERS_SUCCESS),
  getSellerOrdersFail: createAction(types.GET_SELLER_ORDERS_FAIL),

  getSellerActivities: createAction(types.GET_SELLER_ACTIVITIES),
  getSellerActivitiesSuccess: createAction(types.GET_SELLER_ACTIVITIES_SUCCESS),
  getSellerActivitiesFail: createAction(types.GET_SELLER_ACTIVITIES_FAIL),
};
