//payment methods
export const extraInfoDetails = {
  productName:
    "Put Eye-catching descriptive product name. If you put more features inside the product name, your product will appear in more search results. Ex: 'SanDisk Ultra New 32GB micro SD SDHC Class10 Flash Memory Card 100MB/s'",
  productCategory:
    "Here, you can add the category of your product. Be attentive on selecting the correct category, because your product will list under this category.",
  productBrand:
    "Select the brand of your product. If it is unbranded, put it as Unbranded. If you are promoting your brand, it's ok to add it here and contact us to add your brand in our top brand section as well (optional).",
  productCondition: "Product Condition.",
  descriptionSection: "Product Description",
  productGallery:
    "Add clear and nice images of your product. Adding eye-catching product images will motivate buyers to buy your product.",
  description:
    "Add enough description about your product. The more you explain more buyers will get interest.",
  heighlights:
    "If there any special specifications to highlight. Put those points here.",
  specifications:
    "In this section, you can add the specifications of your product (Ex: Brand Name, Model Number...). If there any special specifications available. if not keep it as it is.",
  specificationsValue:
    "In this section, you can add the specifications value of your product .",
  variableOne:
    "Variable product means the product has more varieties. Ex: T-shirts with different sizes. You can add different prices for each variant product by setting this state as yes.",
  productStock: "Add your price and stock related details here.",
  packagePrice: "Price of the product in your local currency.",
  packageAvailability:
    "Number of items available in your stock. (putting only number is enough, no description needed)",
  packageDiscount:
    "Add discount amount if you plan to give a discount to your customers. otherwise, keep it blank.",
  packageService: "Add details about the warranty",
  packageWarranty:
    "Here you can select a warranty option. Select the option which best describes your service",
  packageDetail: "Shipping-related data will add here",
  packageType:
    "Here package sizes are indicated compared to letter-size. select the best appropriate size category in this list",
  packageWieght: "Weight of the package.",
  packageLength: "Length of the package.",
  packageWidth: "Width of the package.",
  packageHeight: "Height of the package.",
  dangerousGoods:
    "This is to mention whether there are any dangerous items inside the package. To get to know if this package needs extended attention. Go through the list and select the appropriate option.",
  itemCountry: "Country which particular item is shipped from.",
  itemCity: "City which particular item is shipped from.",
  itemZipCode: "Zip code of items's location.",
  domShipngMeth:
    "Free shipping > Free shipping inside the sellers country,  Flat-rate > Shipping prize is the same anywhere inside the sellers county, No-shipping > Item will not ship inside the sellers country",
  domesticShippingEstimatedDeliveryDaysMin:
    "Minimum days which take for domestic shipping.",
  domesticShippingEstimatedDeliveryDaysMax:
    "Maximum date which take for domestic shipping.",
  domesticShippingEstimatedDeliveryNote:
    "domesticShippingEstimatedDeliveryNote",
  domesticShippingPrice:
    "This option enables because you have chosen the flat rate option as the shipping option. here you can put that flat rate shipping cost for shipping inside your country.",
  domesticShippingAdditionalPrice:
    "If customers require additional items in a single order how much cost you charge to increase the shipping cost per additional item.",
  domesticShippingTrackingAvailable: "domesticShippingTrackingAvailable",
  internationalShippingEstimatedDeliveryDaysMin:
    "Minimum days which take for international shipping",
  internationalShippingEstimatedDeliveryDaysMax:
    "Maximum date which take for international shipping",
  internationalShippingEstimatedDeliveryNote:
    "internationalShippingEstimatedDeliveryNote",
  returnShpingAccepted:
    "To return an item who is going to pay. select the appropriate option",
  shippingHandlingDay: "Return shipment handling days",
  internationShippingMethod: `- Calculated - Shipping service will calculate the shipping cost according to the seller's address and buyer's address
  - Free shipping - No shipping cost for buyer
  - Flat rate - Flat shipping rate for worldwide
  - No shipping - Item will not ship internationally. Only local pickups
  `,
  internationalShippingTracking:
    "By enabling this option customers will able to Track international shipping orders.",
  internationalShippingAdditionalPrice:
    "If customers require additional items in a single order how much cost you charge to increase the shipping cost per additional item.",
  internationalShippingPrice:
    "This option enables because you have chosen the flat rate option as the shipping option. here you can put that flat rate shipping cost for worldwide shipping.",
  estimatedNote:
    'Here you can put any special notes regarding delivery such as "Delivery days may vary due to COVID-19 pandemic situation"',
  internationalShippingCarriers:
    "Select carriers service. If you want you can select multiple carrier service",
  excludeShippingLocation: "locations which this item will not ship",
  internationalShipping: "Shipping options related to international shipping",
  domesticShippingTracking:
    "By enabling this option customers will able to Track domestic shipping orders. ( Enable this option if only your package delivery service support order tracking )",
  shippingHandlingDays: "Return shipment handling days",
  returnAcceptedMethod: "To return an item who is going to pay. select the appropriate option"
};
