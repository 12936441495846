const action_header = "userManagement/";

// Types
export default {
  CHANGE_USER_ACTIVE_STATUS: action_header + "CHANGE_USER_ACTIVE_STATUS",
  CHANGE_USER_ACTIVE_STATUS_SUCCESS:
    action_header + "CHANGE_USER_ACTIVE_STATUS_SUCCESS",
  CHANGE_USER_ACTIVE_STATUS_FAIL:
    action_header + "CHANGE_USER_ACTIVE_STATUS_FAIL",

  CREATE_USER: action_header + "CREATE_USER",
  CREATE_USER_SUCCESS: action_header + "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: action_header + "CREATE_USER_FAIL",

  GET_USER: action_header + "GET_USER",
  GET_USER_SUCCESS: action_header + "GET_USER_SUCCESS",
  GET_USER_FAIL: action_header + "GET_USER_FAIL",

  UPDATE_USER: action_header + "UPDATE_USER",
  UPDATE_USER_SUCCESS: action_header + "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: action_header + "UPDATE_USER_FAIL",

  DELETE_USER: action_header + "DELETE_USER",
  DELETE_USER_SUCCESS: action_header + "DELETE_USER_SUCCESS",
  DELETE_USER_FAIL: action_header + "DELETE_USER_FAIL",

  CLEAR_USER: action_header + "CLEAR_USER",

  GET_USER_COUNT: action_header + "GET_USER_COUNT",
  GET_USER_COUNT_SUCCESS: action_header + "GET_USER_COUNT_SUCCESS",
  GET_USER_COUNT_FAIL: action_header + "GET_USER_COUNT_FAIL",

};
