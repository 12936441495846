import React from "react";
import { Layout } from "antd";
import Sidebar from "../containers/Sidebar/Seller";
import DashboardProfileNav from "../components/Buyer/DashboardProfileHeader/DashboardProfileHeader";
import DashboardAccountGenaralNav from "../components/Buyer/DashboardAccountGenaralNav/DashboardAccountGenaralNav";
import { userRoles } from "../constants/Utilities";
import history from "../_helpers/history";
import Topbar from "../containers/Dashboard/Topbar/Topbar";

const { Content, Footer } = Layout;

const DashboardProfileLayout = (ViewComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        userJson: null,
      };
    }
    componentDidMount() {
      // getter
      var user = localStorage.getItem("user");
      if (user) {
        var userJson = JSON.parse(user);
        var role = null;
        role = userJson && userJson.role;
        var isAdminRole =
          role === userRoles.Seller ||
          role === userRoles.Moderator ||
          role === userRoles.Admin
            ? true
            : false;

        if (!isAdminRole) {
          history.push(`/login`);
        }
        this.setState({ userJson });
      } else {
        history.push(`/login`);
      }
    }
    render() {
      const { userJson } = this.state;

      return (
        <Layout className="gx-app-layout">
          <Sidebar userJson={userJson} />
          <Layout>
            <Topbar userJson={userJson} />
            <DashboardProfileNav />

            <Content>
              <div
                className="gx-main-content-wrapper"
                style={{ minHeight: "91%" }}
              >
                <DashboardAccountGenaralNav />
                <ViewComponent />
              </div>
              <Footer>
                <div className="gx-layout-footer-content">
                  {process.env.REACT_APP_FOOTER_COPYRIGHT_TEXT}
                </div>
              </Footer>
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export default DashboardProfileLayout;
