// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getOrdersCount: createAction(types.GET_ORDERS_COUNT),
  getOrdersCountSuccess: createAction(types.GET_ORDERS_COUNT_SUCCESS),
  getOrdersCountFail: createAction(types.GET_ORDERS_COUNT_FAIL),

  buyShippingLabel: createAction(types.BUY_SHIPPING_LABEL),
  buyShippingLabelSuccess: createAction(types.BUY_SHIPPING_LABEL_SUCCESS),
  buyShippingLabelFail: createAction(types.BUY_SHIPPING_LABEL_FAIL),

  exportOrders: createAction(types.EXPORT_ORDERS),
  exportOrdersSuccess: createAction(types.EXPORT_ORDERS_SUCCESS),
  exportOrdersFail: createAction(types.EXPORT_ORDERS_FAIL),

  orderTracking: createAction(types.ORDER_TRACKING),
  orderTrackingSuccess: createAction(types.ORDER_TRACKING_SUCCESS),
  orderTrackingFail: createAction(types.ORDER_TRACKING_FAIL),
};
