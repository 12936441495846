import React from "react";
import { Avatar } from "antd";
import moment from "moment";

const SentMessageCell = ({ conversation, currentUser }) => {
  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={
          currentUser
            ? currentUser.image
            : "https://via.placeholder.com/150x150"
        }
        alt={conversation.name}
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          {conversation.message_type === "Message" ? (
            <div className="gx-message">{conversation.message}</div>
          ) : conversation.message_type === "Image" ? (
            <div className="gx-message" style={{ maxWidth: "80%" }}>
              <img alt="img-sent-message-cell" src={conversation.url} />
            </div>
          ) : (
            <div className="gx-message" style={{ maxWidth: "80%" }}>
              <a href={`/products/${conversation.product.id}`}>
                <img
                  alt="img-sent-message-cell"
                  src={conversation.product.image}
                />
                <p>{conversation.product.name}</p>
              </a>
            </div>
          )}
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            {conversation.id === 0
              ? moment().fromNow()
              : moment(conversation.sentAt).format("lll")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SentMessageCell;
