import { createLogic } from "redux-logic";

import actions from "./actions";
import types from "./types";
import endPoints from "../../../../util/EndPoints";
import API from "../../../../util/HTTPClient";
import { exportCsv } from "../../../../util/services";

const getTransactionRecord = createLogic({
  type: types.GET_TRANSACTION_RECORD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.TRANSACTION_REPORT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getTransactionRecordSuccess(data));
        exportCsv(
          data && data.data,
          action.payload.startDate,
          action.payload.endDate,
          action.payload.type
        );
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getTransactionRecordFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [getTransactionRecord];
