import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Card } from "antd";
import { resolutionCenterActions } from "../../../modules/ResolutionCenter/ducks";
import ResolutionConversationHeader from "./ResolutionConversationHeader";
import SingleCaseForm from "../../../modules/ResolutionCenter/SingleCase/SingleCaseForm";
import "./ResolutionConversationList.less";

class ResolutionConversationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    // const userObj = JSON.parse(user);

    if (!user) {
      this.props.history.push("/login");
    } else {
      const userObj = JSON.parse(user);
      const user_id = userObj.user_id;
      this.setState({
        user,
        user_id,
      });
    }
  }

  handleVisitShop = (sellerID) => {
    this.props.history.push(`/seller/${sellerID}`);
  };

  render() {
    const { getCase } = this.props;
    const { user_id } = this.state;

    if (!getCase.loading && !getCase.hasError) {
      const { product, seller, status, conversation, reason } =
        getCase.data && getCase.data.data;

      return (
        <React.Fragment>
          <ResolutionConversationHeader
            product={product}
            seller={seller}
            handleVisitShop={this.handleVisitShop}
            status={status}
            reason={reason}
          />

          <div className="gx-card p-4 mb-0">
            <h6 className="mb-3">Conversation</h6>
            <div className="details-card-jr">
              <div className="conv-contnr">
                {conversation.map((con, i) => {
                  if (con.from === user_id) {
                    return (
                      <table style={{ width: "100%", marginBottom: "5px" }}>
                        <tr>
                          <td></td>
                          <td style={{ width: "60%" }}>
                            <div
                              style={{
                                backgroundColor: "#e6fffb",
                                padding: "10px",
                                border: "1px solid #87e8de",
                                borderRadius: "4px",
                                color: "rgba(0,0,0,.65)",
                              }}
                            >
                              <p className="mb-0">
                                <b>{con.sender_name}</b>
                              </p>
                              <p className="mb-0" style={{ fontSize: "13px" }}>
                                {moment(con.created_at).format("LLLL")}
                              </p>
                              <hr
                                className="mt-2 mb-2"
                                style={{
                                  borderTop: "1px solid #87e8de",
                                }}
                              />
                              <p className="mb-0">{con.message}</p>
                              {con.images.map((image, i) => {
                                return (
                                  <div className="mt-2">
                                    <div className="mb-0">
                                      <img
                                        style={{
                                          width: "175px",
                                          borderRadius: "5px",
                                        }}
                                        src={image}
                                        alt="..."
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                          <td
                            style={{
                              width: "60px",
                              verticalAlign: "top",
                              textAlign: "end",
                            }}
                          >
                            <img
                              className=""
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                border: "2px solid #e8e8e8",
                              }}
                              src={con.sender_image}
                              alt="..."
                            />
                          </td>
                        </tr>
                      </table>
                    );
                  } else {
                    return (
                      <table style={{ width: "100%", marginBottom: "5px" }}>
                        <tr>
                          <td
                            style={{
                              width: "60px",
                              verticalAlign: "top",
                              textAlign: "start",
                            }}
                          >
                            <img
                              className=""
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                border: "2px solid #e8e8e8",
                              }}
                              src={con.sender_image}
                              alt="..."
                            />
                          </td>
                          <td style={{ width: "60%" }}>
                            <div
                              style={{
                                backgroundColor: "#f0f5ff",
                                padding: "10px",
                                border: "1px solid #adc6ff",
                                borderRadius: "4px",
                                color: "rgba(0,0,0,.65)",
                              }}
                            >
                              <p className="mb-0">
                                <b>{con.sender_name}</b>
                              </p>
                              <p className="mb-0" style={{ fontSize: "13px" }}>
                                {moment(con.created_at).format("LLLL")}
                              </p>
                              <hr
                                className="mt-2 mb-2"
                                style={{
                                  borderTop: "1px solid #adc6ff",
                                }}
                              />
                              <p className="mb-0">{con.message}</p>
                              {con.images.map((image, i) => {
                                return (
                                  <div className="mt-2">
                                    <div className="mb-0">
                                      <img
                                        style={{
                                          width: "175px",
                                          borderRadius: "5px",
                                        }}
                                        src={image}
                                        alt="..."
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </table>
                    );
                  }
                })}
              </div>
            </div>
            {getCase.data &&
              getCase.data.data &&
              getCase.data.data.status === "Closed" ? null : (
                <SingleCaseForm />
              )}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <Card
          className="gx-card"
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          <div>Loading...</div>
        </Card>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    getCase: state.ResolutionCenter.getCase,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResolutionConversationList)
);
