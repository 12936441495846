import React from "react";
import ShippingInformation from "../ShippingInformation/ShippingInformation";
import PaymentMethods from "../PaymentMethods/PaymentMethods";
import OrderReview from "../OrderReview/OrderReview";
import ShippingAddressSelect from "../ShippingAddressSelect/ShippingAddressSelect";
import CircularProgress from "../../../Common/Loader/CircularProgress/CircularProgress";

const CheckoutDetailsContainer = (props) => {
  const { shippingAddress } = props;
  const ShippingData =
    shippingAddress.data &&
    shippingAddress.data.data &&
    shippingAddress.data.data.shipping_addresses;
  const ShippingApioading = shippingAddress.loading;

  return (
    <React.Fragment>
      {ShippingApioading ? (
        <React.Fragment>
          <h5 className="ml-2 mb-3">Shopping Information</h5>
          <CircularProgress />
        </React.Fragment>
      ) : ShippingData.length !== 0 ? (
        <React.Fragment>
          <h5 className="ml-2 mb-3">Shopping Information</h5>
          <ShippingAddressSelect shippingAddress={shippingAddress.data} />
        </React.Fragment>
      ) : (
        <ShippingInformation isUpdate={false} />
      )}

      <PaymentMethods />
      <OrderReview />
    </React.Fragment>
  );
};

export default CheckoutDetailsContainer;
