import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userManagementActions } from "./ducks";
import UserSelections from "../../../components/Seller/UserManagement/UserSelections/UserSelections";
import UserContainer from "../../../components/Seller/UserManagement/UserContainer/UserContainer";
import UserSearchBar from "../../../components/Seller/UserManagement/UserSearchBar/UserSearchBar";
import { Card } from "antd";

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
      userRole: null,
    };
  }
  componentDidMount() {
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    const userRole = userObj.role;

    this.props.userManagementActions.getUserCount();
    this.setState({
      algolia,
      userRole,
    });
  }
  render() {
    const { match, userCount } = this.props;
    const { algolia, userRole } = this.state;

    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    if (algolia) {
      switch (type) {
        case "sellers":
          apiKey = algolia.all_sellers.api_key;
          index = algolia.all_sellers.index;
          break;
        case "buyers":
          apiKey = algolia.all_buyers.api_key;
          index = algolia.all_buyers.index;
          break;
        case "moderators":
          apiKey = algolia.all_moderators.api_key;
          index = algolia.all_moderators.index;
          break;
        case "admin":
          apiKey = algolia.all_admins.api_key;
          index = algolia.all_admins.index;
          break;
        default:
          apiKey = algolia.all_sellers.api_key;
          index = algolia.all_sellers.index;
      }
      return (
        <div>
          <div style={{ paddingBottom: "20px" }}>
            <h3>User Management</h3>{" "}
          </div>

          <Card className="gx-card">
            {/* User selections */}
            <UserSelections userCount={userCount} userRole={userRole} type={type} />

            {/* User Search bar */}
            <UserSearchBar />

            {/* User Container */}
            <UserContainer apiKey={apiKey} index={index} />
          </Card>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ paddingBottom: "20px" }}>
            {" "}
            <h3>User Management</h3>{" "}
          </div>

          {/* User selections */}
          <UserSelections userCount={userCount} userRole={userRole} />

          {/* User Search bar */}
          <UserSearchBar />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    userCount: state.UserManagement.userCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserManagement)
);
