import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Select, Input, Row, Col, Card, Spin } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dashboardProfileActions } from "../../../ducks";
import { makeField } from "components/Common/Controls/MakeField";
import {
  currencyTypes,
  FormItemLayout,
  FormItemLayout2,
  TailFormItemLayout,
} from "../../../../../../constants/Utilities";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);
class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerDetails: null,
    };
  }
  componentDidMount() {
    const { dashboardProfileActions, sellerDetails } = this.props;
    sellerDetails.loading && dashboardProfileActions.getSellerDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sellerDetails } = this.props;

    if (
      sellerDetails &&
      sellerDetails.data &&
      sellerDetails.data.data &&
      sellerDetails.data.data.account_info &&
      JSON.stringify(sellerDetails) !== JSON.stringify(prevState.sellerDetails)
    ) {
      var receivedObj = {
        holderName: sellerDetails.data.data.payment_details.account_name,
        accountNumber: sellerDetails.data.data.payment_details.account_no,
        accountType: sellerDetails.data.data.payment_details.bank_acc_type,
        bankName: sellerDetails.data.data.payment_details.bank_name,
        bankCode: sellerDetails.data.data.payment_details.bank_code,
        branchName: sellerDetails.data.data.payment_details.branch,
        branchCode: sellerDetails.data.data.payment_details.branch_code,
        currency: sellerDetails.data.data.payment_details.currency,
      };

      this.props.initialize(receivedObj);
      this.setState({
        sellerDetails: sellerDetails,
      });
    }
  }

  handleSubmit = (values) => {
    const { dashboardProfileActions } = this.props;

    var sellerPaymentInfoDto = {
      account_name: values && values.holderName,
      account_no: values && values.accountNumber,
      bank_acc_type: values && values.accountType,
      bank_name: values && values.bankName,
      bank_code: values && values.bankCode,
      branch: values && values.branchName,
      branch_code: values && values.branchCode,
      currency: values && values.currency,
    };
    dashboardProfileActions.updateSellerPayment(sellerPaymentInfoDto);
  };

  render() {
    const { handleSubmit, updateSellerPaymentInfo, sellerDetails } = this.props;
    let accountInfo =
      sellerDetails &&
      sellerDetails.data &&
      sellerDetails.data.data &&
      sellerDetails.data.data.account_info;

    return (
      <div className="gx-card p-3 pt-4">
        <Spin
          spinning={updateSellerPaymentInfo.pending || sellerDetails.loading}
        >
          <Form
            onSubmit={handleSubmit(this.handleSubmit)}
            className="jr-fld-catch-2"
          >
            <Card className="mb-3" style={{ backgroundColor: "rgb(250, 250, 250)" }}>
              <FormItem {...FormItemLayout} label="ID Seller">
                <span className="ant-form-text">
                  {accountInfo && accountInfo.id}
                </span>
              </FormItem>

              <FormItem {...FormItemLayout} label="Account Holder Name">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="holderName"
                  component={AInputField}
                  placeholder="Account Holder Name"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Account Number">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="accountNumber"
                  component={AInputField}
                  placeholder="Account Number"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Account Type">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="accountType"
                  component={ASelectField}
                  hasFeedback
                >
                  <Option value="Savings Account">Saving Account</Option>
                  <Option value="Current Account">Current Account</Option>
                </Field>
              </FormItem>

              <FormItem {...FormItemLayout} label="Bank Name">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="bankName"
                  component={AInputField}
                  placeholder="Bank Name"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Bank Code">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="bankCode"
                  component={AInputField}
                  placeholder="Bank Code"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Branch Name">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="branchName"
                  component={AInputField}
                  placeholder="Branch Name"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Branch Code">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="branchCode"
                  component={AInputField}
                  placeholder="Branch Code"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Currency">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="currency"
                  component={ASelectField}
                  hasFeedback
                >
                  {currencyTypes.map((currency, i) => {
                    return (
                      <Option key={i} value={currency.value}>
                        {currency.label}
                      </Option>
                    );
                  })}
                </Field>
              </FormItem>
            </Card>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="float-right">
                  <FormItem
                    {...TailFormItemLayout}
                    style={{ marginBottom: "0" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        width: "160px",
                      }}
                    >
                      Submit
                    </Button>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.holderName) {
    errors.holderName = "Account Holder Name is required";
  }
  if (!values.accountNumber) {
    errors.accountNumber = "Account Number is required";
  }
  if (!values.accountType) {
    errors.accountType = "Account Type is required";
  }
  if (!values.bankName) {
    errors.bankName = "Bank Name is required";
  }
  if (!values.bankCode) {
    errors.bankCode = "Bank Code is required";
  }
  if (!values.branchName) {
    errors.branchName = "Branch Name is required";
  }
  if (!values.branchCode) {
    errors.branchCode = "Branch Code is required";
  }
  if (!values.currency) {
    errors.currency = "Currency is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    sellerDetails: state.DashboardProfile.sellerDetails,
    updateSellerPaymentInfo: state.DashboardProfile.updateSellerPaymentInfo,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dashboardProfileActions: bindActionCreators(
      dashboardProfileActions,
      dispatch
    ),
  };
}
export default withRouter(
  reduxForm({
    form: "BankAccount",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(BankAccount))
);
