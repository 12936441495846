import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  signinUser: {
    ...initialStateModal,
    authType: null,
  },
  signupUser: {
    ...initialStateModal,
  },
  signoutUser: {
    signoutStatus: null,
  },
  currentUser: {
    ...initialStateModal,
    notifyCount: null,
  },
  upgradeAccount: {
    ...initialStateModal,
  },
  sellerRegister: {
    ...initialStateModal,
  },
  forgetPassword: {
    ...initialStateModal,
  },
  verifyResetPassword: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.SIGNIN_GOOGLE_USER]: (state, { payload }) => ({
      ...state,
      signinUser: {
        ...state.signinUser,
        loading: true,
        pending: true,
        authType: "GOOGLE",
      },
      currentUser: {
        ...state.currentUser,
        loginProcessPending: true,
      },
    }),
    [types.SIGNIN_FACEBOOK_USER]: (state, { payload }) => ({
      ...state,
      signinUser: {
        ...state.signinUser,
        loading: true,
        pending: true,
        authType: "FACEBOOK",
      },
      currentUser: {
        ...state.currentUser,
        loginProcessPending: true,
      },
    }),
    [types.SIGNIN_APPLE_USER]: (state, { payload }) => ({
      ...state,
      signinUser: {
        ...state.signinUser,
        loading: true,
        pending: true,
        authType: "APPLE",
      },
      currentUser: {
        ...state.currentUser,
        loginProcessPending: true,
      },
    }),
    // [types.SIGNIN_GOOGLE_USER]: (state, { payload }) => ({
    //   ...state,
    //   signinUser: {
    //     ...state.signinUser,
    //     loading: true,
    //     pending: true,
    //     authType: "GENERAL",
    //   },
    // }),

    [types.SIGNIN_EMAIL_USER]: (state, { payload }) => ({
      ...state,
      signinUser: {
        ...state.signinUser,
        loading: true,
        pending: true,
        authType: "EMAIL_PASSWORD",
      },
      currentUser: {
        ...state.currentUser,
        loginProcessPending: true,
      },
    }),
    [types.SIGNIN_USER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        signinUser: {
          ...state.signinUser,
          loading: false,
          pending: false,
          data: payload,
        },
        signoutUser: {
          signoutStatus: null,
        },
      };
    },

    [types.SIGNIN_USER_FAILED]: (state, { payload }) => {
      return {
        ...state,
        signinUser: {
          ...state.signinUser,
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
        currentUser: {
          ...state.currentUser,
          loginProcessPending: false,
        },
      };
    },
    //----------------------------------------------------------

    [types.SIGNOUT_USER]: (state, { payload }) => ({
      ...state,
    }),
    [types.SIGNOUT_USER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        signinUser: {
          ...initialStateModal,
          authType: null,
        },
        signoutUser: {
          signoutStatus: "SUCCESS",
        },
      };
    },

    [types.SIGNOUT_USER_FAILED]: (state, { payload }) => {
      return {
        ...state,
        signinUser: {
          ...state.signinUser,
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },
    //------------ GET CURRENT USER -----------------------
    [types.GET_CURRENT_USER]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_CURRENT_USER_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          loginProcessPending: false,
          loading: false,
          pending: false,
          data: data,
        },
      };
    },
    [types.GET_CURRENT_USER_FAILED]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        loginProcessPending: false,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------

    //------------ SIGN UP USER -----------------------
    [types.SIGNUP_USER]: (state, { payload }) => ({
      ...state,
      signupUser: {
        ...state.signupUser,
        loading: true,
        pending: true,
      },
    }),
    [types.SIGNUP_USER_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        signupUser: {
          ...state.signupUser,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.SIGNUP_USER_FAILED]: (state, { payload }) => ({
      ...state,
      signupUser: {
        ...state.signupUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //------------ UPGRADE ACCOUNT-----------------------
    [types.UPGRADE_BUYER_ACCOUNT]: (state, { payload }) => ({
      ...state,
      upgradeAccount: {
        ...state.upgradeAccount,
        loading: true,
        pending: true,
      },
    }),
    [types.UPGRADE_BUYER_ACCOUNT_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        upgradeAccount: {
          ...state.upgradeAccount,
          loading: false,
          pending: false,
          data: data,
        },
      };
    },
    [types.UPGRADE_BUYER_ACCOUNT_FAILED]: (state, { payload }) => ({
      ...state,
      upgradeAccount: {
        ...state.upgradeAccount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    //------------ UPGRADE ACCOUNT-----------------------
    [types.SELLER_REGISTER]: (state, { payload }) => ({
      ...state,
      sellerRegister: {
        ...state.sellerRegister,
        loading: true,
        pending: true,
      },
    }),
    [types.SELLER_REGISTER_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        sellerRegister: {
          ...state.sellerRegister,
          loading: false,
          pending: false,
          data: data,
        },
      };
    },
    [types.SELLER_REGISTER_FAIL]: (state, { payload }) => ({
      ...state,
      sellerRegister: {
        ...state.sellerRegister,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //-------------------------------------------------------
    [types.FORGET_PASSWORD]: (state, { payload }) => ({
      ...state,
      forgetPassword: {
        ...state.forgetPassword,
        loading: true,
        pending: true,
      },
    }),
    [types.FORGET_PASSWORD_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        forgetPassword: {
          ...state.forgetPassword,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.FORGET_PASSWORD_FAILED]: (state, { payload }) => {
      return {
        ...state,
        forgetPassword: {
          ...state.forgetPassword,
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },
    //-------------------------------------------------------
    //-------------------------VERIFY_RESET_PASSWORD------------------------------
    [types.VERIFY_RESET_PASSWORD]: (state, { payload }) => ({
      ...state,
      verifyResetPassword: {
        ...state.verifyResetPassword,
        loading: true,
        pending: true,
      },
    }),
    [types.VERIFY_RESET_PASSWORD_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        verifyResetPassword: {
          ...state.verifyResetPassword,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    [types.VERIFY_RESET_PASSWORD_FAILED]: (state, { payload }) => {
      return {
        ...state,
        verifyResetPassword: {
          ...state.verifyResetPassword,
          loading: false,
          pending: false,
          hasError: true,
          error: { payload },
        },
      };
    },
    //-------------------------------------------------------
  },
  initialState
);
