// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getSellerDetails: createAction(types.GET_SELLER_DETAILS),
  getSellerDetailsSuccess: createAction(types.GET_SELLER_DETAILS_SUCCESS),
  getSellerDetailsFail: createAction(types.GET_SELLER_DETAILS_FAIL),

  updateSellerBusinessInfo: createAction(types.UPDATE_SELLER_BUSINESS_INFO),
  updateSellerBusinessInfoSuccess: createAction(
    types.UPDATE_SELLER_BUSINESS_INFO_SUCCESS
  ),
  updateSellerBusinessInfoFail: createAction(
    types.UPDATE_SELLER_BUSINESS_INFO_FAIL
  ),

  updateSellerInfo: createAction(types.UPDATE_SELLER_INFO),
  updateSellerInfoSuccess: createAction(types.UPDATE_SELLER_INFO_SUCCESS),
  updateSellerInfoFail: createAction(types.UPDATE_SELLER_INFO_FAIL),

  updateSellerPayment: createAction(types.UPDATE_SELLER_PAYMENT_INFO),
  updateSellerPaymentSuccess: createAction(
    types.UPDATE_SELLER_PAYMENT_INFO_SUCCESS
  ),
  updateSellerPaymentFail: createAction(types.UPDATE_SELLER_PAYMENT_INFO_FAIL),
};
