import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Drawer, Layout } from "antd";
import SidebarContent from "./SidebarContent";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";

import { sidebarSettings } from "constants/Utilities";
import { cartActions } from "../../modules/Cart/ducks";
import { homeActions } from "../../modules/Home/ducks";

const { Sider } = Layout;

const { themeType } = sidebarSettings;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: "4379946a-9ce0-11ea-8e84-a112b8f334ec",
          name: "Business & Industrial",
          short_name: "Business",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FBusiness.jpg?alt=media",
        },
        {
          id: "4379bb3e-9ce0-11ea-8e84-a112b8f334ec",
          name: "Collectibles & Art",
          short_name: "Art",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FArt.jpg?alt=media",
        },
        {
          id: "4379e23c-9ce0-11ea-8e84-a112b8f334ec",
          name: "Electronics",
          short_name: "Electronics",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FElectronics.jpg?alt=media",
        },
        {
          id: "4379e24e-9ce0-11ea-8e84-a112b8f334ec",
          name: "Fashion",
          short_name: "Fashion",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FFashion.jpg?alt=media",
        },
        {
          id: "437a0964-9ce0-11ea-8e84-a112b8f334ec",
          name: "Home & Garden",
          short_name: "Home",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FHome.jpg?alt=media",
        },
        {
          id: "437a305d-9ce0-11ea-8e84-a112b8f334ec",
          name: "Motors",
          short_name: "Motors",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FMotors.jpg?alt=media",
        },
        {
          id: "437a3067-9ce0-11ea-8e84-a112b8f334ec",
          name: "Music",
          short_name: "Music",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FMusic.jpg?alt=media",
        },
        {
          id: "43826db3-9ce0-11ea-8e84-a112b8f334ec",
          name: "Others",
          short_name: "Other",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FOther.jpg?alt=media",
        },
        {
          id: "438294e6-9ce0-11ea-8e84-a112b8f334ec",
          name: "Sporting Goods",
          short_name: "Sports",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FSports.jpg?alt=media",
        },
        {
          id: "4382bbe5-9ce0-11ea-8e84-a112b8f334ec",
          name: "Toys & Hobbies",
          short_name: "Toys",
          sub_categories: [],
          banner:
            "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FToys.jpg?alt=media",
        },
      ],
    };
  }

  componentDidMount() {
    const { allCategories } = this.props;
    allCategories.pending && this.props.homeActions.getAllCategories();
  }
  onToggleCollapsedNav = () => {
    this.props.cartActions.sidebarToggle();
  };
  render() {
    const { isVisibleSidebar, allCategories } = this.props;
    return (
      <Sider
        className={"gx-app-sidebar gx-collapsed-sidebar"}
        trigger={null}
        collapsed={false}
        theme={"lite"}
        collapsible
      >
        <Drawer
          className={`gx-drawer-sidebar ${
            themeType !== THEME_TYPE_LITE ? "gx-drawer-sidebar-lite" : null
          }`}
          placement="left"
          closable={false}
          onClose={this.onToggleCollapsedNav}
          visible={isVisibleSidebar}
        >
          <SidebarContent
            data={allCategories.loading ? this.state.data : allCategories.data}
            onClose={this.onToggleCollapsedNav}
          />
        </Drawer>
      </Sider>
    );
  }
}

function mapStateToProps(state) {
  return {
    isVisibleSidebar: state.Cart.sidebarToggle.isVisibleSidebar,
    settingsUpdate: state.Cart.settingsUpdate,
    allCategories: state.Home.allCategories,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    homeActions: bindActionCreators(homeActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
