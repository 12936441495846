import React, { Component } from "react";
import { Col, Row } from "antd";
import { Card, Icon, message, Upload } from "antd";
import { uploadWithSignURL } from "./../../../../../services/storage.service";

import endPoints from "./../../../../../util/EndPoints";
import API from "./../../../../../util/HTTPClient";
import { auth } from "./../../../../../firebase/firebase";
import { Modal } from "antd";

class SellerLogo extends Component {
  current_user = JSON.parse(localStorage.getItem("user"));
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: this.current_user["image"],
      loading: false,
      confirmBefore: false,
    };
  }

  UpdateUserImage = (img_url) => {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged((user) => {
        if (!user) return reject(false);
        this.setState({ loading: true, imageUrl: null });
        user
          .updateProfile({ photoURL: img_url })
          .then(() => user.getIdToken(true))
          .then(() =>
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...this.current_user,
                image: img_url,
              })
            )
          )
          .then(() => resolve(img_url))
          .catch((err) => reject(err));
      });
    });
  };

  beforeUpload = (file, fileList) => {
    return new Promise(function (resolve, reject) {
      let isImage = [
        "image/jpeg",
        "image/jpg",
        "image/bmp",
        "image/png",
      ].includes(file.type);

      const isLt512K = file.size / 1024 <= 512;

      if (!isImage) {
        message.error("You can only upload Image file!");
        reject();
      }

      if (!isLt512K) {
        message.error("Image must smaller than 512KB!");
        reject();
      } else {
        Modal.confirm({
          title: "Are you sure to change the seller image?",
          content: "",
          okText: "Confirm",
          cancelText: "Cancel",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      }
    });
  };

  handleUpload = ({ onSuccess, onError, file }) => {
    API.Put(endPoints.GET_SELLER_LOGO_SIGNURL)
      .then((res) => res.data)
      .then(async (res) => {
        await uploadWithSignURL(
          res.data.signed_url,
          new Blob([file]),
          file.type
        );
        return res.data.url;
      })
      .then((image_url) => this.UpdateUserImage(image_url))
      .then((img_url) => {
        this.setState({ imageUrl: img_url });
        onSuccess(null, file);
      })
      .catch((err) => {
        console.error(err);
        onError(err);
      });
  };

  render() {
    const { isUpgradeAcc } = this.props;
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />

        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;

    return (
      <div>
        {isUpgradeAcc ? (
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={this.beforeUpload}
            customRequest={this.handleUpload}
          >
            {imageUrl ? <img src={imageUrl} alt="" /> : uploadButton}
          </Upload>
        ) : (
          <Row>
            <Col span={24}>
              <Card className="gx-card" title="">
                <h6 className="mb-3">Upload Your Logo</h6>
                <Card style={{ backgroundColor: "#fafafa" }} className="mb-0">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={this.beforeUpload}
                    customRequest={this.handleUpload}
                  >
                    {imageUrl ? <img src={imageUrl} alt="" /> : uploadButton}
                  </Upload>
                </Card>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default SellerLogo;
