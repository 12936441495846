import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  sellerDetails: {
    ...initialStateModal,
  },
  updateSellerBusinessInfo: {
    ...initialStateModal,
  },
  updateSellerInfo: {
    ...initialStateModal,
  },
  updateSellerPaymentInfo: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    // ********************************************//
    [types.GET_SELLER_DETAILS]: (state, { payload }) => ({
      ...state,
      sellerDetails: {
        ...state.sellerDetails,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerDetails: {
        ...state.sellerDetails,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_SELLER_DETAILS_FAIL]: (state, { payload }) => ({
      ...state,
      sellerDetails: {
        ...state.sellerDetails,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ********************************************//
    // ********************************************//
    [types.UPDATE_SELLER_BUSINESS_INFO]: (state, { payload }) => ({
      ...state,
      updateSellerBusinessInfo: {
        ...state.updateSellerBusinessInfo,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_SELLER_BUSINESS_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      updateSellerBusinessInfo: {
        ...state.updateSellerBusinessInfo,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.UPDATE_SELLER_BUSINESS_INFO_FAIL]: (state, { payload }) => ({
      ...state,
      updateSellerBusinessInfo: {
        ...state.updateSellerBusinessInfo,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ********************************************//
    // ********************************************//
    [types.UPDATE_SELLER_INFO]: (state, { payload }) => ({
      ...state,
      updateSellerInfo: {
        ...state.updateSellerInfo,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_SELLER_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      updateSellerInfo: {
        ...state.updateSellerInfo,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.UPDATE_SELLER_INFO_FAIL]: (state, { payload }) => ({
      ...state,
      updateSellerInfo: {
        ...state.updateSellerInfo,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ********************************************//
    // ********************************************//
    [types.UPDATE_SELLER_PAYMENT_INFO]: (state, { payload }) => ({
      ...state,
      updateSellerPaymentInfo: {
        ...state.updateSellerPaymentInfo,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_SELLER_PAYMENT_INFO_SUCCESS]: (state, { payload }) => ({
      ...state,
      updateSellerPaymentInfo: {
        ...state.updateSellerPaymentInfo,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.UPDATE_SELLER_PAYMENT_INFO_FAIL]: (state, { payload }) => ({
      ...state,
      updateSellerPaymentInfo: {
        ...state.updateSellerPaymentInfo,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ********************************************//
  },
  initialState
);
