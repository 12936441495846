import React from "react";
import { Col, Row } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonialsData } from "./similarProductData";
// import IntlMessages from "util/IntlMessages";
// import ContainerHeader from "components/ContainerHeader/index";
import SimilarProductCardBox from "./SimilarProductCardBox";
import SimilarProductCard from "./SimilarProductCard";
import "./SimilarProductCarousel.less";

// **********************************************
//         WHERE IN USE
//             cart
//             single product
// **********************************************

const SimilarProductCarousel = (props) => {
  const { product, isCart, relatedProducts, loading } = props;
  const options1 = {
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    dots: false,
    infinite: true,
    speed: 500,
    marginLeft: 10,
    marginRight: 10,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="gx-main-content">
      <Row>
        <Col span={24}>
          <SimilarProductCardBox styleName="gx-text-center slr-bcgd smlr-prdct reduce-pdng-2">
            <h5 className="mb-3 ml-2" style={{ textAlign: "start" }}>
              Similar Products
            </h5>

            {product ? (
              <Slider {...options1}>
                {product.data &&
                  product.data.data &&
                  product.data.data.related_products &&
                  product.data.data.related_products.map((prod, i) => {
                    return (
                      <SimilarProductCard
                        key={i}
                        loading={loading}
                        isCart={isCart}
                        testimonial={prod}
                      />
                    );
                  })}
              </Slider>
            ) : isCart ? (
              loading ? (
                <Slider {...options1}>
                  <SimilarProductCard
                    testimonial={testimonialsData[0]}
                    loading={loading}
                    isCart={isCart}
                  />
                  <SimilarProductCard
                    testimonial={testimonialsData[1]}
                    loading={loading}
                    isCart={isCart}
                  />
                  <SimilarProductCard
                    testimonial={testimonialsData[2]}
                    loading={loading}
                    isCart={isCart}
                  />
                  <SimilarProductCard
                    testimonial={testimonialsData[3]}
                    loading={loading}
                    isCart={isCart}
                  />
                  <SimilarProductCard
                    testimonial={testimonialsData[4]}
                    loading={loading}
                    isCart={isCart}
                  />
                  <SimilarProductCard
                    testimonial={testimonialsData[5]}
                    loading={loading}
                    isCart={isCart}
                  />
                  <SimilarProductCard
                    testimonial={testimonialsData[6]}
                    loading={loading}
                    isCart={isCart}
                  />
                </Slider>
              ) : (
                <Slider {...options1}>
                  {relatedProducts.data &&
                    relatedProducts.data.data &&
                    relatedProducts.data.data.map((prod, i) => {
                      return (
                        <SimilarProductCard
                          key={i}
                          testimonial={prod}
                          loading={loading}
                          isCart={isCart}
                        />
                      );
                    })}
                </Slider>
              )
            ) : (
              <Slider {...options1}>
                <SimilarProductCard
                  testimonial={testimonialsData[0]}
                  loading={loading}
                  isCart={isCart}
                />
                <SimilarProductCard
                  testimonial={testimonialsData[1]}
                  loading={loading}
                  isCart={isCart}
                />
                <SimilarProductCard
                  testimonial={testimonialsData[2]}
                  loading={loading}
                  isCart={isCart}
                />
                <SimilarProductCard
                  testimonial={testimonialsData[3]}
                  loading={loading}
                  isCart={isCart}
                />
                <SimilarProductCard
                  testimonial={testimonialsData[4]}
                  loading={loading}
                  isCart={isCart}
                />
                <SimilarProductCard
                  testimonial={testimonialsData[5]}
                  loading={loading}
                  isCart={isCart}
                />
                <SimilarProductCard
                  testimonial={testimonialsData[6]}
                  loading={loading}
                  isCart={isCart}
                />
              </Slider>
            )}
          </SimilarProductCardBox>
        </Col>
      </Row>
    </div>
  );
};

export default SimilarProductCarousel;
