const action_header = "reviewManagement/";

// Types
export default {
  REPLY_TO_REVIEW: action_header + "REPLY_TO_REVIEW",
  REPLY_TO_REVIEW_SUCCESS: action_header + "REPLY_TO_REVIEW_SUCCESS",
  REPLY_TO_REVIEW_FAIL: action_header + "GET_COVID19_DETAILS_FAIL",

  DELETE_REVIEW: action_header + "DELETE_REVIEW",
  DELETE_REVIEW_SUCCESS: action_header + "DELETE_REVIEW_SUCCESS",
  DELETE_REVIEW_FAIL: action_header + "DELETE_REVIEW_FAIL",

  DELETE_REVIEW_REPLY: action_header + "DELETE_REVIEW_REPLY",
  DELETE_REVIEW_REPLY_SUCCESS: action_header + "DELETE_REVIEW_REPLY_SUCCESS",
  DELETE_REVIEW_REPLY_FAIL: action_header + "DELETE_REVIEW_REPLY_FAIL",
};
