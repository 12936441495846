// import { createLogic } from "redux-logic";

// import actions from "./actions";
// import types from "./types";
// import API from "../../../util/HTTPClient";
// import EndPoints from "../../../util/EndPoints";

// const getProduct = createLogic({
//   type: types.SELECTED_SELLER_HANDLER,
//   latest: true,
//   debounce: 1000,

//   process({ MockHTTPClient, getState, action }, dispatch, done) {
//     let HTTPClient;
//     if (MockHTTPClient) {
//       HTTPClient = MockHTTPClient;
//     } else {
//       HTTPClient = API;
//     }
//     HTTPClient.Get(EndPoints.GET_PRODUCT + `/${action.payload.id}`)
//       .then((resp) => resp.data)
//       .then((data) => {
//         dispatch(actions.getProductSuccess(data));
//       })
//       .catch((err) => {
//         var errorMessage = "Failed to get regions";
//         if (err && err.code === "ECONNABORTED") {
//           errorMessage = "Please check your internet connection.";
//         }
//         dispatch(
//           actions.getProductFail({
//             title: "Error!",
//             message: errorMessage,
//           })
//         );
//       })
//       .then(() => done());
//   },
// });

export default [];
