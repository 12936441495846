import { IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST } from "../../../../constants";

const validate = (values) => {
  const errors = {};
  var numbers = /^[1-9]\d*(\.\d+)?$/;

  if (!values.productName) {
    errors.productName = "Product Name is required";
  }
  if (!values.productCategory) {
    errors.productCategory = "Product Category is required";
  }
  if (!values.productDescripton) {
    errors.productDescripton = "Product Description is required";
  }
  if (values.productPrice && !values.productPrice.match(numbers)) {
    errors.productPrice = "Product Price is invalid";
  }
  if (values.isProductVariable === "disable" && !values.productPrice) {
    errors.productPrice = "Product Price is required";
  }
  if (values.isProductVariable === "disable" && !values.productStock) {
    errors.productStock = "Product Availability is required";
  }
  if (values.discount && (values.discount > 100 || values.discount < 0)) {
    errors.discount = "Discount is invalid";
  }
  if (!values.warranty) {
    errors.warranty = "Warranty is required";
  }
  if (!values.packageType) {
    errors.packageType = "Package Type is required";
  }
  if (!values.packageWieght) {
    errors.packageWieght = "Package Wieght is required";
  }
  if (!values.packageLength) {
    errors.packageLength = "Package Length is required";
  }
  if (!values.packageWidth) {
    errors.packageWidth = "Package Width is required";
  }
  if (!values.packageHeight) {
    errors.packageHeight = "Package Height is required";
  }
  if (!values.dangerousGoods) {
    errors.dangerousGoods = "Dangerous Goods is required";
  }
  if (!values.itemCountry) {
    errors.itemCountry = "item Country is required";
  }
  if (!values.itemCity) {
    errors.itemCity = "Item City is required";
  }
  if (!values.itemZipCode) {
    errors.itemZipCode = "Item Zip Code is required";
  }
  if (
    values.internationalShippingMethod === "calculated" &&
    values.internationalShippingCarriers &&
    values.internationalShippingCarriers.length === 0
  ) {
    errors.internationalShippingCarriers =
      "International Shipping Carriers is required";
  }
  if (
    values.domesticShippingMethod !== "no_shipping" &&
    !values.domesticShippingEstimatedDeliveryDaysMin
  ) {
    errors.domesticShippingEstimatedDeliveryDaysMin =
      "Domestic shipping estimate min date is required";
  }
  if (
    values.domesticShippingMethod !== "no_shipping" &&
    !values.domesticShippingEstimatedDeliveryDaysMax
  ) {
    errors.domesticShippingEstimatedDeliveryDaysMax =
      "Domestic shipping estimate max date is required";
  }
  if (
    values.domesticShippingMethod !== "no_shipping" &&
    values.domesticShippingEstimatedDeliveryDaysMax &&
    values.domesticShippingEstimatedDeliveryDaysMin &&
    +values.domesticShippingEstimatedDeliveryDaysMin >
      +values.domesticShippingEstimatedDeliveryDaysMax
  ) {
    errors.domesticShippingEstimatedDeliveryDaysMax =
      "Domestic shipping estimate max date invalid";
  }
  if (
    values.domesticShippingAdditionalPrice &&
    values.domesticShippingAdditionalPrice < 0
  ) {
    errors.domesticShippingAdditionalPrice =
      "Domestic Shipping Additional Price is invalid";
  }
  if (
    IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST &&
    values.domesticShippingMethod === "flat_rated" &&
    !values.domesticShippingAdditionalPrice
  ) {
    errors.domesticShippingAdditionalPrice =
      "Domestic Shipping Additional Price is required";
  }
  if (
    values.domesticShippingMethod === "flat_rated" &&
    !values.domesticShippingPrice
  ) {
    errors.domesticShippingPrice = "Domestic Shipping Price is required";
  }
  if (values.domesticShippingPrice && values.domesticShippingPrice < 0) {
    errors.domesticShippingPrice = "Domestic Shipping Price is invalid";
  }
  if (
    (values.internationalShippingMethod === "free_shipping" ||
      values.internationalShippingMethod === "flat_rated") &&
    !values.internationalShippingEstimatedDeliveryDaysMin
  ) {
    errors.internationalShippingEstimatedDeliveryDaysMin =
      "International shipping estimate min date is required";
  }
  if (
    (values.internationalShippingMethod === "free_shipping" ||
      values.internationalShippingMethod === "flat_rated") &&
    !values.internationalShippingEstimatedDeliveryDaysMax
  ) {
    errors.internationalShippingEstimatedDeliveryDaysMax =
      "International shipping estimate max date is required";
  }
  if (
    IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST &&
    values.internationalShippingMethod === "flat_rated" &&
    !values.internationalShippingAdditionalPrice
  ) {
    errors.internationalShippingAdditionalPrice =
      "International shipping additional price is required";
  }
  if (
    values.internationalShippingMethod === "flat_rated" &&
    !values.internationalShippingPrice
  ) {
    errors.internationalShippingPrice =
      "International shipping price is required";
  }
  if (
    (values.internationalShippingMethod === "free_shipping" ||
      values.internationalShippingMethod === "flat_rated") &&
    values.internationalShippingEstimatedDeliveryDaysMax &&
    values.internationalShippingEstimatedDeliveryDaysMin &&
    +values.internationalShippingEstimatedDeliveryDaysMin >
      +values.internationalShippingEstimatedDeliveryDaysMax
  ) {
    errors.internationalShippingEstimatedDeliveryDaysMax =
      "International shipping estimate max date invalid";
  }
  if (!values.shippingHandlingDays) {
    errors.shippingHandlingDays = "Shipping handling days is required";
  }

  if (values.shippingHandlingDays && values.shippingHandlingDays < 0) {
    errors.shippingHandlingDays = "Shipping handling days invalid";
  }
  if (
    values.isProductVariable === "enable" &&
    values.variations &&
    values.variations.length === 0
  ) {
    errors.isProductVariable = "variations is required";
  }
  if (!values.productCondition) {
    errors.productCondition = "Product condition is required";
  }
  if (!values.domesticShippingMethod) {
    errors.domesticShippingMethod = "Domestic shipping method is required";
  }
  if (
    values.domesticShippingMethod !== "no_shipping" &&
    !values.domesticShippingTrackingAvailable
  ) {
    errors.domesticShippingTrackingAvailable =
      "Domestic shipping tracking avalability is required";
  }
  if (!values.internationalShippingMethod) {
    errors.internationalShippingMethod =
      "International shipping method is required";
  }
  if (
    (values.internationalShippingMethod !== "calculated" ||
      values.internationalShippingMethod !== "no_shipping") &&
    !values.domesticShippingTrackingAvailable
  ) {
    errors.internationalShippingTrackingAvailable =
      "International shipping tracking avalability is required";
  }
  if (!values.returnAcceptedMethod) {
    errors.returnAcceptedMethod = "Return accepted method is required";
  }
  return errors;
};

export default validate;
