// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  signinGoogleUser: createAction(types.SIGNIN_GOOGLE_USER),

  signinFacebookUser: createAction(types.SIGNIN_FACEBOOK_USER),

  signinTwitterUser: createAction(types.SIGNIN_TWITTER_USER),

  signinAppleUser: createAction(types.SIGNIN_APPLE_USER),

  signinEmailUser: createAction(types.SIGNIN_EMAIL_USER),

  signinUser: createAction(types.SIGNIN_USER),
  signinUserSuccess: createAction(types.SIGNIN_USER_SUCCESS),
  signinUserFail: createAction(types.SIGNIN_USER_FAILED),

  signoutUser: createAction(types.SIGNOUT_USER),
  signoutUserSuccess: createAction(types.SIGNOUT_USER_SUCCESS),
  signoutUserFail: createAction(types.SIGNOUT_USER_FAILED),

  signupUser: createAction(types.SIGNUP_USER),
  signupUserSuccess: createAction(types.SIGNUP_USER_SUCCESS),
  signupUserFail: createAction(types.SIGNUP_USER_FAILED),

  getCurrentUser: createAction(types.GET_CURRENT_USER),
  getCurrentUserSuccess: createAction(types.GET_CURRENT_USER_SUCCESS),
  getCurrentUserFail: createAction(types.GET_CURRENT_USER_FAILED),

  upgradeBuyerAccount: createAction(types.UPGRADE_BUYER_ACCOUNT),
  upgradeBuyerAccountSuccess: createAction(types.UPGRADE_BUYER_ACCOUNT_SUCCESS),
  upgradeBuyerAccountFail: createAction(types.UPGRADE_BUYER_ACCOUNT_FAIL),

  sellerRegister: createAction(types.SELLER_REGISTER),
  sellerRegisterSuccess: createAction(types.SELLER_REGISTER_SUCCESS),
  sellerRegisterFail: createAction(types.SELLER_REGISTER_FAIL),

  forgetPassword: createAction(types.FORGET_PASSWORD),
  forgetPasswordSuccess: createAction(types.FORGET_PASSWORD_SUCCESS),
  forgetPasswordFail: createAction(types.FORGET_PASSWORD_FAILED),

  verifyResetPassword: createAction(types.VERIFY_RESET_PASSWORD),
  verifyResetPasswordSuccess: createAction(types.VERIFY_RESET_PASSWORD_SUCCESS),
  verifyResetPasswordFail: createAction(types.VERIFY_RESET_PASSWORD_FAILED),
};
