import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Input, Tooltip, Icon } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { authActions } from "../ducks";
import { makeField } from "../../../components/Common/Controls/MakeField";
import LoginLoader from "../../../util/Loader/LoginLoader/LoginLoader";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const EmailField = makeField(Input);
class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("bckgrnd-img-2");
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("bckgrnd-img-2");
  }

  handleHomeNavigator = () => {
    this.props.history.push("/");
  };

  handleSubmit = (values) => {
    const { email } = values;
    this.props.authActions.forgetPassword({ email: email });
  };

  render() {
    const { handleSubmit, forgetPassword } = this.props;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container pdng-jr">
          <div className="gx-app-login-content mrgn-jr bckgrnd-lg">
            <div
              className="mb-3 login-logo"
              onClick={() => this.handleHomeNavigator()}
            >
              <img
                style={{ height: "45px" }}
                alt=""
                // src="/static/media/logo.fd441063.png"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
              />
            </div>
            <Form
              onSubmit={handleSubmit(this.handleSubmit)}
              className="gx-signin-form gx-form-row0 mt-4 log-placehldr"
            >
              <Field
                formItemLayout={formItemLayout}
                name="email"
                suffix={
                  <Tooltip title="Please type your email address">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                component={EmailField}
                placeholder="Email"
                hasFeedback
                autocomplete="on"
              />
              <FormItem>
                <Button
                  style={{ width: "80%" }}
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </FormItem>
              <br />
            </Form>
            <Link to="/login">
              <a href>Return to Login</a>
            </Link>
          </div>
          {forgetPassword.pending ? (
            <div className="gx-loader-view">
              <LoginLoader />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    forgetPassword: state.AuthStore.forgetPassword,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "Forgetpassword_form",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword))
);
