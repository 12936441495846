import React from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, Table, Spin } from "antd";
import { toAppendType } from "../../../util/services";
import { ReportManagementActions } from "../../../modules/SellerDashboard/ReportManagement/ducks";

class ReportList extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    loading: true,
    firstDate: null,
    secondDate: null,
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  exportHandler = () => {
    const { reportManagementActions, match } = this.props;
    const type = match.params && match.params.type;

    reportManagementActions.getExportDetail({
      type: type,
    });
  };

  render() {
    let { hits, exportDetail } = this.props;
    let isEmpty = hits && hits.length === 0;
    const columns = [
      {
        title: "Report",
        render: (text, record) => <div>{toAppendType(record.type,record.date)}</div>,
      },
      {
        title: "Orders",
        dataIndex: "count",
      },
      {
        title: "Commission",
        render: (text, record) => (
          <div>{record.commission_precentage + " %"}</div>
        ),
      },
      {
        title: "Total",
        dataIndex: "total",
      },
      {
        title: "Gross Total",
        dataIndex: "gross_total",
      },
    ];

    const title = (
      <div>
        <Button
          type="primary"
          style={{
            display: "inline-block",
            float: "right",
            marginBottom: "0px",
            width: "250px",
          }}
          disabled={isEmpty}
          onClick={this.exportHandler}
        >
          <i className="fad fa-file-excel mr-2"></i>
          Export as an Excel File
        </Button>
      </div>
    );
    return (
      <Spin spinning={exportDetail.pending}>
        <Card className="gx-card mb-2" title={title}>
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={hits}
            onChange={this.handleChange}
            pagination={false}
            bordered
          />
        </Card>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    exportDetail: state.ReportManagement.exportDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    reportManagementActions: bindActionCreators(
      ReportManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportList)
);
