import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  allProducts: {
    ...initialStateModal,
  },
  popularCarouselProducts: {
    ...initialStateModal,
  },
  latestCarouselProducts: {
    ...initialStateModal,
  },
  discountedProducts: {
    ...initialStateModal,
  },
  topSellers: {
    ...initialStateModal,
  },
  allCategories: {
    ...initialStateModal,
  },
  popularCategories: {
    ...initialStateModal,
  },
  topBrands: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.GET_PRODUCTS]: (state, { payload }) => ({
      ...state,
      allProducts: {
        ...state.allProducts,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_PRODUCTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allProducts: {
        ...state.allProducts,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_PRODUCTS_FAILED]: (state, { payload }) => ({
      ...state,
      allProducts: {
        ...state.allProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *************************************************
    [types.GET_LATEST_PRODUCT_CAROUSEL]: (state, { payload }) => {
      return {
        ...state,
        latestCarouselProducts: {
          ...state.latestCarouselProducts,
          loading: true,
          pending: true,
        },
      };
    },
    [types.GET_LATEST_PRODUCT_CAROUSEL_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        latestCarouselProducts: {
          ...state.latestCarouselProducts,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_LATEST_PRODUCT_CAROUSEL_FAILED]: (state, { payload }) => ({
      ...state,
      latestCarouselProducts: {
        ...state.latestCarouselProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *************************************************
    [types.GET_POPULAR_PRODUCT_CAROUSEL]: (state, { payload }) => ({
      ...state,
      popularCarouselProducts: {
        ...state.popularCarouselProducts,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_POPULAR_PRODUCT_CAROUSEL_SUCCESS]: (state, { payload }) => ({
      ...state,
      popularCarouselProducts: {
        ...state.popularCarouselProducts,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_POPULAR_PRODUCT_CAROUSEL_FAILED]: (state, { payload }) => ({
      ...state,
      popularCarouselProducts: {
        ...state.popularCarouselProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ********************** GET_DISCOUNTED_PRODUCTS ***************************
    [types.GET_DISCOUNTED_PRODUCTS]: (state, { payload }) => {
      return {
        ...state,
        discountedProducts: {
          ...state.discountedProducts,
          loading: true,
          pending: true,
        },
      };
    },
    [types.GET_DISCOUNTED_PRODUCTS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        discountedProducts: {
          ...state.discountedProducts,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_DISCOUNTED_PRODUCTS_FAILED]: (state, { payload }) => ({
      ...state,
      discountedProducts: {
        ...state.discountedProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ********************** GET_TOP_SELLERS ***************************
    [types.GET_TOP_SELLERS]: (state, { payload }) => {
      return {
        ...state,
        topSellers: {
          ...state.topSellers,
          loading: true,
          pending: true,
        },
      };
    },
    [types.GET_TOP_SELLERS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        topSellers: {
          ...state.topSellers,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_TOP_SELLERS_FAILED]: (state, { payload }) => ({
      ...state,
      topSellers: {
        ...state.topSellers,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ********************** GET_ALL_CATEGORIES ***************************
    [types.GET_ALL_CATEGORIES]: (state, { payload }) => {
      return {
        ...state,
        allCategories: {
          ...state.allCategories,
          loading: true,
          pending: true,
        },
      };
    },
    [types.GET_ALL_CATEGORIES_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        allCategories: {
          ...state.allCategories,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_ALL_CATEGORIES_FAILED]: (state, { payload }) => ({
      ...state,
      allCategories: {
        ...state.allCategories,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    // ********************** GET_POPULAR_CATEGORIES ***************************
    [types.GET_POPULAR_CATEGORIES]: (state, { payload }) => {
      return {
        ...state,
        popularCategories: {
          ...state.popularCategories,
          loading: true,
          pending: true,
        },
      };
    },
    [types.GET_POPULAR_CATEGORIES_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        popularCategories: {
          ...state.popularCategories,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_POPULAR_CATEGORIES_FAILED]: (state, { payload }) => ({
      ...state,
      popularCategories: {
        ...state.popularCategories,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ********************** GET_POPULAR_CATEGORIES ***************************
    [types.GET_TOP_BRANDS]: (state, { payload }) => ({
      ...state,
      topBrands: {
        ...state.topBrands,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_TOP_BRANDS_SUCCESS]: (state, { payload }) => ({
      ...state,
      topBrands: {
        ...state.topBrands,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_TOP_BRANDS_FAILED]: (state, { payload }) => ({
      ...state,
      topBrands: {
        ...state.topBrands,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *************************************************
  },
  initialState
);
