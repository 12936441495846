import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Card } from "antd";
import { withRouter, Link } from "react-router-dom";
import { resolutionCenterActions } from "../ducks";
import ResolutionConversationList from "../../../components/Buyer/ResolutionConversationList/ResolutionConversationList";

class SingleCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageList: [],
    };
  }

  componentDidMount() {
    var id = this.props.match.params && this.props.match.params.id;
    this.props.resolutionCenterActions.getCase({ id: id });
  }

  componentWillUnmount() {
    this.props.resolutionCenterActions.clearCase();
  }

  render() {
    const { getCase } = this.props;

    if (getCase.loading) {
      return (
        <div className="gx-container pt-4">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                style={{
                  backgroundColor: "rgb(250, 250, 250)",
                  height: "500px",
                }}
              >
                <h5 className="mb-5 ml-2">
                  Resolution Center {">"} Single Dispute
                </h5>
                <div
                  className="tr-wishlist-notify-container"
                  style={{ marginTop: "100px" }}
                >
                  {/* <div className="tr-address-loader"> </div> */}
                  <div className="tr-wishlist-notify-loading-spinner"> </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="gx-container pt-4">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                style={{ backgroundColor: "rgb(250, 250, 250)" }}
              >
                <h5 className="mb-3 ml-2">
                  <Link className="crd-fnt-jr" to="/resolution-center/all">
                    Resolution Center
                  </Link>
                  {" >"} Single Dispute
                </h5>
                <ResolutionConversationList />
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    getCase: state.ResolutionCenter.getCase,
    updateCaseImages: state.ResolutionCenter.updateCaseImages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SingleCase)
);
