const action_header = "cart/";
// Types
export default {
  GET_PRODUCT: action_header + "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: action_header + "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAILED: action_header + "GET_PRODUCT_FAILED",

  GET_RELATED_PRODUCTS_FOR_PRODUCT:
    action_header + "GET_RELATED_PRODUCTS_FOR_PRODUCT",
  GET_RELATED_PRODUCTS_FOR_PRODUCT_SUCCESS:
    action_header + "GET_RELATED_PRODUCTS_FOR_PRODUCT_SUCCESS",
  GET_RELATED_PRODUCTS_FOR_PRODUCT_FAILED:
    action_header + "GET_RELATED_PRODUCTS_FOR_PRODUCT_FAILED",

  GET_HOME_PRODUCTS: action_header + "GET_HOME_PRODUCTS",
  GET_HOME_PRODUCTS_SUCCESS: action_header + "GET_HOME_PRODUCTS_SUCCESS",
  GET_HOME_PRODUCTS_FAILED: action_header + "GET_HOME_PRODUCTS_FAILED",

  SELECT_ITEM: action_header + "SELECT_ITEM",
  CLEAR_SELECT_ITEM: action_header + "CLEAR_SELECT_ITEM",

  INITIALIZE_REDUX_CART: action_header + "INITIALIZE_REDUX_CART",
  INITIALIZE_REDUX_CHECKOUT_CART:
    action_header + "INITIALIZE_REDUX_CHECKOUT_CART",

  ADD_CART_ITEM: action_header + "ADD_CART_ITEM",
  UPDATE_EXIST_CART_ITEM: action_header + "UPDATE_EXIST_CART_ITEM",

  CHECKOUT_ITEMS: action_header + "CHECKOUT_ITEMS",

  REMOVE_CART_ITEM: action_header + "REMOVE_CART_ITEM",

  CART_SETTINGS_HANDLER: action_header + "CART_SETTINGS_HANDLER",

  GET_SHIPPING_COST: action_header + "GET_SHIPPING_COST",
  GET_SHIPPING_COST_SUCCESS: action_header + "GET_SHIPPING_COST_SUCCESS",
  GET_SHIPPING_COST_FAILED: action_header + "GET_SHIPPING_COST_FAILED",

  MAKE_PAYMENT: action_header + "MAKE_PAYMENT",
  MAKE_PAYMENT_SUCCESS: action_header + "MAKE_PAYMENT_SUCCESS",
  MAKE_PAYMENT_FAILED: action_header + "MAKE_PAYMENT_FAILED",

  MAKE_AUTHORIZE_PAYMENT: action_header + "MAKE_AUTHORIZE_PAYMENT",
  MAKE_AUTHORIZE_PAYMENT_SUCCESS:
    action_header + "MAKE_AUTHORIZE_PAYMENT_SUCCESS",
  MAKE_AUTHORIZE_PAYMENT_FAILED:
    action_header + "MAKE_AUTHORIZE_PAYMENT_FAILED",

  SECURE_ACCEPTANCE_HANDLER: action_header + "SECURE_ACCEPTANCE_HANDLER",

  SELECT_SHIPPING_METHOD: action_header + "SELECT_SHIPPING_METHOD",

  PRODUCT_USER_SELECTIONS_HANDLER:
    action_header + "PRODUCT_USER_SELECTIONS_HANDLER",

  ADD_USER_SELECTIONS: action_header + "ADD_USER_SELECTIONS",
  CLEAR_USER_SELECTIONS: action_header + "CLEAR_USER_SELECTIONS",

  CONFIRM_SHIPPING_INFO: action_header + "CONFIRM_SHIPPING_INFO",
  CONFIRM_CARD_INFO: action_header + "CONFIRM_CARD_INFO",

  UPDATE_SHIPPING_COST: action_header + "UPDATE_SHIPPING_COST",
  UPDATE_SHIPPING_COST_SUCCESS: action_header + "UPDATE_SHIPPING_COST_SUCCESS",
  UPDATE_SHIPPING_COST_FAILED: action_header + "UPDATE_SHIPPING_COST_FAILED",

  SELECT_VARIATIONS: action_header + "SELECT_VARIATIONS",
  DESELECT_VARIATIONS: action_header + "DESELECT_VARIATIONS",

  ADD_WISHLIST_ITEM: action_header + "ADD_WISHLIST_ITEM",
  ADD_WISHLIST_ITEM_SUCCESS: action_header + "ADD_WISHLIST_ITEM_SUCCESS",
  ADD_WISHLIST_ITEM_FAILED: action_header + "ADD_WISHLIST_ITEM_FAILED",

  REMOVE_WISHLIST_ITEM: action_header + "REMOVE_WISHLIST_ITEM",
  REMOVE_WISHLIST_ITEM_SUCCESS: action_header + "REMOVE_WISHLIST_ITEM_SUCCESS",
  REMOVE_WISHLIST_ITEM_FAILED: action_header + "REMOVE_WISHLIST_ITEM_FAILED",

  GET_RELATED_PRODUCTS: action_header + "GET_RELATED_PRODUCTS",
  GET_RELATED_PRODUCTS_SUCCESS: action_header + "GET_RELATED_PRODUCTS_SUCCESS",
  GET_RELATED_PRODUCTS_FAILED: action_header + "GET_RELATED_PRODUCTS_FAILED",

  ADD_PRODUCT_FAQS: action_header + "ADD_PRODUCT_FAQS",
  ADD_PRODUCT_FAQS_SUCCESS: action_header + "ADD_PRODUCT_FAQS_SUCCESS",
  ADD_PRODUCT_FAQS_FAIL: action_header + "ADD_PRODUCT_FAQS_FAIL",

  PRODUCT_QUANTITY_CHANGE_IN_CART:
    action_header + "PRODUCT_QUANTITY_CHANGE_IN_CART",
  PRODUCT_QUANTITY_CHANGE_IN_CART_SUCCESS:
    action_header + "PRODUCT_QUANTITY_CHANGE_IN_CART_SUCCESS",
  PRODUCT_QUANTITY_CHANGE_IN_CART_FAILED:
    action_header + "PRODUCT_QUANTITY_CHANGE_IN_CART_FAILED",

  REMOVE_PRODUCT_FROM_CART: action_header + "REMOVE_PRODUCT_FROM_CART",
  REMOVE_PRODUCT_FROM_CART_SUCCESS:
    action_header + "REMOVE_PRODUCT_FROM_CART_SUCCESS",
  REMOVE_PRODUCT_FROM_CART_FAILED:
    action_header + "REMOVE_PRODUCT_FROM_CART_FAILED",

  PRODUCTS_SELECT_IN_CART: action_header + "PRODUCTS_SELECT_IN_CART",
  PRODUCTS_SELECT_IN_CART_SUCCESS:
    action_header + "PRODUCTS_SELECT_IN_CART_SUCCESS",
  PRODUCTS_SELECT_IN_CART_FAILED:
    action_header + "PRODUCTS_SELECT_IN_CART_FAILED",

  CHECKOUT_CALCULATION: action_header + "CHECKOUT_CALCULATION",
  CHECKOUT_CALCULATION_SUCCESS: action_header + "CHECKOUT_CALCULATION_SUCCESS",
  CHECKOUT_CALCULATION_FAILED: action_header + "CHECKOUT_CALCULATION_FAILED",

  //RESET
  RESET_CHECKOUT_ITEMS: action_header + "RESET_CHECKOUT_ITEMS",

  //Sidebar
  SIDEBAR_TOGGLE: action_header + "SIDEBAR_TOGGLE",
  SELLER_SIDEBAR_TOGGLE: action_header + "SELLER_SIDEBAR_TOGGLE",

  //Settings
  SETTINGS_UPDATE: action_header + "SETTINGS_UPDATE",

  //Payment type handle
  PAYMENT_METHOD_HANDLE: action_header + "PAYMENT_METHOD_HANDLE",

  //clear product info
  CLEAR_PRODUCT_INFO: action_header + "CLEAR_PRODUCT_INFO",

  // check product quantity
  CHECK_ORDER_QUANTITY: action_header + "CHECK_ORDER_QUANTITY",
  CHECK_ORDER_QUANTITY_SUCCESS: action_header + "CHECK_ORDER_QUANTITY_SUCCESS",
  CHECK_ORDER_QUANTITY_FAILED: action_header + "CHECK_ORDER_QUANTITY_FAILED",
};
