import React from "react";
import { Layout } from "antd";
import {
  Configure,
  connectHits,
  connectStateResults,
  InstantSearch,
} from "react-instantsearch-dom";
import "instantsearch.css/themes/algolia.css";
import QnAList from "../QnAList/QnAList";
import { initAlgoliaClient } from "../../../../../services/algolia.service";

const { Content } = Layout;

const ProductQnAContainer = (props) => {
  const algolia_api_key = props.algolia_api_key || {};
  const searchClient = initAlgoliaClient(algolia_api_key.api_key);
  return (
    <InstantSearch
      className="gx-main-content"
      indexName={algolia_api_key.index}
      searchState={props.searchState}
      createURL={props.createURL}
      searchClient={searchClient}
      onSearchStateChange={props.onSearchStateChange}
    >
      <Configure hitsPerPage={5} />

      <Layout className="gx-algolia-layout-has-sider">
        <Content>
          <CustomResults
            isMine={props.isMine}
            isOwnProduct={props.isOwnProduct}
          />
        </Content>
      </Layout>
    </InstantSearch>
  );
};

const CustomResults = connectStateResults(
  ({ searchState, searchResult, isMine, isOwnProduct }) => {
    if (searchResult && searchResult.nbHits === 0) {
      return (
        <div className="gx-algolia-content-inner">
          <div className="gx-algolia-no-results">
            No results found matching{" "}
            <span className="gx-algolia-query">{searchState.query}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="gx-algolia-content-inner">
          <ConnectedProducts isMine={isMine} isOwnProduct={isOwnProduct} />
        </div>
      );
    }
  }
);

const ConnectedProducts = connectHits(QnAList);

export default ProductQnAContainer;
