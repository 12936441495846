// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getShippingAddress: createAction(types.GET_SHIPPING_ADDRESS),
  getShippingAddressSuccess: createAction(types.GET_SHIPPING_ADDRESS_SUCCESS),
  getShippingAddressFail: createAction(types.GET_SHIPPING_ADDRESS_FAILED),

  addShippingAddress: createAction(types.ADD_SHIPPING_ADDRESS),
  addShippingAddressSuccess: createAction(types.ADD_SHIPPING_ADDRESS_SUCCESS),
  addShippingAddressFail: createAction(types.ADD_SHIPPING_ADDRESS_FAILED),

  updateShippingAddress: createAction(types.UPDATE_SHIPPING_ADDRESS),
  updateShippingAddressSuccess: createAction(
    types.UPDATE_SHIPPING_ADDRESS_SUCCESS
  ),
  updateShippingAddressFail: createAction(types.UPDATE_SHIPPING_ADDRESS_FAILED),

  deleteShippingAddress: createAction(types.DELETE_SHIPPING_ADDRESS),
  deleteShippingAddressSuccess: createAction(
    types.DELETE_SHIPPING_ADDRESS_SUCCESS
  ),
  deleteShippingAddressFail: createAction(types.DELETE_SHIPPING_ADDRESS_FAILED),

  addOrderAddress: createAction(types.ADD_ORDER_ADDRESS),
  removeOrderAddress: createAction(types.REMOVE_ORDER_ADDRESS),
  editOrderAddress: createAction(types.EDIT_ORDER_ADDRESS),

  getProfileDetails: createAction(types.GET_PROFILE_DETAILS),
  getProfileDetailsSuccess: createAction(types.GET_PROFILE_DETAILS_SUCCESS),
  getProfileDetailsFail: createAction(types.GET_PROFILE_DETAILS_FAILED),

  addOrderReview: createAction(types.ADD_ORDER_REVIEW),
  addOrderReviewSuccess: createAction(types.ADD_ORDER_REVIEW_SUCCESS),
  addOrderReviewFail: createAction(types.ADD_ORDER_REVIEWSS_FAILED),

  getBuyerOrderCount: createAction(types.GET_BUYER_ORDER_COUNT),
  getBuyerOrderCountSuccess: createAction(types.GET_BUYER_ORDER_COUNT_SUCCESS),
  getBuyerOrderCountFail: createAction(types.GET_BUYER_ORDER_COUNT_FAILED),

  selectedShippingAddressHandler: createAction(
    types.SELECTED_SHIPPING_ADDRESS_HANDLER
  ),
};
