export const MainNavbarCategories = [
  {
    id: "4379946a-9ce0-11ea-8e84-a112b8f334ec",
    name: "Business & Industrial",
    short_name: "Business",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FBusiness.jpg?alt=media",
  },
  {
    id: "4379bb3e-9ce0-11ea-8e84-a112b8f334ec",
    name: "Collectibles & Art",
    short_name: "Art",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FArt.jpg?alt=media",
  },
  {
    id: "4379e23c-9ce0-11ea-8e84-a112b8f334ec",
    name: "Electronics",
    short_name: "Electronics",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FElectronics.jpg?alt=media",
  },
  {
    id: "4379e24e-9ce0-11ea-8e84-a112b8f334ec",
    name: "Fashion",
    short_name: "Fashion",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FFashion.jpg?alt=media",
  },
  {
    id: "437a0964-9ce0-11ea-8e84-a112b8f334ec",
    name: "Home & Garden",
    short_name: "Home",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FHome.jpg?alt=media",
  },
  {
    id: "437a305d-9ce0-11ea-8e84-a112b8f334ec",
    name: "Motors",
    short_name: "Motors",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FMotors.jpg?alt=media",
  },
  {
    id: "437a3067-9ce0-11ea-8e84-a112b8f334ec",
    name: "Music",
    short_name: "Music",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FMusic.jpg?alt=media",
  },
  {
    id: "43826db3-9ce0-11ea-8e84-a112b8f334ec",
    name: "Others",
    short_name: "Other",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FOther.jpg?alt=media",
  },
  {
    id: "438294e6-9ce0-11ea-8e84-a112b8f334ec",
    name: "Sporting Goods",
    short_name: "Sports",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FSports.jpg?alt=media",
  },
  {
    id: "4382bbe5-9ce0-11ea-8e84-a112b8f334ec",
    name: "Toys & Hobbies",
    short_name: "Toys",
    sub_categories: [],
    banner:
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/categories%2Fbanners%2FToys.jpg?alt=media",
  },
];

export const CategoryData = [
  {
    value: "4379946a-9ce0-11ea-8e84-a112b8f334ec",
    label: "Business & Industrial",
    children: [
      {
        value: "43796ce7-9ce0-11ea-8e84-a112b8f334ec",
        label: "Agriculture & Forestry",
        children: [
          {
            value: "43796ce8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Agriculture Forestry Equipment",
          },
          {
            value: "43796ce9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Agriculture Forestry Wholesale",
          },
          {
            value: "4379e26f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Forestry Equipment Other",
          },
          {
            value: "437a0934-9ce0-11ea-8e84-a112b8f334ec",
            label: "GPS & Guidance Equipment",
          },
          {
            value: "437a09b7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Livestock Supplies",
          },
        ],
      },
      {
        value: "4379bb52-9ce0-11ea-8e84-a112b8f334ec",
        label: "Construction",
        children: [
          {
            value: "43799466-9ce0-11ea-8e84-a112b8f334ec",
            label: "Building Materials & Supplies",
          },
          {
            value: "43799468-9ce0-11ea-8e84-a112b8f334ec",
            label: "Buildings Modular & PreFab",
          },
          {
            value: "4379bb54-9ce0-11ea-8e84-a112b8f334ec",
            label: "Construction Wholesale Lots",
          },
          {
            value: "437a0957-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heavy Equipment",
          },
          {
            value: "437a0958-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heavy Equipment Attachments",
          },
          {
            value: "437a095a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heavy Equipment Parts & Accessories",
          },
          {
            value: "437a09ad-9ce0-11ea-8e84-a112b8f334ec",
            label: "Levels & Surveying Equipment",
          },
          {
            value: "437a09b0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Light Equipment & Tools",
          },
          {
            value: "437a304d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mining Equipment",
          },
          {
            value: "437a3061-9ce0-11ea-8e84-a112b8f334ec",
            label: "MRO & Industrial Supplies",
          },
          {
            value: "438246a7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Construction",
          },
          {
            value: "43826e0b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Protective Gear",
          },
          {
            value: "4382bc11-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Construction",
          },
        ],
      },
      {
        value: "4379e236-9ce0-11ea-8e84-a112b8f334ec",
        label: "Electrical & Test Equipment",
        children: [
          {
            value: "4379bb27-9ce0-11ea-8e84-a112b8f334ec",
            label: "Circuit Breakers & Fuses",
          },
          {
            value: "4379bb51-9ce0-11ea-8e84-a112b8f334ec",
            label: "Connectors, Switches & Wire",
          },
          {
            value: "4379e237-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electrical Supply Equipment",
          },
          {
            value: "4379e239-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electronic Components",
          },
          {
            value: "4382469e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "4382bbc7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Test, Measurement & Inspection",
          },
          {
            value: "4382bbcf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Thermal Management",
          },
          {
            value: "4382e2c8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "4379e276-9ce0-11ea-8e84-a112b8f334ec",
        label: "Fuel & Energy",
        children: [
          {
            value: "437993f3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Alternative Fuel & Energy",
          },
          {
            value: "437993f4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Alternative Fuel & Energy",
          },
          {
            value: "4382468b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Oil & Gas",
          },
          {
            value: "43826d91-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Fuel & Energy",
          },
          {
            value: "43826d92-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Fuel & Energy - Fuel & Energy Power & Utilities -",
          },
        ],
      },
      {
        value: "437a0920-9ce0-11ea-8e84-a112b8f334ec",
        label: "General Office",
        children: [
          {
            value: "437a3045-9ce0-11ea-8e84-a112b8f334ec",
            label: "Microfilm & Microfiche",
          },
          {
            value: "43824688-9ce0-11ea-8e84-a112b8f334ec",
            label: "Office Equipment",
          },
          {
            value: "43824689-9ce0-11ea-8e84-a112b8f334ec",
            label: "Office Furniture",
          },
          {
            value: "4382468a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Office Supplies",
          },
          {
            value: "43826d9e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Office Supplies",
          },
          {
            value: "43826dbc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Packing and Shipping Supplies",
          },
          {
            value: "43826dff-9ce0-11ea-8e84-a112b8f334ec",
            label: "Presentation, A/V & Projectors",
          },
          {
            value: "4382bbc1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Telecom Systems",
          },
          {
            value: "4382bbea-9ce0-11ea-8e84-a112b8f334ec",
            label: "Trade Show Displays",
          },
          {
            value: "4382e2c6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "437a0950-9ce0-11ea-8e84-a112b8f334ec",
        label: "Healthcare, Lab & Life",
        children: [
          {
            value: "4379bb6d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dental",
          },
          {
            value: "437a097c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Imaging & Aesthetics Equipment",
          },
          {
            value: "437a097d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Imaging & Aesthetics Supplies",
          },
          {
            value: "437a09a0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lab Supplies",
          },
          {
            value: "437a3035-9ce0-11ea-8e84-a112b8f334ec",
            label: "Medical Equipment",
          },
          {
            value: "437a3036-9ce0-11ea-8e84-a112b8f334ec",
            label: "Medical Instruments",
          },
          {
            value: "437a3037-9ce0-11ea-8e84-a112b8f334ec",
            label: "Medical Specialties",
          },
          {
            value: "437a3038-9ce0-11ea-8e84-a112b8f334ec",
            label: "Medical Supplies & Disposables",
          },
          {
            value: "4382469f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other - Healthcare, Lab & Life Sciences",
          },
          {
            value: "4382e2ca-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots - Healthcare, Lab & Life Sciences",
          },
        ],
      },
      {
        value: "437a0955-9ce0-11ea-8e84-a112b8f334ec",
        label: "Heavy Equipemt Attachments",
        children: [
          {
            value: "437993fa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Antique & Vintage Implements",
          },
          {
            value: "4379940d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Augers",
          },
          {
            value: "43799418-9ce0-11ea-8e84-a112b8f334ec",
            label: "Backhoe Attachments",
          },
          {
            value: "4379941f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bale Spears",
          },
          {
            value: "43799420-9ce0-11ea-8e84-a112b8f334ec",
            label: "Balers",
          },
          {
            value: "43799456-9ce0-11ea-8e84-a112b8f334ec",
            label: "Box Blades & Snow Plows",
          },
          {
            value: "43799463-9ce0-11ea-8e84-a112b8f334ec",
            label: "Buckets & Accessories",
          },
          {
            value: "4379bb60-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cultivators",
          },
          {
            value: "4379e213-9ce0-11ea-8e84-a112b8f334ec",
            label: "Disc Harrows",
          },
          {
            value: "4379e272-9ce0-11ea-8e84-a112b8f334ec",
            label: "Forks",
          },
          {
            value: "437a0938-9ce0-11ea-8e84-a112b8f334ec",
            label: "Grain Equipment",
          },
          {
            value: "437a094a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Harvesting Equipment",
          },
          {
            value: "437a094b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hay Rakes",
          },
          {
            value: "437a095f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hitches",
          },
          {
            value: "437a098b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Irrigation Equipment",
          },
          {
            value: "437a09c8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manure Spreaders",
          },
          {
            value: "437a09d1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Material Handling Equipment",
          },
          {
            value: "437a305f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mower Decks",
          },
          {
            value: "437a3060-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mowers & Mower Conditioners",
          },
          {
            value: "43826d94-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Heavy Equip Attachments",
          },
          {
            value: "43826de0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Planters",
          },
          {
            value: "43826de8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Plows",
          },
          {
            value: "43826df1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Post Hole Diggers",
          },
          {
            value: "43826e4f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Seeders & Spreaders",
          },
          {
            value: "438294c7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Snow Blowers",
          },
          {
            value: "438294ec-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sprayers",
          },
          {
            value: "4382bbd3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tillers",
          },
        ],
      },
      {
        value: "437a0956-9ce0-11ea-8e84-a112b8f334ec",
        label: "Heavy Equipment",
        children: [
          {
            value: "437993f9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Antique & Vintage Farm Equipment",
          },
          {
            value: "43799409-9ce0-11ea-8e84-a112b8f334ec",
            label: "Asphalt & Concrete Pavers",
          },
          {
            value: "43799419-9ce0-11ea-8e84-a112b8f334ec",
            label: "Backhoe Loaders",
          },
          {
            value: "4379bb26-9ce0-11ea-8e84-a112b8f334ec",
            label: "Chemical & Petrochemical Equipment",
          },
          {
            value: "4379bb40-9ce0-11ea-8e84-a112b8f334ec",
            label: "Combines",
          },
          {
            value: "4379bb5c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cranes",
          },
          {
            value: "4379bb5d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Crawler Dozers & Loaders",
          },
          {
            value: "4379e211-9ce0-11ea-8e84-a112b8f334ec",
            label: "Directional Drills",
          },
          {
            value: "4379e227-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drilling & Tapping Machines",
          },
          {
            value: "4379e231-9ce0-11ea-8e84-a112b8f334ec",
            label: "EDM Machines",
          },
          {
            value: "4379e247-9ce0-11ea-8e84-a112b8f334ec",
            label: "Excavators",
          },
          {
            value: "4379e256-9ce0-11ea-8e84-a112b8f334ec",
            label: "Finishing Machines",
          },
          {
            value: "4379e271-9ce0-11ea-8e84-a112b8f334ec",
            label: "Forklifts",
          },
          {
            value: "437a0937-9ce0-11ea-8e84-a112b8f334ec",
            label: "Graders",
          },
          {
            value: "437a093d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Grinding Machines",
          },
          {
            value: "437a0953-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heating & Cooling Equipment",
          },
          {
            value: "437a09d0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Material Handling & Processing",
          },
          {
            value: "437a3040-9ce0-11ea-8e84-a112b8f334ec",
            label: "Metal Bending Machines",
          },
          {
            value: "437a3043-9ce0-11ea-8e84-a112b8f334ec",
            label: "Metalworking Lathes",
          },
          {
            value: "437a304b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Milling Machines",
          },
          {
            value: "438246a9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Equipment",
          },
          {
            value: "43826e00-9ce0-11ea-8e84-a112b8f334ec",
            label: "Press Brakes",
          },
          {
            value: "43826e0f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Punch Presses",
          },
          {
            value: "43826e2e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Riding Compactors & Rollers",
          },
          {
            value: "43826e2f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Riding Trenchers",
          },
          {
            value: "43826e43-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scissor & Boom Lifts",
          },
          {
            value: "43826e48-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scrapers",
          },
          {
            value: "438294a1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sheet Metal Shears",
          },
          {
            value: "438294bd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Skid Steer Loaders",
          },
          {
            value: "4382bbe9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tractors",
          },
          {
            value: "4382bbed-9ce0-11ea-8e84-a112b8f334ec",
            label: "Trailers",
          },
          {
            value: "4382bbfc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Utility Vehicles",
          },
          {
            value: "4382e2c2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wheel Loaders",
          },
          {
            value: "4382e2d5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wood Chippers & Stump Grinders",
          },
        ],
      },
      {
        value: "437a0959-9ce0-11ea-8e84-a112b8f334ec",
        label: "Heavy Equipment Parts",
        children: [
          {
            value: "437993f8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Antique & Vintage Equipment",
          },
          {
            value: "437993fb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Antique & Vintage Manuals",
          },
          {
            value: "4379e24c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Farm Implement Parts",
          },
          {
            value: "4379e270-9ce0-11ea-8e84-a112b8f334ec",
            label: "Forklift Parts & Accessories",
          },
          {
            value: "437a09c0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manuals & Books",
          },
          {
            value: "437a09c7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manufacturing Equipment Parts",
          },
          {
            value: "437a3042-9ce0-11ea-8e84-a112b8f334ec",
            label: "Metalworking Equipment Parts",
          },
          {
            value: "43826d95-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Heavy Equip Parts Accessories",
          },
          {
            value: "4382bbe8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tractor Parts",
          },
        ],
      },
      {
        value: "437a0981-9ce0-11ea-8e84-a112b8f334ec",
        label: "Industrial Automation",
        children: [
          {
            value: "4379bb4b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Component Sensors",
          },
          {
            value: "4379bb56-9ce0-11ea-8e84-a112b8f334ec",
            label: "Control Systems & PLCs",
          },
          {
            value: "4379e229-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drives & Motion Control",
          },
          {
            value: "437a0982-9ce0-11ea-8e84-a112b8f334ec",
            label: "Industrial Contactors, Relays & Starters",
          },
          {
            value: "437a0983-9ce0-11ea-8e84-a112b8f334ec",
            label: "Industrial Electric Motors",
          },
          {
            value: "437a0984-9ce0-11ea-8e84-a112b8f334ec",
            label: "Industrial Mechanical Power Transmission",
          },
          {
            value: "437a0985-9ce0-11ea-8e84-a112b8f334ec",
            label: "Industrial Robotic Arms",
          },
          {
            value: "437a09b3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Linear Motion",
          },
          {
            value: "437a09b9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Machinery Signals & Indicators",
          },
          {
            value: "438246a2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Automation Equipment",
          },
        ],
      },
      {
        value: "437a09af-9ce0-11ea-8e84-a112b8f334ec",
        label: "Light Equipment & Tools",
        children: [
          {
            value: "43796cea-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Compressor Parts & Accessories",
          },
          {
            value: "43796cec-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Compressors",
          },
          {
            value: "43796cf0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Tools",
          },
          {
            value: "4379945d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Breakers & Demolition Hammers",
          },
          {
            value: "4379bb4a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Compactors Walk Behind",
          },
          {
            value: "4379bb4f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Concrete Stamps Forms & Forms Mats",
          },
          {
            value: "4379bb50-9ce0-11ea-8e84-a112b8f334ec",
            label: "Concrete Vibrators",
          },
          {
            value: "4379e228-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drills & Hammers",
          },
          {
            value: "4379e22d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drywall Tools",
          },
          {
            value: "437a0921-9ce0-11ea-8e84-a112b8f334ec",
            label: "Generator Parts & Accessories",
          },
          {
            value: "437a0923-9ce0-11ea-8e84-a112b8f334ec",
            label: "Generators",
          },
          {
            value: "437a093c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Grinders Professional",
          },
          {
            value: "437a0946-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hand Tools",
          },
          {
            value: "437a0951-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heat Guns",
          },
          {
            value: "437a0952-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heaters Jobsite",
          },
          {
            value: "437a0961-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hoists",
          },
          {
            value: "437a3052-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mixers Concrete & Mortar",
          },
          {
            value: "43826d9b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Light Equipment & Tools",
          },
          {
            value: "43826da7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Soldering, Desoldering & Rework Accessories",
          },
          {
            value: "43826dc1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Painting Equipment & Supplies",
          },
          {
            value: "43826dde-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pipe & Tubing Benders",
          },
          {
            value: "43826ddf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pipe Threaders & Dies",
          },
          {
            value: "43826df6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Powder Actuated Tools",
          },
          {
            value: "43826e35-9ce0-11ea-8e84-a112b8f334ec",
            label: "Routers",
          },
          {
            value: "43826e3d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sanders",
          },
          {
            value: "43826e3e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Saws",
          },
          {
            value: "43826e3f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scaffolding",
          },
          {
            value: "438294f5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Stationary Engines",
          },
          {
            value: "4382bbd8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tool Batteries & Chargers",
          },
          {
            value: "4382bbda-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tool Combo Kits",
          },
          {
            value: "4382bbdc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tooling Storage & Cabinets",
          },
          {
            value: "4382bbef-9ce0-11ea-8e84-a112b8f334ec",
            label: "Transfer Switches",
          },
          {
            value: "4382e2cd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Winches Come Alongs & Straps",
          },
        ],
      },
      {
        value: "437a09c5-9ce0-11ea-8e84-a112b8f334ec",
        label: "Manufacturing & Metal",
        children: [
          {
            value: "437a3041-9ce0-11ea-8e84-a112b8f334ec",
            label: "Metal Sheets & Flat Stock",
          },
          {
            value: "437a3044-9ce0-11ea-8e84-a112b8f334ec",
            label: "Metalworking Tooling",
          },
          {
            value: "43826d9c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Manufacturing Metalworking",
          },
          {
            value: "43826e08-9ce0-11ea-8e84-a112b8f334ec",
            label: "Process Equipment",
          },
          {
            value: "43826e50-9ce0-11ea-8e84-a112b8f334ec",
            label: "Semiconductor & PCB Equipment",
          },
          {
            value: "4382bbc9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Textile & Apparel Equipment",
          },
          {
            value: "4382bc29-9ce0-11ea-8e84-a112b8f334ec",
            label: "Welding",
          },
          {
            value: "4382e2d8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Woodworking",
          },
        ],
      },
      {
        value: "437a3062-9ce0-11ea-8e84-a112b8f334ec",
        label: "MRO & Industrial Supply",
        children: [
          {
            value: "43796ce4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Adhesives & Sealants",
          },
          {
            value: "4379bb29-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cleaning Equipment & Supplies",
          },
          {
            value: "4379bb49-9ce0-11ea-8e84-a112b8f334ec",
            label: "Commercial Radios",
          },
          {
            value: "4379e250-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fasteners & Hardware",
          },
          {
            value: "437a0933-9ce0-11ea-8e84-a112b8f334ec",
            label: "Government & Public Safety",
          },
          {
            value: "437a0979-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hydraulics & Pneumatics",
          },
          {
            value: "437a09b1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lighting & Lasers",
          },
          {
            value: "437a09c1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manuals & Books",
          },
          {
            value: "437a09cf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Material Handling",
          },
          {
            value: "4382469d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43826e0e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pumps & Plumbing",
          },
          {
            value: "43826e3a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Safety & Security",
          },
          {
            value: "4382e2c7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "43826dbb-9ce0-11ea-8e84-a112b8f334ec",
        label: "Packing & Shipping",
        children: [
          {
            value: "4379941c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bags",
          },
          {
            value: "4379bb63-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cushioning Wrap",
          },
          {
            value: "4379e242-9ce0-11ea-8e84-a112b8f334ec",
            label: "Envelopes",
          },
          {
            value: "437a09ac-9ce0-11ea-8e84-a112b8f334ec",
            label: "Letter Folders",
          },
          {
            value: "437a09bb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mailers",
          },
          {
            value: "43826dbd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Packing Peanuts",
          },
          {
            value: "43826dbe-9ce0-11ea-8e84-a112b8f334ec",
            label: "Packing Tape & Dispensers",
          },
          {
            value: "43826df2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Postage Meter Supplies",
          },
          {
            value: "438294a4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shipping & Postal Scales",
          },
          {
            value: "438294b5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shrink Wrap",
          },
        ],
      },
      {
        value: "43826e04-9ce0-11ea-8e84-a112b8f334ec",
        label: "Printing & Graphic Arts",
        children: [
          {
            value: "43799442-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bindery & Finishing Equipment",
          },
          {
            value: "4379bb47-9ce0-11ea-8e84-a112b8f334ec",
            label: "Commercial Printing Essentials",
          },
          {
            value: "4379bb48-9ce0-11ea-8e84-a112b8f334ec",
            label: "Commercial Printing Presses",
          },
          {
            value: "4379e210-9ce0-11ea-8e84-a112b8f334ec",
            label: "Direct Mail Equipment",
          },
          {
            value: "437a0986-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ink, Plates & Film",
          },
          {
            value: "43826da1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Printing & Graphic Art",
          },
          {
            value: "43826de7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Plotters & Wide Format Printing",
          },
          {
            value: "43826dfd-9ce0-11ea-8e84-a112b8f334ec",
            label: "PrePress Equipment",
          },
          {
            value: "43826e05-9ce0-11ea-8e84-a112b8f334ec",
            label: "Printing & Graphic Essentials",
          },
          {
            value: "43826e49-9ce0-11ea-8e84-a112b8f334ec",
            label: "Screen & Specialty Printing",
          },
          {
            value: "438294b7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sign Making Supplies",
          },
        ],
      },
      {
        value: "43826e26-9ce0-11ea-8e84-a112b8f334ec",
        label: "Restaurant & Catering",
        children: [
          {
            value: "4379bb1a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Catering Concession Trailers & Carts",
          },
          {
            value: "4379bb45-9ce0-11ea-8e84-a112b8f334ec",
            label: "Commercial Bar & Beverage Equipment",
          },
          {
            value: "4379bb46-9ce0-11ea-8e84-a112b8f334ec",
            label: "Commercial Kitchen Equipment",
          },
          {
            value: "43826da5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Restaurant & Catering",
          },
          {
            value: "43826e27-9ce0-11ea-8e84-a112b8f334ec",
            label: "Restaurant & Catering Tabletop & Serving",
          },
          {
            value: "43826e28-9ce0-11ea-8e84-a112b8f334ec",
            label: "Restaurant & Catering Wholesale Lots",
          },
          {
            value: "43826e29-9ce0-11ea-8e84-a112b8f334ec",
            label: "Restaurant Furniture, Signs & Decor",
          },
          {
            value: "43826e2a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Restaurant Refrigeration & Ice Machines",
          },
          {
            value: "43826e2b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Restaurant Uniforms & Aprons",
          },
          {
            value: "4382bc04-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vending & Tabletop Concessions",
          },
        ],
      },
      {
        value: "43826e2d-9ce0-11ea-8e84-a112b8f334ec",
        label: "Retail & Services",
        children: [
          {
            value: "4379946b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Business Signs",
          },
          {
            value: "4379e22b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dry Cleaning & Laundromat",
          },
          {
            value: "437a098f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Jewelry Packaging & Display",
          },
          {
            value: "437a09a1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Labeling & Tagging Supplies",
          },
          {
            value: "437a09bf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mannequins & Dress Forms",
          },
          {
            value: "43826da6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Retail & Services",
          },
          {
            value: "43826deb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Point of Sale Equipment",
          },
          {
            value: "43826e12-9ce0-11ea-8e84-a112b8f334ec",
            label: "Racks & Fixtures",
          },
          {
            value: "43826e4e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Security & Surveillance",
          },
          {
            value: "438294b0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shopping Carts and Baskets",
          },
        ],
      },
      {
        value: "4382bc27-9ce0-11ea-8e84-a112b8f334ec",
        label: "Websites & Businesses",
        children: [
          {
            value: "437a0989-9ce0-11ea-8e84-a112b8f334ec",
            label: "Internet Businesses & Websites for Sale",
          },
          {
            value: "437a09c6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manufacturing Businesses for Sale",
          },
          {
            value: "437a09cb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Marketing Businesses for Sale",
          },
          {
            value: "43826daf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Websites & Businesses for Sale",
          },
          {
            value: "43826dcd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Patents & Trademarks for Sale",
          },
          {
            value: "43826e2c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Retail & General Stores for Sale",
          },
          {
            value: "43826e51-9ce0-11ea-8e84-a112b8f334ec",
            label: "Service Businesses for Sale",
          },
          {
            value: "4382bc03-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vending & Coin Op Businesses for Sale",
          },
          {
            value: "4382e2cc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Trade & Distribution",
          },
        ],
      },
    ],
  },
  {
    value: "4379bb3e-9ce0-11ea-8e84-a112b8f334ec",
    label: "Collectibles & Art",
    children: [
      {
        value: "437993fc-9ce0-11ea-8e84-a112b8f334ec",
        label: "Antiques",
        children: [
          {
            value: "43799401-9ce0-11ea-8e84-a112b8f334ec",
            label: "Architectual & Garden",
          },
          {
            value: "43799407-9ce0-11ea-8e84-a112b8f334ec",
            label: "Asian Antiques",
          },
          {
            value: "4379bb69-9ce0-11ea-8e84-a112b8f334ec",
            label: "Decorative Arts",
          },
          {
            value: "4379e278-9ce0-11ea-8e84-a112b8f334ec",
            label: "Furniture",
          },
          {
            value: "437a0965-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home & Hearth",
          },
          {
            value: "437a09b4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Linens & Textiles",
          },
          {
            value: "437a09ca-9ce0-11ea-8e84-a112b8f334ec",
            label: "Maritime",
          },
          {
            value: "43826dd2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Periods & Styles",
          },
          {
            value: "43826e02-9ce0-11ea-8e84-a112b8f334ec",
            label: "Primitives",
          },
          {
            value: "438294b8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Silver",
          },
        ],
      },
      {
        value: "43799402-9ce0-11ea-8e84-a112b8f334ec",
        label: "Art",
        children: [
          {
            value: "43799403-9ce0-11ea-8e84-a112b8f334ec",
            label: "Art Photographs from Dealers and Resellers",
          },
          {
            value: "43799404-9ce0-11ea-8e84-a112b8f334ec",
            label: "Art Posters from Dealers and Resellers",
          },
          {
            value: "43799405-9ce0-11ea-8e84-a112b8f334ec",
            label: "Art Prints from Dealers and Resellers",
          },
          {
            value: "4379e220-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drawings Direct from the Artist",
          },
          {
            value: "4379e221-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drawings from Dealers and Resellers",
          },
          {
            value: "4379e26a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Folk Art and Primitives Direct from the Artist",
          },
          {
            value: "4379e26b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Folk Art and Primitives from Dealers and Resellers",
          },
          {
            value: "437a3050-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mixed Media and Collage",
          },
          {
            value: "437a3051-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mixed Media and Collages from Dealers and Resellers",
          },
          {
            value: "438246a0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Art Direct from the Artist",
          },
          {
            value: "438246a1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Art from Dealers and Resellers",
          },
          {
            value: "43826dc2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paintings Direct from the Artist",
          },
          {
            value: "43826dc3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paintings from Dealers and Resellers",
          },
          {
            value: "43826dd7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Photographs Direct from the Artist",
          },
          {
            value: "43826e06-9ce0-11ea-8e84-a112b8f334ec",
            label: "Prints Direct from the Artist",
          },
          {
            value: "43826e4c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sculptures and Carvings Direct from the Artist",
          },
          {
            value: "43826e4d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sculptures and Carvings from Dealers and Resellers",
          },
          {
            value: "4382bbca-9ce0-11ea-8e84-a112b8f334ec",
            label: "Textile Art Direct from the Artist",
          },
          {
            value: "4382bbcb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Textile Art from Dealers and Resellers",
          },
        ],
      },
      {
        value: "4379bb37-9ce0-11ea-8e84-a112b8f334ec",
        label: "Coins & Paper Money",
        children: [
          {
            value: "43799469-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bullion",
          },
          {
            value: "4379bb38-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coins: Ancient",
          },
          {
            value: "4379bb39-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coins: Canada",
          },
          {
            value: "4379bb3a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coins: Medieval",
          },
          {
            value: "4379bb3b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coins: US",
          },
          {
            value: "4379bb3c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coins: World",
          },
          {
            value: "4379e248-9ce0-11ea-8e84-a112b8f334ec",
            label: "Exonumia",
          },
          {
            value: "43824698-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43826dc7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paper Money: US",
          },
          {
            value: "43826dc8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paper Money: World",
          },
          {
            value: "43826e0c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Publications & Supplies",
          },
          {
            value: "438294f7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Stocks, Bonds, Scripophily",
          },
          {
            value: "4382bc1a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Virtual Currency",
          },
        ],
      },
      {
        value: "4379bb3d-9ce0-11ea-8e84-a112b8f334ec",
        label: "Collectibles",
        children: [
          {
            value: "43796ce5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Advertising",
          },
          {
            value: "437993f6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Animation Art & Characters",
          },
          {
            value: "437993ff-9ce0-11ea-8e84-a112b8f334ec",
            label: "Arcade, Jukebox & Pinball",
          },
          {
            value: "4379945e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Breweriana, Beer",
          },
          {
            value: "4379bb44-9ce0-11ea-8e84-a112b8f334ec",
            label: "Comics",
          },
          {
            value: "4379bb61-9ce0-11ea-8e84-a112b8f334ec",
            label: "Culture & Ethnicities",
          },
          {
            value: "4379bb6a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Decorative Collectibles",
          },
          {
            value: "4379e217-9ce0-11ea-8e84-a112b8f334ec",
            label: "Disneyana",
          },
          {
            value: "437a095d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Historic Memorabilia",
          },
          {
            value: "437a0962-9ce0-11ea-8e84-a112b8f334ec",
            label: "Holiday & Seasonal",
          },
          {
            value: "437a0998-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kitchen & Home",
          },
          {
            value: "437a099f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Knives, Swords & Blades",
          },
          {
            value: "437a09a4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lamps & Lighting",
          },
          {
            value: "437a3048-9ce0-11ea-8e84-a112b8f334ec",
            label: "Militaria",
          },
          {
            value: "43826dcf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pen & Writing Instruments",
          },
          {
            value: "43826ddc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pinbacks, Bobbles, Lunchboxes",
          },
          {
            value: "43826e31-9ce0-11ea-8e84-a112b8f334ec",
            label: "Rocks, Fossils & Minerals",
          },
          {
            value: "43826e42-9ce0-11ea-8e84-a112b8f334ec",
            label: "Science Fiction & Horror",
          },
          {
            value: "4382bbd6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tobacciana",
          },
          {
            value: "4382bbde-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tools, Hardware & Locks",
          },
          {
            value: "4382bbec-9ce0-11ea-8e84-a112b8f334ec",
            label: "Trading Cards",
          },
          {
            value: "4382bbf1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Transportation",
          },
        ],
      },
      {
        value: "4379bb43-9ce0-11ea-8e84-a112b8f334ec",
        label: "Comics",
        children: [
          {
            value: "43799462-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bronze Age 1971-1983",
          },
          {
            value: "4379bb59-9ce0-11ea-8e84-a112b8f334ec",
            label: "Copper Age 1984-1991",
          },
          {
            value: "4379e253-9ce0-11ea-8e84-a112b8f334ec",
            label: "Figurines",
          },
          {
            value: "437a092b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golden Age 1938-1955",
          },
          {
            value: "437a0939-9ce0-11ea-8e84-a112b8f334ec",
            label: "Graphic Novels, TPBs",
          },
          {
            value: "437a09be-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manga",
          },
          {
            value: "437a3055-9ce0-11ea-8e84-a112b8f334ec",
            label: "Modern Age 1992-Now",
          },
          {
            value: "43824690-9ce0-11ea-8e84-a112b8f334ec",
            label: "Original Comic Art",
          },
          {
            value: "43826de2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Platinum Age 1897-1937",
          },
          {
            value: "438294b9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Silver Age  1956-1969",
          },
        ],
      },
      {
        value: "4379e21d-9ce0-11ea-8e84-a112b8f334ec",
        label: "Dolls & Bears",
        children: [
          {
            value: "43799431-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bear Making Supplies",
          },
          {
            value: "43799432-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bears",
          },
          {
            value: "4379e21b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dollhouse Miniatures",
          },
          {
            value: "4379e21c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dolls",
          },
          {
            value: "43826dc6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paper Dolls",
          },
        ],
      },
      {
        value: "4379e240-9ce0-11ea-8e84-a112b8f334ec",
        label: "Entertainment Memorabilia",
        children: [
          {
            value: "437a305e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Movie Memorabilia",
          },
          {
            value: "437a306a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Music Memorabilia",
          },
          {
            value: "4382468f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Original Autographs",
          },
          {
            value: "43826dd6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Photographic Images",
          },
          {
            value: "4382bbc3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Television Memorabilia",
          },
          {
            value: "4382bbcd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Theater Memorabilia",
          },
          {
            value: "4382bc07-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Game Memorabilia",
          },
        ],
      },
      {
        value: "43826df3-9ce0-11ea-8e84-a112b8f334ec",
        label: "Potery & Glass",
        children: [
          {
            value: "437a0927-9ce0-11ea-8e84-a112b8f334ec",
            label: "Glass",
          },
          {
            value: "43826df4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pottery & China",
          },
        ],
      },
      {
        value: "438294ea-9ce0-11ea-8e84-a112b8f334ec",
        label: "Sports Memorablilia",
        children: [
          {
            value: "4379940e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Autograph-Original",
          },
          {
            value: "4379940f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Autograph-Reprints",
          },
          {
            value: "4379e24a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fan Apparel & Souvenir",
          },
          {
            value: "4379e27f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Game Used Memorabilia",
          },
          {
            value: "438294e9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sports Cards",
          },
          {
            value: "4382bc18-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Sports Memorabilia",
          },
          {
            value: "4382e2c5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "438294f0-9ce0-11ea-8e84-a112b8f334ec",
        label: "Stamps",
        children: [
          {
            value: "43796ce6-9ce0-11ea-8e84-a112b8f334ec",
            label: "African Stamps",
          },
          {
            value: "43799408-9ce0-11ea-8e84-a112b8f334ec",
            label: "Asian Stamps",
          },
          {
            value: "43799461-9ce0-11ea-8e84-a112b8f334ec",
            label: "British Colonies & Territory Stamps",
          },
          {
            value: "43799477-9ce0-11ea-8e84-a112b8f334ec",
            label: "Canadian Stamps",
          },
          {
            value: "4379bb0f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Caribbean Stamps",
          },
          {
            value: "4379e246-9ce0-11ea-8e84-a112b8f334ec",
            label: "European Stamps",
          },
          {
            value: "437a093a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Great Britain Stamps",
          },
          {
            value: "437a09a8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Latin American Stamps",
          },
          {
            value: "437a3047-9ce0-11ea-8e84-a112b8f334ec",
            label: "Middle Eastern Stamps",
          },
          {
            value: "43826e0d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Publications & Supplies",
          },
          {
            value: "438294d3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Specialty Philately",
          },
          {
            value: "4382bbe1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Topical Stamps",
          },
          {
            value: "4382bbfb-9ce0-11ea-8e84-a112b8f334ec",
            label: "United State Stamps",
          },
          {
            value: "4382e2d9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Worldwide Stamps",
          },
        ],
      },
    ],
  },
  {
    value: "4379e23c-9ce0-11ea-8e84-a112b8f334ec",
    label: "Electronics",
    children: [
      {
        value: "4379946e-9ce0-11ea-8e84-a112b8f334ec",
        label: "Cables & Connectors",
        children: [
          {
            value: "4379bb4d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Computer Components & Parts",
          },
          {
            value: "4379bb70-9ce0-11ea-8e84-a112b8f334ec",
            label: "Desktops & All-In-Ones",
          },
          {
            value: "4379e22a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Drives, Storage & Blank Media",
          },
          {
            value: "4379e23f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Enterprise Networking & Servers",
          },
          {
            value: "437a096b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Networking & Connectivity",
          },
          {
            value: "437a0993-9ce0-11ea-8e84-a112b8f334ec",
            label: "Keyborads, Mice, & Pointing",
          },
          {
            value: "437a09a6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Laptop & Desktop Accessories",
          },
          {
            value: "437a09a7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Laptops & Netbooks",
          },
          {
            value: "437a09c4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manuals & Rosources",
          },
          {
            value: "437a3056-9ce0-11ea-8e84-a112b8f334ec",
            label: "Monitors, Projectors & Accessories",
          },
          {
            value: "438246a6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Computing & Networking",
          },
          {
            value: "43826df8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Power Protection, & Distribution",
          },
          {
            value: "43826e03-9ce0-11ea-8e84-a112b8f334ec",
            label: "Printers, Scanners, & Supplies",
          },
          {
            value: "438294cf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Software",
          },
          {
            value: "4382bbb6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tablet & eBook Readers",
          },
          {
            value: "4382bbb7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tablet & eBook Readers Accessories",
          },
          {
            value: "4382bbb8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tablet & eBook Readers Parts",
          },
          {
            value: "4382bc10-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Computing",
          },
          {
            value: "4382e2c3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "43799472-9ce0-11ea-8e84-a112b8f334ec",
        label: "Camera & Photo",
        children: [
          {
            value: "43799443-9ce0-11ea-8e84-a112b8f334ec",
            label: "Binoculars & Telescopes",
          },
          {
            value: "43799471-9ce0-11ea-8e84-a112b8f334ec",
            label: "Camcorders",
          },
          {
            value: "43799473-9ce0-11ea-8e84-a112b8f334ec",
            label: "Camera & Photo Accessories",
          },
          {
            value: "43799474-9ce0-11ea-8e84-a112b8f334ec",
            label: "Camera Drones",
          },
          {
            value: "43799475-9ce0-11ea-8e84-a112b8f334ec",
            label: "Camera Lenses Filters",
          },
          {
            value: "4379bb75-9ce0-11ea-8e84-a112b8f334ec",
            label: "Digital Cameras",
          },
          {
            value: "4379bb77-9ce0-11ea-8e84-a112b8f334ec",
            label: "Digital Photo Frames",
          },
          {
            value: "4379e254-9ce0-11ea-8e84-a112b8f334ec",
            label: "Film Photography",
          },
          {
            value: "4379e265-9ce0-11ea-8e84-a112b8f334ec",
            label: "Flashes & Flash Accessories",
          },
          {
            value: "437a09c2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manuals & Guides",
          },
          {
            value: "438246a3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Cameras & Photo",
          },
          {
            value: "43826dd5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Photo Lighting and Studio Equipments",
          },
          {
            value: "43826e23-9ce0-11ea-8e84-a112b8f334ec",
            label: "Replacement Parts & Tools",
          },
          {
            value: "4382bbf4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tripods & Supports",
          },
          {
            value: "4382bc0c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Production & Editing",
          },
          {
            value: "4382bc17-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Movie & Photography",
          },
          {
            value: "4382e2c4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "4379bb07-9ce0-11ea-8e84-a112b8f334ec",
        label: "Car Electronics",
        children: [
          {
            value: "4379bb04-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Alarms & Security",
          },
          {
            value: "4379bb05-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Audio",
          },
          {
            value: "4379bb06-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Audio & Video Installation",
          },
          {
            value: "4379bb09-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Electronics Accessories",
          },
          {
            value: "4379bb0b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Video",
          },
          {
            value: "437a0935-9ce0-11ea-8e84-a112b8f334ec",
            label: "GPS Accessories & Tracking",
          },
          {
            value: "437a0936-9ce0-11ea-8e84-a112b8f334ec",
            label: "GPS Units",
          },
          {
            value: "437a09c9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Marine Audio",
          },
          {
            value: "43826da8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Vehicle Electronics",
          },
          {
            value: "43826def-9ce0-11ea-8e84-a112b8f334ec",
            label: "Portable Appliances 12 Volt",
          },
          {
            value: "43826e14-9ce0-11ea-8e84-a112b8f334ec",
            label: "Radar & Laser Detectors",
          },
        ],
      },
      {
        value: "4379bb1e-9ce0-11ea-8e84-a112b8f334ec",
        label: "Cell Phones & Accessories",
        children: [
          {
            value: "4379942e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Batteries",
          },
          {
            value: "4379946d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cables & Adapters",
          },
          {
            value: "4379bb17-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cases, Covers, & Skins",
          },
          {
            value: "4379bb1c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cell Phone Accessories",
          },
          {
            value: "4379bb1d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cell Phone Parts",
          },
          {
            value: "4379bb1f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cell Phones & Smartphones",
          },
          {
            value: "4379bb24-9ce0-11ea-8e84-a112b8f334ec",
            label: "Chargers & Cradles",
          },
          {
            value: "4379bb4c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Computer & Tablets",
          },
          {
            value: "4379e264-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fitness Watches",
          },
          {
            value: "437a094d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Headsets",
          },
          {
            value: "43826e4a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scren protectors",
          },
          {
            value: "438294c5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Smart Watches",
          },
        ],
      },
      {
        value: "4382bbf9-9ce0-11ea-8e84-a112b8f334ec",
        label: "TV, Video, & Audio",
        children: [
          {
            value: "43799470-9ce0-11ea-8e84-a112b8f334ec",
            label: "Calculators",
          },
          {
            value: "4379e22f-9ce0-11ea-8e84-a112b8f334ec",
            label: "DVD & Blu-ray players",
          },
          {
            value: "4379e27b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gadgets",
          },
          {
            value: "437a094c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Headphones",
          },
          {
            value: "437a0967-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Audio",
          },
          {
            value: "437a0968-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Automation",
          },
          {
            value: "437a096e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Speakers & Subwoofers",
          },
          {
            value: "437a096f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Surveillance",
          },
          {
            value: "437a3034-9ce0-11ea-8e84-a112b8f334ec",
            label: "Media Streamers",
          },
          {
            value: "43826df0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Portable Audio & Accessories",
          },
          {
            value: "4382bbc4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Televisions",
          },
          {
            value: "4382bc13-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Electronics",
          },
          {
            value: "4382bc1b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Virtual Reality",
          },
        ],
      },
      {
        value: "4382bc0b-9ce0-11ea-8e84-a112b8f334ec",
        label: "Video Games & Consoles",
        children: [
          {
            value: "43826dad-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Video Games & Consoles",
          },
          {
            value: "43826dfc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Prepaid Gaming Cards",
          },
          {
            value: "43826e24-9ce0-11ea-8e84-a112b8f334ec",
            label: "Replacement Parts & Tools",
          },
          {
            value: "438294f9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Strategy Guides & Cheats",
          },
          {
            value: "4382bc05-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Game Accessories",
          },
          {
            value: "4382bc06-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Game Memorabilia",
          },
          {
            value: "4382bc08-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Game Merchandise",
          },
          {
            value: "4382bc09-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Game Wholesale Lots",
          },
          {
            value: "4382bc0a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Video Games",
          },
        ],
      },
    ],
  },
  {
    value: "4379e24e-9ce0-11ea-8e84-a112b8f334ec",
    label: "Fashion",
    children: [
      {
        value: "43799433-9ce0-11ea-8e84-a112b8f334ec",
        label: "Beauty",
        children: [
          {
            value: "43799426-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bath & Body",
          },
          {
            value: "4379e273-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fragrances",
          },
          {
            value: "437a0944-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hair Care & Styling",
          },
          {
            value: "437a09bd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Makeup",
          },
          {
            value: "437a3071-9ce0-11ea-8e84-a112b8f334ec",
            label: "Nail Care",
          },
          {
            value: "43826e3b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Salon & Spa Equipment",
          },
          {
            value: "438294a0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shaving & Hair Removal",
          },
          {
            value: "438294be-9ce0-11ea-8e84-a112b8f334ec",
            label: "Skin Care",
          },
          {
            value: "438294ff-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sun Protection & Tanning",
          },
          {
            value: "4382bbbd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tattoos & Body Art",
          },
        ],
      },
      {
        value: "437a0947-9ce0-11ea-8e84-a112b8f334ec",
        label: "Handbags & Accessories",
        children: [
          {
            value: "437a09b8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Luxury Handbags",
          },
          {
            value: "437a303c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Men's Accessories",
          },
          {
            value: "4382e2d2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Women's Accessories",
          },
        ],
      },
      {
        value: "437a094e-9ce0-11ea-8e84-a112b8f334ec",
        label: "Health",
        children: [
          {
            value: "437a094f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Health Care",
          },
          {
            value: "437a3039-9ce0-11ea-8e84-a112b8f334ec",
            label: "Medical, Mobility & Disability",
          },
          {
            value: "437a3072-9ce0-11ea-8e84-a112b8f334ec",
            label: "Natural & Homeopathic Remedies",
          },
          {
            value: "4382468e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Oral Care",
          },
          {
            value: "43826e54-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sexual Wellness",
          },
          {
            value: "4382bc1c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vision Care",
          },
          {
            value: "4382bc1d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vitamins & Supplements",
          },
          {
            value: "4382bc28-9ce0-11ea-8e84-a112b8f334ec",
            label: "Weight Management",
          },
        ],
      },
      {
        value: "437a098e-9ce0-11ea-8e84-a112b8f334ec",
        label: "Jewelry",
        children: [
          {
            value: "4379bb71-9ce0-11ea-8e84-a112b8f334ec",
            label: "Diamond Jewelry and Loose Diamonds",
          },
          {
            value: "4379e23e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Engagement & Wedding",
          },
          {
            value: "4379e24f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fashion Jewelry",
          },
          {
            value: "4379e255-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fine Jewelry",
          },
          {
            value: "4379e284-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gemstones",
          },
          {
            value: "437a303e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Men's Jewelry",
          },
          {
            value: "4382bc0e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage & Antique",
          },
        ],
      },
      {
        value: "437a0994-9ce0-11ea-8e84-a112b8f334ec",
        label: "Kids & Baby",
        children: [
          {
            value: "43799453-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bottoms",
          },
          {
            value: "4379bb35-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coats and Jackets",
          },
          {
            value: "4379e225-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dresses",
          },
          {
            value: "4382468c-9ce0-11ea-8e84-a112b8f334ec",
            label: "One Pieces",
          },
          {
            value: "43826db9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Outfits and Sets",
          },
          {
            value: "438294a7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shoes",
          },
          {
            value: "438294c2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sleepwear",
          },
          {
            value: "43829505-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sweaters",
          },
          {
            value: "4382bbe3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tops and T-Shirts",
          },
        ],
      },
      {
        value: "437a303d-9ce0-11ea-8e84-a112b8f334ec",
        label: "Men's Clothing",
        children: [
          {
            value: "4379940b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Athletic Apparel",
          },
          {
            value: "43799447-9ce0-11ea-8e84-a112b8f334ec",
            label: "Blazers & Sport Coats",
          },
          {
            value: "4379bb34-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coats & Jackets",
          },
          {
            value: "4379e222-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dress Shirts",
          },
          {
            value: "437a098d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Jeans",
          },
          {
            value: "43826dc5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pants",
          },
          {
            value: "438294b2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shorts",
          },
          {
            value: "438294fd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Suits",
          },
          {
            value: "43829504-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sweaters",
          },
          {
            value: "4382bbb1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sweats & Hoodies",
          },
          {
            value: "4382bbf6-9ce0-11ea-8e84-a112b8f334ec",
            label: "T-Shirts",
          },
          {
            value: "4382bbfa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Underwear",
          },
        ],
      },
      {
        value: "438294a5-9ce0-11ea-8e84-a112b8f334ec",
        label: "Shoes",
        children: [
          {
            value: "43799414-9ce0-11ea-8e84-a112b8f334ec",
            label: "Baby & Toddler Boys",
          },
          {
            value: "43799415-9ce0-11ea-8e84-a112b8f334ec",
            label: "Baby & Toddler Girls",
          },
          {
            value: "4379945b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boys",
          },
          {
            value: "437a0926-9ce0-11ea-8e84-a112b8f334ec",
            label: "Girls",
          },
          {
            value: "437a303f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Men's Shoes",
          },
          {
            value: "4382e2d4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Women's Shoes",
          },
        ],
      },
      {
        value: "4382bc24-9ce0-11ea-8e84-a112b8f334ec",
        label: "Watches",
        children: [
          {
            value: "43799457-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boxes, Cases & Watch Winders",
          },
          {
            value: "43826dea-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pocket Watches",
          },
          {
            value: "4382e2db-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wristwatch Bands",
          },
          {
            value: "4382e2dc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wristwatches",
          },
        ],
      },
      {
        value: "4382e2d3-9ce0-11ea-8e84-a112b8f334ec",
        label: "Women's Clothing",
        children: [
          {
            value: "4379940a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Athletic Apparel",
          },
          {
            value: "4379bb33-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coats & Jackets",
          },
          {
            value: "4379e224-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dresses",
          },
          {
            value: "437a098a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Intimates & Sleep",
          },
          {
            value: "437a098c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Jeans",
          },
          {
            value: "43826dc4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pants",
          },
          {
            value: "438294b1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shorts",
          },
          {
            value: "438294bf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Skirts",
          },
          {
            value: "438294fe-9ce0-11ea-8e84-a112b8f334ec",
            label: "Suits & Blazers",
          },
          {
            value: "43829503-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sweaters",
          },
          {
            value: "4382bbb0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sweats & Hoodies",
          },
          {
            value: "4382bbb3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Swimwear",
          },
          {
            value: "4382bbe2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tops & Blouses",
          },
          {
            value: "4382bbf5-9ce0-11ea-8e84-a112b8f334ec",
            label: "T-Shirts",
          },
        ],
      },
      {
        value: "4382e2e2-9ce0-11ea-8e84-a112b8f334ec",
        label: "Hats, Caps & Helmets",
        children: [
          {
            value: "4382e2e3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Leather Hats",
          },
          {
            value: "4382e2e4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Men's Caps",
          },
          {
            value: "4382e2e5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Women's Caps",
          },
          {
            value: "4382e2e6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Children's Caps",
          },
        ],
      },
    ],
  },
  {
    value: "437a0964-9ce0-11ea-8e84-a112b8f334ec",
    label: "Home & Garden",
    children: [
      {
        value: "43799413-9ce0-11ea-8e84-a112b8f334ec",
        label: "Baby",
        children: [
          {
            value: "43799416-9ce0-11ea-8e84-a112b8f334ec",
            label: "Baby Gear",
          },
          {
            value: "43799417-9ce0-11ea-8e84-a112b8f334ec",
            label: "Baby Safety & Health",
          },
          {
            value: "4379942a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bathing & Grooming",
          },
          {
            value: "4379bb0a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Seats",
          },
          {
            value: "4379bb72-9ce0-11ea-8e84-a112b8f334ec",
            label: "Diapering",
          },
          {
            value: "437a0992-9ce0-11ea-8e84-a112b8f334ec",
            label: "Keepsakes & Announcements",
          },
          {
            value: "43824683-9ce0-11ea-8e84-a112b8f334ec",
            label: "Nursery Bedding",
          },
          {
            value: "43824684-9ce0-11ea-8e84-a112b8f334ec",
            label: "Nursery Décor",
          },
          {
            value: "43824685-9ce0-11ea-8e84-a112b8f334ec",
            label: "Nursery Furniture",
          },
          {
            value: "43824686-9ce0-11ea-8e84-a112b8f334ec",
            label: "Nursing & Feeding",
          },
          {
            value: "43826df5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Potty Training",
          },
          {
            value: "43826dfb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pregnancy Pillows",
          },
          {
            value: "438294fc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Strollers",
          },
          {
            value: "4382bbe6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Toys for Baby",
          },
        ],
      },
      {
        value: "43799425-9ce0-11ea-8e84-a112b8f334ec",
        label: "Bath",
        children: [
          {
            value: "43799427-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bath Accessory Sets",
          },
          {
            value: "43799428-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bath Caddies & Storage",
          },
          {
            value: "43799429-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bath Products",
          },
          {
            value: "4379942b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bathmats, Rugs & Toilets",
          },
          {
            value: "4379942c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bathroom Shelves",
          },
          {
            value: "437a303a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Medicine Cabinets",
          },
          {
            value: "43824680-9ce0-11ea-8e84-a112b8f334ec",
            label: "Non-Slip Bathroom Appliques & Mats",
          },
          {
            value: "438294b3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shower Curtain Hooks",
          },
          {
            value: "438294b4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shower Curtains",
          },
          {
            value: "438294cc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Soap Dishes & Dispensers",
          },
          {
            value: "4382bbd5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tissue Box Covers",
          },
          {
            value: "4382bbd7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Toilet Paper Storage & Covers",
          },
          {
            value: "4382bbe0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Toothbr- Bathush Holders",
          },
          {
            value: "4382bbe4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Towels & Washcloths",
          },
          {
            value: "4382bc20-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wall Hooks and Hangers",
          },
        ],
      },
      {
        value: "43799435-9ce0-11ea-8e84-a112b8f334ec",
        label: "Bedding",
        children: [
          {
            value: "43799434-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bed Pillows",
          },
          {
            value: "43799436-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bed-in-a-Bag",
          },
          {
            value: "43799446-9ce0-11ea-8e84-a112b8f334ec",
            label: "Blankets & Throws",
          },
          {
            value: "4379bb41-9ce0-11ea-8e84-a112b8f334ec",
            label: "Comforters & Sets",
          },
          {
            value: "4379bb5b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Crafts",
          },
          {
            value: "4379e22e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Duvet Covers & Pillow Shams",
          },
          {
            value: "437a3032-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mattress Pads & Feather Beds",
          },
          {
            value: "43826e11-9ce0-11ea-8e84-a112b8f334ec",
            label: "Quilts, Bedspreads & Coverlets",
          },
          {
            value: "438294a3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sheets & Pillowcases",
          },
        ],
      },
      {
        value: "4379bb5a-9ce0-11ea-8e84-a112b8f334ec",
        label: "Crafts",
        children: [
          {
            value: "43799406-9ce0-11ea-8e84-a112b8f334ec",
            label: "Art Supplies",
          },
          {
            value: "43799430-9ce0-11ea-8e84-a112b8f334ec",
            label: "Beads & Jewelry Making",
          },
          {
            value: "4379e249-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fabric & Sewing",
          },
          {
            value: "437a0928-9ce0-11ea-8e84-a112b8f334ec",
            label: "Glass & Mosaics",
          },
          {
            value: "437a0966-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Arts & Crafts",
          },
          {
            value: "437a0995-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kids' Crafts",
          },
          {
            value: "437a3065-9ce0-11ea-8e84-a112b8f334ec",
            label: "Multi-Purpose Craft Supplies",
          },
          {
            value: "43826e47-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scrapbooking & Paper Crafts",
          },
          {
            value: "438294ef-9ce0-11ea-8e84-a112b8f334ec",
            label: "Stamping & Embossing",
          },
          {
            value: "4382e2de-9ce0-11ea-8e84-a112b8f334ec",
            label: "Yarn & Needlecrafts",
          },
        ],
      },
      {
        value: "4379e26c-9ce0-11ea-8e84-a112b8f334ec",
        label: "Food and Beverages",
        children: [
          {
            value: "4379bb02-9ce0-11ea-8e84-a112b8f334ec",
            label: "Candy",
          },
          {
            value: "4379bb36-9ce0-11ea-8e84-a112b8f334ec",
            label: "Coffee & K-cups",
          },
          {
            value: "437a095c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Herbal Drinks",
          },
          {
            value: "437a0971-9ce0-11ea-8e84-a112b8f334ec",
            label: "Household Cleaning Products",
          },
          {
            value: "437a0972-9ce0-11ea-8e84-a112b8f334ec",
            label: "Household Paper Products",
          },
          {
            value: "438294c6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Snacks",
          },
          {
            value: "438294d4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Spices & Herbs",
          },
          {
            value: "438294d5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ceylon Spices",
          },
          {
            value: "438294d6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ceylon Tea",
          },
          {
            value: "438294d7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Indian Food",
          },
          {
            value: "438294d8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Singapore Food",
          },
          {
            value: "438294d9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sri Lankan Food",
          },
          {
            value: "438294da-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mexican Food",
          },
          {
            value: "438294db-9ce0-11ea-8e84-a112b8f334ec",
            label: "Russian Food",
          },
          {
            value: "438294dc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Thai Food",
          },
          {
            value: "438294dd-9ce0-11ea-8e84-a112b8f334ec",
            label: "African Food",
          },
          {
            value: "438294de-9ce0-11ea-8e84-a112b8f334ec",
            label: "Asian Food",
          },
          {
            value: "438294df-9ce0-11ea-8e84-a112b8f334ec",
            label: "English Food",
          },
          {
            value: "438294e0-9ce0-11ea-8e84-a112b8f334ec",
            label: "French Food",
          },
          {
            value: "438294e1-9ce0-11ea-8e84-a112b8f334ec",
            label: "European Food",
          },
          {
            value: "438294e2-9ce0-11ea-8e84-a112b8f334ec",
            label: "American Food",
          },
          {
            value: "438294e3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Australian Food",
          },
          {
            value: "438294e4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Chinese Food",
          },
          {
            value: "438294e5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Japanese Food",
          },
          {
            value: "4382bbbf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tea",
          },
          {
            value: "4382e2cf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wine",
          },
        ],
      },
      {
        value: "4379e277-9ce0-11ea-8e84-a112b8f334ec",
        label: "Furniture",
        children: [
          {
            value: "43799421-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bar Stools",
          },
          {
            value: "43799437-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bedroom Sets",
          },
          {
            value: "43799438-9ce0-11ea-8e84-a112b8f334ec",
            label: "Beds & Mattresses",
          },
          {
            value: "43799439-9ce0-11ea-8e84-a112b8f334ec",
            label: "Benches & Stools",
          },
          {
            value: "4379946c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cabinets & Cupboards",
          },
          {
            value: "4379bb22-9ce0-11ea-8e84-a112b8f334ec",
            label: "Chairs",
          },
          {
            value: "4379bb6f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Desks & Home Office Furniture",
          },
          {
            value: "4379bb78-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dining Sets",
          },
          {
            value: "4379e223-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dressers & Chests of Drawers",
          },
          {
            value: "4379e241-9ce0-11ea-8e84-a112b8f334ec",
            label: "Entertainment Units, TV Stands",
          },
          {
            value: "4379e279-9ce0-11ea-8e84-a112b8f334ec",
            label: "Futons, Frames & Covers",
          },
          {
            value: "43826db4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ottomans, Footstools & Poufs",
          },
          {
            value: "438294c3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Slipcovers",
          },
          {
            value: "438294ce-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sofas, Loveseats & Chaises",
          },
          {
            value: "4382bbb5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tables",
          },
        ],
      },
      {
        value: "437a0969-9ce0-11ea-8e84-a112b8f334ec",
        label: "Home Décor",
        children: [
          {
            value: "43799458-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boxes, Jars & Tins",
          },
          {
            value: "4379bb00-9ce0-11ea-8e84-a112b8f334ec",
            label: "Candle Holders & Accessories",
          },
          {
            value: "4379bb01-9ce0-11ea-8e84-a112b8f334ec",
            label: "Candles",
          },
          {
            value: "4379bb2d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Clocks",
          },
          {
            value: "4379bb68-9ce0-11ea-8e84-a112b8f334ec",
            label: "Decals & Vinyl Art",
          },
          {
            value: "4379bb6b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Decorative Pillows",
          },
          {
            value: "4379e268-9ce0-11ea-8e84-a112b8f334ec",
            label: "Floral Décor",
          },
          {
            value: "4379e274-9ce0-11ea-8e84-a112b8f334ec",
            label: "Frames",
          },
          {
            value: "437a09a5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lamps, Lighting & Fans",
          },
          {
            value: "437a304e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mirrors",
          },
          {
            value: "43826de1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Plaques & Signs",
          },
          {
            value: "43826e55-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shadow Boxes",
          },
          {
            value: "4382bbbc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tapestries",
          },
          {
            value: "4382bc00-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vases",
          },
          {
            value: "4382bc21-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wall Shelves",
          },
          {
            value: "4382e2ce-9ce0-11ea-8e84-a112b8f334ec",
            label: "Window Treatments & Hardware",
          },
        ],
      },
      {
        value: "437a096a-9ce0-11ea-8e84-a112b8f334ec",
        label: "Home Improvement",
        children: [
          {
            value: "43799464-9ce0-11ea-8e84-a112b8f334ec",
            label: "Building & Hardware",
          },
          {
            value: "4379e235-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electrical & Solar",
          },
          {
            value: "4379e23d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Emergency Preparedness",
          },
          {
            value: "437a0954-9ce0-11ea-8e84-a112b8f334ec",
            label: "Heating, Cooling & Air",
          },
          {
            value: "437a096d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Security",
          },
          {
            value: "43826d97-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Home Improvement",
          },
          {
            value: "43826de9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Plumbing & Fixtures",
          },
        ],
      },
      {
        value: "437a0973-9ce0-11ea-8e84-a112b8f334ec",
        label: "Housekeeping",
        children: [
          {
            value: "4379bb10-9ce0-11ea-8e84-a112b8f334ec",
            label: "Carpet & Floor Sweepers",
          },
          {
            value: "4379bb11-9ce0-11ea-8e84-a112b8f334ec",
            label: "Carpet Shampooers",
          },
          {
            value: "4379bb12-9ce0-11ea-8e84-a112b8f334ec",
            label: "Carpet Steamers",
          },
          {
            value: "4379bb2a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cleaning Products",
          },
          {
            value: "4379bb2b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cleaning Towels & Cloths",
          },
          {
            value: "437a096c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Home Organization",
          },
          {
            value: "437a09a9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Laundry Supplies",
          },
          {
            value: "437a3058-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mops & Brooms",
          },
          {
            value: "43826d96-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Home Cleaning Supplies",
          },
          {
            value: "43826dc9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paper Products",
          },
          {
            value: "4382bbf2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Trash Cans & Wastebaskets",
          },
          {
            value: "4382bbfd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vacuum Cleaner Bags",
          },
          {
            value: "4382bbfe-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vacuum Cleaners",
          },
          {
            value: "4382bbff-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vacuum Parts & Accessories",
          },
          {
            value: "4382bc19-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Vacuums",
          },
        ],
      },
      {
        value: "437a0996-9ce0-11ea-8e84-a112b8f334ec",
        label: "Kitchen & Dining",
        children: [
          {
            value: "4379941e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bakeware",
          },
          {
            value: "43799422-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bar Tools & Accessories",
          },
          {
            value: "4379946f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cake, Candy & Pastry Tools",
          },
          {
            value: "4379bb58-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cookware",
          },
          {
            value: "4379bb79-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dinnerware & Serving Dishes",
          },
          {
            value: "4379e267-9ce0-11ea-8e84-a112b8f334ec",
            label: "Flatware, Knives & Cutlery",
          },
          {
            value: "437a0929-9ce0-11ea-8e84-a112b8f334ec",
            label: "Glassware",
          },
          {
            value: "437a0997-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kitchen & Dining",
          },
          {
            value: "437a0999-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kitchen Storage & Organization",
          },
          {
            value: "437a099a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kitchen Tools & Gadgets",
          },
          {
            value: "43826d9a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Kitchen & Dining Items",
          },
          {
            value: "438294c4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Small Kitchen Appliances",
          },
        ],
      },
      {
        value: "437a09bc-9ce0-11ea-8e84-a112b8f334ec",
        label: "Major Appliances",
        children: [
          {
            value: "4379bb57-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cooktops",
          },
          {
            value: "4379e215-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dishwashers",
          },
          {
            value: "4379e22c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dryers",
          },
          {
            value: "4379e275-9ce0-11ea-8e84-a112b8f334ec",
            label: "Freezers",
          },
          {
            value: "437a3046-9ce0-11ea-8e84-a112b8f334ec",
            label: "Microwave Ovens",
          },
          {
            value: "43826dba-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ovens",
          },
          {
            value: "43826dcc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Parts & Accessories",
          },
          {
            value: "43826e17-9ce0-11ea-8e84-a112b8f334ec",
            label: "Range Hoods",
          },
          {
            value: "43826e18-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ranges & Stoves",
          },
          {
            value: "43826e21-9ce0-11ea-8e84-a112b8f334ec",
            label: "Refrigerators",
          },
          {
            value: "4382bc22-9ce0-11ea-8e84-a112b8f334ec",
            label: "Washer & Dryer Sets",
          },
          {
            value: "4382bc23-9ce0-11ea-8e84-a112b8f334ec",
            label: "Washing Machines",
          },
          {
            value: "4382bc25-9ce0-11ea-8e84-a112b8f334ec",
            label: "Water Filters",
          },
        ],
      },
      {
        value: "43826dd4-9ce0-11ea-8e84-a112b8f334ec",
        label: "Pet Supplies",
        children: [
          {
            value: "4379941a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Backyard Poultry Supplies",
          },
          {
            value: "43799445-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bird Supplies",
          },
          {
            value: "4379bb19-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cat Supplies",
          },
          {
            value: "4379e21a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dog Supplies",
          },
          {
            value: "4379e259-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fish & Aquariums",
          },
          {
            value: "437a0970-9ce0-11ea-8e84-a112b8f334ec",
            label: "Horse Supplies",
          },
          {
            value: "43826e25-9ce0-11ea-8e84-a112b8f334ec",
            label: "Reptile Supplies",
          },
        ],
      },
      {
        value: "4382bbdd-9ce0-11ea-8e84-a112b8f334ec",
        label: "Tools",
        children: [
          {
            value: "43796ceb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Compressors",
          },
          {
            value: "43796cef-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Tools",
          },
          {
            value: "4379e266-9ce0-11ea-8e84-a112b8f334ec",
            label: "Flashlights",
          },
          {
            value: "437a0922-9ce0-11ea-8e84-a112b8f334ec",
            label: "Generators",
          },
          {
            value: "437a0945-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hand Tools",
          },
          {
            value: "437a09a3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ladders",
          },
          {
            value: "437a3033-9ce0-11ea-8e84-a112b8f334ec",
            label: "Measuring & Layout Tools",
          },
          {
            value: "43826d98-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Home Improvement Tools",
          },
          {
            value: "43826df9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Power Tools",
          },
          {
            value: "43826e39-9ce0-11ea-8e84-a112b8f334ec",
            label: "Safety & Protective Gear",
          },
          {
            value: "4382bbd2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tie Downs & Straps",
          },
          {
            value: "4382bbd9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tool Boxes, Belts & Storage",
          },
          {
            value: "4382e2c0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Welding & Soldering Tools",
          },
        ],
      },
      {
        value: "4382e2dd-9ce0-11ea-8e84-a112b8f334ec",
        label: "Yard & Garden",
        children: [
          {
            value: "4379942f-9ce0-11ea-8e84-a112b8f334ec",
            label: "BBQs, Grills & Smokers",
          },
          {
            value: "43799444-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bird & Wildlife Accessories",
          },
          {
            value: "4379e258-9ce0-11ea-8e84-a112b8f334ec",
            label: "Firepits & Chimineas",
          },
          {
            value: "4379e269-9ce0-11ea-8e84-a112b8f334ec",
            label: "Flowers, Trees & Plants",
          },
          {
            value: "4379e281-9ce0-11ea-8e84-a112b8f334ec",
            label: "Garden Décor",
          },
          {
            value: "4379e282-9ce0-11ea-8e84-a112b8f334ec",
            label: "Garden Structures & Shade",
          },
          {
            value: "4379e283-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gardening Supplies",
          },
          {
            value: "437a09aa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lawnmowers",
          },
          {
            value: "43826db1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Yard, Garden & Outdoor",
          },
          {
            value: "43826db5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Outdoor Cooking & Eating",
          },
          {
            value: "43826db6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Outdoor Lighting",
          },
          {
            value: "43826db7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Outdoor Power Equipment",
          },
          {
            value: "43826dce-9ce0-11ea-8e84-a112b8f334ec",
            label: "Patio & Garden Furniture",
          },
          {
            value: "43826dee-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pools & Spas",
          },
        ],
      },
    ],
  },
  {
    value: "437a305d-9ce0-11ea-8e84-a112b8f334ec",
    label: "Motors",
    children: [
      {
        value: "43826dcb-9ce0-11ea-8e84-a112b8f334ec",
        label: "Parts & Accessories",
        children: [
          {
            value: "437993fe-9ce0-11ea-8e84-a112b8f334ec",
            label: "Apparel & Merchandise",
          },
          {
            value: "4379940c-9ce0-11ea-8e84-a112b8f334ec",
            label: "ATV Parts",
          },
          {
            value: "43799411-9ce0-11ea-8e84-a112b8f334ec",
            label: "Automotive Tools & Supplies",
          },
          {
            value: "43799412-9ce0-11ea-8e84-a112b8f334ec",
            label: "Aviation Parts & Accessories",
          },
          {
            value: "4379944a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boat Parts",
          },
          {
            value: "4379bb08-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car Electronics",
          },
          {
            value: "4379bb16-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cart & Truck Parts & Accessories",
          },
          {
            value: "437a09c3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Manuals & Literature",
          },
          {
            value: "437a305a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Motorcycle Parts",
          },
          {
            value: "43826da0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Parts",
          },
          {
            value: "43826da9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Vehicle Parts",
          },
          {
            value: "43826dd1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Performance & Racing Parts",
          },
          {
            value: "43826dd3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Personal Watercraft Parts",
          },
          {
            value: "43826e37-9ce0-11ea-8e84-a112b8f334ec",
            label: "RV, Trailer & Camper Parts",
          },
          {
            value: "43826e3c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Salvage Parts Cars",
          },
          {
            value: "43826e44-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scooter Parts",
          },
          {
            value: "43826e53-9ce0-11ea-8e84-a112b8f334ec",
            label: "Services & Installation",
          },
          {
            value: "438294c9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Snowmobile Parts",
          },
          {
            value: "4382bc0f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Car & Truck Parts",
          },
          {
            value: "4382e2c9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Lots",
          },
        ],
      },
      {
        value: "4382bc02-9ce0-11ea-8e84-a112b8f334ec",
        label: "Vehicles",
        children: [
          {
            value: "4379944b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boats",
          },
          {
            value: "4379bb13-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cars & Trucks",
          },
          {
            value: "4379bb28-9ce0-11ea-8e84-a112b8f334ec",
            label: "Classics & Exotics",
          },
          {
            value: "437a093b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Green Driving",
          },
          {
            value: "437a305b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Motorcycles",
          },
          {
            value: "43826daa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Vehicles & Trailers",
          },
          {
            value: "43826dfa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Powersports",
          },
        ],
      },
    ],
  },
  {
    value: "437a3067-9ce0-11ea-8e84-a112b8f334ec",
    label: "Music",
    children: [
      {
        value: "437a306d-9ce0-11ea-8e84-a112b8f334ec",
        label: "Musical Instruments",
        children: [
          {
            value: "43796ce2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Accordian & Concertina",
          },
          {
            value: "4379945c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Brass",
          },
          {
            value: "4379e218-9ce0-11ea-8e84-a112b8f334ec",
            label: "DJ Equipment",
          },
          {
            value: "4379e23a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electronic Keyboards",
          },
          {
            value: "437a093e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Guitar",
          },
          {
            value: "437a0949-9ce0-11ea-8e84-a112b8f334ec",
            label: "Harmonica",
          },
          {
            value: "437a0988-9ce0-11ea-8e84-a112b8f334ec",
            label: "Instuction Books, CDs & Videos",
          },
          {
            value: "437a0990-9ce0-11ea-8e84-a112b8f334ec",
            label: "Karaoke Entertainment",
          },
          {
            value: "437a306c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Musical Equipment",
          },
          {
            value: "437a306e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Musical Organs",
          },
          {
            value: "43826d9d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Musical Instruments",
          },
          {
            value: "43826dd0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Percussion",
          },
          {
            value: "43826dd8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pianos",
          },
          {
            value: "43826e07-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pro Audio Equipment",
          },
          {
            value: "438294a2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sheet Music & Song Books",
          },
          {
            value: "438294ee-9ce0-11ea-8e84-a112b8f334ec",
            label: "Stage Lighting & Effects",
          },
          {
            value: "438294fb-9ce0-11ea-8e84-a112b8f334ec",
            label: "String",
          },
          {
            value: "4382bc16-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage instruments",
          },
          {
            value: "4382e2cb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wholesale Musical Instruments",
          },
          {
            value: "4382e2d7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Woodwind",
          },
        ],
      },
      {
        value: "43826e1d-9ce0-11ea-8e84-a112b8f334ec",
        label: "Recorded Music",
        children: [
          {
            value: "4379bb18-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cassettes",
          },
          {
            value: "4379bb1b-9ce0-11ea-8e84-a112b8f334ec",
            label: "CDs",
          },
          {
            value: "437a306b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Music Wholesale Lots",
          },
          {
            value: "43826d90-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Formats",
          },
          {
            value: "43826e1e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Records",
          },
          {
            value: "438294f8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Storage and Media Accessories",
          },
        ],
      },
      {
        value: "4382bbd1-9ce0-11ea-8e84-a112b8f334ec",
        label: "Tickets & Experiences",
        children: [
          {
            value: "4379bb4e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Concert Tickets",
          },
          {
            value: "43826dca-9ce0-11ea-8e84-a112b8f334ec",
            label: "Parking Passes",
          },
          {
            value: "438294d1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Special Experiences",
          },
          {
            value: "438294eb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sports Tickets",
          },
          {
            value: "4382bbcc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Theater & Arts Tickets",
          },
          {
            value: "4382bbce-9ce0-11ea-8e84-a112b8f334ec",
            label: "Theme Park & Club Passes",
          },
        ],
      },
    ],
  },
  {
    value: "43826db3-9ce0-11ea-8e84-a112b8f334ec",
    label: "Others",
    children: [
      {
        value: "4379e233-9ce0-11ea-8e84-a112b8f334ec",
        label: "Educational",
        children: [
          {
            value: "4379944f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Books",
          },
          {
            value: "4379bb76-9ce0-11ea-8e84-a112b8f334ec",
            label: "Digital Media",
          },
        ],
      },
      {
        value: "43824691-9ce0-11ea-8e84-a112b8f334ec",
        label: "Other",
        children: [
          {
            value: "43824692-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43824693-9ce0-11ea-8e84-a112b8f334ec",
            label: "Donations",
          },
          {
            value: "43824694-9ce0-11ea-8e84-a112b8f334ec",
            label: "Package Delivery by Sea",
          },
          {
            value: "43824695-9ce0-11ea-8e84-a112b8f334ec",
            label: "Package Delivery by Air",
          },
          {
            value: "43824696-9ce0-11ea-8e84-a112b8f334ec",
            label: "Package Delivery by Ground",
          },
        ],
      },
      {
        value: "43826e52-9ce0-11ea-8e84-a112b8f334ec",
        label: "Services",
        children: [
          {
            value: "4379944e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Book Rentals",
          },
          {
            value: "4379bb0e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cargo Shipping",
          },
        ],
      },
    ],
  },
  {
    value: "438294e6-9ce0-11ea-8e84-a112b8f334ec",
    label: "Sporting Goods",
    children: [
      {
        value: "4379945a-9ce0-11ea-8e84-a112b8f334ec",
        label: "Boxing, Martial Arts & MMA",
        children: [
          {
            value: "43799459-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boxing Gloves",
          },
          {
            value: "4379bb30-9ce0-11ea-8e84-a112b8f334ec",
            label: "Clothing, Shoes & Accessories",
          },
          {
            value: "4379e230-9ce0-11ea-8e84-a112b8f334ec",
            label: "DVDs, Videos & Books",
          },
          {
            value: "437a09cc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Martial Arts Gloves",
          },
          {
            value: "437a09cd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Martial Arts Weapons",
          },
          {
            value: "438246a5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Combat Sport Supplies",
          },
          {
            value: "43826e0a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Protective Gear",
          },
          {
            value: "438294a8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Boxing, Martial Arts & MMA",
          },
          {
            value: "4382bbee-9ce0-11ea-8e84-a112b8f334ec",
            label: "Training Equipment & Supplies",
          },
        ],
      },
      {
        value: "4379bb64-9ce0-11ea-8e84-a112b8f334ec",
        label: "Cycling",
        children: [
          {
            value: "4379943b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bicycle Accessories",
          },
          {
            value: "4379943c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bicycle Components & Parts",
          },
          {
            value: "4379943d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bicycle Electronics",
          },
          {
            value: "4379943e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bicycle Frames",
          },
          {
            value: "4379943f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bicycle Maintenance & Tools",
          },
          {
            value: "43799440-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bicycles",
          },
          {
            value: "43799450-9ce0-11ea-8e84-a112b8f334ec",
            label: "Books & Video",
          },
          {
            value: "4379bb03-9ce0-11ea-8e84-a112b8f334ec",
            label: "Car & Truck Racks",
          },
          {
            value: "4379bb66-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cycling Clothing",
          },
          {
            value: "4379bb67-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cycling Shoes & Shoe Covers",
          },
          {
            value: "4379e234-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electric Bicycles",
          },
          {
            value: "437a095b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Helmets & Protective Gear",
          },
          {
            value: "438246a8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Cycling",
          },
          {
            value: "43829500-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sunglasses & Goggles",
          },
          {
            value: "4382bc12-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Cycling",
          },
        ],
      },
      {
        value: "4379e25b-9ce0-11ea-8e84-a112b8f334ec",
        label: "Fishing",
        children: [
          {
            value: "4379941d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Baits, Lures & Flies",
          },
          {
            value: "43799451-9ce0-11ea-8e84-a112b8f334ec",
            label: "Books & Videos",
          },
          {
            value: "4379bb31-9ce0-11ea-8e84-a112b8f334ec",
            label: "Clothing, Shoes & Accessories",
          },
          {
            value: "4379e25d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fishing Equipment",
          },
          {
            value: "4379e25e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fishing Trips",
          },
          {
            value: "43824681-9ce0-11ea-8e84-a112b8f334ec",
            label: "Novelties & Gifts",
          },
          {
            value: "438246aa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Fishing",
          },
          {
            value: "43826e1f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Reel Parts & Repair",
          },
          {
            value: "43826e20-9ce0-11ea-8e84-a112b8f334ec",
            label: "Reels",
          },
          {
            value: "43826e32-9ce0-11ea-8e84-a112b8f334ec",
            label: "Rod Building & Repair",
          },
          {
            value: "43826e33-9ce0-11ea-8e84-a112b8f334ec",
            label: "Rods",
          },
          {
            value: "4382bbb9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tackle Craft",
          },
          {
            value: "4382bbbe-9ce0-11ea-8e84-a112b8f334ec",
            label: "Taxidermy",
          },
          {
            value: "4382bc0d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage",
          },
        ],
      },
      {
        value: "4379e25f-9ce0-11ea-8e84-a112b8f334ec",
        label: "Fitness & Running",
        children: [
          {
            value: "4379bb0d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cardio Equipment",
          },
          {
            value: "4379bb2f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Clothing & Accessories",
          },
          {
            value: "4379e260-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fitness & Running",
          },
          {
            value: "4379e261-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fitness DVDs",
          },
          {
            value: "4379e262-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fitness Equipment & Gear",
          },
          {
            value: "4379e263-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fitness Technology",
          },
          {
            value: "437a0942-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gym Bags",
          },
          {
            value: "438246ab-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Fitness & Running",
          },
          {
            value: "438294a6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shoes",
          },
          {
            value: "438294fa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Strength Training",
          },
        ],
      },
      {
        value: "437a092c-9ce0-11ea-8e84-a112b8f334ec",
        label: "Golf",
        children: [
          {
            value: "437a092e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf Accessories",
          },
          {
            value: "437a092f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf Clothing, Shoes & Accs",
          },
          {
            value: "437a0930-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf Club Components",
          },
          {
            value: "437a0931-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf Clubs & Equipment",
          },
          {
            value: "437a0932-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf Training Aids",
          },
          {
            value: "43826d93-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Golf",
          },
          {
            value: "4382bc14-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Golf Equipment",
          },
          {
            value: "4382e2e7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf Caps",
          },
          {
            value: "4382e2ee-9ce0-11ea-8e84-a112b8f334ec",
            label: "Helmets",
          },
        ],
      },
      {
        value: "437a0974-9ce0-11ea-8e84-a112b8f334ec",
        label: "Hunting",
        children: [
          {
            value: "43799448-9ce0-11ea-8e84-a112b8f334ec",
            label: "Blinds & Treestands",
          },
          {
            value: "43799452-9ce0-11ea-8e84-a112b8f334ec",
            label: "Books & Videos",
          },
          {
            value: "43799454-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bow Hunting",
          },
          {
            value: "4379bb32-9ce0-11ea-8e84-a112b8f334ec",
            label: "Clothing, Shoes & Accessories",
          },
          {
            value: "4379bb6c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Decoys",
          },
          {
            value: "4379e27d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Game & Trail Cameras",
          },
          {
            value: "437a093f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gun Parts",
          },
          {
            value: "437a0940-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gun Smithing & Maintenance",
          },
          {
            value: "437a0941-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gun Storage",
          },
          {
            value: "437a0963-9ce0-11ea-8e84-a112b8f334ec",
            label: "Holsters, Belts & Pouches",
          },
          {
            value: "437a0976-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hunting Accessories",
          },
          {
            value: "437a0977-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hunting Dog Supplies",
          },
          {
            value: "437a0978-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hunting Trips & Leases",
          },
          {
            value: "437a099e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Knives & Tools",
          },
          {
            value: "43826e16-9ce0-11ea-8e84-a112b8f334ec",
            label: "Range & Shooting Accessories",
          },
          {
            value: "43826e22-9ce0-11ea-8e84-a112b8f334ec",
            label: "Reloading Equipment",
          },
          {
            value: "43826e46-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scopes, Optics & Lasers",
          },
          {
            value: "4382bbba-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tactical Gear",
          },
          {
            value: "4382bc15-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vintage Hunting",
          },
        ],
      },
      {
        value: "437a097e-9ce0-11ea-8e84-a112b8f334ec",
        label: "Indoor Games",
        children: [
          {
            value: "43796cee-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Hockey",
          },
          {
            value: "43799441-9ce0-11ea-8e84-a112b8f334ec",
            label: "Billiards",
          },
          {
            value: "4379e26d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Foosball",
          },
          {
            value: "437a097f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Indoor Roller Skating",
          },
          {
            value: "43826d99-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Indoor Games",
          },
          {
            value: "43826ddd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ping Pong",
          },
          {
            value: "438294aa-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Indoor Games",
          },
          {
            value: "438294b6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shuffleboard",
          },
        ],
      },
      {
        value: "43826db8-9ce0-11ea-8e84-a112b8f334ec",
        label: "Outdoor Sports",
        children: [
          {
            value: "43796ced-9ce0-11ea-8e84-a112b8f334ec",
            label: "Air Guns & Slingshots",
          },
          {
            value: "437993f1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Airsoft",
          },
          {
            value: "43799400-9ce0-11ea-8e84-a112b8f334ec",
            label: "Archery",
          },
          {
            value: "43799476-9ce0-11ea-8e84-a112b8f334ec",
            label: "Camping & Hiking",
          },
          {
            value: "4379bb2c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Climbing & Caving",
          },
          {
            value: "4379e212-9ce0-11ea-8e84-a112b8f334ec",
            label: "Disc Golf",
          },
          {
            value: "4379e243-9ce0-11ea-8e84-a112b8f334ec",
            label: "Equestrian",
          },
          {
            value: "437a0924-9ce0-11ea-8e84-a112b8f334ec",
            label: "Geocaching",
          },
          {
            value: "437a092a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Go-Karts Recreational",
          },
          {
            value: "437a0948-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hang Gliding & Paragliding",
          },
          {
            value: "437a0987-9ce0-11ea-8e84-a112b8f334ec",
            label: "Inline & Roller Skating",
          },
          {
            value: "43826d9f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Outdoor Sports",
          },
          {
            value: "43826dbf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paintball",
          },
          {
            value: "43826e45-9ce0-11ea-8e84-a112b8f334ec",
            label: "Scooters & Hover Boards",
          },
          {
            value: "438294bb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Skateboarding & Longboarding",
          },
          {
            value: "438294c0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sky Diving",
          },
          {
            value: "4382bbe7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Track & Field",
          },
          {
            value: "4382bbf3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Triathlon",
          },
        ],
      },
      {
        value: "438294e7-9ce0-11ea-8e84-a112b8f334ec",
        label: "Sporting Goods Wholesale",
        children: [
          {
            value: "4379bb65-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cycling",
          },
          {
            value: "4379e244-9ce0-11ea-8e84-a112b8f334ec",
            label: "Equestrian",
          },
          {
            value: "4379e25c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fishing",
          },
          {
            value: "437a092d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Golf",
          },
          {
            value: "437a0975-9ce0-11ea-8e84-a112b8f334ec",
            label: "Hunting",
          },
          {
            value: "43826db0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Wholesale Sporting Goods",
          },
          {
            value: "43826dc0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Paintball",
          },
          {
            value: "438294ad-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Sporting Goods Wholesale Lots",
          },
        ],
      },
      {
        value: "4382bbc0-9ce0-11ea-8e84-a112b8f334ec",
        label: "Team Sports",
        children: [
          {
            value: "43799423-9ce0-11ea-8e84-a112b8f334ec",
            label: "Baseball & Softball",
          },
          {
            value: "43799424-9ce0-11ea-8e84-a112b8f334ec",
            label: "Basketball",
          },
          {
            value: "43799455-9ce0-11ea-8e84-a112b8f334ec",
            label: "Bowling",
          },
          {
            value: "4379bb25-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cheerleading",
          },
          {
            value: "4379bb5e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cricket",
          },
          {
            value: "4379bb62-9ce0-11ea-8e84-a112b8f334ec",
            label: "Curling",
          },
          {
            value: "4379e251-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fencing",
          },
          {
            value: "4379e252-9ce0-11ea-8e84-a112b8f334ec",
            label: "Field Hockey",
          },
          {
            value: "4379e26e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Football",
          },
          {
            value: "4379e27c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gaelic Sports",
          },
          {
            value: "437a0943-9ce0-11ea-8e84-a112b8f334ec",
            label: "Gymnastics",
          },
          {
            value: "437a097a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ice & Roller Hockey",
          },
          {
            value: "437a0980-9ce0-11ea-8e84-a112b8f334ec",
            label: "Indoor Roller Skating",
          },
          {
            value: "437a09a2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lacrosse",
          },
          {
            value: "43826e36-9ce0-11ea-8e84-a112b8f334ec",
            label: "Rugby",
          },
          {
            value: "438294cd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Soccer",
          },
          {
            value: "4382bc1e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Volleyball",
          },
          {
            value: "4382e2da-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wrestling",
          },
        ],
      },
      {
        value: "4382bbc6-9ce0-11ea-8e84-a112b8f334ec",
        label: "Tennis & Racquet Sports",
        children: [
          {
            value: "437993fd-9ce0-11ea-8e84-a112b8f334ec",
            label: "Apparel & Accessories",
          },
          {
            value: "4379941b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Badminton",
          },
          {
            value: "43826da2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Racquet Sports",
          },
          {
            value: "43826e13-9ce0-11ea-8e84-a112b8f334ec",
            label: "Racquetball",
          },
          {
            value: "438294ae-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Tennis & Racquet Sports",
          },
          {
            value: "438294ed-9ce0-11ea-8e84-a112b8f334ec",
            label: "Squash",
          },
          {
            value: "4382bbc5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tennis",
          },
        ],
      },
      {
        value: "4382bc26-9ce0-11ea-8e84-a112b8f334ec",
        label: "Water Sports",
        children: [
          {
            value: "4379e257-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fins, Footwear & Gloves",
          },
          {
            value: "437a0991-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kayaking, Canoeing & Rafting",
          },
          {
            value: "437a099c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kitesurfing",
          },
          {
            value: "43826dae-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Water Sports",
          },
          {
            value: "43826e4b-9ce0-11ea-8e84-a112b8f334ec",
            label: "SCUBA & Snorkeling",
          },
          {
            value: "438294af-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Water Sports",
          },
          {
            value: "438294f1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Stand Up Paddleboarding",
          },
          {
            value: "43829502-9ce0-11ea-8e84-a112b8f334ec",
            label: "Surfing",
          },
          {
            value: "4382bbb2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Swimming",
          },
          {
            value: "4382bbb4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Swimwear & Safety",
          },
          {
            value: "4382bc1f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wakeboarding & Waterskiing",
          },
          {
            value: "4382e2c1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wetsuits & Drysuits",
          },
        ],
      },
      {
        value: "4382e2d1-9ce0-11ea-8e84-a112b8f334ec",
        label: "Winter Sports",
        children: [
          {
            value: "43796ce0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Accessories",
          },
          {
            value: "4379bb2e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Clothing",
          },
          {
            value: "4379bb5f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cross Country Skiing",
          },
          {
            value: "4379e21e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Downhill Skiing",
          },
          {
            value: "437a097b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ice Skating",
          },
          {
            value: "437a09ae-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lift Tickets",
          },
          {
            value: "43824697-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "438294bc-9ce0-11ea-8e84-a112b8f334ec",
            label: "Ski Touring",
          },
          {
            value: "438294c1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sleds & Snow Tubes",
          },
          {
            value: "438294c8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Snowboarding",
          },
          {
            value: "438294ca-9ce0-11ea-8e84-a112b8f334ec",
            label: "Snowmobiling",
          },
          {
            value: "438294cb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Snowshoeing",
          },
          {
            value: "4382bbc2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Telemarking",
          },
        ],
      },
      {
        value: "4382e2df-9ce0-11ea-8e84-a112b8f334ec",
        label: "Yoga & Pilates",
        children: [
          {
            value: "437a09ce-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mat Carriers & Bags",
          },
          {
            value: "437a3031-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mats & Non-Slip Towels",
          },
          {
            value: "43826db2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Yoga & Pilates",
          },
          {
            value: "43826dd9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pilates Accessories",
          },
          {
            value: "43826dda-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pilates Rings",
          },
          {
            value: "43826ddb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pilates Tables",
          },
          {
            value: "4382e2e0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Yoga Props",
          },
          {
            value: "4382e2e1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Yoga Straps",
          },
        ],
      },
      {
        value: "4382e2e8-9ce0-11ea-8e84-a112b8f334ec",
        label: "Sporting Accessories ",
        children: [
          {
            value: "4382e2e9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Caps for Golf",
          },
          {
            value: "4382e2ea-9ce0-11ea-8e84-a112b8f334ec",
            label: "Caps for Runing & Cycling",
          },
          {
            value: "4382e2eb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Caps for Fishing & Hunting",
          },
          {
            value: "4382e2ec-9ce0-11ea-8e84-a112b8f334ec",
            label: "Outdoor Caps ",
          },
          {
            value: "4382e2ed-9ce0-11ea-8e84-a112b8f334ec",
            label: "Indoor Caps ",
          },
        ],
      },
    ],
  },
  {
    value: "4382bbe5-9ce0-11ea-8e84-a112b8f334ec",
    label: "Toys & Hobbies",
    children: [
      {
        value: "43796ce3-9ce0-11ea-8e84-a112b8f334ec",
        label: "Action Figures",
        children: [
          {
            value: "437993f5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Animals & Dinosaurs",
          },
          {
            value: "4379bb42-9ce0-11ea-8e84-a112b8f334ec",
            label: "Comic Book Heroes",
          },
          {
            value: "4379bb6e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Designer & Urban Vinyl",
          },
          {
            value: "4379e24b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fantasy",
          },
          {
            value: "437a095e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Historical Figures",
          },
          {
            value: "437a304a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Military & Adventure",
          },
          {
            value: "437a304f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mixed Lots",
          },
          {
            value: "437a3068-9ce0-11ea-8e84-a112b8f334ec",
            label: "Music",
          },
          {
            value: "438294e8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Sports",
          },
          {
            value: "4382bbf0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Transformers & Robots",
          },
          {
            value: "4382bbf8-9ce0-11ea-8e84-a112b8f334ec",
            label: "TV, Movie & Video Games",
          },
        ],
      },
      {
        value: "43799467-9ce0-11ea-8e84-a112b8f334ec",
        label: "Building Toys",
        children: [
          {
            value: "43799465-9ce0-11ea-8e84-a112b8f334ec",
            label: "Building Blocks",
          },
          {
            value: "4379e245-9ce0-11ea-8e84-a112b8f334ec",
            label: "Erector Sets",
          },
          {
            value: "437a099d-9ce0-11ea-8e84-a112b8f334ec",
            label: "K'NEX",
          },
          {
            value: "437a09ab-9ce0-11ea-8e84-a112b8f334ec",
            label: "LEGO",
          },
          {
            value: "437a09b2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Lincoln Logs",
          },
          {
            value: "437a303b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mega Bloks",
          },
        ],
      },
      {
        value: "4379bb73-9ce0-11ea-8e84-a112b8f334ec",
        label: "Diecast & Toy Vehicles",
        children: [
          {
            value: "43796ce1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Accessories, Parts & Display",
          },
          {
            value: "43796cf1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Aircraft",
          },
          {
            value: "437993f0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Aircrafts",
          },
          {
            value: "4379944c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boats & Ships",
          },
          {
            value: "4379bb14-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cars, Trucks & Vans",
          },
          {
            value: "4379bb15-9ce0-11ea-8e84-a112b8f334ec",
            label: "Cars: Racing, NASCAR",
          },
          {
            value: "4379bb3f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Collections & Lots",
          },
          {
            value: "4379bb53-9ce0-11ea-8e84-a112b8f334ec",
            label: "Construction Equipment",
          },
          {
            value: "4379e24d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Farm Vehicles",
          },
          {
            value: "437a305c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Motorcycles & ATVs",
          },
          {
            value: "43824699-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43826dab-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Vehicles 1971-Now",
          },
          {
            value: "43826dac-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Vehicles Pre-1971",
          },
          {
            value: "43826de3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Play Sets",
          },
          {
            value: "43826e01-9ce0-11ea-8e84-a112b8f334ec",
            label: "Price Guides & Publications",
          },
          {
            value: "4382bbbb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tanks & Military Vehicles",
          },
          {
            value: "4382bc01-9ce0-11ea-8e84-a112b8f334ec",
            label: "Vehicle Banks",
          },
        ],
      },
      {
        value: "4379e232-9ce0-11ea-8e84-a112b8f334ec",
        label: "Educational",
        children: [
          {
            value: "437993f2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Alphabet & Language",
          },
          {
            value: "4379e23b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electronic Learning Toys",
          },
          {
            value: "437a0925-9ce0-11ea-8e84-a112b8f334ec",
            label: "Geography & History",
          },
          {
            value: "437a3030-9ce0-11ea-8e84-a112b8f334ec",
            label: "Mathematics",
          },
          {
            value: "437a3063-9ce0-11ea-8e84-a112b8f334ec",
            label: "Multilingual",
          },
          {
            value: "437a3064-9ce0-11ea-8e84-a112b8f334ec",
            label: "Multiple Subjects, Mixed Lots",
          },
          {
            value: "437a3069-9ce0-11ea-8e84-a112b8f334ec",
            label: "Music & Art",
          },
          {
            value: "43824682-9ce0-11ea-8e84-a112b8f334ec",
            label: "Numbers",
          },
          {
            value: "4382469a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43826e1c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Reading & Writing",
          },
          {
            value: "43826e40-9ce0-11ea-8e84-a112b8f334ec",
            label: "Science & Nature",
          },
          {
            value: "438294a9-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Educational",
          },
          {
            value: "438294d2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Special Needs & Autism",
          },
          {
            value: "4382bbd4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Time",
          },
        ],
      },
      {
        value: "4379e280-9ce0-11ea-8e84-a112b8f334ec",
        label: "Games",
        children: [
          {
            value: "43799449-9ce0-11ea-8e84-a112b8f334ec",
            label: "Board & Traditional Games",
          },
          {
            value: "4379bb0c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Card Games & Poker",
          },
          {
            value: "4379e238-9ce0-11ea-8e84-a112b8f334ec",
            label: "Electronic",
          },
          {
            value: "4379e27e-9ce0-11ea-8e84-a112b8f334ec",
            label: "Game Pieces & Parts",
          },
          {
            value: "437a304c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Miniatures, War Games",
          },
          {
            value: "43826e34-9ce0-11ea-8e84-a112b8f334ec",
            label: "Role Playing Games",
          },
        ],
      },
      {
        value: "437a3053-9ce0-11ea-8e84-a112b8f334ec",
        label: "Model Railroads & Trains",
        children: [
          {
            value: "4379e27a-9ce0-11ea-8e84-a112b8f334ec",
            label: "G Scale",
          },
          {
            value: "437a0960-9ce0-11ea-8e84-a112b8f334ec",
            label: "HO Scale",
          },
          {
            value: "437a3070-9ce0-11ea-8e84-a112b8f334ec",
            label: "N Scale",
          },
          {
            value: "43824687-9ce0-11ea-8e84-a112b8f334ec",
            label: "O Scale",
          },
          {
            value: "4382468d-9ce0-11ea-8e84-a112b8f334ec",
            label: "OO Scale",
          },
          {
            value: "43826da3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Rare Scale Model Railroads & Trains",
          },
          {
            value: "43826df7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Power & Control",
          },
          {
            value: "43826e38-9ce0-11ea-8e84-a112b8f334ec",
            label: "S Scale",
          },
          {
            value: "438294f2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Standard Scale",
          },
        ],
      },
      {
        value: "437a3054-9ce0-11ea-8e84-a112b8f334ec",
        label: "Models & Kits",
        children: [
          {
            value: "43796cf2-9ce0-11ea-8e84-a112b8f334ec",
            label: "Aircraft Non-Military",
          },
          {
            value: "437993f7-9ce0-11ea-8e84-a112b8f334ec",
            label: "Anime",
          },
          {
            value: "43799410-9ce0-11ea-8e84-a112b8f334ec",
            label: "Automotive",
          },
          {
            value: "4379944d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Boats & Ships",
          },
          {
            value: "4379bb23-9ce0-11ea-8e84-a112b8f334ec",
            label: "Character Figures",
          },
          {
            value: "4379bb7b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dioramas",
          },
          {
            value: "437a3049-9ce0-11ea-8e84-a112b8f334ec",
            label: "Military",
          },
          {
            value: "437a3057-9ce0-11ea-8e84-a112b8f334ec",
            label: "Monster",
          },
          {
            value: "437a3059-9ce0-11ea-8e84-a112b8f334ec",
            label: "Motorcycle",
          },
          {
            value: "4382469b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43826e09-9ce0-11ea-8e84-a112b8f334ec",
            label: "Promo",
          },
          {
            value: "43826e30-9ce0-11ea-8e84-a112b8f334ec",
            label: "Rocketry",
          },
          {
            value: "43826e41-9ce0-11ea-8e84-a112b8f334ec",
            label: "Science Fiction",
          },
          {
            value: "438294ab-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Models & Kits",
          },
          {
            value: "438294d0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Spacecraft",
          },
          {
            value: "43829501-9ce0-11ea-8e84-a112b8f334ec",
            label: "Super Hero",
          },
          {
            value: "4382bbdf-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tools, Supplies & Engines",
          },
          {
            value: "4382e2d6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Wooden",
          },
        ],
      },
      {
        value: "43826dfe-9ce0-11ea-8e84-a112b8f334ec",
        label: "Preschool Toys & Pretend Play",
        children: [
          {
            value: "4379945f-9ce0-11ea-8e84-a112b8f334ec",
            label: "Brio",
          },
          {
            value: "43799460-9ce0-11ea-8e84-a112b8f334ec",
            label: "Brio Compatible",
          },
          {
            value: "4379bb7a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dinosaurs",
          },
          {
            value: "4379e214-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dish & Tea Sets",
          },
          {
            value: "4379e219-9ce0-11ea-8e84-a112b8f334ec",
            label: "Doctor & Nurse Kits",
          },
          {
            value: "4379e226-9ce0-11ea-8e84-a112b8f334ec",
            label: "Dress-Up & Costumes",
          },
          {
            value: "4379e25a-9ce0-11ea-8e84-a112b8f334ec",
            label: "Fisher-Price",
          },
          {
            value: "437a099b-9ce0-11ea-8e84-a112b8f334ec",
            label: "Kitchens",
          },
          {
            value: "437a09b5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Little Tikes",
          },
          {
            value: "437a09b6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Littlest Pet Shop",
          },
          {
            value: "4382469c-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other",
          },
          {
            value: "43826de4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Play-Doh & Modeling Clay",
          },
          {
            value: "43826de5-9ce0-11ea-8e84-a112b8f334ec",
            label: "Playmobil",
          },
          {
            value: "43826de6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Playskool",
          },
          {
            value: "43826e10-9ce0-11ea-8e84-a112b8f334ec",
            label: "Puppets",
          },
          {
            value: "438294ac-9ce0-11ea-8e84-a112b8f334ec",
            label: "Shop all Preschool Toys & Pretend Play",
          },
          {
            value: "438294f6-9ce0-11ea-8e84-a112b8f334ec",
            label: "Step",
          },
          {
            value: "4382bbdb-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tool Sets",
          },
        ],
      },
      {
        value: "43826e15-9ce0-11ea-8e84-a112b8f334ec",
        label: "Radio Control and Control Line",
        children: [
          {
            value: "4379bb55-9ce0-11ea-8e84-a112b8f334ec",
            label: "Control Line & Freeflight",
          },
          {
            value: "43826da4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other RC & Control Line",
          },
          {
            value: "43826e19-9ce0-11ea-8e84-a112b8f334ec",
            label: "RC Engines, Parts & Accessories",
          },
          {
            value: "43826e1a-9ce0-11ea-8e84-a112b8f334ec",
            label: "RC Vehicle Plans & Templates",
          },
          {
            value: "43826e1b-9ce0-11ea-8e84-a112b8f334ec",
            label: "RC Vehicles",
          },
          {
            value: "438294ba-9ce0-11ea-8e84-a112b8f334ec",
            label: "Simulators",
          },
          {
            value: "4382bbc8-9ce0-11ea-8e84-a112b8f334ec",
            label: "Tether Cars",
          },
        ],
      },
      {
        value: "4382bbeb-9ce0-11ea-8e84-a112b8f334ec",
        label: "Trading Card Games",
        children: [
          {
            value: "437a09ba-9ce0-11ea-8e84-a112b8f334ec",
            label: "Magic The Gathering Cards Merchandise",
          },
          {
            value: "438246a4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Other Collectible Card Game Items",
          },
          {
            value: "43826ded-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pokémon Trading Card Games",
          },
          {
            value: "438294f3-9ce0-11ea-8e84-a112b8f334ec",
            label: "Star Trek Trading Cards",
          },
          {
            value: "438294f4-9ce0-11ea-8e84-a112b8f334ec",
            label: "Star Wars Trading Cards",
          },
          {
            value: "4382e2f1-9ce0-11ea-8e84-a112b8f334ec",
            label: "Yu-Gi-Oh Trading Card Game",
          },
        ],
      },
      {
        value: "4382bbf7-9ce0-11ea-8e84-a112b8f334ec",
        label: "TV, Movie & Character Toys",
        children: [
          {
            value: "4379942d-9ce0-11ea-8e84-a112b8f334ec",
            label: "Batman",
          },
          {
            value: "4379943a-9ce0-11ea-8e84-a112b8f334ec",
            label: "BeyBlade",
          },
          {
            value: "4379bb74-9ce0-11ea-8e84-a112b8f334ec",
            label: "Digimon",
          },
          {
            value: "4379e216-9ce0-11ea-8e84-a112b8f334ec",
            label: "Disney",
          },
          {
            value: "4379e21f-9ce0-11ea-8e84-a112b8f334ec",
            label: "DragonBall Z",
          },
          {
            value: "437a3066-9ce0-11ea-8e84-a112b8f334ec",
            label: "Muppets & Sesame Street",
          },
          {
            value: "437a306f-9ce0-11ea-8e84-a112b8f334ec",
            label: "My Little Pony",
          },
          {
            value: "43826dec-9ce0-11ea-8e84-a112b8f334ec",
            label: "Pokemon",
          },
          {
            value: "4382bbd0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Thomas the Tank Engine",
          },
          {
            value: "4382e2d0-9ce0-11ea-8e84-a112b8f334ec",
            label: "Winnie the Pooh",
          },
        ],
      },
    ],
  },
];
