import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class AcceptedPaymentsPolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Accepted Payments Policy" />
          <CSBreadcrumb label={"Accepted Payments Policy"} />
        </div>
        
        <Widget styleName="mb-0">
          <div>
            <p>
              Our accepted payments policy gives buyers and sellers on
              Tradesbarn convenient payment options. To help ensure a safe
              marketplace, as a seller you can only use our approved payment
              methods. You also must state in your listing which specific
              payment methods you accept. If you accept a payment method, don't
              place limitations on what forms of it you accept. Make sure your
              listing follows these guidelines. If it doesn't, it may be
              removed, and you may be subject to a range of other actions,
              including limits of your buying and selling privileges and
              suspension of your account.
            </p>
            <p>What are the guidelines?</p>
            <p>
              <u>
                <b>Allowed</b>
              </u>
            </p>
            <ul>
              <li>
                <p>PayPal</p>
              </li>
              <li>
                <p>PayPal Credit</p>
              </li>
              <li>
                <p>
                  Credit card or debit card processed through the seller's
                  Internet merchant account
                </p>
              </li>
            </ul>
            <p>
              <u>
                <b>Restricted</b>
              </u>
            </p>
            <ul>
              <li>
                <p>
                  Bank-to-bank transfers (also known as bank wire transfers and
                  bank cash transfers)
                </p>
              </li>
              <li>
                <p>Checks</p>
              </li>
              <li>
                <p>Money orders</p>
              </li>
              <li>
                <p>
                  Online payment services: Allpay.net, CertaPay, Fiserv,
                  Nochex.com, XOOM
                </p>
              </li>
              <li>
                <p>
                  Any payments that occur outside of Tradesbarn pages are not
                  allowed, except in certain listing categories. See the full
                  list of categories that allow bank-to-bank transfers, checks,
                  money orders, and the permitted online payment services.
                </p>
              </li>
            </ul>
            <p>
              <u>
                <b>Not allowed</b>
              </u>
            </p>
            <ul>
              <li>
                <p>Sending cash through the mail</p>
              </li>
              <li>
                <p>
                  Sending cash or money orders through instant, point-to-point
                  cash transfer services (that aren't banks) such as Western
                  Union or MoneyGram
                </p>
              </li>
              <li>
                <p>
                  Mailing checks or money orders (except for items in categories
                  specifically permitted)
                </p>
              </li>
              <li>
                <p>
                  Paying through bank-to-bank transfers (except for items in
                  categories specifically permitted)
                </p>
              </li>
              <li>
                <p>
                  Paying by "topping off" a seller's prepaid credit or debit
                  card
                </p>
              </li>
              <li>
                <p>
                  Paying using online or other payment methods not specifically
                  permitted in this policy
                </p>
              </li>
              <li>
                <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
                  Asking buyers to contact you for additional payment methods,
                  or including misleading or unclear payment information
                </p>
              </li>
              <li>
                <p>
                  Offering a payment method to some buyers and not to others
                </p>
              </li>
              <li>
                <p>
                  Discouraging buyers from using any payment method you
                  specified in the listing
                </p>
              </li>
              <li>
                <p>
                  Discouraging buyers from using Tradesbarn's accepted payment
                  methods
                </p>
              </li>
              <li>
                <p>
                  Asking buyers to pay using a method not mentioned in the
                  listing
                </p>
              </li>
              <li>
                <p>Paying with Virtual or Cryptocurrencies</p>
              </li>
              <li>
                <p>
                  Placing limitations on payment methods you accept. For
                  example:
                </p>
              </li>
            </ul>
            <p className="ml-5">
              <i>
                "I accept PayPal but do not accept credit card payments through
                PayPal"
              </i>
            </p>
            <p className="ml-5">
              <i>"I only accept PayPal if the amount is greater than $60"</i>
            </p>
            <br />
            <p>
              These rules apply to all transaction-related correspondence
              between a seller and buyer, as well as to the listings. In
              addition, you aren't allowed to make statements such as:
            </p>
            <p className="ml-5">
              <i>"Contact us for payment information."</i>
            </p>
            <p className="ml-5">
              <i>"Contact us for other payment methods."</i>
            </p>
            <p className="ml-5">
              <i>"Contact us for your preferred payment method."</i>
            </p>
            <p className="ml-5">
              <i>"Buyers may request to pay by check or money order."</i>
            </p>
          </div>
          <br />

          <p>
            <u>
              <b>
                Categories allowing bank-to-bank transfers, checks, money order
                and online payment services.
              </b>
            </u>
          </p>
          <p>Motors &gt; Boats</p>
          <p>Motors &gt; Cars &amp; Trucks</p>
          <p>
            Motors &gt; Cars &amp; Trucks &gt; Collector Cars Motors &gt;
            Motorcycles
          </p>
          <p>Motors &gt; Powersports</p>
          <p>Motors &gt; Other Vehicles &amp; Trailers</p>
          <p>
            Motors &gt; Automotive Tools &amp; Supplies &gt; Air Compressors
          </p>
          <p>
            Motors &gt; Automotive Tools &amp; Supplies &gt; Shop Equipment
            &amp; Supplies &gt; Brake Lathes
          </p>
          <p>
            Motors &gt; Automotive Tools &amp; Supplies &gt; Shop Equipment
            &amp; Supplies &gt; Auto Lifts &amp; Frame Machines Motors &gt;
            Automotive Tools &amp; Supplies &gt; Shop Equipment &amp; Supplies
            &gt; Other Shop Equipment Motors &gt; Automotive Tools &amp;
            Supplies &gt; Shop Equipment &amp; Supplies &gt; Paint Booths
          </p>
          <p>
            Motors &gt; Automotive Tools &amp; Supplies &gt; Shop Equipment
            &amp; Supplies &gt; Tire Changers / Wheel Balancers Motors &gt;
            Parts &amp; Accessories &gt; Aviation Parts &gt; Engines
          </p>
          <p>
            Motors &gt; Parts &amp; Accessories &gt; Car &amp; Truck Parts &gt;
            Engines &amp; Components Motors &gt; Parts &amp; Accessories &gt;
            Motorcycle Parts &gt; Engines &amp; Components
          </p>
          <p>
            Motors &gt; Parts &amp; Accessories &gt; Performance &amp; Racing
            Parts &gt; Auto Performance Parts &gt; Engine &amp; Components
            Motors &gt; Parts &amp; Accessories &gt; Salvage Parts Cars
          </p>
          <p>
            Motors &gt; Parts &amp; Accessories &gt; Vintage Car &amp; Truck
            Parts &gt; Engines &amp; Components Business &amp; Industrial &gt;
            Construction &gt; Buildings, Modular &amp; Pre-Fab
          </p>
          <p>
            Business &amp; Industrial &gt; Healthcare, Lab &amp; Life Science
            &gt; Imaging &amp; Aesthetics Equipment Business &amp; Industrial
            &gt; Heavy Equipment (all subcategories)
          </p>
          <p>
            Business &amp; Industrial &gt; Heavy Equipment Parts &amp;
            Accessories (all subcategories)
          </p>
          <p>
            Business &amp; Industrial &gt; Manufacturing &amp; Metalworking &gt;
            Woodworking &gt; Equipment &amp; Machinery Business &amp; Industrial
            &gt; Industrial Supply &amp; MRO &gt; Cleaning Equipment &amp;
            Supplies &gt; Sweepers &amp; Scrubbers Business &amp; Industrial
            &gt; Industrial Supply&amp; MRO &gt; Fork Lifts &amp; Other Lifts
          </p>
          <p>
            Business &amp; Industrial &gt; MRO &amp; Industrial Supply &gt; HVAC
            &gt; HVAC Units
          </p>
          <p>
            Business &amp; Industrial &gt; Printing &amp; Graphic Arts &gt;
            Bindery &amp; Finishing Equipment Business &amp; Industrial &gt;
            Printing &amp; Graphic Arts &gt; Commercial Printing Presses
            Business &amp; Industrial &gt; Printing &amp; Graphic Arts &gt;
            Plotters, Wide Format Printing
          </p>
          <p>
            Business &amp; Industrial &gt; Restaurant &amp; Catering &gt;
            Commercial Kitchen Equipment &gt; Cooking &amp; Warming Equipment
            &gt; Ovens &amp; Ranges
          </p>
          <p>Coins &amp; Paper Money &gt; Bullion &gt; Wholesale Bullion</p>
          <p>
            Business &amp; Industrial &gt; Restaurant &amp; Catering &gt;
            Concession Trailers &amp; Carts &gt; Concession Trailers Business
            &amp; Industrial &gt; Restaurant &amp; Catering &gt; Refrigeration
            &amp; Ice Machines
          </p>
          <p>Real Estate</p>
          <p>Everything Else &gt; Adult Only</p>

          <br />
          <p>
            <u>
              <b>Why does Tradesbarn have this policy?</b>
            </u>
          </p>

          <p>
            This policy helps to create a successful shopping and selling
            experience by encouraging online payment methods that are safe, easy
            to use, and offer strong protections for members.
          </p>
          <br />
          <p>
            <u>
              <b>Funds availability</b>
            </u>
          </p>
          <p>
            Based on your shipping method and the shipping information you
            provide, here's when your funds should be available
          </p>
          <ul>
            <li>
              <p>
                Within 24 hours after we confirm the order was delivered, if you
                pay for shipping and print labels on Tradesbarn.
              </p>
            </li>
            <li>
              <p>
                Within 24 hours after we confirm the order was delivered, if you
                ship directly using USPS, UPS or FedEx and manually enter your
                tracking information to My Tradesbarn.
              </p>
            </li>
            <li>
              <p>
                Seven days after your latest estimated delivery date, if you
                don't upload tracking information but you mark the item as
                shipped in My Tradesbarn.
              </p>
            </li>
            <li>
              <p>
                21 days after the buyer pays, if you don't provide tracking
                information, mark the item as shipped, or the estimated delivery
                date can't be calculated.
              </p>
            </li>
          </ul>

          <p>
            Keep in mind that it may take longer to get funds if your buyer
            reports a problem with the transaction.
          </p>
          <br />
          <p>
            <u>
              <b>Pending funds</b>
            </u>
          </p>

          <p>
            Some payments may show as pending in your PayPal account and may not
            be immediately available. Below are the most common reasons your
            funds would display as pending
          </p>
          <ul>
            <li>
              <p>
                New sellers or those with limited buying and/ or selling
                histories may not have met the following requirements
              </p>
            </li>
            <li>
              <p>
                Your current performance has fallen below the minimum standard
                for all Tradesbarn sellers.
              </p>
            </li>
            <li>
              <p>
                There may be a high level of risk associated with your account
                or transactions.
              </p>
            </li>
          </ul>
          <br />
          <p>
            <u>
              <b>Why do my funds show as pending?</b>
            </u>
          </p>

          <p>
            The most common reasons your funds would show as pending are you
            have a limited selling history or you aren't meeting Tradesbarn's
            minimum performance standards.
          </p>
          <br />
          <p>
            <u>
              <b>
                How can I pay for shipping if the money from my buyer isn't
                available right away?
              </b>
            </u>
          </p>

          <p>
            If you print labels on Tradesbarn for USPS shipping (domestic or
            First Class international), up to $15 of your buyer's funds will be
            available immediately to cover shipping costs for any order less
            than $50. For any order with a total cost that exceeds $50, up to
            30% of the funds from you buyer will be available immediately to
            cover shipping costs. If you use PayPal for USPS, UPS or Canada
            Post, you'll need to pay for shipping when you print the label but
            the reimbursement will be deposited back to your PayPal account
            within 24 hours.
          </p>
          <br />
          <p>
            <u>
              <b>How is the estimated delivery date calculated?</b>
            </u>
          </p>

          <p>
            Your estimated delivery date is determined using a combination of
            your handling time plus the shipping carrier's published estimated
            delivery time for the service selected by your buyer.
          </p>
          <br />
          <p>
            <u>
              <b>
                What's the longest period of time funds may show as pending and
                be unavailable?
              </b>
            </u>
          </p>

          <p>
            If you don't provide tracking information, mark the item as shipped,
            or the estimated delivery date cannot be calculated, your funds
            could be unavailable for up to 21 days.
          </p>
          <br />
          <p>
            <u>
              <b>
                How can I find out the status of my payments to manage my cash
                flow?
              </b>
            </u>
          </p>

          <p>
            If you have payments shown as pending in your PayPal account, you'll
            see the status of these payments in My Tradesbarn or Seller Hub,
            including the estimated date funds will be available and steps you
            can take to help get your funds faster. You can also log in to your
            PayPal account for up-to-date information on the status of your
            payments.
          </p>
          <br />
          <p>
            <u>
              <b>What is considered a limited selling history?</b>
            </u>
          </p>

          <p>
            Sellers are considered to have a limited selling history until three
            conditions are met:
          </p>
          <ul>
            <li>
              <p>
                It's been more than 60 days since your first successful sale
              </p>
            </li>
            <li>
              <p>You have more than 15 sales transactions</p>
            </li>
            <li>
              <p
                style={{
                  marginRight: "4.44in",
                  marginTop: "0in",
                  lineHeight: "195%",
                }}
              >
                You have more than $599 in total sales
              </p>
            </li>
          </ul>
          <br />
          <p>
            <u>
              <b>Where can I see my selling history?</b>
            </u>
          </p>
          <p style={{ marginTop: "0.02in" }}>
            Log in to My Tradesbarn and check your Seller Dashboard to see your
            total number of selling transactions, total sales volume, and time
            since you registered as a seller.
          </p>
          <br />
          <p style={{ marginTop: "0.03in", pageBreakBefore: "always" }}>
            <u>
              <b>
                How will I know if I'm not meeting Tradesbarn's minimum
                performance standards?
              </b>
            </u>
          </p>

          <p>
            Sellers can see their current performance rating in their Seller
            Dashboard. If your rating is "Below Standard," that means you are
            not meeting the minimum.
          </p>
          <br />
          <p>
            <u>
              <b>Which of my listings are affected?</b>
            </u>
          </p>

          <p>
            All of your active or "live" listings, including Good 'Til Cancelled
            listings are affected.
          </p>

          <p>
            My seller dashboard says my performance rating is “Above Standard”
            so why do my payments show as pending?
          </p>

          <p>
            It is possible to have an Above Standard rating and still have your
            payments show as pending because you have a limited selling
            history—60 days or less from your first sale, 15 or fewer sales
            transactions, or $599 or less in total sales.
          </p>
          <br />
          <p>
            <u>
              <b>
                If I don't have a limited selling history and my performance
                rating is Above Standard, could I still be subject to having my
                payments show unavailable for a period of time?
              </b>
            </u>
          </p>

          <p>
            Yes, there may be other PayPal policies that impact the availability
            of funds if a higher than normal level of risk is associated with
            your account--for example a sudden spike in Tradesbarn Money Back
            Guarantee cases, PayPal disputes or claims, unusual selling
            activity, items with an exceptionally high selling price, or sales
            in a new category or a category such as tickets, travel, or gift
            certificates. Learn more.
          </p>
          <br />
          <p>
            <u>
              <b>
                What if I improve my performance and my rating is no longer
                Below Standard?
              </b>
            </u>
          </p>

          <p>
            Tradesbarn evaluates seller performance every 30 days. If you’re
            rating moves to Above Standard or Top Rated Sellerstatus, your
            seller performance rating will no longer be a reason why your funds
            are unavailable.
          </p>
          <br />
          <p>
            <u>
              <b>
                What happens if a Tradesbarn Money Back Guarantee case or PayPal
                claim is opened during the time the payment is pending?
              </b>
            </u>
          </p>

          <p>
            If a Tradesbarn Money Back Guarantee case, PayPal claim, or PayPal
            dispute is opened, or a credit card chargeback is received or other
            reversal is initiated during the time the payment is pending, funds
            for that sale are unavailable until the seller resolves the issue or
            the case is found in the seller's favor. If the seller does not
            resolve the issue or is found responsible, Tradesbarn or PayPal will
            refund the buyer's payment.
          </p>
          <br />
          <p>
            <u>
              <b>
                I sell internationally. Will these transactions still be subject
                to this change?
              </b>
            </u>
          </p>

          <p>
            If you are a seller registered on Tradesbarn.com, this change will
            apply to all of your listings on Tradesbarn site. Whether your items
            ship domestically or internationally. To help get your funds quicker
            you can upload tracking information from USPS, UPS, or FedEx. In
            most cases, when we can confirm delivery, funds will be available in
            3 days after delivery has been confirmed. If you use an alternate
            carrier or when carrier tracking is not available, you can still
            mark your item as shipped and your funds should be available 7 days
            after the latest estimated delivery date. If no estimated delivery
            date can be calculated, funds should be available 21 days after the
            buyer pays.
          </p>
          <br />
          <p>
            <u>
              <b>
                What about transactions using payment methods besides PayPal?
              </b>
            </u>
          </p>

          <p>
            Only PayPal transactions are subject to this action. Payments
            through other methods are covered by the terms and practices of each
            provider.
          </p>
          <br />
          <p>
            <u>
              <b>
                What about items that don't require shipping such as services?
              </b>
            </u>
          </p>

          <p
            style={{
              marginRight: "0.98in",
              marginTop: "0.04in",
              lineHeight: "200%",
            }}
          >
            For affected sellers, funds from sales of services will be available
            in 21 days from when the buyer pays.
          </p>
          <br />
          <p>
            <u>
              <b>
                What about listings that use Tradesbarn's Classified Ad format?
              </b>
            </u>
          </p>
          <p
            style={{
              marginRight: "0.26in",
              marginTop: "0.03in",
              lineHeight: "200%",
              pageBreakBefore: "always",
            }}
          >
            Sales transacted through a classified advertisement on
            Tradesbarn.com or Tradesbarn Classifieds are not affected.
          </p>
          <br />
          <p>
            <u>
              <b>
                I have more than one account. How will my payments be affected?
              </b>
            </u>
          </p>

          <p style={{ marginTop: "0in", lineHeight: "98%" }}>
            Funds will be available based on the status of the Tradesbarn
            account from which an item is sold. All new accounts, even those
            created by established sellers, are subject to funds availability.
          </p>
          <br />
          <p>
            <u>
              <b>
                Will items with local pick-up be subject to funds availability?
              </b>
            </u>
          </p>

          <p style={{ marginRight: "0.26in", marginTop: "0.04in" }}>
            If your buyer pre-pays for the item with PayPal and uses local
            pick-up for an item purchased on Tradesbarn.com, your funds will be
            available 21 days after the buyer pays.
          </p>
          <br />
          <p>
            <u>
              <b>Unpaid item policy overview</b>
            </u>
          </p>

          <p>
            When a buyer buys an item or uses Buy It Now to purchase an item,
            the buyer is obligated to complete the purchase by sending full
            payment to the seller. If a buyer doesn't pay within 2 days, a
            seller can open an unpaid item case in the Resolution Center. If the
            buyer still doesn't pay or reach some other agreement with the
            seller, Tradesbarn may record the unpaid item on the buyer's
            account.
          </p>
          <p>
            When an unpaid item case closes without payment from the buyer, the
            seller is eligible to receive a final value fee credit to their
            seller account. Also, if the seller relists the item and it sells
            the second time, Tradesbarn may refund the insertion fee for the
            relisting. Learn more about managing unpaid items and receiving
            insertion fee credits. Excessive unpaid items on a buyer's account
            may result in a range of consequences, including limits on or loss
            of buying privileges. Even if you don't have excessive unpaid items,
            we may limit your buying until you've established a good buying
            history or paid for the items you've committed to.
          </p>
          <br />
          <p>
            <u>
              <b>Examples of policy violations</b>
            </u>
          </p>

          <p>
            If you've clicked the Buy It Now button in a listing, you've
            committed to purchasing that item. If you don't pay for it, even if
            you've changed your mind, you violate the unpaid item policy. Here
            are some examples:
          </p>
          <br />
          <p>
            <u>
              <b>Not allowed</b>
            </u>
          </p>

          <p>
            Chandana looks for a thread mill on Tradesbarn and places an
            order.Afterword’s, he realizes that shipping the item will cost more
            than he's willing to spend. He decides not to send payment and
            simply ignores the invoices that he receives.
          </p>
          <p>
            Waruni is new to Tradesbarn and is just browsing. Excited by all the
            great deals she's finding, using Buy It Now on every item that
            catches her eye. She doesn't realize that she's purchased many of
            those items and is obligated to pay for them. If she chooses not to
            pay for the items, she's violating the unpaid item policy.
          </p>
          <br />
          <p>
            <u>
              <b>Unpaid items and account limits</b>
            </u>
          </p>

          <p>
            Buyers who don't pay may have unpaid items recorded on their
            accounts Unpaid items are separate from feedback, and don't affect a
            user's feedback score or feedback profile. An unpaid item will be
            recorded on the buyer's account when the seller receives a final
            value fee credit, If you have excessive unpaid items or canceled
            transactions on your account, we may limit or end your ability to
            buy Buyers have the option of appealing an unpaid item if they
            believe it was undeserved Unpaid items are also considered a
            violation of the buying practices policy
          </p>

          <p>
            For transactions involving an unpaid item, feedback left by a buyer
            may be removed when:
          </p>
          <ul>
            <li>
              <p>The buyer didn't pay or respond to the unpaid item case</p>
            </li>
            <li>
              <p>The buyer is suspended</p>
            </li>
            <li>
              <p
                style={{
                  marginRight: "0.08in",
                  marginTop: "0in",
                  lineHeight: "98%",
                }}
              >
                The buyer's communication in the Resolution Center undermines
                the purpose of the unpaid item process and the Tradesbarn
                feedback system
              </p>
            </li>
          </ul>
          <br />
          <p>
            <u>
              <b>Why does Tradesbarn have this policy?</b>
            </u>
          </p>

          <p>
            When a buyer doesn't pay for an item they've won or purchased, it
            costs the seller time and money and holds up inventory. The unpaid
            item policy helps create a safer and more successful buying and
            selling experience.
          </p>
          <br />
          <p style={{ marginTop: "0.02in", pageBreakBefore: "always" }}>
            <u>
              <b>Tradesbarn protects you</b>
            </u>
          </p>

          <p>
            Tradesbarn works to identify, warn, and restrict buyers who file
            unusually high numbers of cases.
          </p>
          <p
            style={{
              marginRight: "0.08in",
              marginTop: "0in",
              lineHeight: "98%",
            }}
          >
            You can track the number of times Tradesbarn Money Back Guarantee
            cases have been removed from your account as a result of these
            actions in your seller dashboard.
          </p>
          <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
            Protection when shipping within your stated time: If a buyer claims
            that they did not receive an item, the related Tradesbarn Money Back
            Guarantee case will be found in your favor if you shipped within
            your stated handling time and provided tracking information (with
            signature confirmation for transactions of $599 or more) before the
            case was escalated to Tradesbarn.
          </p>
          <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
            To be protected, the tracking information will need to show proof of
            delivery from a shipping company that clearly displays the delivery
            status of the item as "delivered," the date of delivery (which
            reflects that you shipped within your stated handling time), and the
            recipient's address.
          </p>
          <p>
            Any buyer feedback (positive, neutral, and negative) along with
            detailed seller ratings [DSRs] will be removed promptly from
            transactions in which a Tradesbarn Money Back Guarantee case is
            resolved in your favor by Tradesbarn.
          </p>
          <br />
          <p>
            <u>
              <b>Offers to buy or sell outside Tradesbarn policy overview</b>
            </u>
          </p>

          <p>
            We don't allow our members to use Tradesbarn to contact each other
            to make offers to buy or sell items outside of Tradesbarn. Also,
            members can't use information obtained from Tradesbarn or any
            Tradesbarn systems to contact each other about buying or selling
            outside of Tradesbarn. Sellers are liable for fees arising out of
            all sales made using some or all Tradesbarn Services, even if sales
            terms are finalized or payment is made outside of Tradesbarn.
            Sellers are also liable for final value fees if they offer or
            reference their contact information, or ask for a buyer's contact
            information, in the context of buying or selling outside of
            Tradesbarn, even if the item does not sell. If you receive an offer
            to buy or sell outside Tradesbarn, please report it.
          </p>
          <p>
            Make sure you follow these guidelines. If you don't, you may be
            subject to a range of actions. We may limit, restrict, or suspend
            you from buying, selling, or using site features. All of your
            listings may be removed, displayed lower or not shown in search
            results, without refunding any or all applicable fees. You may also
            forfeit special account status and any discounts. You may be subject
            to the application of fees and recovery of our expenses in policy
            monitoring and enforcement.
          </p>
          <br />
          <p>
            <u>
              <b>What are the guidelines?</b>
            </u>
          </p>

          <p>
            <u>
              <b>Allowed</b>
            </u>
          </p>

          <ul>
            <li>
              <p>
                Sellers can use Tradesbarn's Offer feature to send a customized
                offer within My Messages to a specific buyer for an active fixed
                price listing
              </p>
            </li>
            <li>
              <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
                When selling an item with a fixed price, you can choose the Best
                Offer option when you list your item, which gives buyers a
                chance to negotiate the price with you
              </p>
            </li>
          </ul>

          <p>
            <u>
              <b>Not allowed</b>
            </u>
          </p>

          <p>Some examples of activities that aren't allowed include:</p>
          <ul>
            <li>
              <p>
                Using member contact information obtained from Tradesbarn or
                using any Tradesbarn system to offer to sell any listed item
                outside of Tradesbarn
              </p>
            </li>
            <li>
              <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
                Posting or displaying contact information in a listing,
                including email, phone number, and mailing address, without the
                permission of Tradesbarn or except as required by law
              </p>
            </li>
            <li>
              <p>
                Canceling a listing to sell to a buyer who found the item on
                Tradesbarn
              </p>
            </li>
            <li>
              <p>
                Including, requesting, offering, or referencing contact
                information or an offer to buy or sell an item outside of
                Tradesbarn within a Best Offer, email message, or other
                communication with an Tradesbarn user
              </p>
            </li>
            <li>
              <p>
                In your listing page or in communications with another
                Tradesbarn member you can't refer to or promote your personal
                website (including links to home page web addresses that promote
                websites outside of Tradesbarn), sales outside of Tradesbarn, or
                other businesses.
              </p>
            </li>
          </ul>
          <br />
          <p style={{ marginTop: "0.03in", pageBreakBefore: "always" }}>
            <u>
              <b>How to tell if you're buying or selling on Tradesbarn</b>
            </u>
          </p>

          <p>
            The best way to tell if you're actually transacting on Tradesbarn
            is:
          </p>
          <p>
            Open a new browser and type
            <a href="http://www.Tradesbarn.com.Click/">www.Tradesbarn.com.</a>
            Click My Tradesbarn at the top of the page and sign in.Check to see
            that the listing you've bid on, bought, or sold appears in the
            Purchase History or Sell sections.If your transaction doesn't show
            up in My Tradesbarn, then you didn't buy or sell the item on
            Tradesbarn.
          </p>
          <br />
          <p>
            <u>
              <b>Why does Tradesbarn have this policy?</b>
            </u>
          </p>

          <p>
            Offers to buy or sell outside of Tradesbarn are a potential fraud
            risk for both buyers and sellers, and are not protected by the
            Tradesbarn Money Back Guarantee and other buyer protection programs.
            Additionally, these offers may be an attempt to avoid Tradesbarn
            fees. This is unfair to other sellers and violates our policies.
          </p>
        </Widget>
      </div>
    );
  }
}

export default AcceptedPaymentsPolicy;
