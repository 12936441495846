import React from "react";
import { Link } from "react-router-dom";
import { BLOG_SELLER_INFO } from "../../../constants";

const SingleProductSideBanner = () => {
  return (
    <div
      className="d-flex flex-column hide-side-baner"
      style={{ height: "100%" }}
    >
      <div className="gx-card p-3 slr-bcgd">
        <img
          className=""
          style={{ width: "100%", borderRadius: "5px" }}
          alt="example"
          src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/singleProduct/side_banner_01.jpg`)}
        />
      </div>

      <div className="gx-card p-3 slr-bcgd flex-fill">
        <div style={{ fontSize: "14px" }}>
          Get tips and guides on listing and selling items.
          <Link to={{ pathname: BLOG_SELLER_INFO }} target="_blank">
            {" "}
            Learn more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SingleProductSideBanner;
