import React, { Component } from "react";
import { Button, message, Popconfirm, Col, Row, Badge } from "antd";
import moment from "moment";
import ShippingAddressModal from "../../Modal/ShippingAddressModal/ShippingAddressModal";
import Widget from "../../../Common/Widget/Widget";

class ShippingAddressCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      visible: false,
      confirmLoading: false,
    };
  }
  render() {
    const { address, shippingAddressDeleteHandler, isDefaultShippingAddress } =
      this.props;
    return (
      <Widget styleName="mb-3">
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="ant-card-head-title">
              {address.first_name} {address.last_name}
            </div>
            {isDefaultShippingAddress ? (
              <Badge
                count="Default Address"
                style={{
                  backgroundColor: "#038fde",
                  color: "#fff",
                  boxShadow: "0 0 0 1px #d9d9d9 inset",
                  marginLeft: "10px",
                }}
              />
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div style={{ textAlign: "end" }}>
              <i class="fad fa-calendar-edit mr-2"></i>
              <span className="gx-text-grey">
                {moment(address.modified_at).format("LLLL")}
              </span>
            </div>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col span={16}>
            <p className="mb-1">
              <b>Address Line 1 : </b>
              {address.address1}
            </p>
            <p className="mb-1">
              <b>Address Line 2 : </b>
              {address.address2}
            </p>
            <p className="mb-1">
              <b>City : </b>
              {address.city}
            </p>
            <p className="mb-1">
              <b>State : </b>
              {address.state}
            </p>
            <p className="mb-1">
              <b>Country : </b>
              {address.country}
            </p>
            <p className="mb-3">
              <b>Zip Code : </b>
              {address.zip}
            </p>
            <p className="mb-0">
              <b>Contact Number : </b>
              {address.phone}
            </p>
          </Col>
          <Col span={8}>
            <div className="d-flex flex-column" style={{ height: "100%" }}>
              <div className="flex-fill">
                <div style={{ marginTop: "120px", textAlign: "end" }}>
                  <ShippingAddressModal
                    ordarAddressItemsHandler={this.ordarAddressItemsHandler}
                    isUpdate={true}
                    data={address}
                  />

                  <Popconfirm
                    title="Are you sure delete this task?"
                    onConfirm={() => shippingAddressDeleteHandler(address)}
                    onCancel={() => message.error("Delete Canceled")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="gx-btn-danger mb-0"
                      // onClick={() => shippingAddressDeleteHandler(address)}
                    >
                      <i className="fad fa-trash-alt mr-1"></i>Delete
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Widget>
    );
  }
}

export default ShippingAddressCard;
