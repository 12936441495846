// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  // getCovid19Details: createAction(types.GET_COVID19_DETAILS),
  // getCovid19DetailsSuccess: createAction(types.GET_COVID19_DETAILS_SUCCESS),
  // getCovid19DetailsFail: createAction(types.GET_COVID19_DETAILS_FAILED),

  selectedSellerHandler: createAction(types.SELECTED_SELLER_HANDLER),
};
