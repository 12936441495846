import React from "react";

function createMarkupDesciption(product) {
  const { data } = product.data;
  return {
    __html: data && data.description,
  };
}

function createMarkupHighlight(product) {
  const { data } = product.data;
  return {
    __html: data && data.highlight,
  };
}

const Description = ({ product }) => {
  return <div className="gx-card p-3 mb-0">
    <div className="pb-0" dangerouslySetInnerHTML={createMarkupDesciption(product)} />

    <div className="pb-0 bot-line-catch" dangerouslySetInnerHTML={createMarkupHighlight(product)} />
  </div>;
};

export default Description;
