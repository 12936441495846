import React from "react";
import { Radio } from "antd";
import { withRouter } from "react-router-dom";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const ProductSelections = ({ productCount, history, type }) => {
  function onChange(e) {
    history.push(
      `/seller/dashboard/productManagement/productList/${e.target.value}`
    );
  }

  return (
    <div className="mb-3">
      <RadioGroup onChange={onChange} defaultValue={type} buttonStyle="solid">
        <RadioButton className="mb-0" value="all">
          All&nbsp;
          {productCount.data && productCount.data.data
            ? `(${productCount.data.data.all_products_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="live">
          Live&nbsp;
          {productCount.data && productCount.data.data
            ? `(${productCount.data.data.active_products_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="imageMissing">
          Image Missing&nbsp;
          {productCount.data && productCount.data.data
            ? `(${productCount.data.data.image_missing_products_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="poorQuality">
          Poor Quality&nbsp;
          {productCount.data && productCount.data.data
            ? `(${productCount.data.data.poor_quality_products_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="soldOut">
          Sold Out&nbsp;
          {productCount.data && productCount.data.data
            ? `(${productCount.data.data.sold_out_products_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="inactive">
          Inactive&nbsp;
          {productCount.data && productCount.data.data
            ? `(${productCount.data.data.inactive_products_count})`
            : ""}
        </RadioButton>
        {/* <RadioButton value="policyVialation">Policy Vialation({productCount.data && productCount.data.data && productCount.data.data.all_products_count})</RadioButton> */}
      </RadioGroup>
    </div>
  );
};

export default withRouter(ProductSelections);
