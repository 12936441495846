import React, { Component } from "react";
import { Col, Row } from "antd";
import ProductCard from "../../Card/ProductCard/ProductCard";
import { GetWishListedStatusByProductIDs } from "./../../../../firebase/functions";
import { allProducts } from "../../../../DummyData/AllProducts";
import AllProductCardLoading from "../../../Loading/AllProductCardLoading";
class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hits: [...allProducts],
      isUpdated: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    var updatedHits = [];

    if (JSON.stringify(this.props.hits) !== JSON.stringify(prevProps.hits)) {
      const product_ids = this.props.hits.map((product) => product.id);
      GetWishListedStatusByProductIDs(product_ids).then(
        (is_wishlisted_query) => {
          updatedHits = this.props.hits.map((product) => {
            return {
              ...product,
              is_wishlisted: is_wishlisted_query[product.id],
            };
          });
          this.setState({
            hits: updatedHits,
            isUpdated: true,
          });
        }
      );
    }
  }

  updateWishlistHandler = (id) => {
    const updatedIndex = this.state.hits.findIndex((item) => item.id === id);
    var updatedObj = {
      ...this.state.hits[updatedIndex],
      is_wishlisted: !this.state.hits[updatedIndex].is_wishlisted,
    };
    var source = this.state.hits;
    source[updatedIndex] = updatedObj;
    this.setState({
      hits: source,
    });
  };

  render() {
    const { hits } = this.state;
    return (
      <div id="product">
        <Row>
          {hits[0].isDummy
            ? hits.map((product, i) => {
                return (
                  <Col
                    xxl={4}
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    key={product.objectID}
                  >
                    <AllProductCardLoading
                      item={product}
                      key={product.objectID}
                    />
                  </Col>
                );
              })
            : hits.map((product, i) => {
                return (
                  <Col
                    xxl={4}
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={24}
                    key={product.objectID}
                  >
                    <ProductCard
                      item={product}
                      key={product.objectID}
                      updateWishlistHandler={this.updateWishlistHandler}
                    />
                  </Col>
                );
              })}
        </Row>
      </div>
    );
  }
}

export default ProductsList;
