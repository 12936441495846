import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, Input, Row, Spin } from "antd";
import { makeField } from "../../components/Common/Controls/MakeField";
import ReCAPTCHA from "react-google-recaptcha";
import { contactPageActions } from "../../modules/StaticPages/ducks";
import { G_RECAPTCHA_SITEKEY } from "../../constants";
import "./HelpAndContact.css";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const { TextArea } = Input;

const AInputField = makeField(Input);
const AInputAreaField = makeField(TextArea);

class HelpAndContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      human: false,
      humankey: "",
      disabled: true,
    };
  }

  handleSubmit = (res) => {
    const { fieldValues } = this.props;
    const { name, email, content } = fieldValues;
    var contactMessageObj = {
      name: name,
      email: email,
      message: content,
      "g-recaptcha-response": res,
    };
    this.props.contactPageActions.addContactDetails(contactMessageObj);
  };

  verifyCaptcha = (res) => {
    if (res) {
      this.handleSubmit(res);
    }
  };

  expireCaptcha = () => {
    
  };

  render() {
    const { pristine, reset, submitting, contactDetail } = this.props;

    let recaptchaInstance;
    const executeCaptcha = (e) => {
      e.preventDefault();
      recaptchaInstance.executeAsync().then((res) => {
        //
        if (res) {
          recaptchaInstance.reset();
        }
      });
    };

    return (
      <React.Fragment>
        <Row>
          <Col xl={14} lg={14} md={12} sm={24} xs={24}>
            <div className="gx-card px-3 pt-3 mb-0">
              <div className="gx-wall-content p-3">
                <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
                  <div className="gx-media-body">
                    <Spin spinning={contactDetail.pending}>
                      <Form onSubmit={executeCaptcha}>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Field
                              formItemLayout={formItemLayout}
                              name="name"
                              component={AInputField}
                              placeholder="Name / Store"
                              hasFeedback
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Field
                              formItemLayout={formItemLayout}
                              name="email"
                              component={AInputField}
                              placeholder="Email"
                              hasFeedback
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Field
                              formItemLayout={formItemLayout}
                              name="content"
                              component={AInputAreaField}
                              rows={10}
                              placeholder="Send Message"
                              hasFeedback
                            />
                          </Col>
                        </Row>
                        <ReCAPTCHA
                          sitekey={G_RECAPTCHA_SITEKEY}
                          onChange={this.verifyCaptcha}
                          onExpired={this.expireCaptcha}
                          size="invisible"
                          ref={(e) => (recaptchaInstance = e)}
                          verifyCallback={this.handleSubmit}
                        />

                        <FormItem
                          {...tailFormItemLayout}
                          style={{ marginBottom: "0" }}
                        >
                          <div style={{ textAlign: "end" }}>
                            <Button
                              className="mb-0"
                              type="primary"
                              // disabled={pristine || submitting}
                              htmlType="submit"
                              style={{ marginRight: "10px" }}
                            >
                              Send
                            </Button>

                            <Button
                              className="mb-0"
                              disabled={pristine || submitting}
                              onClick={reset}
                            >
                              Clear Values
                            </Button>
                          </div>
                        </FormItem>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={10} lg={10} md={12} sm={24} xs={24}>
            <div
              className="gx-card p-3 mb-0 add-mrgn-top-hac"
              style={{ textAlign: "center", height: "100%" }}
            >
              <img
                className="p-0 bd-highlight"
                style={{ maxHeight: "390px" }}
                src={require("assets/images/undraw_home_screen_4n7s.png")}
                alt="Mobile App"
              />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name / Store is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.content) {
    errors.content = "Message is required";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    fieldValues: getFormValues("HelpAndContact")(state),
    contactDetail: state.CustomerService.contactDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    contactPageActions: bindActionCreators(contactPageActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "HelpAndContact",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(HelpAndContact))
);

// export default HelpAndContact;
