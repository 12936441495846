import React, { Component } from "react";
import { Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import "./ResolveProblem.less";

class ResolveProblem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "product_no_received",
    };
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };
  render() {
    const { isDashboard } = this.props;

    return (
      <div className="gx-card p-4 mb-0">
        <h6 className="mb-3">Resolve a problem</h6>
        {isDashboard ? (
          <p style={{ fontSize: "14px", color: "#545454" }}>
            Effective communication resolves any issue. We encourage our
            tradesbarn members to communicate with each other when there's a
            problem regarding anything. This resolution center is specific for
            sellers. The first step of resolving any issue is opening a{" "}
            <span style={{ color: "rgb(255, 105, 0)" }}>dispute</span> regarding
            particular order. As a seller whenever you want to open a dispute
            regarding any order (ex: Item out of stock, Item re received since
            buyers address failier) You can use the following button to see
            recent orders, and there you can open dispute relating to items. The
            latter section of this resolution center is for view all your
            dispute summary.
          </p>
        ) : (
          <p>
            Effective communication resolves any issue. We encourage our
            tradesbarn members to communicate with each other when there's a
            problem regarding anything. The first step of resolving any issue is
            opening a
            <span style={{ color: "rgb(255, 105, 0)" }}> dispute </span>
            regarding particular order. You can open despite here relating to
            your orders which you bought from other sellers.
          </p>
        )}

        <div className="mt-3">
          <Link
            to={
              isDashboard
                ? "/seller/dashboard/resolution-center/item/list"
                : "/resolution/report"
            }
          >
            <Button className="mb-0" type="primary">
              Recent Orders
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(ResolveProblem);
