import React from "react";
import Widget from "../Widget/Widget";
import "./TopSellerCard.less";
import history from "../../../../_helpers/history";
// import _mg from "../../../../assets/images/asadaad.jpg"

const TopSellerCard = ({ data, loading }) => {
  const { name, seller_category, image, id } = data;
  return (
    <Widget
      // style={{ height: "250px" }}
      styleName="nw-bdr-jr top-slr-crd-hvr mb-0"
    >
      <div onClick={loading ? null : () => history.push(`/seller/${id}`)}>
        <img
          className="p-2"
          style={{ borderRadius: "15px" }}
          alt="example"
          src={image}
        />
        <div className="pt-2 top-slr-crd-bdy">
          <p className="gx-mb-1 jr-text-truncate-6">{name}</p>
          <span className="gx-pointer gx-text-uppercase gx-fs-sm">
            <p
              style={{ marginBottom: "0px", fontSize: "10px" }}
              className="jr-text-truncate-6"
            >
              {seller_category}
            </p>
          </span>
        </div>
      </div>
    </Widget>
  );
};

export default TopSellerCard;
