import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderSelections from "../../../components/Seller/OrderManagement/OrderSelections/OrderSelections";
import OrderSearchBar from "../../../components/Seller/OrderManagement/OrderSearchBar/OrderSearchBar";
import OrderContainer from "../../../components/Seller/OrderManagement/OrderContainer/OrderContainer";
import { orderManagementActions } from "./ducks";
import { Card, Col, Row } from "antd";

class OrderManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
    };
  }

  componentDidMount() {
    const { orderManagementActions, ordersCount } = this.props;
    ordersCount.loading && orderManagementActions.getOrdersCount();

    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    this.setState({
      algolia,
    });
  }

  render() {
    const { match, ordersCount } = this.props;
    const { algolia } = this.state;

    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    if (algolia) {
      switch (type) {
        case "all":
          apiKey = algolia.all_orders.api_key;
          index = algolia.all_orders.index;

          break;
        case "unpaid":
          apiKey = algolia.unpaid_orders.api_key;
          index = algolia.unpaid_orders.index;

          break;
        case "neworder":
          apiKey = algolia.orders_to_ship.api_key;
          index = algolia.orders_to_ship.index;

          break;
        case "shipped":
          apiKey = algolia.poor_quality_products.api_key;
          index = algolia.poor_quality_products.index;

          break;
        case "delivered":
          apiKey = algolia.delivered_orders.api_key;
          index = algolia.delivered_orders.index;

          break;
        case "cancelled":
          apiKey = algolia.cancelled_orders.api_key;
          index = algolia.cancelled_orders.index;

          break;
        case "returned":
          apiKey = algolia.returned_orders.api_key;
          index = algolia.returned_orders.index;

          break;
        default:
          apiKey = algolia.all_orders.api_key;
          index = algolia.all_orders.index;
      }
      return (
        <Row>
          <Col span={24}>
            <div style={{ paddingBottom: "20px" }}>
              <h3>Order Management</h3>
            </div>
            <Card className="gx-card">
              {/* Order selections */}
              <OrderSelections ordersCount={ordersCount} type={type} />

              {/* Order Container */}
              <OrderContainer apiKey={apiKey} index={index} />
            </Card>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={24}>
            <div style={{ paddingBottom: "20px" }}>
              <h3>Order Management</h3>
            </div>
            <Card className="gx-card">
              {/* Order selections */}
              <OrderSelections ordersCount={ordersCount} type={type} />

              {/* Order Search bar */}
              <OrderSearchBar />
            </Card>
          </Col>
        </Row>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    ordersCount: state.OrderManagement.ordersCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderManagementActions: bindActionCreators(
      orderManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderManagement)
);
