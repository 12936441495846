import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues, reset } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, Input, Row, Rate, Card } from "antd";
import { profileActions } from "../../../../modules/ProfilePage/ducks";
import { makeField } from "../../../Common/Controls/MakeField";

const { TextArea } = Input;

const TextAreaField = makeField(TextArea);
const RateField = makeField(Rate);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

class OrderReviewModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: null,
    };
  }
  componentDidMount() {
    this.setState({
      orderID: this.props.orderID,
    });
  }

  componentWillUnmount() {
    reset("OrderReviewModalBody");
  }

  handleChange = (e) => {
    this.setState({ review: e.target.value });
  };

  handleSubmit = (values) => {
    const { orderID } = this.state;
    var orderReview = {
      rating_value: values.rate,
      content: values.review,
      order_id: orderID,
    };

    this.props.profileActions.addOrderReview({
      orderReview: orderReview,
      callback: this.props.onCloseModal,
    });
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, isUpdate, fieldValues } =
      this.props;
    return (
      <React.Fragment>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <p>
              Be kind to add your honest review. Your review will help others to
              choose the best.
            </p>
          </Col>
        </Row>
        <Form
          className="feild-catcher-reg"
          onSubmit={handleSubmit(this.handleSubmit)}
        >
          <Card
            className="crd-bdy-fd mb-3 pb-3"
            style={{ backgroundColor: "#fafafa" }}
          >
            <p className="pl-1 mb-0 mt-3">Your Rating</p>
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  name="rate"
                  component={RateField}
                  // hasFeedback
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <span
                  className="ant-rate-text"
                  style={{ fontSize: "18px", marginTop: "10px" }}
                >
                  {(fieldValues && fieldValues.rate) || 0} / 5 stars
                </span>
              </Col>
            </Row>

            <p className="pl-1 mb-2 mt-2">Your Review</p>
            <Field
              formItemLayout={formItemLayout}
              name="review"
              component={TextAreaField}
              placeholder="Type here..."
              type="textArea"
              hasFeedback
              autocomplete="on"
              rows={4}
            />
          </Card>

          <div style={{ textAlign: "end" }}>
            {isUpdate ? null : (
              <Button
                className="mb-0"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            )}
            <Button type="primary" htmlType="submit" className="mb-0">
              {isUpdate ? "Update" : "Confirm"}
            </Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.rate) {
    errors.rate = "Rate is required";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    orderReview: state.orderReview,
    fieldValues: getFormValues("OrderReviewModalBody")(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "OrderReviewModalBody",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(OrderReviewModalBody))
);
