import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal, Spin } from "antd";
import { profileActions } from "../../../../modules/ProfilePage/ducks";
import OrderReviewModalBody from "./OrderReviewModalBody";

class OrderReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      orderId: props.orderID,
      canReview: props.canReview,
    };
  }

  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  modalHandler = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const { canReview } = this.props;
    const { orderReview } = this.props;
    return (
      <div className="add-card">
        <Button
          block
          className="gx-btn-outline-success"
          onClick={this.modalHandler}
          disabled={!canReview}
        >
          {/* {canReview ? (<div>&nbsp; Product review</div>) : <div>&nbsp; Product review  added</div>} */}
          <i className="fad fa-box-check mr-1"></i>
          Confirm Goods Received
        </Button>
        <Modal
        style={{width:'800px'}}
          title="Product Review"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.modalHandler}
          footer={null}
          loading={true}
        >
          <Spin spinning={orderReview.pending}>
            <OrderReviewModalBody
              orderID={this.state.orderId}
              onCloseModal={this.modalHandler}
            />
          </Spin>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderReview: state.Profile.orderReview,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderReviewModal)
);
