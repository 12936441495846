import React, { Component } from "react";
import { Col, Row, Button } from "antd";
import AllProductsCarousel from "../Buyer/Carousel/AllProductsCarousel/AllProductsCarousel";
import { DEFAULT_CURRENCY_SHORT } from "../../constants";

class AllProductCardLoading extends Component {
  render() {
    const { item } = this.props;
    const { name, is_wishlisted } = item;
    return (
      <div className="gx-product-item pdct-crd-ef-jr-2 bdr-jr gx-product-vertical">
        <div style={{ height: "100%" }}>
          <div className="gx-product-image">
            <div className="gx-grid-thumb-equal">
              <span className="gx-link gx-grid-thumb-cover">
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div
                      className="perant-hvr"
                      style={{ position: "relative" }}
                    >
                      <AllProductsCarousel data={item.images} />
                    </div>
                  </Col>
                </Row>
              </span>
            </div>
          </div>

          <div className="gx-product-body p-2 pl-3 pr-3 txt-alng-pg-jr">
            <p className="gx-product-title tb-product-title crd-fnt-jr mb-1">
              {name}
            </p>

            <div>
              <div style={{ display: "inline-block", width: "70%" }}>
                <div style={{ display: "inline-block" }}>
                  <div
                    style={{ fontWeight: "bold", color: "#ff6900" }}
                    className="gx-product-price"
                  >
                    {`${DEFAULT_CURRENCY_SHORT} 0.00`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="txt-alng-pg-jr pt-0 pr-3 pb-3 pl-3 m-0"
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          <div
            className="crd-hvr"
            style={{
              display: "inline-block",
              marginRight: "0px",
              width: "70%",
            }}
          >
            <Button
              onClick={() => console.log("wait")}
              style={{ width: "90%" }}
              type="primary"
              className="gx-mb-0"
              htmlType="submit"
            >
              Buy Now
            </Button>
          </div>
          <div
            className="crd-hvr-3"
            style={{
              display: "inline-block",
              marginRight: "0px",
              width: "30%",
              position: "absolute",
            }}
          >
            <button
              className="like-button-2"
              onClick={() => console.log("wait")}
            >
              {is_wishlisted ? (
                <i
                  className="fa fa-heart like-icon-2"
                  style={{ color: "rgb(194, 26, 26)" }}
                ></i>
              ) : (
                <i className="fa fa-heart like-icon-2"></i>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default AllProductCardLoading;
