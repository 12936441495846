import React, { Component } from "react";
import { Result, Button, Typography, Card } from "antd";
import { withRouter } from "react-router-dom";

const { Paragraph, Text } = Typography;
class CheckoutFail extends Component {
  buyAgainHandler = () => {
    this.props.history.push("/");
  };

  homeHandler = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div
        className="gx-container"
        style={{ paddingTop: "10vh", paddingBottom: "7vh" }}
      >
        <Card className="mb-0">
          <Result
            status="error"
            title="Payment Failed"
            subTitle="Please check and modify the following information before resubmitting."
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={this.buyAgainHandler}
              >
                Home
              </Button>,
              <Button key="buy" onClick={this.homeHandler}>
                Buy Again
              </Button>,
            ]}
          >
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  The content you submitted has the following error:
                </Text>
              </Paragraph>
              <Paragraph>
                <i
                  className="fad fa-times-circle mr-1"
                  style={{ color: "red" }}
                ></i>
                Payment Failed
              </Paragraph>
            </div>
          </Result>
        </Card>
      </div>
    );
  }
}

export default withRouter(CheckoutFail);
