import React, { Component } from "react";
import { Result, Button, Card } from "antd";
import { withRouter } from "react-router-dom";
import Feedback from "../../components/Common/Feedback/Feedback";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
});
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: "", error: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    

    this.setState({
      error,
      errorInfo,
      hasError: true,
    });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  feedBackModalHandler = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  homePageNagigateHandler = () => {
    window.location.href = "/";
  };

  render() {
    const { hasError, visible } = this.state;
    if (hasError) {
      return (
        <div className="gx-container" style={{ paddingTop: "15vh" }}>
          <Card>
            <Result
              status="error"
              title="Oooops.!"
              subTitle="Something went wrong."
              extra={[
                <div className="mb-4 mt-3">
                  We are working on getting it fixed as soon as possible.
                  Meanwhile, help us by sharing your experience using the
                  following feedback form.{" "}
                </div>,
                <Button
                  type="primary"
                  onClick={() => this.feedBackModalHandler()}
                >
                  Feedback
                </Button>,
                <Button
                  key="home"
                  onClick={() => this.homePageNagigateHandler()}
                >
                  Home
                </Button>,
              ]}
            ></Result>
            <Feedback
              visible={visible}
              feedBackModalHandler={this.feedBackModalHandler}
            />
          </Card>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
