import React from "react";
import { Card } from "antd";
import PropTypes from "prop-types";

const Metrics = ({ title, icon, styleName, children }) => {
  return (
    <Card
      title={<span><i className={icon}></i>{title}</span>}
      className={`gx-card-metrics ${styleName}`}
    >
      {children}
    </Card>
  );
};

export default Metrics;
Metrics.defaultProps = {
  styleName: "",
};

Metrics.propTypes = {
  title: PropTypes.string.isRequired,
  styleName: PropTypes.string,
  children: PropTypes.node.isRequired,
};
