import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  replyToReview: {
    ...initialStateModal,
  },
  deleteReview: {
    ...initialStateModal,
  },
  deleteReviewReply: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    //------------ REPLY TO REVIEW ------------------- //
    [types.REPLY_TO_REVIEW]: (state, { payload }) => ({
      ...state,
      replyToReview: {
        ...state.replyToReview,
        loading: true,
        pending: true,
      },
    }),
    [types.REPLY_TO_REVIEW_SUCCESS]: (state, { payload }) => ({
      ...state,
      replyToReview: {
        ...state.replyToReview,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.REPLY_TO_REVIEW_FAIL]: (state, { payload }) => ({
      ...state,
      replyToReview: {
        ...state.replyToReview,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------ ---------------- ------------------- //
    //------------DELETE REVIEW ------------------- //
    [types.DELETE_REVIEW]: (state, { payload }) => ({
      ...state,
      deleteReview: {
        ...state.deleteReview,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_REVIEW_SUCCESS]: (state, { payload }) => ({
      ...state,
      deleteReview: {
        ...state.deleteReview,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.DELETE_REVIEW_FAIL]: (state, { payload }) => ({
      ...state,
      deleteReview: {
        ...state.deleteReview,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------ ---------------- ------------------- //
    //------------DELETE REVIEW REPLY ------------------- //
    [types.DELETE_REVIEW_REPLY]: (state, { payload }) => ({
      ...state,
      deleteReviewReply: {
        ...state.deleteReviewReply,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_REVIEW_REPLY_SUCCESS]: (state, { payload }) => ({
      ...state,
      deleteReviewReply: {
        ...state.deleteReviewReply,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.DELETE_REVIEW_REPLY_FAIL]: (state, { payload }) => ({
      ...state,
      deleteReviewReply: {
        ...state.deleteReviewReply,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
