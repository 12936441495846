const action_header = "auth/";

// Types
export default {
  SIGNIN_GOOGLE_USER: action_header + "SIGNIN_GOOGLE_USER",

  SIGNIN_FACEBOOK_USER: action_header + "SIGNIN_FACEBOOK_USER",

  SIGNIN_TWITTER_USER: action_header + "SIGNIN_TWITTER_USER",

  SIGNIN_APPLE_USER: action_header + "SIGNIN_APPLE_USER",

  SIGNIN_EMAIL_USER: action_header + "SIGNIN_EMAIL_USER",

  SIGNIN_USER: action_header + "SIGNIN_USER",
  SIGNIN_USER_SUCCESS: action_header + "SIGNIN_USER_SUCCESS",
  SIGNIN_USER_FAILED: action_header + "SIGNIN_USER_FAILED",

  SIGNOUT_USER: action_header + "SIGNOUT_USER",
  SIGNOUT_USER_SUCCESS: action_header + "SIGNOUT_USER_SUCCESS",
  SIGNOUT_USER_FAILED: action_header + "SIGNOUT_USER_FAILED",

  SIGNUP_USER: action_header + "SIGNUP_USER",
  SIGNUP_USER_SUCCESS: action_header + "SIGNUP_USER_SUCCESS",
  SIGNUP_USER_FAILED: action_header + "SIGNUP_USER_FAILED",

  GET_CURRENT_USER: action_header + "GET_CURRENT_USER",
  GET_CURRENT_USER_SUCCESS: action_header + "GET_CURRENT_USER_SUCCESS",
  GET_CURRENT_USER_FAILED: action_header + "GET_CURRENT_USER_FAILED",

  UPGRADE_BUYER_ACCOUNT: action_header + "UPGRADE_BUYER_ACCOUNT",
  UPGRADE_BUYER_ACCOUNT_SUCCESS:
    action_header + "UPGRADE_BUYER_ACCOUNT_SUCCESS",
  UPGRADE_BUYER_ACCOUNT_FAIL: action_header + "UPGRADE_BUYER_ACCOUNT_FAIL",

  SELLER_REGISTER: action_header + "SELLER_REGISTER",
  SELLER_REGISTER_SUCCESS: action_header + "SELLER_REGISTER_SUCCESS",
  SELLER_REGISTER_FAIL: action_header + "SELLER_REGISTER_FAIL",

  FORGET_PASSWORD: action_header + "FORGET_PASSWORD",
  FORGET_PASSWORD_SUCCESS: action_header + "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILED: action_header + "FORGET_PASSWORD_FAILED",

  VERIFY_RESET_PASSWORD: action_header + "VERIFY_RESET_PASSWORD",
  VERIFY_RESET_PASSWORD_SUCCESS: action_header + "VERIFY_RESET_PASSWORD_SUCCESS",
  VERIFY_RESET_PASSWORD_FAILED: action_header + "VERIFY_RESET_PASSWORD_FAILED",
};
