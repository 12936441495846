import React, { Component } from "react";
import { Card, Tabs } from "antd";
import "./NatificationPage.css";

const TabPane = Tabs.TabPane;

class NotificationPage extends Component {
  render() {
    return (
      <div>
        {/* <Card className="gx-card" title="Card Tab"> */}
        <Tabs type="card">
          <TabPane tab="read" key="1">
            <div className="board__news">
              <Card className="news_card">
                <a style={{ padding: "0" }} className="news--item" href="/#">
                  <p className="date">Apr 26, 2019</p>
                  <p className="title">Lorem inpsum</p>
                </a>
              </Card>

              <Card style={{ padding: "0" }} className="news_card">
                <a className="news--item" href="/#">
                  <p className="date">Apr 26, 2019</p>
                  <p className="title">Lorem inpsum</p>
                </a>
              </Card>
            </div>
          </TabPane>
          <TabPane tab="unread" key="2">
            Content of Tab Pane 2
          </TabPane>
        </Tabs>
        {/* </Card> */}
      </div>
    );
  }
}

export default NotificationPage;
