import React from "react";
import { Tabs } from "antd";
import Description from "./Tabs/Description/Description";
// import Features from "./Tabs/Features/Features";
import ShippingOptions from "./Tabs/ShippingOptions/ShippingOptions";
import Specifications from "./Tabs/Specifications/Specifications";
import { IS_INTERNATIONAL_MODE } from "../../../../constants";

const TabPane = Tabs.TabPane;

function ProductDetails({ product }) {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Description" key="1">
        <Description product={product} />
      </TabPane>
      {/* <TabPane tab="Feature" key="2">
        <Features />
      </TabPane> */}
      <TabPane
        tab={IS_INTERNATIONAL_MODE ? "Shipping Options" : "Delivery Options"}
        key="3"
      >
        <ShippingOptions product={product} />
      </TabPane>
      <TabPane tab="Specifications" key="4">
        <Specifications product={product} />
      </TabPane>
    </Tabs>
  );
}

export default ProductDetails;
