import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "antd";
import ShippingAddressModalBody from "./ShippingAddressModalBody";
import { profileActions } from "../../../../modules/ProfilePage/ducks";

class ShippingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
    };
  }

  showModal = () => {
    const { data, isCreate } = this.props;
    if (isCreate) {
      var initialObj = {
        address1: null,
        address2: null,
        city: null,
        country: null,
        created_at: null,
        first_name: null,
        id: null,
        last_name: null,
        modified_at: null,
        phone: null,
        state: null,
        zip: null,
        deault_address: false,
      };
      this.props.profileActions.selectedShippingAddressHandler(initialObj);
    } else {
      this.props.profileActions.selectedShippingAddressHandler(data);
    }

    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  handleform = (data) => {
    this.props.profileActions.selectedShippingAddressHandler(data);
  };

  render() {
    const { isUpdate, isCreate, data } = this.props;
    return (
      <span className="add-card">
        {isCreate ? (
          <Button className="gx-btn-outline-primary" onClick={this.showModal}>
            <i className="fad fa-plus-square mr-1"></i>Add Shipping Address
          </Button>
        ) : isUpdate ? (
          <Button
            className="gx-btn-outline-primary mb-0 mr-2"
            onClick={this.showModal}
          >
            <i className="fad fa-edit mr-1"></i>Edit Address
          </Button>
        ) : null}

        <Modal
          title="Shipping Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          footer={null}
        >
          <ShippingAddressModalBody
            orderedit={this.handleform}
            isUpdate={isUpdate}
            data={data}
            handleOk={this.handleOk}
          />
        </Modal>
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    // selectedItems: state.ShippingAddress.selectedItems,
    orderAddress: state.Profile.orderAddress,
    shippingAddress: state.Profile.shippingAddress,
    selectedShippingAdress: state.Profile.selectedShippingAdress,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingAddressModal)
);
