import React from "react";
import { Radio } from "antd";
import { withRouter } from "react-router-dom";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const ResolutionTypes = ({ productCount, history, type, isDashboard }) => {
  function onChange(e) {
    if (isDashboard) {
      history.push(`/seller/dashboard/resolution-center/${e.target.value}`);
    } else {
      history.push(`/resolution-center/${e.target.value}`);
    }
  }

  return (
    <div className="mb-3">
      <RadioGroup onChange={onChange} value={type} buttonStyle="solid">
        <RadioButton className="mb-0" value="all">
          All
        </RadioButton>
        <RadioButton className="mb-0" value="attentionNeeded">
          Attention Needed
        </RadioButton>
        <RadioButton className="mb-0" value="opened">
          Open
        </RadioButton>
        <RadioButton className="mb-0" value="closed">
          Closed
        </RadioButton>
      </RadioGroup>
    </div>
  );
};

export default withRouter(ResolutionTypes);
