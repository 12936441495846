import React from "react";
import { Col, Row, Divider } from "antd";
import ProductCard from "../../ProductCards/ProductCard/ProductCard";
import { feauredProducts } from "DummyData/FeaturedProducts";
import CardBox from "../../Card/CardBox/CardBox";
import IntlMessages from "util/IntlMessages";
import "./ProductData.less";

const ProductsGrid = ({ data }) => {
  return (
    <Row>
      <Col span={24} className="">
        <CardBox styleName="crd-tag-jr">
          <Divider className="mt-0 mb-2">
            <h4 className="nw-jr-fnt-clr">
              <IntlMessages id="productsGrid" />
            </h4>
          </Divider>

          <Row>
            {data.loading
              ? feauredProducts.map((product, index) => (
                  <Col key={index} xl={6} md={8} sm={12} xs={24}>
                    <ProductCard
                      key={index}
                      grid
                      product={product}
                      isLoading={true}
                    />
                  </Col>
                ))
              : data.data.map((product, index) => (
                  <Col key={index} xl={6} md={8} sm={12} xs={24}>
                    <ProductCard
                      key={index}
                      grid
                      product={product}
                      isLoading={false}
                    />
                  </Col>
                ))}
          </Row>
        </CardBox>
      </Col>
    </Row>
  );
};

export default ProductsGrid;
