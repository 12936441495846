import React, { Component } from "react";
import { Card, Col, Row } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import ReportItemForm from "../../../components/Buyer/ReturnItemForm/ReturnItemForm";
import reportItemsActions from "../ducks";

class ReportItemConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const rcr_item = localStorage.getItem("rcr_item");
    if (rcr_item) {
      const reportedItem = JSON.parse(rcr_item);
      this.setState({
        reportedItem,
      });
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    const { reportedItem } = this.state;

    return (
      <div className="gx-container pt-4">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card
              className="gx-card"
              style={{
                backgroundColor: "rgb(250, 250, 250)",
                minHeight: "500px",
              }}
            >
              <h5 className="mb-3 ml-2">
                <Link className="crd-fnt-jr" to="/resolution-center/all">
                  Resolution Center
                </Link>
                {" > "}
                <Link className="crd-fnt-jr" to="/resolution/report">
                  Recent Orders
                </Link>
                {" > "}Open Dispute
              </h5>
              <ReportItemForm data={reportedItem} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportItem: state.ResolutionCenter.reportItem,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    reportItemsActions: bindActionCreators(reportItemsActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportItemConfirm)
);
