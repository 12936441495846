import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import NextApp from "./NextApp";
import { AppContainer } from "react-hot-loader";
import store from "./store";

const app = (
  // Wrap App inside AppContainer
  <AppContainer>
    <Provider store={store}>
      <NextApp />
    </Provider>
  </AppContainer>
);

ReactDOM.render(app, document.getElementById("root"));

// Do this once
// registerServiceWorker();
