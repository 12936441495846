import React, { Component } from "react";
import { Col, Row } from "antd";
// import IntlMessages from "util/IntlMessages";
import Widget from "../../components/Common/Widget/Widget";

class BuyerHelpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="gx-main-content-wrapper gx-container">
        <Row>
          <Col className="d-flex" xl={18} lg={18} md={24} sm={24} xs={24}>
            <Widget
            // title={<IntlMessages id="sidebar.documents.installation" />}
            >
              <div>
                <h1>Setup Buyer Envrionment</h1>
                <div className="adocs-section-content ng-binding">
                  <p>
                    You can shop on Tradesbarn even if you haven't registered
                    with an account. However, there are some restrictions.
                    Buying as an Tradesbarn guest is easy, but you miss out on
                    some of the benefits of Tradesbarn membership – for example,
                    you won't be able to use MYBARN to keep track of your
                    activity, or make money by selling any unwanted items. To
                    buy as a guest, select Buy It Now, then select Continue as a
                    guest. You'll have to fill in your shipping address, email
                    address, and payment information. For most transactions,
                    you'll have to use PayPal. Registering for a Tradesbarn
                    account It's easy to purchase an item as a guest, but you
                    don't get all the benefits of being a member. If you
                    register for a full account, you can:
                  </p>

                  <ul>
                    <li>
                      <p>Access full order details</p>
                    </li>
                    <li>
                      <p>Use MYBARN to manage and track orders</p>
                    </li>
                    <li>
                      <p>
                        When buying an item, you agree to comply with the rules
                        for buyers and that:
                      </p>
                    </li>
                    <li>
                      <p>
                        You are responsible for reading the full item listing
                        before making a commitment to buy;
                      </p>
                    </li>
                    <li>
                      <p>
                        You enter into a legally binding contract to purchase an
                        item when you commit to buy an item
                      </p>
                    </li>
                    <li>
                      <p>
                        for motor vehicles Real Estate or Bulk Buying Or Selling
                        listed, an offer is not binding, but expresses a buyer's
                        serious interest in the item;
                      </p>
                    </li>
                    <li>
                      <p>
                        For Professional Service Listed User Agreement Binds
                        solely with respect to that particular Service.
                      </p>
                    </li>
                    <li>
                      <p>International buying, selling and translation</p>
                    </li>
                  </ul>
                </div>
              </div>
            </Widget>
          </Col>
          <Col className="d-flex" xl={6} lg={6} md={12} sm={24} xs={24}>
            <div className="gx-card p-3 slr-bcgd">
              <img
                className=""
                style={{ width: "100%", borderRadius: "5px" }}
                alt="example"
                src={require("assets/img/sidw123.jpg")}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BuyerHelpPage;
