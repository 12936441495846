const action_header = "creditCard/";

export default {
  GET_CREDIT_CARD: action_header + "GET_CREDIT_CARD",
  GET_CREDIT_CARD_SUCCESS: action_header + "GET_CREDIT_CARD_SUCCESS",
  GET_CREDIT_CARD_FAILED: action_header + "GET_CREDIT_CARD_FAILED",

  ADD_CREDIT_CARD: action_header + "ADD_CREDIT_CARD",
  ADD_CREDIT_CARD_SUCCESS: action_header + "ADD_CREDIT_CARD_SUCCESS",
  ADD_CREDIT_CARD_FAILED: action_header + "ADD_CREDIT_CARD_FAILED",

  DELETE_CREDIT_CARD: action_header + "DELETE_CREDIT_CARD",
  DELETE_CREDIT_CARD_SUCCESS: action_header + "DELETE_CREDIT_CARD_SUCCESS",
  DELETE_CREDIT_CARD_FAILED: action_header + "DELETE_CREDIT_CARD_FAILED",
};
