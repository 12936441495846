import React from "react";
import CardBox from "../Card/CardBox/CardBox";

export default function policy() {
    return (
        <CardBox style={{ margin: '15px' }}>
            <h1>Privacy Policy</h1>
            <p>
                <strong>
                    In our User Privacy Notice we have compiled all essential information
                    about our handling of your personal data and your corresponding rights
                    for you.
        </strong>
            </p>
            <p>
                <strong>This User Privacy Notice is effective from Jan 1, 2021.</strong>
            </p>
            <p>
                <strong>1. Scope and updates of this User Privacy Notice</strong>
            </p>
            <p>
                This User Privacy Notice applies to your use of this website and all
                Tradesbarn LLC applications, services (including payment services),
                products and tools (collectively the "Services"), regardless of how you
                access or use these Services, including access via mobile devices and apps.
      </p>
            <p>
                <strong>Learn more about section 1</strong>
            </p>
            <p>
                This User Privacy Notice also applies if explicit reference is made to it
                via a link or in a similar manner, e.g. on websites of partners on which
                Services from Tradesbarn LLC are offered.
      </p>
            <p>
                We may change this User Privacy Notice at any time by posting the revised
                User Privacy Notice on this website and indicating the effective date of
                the revised User Privacy Notice. You will be notified of any material
                changes to this User Privacy Notice via My Messages in My Tradesbarn LLC
                and/or by email.
      </p>
            <p>
                <strong>2. Controller</strong>
            </p>
            <p>
                Which Tradesbarn LLC is responsible for the collection and processing of
                your personal data in connection with the provision of the Services depends
                on how you use our Services.
      </p>
            <p>
                <strong>2.1 Use of the Services</strong>
            </p>
            <p>
                Depending on the region in which you are located, one of the following
                Tradesbarn LLC group companies is responsible for the collection and
                processing of your personal data in connection with the provision of our
                Services (except payment services for sellers):
      </p>
            <p>
                • USA: Tradesbarn LLC, 3675 Gabriel Lane, #1218 Aurora IL 60504.
      </p>
            <p>
                • For all other countries: Tradesbarn LLC, 575 Avissawella Road, Colombo.
                Srilanka 10620
      </p>
            <p>
                <strong>2.2 Use of the payment services for sellers</strong>
            </p>
            <p>
                Depending on the region in which you are located and whether we provide our
                payment services there, the following Tradesbarn LLC group companies are
                responsible for the collection and processing of your personal data in
                connection with the provision of our payment services to sellers:
      </p>
            <p>
                • USA: Tradesbarn LLC, 3675 Gabriel Lane, #1218 Aurora IL 60504.
      </p>
            <p>
                <strong></strong>
            </p>
            <p>
                <strong> 3. Data protection officer and contact</strong>
            </p>
            <p>
                We have appointed data protection officers in several countries to oversee
                the protection of your personal data
      </p>
            <p>
                <strong>4. What personal data we collect and process</strong>
            </p>
            <p>
                We collect your personal data when you use our Services, create a new
                Tradesbarn LLC account, provide us with information via a web form, add or
                update information in your Tradesbarn LLC account, participate in online
                community discussions or otherwise interact with us. We also collect
                personal data from other sources (such as other Tradesbarn LLC Inc.
                corporate family members, credit agencies or bureaus, and other data
                brokers).
      </p>
            <p>
                In total, we collect the following personal data:
      </p>
            <p>
                <strong>
                    4.1 Personal data you provide when using our Services or creating a
                    Tradesbarn LLC account
          </strong>
            </p>
            <p>
                · Data that identifies you, such as your name, address, telephone numbers,
                email addresses, your user name or your tax identification number that you
                provide when setting up your Tradesbarn User account or at a later date.
      </p>
            <p>
                · If you use our payment services as a seller, additional identification
                data (such as social security number or date of birth), tax identification
                numbers (such as VAT identification number) and other information (such as
                bank account number) that you provide when using our payment services as a
                seller.
      </p>
            <p>
                · Data regarding bids, purchases, or sales that you provide in a
                transaction.
      </p>
            <p>
                · Content that you share with other users through our messaging tools
      </p>
            <p>
                · Financial information (e.g. credit card and account numbers, transaction
                details, and form of payment).
      </p>
            <p>
                · Shipping, billing, and other information you provide, as well as
                information required for customs clearance (such as tax identification
                numbers or other identification numbers) and relevant shipping information
                (such as shipment numbers and tracking information) if shipped through one
                of our programs.
      </p>
            <p>
                · In some cases: age, gender, country of birth, nationality, employment
                status, family status, interests and preferences.
      </p>
            <p>
                · You may provide us with additional information through a web form or by
                updating or adding information to your Tradesbarn account, by participating
                in community discussions, member chats, inquiries, dispute resolution,
                customer service calls recorded with your consent, or if you contact us for
                any other reason regarding our Services.
      </p>
            <p>
                · Other data that we are required or entitled by applicable law to collect
                and process and that we need for your authentication or identification, or
                for the verification of the data we collect.
      </p>
            <p>
                <strong></strong>
            </p>
            <p>
                <strong>
                    4.2 Personal data we collect automatically when you use our Services or
                    create a Tradesbarn LLC account
          </strong>
            </p>
            <p>
                · Data that is generated as part of one of your transactions (bids,
                purchases, sales) or that is linked to your Tradesbarn account as a result
                of a transaction in which you are involved, such as transaction amounts,
                time and location of transactions and form of payment or payout method.
      </p>
            <p>
                · Data that is generated through your other actions when you use our
                Services and which is linked to your Tradesbarn account, e.g. when you
                place items in your shopping cart, place items on the watch list, save
                sellers, searches or interests, or follow users.
      </p>
            <p>
                · Data regarding all other interactions with our Services, your advertising
                preferences, and your communications with us.
      </p>
            <p>
                · Location data, including your general location data (e.g., IP address)
                and the precise location data of your mobile device. Please note that for
                most mobile devices, you can manage or disable the use of location services
                for all applications in the settings menu of your mobile device.
      </p>
            <p>
                · Computer and connection information, such as statistics regarding your
                use of services from eBay, information on data traffic to and from
                websites, referral URL, information on advertisements, your IP address,
                your access times, your browser history data, your language settings and
                your weblog information.
      </p>
            <p>
                <strong>
                    4.3 Personal data we collect in connection with the use of cookies and
                    similar technologies
          </strong>
            </p>
            <p>
                · We use cookies, web beacons and similar technologies to collect data
                while you use our Services. We collect this data from the devices
                (including mobile devices) that you use our Services with. The data
                collected includes the following usage- and device-related information:
      </p>
            <p>
                · Data about the pages you visit, the access time, frequency and duration
                of visits, the links on which you click and other actions you take as part
                of your use of our Services and in advertising and email content.
      </p>
            <p>
                · Data about your activities and interactions with our advertising partners
                including data about the advertisements you were shown, how often they were
                shown, when and where they were shown, and whether you took any action,
                such as clicking on an advertisement or making a purchase.
      </p>
            <p>
                · The user segment or category into which you as a user fall, for example:
                male, 20-49 years old, interested in cars.
      </p>
            <p>
                · Model or device type, operating system and version, browser type and
                settings, device ID or individual device identifier, advertisement ID,
                individual device token, and cookie-related data (e.g. cookie ID).
      </p>
            <p>
                · The IP address from which your device accesses the Services.
      </p>
            <p>
                · Location data, including your general location data (e.g., IP address)
                and the precise location data of your mobile device. Please note that most
                mobile devices allow you to manage or disable the use of location services
                for all applications in the settings menu.
      </p>
            <p>
                <strong>4.4 Personal data from other sources</strong>
            </p>
            <p>
                · We also collect personal data about you from other sources and from third
                parties to the extent permitted by applicable law. In particular, this
                includes the following data:
      </p>
            <ul>
                <li>
                    Data from public sources (e.g. demographic data)
          </li>
            </ul>
            <p>
                · Data from credit agencies or bureaus (e.g. credit reports/checks,
                identity confirmation, data for risk modeling and setting of credit limits)
      </p>
            <p>
                · Data from data providers (e.g. "Know Your Customer"/identity
                verification, demographic, interest-based and online advertising related
                data)
      </p>
            <p>
                · With regard to our payment services for sellers: data from government or
                other sources concerning any previous convictions of the respective seller,
                to the extent permitted by applicable law
      </p>
            <p>
                We combine or connect the personal data we collect from you with data from
                these other sources. Where personal data is disclosed to us by third
                parties that these third parties are legally permitted to disclose your
                personal data to us. We also receive access to personal data about you from
                other members of the Tradesbarn LLC Inc. corporate family.
      </p>
            <p>
                <strong>4.5 Social network data you share with us</strong>
            </p>
            <p>
                · We allow you to use social networks (such as Facebook) or other providers
                of single sign-on services (such as Google or Apple) with whom you already
                have an account to create an Tradesbarn account or to link your Tradesbarn
                account to such single sign-on services. You can determine the personal
                data that we can access when authorizing the connection with the single
                sign-on service.
      </p>
            <p>
                · We allow you to share personal data with social networks (such as
                Facebook) or to link your Tradesbarn account to a social network. These
                social networks may automatically provide us with access to certain
                personal data they have stored about you (e.g. content you have viewed or
                enjoyed, information about the advertisements you have been shown or
                clicked on, etc.). You can determine the personal data that we can access
                through the privacy settings of each social network.
      </p>
            <p>
                · If you give us access to video content pages, your grant of access is
                your consent that we may, for at least two years, or until you withdraw
                your consent or are no longer connected to the social network, share with
                and collect from social networks information regarding your viewing of
                videos.
      </p>
            <p>
                · We may also use plug-ins or other technologies from various social
                networks. If you click on a link displayed through a social network
                plug-in, you voluntarily connect to that social network.
      </p>
            <p>
                <strong>
                    5. Purposes and legal basis for data processing and categories of
                    recipients
          </strong>
            </p>
            <p>
                We process your personal data for various purposes and based on several
                different legal bases that allow this processing. For example, we process
                your personal data to provide and improve our Services, to provide you with
                a personalized user experience on this website, to contact you about your
                Tradesbarn account and our Services, to provide customer service, to
                provide you with personalized advertising and marketing communications, and
                to detect, prevent, mitigate and investigate fraudulent or illegal
                activity. We also share your information with third parties, including
                service providers acting on our behalf, for these purposes. In addition, we
                may share your personal data among Tradesbarn LLC group companies in order
                to fulfil our contract with you under the User Agreement and, if
                applicable, the Payments Terms of Use.
      </p>
            <p>
                Below you will find a summary of the purposes for which we process your
                personal data, including the categories of recipients to whom we transmit
                personal data for the purposes stated, sorted by our legal basis for this
                processing or sharing:
      </p>
            <p>
                <strong></strong>
            </p>
            <p>
                <strong>
                    5.1 We process your personal data in order to fulfil our contract with
                    you and to provide you with our Services. This includes the following
                    purposes:
          </strong>
            </p>
            <p>
                Processing of data relating to you or your company for the purpose of
                entering into a contract with you and executing it.
      </p>
            <p>
                Provision of our Services, including but not limited to enabling and
                performing transactions with other users (including the transmission of
                your personal data to other users where necessary to perform the
                transaction, including in cases of terminated, failed or subsequently
                voided transactions, e.g. by sharing your return address so a buyer may
                return an item), displaying your transaction and feedback history to you,
                providing and enhancing features such as payment processing, ratings and
                Tradesbarn account management, providing other services you may use (as
                described in connection with such services), and ensuring the functionality
                of our Services. In connection with the provision of our Services, we will
                send you notifications relating to the execution of transactions and the
                use of our Services in accordance with the communication preferences in
                your Tradesbarn account.
      </p>
            <p>
                Enabling the delivery of purchased items by logistics/shipping service
                providers including notifications in connection with the delivery (such as
                tracking information), the latter to the extent permitted by applicable law
                without your consent.
      </p>
            <p>
                Provision of our payment services in accordance with the Payments Terms of
                Use.
      </p>
            <p>
                Providing general customer support including the solution of problems with
                your Tradesbarn account, arbitration of disputes, providing other services
                within the scope of customer service as well as enforcement of fee claims.
                For these purposes, we may contact you via notification in My Tradesbarn,
                telephone, SMS, push notification on your mobile device or by mail. If we
                contact you by telephone, in order to ensure efficiency, we may use
                automatically dialed calls with tape announcements or automated text
                messages in accordance with our User Agreement and Payments Terms of Use,
                to the extent permitted by applicable law.
      </p>
            <p>
                Processing of general location data (such as IP address or postal code) in
                order to provide you with location-based services (such as radius search
                and other content that is personalized on the basis of your general
                location data).
      </p>
            <p>
                Enforcement of our User Agreement, Payments Terms of Use, this User Privacy
                Notice and other rules and policies.
      </p>
            <p>
                Publication and promotion of your listings and related content on the
                websites or in the applications, services and tools of other Tradesbarn
                LLC. Corporate family members or cooperating third party operators of
                websites, applications, services and tools. When we share the content of
                your listings and any related personal data with third parties, we do so
                only on the basis of an agreement that limits the use of such personal data
                by the third party to the purposes necessary to fulfil its contractual
                obligations to us. Third party providers are contractually obliged to take
                appropriate security measures with regard to this data. Third party
                providers are strictly forbidden to pass on personal data contained in your
                listings to other third parties. In case of data transmissions to
                Tradesbarn LLC. Corporate family members, such restrictions result from our
                Binding Corporate Rules
      </p>
            <p>
                Where necessary, we transmit your personal data to processors and the
                following recipients for one or several of the purposes described above:
      </p>
            <p>
                • Other Tradesbarn LLC users
      </p>
            <p>
                • External service providers and shipping companies (such as DHL, UPS,
                etc.)
      </p>
            <p>
                • Payment service providers including the PayPal Inc. group of companies
      </p>
            <p>
                • External operators of websites, applications, services and tools
      </p>
            <p>
                <strong>
                    5.2 We process your personal data in order to comply with legal
                    obligations to which we are subject. This includes the following
                    purposes:
          </strong>
            </p>
            <p>
                Participation in investigations and proceedings (including judicial
                proceedings) conducted by public authorities or government agencies, in
                particular, for the purpose of detecting, investigating and prosecuting
                illegal acts.
      </p>
            <p>
                Prevention, detection and mitigation of illegal activities (e.g. fraud,
                money laundering and terrorist financing).
      </p>
            <p>
                Complying with information requests from third parties based on any
                statutory information rights they have against us (e.g. in the event of an
                intellectual property infringement, product piracy, or other unlawful
                activity).
      </p>
            <p>
                Ensuring the information security of our Services.
      </p>
            <p>
                Where necessary, we transmit your personal data to processors and the
                following recipients for one or several of the purposes described above:
      </p>
            <p>
                Law enforcement agencies, courts, government agencies or public
                authorities, intergovernmental or supranational bodies (in connection with
                our payment services provided in the EU for sellers in particular with
                regard to compliance with Luxembourg laws concerning FATCA dated July 7,
                2015 and concerning the OECD Common Reporting Standard dated December 18,
                2015).
      </p>
            <p>
                Third parties based on statutory information claims against us
      </p>
            <p>
                Third party service providers
      </p>
            <p>
                Third parties who are involved in judicial proceedings, in particular, if
                they submit a legal order, court order or equivalent legal order to us.
      </p>
            <p>
                Credit agencies, bureaus, or associations, if required by applicable law
                (e.g. information on payment delays, payment defaults or other
                irregularities that may be relevant to your credit report).
      </p>
            <p>
                <strong></strong>
            </p>
            <p>
                <strong>
                    5.3 We process your personal data in order to protect your vital
                    interests or the vital interests of another natural person. This
                    includes the following purposes:
          </strong>
            </p>
            <p>
                Prevention, detection, mitigation and investigation of unlawful activities
                that may result in impairment of your vital interests or the vital
                interests of another natural person, unless there is a statutory obligation
                to this effect.
      </p>
            <p>
                Where necessary, we transmit your personal data to processors and the
                following recipients for one or several of the purposes described above:
      </p>
            <p>
                Law enforcement agencies, courts, government agencies or public
                authorities, intergovernmental or supranational bodies
      </p>
            <p>
                Third parties who are involved in judicial proceedings
      </p>
            <p>
                External service providers
      </p>
            <p>
                <strong>
                    5.4 We process your personal data where necessary for the purposes of
                    the legitimate interests pursued by us or by a third party, except
                    where such interests are overridden by your interests or fundamental
                    rights and freedoms.
          </strong>
            </p>
            <p>
                In order to reconcile our legitimate interests with your rights, we have
                introduced appropriate control mechanisms. On this basis, we process your
                data for the following purposes:
      </p>
            <p>
                Participation in proceedings (including judicial proceedings) conducted by
                courts, law enforcement agencies, government agencies or public
                authorities, intergovernmental or supranational bodies, in particular for
                the purpose of detecting, investigating and prosecuting illegal acts,
                unless there is a statutory obligation to this effect, and we may
                legitimately assume that the disclosure of the data is necessary to avert
                imminent disadvantages or to report a suspicion of an illegal act. In such
                cases, we will only disclose what we believe is necessary, such as your
                name, city, zip code, telephone number, email address, (previous) user
                names, IP address, fraud complaints, bidding and listing history.
      </p>
            <p>
                Protection of the legitimate interests of third parties in connection with
                civil law disputes, unless there is a statutory obligation to this effect,
                if we may legitimately assume that it is necessary to disclose the data to
                such third parties in order to avert imminent disadvantages.
      </p>
            <p>
                Prevention, detection, mitigation and investigation of fraud, security
                breaches and other prohibited or unlawful activities, including the
                assessment of corresponding risks (e.g. through the use of captchas or the
                telephone number stored in your Tradesbarn account for two-factor
                authentication), unless there is a statutory obligation to this effect.
      </p>
            <p>
                Monitoring and improvement of the information security of our Services,
                unless there is a statutory obligation to this effect.
      </p>
            <p>
                Performance of identity checks, creditworthiness and other financial
                standing checks, evaluation of applications and comparison of information
                for accuracy and verification purposes.
      </p>
            <p>
                Automatic filtering and, where necessary, manual review of messages sent
                through our messaging tools to prevent fraudulent or suspicious activity or
                violations of our User Agreement or other rules and policies, including
                enforcing the prohibition of purchases and sales outside of Tradesbarn,
      </p>
            <p>
                Provision of functions for users that make the processing of transactions
                easier or more convenient (e.g. administration of several delivery
                addresses).
      </p>
            <p>
                Analysis and improvement of the Services from Tradesbarn LLC. corporate
                family members, e.g. by reviewing site usage data or information from users
                about blocked or crashed pages in order to identify and solve problems and
                to provide you with an improved user experience, including as part of
                product development.
      </p>
            <p>
                Analysis of telephone conversations with our customer service that we
                recorded with your consent.
      </p>
            <p>
                Advertisements by mail (according to your communication preferences in your
                Tradesbarn account).
      </p>
            <p>
                To the extent permitted by applicable law without your consent,
                communications with you via electronic mail (e.g. email or text message) or
                telephone to offer you vouchers, discounts and special offers, to conduct
                opinion polls and surveys, and to inform you about our Services (according
                to your communication preferences in your Tradesbarn account). If you do
                not wish to receive marketing communications from us, you can also
                unsubscribe by clicking on the link in the email you received. For
                technical reasons, the implementation may take a few days.
      </p>
            <p>
                Notifications regarding promotions and information about our Services after
                logging into your account or in My Messages in My Tradesbarn (according to
                your communication preferences in your Tradesbarn account).
      </p>
            <p>
                For users outside the European Economic Area (EEA): Personalization,
                measurement and improvement of our and third party advertisements in our
                online offerings, the online offerings of other Tradesbarn LLC. Corporate
                family members or third parties.
      </p>
            <p>
                Customization of page content to display the items and services you may
                like based on the actions you take.
      </p>
            <p>
                Evaluation of the quality and success of our email marketing campaigns
                (e.g. through analysis of opening and click rates).
      </p>
            <p>
                Assessment of the service status (e.g. on the basis of tracking information
                if sellers use shipping labels from shipping providers via Tradesbarn or
                provide tracking numbers).
      </p>
            <p>
                Offering of partner and bonus programs and other co-branded marketing
                efforts, e.g. co-branded credit cards in collaboration with a third party
                credit card issuer.
      </p>
            <p>
                Provision of shared content and services (such as registration for
                services, transaction processing and customer service) with Tradesbarn LLC.
                Corporate family members or cooperating payment service providers.
      </p>
            <p>
                Initiation, preparation and execution of a company acquisition, e.g. in the
                event of a merger with another company or takeover by another company. If
                such an event occurs, we will require the merged entity to comply with this
                User Privacy Notice with respect to your personal data. Should your
                personal data be processed for any purpose not specified in this User
                Privacy Notice, you will be informed in advance of the processing of your
                data for this new purpose.
      </p>
            <p>
                Assertion of or defense against legal claims, including those asserted by
                one Tradesbarn user against another Tradesbarn user.
      </p>
            <p>
                Where necessary, we transmit your personal data to processors and the
                following recipients for one or several of the purposes described above:
      </p>
            <p>
                External service providers
      </p>
            <p>
                Other Tradesbarn LLC users
      </p>
            <p>
                Law enforcement agencies, courts, government agencies or public
                authorities, intergovernmental or supranational bodies
      </p>
            <p>
                Third parties who are involved in judicial proceedings
      </p>
            <p>
                Payment service providers including the PayPal Inc. group of companies
      </p>
            <p>
                Credit agencies or bureaus, data verification services, risk assessment
                vendors and collections agencies (e.g. information about payment delays,
                payment defaults, or other irregularities that may be relevant to your
                credit report, or that we use to confirm your identity, model risk,
                establish credit limits, or collect unpaid debts)
      </p>
            <p>
                Other companies in the context of a company acquisition
      </p>
            <p>
                Participants with third parties in the event of an investigation for fraud,
                intellectual property infringement, retail crime, stolen goods, product
                piracy or other unlawful activity, if we, in our sole discretion, deem the
                investigation of such incident necessary or useful. In such cases, we will
                disclose the seller's name, address, city, zip code, country, telephone
                number, email address and company name to the third party and bind the
                third party by a worldwide non-disclosure agreement to treat the data as
                confidential.
      </p>
            <p>
                <strong>
                    5.5 With your consent, we process your personal data for the following
                    purposes
          </strong>
          :
      </p>
            <p>
                For users within the European Economic Area (EEA): Personalization,
                measurement and improvement of our and third party advertisements in our
                online offerings, the online offerings of other Tradesbarn LLC. Corporate
                family members or third parties
      </p>
            <p>
                Marketing communications by telephone or electronic mail (such as email or
                SMS), including communications by other Tradesbarn LLC. Corporate family
                members or by third parties, unless these communications are permitted
                without your consent under applicable law. We may engage third parties to
                send marketing communications on our behalf
      </p>
            <p>
                Processing of your precise location data to provide location-based services
      </p>
            <p>
                Provision of a single sign-on service allowing you to register or log-in to
                third party services using your Tradesbarn log-in credentials
      </p>
            <p>
                Processing of your personal data on the basis of your consent, which you
                have given so that we or third parties can enable you to use certain
                services or make them available to you
      </p>
            <p>
                Where necessary, we transmit your personal data to processors and the
                following recipients for one or several of the purposes described above:
      </p>
            <p>
                External service providers
      </p>
            <p>
                Third parties using our single sign-on service (as authorized by you in
                each single case)
      </p>
            <p>
                Other third parties with whom we partner to offer you specific services (as
                described at the collection of the respective user consent)
      </p>
            <p>
                Third party financial institutions with whom we partner to offer financial
                products to you, for them to provide joint content and services (such as,
                registration, transactions and customer support). These third party
                financial institution partners will use your personal data to send you
                marketing communications only if you have requested their services
      </p>
            <p>
                <strong>
                    5.6 Data Sharing between Tradesbarn LLC. Corporate Family Members
          </strong>
            </p>
            <p>
                As set out above under "Personal data from other sources", we also receive
                access to personal data about you from other Tradesbarn LLC. Corporate
                family members. This enables us, in particular, to provide you, with your
                consent as legally required, with information about products and services,
                which we believe might interest you, and improve our products, services,
                content, and advertising by analyzing your use of the services of other
                Tradesbarn LLC Inc. corporate family members, in particular through the
                matching of certain data (e.g. cookie IDs). Furthermore, this allows us to
                better prevent, detect, mitigate and investigate fraud, security breaches
                and other prohibited or unlawful activities, including the assessment of
                corresponding risks. We will also grant access to personal data about you
                to other Tradesbarn LLC. Corporate family members for the aforementioned
                purposes. To the extent that other Tradesbarn LLC. Corporate family members
                have access to your personal data, they will follow practices that are at
                least as restrictive as the practices described in this User Privacy
                Notice.
      </p>
            <p>
                <strong>
                    5.7 Additional information regarding our payment services for sellers
          </strong>
            </p>
            <p>
                With regard to our payment services for sellers in the EU, we additionally
                point out that Tradesbarn LLC is subject to professional secrecy, which
                requires special transparency with regard to the processing and, in
                particular, the transmission of your personal data. We therefore expressly
                draw your attention to the fact that Tradesbarn LLC may pass on your
                personal data to trustworthy third parties and business partners where
                necessary to provide our payment services for sellers, as described above
                in this Section 5 of the User Privacy Notice. This includes in particular
                the following third parties and business partners:
      </p>
            <p>
                Other payment service providers, including financial institutions
      </p>
            <p>
                Companies offering financial products, analyses and scoring (e.g. credit
                agencies or bureaus)
      </p>
            <p>
                Authorities, agencies and other governmental bodies (e.g. to combat fraud)
      </p>
            <p>
                Auditors and other professional advisors
      </p>
            <p>
                You can request a complete list of all third party providers and business
                partners for our payment services for sellers in the EU by contacting us.
      </p>
            <p>
                <strong>5.8 Automated decision-making</strong>
            </p>
            <p>
                We use technologies that are considered automated decision making or
                profiling. We will not make any automated decisions about you that would
                significantly affect you unless such a decision is necessary for entering
                into, or the performance of, a contract with you, we have obtained your
                consent, or we are required by applicable law to use such technology. You
                will find information on your right to object to this processing of your
                data below under Rights as a data subject.
      </p>
            <p>
                <strong>6. International data transfers</strong>
            </p>
            <p>
                Some recipients of your personal data are located outside your country or
                have offices in countries where data protection laws may provide a
                different level of protection than the laws in your country. When
                transferring personal data to such recipients, we provide appropriate
                safeguards.
      </p>
            <p>
                <strong>
                    6.1 Data transfers to Tradesbarn LLC. Corporate family members
          </strong>
            </p>
            <p>
                The transmission of personal data between different Tradesbarn LLC.
                corporate family members (e.g. to enable cross border transactions and for
                other legally permitted purposes) is based on our worldwide data protection
                principles, which are binding internal data protection regulations (Binding
                Corporate Rules, BCRs), or on the basis of other appropriate safeguards
                (e.g. standard data protection clauses issued or approved by the European
                Commission (2010/87/EU, 2001/497/EC or 2004/915/EC)). Through our Binding
                Corporate Rules, the Tradesbarn LLC. Corporate family members undertake to
                protect your personal data and to comply with data protection obligations.
                Further information on our binding company-wide guidelines and our
                worldwide data protection principles can be found in the Tradesbarn LLC
                Privacy Center.
      </p>
            <p>
                <strong>
                    6.2 Other data transfers (from the European Economic Area to third
                    countries)
          </strong>
            </p>
            <p>
                We will only transfer your personal data from the European Economic Area
                (EEA) to third countries, i.e. countries outside the EEA, on the basis of
                appropriate safeguards. Third countries providing an adequate level of data
                protection according to the European Commission currently include Andorra,
                Argentina, Canada (for companies covered by the Personal Information
                Protection and Electronic Documents Act), Switzerland, the Faroe Islands,
                Guernsey, the State of Israel, the Isle of Man, Japan, Jersey, New Zealand
                and Uruguay. In other cases, Tradesbarn LLC provides the necessary
                safeguards, e.g. through the conclusion of data protection contracts
                adopted by the European Commission (e.g. standard data protection clauses
                (2010/87/EU, 2001/497/EC or 2004/915/EC)) with the recipients, or through
                other measures provided for by law. A copy of the documentation of the
                measures taken by us is available on request.
      </p>
            <p>
                <strong>7. Storage duration and erasure</strong>
            </p>
            <p>
                Your personal data will be stored by us and our service providers in
                accordance with applicable data protection laws to the extent necessary for
                the processing purposes set out in this User Privacy Notice (see Purposes
                and legal basis for data processing and categories of recipients for more
                information on the processing purposes). Subsequently, we will delete your
                personal data in accordance with our data retention and deletion policy or
                take steps to properly render the data anonymous, unless we are legally
                obliged to keep your personal data longer (e.g. for legal compliance, tax,
                accounting or auditing purposes). In Europe, the retention periods are
                generally between 6 and 10 years (e.g. for contracts, notifications and
                business letters). As far as legally permissible or required, we restrict
                the processing of your data instead of deleting it (e.g. by restricting
                access to it). This applies in particular to cases where we may still need
                the data for the execution of the contract or for the assertion of or
                defense against legal claims, or where such retention is otherwise required
                or permitted by law. In these cases, the duration of the restriction of
                processing depends on the respective statutory limitation or retention
                periods. The data will be deleted after the relevant limitation or
                retention periods have expired.
      </p>
            <p>
                The specific retention periods for personal data are documented in our
                regional data retention guidelines. How long we retain personal data may
                vary depending on the Services we provide and our legal obligations under
                applicable national law. The following factors typically affect the
                retention period:
      </p>
            <p>
                • Necessity for the provision of our Services
      </p>
            <p>
                This includes such things as executing the User Agreement with you,
                maintaining and improving the performance of our products, keeping our
                systems secure, and maintaining appropriate business and financial records.
                Most of our retention periods are determined on the basis of this general
                rule.
      </p>
            <p>
                • Special categories of personal data
      </p>
            <p>
                If we store special categories of personal data (i.e., personal data
                revealing racial or ethnic origin, political opinions, religious or
                philosophical beliefs, trade union membership, genetic or biometric data
                which is being processed for the purpose of uniquely identifying a natural
                person, data concerning health or data concerning a natural person's sex
                life or sexual orientation), a shorter retention period is usually
                appropriate.
      </p>
            <p>
                • Consent-based processing of personal data
      </p>
            <p>
                If we process personal data on the basis of consent (including consent to
                the extended storage), we store the data for as long as necessary in order
                to process it according to your consent.
      </p>
            <p>
                • Statutory, contractual or other similar obligations
      </p>
            <p>
                Corresponding storage obligations may arise, for example, from laws or
                official orders. It may also be necessary to store personal data with
                regard to pending or future legal disputes. Personal data contained in
                contracts, notifications and business letters may be subject to statutory
                storage obligations depending on national law.
      </p>
            <p>
                <strong> </strong>
            </p>
            <p>
                <strong>8. Rights as a data subject</strong>
            </p>
            <p>
                Subject to possible restrictions under national law, as a data subject, you
                have the right to access, rectification, and erasure, restriction of
                processing and data portability with regard to your personal data. In
                addition, you can withdraw your consent and object to our processing of
                your personal data on the basis of our legitimate interests. You can also
                lodge a complaint with a supervisory authority.
      </p>
            <p>
                Your rights in detail:
      </p>
            <p>
                • You can withdraw your consent to the processing of your personal data by
                us at any time. As a result, we may no longer process your personal data
                based on this consent in the future. The withdrawal of consent has no
                effect on the lawfulness of processing based on consent before its
                withdrawal.
      </p>
            <p>
                • You have the right to obtain access to your personal data that is being
                processed by us. In particular, you may request information on the purposes
                of the processing, the categories of personal data concerned, the
                categories of recipients to whom the personal data have been or will be
                disclosed, the envisaged period for which the personal data will be stored,
                the existence of the right to request rectification or erasure of personal
                data or restriction of processing of personal data or to object to such
                processing, the right to lodge a complaint with a supervisory authority,
                any available information as to the personal data’s source (where they are
                not collected from you), the existence of automated decision-making,
                including profiling and, where appropriate, meaningful information on its
                details. Your right to access may be limited by national law.
      </p>
            <p>
                • You have the right to obtain from us without undue delay the
                rectification of inaccurate personal data concerning you. Taking into
                account the purposes of the processing, you have the right to have
                incomplete personal data completed, including by means of providing a
                supplementary statement.
      </p>
            <p>
                • You have the right to obtain from us the erasure of personal data
                concerning you, unless processing is necessary for exercising the right of
                freedom of expression and information, for compliance with a legal
                obligation, for reasons of public interest or for the establishment,
                exercise or defense of legal claims. The right to erasure may be limited by
                national law.
      </p>
            <p>
                • You have the right to obtain from us restriction of processing to the
                extent that
      </p>
            <p>
                • The accuracy of the data is disputed by you,
      </p>
            <p>
                • The processing is unlawful, but you oppose the erasure of the personal
                data,
      </p>
            <p>
                • We no longer need the data, but you need it to assert, exercise or defend
                legal claims or
      </p>
            <p>
                • You have objected to the processing.
      </p>
            <p>
                • You have the right to receive the personal data concerning you, which you
                have provided to us, in a structured, commonly used and machine-readable
                format and have the right to transmit those data to another controller
                ("right to data portability").
      </p>
            <p>
                • You have the right to lodge a complaint with a supervisory authority. As
                a rule, you can contact the supervisory authority of your usual place of
                residence, your place of work or the registered office of the controller.
      </p>
            <p>
                If your personal data is processed on the basis of our legitimate
                interests, you have the right to object to the processing of your personal
                data on grounds relating to your particular situation. This also applies to
                profiling. If your personal data is processed by us for direct marketing
                purposes, you have the right to object at any time to the processing of
                your personal data for such marketing, which includes profiling to the
                extent that it is related to such direct marketing.
      </p>
            <p>
                The exercise of the above data subjects' rights (e.g. right to access or
                erasure) is generally free of charge. Where requests are manifestly
                unfounded or excessive, in particular because of their repetitive
                character, we may charge an appropriate fee (at most our actual costs) in
                accordance with the applicable statutory regulations or refuse to process
                the application.
      </p>
            <p>
                Exercising your rights and managing your settings
      </p>
            <p>
                You can exercise your rights as a data subject via our Privacy Contact
                page. In addition, you are of course free to contact us.
      </p>
            <p>
                If you would like to change your preferences regarding Tradesbarn LLC
                notifications (including marketing communications), you can do so at any
                time in My Tradesbarn under "Communication Preferences". If you do not wish
                to receive marketing communications from us, you can also unsubscribe by
                clicking on the link in the email you received. For technical reasons, the
                implementation may take a few days. For information on how to manage your
                cookie and similar technology preferences, see the next section Cookies
                &amp; similar technologies.
      </p>
            <p>
                <strong>9. Cookies &amp; similar technologies</strong>
            </p>
            <p>
                When you use our Services, we and selected third parties may use cookies
                and similar technologies to provide you with a better, faster and safer
                user experience or to show you personalized advertising. Cookies are small
                text files that are automatically created by your browser and stored on
                your device when you use the Services. You can find detailed information
                about our use of cookies and similar technologies and your choices in our
                User Cookie Notice.
      </p>
            <p>
                Our cookies and similar technologies have different functions:
      </p>
            <p>
                They may be technically necessary for the provision of our Services
      </p>
            <p>
                They help us optimize our Services technically (e.g. monitoring of error
                messages and loading times)
      </p>
            <p>
                They help us improve your user experience (e.g. save font size and form
                data entered)
      </p>
            <p>
                They allow us to show you more relevant advertisements
      </p>
            <p>
                We use cookies and similar technologies that remain on your device only as
                long as your browser is active (session cookies), as well as cookies and
                similar technologies that remain on your device longer (persistent
                cookies). Where possible, we take appropriate security measures to prevent
                unauthorized access to our cookies and similar technologies. A unique ID
                ensures that only we and/or selected third parties have access to cookie
                data.
      </p>
            <p>
                Your choices regarding cookies
      </p>
            <p>
                You are free to disable the use of cookies and similar technologies if this
                is supported by your device. You can manage your cookie settings in your
                browser or device settings. In addition, you can decide whether we may use
                cookies and similar technologies to show you personalized advertisements:
      </p>
            <p>
                To manage the settings regarding the use of first-party cookies (and
                similar technologies) for advertising-related purposes, please go to Ad
                Choice - Tradesbarn LLC Advertising Preferences (you can also access this
                page directly via a link in the respective advertisement or the footer of
                our website)
      </p>
            <p>
                If you decide not to have your personal data processed by us for
                advertising purposes via cookies (and similar technologies), this does not
                mean that we will not show you advertisements. It simply means that these
                advertisements will not be personalized for you using first-party or
                third-party cookies, web beacons, or similar technologies.
      </p>
            <p>
                <strong>10. Data security</strong>
            </p>
            <p>
                We protect your personal data through technical and organizational security
                measures to minimize risks associated with data loss, misuse, unauthorized
                access and unauthorized disclosure and alteration. To this end we use
                firewalls and data encryption, for example, as well as physical access
                restrictions for our data centers and authorization controls for data
                access. You can find further information on our data security in our
                Security Center.
      </p>
            <p>
                <strong>11. Other important information regarding data protection</strong>
            </p>
            <p>
                This section contains important additional information about the protection
                of personal data in connection with the use of our Services, including
                whether you are required to provide personal data.
      </p>
            <p>
                What happens when you share your personal data on our sites or
                applications?
      </p>
            <p>
                Other users have access to the information you share on Tradesbarn or
                disclose to other users. For example, other users can see your bids,
                purchases, items for sale, saved interests, sellers and searches,
                storefronts, feedback, ratings, product reviews and associated comments.
                Other users can also see any information you chose to share in your
                profile.
      </p>
            <p>
                When you use our Services, your public user ID may be displayed and
                available to the public and associated with all of your public Tradesbarn
                activity. Notices sent to other users about suspicious activity and notice
                violations on our sites may refer to your public user ID and specific
                items. Accordingly, if you use a username that allows others to identify
                you, these others may be able to identify your Tradesbarn activities.
      </p>
            <p>
                To help protect your personal data, we allow only limited access to other
                users' contact, shipping and financial information as necessary to
                facilitate your transactions and collect payments. However, when users are
                involved in a transaction, they have access to each other's name, user ID,
                email address and other contact and shipping information. For example, we
                may allow users to exchange telephone numbers in order to contact each
                other prior to completing a transaction (e.g. a seller may opt to share
                their telephone number with a buyer so that the buyer may call with
                questions about a listed item). In this case, sellers are prohibited from
                using a buyer's telephone number for other purposes (e.g. completing a
                transaction off Tradesbarn or adding the buyer to a marketing list).
      </p>
            <p>
                Your responsibilities over transactional information you receive through
                Tradesbarn
      </p>
            <p>
                When you complete a transaction with another user (or a transaction has
                been cancelled, failed, or subsequently invalidated), we will provide you
                with the other user's personal data (such as name, username, email address,
                contact information, shipping and billing information, or return address).
                Independent from us, you are the controller of such data and responsible
                for any processing that you perform after we have shared this data with
                you, including compliance with any limitations imposed by this User Privacy
                Notice and our User Agreement.
      </p>
            <p>
                Unless you act for purely personal purposes, we recommend that you explain
                your data processing activities in your own privacy notice and protect the
                privacy of other users. As a seller, you must in any case comply with the
                applicable data protection laws and in particular protect the rights of
                other users as data subjects, e.g. give them the opportunity to access the
                personal data collected by you and demand that it be erased.
      </p>
            <p>
                You may only use the personal data that you have access to for Tradesbarn
                transaction-related purposes, or for other Services offered through
                Tradesbarn (such as shipping, fraud complaints, and member-to-member
                communications), and for purposes expressly consented by the user to whom
                the data relates. Using personal data of other users that you have access
                to for any other purpose, such as adding them to a mailing list without
                their express consent, constitutes a violation of our User Agreement.
      </p>
            <p>
                Personal data relating to third parties
      </p>
            <p>
                If you provide us with personal data relating to another person, you must
                obtain the consent of this person or the disclosure of the data to us must
                be otherwise legally permissible. You must inform the other person of how
                we process personal data in accordance with our User Privacy Notice.
      </p>
            <p>
                Filtering of messages sent via our messaging tools
      </p>
            <p>
                All messages sent via our messaging tools are first received by us and then
                forwarded to the recipient. All messages are automatically filtered
                according to certain criteria. If necessary, conspicuous messages are
                checked manually by our customer service. In the event of a violation of
                our User Agreement (including any of our rules and policies), we reserve
                the right to block the transmission of the message and to restrict the
                purchase and sales functions of your Tradesbarn account or to block your
                Tradesbarn account.
      </p>
            <p>
                This is to protect our legitimate interests such as protecting against
                fraudulent or suspicious activities (e.g. spam, viruses, phishing, or other
                illegal activities) or enforcing our User Agreement and our other rules and
                policies (e.g. illegal and other prohibited content), including but not
                limited to enforcing the prohibition of purchases and sales outside of
                eBay.
      </p>
            <p>
                Are you obliged to provide your personal data to us?
      </p>
            <p>
                Some of the personal data that you provide to us (e.g. data by which we can
                identify you) are required to enter into the User Agreement and the
                Payments Terms of Use. For example, under the Payments Terms of Use you
                must provide us with certain identification information including legal
                name, date of birth, and tax identification number or social security
                number so that we may fulfil our legal "Know Your Customer" (KYC)
                obligations. As described elsewhere in our User Privacy Notice and in the
                Payments Terms of Use, we may share this data with other Tradesbarn LLC
                group companies in order to facilitate transactions and with service
                providers, including payment processors, credit agencies and bureaus.
                Although the provision of any other personal data (e.g. address and
                shipping data) is voluntary, it may be necessary for the use of our
                Services, such as bidding, purchase and sales data to complete a
                transaction.
      </p>
            <p>
                Children's Privacy
      </p>
            <p>
                Our services are not intended for the use by children. We do not knowingly
                collect personal data from users who are considered children under
                applicable national laws. Under our User Agreement, children are not
                permitted to use our Services.
      </p>
            <p>
                Staying Signed in
      </p>
            <p>
                When you sign in to your account on our Services, we give you the option to
                stay signed in to your account for certain amount of time. If you are using
                a public or shared computer, we encourage you to decline. You or any other
                user of the computer/browser you signed in on will be able to view and
                access most parts of your account and take certain specific actions during
                this signed in period without any further authorization. The specific
                actions and account activities that you or any other user of this
                computer/browser may take include:
      </p>
            <p>
                Bid, buy or make an offer on an item
      </p>
            <p>
                Check out or add items to your cart
      </p>
            <p>
                Purchase an item with PayPal using Faster Checkout (if enabled in your
                account)
      </p>
            <p>
                View the activity header
      </p>
            <p>
                View the My Tradesbarn page
      </p>
            <p>
                View or edit the Watch List or order details
      </p>
            <p>
                View the profile page
      </p>
            <p>
                Send member-to-member messages
      </p>
            <p>
                Conduct after-sale activities, like leaving feedback, canceling orders,
                requesting returns or submitting claims
      </p>
            <p>
                If you attempt to change your password or user ID, update any other account
                information or attempt other account activity beyond those listed above,
                you may be required to enter your password.
      </p>
            <p>
                You can typically end your signed in session by either signing out and/or
                clearing your cookies. If you have certain browser privacy settings
                enabled, simply closing your browser may also end your signed in session.
                If you are using a public or shared computer, you should sign out and/or
                clear your cookies when you are done using our Services to protect your
                account and your personal data.
      </p>
            <p>
                <strong>12. Regional and State Privacy Disclosures</strong>
            </p>
            <p>
                For additional regional disclosures for residents of certain U.S. states,
                including California, please review our State Privacy Disclosures page. For
                additional disclosures for specific regions or countries, including Brazil,
                please review our Privacy Disclosures page.
      </p>
        </CardBox>
    )
}
