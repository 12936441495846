import React from "react";
// import ContentLoader from "react-content-loader"
function SingleProductLoading() {
  return (
    // <div style={{width: "100%", margin:"0 16px"}}>
    //     <ContentLoader
    //         speed={2}
    //         width={1200}
    //         viewBox="0 0 800 800"
    //         backgroundColor="#f3f3f3"
    //         foregroundColor="#ecebeb"
    //         {...props}
    //     >
    //         {/* image */}
    //         <rect x="0" y="20" rx="2" ry="2" width="300" height="245" />
    //         {/* image bar */}
    //         <rect x="0" y="276" rx="2" ry="2" width="300" height="55" />

    //         {/* category */}
    //         <rect x="320" y="23" rx="0" ry="0" width="150" height="14" />
    //         {/* title */}
    //         <rect x="320" y="48" rx="0" ry="0" width="300" height="20" />

    //         {/* variations */}
    //         <rect x="320" y="90" rx="0" ry="0" width="61" height="15" />
    //         <rect x="320" y="110" rx="0" ry="0" width="152" height="30" />

    //         {/* variations */}
    //         <rect x="320" y="167" rx="0" ry="0" width="91" height="8" />
    //         <rect x="320" y="180" rx="0" ry="0" width="146" height="24" />

    //         <rect x="320" y="221" rx="0" ry="0" width="65" height="26" />
    //         <rect x="320" y="298" rx="0" ry="0" width="51" height="29" />
    //         <rect x="320" y="297" rx="0" ry="0" width="52" height="29" />
    //         <rect x="573" y="300" rx="0" ry="0" width="33" height="25" />
    //         <rect x="320" y="261" rx="0" ry="0" width="165" height="26" />

    //         <circle cx="446" cy="235" r="13" />
    //         <circle cx="559" cy="232" r="14" />

    //         {/* delivery options */}
    //         <rect x="630" y="20" rx="2" ry="2" width="260" height="300" />

    //         {/* Seller Section */}
    //         <rect x="0" y="371" rx="0" ry="0" width="153" height="165" />
    //         <rect x="0" y="566" rx="0" ry="0" width="138" height="20" />
    //         <rect x="0" y="599" rx="0" ry="0" width="134" height="19" />

    //         {/* Description section */}
    //         <rect x="201" y="366" rx="0" ry="0" width="690" height="249" />
    //         <rect x="201" y="645" rx="0" ry="0" width="690" height="264" />

    //         {/* related products */}
    //         <rect x="0" y="940" rx="0" ry="0" width="890" height="162" />

    //     </ContentLoader>
    // </div>
    <div className="tr-wishlist-notify-container" style={{ margin: "200px 0" }}>
      {/* <div className="tr-address-loader"> </div> */}
      <div className="tr-wishlist-notify-loading-spinner"> </div>
    </div>
  );
}

export default SingleProductLoading;
