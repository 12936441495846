import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Select, Input } from "antd";
import { makeField } from "components/Common/Controls/MakeField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class Address extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = (values) => {
    
  };

  render() {
    const { handleSubmit } = this.props;

    const formItemLayout = {
      labelCol: { xs: 24, sm: 6 },
      wrapperCol: { xs: 24, sm: 14 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };

    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          {/* <h5>Warehouse Address</h5> */}
          <FormItem {...formItemLayout} label="Address Line 1">
            <Field
              formItemLayout={formItemLayout}
              // label="First Name"
              name="address1"
              component={AInputField}
              placeholder="Address Line 1"
              hasFeedback
            />
          </FormItem>

          <FormItem {...formItemLayout} label="Address Line 2">
            <Field
              formItemLayout={formItemLayout}
              // label="First Name"
              name="address2"
              component={AInputField}
              placeholder="Address Line 2"
              hasFeedback
            />
          </FormItem>

          <FormItem {...formItemLayout} label="Country / Region">
            <Field
              formItemLayout={formItemLayout}
              name="country"
              component={ASelectField}
              hasFeedback
            >
              <Option value="LKA">Sri Lanka</Option>
              <Option value="uk">UK</Option>
              <Option value="USA">USA</Option>
              <Option value="canada">Canada</Option>
              <Option value="india">India</Option>
            </Field>
          </FormItem>

          <FormItem {...formItemLayout} label="Region">
            <Field
              formItemLayout={formItemLayout}
              name="region"
              component={ASelectField}
              hasFeedback
            >
              <Option value="LKA">Sri Lanka</Option>
              <Option value="uk">UK</Option>
              <Option value="USA">USA</Option>
              <Option value="canada">Canada</Option>
              <Option value="india">India</Option>
            </Field>
          </FormItem>

          <FormItem {...formItemLayout} label="Postcode">
            <Field
              formItemLayout={formItemLayout}
              // label="First Name"
              name="postcode"
              component={AInputField}
              placeholder="Postcode"
              hasFeedback
            />
          </FormItem>

          <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "10px", marginBottom: "0" }}
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.nameid) {
    errors.nameid = "Name Id is required";
  }
  if (!values.email) {
    errors.email = "Email Address is required";
  }
  if (!values.phonenumber) {
    errors.phonenumber = "Phone Number is required";
  }
  if (!values.displayname) {
    errors.displayname = "Display Name / Shop Name is required";
  }

  return errors;
};

function mapStateToProps(state) {
  return null;
}
function mapDispatchToProps(dispatch) {
  return null;
}
export default withRouter(
  reduxForm({
    form: "Address",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(Address))
);
