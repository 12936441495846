import React, { useState } from "react";
import { Col, Row, Tabs } from "antd";
import { withRouter } from "react-router-dom";
import OrderItem from "./OrderItem";
import "./OrderSummery.less";

const TabPane = Tabs.TabPane;

const OrderSummery = ({
  task_list,
  newOrders,
  shippedOrders,
  deliveredOrders,
  cencelOrders,
  history,
}) => {
  const [taskList, setTaskList] = useState(task_list);

  const onChange = (data) => {
    setTaskList(
      taskList.map((task) => {
        if (task.id === data.id) {
          task.completed = !data.completed;
        }
        return task;
      })
    );
  };

  return (
    <div className="gx-card" style={{ padding: "24px" }}>
      <div className="mb-1">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <h6 className="gx-text-capitalize gx-mb-0">Order List</h6>
              </td>
              <td style={{ textAlign: "end" }}>
                <div
                  style={{ fontSize: "13px" }}
                  onClick={() =>
                    history.push("/seller/dashboard/orderManagement/all")
                  }
                >
                  <span className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
                    More Details<i className="fad fa-external-link ml-1"></i>
                    {/* <i className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle" /> */}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="jrTabSelect">
        <Tabs defaultActiveKey="2">
          {/* <TabPane tab="All Order" key="1">
          {newOrders.map((task, index) => (
            <OrderItem key={index} data={task} onChange={onChange} />
          ))}
        </TabPane> */}
          <TabPane tab="New Orders" key="2">
            <Row>
              {newOrders.length > 0 ? (
                newOrders.map((task, index) => (
                  <OrderItem
                    key={"2" + index}
                    data={task}
                    onChange={onChange}
                  />
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "30px",
                    }}
                  >
                    No orders yet
                  </div>
                </Col>
              )}
            </Row>
          </TabPane>
          <TabPane tab="Shipping Orders" key="3">
            <Row>
              {shippedOrders.length > 0 ? (
                shippedOrders.map((task, index) => (
                  <OrderItem
                    key={"3" + index}
                    data={task}
                    onChange={onChange}
                  />
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "30px",
                    }}
                  >
                    No orders yet
                  </div>
                </Col>
              )}
            </Row>
          </TabPane>
          <TabPane tab="Delivered Orders" key="4">
            <Row>
              {deliveredOrders.length > 0 ? (
                deliveredOrders.map((task, index) => (
                  <OrderItem
                    key={"4" + index}
                    data={task}
                    onChange={onChange}
                  />
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "30px",
                    }}
                  >
                    No orders yet
                  </div>
                </Col>
              )}
            </Row>
          </TabPane>
          <TabPane tab="Cancelled Orders" key="5">
            <Row>
              {cencelOrders.length > 0 ? (
                cencelOrders.map((task, index) => (
                  <OrderItem
                    key={"5" + index}
                    data={task}
                    onChange={onChange}
                  />
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      padding: "30px",
                    }}
                  >
                    No orders yet
                  </div>
                </Col>
              )}
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default withRouter(OrderSummery);
