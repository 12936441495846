import React from "react";
import Widget from "../../Card/Widget/Widget";

const service = [
  {
    id: 1,
    icon: "icon-rocket",
    title: "Quality Product",
    subtitle: "Made in Sri Lanka",
  },
  {
    id: 2,
    icon: "icon-rotate-left",
    title: "Get Free Store",
    subtitle: "When you sign up",
  },
  {
    id: 3,
    icon: "icon-info-circle",
    title: "Gifting Store",
    subtitle: "Create happy memory ",
  },
  {
    id: 4,
    icon: "icon-life-ring",
    title: "We Support",
    subtitle: "24/7 amazing services",
  },
];

function PlatformFeatures() {
  return (
    <Widget styleName="c-full bck-clr-nw-jr card-bdy-holder">
      <div className="gx-container row">
        {service.map((item, index) => (
          <div className="col-sm-6 col-lg-3" key={index}>
            <div className="icon-box icon-box-side">
              <span className={`icon-box-icon`}>
                <i className={item.icon}></i>
              </span>
              <div className="icon-box-content">
                <h3 className="icon-box-title">{item.title}</h3>
                <p>{item.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Widget>
  );
}

export default PlatformFeatures;
