import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import { Row, Col } from "antd";
import OrderItem from "./OrderItem";

class OrderReview extends Component {
  render() {
    const { checkoutItems } = this.props;

    return (
      <div className="gx-wall-content">
        <h5 className="ml-2 mb-3 mt-4">Order Review</h5>

        <Row>
          <Col span={24}>
            {checkoutItems &&
              checkoutItems.items &&
              checkoutItems.items.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="gx-card p-3 mb-2"
                    style={{ overflow: "hidden" }}
                  >
                    <OrderItem
                      index={index}
                      data={data}
                      styleName="gx-card-strip"
                    />
                  </div>
                );
              })}
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    checkoutItems: state.Cart.checkoutItems,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderReview)
);
