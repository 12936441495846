import React, { useState } from "react";
import { Col, Row } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { latestProducts } from "../CarouselData";
import LatestProductCardContainer from "../../Card/LatestProductCard/LatestProductCardContainer";
import Widget from "../../Card/Widget/Widget";
import "./LatestProductCarousel.less";

const LatestProductCarousel = ({ data }) => {
  const [image, setImage] = useState(latestProducts[0].image);
  const [loading, setLoading] = useState(false);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    marginLeft: 10,
    marginRight: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    autoplay: true,
    otsClass: "slick-dots",
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "rgb(236, 236, 236)",
          borderRadius: "10px",
          padding: "10px",
          position: "unset",
        }}
      >
        <ul className="tb-append-dot-ul txt-cnt-jr p-0">{dots}</ul>
      </div>
    ),
    customPaging: (i) => {
      return <a href="/#">{!data.loading && data?.data[i]?.name}</a>;
    },
  };
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Widget styleName="c-full bck-clr-nw-jr card-bdy-holder">
              <Row>
                <Col
                  xl={6}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={24}
                  className="mgn-botm-jr"
                >
                  <div className="gx-slick-slider-lt-thumb">
                    <img
                      className={
                        loading
                          ? "fadeout gx-img-fluid gx-card"
                          : "fadein gx-img-fluid gx-card"
                      }
                      src={image}
                      alt="..."
                    />
                  </div>
                </Col>
                <Col xl={18} lg={12} md={12} sm={12} xs={24}>
                  <Slider
                    className="gx-slick-slider slider tb-slider-padding"
                    {...settings}
                    afterChange={(index) => {
                      setLoading(false);
                      if (latestProducts[index])
                        setImage(latestProducts[index].image);
                    }}
                    beforeChange={(oldIndex, newIndex) => {
                      setLoading(true);
                    }}
                  >
                    {data.loading
                      ? latestProducts.map((data, index) => (
                          <LatestProductCardContainer
                            key={index}
                            data={data}
                            isLoading={true}
                          />
                        ))
                      : data.data.map((data, index) => (
                          <LatestProductCardContainer
                            key={index}
                            data={data}
                            isLoading={false}
                          />
                        ))}
                  </Slider>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LatestProductCarousel;
