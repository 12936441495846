import React, { useState } from "react";
import { Layout } from "antd";
import qs from "qs";
import {
  Configure,
  connectHits,
  connectStateResults,
  InstantSearch,
  Pagination,
  Stats,
} from "react-instantsearch-dom";
import { withUrlSync } from "../urlSync/urlSync";
import "instantsearch.css/themes/algolia.css";
import ProductsHeader from "../ProductsHeader/ProductsHeader";
import Sidebar from "../ProductsSideBar/ProductsSideBar";
import ProductList from "../ProductsList/ProductsList";
import "./AllProductsContainer.less";
import {
  ALGOLIA_PRODUCTS_INDEX,
  ALGOLIA_PRODUCTS_APIKEY,
} from "./../../../../constants";
import AlgoliaFooter from "../../../Common/AlgoliaFooter/AlgoliaFooter";
import { initAlgoliaClient } from "../../../../services/algolia.service";
const { Content } = Layout;

const searchClient = initAlgoliaClient(ALGOLIA_PRODUCTS_APIKEY);

const getCategorySlug = (name) =>
  name.split(" ").map(encodeURIComponent).join("+");

const getCategoryName = (slug) =>
  slug.split("+").map(decodeURIComponent).join(" ");

const createSearchURL = (state) => {
  // debugger;
  const isDefaultRoute =
    !state.query &&
    state.page === 1 &&
    state.hierarchicalMenu &&
    !state.hierarchicalMenu["hierarchicalCategories.lvl0"];
  if (isDefaultRoute) return "";

  const categoryPath = state.hierarchicalMenu["hierarchicalCategories.lvl0"]
    ? `${getCategorySlug(
        state.hierarchicalMenu["hierarchicalCategories.lvl0"]
      )}/`
    : "";
  const queryParameters = {};

  if (state.query) queryParameters.query = encodeURIComponent(state.query);
  if (state.page !== 1) queryParameters.page = state.page;

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  });

  const [categorylvl0, categorylvl1, categorylvl2] =
    categoryPath.split("+%3E+");
  if (categorylvl2)
    return `/search/${categorylvl0}/${categorylvl1}/${categorylvl2}${queryString}`;
  if (categorylvl1)
    return `/search/${categorylvl0}/${categorylvl1}${queryString}`;
  return `/search/${categorylvl0}${queryString}`;
};

const searchStateToUrl = (searchState) =>
  searchState ? createSearchURL(searchState) : "";

const urlToSearchState = (location) => {
  const pathnameMatches = location.pathname.match(/search\/(.*?)\/?$/);
  const [categorylvl0, categorylvl1, categorylvl2] = getCategoryName(
    (pathnameMatches && pathnameMatches[1]) || ""
  ).split("/");

  const hierarchicalMenu = {};
  if (categorylvl2) {
    hierarchicalMenu[
      "hierarchicalCategories.lvl2"
    ] = `${categorylvl0} > ${categorylvl1} > ${categorylvl2}`;
    hierarchicalMenu["hierarchicalCategories.lvl1"] =
      hierarchicalMenu["hierarchicalCategories.lvl2"];
    hierarchicalMenu["hierarchicalCategories.lvl0"] =
      hierarchicalMenu["hierarchicalCategories.lvl2"];
  } else if (categorylvl1) {
    hierarchicalMenu[
      "hierarchicalCategories.lvl1"
    ] = `${categorylvl0} > ${categorylvl1}`;
    hierarchicalMenu["hierarchicalCategories.lvl0"] =
      hierarchicalMenu["hierarchicalCategories.lvl1"];
  } else if (categorylvl0) {
    hierarchicalMenu["hierarchicalCategories.lvl0"] = categorylvl0;
  }

  const { query = "", page = 1 } = qs.parse(location.search.slice(1));
  return {
    query: decodeURIComponent(query),
    page,
    hierarchicalMenu,
  };
};

const Products = (props) => {
  const DEBOUNCE_TIME = 400;
  const { location } = props;
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  // useEffect(() => {
  //   onSearchStateChange(urlToSearchState(location));
  // }, [location]); // need to be check ************************

  const onSearchStateChange = (updatedSearchState) => {
    // debugger;
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        window.history.pushState(
          updatedSearchState,
          null,
          searchStateToUrl(updatedSearchState)
        );
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  return (
    <InstantSearch
      className="gx-main-content"
      indexName={ALGOLIA_PRODUCTS_INDEX}
      searchState={searchState}
      createURL={createSearchURL}
      searchClient={searchClient}
      onSearchStateChange={onSearchStateChange}
    >
      <Configure hitsPerPage={24} />

      <Layout className="gx-algolia-layout-has-sider">
        <Sidebar />
        <div className="gx-algolia-main">
          <div
            className="gx-card p-4 mb-0"
            style={{ backgroundColor: "rgb(252, 252, 252)" }}
          >
            <ProductsHeader />
            <Content className="gx-algolia-content">
              <CustomResults />
            </Content>
            <AlgoliaFooter>
              <Pagination showLast={true} />
            </AlgoliaFooter>
          </div>
        </div>
      </Layout>
    </InstantSearch>
  );
};

const CustomResults = connectStateResults(({ searchState, searchResults }) => {
  if (searchResults && searchResults.nbHits === 0) {
    return (
      <div className="gx-algolia-content-inner">
        <div className="gx-algolia-no-results">
          No results found matching{" "}
          <span className="gx-algolia-query">{searchState.query}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="gx-algolia-content-inner">
        <Stats />
        <ConnectedProducts />
      </div>
    );
  }
});

const ConnectedProducts = connectHits(ProductList);

export default withUrlSync(Products);
