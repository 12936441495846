import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";
import _ from "lodash";

const initialState = {
  orderAddress: {
    items: [
      {
        id: "c14c7f80-a0f1-11ea-9736-a90d7e549030",
        city: "Colombo",
        phone: "0774123112",
        country: "LKA",
        address1: "26, ABC Road",
        address2: "Pannipitiya",
        zip: "10230",
        last_name: "Rathnayaka",
        first_name: "Bhanuka",
        state: "WP",
        created_at: "2020-05-28T14:44:37.880Z",
        modified_at: "2020-05-28T14:44:37.880Z",
      },
      {
        id: "222-0221-741233-21212",
        first_name: "Bhanuka",
        last_name: "Rathnayaka",
        zip: "10230",
        city: "Colombo",
        phone: "+94771212121",
        state: "WP",
        address1: "76, ABC Road",
        address2: "Pannipitiya",
        country: "LKA",
        created_at: "2020-05-28T14:44:37.880Z",
        modified_at: "2020-05-28T14:44:37.880Z",
      },
    ],
  },
  profileDetails: {
    ...initialStateModal,
  },
  addShipingAddress: {
    ...initialStateModal,
  },
  orderReview: {
    ...initialStateModal,
  },
  shippingAddress: {
    ...initialStateModal,
  },
  updateShippingAddress: {
    ...initialStateModal,
  },
  deleteShippingAddress: {
    ...initialStateModal,
  },
  selectedShippingAdress: {
    data: {
      address1: null,
      address2: null,
      city: null,
      country: null,
      created_at: null,
      first_name: null,
      id: null,
      last_name: null,
      modified_at: null,
      phone: null,
      state: null,
      zip: null,
      deault_address: false,
    },
  },
  buyerOrderCount: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    //------------------ GET ADDRESS ---------------------
    [types.GET_SHIPPING_ADDRESS]: (state, { payload }) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SHIPPING_ADDRESS_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.GET_SHIPPING_ADDRESS_FAILED]: (state, { payload }) => ({
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    //------------------ ADD SHIPPING ADDRESS ---------------------
    [types.ADD_SHIPPING_ADDRESS]: (state, { payload }) => {
      return {
        ...state,
        addShipingAddress: {
          ...state.addShipingAddress,
          loading: true,
          pending: true,
        },
      };
    },
    [types.ADD_SHIPPING_ADDRESS_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      var source =
        state.shippingAddress.data &&
        state.shippingAddress.data.data &&
        state.shippingAddress.data.data.shipping_addresses;

      // source.push(data);
      source = [data].concat(source);
      return {
        ...state,
        addShipingAddress: {
          ...state.addShipingAddress,
          loading: false,
          pending: false,
          data: payload,
        },

        shippingAddress: {
          data: {
            ...state.shippingAddress.data,
            data: {
              ...state.shippingAddress.data.data,
              shipping_addresses: [...source],
            },
          },
        },
      };
    },
    [types.ADD_SHIPPING_ADDRESS_FAILED]: (state, { payload }) => ({
      ...state,
      addShipingAddress: {
        ...state.addShipingAddress,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    //------------------ UPDATE SHIPPING ADDRESS ---------------------
    [types.UPDATE_SHIPPING_ADDRESS]: (state, { payload }) => ({
      ...state,
      updateShippingAddress: {
        ...state.updateShippingAddress,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_SHIPPING_ADDRESS_SUCCESS]: (state, { payload }) => {
      const { data, updatedData, updatedID } = payload;
      const isDefaultShippingAddress =
        updatedData && updatedData.default_address;
      if (isDefaultShippingAddress) {
      }
      let prevDefaultShippingAddressId =
        state.shippingAddress.data &&
        state.shippingAddress.data.data &&
        state.shippingAddress.data.data.default_shipping_address_id;
      var source =
        state.shippingAddress.data &&
        state.shippingAddress.data.data &&
        state.shippingAddress.data.data.shipping_addresses;
      //find the updated index
      var updatedIndex = source.findIndex((x) => x.id === updatedID);
      source.splice(updatedIndex, 1, updatedData);
      return {
        ...state,
        updateShippingAddress: {
          ...state.updateShippingAddress,
          loading: false,
          pending: false,
          data: payload,
        },
        shippingAddress: {
          data: {
            ...state.shippingAddress.data,
            data: {
              ...data.data,
              default_shipping_address_id: isDefaultShippingAddress
                ? updatedID
                : prevDefaultShippingAddressId,
            },
          },
        },
      };
    },
    [types.UPDATE_SHIPPING_ADDRESS_FAILED]: (state, { payload }) => ({
      ...state,
      updateShippingAddress: {
        ...state.updateShippingAddress,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    //------------------ DELETE SHIPPING ADDRESS ---------------------
    [types.DELETE_SHIPPING_ADDRESS]: (state, { payload }) => ({
      ...state,
      deleteShippingAddress: {
        ...state.deleteShippingAddress,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_SHIPPING_ADDRESS_SUCCESS]: (state, { payload }) => {
      const { updatedID } = payload;
      var source =
        state.shippingAddress.data &&
        state.shippingAddress.data.data &&
        state.shippingAddress.data.data.shipping_addresses;
      // lodash.js
      source = _.reject(source, function (el) {
        return el.id === updatedID;
      });
      // source.splice(updatedIndex, 1, updatedData);
      return {
        ...state,
        deleteShippingAddress: {
          ...state.deleteShippingAddress,
          loading: false,
          pending: false,
          data: payload,
        },
        shippingAddress: {
          data: {
            ...state.shippingAddress.data,
            data: {
              ...state.shippingAddress.data.data,
              shipping_addresses: [...source],
            },
          },
        },
      };
    },
    [types.DELETE_SHIPPING_ADDRESS_FAILED]: (state, { payload }) => ({
      ...state,
      deleteShippingAddress: {
        ...state.deleteShippingAddress,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    [types.ADD_ORDER_ADDRESS]: (state, { payload }) => {
      // getter
      var orderAddressObj = state.orderAddress.items;
      if (orderAddressObj) {
        orderAddressObj.push(payload);
        return {
          ...state,
          orderAddress: {
            items: orderAddressObj,
          },
        };
      } else {
        var orderAddress_Obj = [];
        orderAddress_Obj.push(payload);
        return {
          ...state,
          orderAddress: {
            items: orderAddress_Obj,
          },
        };
      }
    },
    [types.REMOVE_ORDER_ADDRESS]: (state, { payload }) => {
      // getter
      var orderAddressObj = state.orderAddress.items;
      // var removeObj = _.remove(orderAddressObj, function (n) {
      //   return (n = _.find(orderAddressObj, { id: payload.id }));
      // });
      if (payload) {
        var findIndex = _.findIndex(orderAddressObj, { id: payload.id });
        _.pullAt(orderAddressObj, findIndex);
      }

      return {
        ...state,
        orderAddress: {
          items: orderAddressObj,
        },
      };
    },
    [types.EDIT_ORDER_ADDRESS]: (state, { payload }) => {
      // getter
      var orderAddressObj = state.orderAddress.items;
      var findIndex = _.findIndex(orderAddressObj, { id: payload.id });

      // _.remove(orderAddressObj, {
      //   objectID: payload.item.objectID,
      // });\
      orderAddressObj[findIndex] = payload;
      return {
        ...state,
        orderAddress: {
          items: orderAddressObj,
        },
      };
    },

    //------------------ GET PROFILE ---------------------
    [types.GET_PROFILE_DETAILS]: (state, { payload }) => ({
      ...state,
      profileDetails: {
        ...state.profileDetails,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_PROFILE_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      profileDetails: {
        ...state.profileDetails,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_PROFILE_DETAILS_FAILED]: (state, { payload }) => ({
      ...state,
      profileDetails: {
        ...state.profileDetails,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------
    [types.ADD_ORDER_REVIEW]: (state, { payload }) => {
      return {
        ...state,
        orderReview: {
          ...state.orderReview,
          loading: true,
          pending: true,
        },
      };
    },
    [types.ADD_ORDER_REVIEW_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        orderReview: {
          ...state.orderReview,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    [types.ADD_ORDER_REVIEWSS_FAILED]: (state, { payload }) => {
      const data = payload;
      return {
        ...state,
        orderReview: {
          ...state.orderReview,
          loading: false,
          pending: false,
          hasError: true,
          error: { data },
        },
      };
    },

    //------------------ GET_BUYER_ORDER_COUNT---------------------
    [types.GET_BUYER_ORDER_COUNT]: (state, { payload }) => ({
      ...state,
      buyerOrderCount: {
        ...state.buyerOrderCount,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_BUYER_ORDER_COUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      buyerOrderCount: {
        ...state.buyerOrderCount,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_BUYER_ORDER_COUNT_FAILED]: (state, { payload }) => ({
      ...state,
      buyerOrderCount: {
        ...state.buyerOrderCount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------
    //------------------SELECTED SHIPPING ADDRESS ---------------------
    [types.SELECTED_SHIPPING_ADDRESS_HANDLER]: (state, { payload }) => {
      let defaultShippingAddressId =
        (state.shippingAddress &&
          state.shippingAddress.data &&
          state.shippingAddress.data.data &&
          state.shippingAddress.data.data.default_shipping_address_id) ||
        null;

      return {
        ...state,
        selectedShippingAdress: {
          ...state.selectedShippingAdress,
          data: {
            ...payload,
            is_default_address: payload.id === defaultShippingAddressId,
          },
        },
      };
    },
    //------------------ ---------------- ---------------------
  },
  initialState
);
