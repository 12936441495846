import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import ReportContainer from "../../../components/Seller/ReportManagement/ReportContainer";
import { toTitleCase } from "../../../util/services";
import { ReportManagementActions } from "./ducks";

class ReportManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
      loading: true,
      firstDate: null,
      secondDate: null,
      setStartWeek: false,
      setEndWeek: false,
      weekStartString: null,
      weekEndString: null,
      monthStartString: null,
      monthEndString: null,
      setMonths: false,
      setYears: false,
      yearStartString: null,
      yearEndString: null,
    };
  }
  componentDidMount() {
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    this.setState({
      algolia,
    });
  }

  componentWillUnmount() {
    this.setState({
      algolia: null,
      loading: true,
      firstDate: null,
      secondDate: null,
      setStartWeek: false,
      setEndWeek: false,
      weekStartString: null,
      weekEndString: null,
      monthStartString: null,
      monthEndString: null,
      setMonths: false,
      setYears: false,
      yearStartString: null,
      yearEndString: null,
    });
  }
  onChange = (date, dateString) => {
    this.setState({
      loading: false,
      firstDate: dateString[0],
      secondDate: dateString[1],
      // value: dateString
    });
  };
  handlePanelChange = (value, mode) => {
    this.setState({
      // value,
      mode: [
        mode[0] === "date" ? "month" : mode[0],
        mode[1] === "date" ? "month" : mode[1],
      ],
    });
  };

  onChangeWeekStart = (date, dateString) => {
    this.setState({
      weekStart: date,
      weekStartString: dateString,
      setStartWeek: true,
    });
  };

  onChangeWeekEnd = (date, dateString) => {
    this.setState({
      weekEnd: date,
      weekEndString: dateString,
      setEndWeek: true,
    });
  };

  handlePanelChangeMonths = (value, mode) => {
    this.setState({
      value,
      monthStartString: moment(value[0]).format("YYYY-MM"),
      monthEndString: moment(value[1]).format("YYYY-MM"),
      setMonths: true,
    });
  };

  handlePanelChangeYears = (value, mode) => {
    this.setState({
      value,
      yearStartString: moment(value[0]).format("YYYY"),
      yearEndString: moment(value[1]).format("YYYY"),
      setYears: true,
    });
  };

  render() {
    const { match } = this.props;
    const { algolia } = this.state;
    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    // var mode = ["date", "date"];
    // var placeholder = ["Start date", "End date"];
    // var format = "YYYY-MM-DD";

    if (algolia) {
      switch (type) {
        case "daily":
          apiKey = algolia.daily_reports.api_key;
          index = algolia.daily_reports.index;
          // mode = ["date", "date"];
          // placeholder = ["Start date", "End date"];
          // format = "YYYY-MM-DD";
          break;
        case "weekly":
          apiKey = algolia.weekly_reports.api_key;
          index = algolia.weekly_reports.index;
          // mode = ["date", "date"];
          // placeholder = ["Start date", "End date"];
          // format = "YYYY-MM-DD";
          break;
        case "monthly":
          apiKey = algolia.monthly_reports.api_key;
          index = algolia.monthly_reports.index;
          // mode = ["month", "month"];
          // placeholder = ["Start month", "End month"];
          // format = "YYYY-MM";
          break;
        case "quarterly":
          apiKey = algolia.quartely_reports.api_key;
          index = algolia.quartely_reports.index;
          // mode = ["date", "date"];
          // placeholder = ["Start date", "End date"];
          // format = "YYYY-MM-DD";
          break;
        case "yearly":
          apiKey = algolia.yearly_reports.api_key;
          index = algolia.yearly_reports.index;
          // mode = ["year", "year"];
          // placeholder = ["Start year", "End year"];
          // format = "YYYY";
          break;
        default:
          apiKey = algolia.daily_reports.api_key;
          index = algolia.daily_reports.index;
        // mode = ["date", "date"];
        // placeholder = ["Start date", "End date"];
        // format = "YYYY-MM-DD";
      }
      if (type === "daily") {
        return (
          <>
            <h3 className="mb-4">{toTitleCase(type) + " Report"}</h3>
            <ReportContainer
              type={toTitleCase(type)}
              apiKey={apiKey}
              index={index}
            />
          </>
        );
      } else if (type === "weekly") {
        return (
          <>
            <h3 className="mb-4">{toTitleCase(type) + " Report"}</h3>
            <ReportContainer
              type={toTitleCase(type)}
              apiKey={apiKey}
              index={index}
            />
          </>
        );
      } else if (type === "monthly") {
        return (
          <>
            <h3 className="mb-4">{toTitleCase(type) + " Report"}</h3>
            <ReportContainer
              type={toTitleCase(type)}
              apiKey={apiKey}
              index={index}
            />
          </>
        );
      } else if (type === "yearly") {
        return (
          <>
            <h3 className="mb-4">{toTitleCase(type) + " Report"}</h3>
            <ReportContainer
              type={toTitleCase(type)}
              apiKey={apiKey}
              index={index}
            />
          </>
        );
      } else {
        this.props.history.push("/not-fount-page");
        return <div>Page 404</div>;
      }
    } else {
      return <div>DailyReport</div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    exportDetail: state.ReportManagement.exportDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ReportManagementActions: bindActionCreators(
      ReportManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportManagement)
);
