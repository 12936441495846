import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class CompanyInforAndPolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Company Infor And Policy" />
          <CSBreadcrumb label={"Company Infor And Policy"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <h6> Introduction</h6>
            <br />
            <p>
              This User Agreement, the Tradesbarn User Privacy Notice, the
              Mobile Device Terms and all policies posted on our sites set out
              the terms on which Tradesbarn offers you access to and use of our
              sites, services, applications and tools (collectively ‘Services’).
              You can find an overview of our policies here. All policies, the
              Mobile Device Terms and the tradesbarn User Privacy Notice are
              incorporated into this User Agreement. You agree to comply with
              all the above when accessing or using our Services. <br />
              <br />
              <ul>
                <li>
                  If you reside In USA Or Internationally, you are entering into
                  a contract withTradesbarn LLC 3660 E New York Street #1512
                  Aurora Illinois 60504.
                </li>
              </ul>
            </p>
          </div>
          <br />

          <div>
            <h6> About Tradesbarn</h6>
            <br />
            <p>
              Tradesbarn LLC Registered in United States of America Baring the
              Registration Number 1821801101.
              <br />
              <br />
              Tradesbarn is a marketplace that allows users to offer, sell and
              buy just about anything in a variety of pricing formats and
              locations. B2B, B2C & Services.
              <br />
              <br />
              Tradesbarn does not have possession of anything listed or sold
              through Tradesbarn, and is not involved in the actual transaction
              between buyers and sellers. The contract for the sale is directly
              between buyer and seller. Tradesbarn is not a traditional
              auctioneer. <br />
              <br />
              While we may provide pricing, postage, drafting and other guidance
              in our Services, such guidance is solely informational and you may
              decide to follow it or not. Tradesbarn does not review users'
              listings or content. While we may help facilitate the resolution
              of disputes through various programs, Tradesbarn has no control
              over and does not guarantee the existence, quality, safety or
              legality of items advertised; the truth or accuracy of users’
              content, listings or feedback; the ability of sellers to sell
              items; the ability of buyers to pay for items; or that a buyer or
              seller will actually complete a transaction or return an item.
              <br />
              <br />
              Under the Tradesbarn Money Back Guarantee, if we resolve a case in
              the buyer's favor, we may: <br />
              <br />
              1. Remove funds from the seller's Transaction Account Or Credit
              Card Linked account to reimburse the buyer for the cost of the
              item and the original postage cost in accordance with the seller's
              authorization provided to us under this User Agreement; or <br />
              <br />
              2. Where there are insufficient funds in the seller's Transaction
              Account or Credit Card Linked account, directly refund the buyer
              for the cost of the item and the original postage cost, and, in
              this case, the seller must reimburse us in full, and in a timely
              manner, for an amount equivalent to the sum we paid to the buyer.
              Sellers must have a payment method on file with Tradesbarn at all
              times. Sellers may change this payment method by contacting
              Tradesbarn. Where the seller is found at fault, we will notify the
              seller and charge the method of payment that the seller has on
              file.
            </p>
            <br />
          </div>

          <div>
            <h6>Using Tradesbarn</h6>
            <br />
            <p>
              In connection with using or accessing the Services you will not:
            </p>
            <br />

            <ul>
              <li>
                Post, list or upload content or items in inappropriate
                categories or areas on our sites;
              </li>
              <li>
                Breach or circumvent any laws, third party rights or our systems
                or policies;
              </li>
              <li>
                Sell any counterfeit items or otherwise infringe the copyright,
                trademark or other rights of third parties;
              </li>
              <li>
                Use our Services if you are not able to form legally binding
                contracts (for example if you are under 18), or are temporarily
                or indefinitely suspended from using our Services;
              </li>
              <li>
                Fail to pay for items purchased by you, unless you have a valid
                reason as set out in an Tradesbarn policy the seller has
                materially changed the item's description after you bid, a clear
                typographical error is made, or you cannot contact the seller
                (see our unpaid item policy);
              </li>

              <li>
                Fail to deliver items sold by you, unless you have a valid
                reason as set out in an Tradesbarn policy, for example the buyer
                fails to follow the posted terms, or you cannot contact the
                buyer;
              </li>
              <li>
                Manipulate the price of any item or interfere with any other
                user's listings;
              </li>
              <li>
                Post false, inaccurate, misleading, defamatory, or libelous
                content;
              </li>
              <li>
                Take any action that may undermine the Feedback or ratings
                systems (please see our rules about Feedback);
              </li>
              <li>
                Transfer your Tradesbarn account (including Feedback) and user
                ID to another party without our consent;
              </li>

              <li>
                Distribute or post spam, unsolicited or bulk electronic
                communications, chain letters, or pyramid schemes;
              </li>
              <li>
                Distribute viruses or any other technologies that may harm
                Tradesbarn, or the interests or property of Tradesbarn users;
              </li>
              <li>
                Use any robot, spider, scraper or other automated means to
                access our Services for any purpose.
              </li>
              <li>
                Bypass our robot exclusion headers, interfere with the working
                of our Services, or impose an unreasonable or disproportionately
                large load on our infrastructure;
              </li>
              <li>
                Export or re-export any Tradesbarn application or tools except
                in compliance with the export control laws of any relevant
                jurisdictions and in accordance with posted rules and
                restrictions;
              </li>

              <li>
                Copy, modify, or distribute rights or content from our Services
                or Tradesbarn’s copyrights and trademarks;
              </li>
              <li>
                Copy, reproduce, reverse engineer, modify, create derivative
                works from, distribute, or publicly display any content (except
                for your information) from our Services without the prior
                express written permission of Tradesbarn and the appropriate
                third party, as applicable;
              </li>
              <li>
                Commercialize any Tradesbarn application or any information or
                software associated with such application;
              </li>
              <li>
                Harvest or otherwise collect information about users, such as
                email addresses, without their consent; or
              </li>
              <li>
                Circumvent any technical measures we use to provide the
                Services.
              </li>
            </ul>
            <br />
            <p>
              If you are registering with Tradesbarn as a business entity, you
              represent that you have the authority to legally bind that entity.
              If you are trading as a business on Tradesbarn, you must comply
              with all applicable laws relating to online trading for the site
              you are selling on (please see Listings, Items and the Law for
              more information on the legal requirements for selling.
            </p>
            <br />

            <p>
              You agree that we will commence supplying our services to you as
              soon as you accept this User Agreement. You can cancel this User
              Agreement under the Consumer Contracts (Information, Cancellation
              and Additional Charges) Regulations. We may recoup the cost of any
              services provided up to the point of cancellation.
            </p>
            <br />

            <p>
              We may cancel unconfirmed accounts or accounts that have been
              inactive for a long time or to modify or discontinue Tradesbarn
              Services.
            </p>
          </div>
        </Widget>
      </div>
    );
  }
}

export default CompanyInforAndPolicy;
