import { Badge } from "antd";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Widget from "../../../Common/Widget/Widget";

const COLORS = ["#20c997", "#13c2c2", "#f5222d", "#fa8c16"];

const OrdersChart = (props) => {
  const { cencelOrders, deliveredOrders, newOrders, shippedOrders } = props;
  const data = [
    { name: "Delivered", value: deliveredOrders },
    { name: "New", value: newOrders },
    { name: "Cancelled", value: cencelOrders },
    { name: "Shipping", value: shippedOrders },
  ];
  return (
    <Widget title={<h6 className="gx-text-capitalize gx-mb-0">My Orders</h6>}>
      <ResponsiveContainer width="100%" height={210}>
        <PieChart>
          <Tooltip />
          <text
            x="50%"
            className="h3"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {cencelOrders + deliveredOrders + newOrders + shippedOrders}
          </text>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="mt-2" style={{ textAlign: "center" }}>
        <div
          key={"#20c997"}
          style={{ display: "inline-block", marginLeft: "15px", marginRight: "15px" }}
        >
          <Badge color={"#20c997"} text={"Delivered"} />
        </div>
        <div
          key={"#13c2c2"}
          style={{ display: "inline-block", marginRight: "15px" }}
        >
          <Badge color={"#13c2c2"} text={"New"} />
        </div>
        <div
          key={"#f5222d"}
          style={{ display: "inline-block", marginRight: "15px" }}
        >
          <Badge color={"#f5222d"} text={"Cancelled"} />
        </div>
        <div
          key={"#fa8c16"}
          style={{ display: "inline-block", marginRight: "15px" }}
        >
          <Badge color={"#fa8c16"} text={"Shipping"} />
        </div>
      </div>
    </Widget>
  );
};
export default OrdersChart;
