import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Input, Card, Select, Spin } from "antd";
import { makeField } from "components/Common/Controls/MakeField";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userManagementActions } from "./ducks";
import { allCountriesJson } from "../../../DummyData/AllCountry";
import { FormItemLayout, FormItemLayout2 } from "../../../constants/Utilities";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class BuyerDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerDetails: null,
      mode: null,
      type: null,
      isDisabled: true,
      isCreate: false,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    const mode = params && params.mode;
    const type = params && params.type;
    const id = params && params.id;
    if (mode !== "create") {
      this.props.userManagementActions.getUser({ user_id: id });
    }
    const isDisabled = mode === "edit" || mode === "create" ? false : true;
    const isCreate = mode === "create" ? true : false;
    this.setState({
      mode,
      type,
      isDisabled,
      isCreate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { getUser } = this.props;
    if (
      getUser &&
      getUser.data &&
      getUser.data.data &&
      JSON.stringify(getUser) !== JSON.stringify(prevState.getUser)
    ) {
      var receivedObj = {
        firstName: getUser.data.data.first_name,
        lastName: getUser.data.data.last_name,
        email: getUser.data.data.email,
        country: getUser.data.data.country,
      };

      this.props.initialize(receivedObj);
      this.setState({
        getUser: getUser,
      });
    }
  }

  componentWillUnmount() {
    this.props.userManagementActions.clearUser();
  }

  editHandler = () => {
    const { params } = this.props.match;
    const type = params && params.type;
    const id = params && params.id;
    this.setState({
      mode: "edit",
      isDisabled: false,
    });
    this.props.history.push(
      `/seller/dashboard/userManagement/${type}/details/edit/${id}`
    );
  };

  handleSubmit = (values) => {
    const { userManagementActions } = this.props;
    const { isCreate } = this.state;
    const { params } = this.props.match;
    const id = params && params.id;
    const type = params && params.type;
    var userDto = {
      first_name: values.firstName,
      last_name: values.lastName,
      role: "Buyer",
      country_code: values.country,
    };
    var createUserDto = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      role: "Buyer",
      country_code: values.country,
    };

    if (isCreate) {
      userManagementActions.createUser({
        id: id,
        userDto: createUserDto,
        type: type,
        form: "BuyerDetailsForm",
      });
    } else {
      userManagementActions.updateUser({
        id: id,
        userDto: userDto,
        type: type,
        form: "BuyerDetailsForm",
      });
    }
  };

  render() {
    const { handleSubmit, getUser } = this.props;
    const { isCreate, isDisabled } = this.state;

    return (
      <Spin spinning={getUser.pending}>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Card className="gx-card" title="Buyer Information">
            <FormItem {...FormItemLayout} label="First Name">
              <Field
                formItemLayout={FormItemLayout2}
                name="firstName"
                component={AInputField}
                placeholder="First Name"
                hasFeedback
                disabled={isDisabled}
              />
            </FormItem>

            <FormItem {...FormItemLayout} label="Last Name">
              <Field
                formItemLayout={FormItemLayout2}
                name="lastName"
                component={AInputField}
                placeholder="Last Name"
                hasFeedback
                disabled={isDisabled}
              />
            </FormItem>

            <FormItem {...FormItemLayout} label="email address">
              <Field
                formItemLayout={FormItemLayout2}
                name="email"
                component={AInputField}
                placeholder="email address"
                hasFeedback
                disabled={!isCreate}
              />
            </FormItem>

            <FormItem {...FormItemLayout} label="Country / Region">
              <Field
                formItemLayout={FormItemLayout2}
                name="country"
                component={ASelectField}
                hasFeedback
                disabled={isDisabled}
              >
                {allCountriesJson.map((country, i) => {
                  return (
                    <Option key={i} value={country.alpha_3}>
                      {country.name}
                    </Option>
                  );
                })}
              </Field>
            </FormItem>

            <hr></hr>
            {/* <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="mb-0 float-right">
                  <FormItem
                    {...TailFormItemLayout}
                    style={{ marginBottom: "0" }}
                  >
                    {isDisabled ? (
                      <Button
                        type="primary"
                        onClick={() => this.editHandler()}
                        style={{
                          marginRight: "10px",
                          marginBottom: "0",
                          width: "120px",
                        }}
                        disabled={userRole === "Moderator"}
                      >
                        Edit
                      </Button>
                    ) : null}
                    {!isDisabled ? (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          marginRight: "10px",
                          marginBottom: "0",
                          width: "120px",
                        }}
                        disabled={userRole === "Moderator"}
                      >
                        Submit
                      </Button>
                    ) : null}
                  </FormItem>
                </div>
              </Col>
            </Row> */}
          </Card>
        </Form>
      </Spin>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }
  if (!values.email) {
    errors.email = "Email Address is required";
  }
  if (!values.country) {
    errors.country = "Country is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    getUser: state.UserManagement.getUser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "BuyerDetailsForm",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(BuyerDetailsForm))
);
