// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  changeUserActiveStatus: createAction(types.CHANGE_USER_ACTIVE_STATUS),
  changeUserActiveStatusSuccess: createAction(
    types.CHANGE_USER_ACTIVE_STATUS_SUCCESS
  ),
  changeUserActiveStatusFail: createAction(
    types.CHANGE_USER_ACTIVE_STATUS_FAIL
  ),

  createUser: createAction(types.CREATE_USER),
  createUserSuccess: createAction(types.CREATE_USER_SUCCESS),
  createUserFail: createAction(types.CREATE_USER_FAIL),

  getUser: createAction(types.GET_USER),
  getUserSuccess: createAction(types.GET_USER_SUCCESS),
  getUserFail: createAction(types.GET_USER_FAIL),

  updateUser: createAction(types.UPDATE_USER),
  updateUserSuccess: createAction(types.UPDATE_USER_SUCCESS),
  updateUserFail: createAction(types.UPDATE_USER_FAIL),

  deleteUser: createAction(types.DELETE_USER),
  deleteUserSuccess: createAction(types.DELETE_USER_SUCCESS),
  deleteUserFail: createAction(types.DELETE_USER_FAIL),

  clearUser: createAction(types.CLEAR_USER),

  getUserCount: createAction(types.GET_USER_COUNT),
  getUserCountSuccess: createAction(types.GET_USER_COUNT_SUCCESS),
  getUserCountFail: createAction(types.GET_USER_COUNT_FAIL),
};
