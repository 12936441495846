// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getProducts: createAction(types.GET_PRODUCTS),
  getProductsSuccess: createAction(types.GET_PRODUCTS_SUCCESS),
  getProductsFail: createAction(types.GET_PRODUCTS_FAILED),

  getLatestProductCarousel: createAction(types.GET_LATEST_PRODUCT_CAROUSEL),
  getLatestProductCarouselSuccess: createAction(
    types.GET_LATEST_PRODUCT_CAROUSEL_SUCCESS
  ),
  getLatestProductCarouselFail: createAction(
    types.GET_LATEST_PRODUCT_CAROUSEL_FAILED
  ),

  getPopularProductCarousel: createAction(types.GET_POPULAR_PRODUCT_CAROUSEL),
  getPopularProductCarouselSuccess: createAction(
    types.GET_POPULAR_PRODUCT_CAROUSEL_SUCCESS
  ),
  getPopularProductCarouselFail: createAction(
    types.GET_POPULAR_PRODUCT_CAROUSEL_FAILED
  ),

  getDiscountedProducts: createAction(types.GET_DISCOUNTED_PRODUCTS),
  getDiscountedProductsSuccess: createAction(
    types.GET_DISCOUNTED_PRODUCTS_SUCCESS
  ),
  getDiscountedProductsFail: createAction(types.GET_DISCOUNTED_PRODUCTS_FAILED),

  getTopSellers: createAction(types.GET_TOP_SELLERS),
  getTopSellersSuccess: createAction(types.GET_TOP_SELLERS_SUCCESS),
  getTopSellersFail: createAction(types.GET_TOP_SELLERS_FAILED),

  getAllCategories: createAction(types.GET_ALL_CATEGORIES),
  getAllCategoriesSuccess: createAction(types.GET_ALL_CATEGORIES_SUCCESS),
  getAllCategoriesFail: createAction(types.GET_ALL_CATEGORIES_FAILED),

  getPopularCategories: createAction(types.GET_POPULAR_CATEGORIES),
  getPopularCategoriesSuccess: createAction(types.GET_POPULAR_CATEGORIES_SUCCESS),
  getPopularCategoriesFail: createAction(types.GET_POPULAR_CATEGORIES_FAILED),

  getTopBrands: createAction(types.GET_TOP_BRANDS),
  getTopBrandsSuccess: createAction(types.GET_TOP_BRANDS_SUCCESS),
  getTopBrandsFail: createAction(types.GET_TOP_BRANDS_FAILED),
};
