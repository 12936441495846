import React from "react";
import { Col, Row } from "antd";
import DashboardSummeryCard from "../../components/Seller/Card/DashboardSummeryCard/DashboardSummeryCard";

function SummerySection({
  newOrders,
  shippedOrders,
  deliveredOrders,
  cencelOrders,
}) {
  return (
    <Row>
      <React.Fragment>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <DashboardSummeryCard
            cardColor="cyan"
            icon="cart-arrow-down"
            title={newOrders}
            subTitle="New Orders&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <DashboardSummeryCard
            cardColor="orange"
            icon="ship"
            title={shippedOrders}
            subTitle="Shipped Orders"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <DashboardSummeryCard
            cardColor="teal"
            icon="shipping-timed"
            title={deliveredOrders}
            subTitle="Delivered Orders"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <DashboardSummeryCard
            cardColor="red"
            icon="times-circle"
            title={cencelOrders}
            subTitle="Cancelled Orders"
          />
        </Col>
      </React.Fragment>
    </Row>
  );
}

export default SummerySection;
