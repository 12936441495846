import React from "react";
import { Layout } from "antd";
const { Content } = Layout;

const AuthLayout = (ViewComponent) => {
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <Content>
            <ViewComponent />
          </Content>
        </Layout>
      );
    }
  };
};

export default AuthLayout;
