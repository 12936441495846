import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Input, Row, Col, Card, Spin } from "antd";
import { makeField } from "components/Common/Controls/MakeField";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dashboardProfileActions } from "../../../ducks";
import {
  FormItemLayout,
  FormItemLayout2,
  TailFormItemLayout,
} from "../../../../../../constants/Utilities";
import "../../../SellerAccount.less";

const FormItem = Form.Item;

const AInputField = makeField(Input);

class SellerAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerDetails: null,
    };
  }

  componentDidMount() {
    const { dashboardProfileActions, sellerDetails } = this.props;
    sellerDetails.loading && dashboardProfileActions.getSellerDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sellerDetails } = this.props;
    if (
      sellerDetails &&
      sellerDetails.data &&
      sellerDetails.data.data &&
      sellerDetails.data.data.account_info &&
      JSON.stringify(sellerDetails) !== JSON.stringify(prevState.sellerDetails)
    ) {
      var receivedObj = {
        displayname: sellerDetails.data.data.account_info.name,
        email: sellerDetails.data.data.account_info.email,
        phonenumber: sellerDetails.data.data.account_info.phone,
        holidayMode: sellerDetails.data.data.account_info.holiday_mode,
      };

      this.props.initialize(receivedObj);
      this.setState({
        sellerDetails: sellerDetails,
      });
    }
  }

  handleSubmit = (values) => {
    const { dashboardProfileActions } = this.props;

    var SellerAccountDto = {
      name: values && values.displayname,
      email: values && values.email,
      phone: values && values.phonenumber,
    };
    dashboardProfileActions.updateSellerInfo(SellerAccountDto);
  };

  onChange(value, dateString) {
    
    
  }

  onOk(value) {
    
  }

  render() {
    const { handleSubmit, sellerDetails, updateSellerInfo } = this.props;
    let accountInfo =
      sellerDetails &&
      sellerDetails.data &&
      sellerDetails.data.data &&
      sellerDetails.data.data.account_info;

    return (
      <div className="gx-card p-3 pt-4">
        <Spin spinning={updateSellerInfo.pending || sellerDetails.loading}>
          <Form
            onSubmit={handleSubmit(this.handleSubmit)}
            className="jr-fld-catch-2"
          >
            <Card
              className="mb-3"
              style={{ backgroundColor: "rgb(250, 250, 250)" }}
            >
              <FormItem {...FormItemLayout} label="ID Seller">
                <span className="ant-form-text">
                  {accountInfo && accountInfo.id}
                </span>
              </FormItem>

              <FormItem {...FormItemLayout} label="Display Name / Shop Name">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="displayname"
                  component={AInputField}
                  placeholder="Display Name / Shop Name"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Email Address">
                <Field
                  disabled="false"
                  formItemLayout={FormItemLayout2}
                  name="email"
                  component={AInputField}
                  placeholder="Email Address"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Phone Number">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="phonenumber"
                  component={AInputField}
                  placeholder="Phone Number"
                  hasFeedback
                />
              </FormItem>
            </Card>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="float-right">
                  <FormItem
                    {...TailFormItemLayout}
                    style={{ marginBottom: "0" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        width: "160px",
                      }}
                    >
                      Submit
                    </Button>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.nameid) {
    errors.nameid = "Name Id is required";
  }
  if (!values.email) {
    errors.email = "Email Address is required";
  }
  if (!values.phonenumber) {
    errors.phonenumber = "Phone Number is required";
  }
  if (!values.displayname) {
    errors.displayname = "Display Name / Shop Name is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    sellerDetails: state.DashboardProfile.sellerDetails,
    updateSellerInfo: state.DashboardProfile.updateSellerInfo,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dashboardProfileActions: bindActionCreators(
      dashboardProfileActions,
      dispatch
    ),
  };
}

export default withRouter(
  reduxForm({
    form: "SellerAccount",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(SellerAccount))
);
