import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./_helpers/history";
import AppLocale from "lngProvider";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import { NotificationContainer } from "react-notifications";
import { locale } from "./constants/Utilities";
import ErrorBoundary from "./modules/ErrorBoundary/ErrorBoundary";
import "assets/vendors/style";
import "styles/wieldy.less";
import "./firebase/firebase";
import routes from "./routes";
import "react-datepicker/dist/react-datepicker.css";
import FloatingFeedbackButton from "./components/Common/Floating/FloatingFeedbackButton";
import MobileAppAlert from "./components/Common/MobileAppAlert/MobileAppAlert";

const NextApp = () => (
  <Router history={history}>
    <ErrorBoundary>
      <LocaleProvider locale={AppLocale[locale.locale].antd}>
        <IntlProvider
          locale={AppLocale[locale.locale].locale}
          messages={AppLocale[locale.locale].messages}
        >
          <Switch>
            {routes
              .filter((route) => !route.isDeactive)
              .map((route, index) => {
                return (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    component={route.layout(route.component)}
                  />
                );
              })}
          </Switch>
          <FloatingFeedbackButton />
          <NotificationContainer enterTimeout={300} leaveTimeout={300} />
          <MobileAppAlert />
        </IntlProvider>
      </LocaleProvider>
    </ErrorBoundary>
  </Router>
);

export default NextApp;
