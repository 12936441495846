import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import StarRatingComponent from "react-star-rating-component";
import { Avatar } from "antd";
import "./SimilarProductCard.less";
import history from "../../../../_helpers/history";
import { cartActions } from "../../../../modules/Cart/ducks";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";

class SimilarProductCard extends Component {
  productNavigateHandler = (id) => {
    const {
      // testimonial,
      loading,
      isCart,
    } = this.props;
    if (loading) {
      alert("wait...");
    } else if (isCart) {
      history.push(`/product/${id}`);
    } else {
      // this.props.cartActions.getProduct({
      //   id: id,
      // });
      history.push(`/product/${id}`);
    }
  };

  render() {
    const {
      testimonial,
      // loading,
      // isCart
    } = this.props;
    const {
      id,
      // content,
      avatar,
      name,
      title,
      images,
      price,
      old_price,
      discount_percentage,
    } = testimonial;
    return (
      <div
        className="gx-card gx-slide-item p-3 mb-0"
        style={{
          overflow: "hidden",
          display: "grid",
          gridTemplateRows: "230px 120px",
          height: "100%",
        }}
        onClick={() => this.productNavigateHandler(id)}
      >
        {!images ? (
          <Avatar src={avatar} alt="..." />
        ) : (
          <div
            className="gx-product-image"
            style={{
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              overflow: "hidden",
            }}
          >
            <img src={images[0]} alt="" />
          </div>
        )}
        <div className="">
          <h5 className="jr-product-title mt-2">{name}</h5>
          <small className="gx-post-designation">{title}</small>

          {/* <div className="gx-star-rating">
          <StarRatingComponent name={name} starCount={5} value={4.5}/>
        </div> */}
          {/* <p className="gx-description">{price}</p> */}
          <span className="h3 d-block">
            <span className="spn-clr">{`${DEFAULT_CURRENCY_SHORT} ${price}`}</span>
            &nbsp;
            {old_price && (
              <del className="text-muted">{`${DEFAULT_CURRENCY_SHORT} ${old_price}`}</del>
            )}
          </span>
          {/* {discount_percentage !== 0 && (
          <span style={{ color: "rgb(255, 105, 0)", fontSize: "20px" }}>
            {discount_percentage} % off
          </span>
        )} */}

          {/* {discount_percentage !== 0 && ( */}
          <span
            style={{
              color: `${
                discount_percentage !== 0
                  ? "rgb(255, 105, 0)"
                  : "rgb(255, 255, 255)"
              } `,
              fontSize: "20px",
            }}
          >
            {discount_percentage} % off
          </span>
        </div>

        {/* )} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.Cart.product,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SimilarProductCard)
);
