import React from "react";
import { Layout } from "antd";
import {
  Configure,
  connectHits,
  connectStateResults,
  InstantSearch,
  Pagination,
} from "react-instantsearch-dom";
import "instantsearch.css/themes/algolia.css";
import MyOrdersList from "../MyOrdersList/MyOrdersList";
import AlgoliaFooter from "../../../../Common/AlgoliaFooter/AlgoliaFooter";
import { initAlgoliaClient } from "../../../../../services/algolia.service";

const { Content } = Layout;

const MyOrdersContainer = (props) => {
  return (
    <div className="gx-card p-4 mb-0">
      <InstantSearch
        className="gx-main-content"
        indexName={props.index}
        searchState={props.searchState}
        createURL={props.createURL}
        searchClient={initAlgoliaClient(props.apiKey)}
        onSearchStateChange={props.onSearchStateChange}
      >
        <Configure hitsPerPage={10} />

        <Layout className="gx-algolia-layout-has-sider">
          <Content>
            <CustomResults />
          </Content>
          <AlgoliaFooter>
            <Pagination showLast={true} />
          </AlgoliaFooter>
        </Layout>
      </InstantSearch>
    </div>
  );
};

const CustomResults = connectStateResults(({ searchState, searchResult }) => {
  if (searchResult && searchResult.nbHits === 0) {
    return (
      <div className="gx-algolia-content-inner">
        <div className="gx-algolia-no-results">
          No results found matching{" "}
          <span className="gx-algolia-query">{searchState.query}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="gx-algolia-content-inner">
        <ConnectedProducts />
      </div>
    );
  }
});

const ConnectedProducts = connectHits(MyOrdersList);

export default MyOrdersContainer;
