import DumyImg from "assets/images/products/dummyProducts.gif";

export const allProducts = [
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc54",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc54",
    price: "0.00",
    seller_id: "1"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc55",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc55",
    price: "0.00",
    seller_id: "2"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc56",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc56",
    price: "0.00",
    seller_id: "3"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc57",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc57",
    price: "0.00",
    seller_id: "1"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc58",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc58",
    price: "0.00",
    seller_id: "2"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc59",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc59",
    price: "0.00",
    seller_id: "2"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc60",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc60",
    price: "0.00",
    seller_id: "2"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc61",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc61",
    price: "0.00",
    seller_id: "3"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc62",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc62",
    price: "0.00",
    seller_id: "3"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc63",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc63",
    price: "0.00",
    seller_id: "3"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc64",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc64",
    price: "0.00",
    seller_id: "3"
  },
  {
    isDummy: true,
    available: 1,
    category: "Loading...",
    created_at: "2020-06-11T06:30:21.343Z",
    hierarchicalCategories: {
      lvl0: "Motors",
      lvl1: "Motors > Parts & Accessories",
      lvl2: "Motors > Parts & Accessories > Automotive Tools & Supplies",
    },
    id: "a1ed66cb-a268-11ea-a691-cfcgfgfa0ccbdc65",
    images: [DumyImg],
    local_pickup: "1",
    name: "Loading...",
    objectID: "a1ed66cb-a268-11ea-a691-cfca0ccbdc65",
    price: "0.00",
    seller_id: "3"
  },
];
