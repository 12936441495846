const action_header = "productManagement/";

// Types
export default {
  GET_PRODUCTS_COUNT: action_header + "GET_PRODUCTS_COUNT",
  GET_PRODUCTS_COUNT_SUCCESS: action_header + "GET_PRODUCTS_COUNT_SUCCESS",
  GET_PRODUCTS_COUNT_FAIL: action_header + "GET_PRODUCTS_COUNT_FAIL",

  CREATE_PRODUCT: action_header + "CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS: action_header + "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAIL: action_header + "CREATE_PRODUCT_FAIL",

  CLEAR_CREATE_PRODUCT: action_header + "CLEAR_CREATE_PRODUCT",

  GET_PRODUCT: action_header + "GET_PRODUCT",
  GET_PRODUCT_SUCCESS: action_header + "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAIL: action_header + "GET_PRODUCT_FAIL",

  UPDATE_PRODUCT: action_header + "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: action_header + "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAIL: action_header + "UPDATE_PRODUCT_FAIL",

  DELETE_PRODUCT: action_header + "DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS: action_header + "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAIL: action_header + "DELETE_PRODUCT_FAIL",

  CLEAR_DELETE_PRODUCT_DATA: action_header + "CLEAR_DELETE_PRODUCT_DATA",

  CHANGE_PRODUCT_ACTIVE_STATUS: action_header + "CHANGE_PRODUCT_ACTIVE_STATUS",
  CHANGE_PRODUCT_ACTIVE_STATUS_SUCCESS:
    action_header + "CHANGE_PRODUCT_ACTIVE_STATUS_SUCCESS",
  CHANGE_PRODUCT_ACTIVE_STATUS_FAIL:
    action_header + "CHANGE_PRODUCT_ACTIVE_STATUS_FAIL",

  CLEAR_PRODUCT_ACTIVE_STATUS_DATA:
    action_header + "CLEAR_PRODUCT_ACTIVE_STATUS_DATA",

  GET_PRODUCT_CATEGORIES: action_header + "GET_PRODUCT_CATEGORIES",
  GET_PRODUCT_CATEGORIES_SUCCESS:
    action_header + "GET_PRODUCT_CATEGORIES_SUCCESS",
  GET_PRODUCT_CATEGORIES_FAIL: action_header + "GET_PRODUCT_CATEGORIES_FAIL",

  REPLY_QUESTION: action_header + "REPLY_QUESTION",
  REPLY_QUESTION_SUCCESS: action_header + "REPLY_QUESTION_SUCCESS",
  REPLY_QUESTION_FAIL: action_header + "REPLY_QUESTION_FAIL",

  HANDLE_PRODUCT_IMAGES: action_header + "HANDLE_PRODUCT_IMAGES",
  CLEAR_PRODUCT_IMAGES: action_header + "CLEAR_PRODUCT_IMAGES",

  REMOVE_PRODUCT_IMAGES: action_header + "REMOVE_PRODUCT_IMAGES",

  HANDLE_VARIATIONS_IMAGES: action_header + "HANDLE_VARIATIONS_IMAGES",
  REMOVE_VARIATIONS_IMAGES: action_header + "REMOVE_VARIATIONS_IMAGES",

  HANDLE_VARIATION_TABLE: action_header + "HANDLE_VARIATION_TABLE",

  REPLY_TO_QNA: action_header + "REPLY_TO_QNA",
  REPLY_TO_QNA_SUCCESS: action_header + "REPLY_TO_QNA_SUCCESS",
  REPLY_TO_QNA_FAIL: action_header + "REPLY_TO_QNA_FAIL",

  DELETE_QNA: action_header + "DELETE_QNA",
  DELETE_QNA_SUCCESS: action_header + "DELETE_QNA_SUCCESS",
  DELETE_QNA_FAIL: action_header + "DELETE_QNA_FAIL",

  DELETE_QNA_REPLY: action_header + "DELETE_QNA_REPLY",
  DELETE_QNA_REPLY_SUCCESS: action_header + "DELETE_QNA_REPLY_SUCCESS",
  DELETE_QNA_REPLY_FAIL: action_header + "DELETE_QNA_REPLY_FAIL",

  GET_SHIPPING_METHOD: action_header + "GET_SHIPPING_METHOD",
  GET_SHIPPING_METHOD_SUCCESS: action_header + "GET_SHIPPING_METHOD_SUCCESS",
  GET_SHIPPING_METHOD_FAILED: action_header + "GET_SHIPPING_METHOD_FAILED",
};
