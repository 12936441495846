import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  product: {
    ...initialStateModal,
  },
  exportDetail: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.GET_COVID19_DETAILS]: (state, { payload }) => ({
      ...state,
      covid19Details: {
        ...state.covid19Details,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_COVID19_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      covid19Details: {
        ...state.covid19Details,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_COVID19_DETAILS_FAILED]: (state, { payload }) => ({
      ...state,
      covid19Details: {
        ...state.covid19Details,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    [types.GET_ALL_DETAILS]: (state, { payload }) => ({
      ...state,
      allDetails: {
        ...state.allDetails,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_ALL_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allDetails: {
        ...state.allDetails,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_ALL_DETAILS_FAILED]: (state, { payload }) => ({
      ...state,
      allDetails: {
        ...state.allDetails,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),

    //************************************************************/
    [types.GET_EXPORT_DETAILS]: (state, { payload }) => ({
      ...state,
      exportDetail: {
        ...state.exportDetail,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_EXPORT_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      exportDetail: {
        ...state.exportDetail,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_EXPORT_DETAILS_FAILED]: (state, { payload }) => ({
      ...state,
      exportDetail: {
        ...state.exportDetail,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //************************************************************/
  },
  initialState
);
