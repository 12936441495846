import React from "react";
import { Button, Card, Table, Spin } from "antd";
import moment from "moment";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { financeActions } from "../../../../modules/SellerDashboard/Finance/ducks";
import "./TransactionList.less";

class TransactionList extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  exportHandler = () => {
    const { financeActions } = this.props;
    financeActions.getTransactionRecord({
      type: "Transaction",
    });
  };

  render() {
    let { hits, exportTransactions } = this.props;
    const columns = [
      {
        title: "Transaction ID",
        dataIndex: "objectID",
      },
      {
        title: "Date",
        render: (text, record) => (
          <div>{moment(record.created_at).format("LLLL")}</div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Tax",
        render: (text, record) => <div>{`$ ${record.tax}`}</div>,
      },
      {
        title: "Total",
        render: (text, record) => <div>{`$ ${record.total}`}</div>,
      },
    ];
    const title = (
      <div>
        <Button
          type="primary"
          style={{
            display: "inline-block",
            float: "right",
            marginBottom: "0px",
            width: "250px",
          }}
          onClick={this.exportHandler}
        >
          <i className="fad fa-file-excel mr-2"></i> Export as an Excel File
        </Button>
      </div>
    );

    return (
      <Spin spinning={exportTransactions.pending}>
        <Card className="gx-card mb-2" title={title}>
          <Table
            columns={columns}
            dataSource={hits}
            className="trns-tbl-scrl"
            scroll={{ x: 1300 }}
            bordered
          />
        </Card>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    exportTransactions: state.FinanceManagement.exportTransactions,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    financeActions: bindActionCreators(financeActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionList)
);
