import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { withRouter } from "react-router-dom";
import Cards from "react-credit-cards";
import { Button, Col, Form, Input, Row, Tabs, Radio } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import { cartActions } from "../../../../modules/Cart/ducks";
import "react-credit-cards/es/styles-compiled.css";
const FormItem = Form.Item;
const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    md: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 24,
      offset: 0,
    },
    xl: {
      span: 24,
      offset: 0,
    },
  },
};

const AInputField = makeField(Input);

class PaymentMethods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      focus: "",
    };
  }

  componentDidMount() {
    var initializeObj = {
      cardHodler: null,
      cardNumber: null,
      cvc: null,
      expire: null,
    };
    this.props.initialize(initializeObj);
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  callback = (key) => {
    // debugger
    this.props.cartActions.paymentMethodHandle({ method: key });
  };
  handleSubmit = (values) => {
    var { cardNumber, cvc, expire } = values;

    var dateObj = new Date();
    var currentyear = dateObj.getUTCFullYear();
    var month = null;
    var year = null;
    var expire_year = null;

    if (expire) {
      month = parseInt(expire.split("/")[0]);
      year = values.expire.split("/")[1];
      expire_year = parseInt(String(currentyear).slice(0, 2) + year);
    }
    if (cardNumber) {
      cardNumber = cardNumber.replace(/^\D+/g, "").replace(/\s/g, "");
    }
    if (cvc) {
      cvc = cvc.replace(/^\D+/g, "").replace(/\s/g, "");
    }

    var cardInfoDto = {
      credit_card_number: cardNumber,
      expire_year: expire_year,
      expire_month: month,
      cvv: cvc,
    };

    this.props.cartActions.confirmCardInfo({ cardInfoDto: cardInfoDto });
  };

  render() {
    const { handleSubmit, pristine, reset, submitting, fieldValues } =
      this.props;
    const { focus } = this.state;

    return (
      <div className="gx-wall-content">
        <h5 className="ml-2 mb-3 mt-4">Payment Methods</h5>
        <div className="gx-card p-3 mb-0">
          <Tabs onChange={this.callback} type="card">
            <TabPane tab="Credit Card" key="credit_card">
              <React.Fragment>
                <div style={{ marginBottom: "15px" }}>
                  <Cards
                    cvc={(fieldValues && fieldValues.cvc) || ""}
                    expiry={(fieldValues && fieldValues.expire) || ""}
                    // expiry={expireDate}
                    focused={focus}
                    name={(fieldValues && fieldValues.cardHodler) || ""}
                    number={(fieldValues && fieldValues.cardNumber) || ""}
                  />
                </div>

                <React.Fragment>
                  <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Field
                          formItemLayout={formItemLayout}
                          name="cardNumber"
                          component={AInputField}
                          placeholder="0000 0000 0000 0000"
                          onFocus={this.handleInputFocus}
                          hasFeedback
                          maxLength={23}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Field
                          formItemLayout={formItemLayout}
                          name="cardHodler"
                          component={AInputField}
                          placeholder="Card Holder"
                          onFocus={this.handleInputFocus}
                          hasFeedback
                        />
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={8} xs={12}>
                        <Field
                          formItemLayout={formItemLayout}
                          name="expire"
                          component={AInputField}
                          placeholder="MM/YY"
                          onFocus={this.handleInputFocus}
                          max="12"
                          min="1"
                          hasFeedback
                          maxLength={5}
                        />
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={8} xs={24}>
                        <Field
                          formItemLayout={formItemLayout}
                          name="cvc"
                          component={AInputField}
                          placeholder="000"
                          onFocus={this.handleInputFocus}
                          hasFeedback
                          maxLength={4}
                        />
                      </Col>
                    </Row>

                    <FormItem
                      {...tailFormItemLayout}
                      style={{ marginBottom: "0" }}
                    >
                      {/* <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          disabled={pristine || submitting}
                          style={{ marginRight: "10px", marginBottom: "0" }}
                        >
                          Confirm
                        </Button> */}
                      <Button
                        disabled={pristine || submitting}
                        onClick={reset}
                        style={{ marginBottom: "0" }}
                        className="float-right"
                      >
                        Clear Form
                      </Button>
                    </FormItem>
                  </Form>
                </React.Fragment>
              </React.Fragment>
            </TabPane>
            <TabPane tab="Paypal" key="paypal">
              <Radio checked>Paypal Payment</Radio>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  var dateObj = new Date();
  var currentmonth = dateObj.getUTCMonth() + 1;
  var currentyear = dateObj.getUTCFullYear();
  var pattern = new RegExp(/^\d*[1-9]\d*$/);
  const errors = {};

  if (!values.cardNumber) {
    errors.cardNumber = "Card number is required";
  }

  if (
    values.cardNumber &&
    values.cardNumber.replace(/\s/g, "").search(pattern)
  ) {
    errors.cardNumber = "Invalid Card Number";
  }

  if (
    (values.cardNumber && values.cardNumber.length < 9) ||
    (values.cardNumber && values.cardNumber.length > 23)
  ) {
    errors.cardNumber = "Number Length Does not Match";
  }

  if (!values.expire) {
    errors.expire = "Card expire is required";
  }

  if (values.expire) {
    if (values.expire.includes("/")) {
      var month = parseInt(values.expire.split("/")[0]);
      var year = parseInt(values.expire.split("/")[1]);

      if (month < 0 || month > 12) {
        errors.expire = "Invalid Month";
      }
      if (year < parseInt(String(currentyear).slice(currentyear.length, -2))) {
        errors.expire = "Card Is Expired";
      }

      if (
        year === parseInt(String(currentyear).slice(currentyear.length, -2)) &&
        month < currentmonth
      ) {
        errors.expire = "Card Is Expired";
      }
    }
  }

  if (!values.cardHodler) {
    errors.cardHodler = "Card holder is required";
  }
  if (!values.cvc) {
    errors.cvc = "CVV  is required";
  }

  if (values.cvc && values.cvc.length !== 4 && values.cvc.length !== 3) {
    errors.cvc = "Invalid CVV";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    shippingCost: state.Cart.shippingCost,
    fieldValues: getFormValues("PaymentMethods")(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "PaymentMethods",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(PaymentMethods))
);
