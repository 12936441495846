import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class FinalValueFees extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Tradesbarn Seller Fees" />
          <CSBreadcrumb label={"Tradesbarn Seller Fees"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              When you list and sell items on Tradesbarn, we charge fees. There
              are two main types of fees: an Ad fee if you Request an
              Advertisement, and a final value fee when your item sells.
            </p>
            <p>
              The amount we charge depends on the item's price, the format and
              category you choose for your listing, any optional listing
              upgrades you add, and your seller conduct and performance. For
              more information, see our different types of selling fees below.
            </p>
            <br />
            <p>
              <b>Ad Fees</b>
            </p>
            <p>
              <u>
                <b>Top Banner</b>
              </u>
            </p>
            <img
              alt="img-final-value"
              width={623}
              height={168}
              className="d-block mx-auto"
              src={require("assets/policy/policy27.png")}
            />
            <p>
              <br />
            </p>
            <p>1000 USD Displaying For 03 Months</p>
            <p>
              Premium Advertising, Ads can be as simple or sophisticated as you
              want them to be. Millions Of people use Tradesbarn every month In
              180 Countries So no matter what kind of audience you want to
              reach, you’ll find them here Let Your Products and Services Expose
              to Millions of Visitors
            </p>
            <p>Access to Your Own Customized Store Front at Tradesbarn</p>
            <div>
              <br />
              <p>
                <b>Branding Your Product</b>
              </p>
              <img
                alt="img-final-value"
                width={625}
                height={114}
                className="d-block mx-auto"
                src={require("assets/policy/policy28.png")}
              />
              <p>
                <br />
              </p>
              <p />
              <p>
                800 USD Displaying For 03 Months Make connections that matter.
              </p>
              <p>
                Millions Of people use Tradesbarn every month to Buy or Sell
                things that matter. Find new customers and build lasting
                relationships with them.
              </p>
              <p>Access to Your Own Customized Store Front at Tradesbarn</p>
              <br />
              <p>
                <b>The Sides</b>
              </p>
              {/* <img
                alt="img-final-value"
                width={149}
                height={316}
                className="d-block mx-auto"
                src={require("assets/policy/policy29.png")}
              /> */}
              {/* <p>
                <br />
              </p> */}
              <p />
              <p>
                1.
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                500 USD Displaying For 03
                <span style={{ letterSpacing: "-.5pt" }}> </span>Months
              </p>
              <p style={{ marginTop: "1.1pt" }}>
                2.
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                500 USD Displaying For 03
                <span style={{ letterSpacing: "-.5pt" }}> </span>Months
              </p>
              <p>
                3.
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                500 USD Displaying For 03
                <span style={{ letterSpacing: "-.5pt" }}> </span>Months
              </p>
              <p>
                No two businesses are alike. That’s why trades barn is built to
                help your business meet its specific goals. Find the perfect
                solution for your business goal with Tradesbarn
              </p>
              <p>Tell your story beautifully on any device.</p>
            </div>
            <div>
              <p>
                Tradesbarn ad formats and Pages are designed to capture
                attention and prompt action. They offer creative flexibility,
                work across devices and are built to help you reach your goals.
              </p>
              <p>Access to Your Own Customized Store Front at Tradesbarn</p>
              <br />
              <p>
                <b>If Paying By Check</b>
              </p>
              <p>Checks Should Be Addressed To Tradesbarn LLC</p>
              <br />
              <p>
                <b>For Wire Transfers</b>
              </p>
              <p>
                <pre>Account Name : Tradesbarn LLC</pre>
              </p>
              <p>
                <pre>Bank : Chase Bank</pre>
              </p>
              <p>
                <pre>Routing Number : 071000013</pre>
              </p>
              <p>
                <pre>Account Number : 656050561</pre>
              </p>
              <p>Disclaimer:</p>
              <p>
                All prices are subject to change without notice and are not
                guaranteed, except that prices for an order that have been
                accepted by Tradesbarn LLC are not subject to change after
                acceptance. Prices stated do not include any tax or any other
                tax, duty or charge which is now in effect or may be hereafter
                imposed by any Federal, State or other authority. All such
                taxes, duties or other charges shall be paid by Buyer unless
                Buyer shall provide Seller an exemption certificate acceptable
                to the appropriate authorities.
              </p>
              <br />
              <p>
                <b>Final value fees</b>
              </p>
              <p>We charge 10% a final value fee when your item sells.</p>
              <p>
                This fee is calculated as a percentage of the total amount of
                the sale. The total amount of the sale includes the item price,
                and any shipping and handling charges. Sales tax isn't included
                in the calculation.
              </p>
              <p>
                If for some reason the buyer doesn't pay, you need to either
                cancel the sale or report it as an unpaid item in order to be
                eligible for a final value fee credit.
              </p>
            </div>
          </div>
        </Widget>
      </div>
    );
  }
}

export default FinalValueFees;
