import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Card, Form, Input, Modal } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import {
  checkSession,
  PasswordReset,
  signOutWithRedirect,
} from "./../../../../services/auth.service";

const FormItem = Form.Item;
const AInputField = makeField(Input);
class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      formLayout: "inline",
      isModalVisible: false,
    };
  }

  handleFormLayoutChange = (e) => {
    this.setState({ formLayout: "inline" });
  };

  componentDidMount() {
    checkSession()
      .then((user) => {
        this.setState({
          isModalVisible: false,
        });
      })
      .catch((err) => {
        this.setState({
          isModalVisible: true,
        });
      });
  }

  handleSubmit = (values) => {
    const { newPassword } = values;
    // var ChangePasswordDto = {
    //   newPassword: confirmPassword,
    // };

    PasswordReset(newPassword)
      .then(() => {
        
        var initializeObj = {
          newPassword: null,
          confirmPassword: null,
        };
        this.props.initialize(initializeObj);
      })
      .catch((error) => {
        
      });
  };

  handleOk = () => {
    signOutWithRedirect("/login?redirect_to=/account/change-password");
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { formLayout } = this.state;
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      // currentUser,
    } = this.props;

    const formItemLayout = null;
    const buttonItemLayout = null;
    return (
      <Card
        className="gx-card mt-4"
        style={{ backgroundColor: "rgb(250, 250, 250)", marginBottom: "160px" }}
      >
        <h5 className="mb-3 ml-2">Change Password</h5>
        <div className="gx-card p-3 mb-0">
          <h6 className="mb-3">Change Password</h6>

          <br></br>
          <Form layout={formLayout} onSubmit={handleSubmit(this.handleSubmit)}>
            <Field
              formItemLayout={formItemLayout}
              // label="First Name"
              name="newPassword"
              component={AInputField}
              placeholder="New Password"
              type="password"
              hasFeedback
            />
            <Field
              formItemLayout={formItemLayout}
              // label="First Name"
              name="confirmPassword"
              component={AInputField}
              placeholder="Confirm Password"
              type="password"
              hasFeedback
            />
            <FormItem {...buttonItemLayout}>
              {/* <Button type="primary">Submit</Button> */}

              <Button
                type="primary"
                disabled={pristine || submitting}
                htmlType="submit"
                style={{ marginRight: "10px" }}
              >
                <i className="fad fa-check-circle"></i>&nbsp; Confirm
              </Button>

              <Button disabled={pristine || submitting} onClick={reset}>
                <i className="fad fa-redo-alt"></i>&nbsp; Reset
              </Button>
            </FormItem>
          </Form>
        </div>

        <Modal
          title="Re-Login Required"
          cancelButtonProps={{
            style: {
              display: "none",
            },
          }}
          visible={this.state.isModalVisible}
          closable={false}
          onOk={this.handleOk}
        >
          <p>Click Ok to continue</p>
        </Modal>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = "New password is required";
  }
  if (values && values.newPassword && values.newPassword.length < 6) {
    errors.newPassword = "Password should be minumum 6 Character";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm password is required";
  }
  if (values.confirmPassword && values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Password mismatch..!";
  }
  return errors;
};

// export default ChangePassword;
export default reduxForm({
  form: "ChangePassword_form",
  validate,
})(ChangePassword);
