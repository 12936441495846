// Services: all redux-logic services
import { authService } from "./modules/Auth/ducks";
import { homeService } from "./modules/Home/ducks";
import { singleProductService } from "./modules/SingleProduct/ducks";
import { sellerStoreService } from "./modules/SellerStore/ducks";
import { cartService } from "./modules/Cart/ducks";
import { profileService } from "./modules/ProfilePage/ducks";
import { resolutionCenterService } from "./modules/ResolutionCenter/ducks";

//seller-dashboard
import { dashboardService } from "./modules/Dashboard/ducks";
import { orderManagementService } from "./modules/SellerDashboard/OrderManagement/ducks";
import { productManagementService } from "./modules/SellerDashboard/ProductManagement/ducks";
import { dashboardProfileService } from "./modules/SellerDashboard/SellerAccount/ducks";
import { reviewService } from "./modules/SellerDashboard/ReviewManagement/ducks";
import { chatService } from "./modules/ChatPage/ducks";
import { ReportManagementService } from "./modules/SellerDashboard/ReportManagement/ducks";
import { financeService } from "./modules/SellerDashboard/Finance/ducks";
//Moderator and Admin dashboard
import { userManagementService } from "./modules/SellerDashboard/UserManagement/ducks";

import { errorHandlerService } from "./modules/ErrorManager/ducks";

//Credit Card Detail
import { creditCardService } from "./modules/CardsAddPage/ducks";

//contact Page
import { contactPageService } from "./modules/StaticPages/ducks";
import { customerServiceService } from "./modules/CustomerService/ducks";

// Export all redux-logics
export default [
  ...homeService,
  ...authService,
  ...singleProductService,
  ...sellerStoreService,
  ...cartService,
  ...profileService,
  ...resolutionCenterService,
  ...dashboardService,
  ...orderManagementService,
  ...productManagementService,
  ...dashboardProfileService,
  ...userManagementService,
  ...reviewService,
  ...chatService,
  ...ReportManagementService,
  ...financeService,
  ...errorHandlerService,
  ...creditCardService,
  ...contactPageService,
  ...customerServiceService
];
