import React, { useState } from "react";
import { connectAutoComplete, Highlight } from "react-instantsearch-dom";
import { Input } from "antd";
import CategorySelector from "../../../components/Common/InputControls/CategorySelector/CategorySelector";
import history from "../../../_helpers/history";
import "./HeaderSearchBox.less";

const Search = Input.Search;

const HeaderSearchBox = ({ allCategories }) => (
  <div className="gx-algolia-header mb-0 mx-auto">
    <div className="srch-input-jr autocomplete-list-tb">
      <ConnectedSearchBox allCategories={allCategories} />
    </div>
  </div>
);

const CustomSearchBox = ({
  hits,
  currentRefinement,
  refine,
  allCategories,
}) => {
  const [CategorySetState, setCategorySetState] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const handleCategory = (value) => setCategorySetState(value);

  const Show = () => {
    setShowResults(true);
  };

  const hide = () => {
    setShowResults(false);
  };

  return (
    <React.Fragment>
      <div className="gx-header-search gx-d-none gx-d-lg-flex tr-search-bar ml-0 vsbl-in-web-2">
        <Search
          onMouseEnter={Show}
          className="mb-0"
          addonBefore={
            <CategorySelector
              allCategories={allCategories}
              onSelectCategory={handleCategory}
            />
          }
          value={currentRefinement}
          onChange={(e) => {
            refine(e.target.value);
            Show();
          }}
          placeholder="Find Product"
          enterButton="Search"
          size="large"
          autoComplete="off"
          id="q"
          onSearch={(value) =>
            CategorySetState && CategorySetState !== "All Categories"
              ? history.push(`/search/${CategorySetState}?query=${value}`)
              : history.push(`/search?query=${value}`)
          }
        />
      </div>

      {showResults && currentRefinement && hits && hits.length !== 0 ? (
        <div
          onMouseEnter={Show}
          onMouseLeave={hide}
          style={{
            position: "absolute",
            zIndex: 100,
            width: "650px",
            background: "white",
            boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.03)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
          className="vsbl-in-web-2"
        >
          <div className="search-reslt-menu">
            <table style={{ width: "100%" }}>
              <tbody>
                {hits.map((hit, idx) => (
                  <tr onClick={() => history.push(`/product/${hit.id}`)} className="search-res-itm">
                    <td style={{ width: "60px", padding: "3px 10px" }}>
                      <img
                        src={hit.images[0]}
                        alt="searchBox"
                        style={{ height: "40px" }}
                      />
                    </td>
                    <td style={{ padding: "3px" }}>
                      <div className="hit hit-jr">
                        <Highlight attribute="name" hit={hit} key={idx} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const ConnectedSearchBox = connectAutoComplete(CustomSearchBox);



export default HeaderSearchBox;
