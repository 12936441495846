import React from "react";
import { Layout } from "antd";
import HorizontalDark from "../containers/Topbar/HorizontalDark";
import BannerCarousel from "../components/Buyer/Carousel/BannerCarousel/BannerCarousel";
import MainNavBar from "../components/MainNavBar/MainNavBar";
import TopBar from "../components/TopBar/TopBar";
import Sidebar from "../containers/Sidebar";
import "./layouts.less";
import SiteFooter from "../components/Buyer/FooterComponent/SiteFooter";

const { Content } = Layout;

const HomeLayout = (ViewComponent) => {
  return class extends React.Component {
    render() {
      return (
        <Layout className="gx-app-layout">
          <Sidebar />
          <Layout>
            <TopBar />
            <HorizontalDark />
            <MainNavBar />
            <BannerCarousel />
            <Content>
              <ViewComponent />
              <SiteFooter />
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export default HomeLayout;
