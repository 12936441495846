import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import { siteVisit } from "../../../../DummyData/SellerDashboard";

const SiteVisit = ({ sellerStatistics }) => (
  <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
    <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4">Site Visits</h6>
    <ResponsiveContainer width="100%" height={140}>
      <AreaChart
        data={!sellerStatistics.loading && sellerStatistics.data.user_traffic}
        // data={siteVisit}
        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
      >
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="this_week"
          fillOpacity={0.3}
          stroke="#038fde"
          fill="#038fde"
        />
        <Area
          type="monotone"
          dataKey="last_week"
          fillOpacity={0.3}
          stroke="#52c41a"
          fill="#52c41a"
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default SiteVisit;
