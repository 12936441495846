import React from "react";
import { Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { Button, Icon, Input, Select, Row, Col, Card } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import { NotificationManager } from "react-notifications";

const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 22 },
    sm: { span: 20 },
  },
};

const selectHandler = () => {
  
};

const addAttributeHandler = (fields, fieldValues) => {
  if (fields.length === 0) {
    fields.push({});
  } else {
    let variations = fieldValues && fieldValues.variations;
    if (variations[fields.length - 1].values.length === 0) {
      let message = `Add ${
        variations[fields.length - 1].label ||
        `Attribute #${[fields.length]} and `
      } Options`;
      NotificationManager.info(message, "Info");
    } else {
      fields.push({});
    }
  }
};

const RenderVariations = ({
  fields,
  meta: { touched, error, submitFailed },
  fieldValues,
}) => {
  let isVariations =
    fieldValues &&
    Array.isArray(fieldValues.variations) &&
    fieldValues.variations.length !== 0;

  return (
    <div>
      <Row>
        <Col sm={24} xs={24} md={24} lg={12} xl={12} className="p-0">
          {fields.length < 3 ? (
            <Button
              className="gx-btn-secondary gx-pulse-effect"
              block
              onClick={() => addAttributeHandler(fields, fieldValues)}
            >
              <Icon type="plus" style={{ verticalAlign: "middle" }} /> Add
              Attributes
            </Button>
          ) : null}
          {(touched || submitFailed) && error && <span>{error}</span>}
        </Col>
      </Row>

      {fields.length !== 0 ? (
        <Card style={{ backgroundColor: "#fafafa" }}>
          {fields.map((variation, index) => {
            return index < 3 ? (
              <div key={index}>
                {index !== 0 && <hr />}
                <div style={{ display: "flex", marginBottom: 8 }} align="start">
                  <div style={{ fontSize: "16px" }}>Attribute #{index + 1}</div>
                  <Icon
                    className="pl-2 dynamic-delete-button cancle-clr"
                    style={{ lineHeight: "unset" }}
                    type="close-circle"
                    // disabled={keys.length === 1}
                    onClick={() => fields.remove(index)}
                  />
                </div>
                <div className="jr-fld-catch">
                  <Field
                    formItemLayout={formItemLayout}
                    name={`${variation}.label`}
                    type="text"
                    component={AInputField}
                    label="Attribute"
                    placeholder={
                      index === 0
                        ? "Ex : Color"
                        : index === 1
                        ? "Ex : Size"
                        : "Ex : Warranty"
                    }
                  />
                  {/* <Field

                type="text"
                component={AInputField}
                label="Values"
              /> */}
                  <Field
                    formItemLayout={formItemLayout}
                    label="Options"
                    name={`${variation}.values`}
                    component={ASelectField}
                    mode="tags"
                    style={{ width: "100%" }}
                    onChange={selectHandler()}
                    tokenSeparators={[","]}
                    // defaultValue="a10"
                    //placeholder={`${variation}.label`}
                    placeholder={
                      isVariations && fieldValues.variations[index].label
                        ? `Enter ${fieldValues.variations[index].label} options`
                        : index === 0
                        ? "Ex : Red, Black, Blue, Green"
                        : index === 1
                        ? "Ex : S, M, L, XL"
                        : index === 2
                        ? "Ex : 1 yr, 2 yr, 3 yr"
                        : "Enter your options"
                    }
                    hasFeedback
                  >
                    <Option value="null" disabled>
                      Type and Enter
                    </Option>
                  </Field>
                </div>
              </div>
            ) : (
              alert("Limited")
            );
          })}
        </Card>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues("AddPoduct_form")(state),
  };
};

// function mapDispatchToProps(dispatch) {
//   return {
//     productManagementActions: bindActionCreators(
//       productManagementActions,
//       dispatch
//     ),
//     dashboardProfileActions: bindActionCreators(
//       dashboardProfileActions,
//       dispatch
//     ),
//   };
// }

// export default withRouter(
//   reduxForm({
//     form: "AddPoduct_form",
//     validate,
//     onSubmitFail: scrollIntoFirstError,
//   })
// );
export default connect(mapStateToProps, null)(RenderVariations);
