import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { profileActions } from "../ducks";
import { Card, Col, Row } from "antd";
import ShippingAddressCard from "../../../components/Buyer/Card/ShippingAddressCard/ShippingAddressCard";
import ShippingAddressModal from "../../../components/Buyer/Modal/ShippingAddressModal/ShippingAddressModal";
import "./ShippingAddress.less";

class ShippingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
    };
  }

  componentDidMount = () => {
    const { shippingAddress } = this.props;
    shippingAddress.loading && this.props.profileActions.getShippingAddress();
  };
  ordarAddrepsssItemsHandler = (data) => {
    this.props.profileActions.addOrderAddress(data);
  };
  ordarAddressEditItemsHandler = (data) => {
    this.props.profileActions.editOrderAddress(data);
  };

  ordarAddressDeleteItemsHandler = (data) => {
    this.props.profileActions.removeOrderAddress(data);
  };

  shippingAddressDeleteHandler = (data) => {
    this.props.profileActions.deleteShippingAddress({
      shipping_address_id: data.id,
    });
  };

  render() {
    const { shippingAddress } = this.props;
    let defaultShippingAddressId =
      (shippingAddress &&
        shippingAddress.data &&
        shippingAddress.data.data &&
        shippingAddress.data.data.default_shipping_address_id) ||
      null;
    const CardList = (props) => (
      <div className="card-list">
        {shippingAddress.data &&
        shippingAddress.data.data &&
        shippingAddress.data.data.shipping_addresses.length > 0 ? (
          shippingAddress.data.data.shipping_addresses.map((address, i) => (
            <ShippingAddressCard
              ordarAddressEditItemsHandler={this.ordarAddressEditItemsHandler}
              shippingAddressDeleteHandler={this.shippingAddressDeleteHandler}
              ordarAddressDeleteItemsHandler={
                this.ordarAddressDeleteItemsHandler
              }
              isDefaultShippingAddress={defaultShippingAddressId === address.id}
              address={address}
              orderAddress={props.orderAddress}
              {...address}
            />
          ))
        ) : (
          <div style={{ padding: "120px", textAlign: "center" }}>
            <p>No shipping address</p>
          </div>
        )}
      </div>
    );

    return (
      <Card
        className="gx-card mt-4 adrs-top-pdng"
        style={{ backgroundColor: "rgb(250, 250, 250)" }}
      >
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h5 className="mb-3 ml-2 mt-2">Shipping Address</h5>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div style={{ textAlign: "end" }}>
              <ShippingAddressModal
                ordarAddressItemsHandler={this.ordarAddressItemsHandler}
                isCreate={true}
              />
            </div>
          </Col>
        </Row>

        {/* <div className="header">{this.props.title}</div> */}

        {shippingAddress.loading ? (
          //loader....
          <div className="tr-address-loader-container">
            {/* <div className="tr-address-loader"> </div> */}
            <div className="loadingspinner"> </div>
          </div>
        ) : (
          <CardList
            orderAddress={this.props.orderAddress}
            profiles={this.state.profiles}
          />
        )}
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    // selectedItems: state.ShippingAddress.selectedItems,
    orderAddress: state.Profile.orderAddress,
    shippingAddress: state.Profile.shippingAddress,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingAddress)
);
