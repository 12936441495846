import ProductManagement from "./ProductManagement/ProductManagement/ProductManagement";
import AddPoduct from "./ProductManagement/AddPoduct/AddPoduct";
import ProductQnA from "./ProductManagement/ProductQnA/ProductQnA";
import OrderManagement from "./OrderManagement/OrderManagement";
import ProductReview from "./ReviewManagement/ProductReview/ProductReview";

import SellerReview from "./ReviewManagement/SellerReview/SellerReview";
import Profile from "./SellerAccount/Profile/Profile";
import AddressBook from "./SellerAccount/AddressBook/AddressBook";
import AccountSettings from "./SellerAccount/AccountSettings/AccountSettings";
import ReportManagement from "./ReportManagement/ReportManagement";

import ApplyTopBrand from "./MarketingManagement/ApplyTopBrand/ApplyTopBrand";
import ApplyFeatureProduct from "./MarketingManagement/ApplyFeatureProduct/ApplyFeatureProduct";
import ApplyBoostProduct from "./MarketingManagement/ApplyBoostProduct/ApplyBoostProduct";
import AccountGenaral from "./SellerAccount/Profile/Genaral/Genaral";
import AccountSellerLogo from "./SellerAccount/Profile/SellerLogo/SellerLogo";

import AccountInvoiceNumber from "./SellerAccount/Profile/Invoicenumber/Invoicenumber";
import AccountShipping from "./SellerAccount/Profile/Shipping/Shipping";
import AccountGenaralSellerAccount from "./SellerAccount/Profile/Genaral/SellerAccount/SellerAccount";
import AccountGenaralBusinessInfo from "./SellerAccount/Profile/Genaral/BusinessInfomation/BusinessInfomation";
import AccountGenaralBankAccount from "./SellerAccount/Profile/Genaral/BankAccount/BankAccount";

import AccountGenaralAddress from "./SellerAccount/Profile/Genaral/Address/Address";
import AccountFinanceAccountStatement from "./Finance/AccountStatement/AccountStatement";
import AccountFinanceOrderOverview from "./Finance/OrderOverview/OrderOverview";
import AccountFinanceTransactionOverview from "./Finance/TransactionOverview/TransactionOverview";
// import AccountMessageCenter from "../../modules/SellerDashboard/MessageManagement/MessageManagement";
import UserDetails from "./UserManagement/UserDetails";
import DashboardResolutionCaseList from "./DashboardResolutionCenter/DashboardResolutionCaseList";
import DashboardResolutionCenterOpenCase from "./DashboardResolutionCenter/DashboardResolutionCenterOpenCase";
// const UserManagement = lazy(() => import("./UserManagement/UserManagement"));
import UserManagement from "./UserManagement/UserManagement"
import DashboardResolutionCenter from "./DashboardResolutionCenter/DashboardResolutionCenter"
import DashboardSingleCase from "./DashboardResolutionCenter/DashboardSingleCase"

export default {
  //seller-dashboard
  ProductManagementView: ProductManagement,
  AddProductView: AddPoduct,
  ProductQnAView: ProductQnA,
  OrderManagementView: OrderManagement,
  UserManagementView: UserManagement,
  ProductReviewView: ProductReview,
  SellerReviewView: SellerReview,
  SellerProfileView: Profile,
  AddressBookView: AddressBook,
  SellerAccountSettingsView: AccountSettings,
  AccountGenaralView: AccountGenaral,
  AccountSellerLogoView: AccountSellerLogo,
  AccountInvoiceNumberView: AccountInvoiceNumber,
  AccountShippingView: AccountShipping,
  AccountGenaralSellerAccountView: AccountGenaralSellerAccount,
  AccountGenaralBusinessInfoView: AccountGenaralBusinessInfo,
  AccountGenaralBankAccountView: AccountGenaralBankAccount,
  AccountGenaralAddressView: AccountGenaralAddress,
  AccountFinanceAccountStatementView: AccountFinanceAccountStatement,
  AccountFinanceOrderOverviewView: AccountFinanceOrderOverview,
  AccountFinanceTransactionOverviewView: AccountFinanceTransactionOverview,
  ReportManagementView: ReportManagement,
  ApplyTopBrandView: ApplyTopBrand,
  ApplyFeatureProductView: ApplyFeatureProduct,
  ApplyBoostProductView: ApplyBoostProduct,
  UserDetailsView: UserDetails,
  DashboardResolutionCenterView: DashboardResolutionCenter,
  DashboardSingleCaseView: DashboardSingleCase,
  DashboardResolutionCaseListView: DashboardResolutionCaseList,
  DashboardResolutionCenterOpenCaseView: DashboardResolutionCenterOpenCase,
};
