import dumyPrdctImg from "assets/images/products/dummyProducts_2.gif";

export const feauredProducts = [
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "1",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "2",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "3",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "4",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "5",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "6",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "7",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "8",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "9",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "10",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "11",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
  {
    brand: "Spa Ceylon",
    category_id: "e1f55748-9478-11ea-b34d-65a05e401ea0",
    category_name: "Bath & Body",
    discount_percentage: 0,
    id: "12",
    images: [dumyPrdctImg],
    name: "Loading...",
    old_price: "0.00",
    price: "0.00",
    ratings_count: 0,
    ratings_value: 0,
    seller_id: "2b0db881-9477-11ea-baf2-c9e0bdf8a282",
    seller_name: "PennsylvaniaBay",
  },
];
