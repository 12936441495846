import React from "react";
import { Table } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { singleProductActions } from "../../../../modules/SingleProduct/ducks";
import { cartActions } from "../../../../modules/Cart/ducks";

import moment from "moment";

const columns = [
  {
    title: "Carrier",
    // dataIndex: "name",
    render: (data) => (
      <div>
        <img alt="Shipping" width="50px" src={data.carrier_image} />
        <div className="">{data.service}</div>
      </div>
    ),
  },
  {
    title: "Rate",
    width: "100px",
    render: (data) => <>$ {data.rate}</>,
  },
  {
    title: "Tracking",
    render: (data) => (
      <div>
        {data.tracking ? (
          // <i className="icon icon-check-circle-o" />
          <i className="fad fa-check fa-2x"></i>
        ) : (
          // <i className="icon icon-close-circle" />
          <i className="fad fa-times fa-2x"></i>
        )}
      </div>
    ),
  },
  {
    title: "Delivery Guarantee",
    render: (data) => (
      <div>
        {data.delivery_date_guaranteed ? (
          // <i className="icon icon-check-circle-o" />
          <i className="fad fa-check fa-2x"></i>
        ) : (
          // <i className="icon icon-close-circle" />
          <i className="fad fa-times fa-2x"></i>
        )}
      </div>
    ),
  },
  {
    title: "Estimated Delivery",
    render: (data) => (
      <div>
        <span> {data.delivery_days ? data.delivery_days + " days" : "-"}</span>
        <br />
        {data.delivery_date ? (
          <span>{moment(data.delivery_date).format("LL")}</span>
        ) : null}
      </div>
    ),
  },
];

class ShippingOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [0], // Check here to configure the CRM column
      initialized: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { shippingCost, selectedShippingMethod } = this.props;
    if (
      selectedShippingMethod &&
      !shippingCost.loading &&
      shippingCost.data &&
      (JSON.stringify(shippingCost.data) !==
        JSON.stringify(prevProps.shippingCost.data) ||
        JSON.stringify(selectedShippingMethod) !==
          JSON.stringify(prevProps.selectedShippingMethod))
    ) {
      let index = shippingCost.data.data.rates.findIndex(
        (x) => x.service === selectedShippingMethod.service
      );
      this.setState({
        selectedRowKeys: [index],
      });
    }
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const { shippingCost } = this.props;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
        const { path } = this.props.match;
        this.props.cartActions.selectShippingMethod({
          selectedMethod: selectedRows,
          isCart: path === "/cart" ? true : false,
          isCheckout: path === "/checkout" ? true : false,
          product_id: this.props.product_id,
        });
      },
    };

    return (
      <Table
        className="gx-table-responsive"
        loading={shippingCost.loading}
        rowSelection={{
          type: "radio",
          selections: true,
          ...rowSelection,
          selectedRowKeys: [selectedRowKeys[0]],
        }}
        columns={columns}
        dataSource={shippingCost.loading ? [] : shippingCost.data.data.rates}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    shippingCost: state.Cart.shippingCost,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    singleProductActions: bindActionCreators(singleProductActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingOptions)
);
