import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import SCBreadcrumb from "./SCBreadcrumb";
import SCHeader from "./SCHeader";
import "./SupportCenter.less";

class ProductListing extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <SCHeader title="Product Listing" />
          <SCBreadcrumb label={"Product Listing"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <h6> Product Listing</h6>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis
              quos sunt iste eveniet tempore aut porro animi ut, libero qui
              illum inventore explicabo nihil nam numquam pariatur expedita
              eaque voluptates.
            </p>

            <br />
          </div>
        </Widget>
      </div>
    );
  }
}

export default ProductListing;
