import React from "react";
import CKEditor from "react-ckeditor-component";

const RenderTextEditor = ({
  input,
  meta: { touched, error, submitFailed },
}) => {
  return (
    <div>
      {(touched || submitFailed) && error && (
        <span style={{ color: "#f5222d" }}>{error}</span>
      )}

      <CKEditor
        activeClass="p10"
        config={{ placeholder: "Placeholder text..." }}
        content={input.value}
        events={{
          change: (event) => input.onChange(event.editor.getData()),
        }}
      />
    </div>
  );
};

export default RenderTextEditor;
