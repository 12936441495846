import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cartActions } from "../Cart/ducks";
import AllProductsContainer from "../../components/Buyer/AllProducts/AllProductsContainer/AllProductsContainer";
import CartNotifyModal from "../../components/Buyer/Modal/CartNotifyModal/CartNotifyModal";
import AllProductsBannerCarousel from "../../components/Buyer/Carousel/AllProductsBannerCarousel/AllProductsBannerCarousel";
import { homeActions } from "../Home/ducks";

class AllProducts extends Component {
  componentDidMount() {
    this.props.homeActions.getAllCategories();
  }

  continueShoppingHandler = () => {
    this.props.cartActions.cartSettingsHandler({ displaySucess: false });
  };
  render() {
    const { location, history } = this.props;
    const { params } = this.props.match;
    return (
      <React.Fragment>
        <div>
          <AllProductsBannerCarousel params={params} />
          <div className="gx-main-content-wrapper" >
            {/* <div className="gx-container" style={{ paddingTop: 20 }}> */}
            <AllProductsContainer
              location={location}
              history={history}
              params={params}
            />
          </div>
        </div>

        <CartNotifyModal />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    cartSettings: state.Cart.cartSettings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    homeActions: bindActionCreators(homeActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllProducts)
);
