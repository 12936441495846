import React from "react";
import { Col, Row, Divider } from "antd";
import IntlMessages from "util/IntlMessages";
import ProductCard from "../../ProductCards/ProductCard/ProductCard";
import CardBox from "../../Card/CardBox/CardBox";
import "./SellerProductList.less";

const SellerProductList = ({ hits }) => {
  return (
    <Row className="slr-prdct-hgt">
      <Col span={24} className="slr-prdct-hgt-2">
        <CardBox styleName="mb-0">
          <Divider className="mt-0">
            <h3 className="nw-jr-fnt-clr">
              <IntlMessages id="productsGrid" />
            </h3>
          </Divider>
          <Row>
            {hits.map((product, index) => (
              <Col key={index} xl={6} md={8} sm={12} xs={24}>
                <ProductCard key={index} grid product={product} />
              </Col>
            ))}
          </Row>
        </CardBox>
      </Col>
    </Row>
  );
};

export default SellerProductList;
