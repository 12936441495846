import { createLogic } from "redux-logic";

import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";

const getCreditCard = createLogic({
  type: types.GET_CREDIT_CARD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.CREDIT_CARD)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getCreditCardSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getCreditCardFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addCreditCard = createLogic({
  type: types.ADD_CREDIT_CARD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.CREDIT_CARD, action.payload)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.addCreditCardSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.addCreditCardFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteCreditCard = createLogic({
  type: types.DELETE_CREDIT_CARD,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Delete(
      endPoints.CREDIT_CARD + `/${action.payload.payment_profile_id}`
    )
      .then((resp) => resp.data)

      .then((data) => {
        dispatch(
          actions.deleteCreditCardSuccess(action.payload.payment_profile_id)
        );
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.deleteCreditCardFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [getCreditCard, addCreditCard, deleteCreditCard];
