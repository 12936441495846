import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Input, Tooltip, Icon, Modal } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { authActions } from "../ducks";
import { makeField } from "../../../components/Common/Controls/MakeField";
import LoginLoader from "../../../util/Loader/LoginLoader/LoginLoader";
import {
  verifyPasswordResetCode,
  applyActionCode,
} from "../../../services/auth.service";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const PasswordField = makeField(Input.Password);
class VerifyResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("bckgrnd-img-2");

    const token = new URLSearchParams(this.props.location.search).get(
      "oobCode"
    );
    const mode = new URLSearchParams(this.props.location.search).get("mode");
    if (!token || !mode) alert("Error: Invalid Token");

    switch (mode) {
      case "resetPassword":
        verifyPasswordResetCode(token).catch((msg) => {
          Modal.error({
            title: "Code is invalid",
            content: (
              <div>
                <p>{msg}</p>
              </div>
            ),
            onOk: () => {
              this.props.history.push(`/login`);
            },
          });
        });
        break;
      case "recoverEmail":
        applyActionCode(token)
          .then((msg) => {
            alert(msg);
            this.props.history.push(`/login`);
          })
          .catch((msg) => {
            alert(msg);
            this.props.history.push(`/login`);
          });
        break;
      case "verifyEmail":
        applyActionCode(token)
          .then((msg) => {
            alert(msg);
            this.props.history.push(`/login`);
          })
          .catch((msg) => {
            alert(msg);
            this.props.history.push(`/login`);
          });
        break;
      default:
        alert("Error: Invalid Token");
        this.props.history.push(`/login`);
    }
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("bckgrnd-img-2");
  }

  handleHomeNavigator = () => {
    this.props.history.push("/");
  };

  handleSubmit = (values) => {
    const { password } = values;
    const token = new URLSearchParams(this.props.location.search).get(
      "oobCode"
    );

    this.props.authActions.verifyResetPassword({
      token: token,
      password: password,
    });
  };

  render() {
    const { handleSubmit, verifyResetPassword } = this.props;
    const mode = new URLSearchParams(this.props.location.search).get("mode");

    return mode === "resetPassword" ? (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container pdng-jr">
          <div className="gx-app-login-content mrgn-jr bckgrnd-lg">
            <div
              className="mb-3 login-logo"
              onClick={() => this.handleHomeNavigator()}
            >
              <img
                style={{ height: "45px" }}
                alt=""
                // src="/static/media/logo.fd441063.png"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
              />
            </div>
            <Form
              onSubmit={handleSubmit(this.handleSubmit)}
              className="gx-signin-form gx-form-row0 mt-4 log-placehldr"
            >
              <Field
                formItemLayout={formItemLayout}
                name="password"
                type="password"
                suffix={
                  <Tooltip title="Please type your new Password">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                component={PasswordField}
                placeholder="New Password"
                hasFeedback
                autocomplete="off"
              />
              <Field
                formItemLayout={formItemLayout}
                name="password_confirmation"
                type="password"
                suffix={
                  <Tooltip title="Please Confirm your new Password">
                    <Icon
                      type="info-circle"
                      style={{ color: "rgba(0,0,0,.45)" }}
                    />
                  </Tooltip>
                }
                component={PasswordField}
                placeholder="Confirm New Password"
                hasFeedback
                autocomplete="off"
              />
              <FormItem>
                <Button
                  style={{ width: "80%" }}
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </FormItem>
              <br />
            </Form>
            <Link to="/login">Return to Login</Link>
          </div>
          {verifyResetPassword.pending ? (
            <div className="gx-loader-view">
              <LoginLoader />
            </div>
          ) : null}
        </div>
      </div>
    ) : (
      "Verifying Please Wait.."
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password is required";
  }
  if (values.password && values.password.length < 6) {
    errors.password = "Password should be minimum 6 charaters";
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = "Confirm Password is required";
  }
  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = "Password is mismatch";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    verifyResetPassword: state.AuthStore.verifyResetPassword,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "verify_resetpassword_form",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(VerifyResetPassword))
);
