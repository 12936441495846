import React from "react";
import { Avatar } from "antd";

function ChatUserInfo({ currentUser }) {
  return (
    <div className="gx-chat-user-hd">
      <div className="gx-chat-avatar gx-mr-3">
        <div className="gx-status-pos">
          <Avatar
            id="avatar-button"
            src={
              currentUser
                ? currentUser.image
                : "https://via.placeholder.com/150x150"
            }
            className="gx-size-50"
            alt=""
          />
          {/* <span className={`gx-status gx-small  gx-online`} /> */}
        </div>
      </div>

      <div className="gx-module-user-info gx-flex-column gx-justify-content-center">
        <div className="gx-module-title">
          <h5 className="gx-mb-0">{currentUser && `${currentUser.name}`}</h5>
        </div>
        <div className="gx-module-user-detail">
          <span className="gx-text-grey gx-link">
            {" "}
            {currentUser && currentUser.email}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ChatUserInfo;
