import React, { Component } from "react";
import Widget from "../../../components/Common/Widget/Widget";
import { List } from "antd";
import { Link, withRouter } from "react-router-dom";

const data = [
  {
    title: "Company Information and Policy",
    route: "/customer-service/company-info-and-policy",
  },
  {
    title: "Accepted Payments Policy",
    route: "/customer-service/accepted-payments-policy",
  },
  {
    title: "Intellectual Property Rules",
    route: "/customer-service/intellectual-property-pules",
  },
  {
    title: "Real Estate Policy",
    route: "/customer-service/real-estate-policy",
  },
  {
    title: "Tax Policy",
    route: "/customer-service/tax-policy",
  },
  {
    title: "TB Identity Screening Policy",
    route: "/customer-service/identity-screening-policy",
  },
  {
    title: "Tradesbarn Fraud Policy",
    route: "/customer-service/fraud-policy",
  },
  {
    title: "Tradesbarn Online Advertising Policy",
    route: "/customer-service/online-advertising-policy",
  },
  {
    title: "Tradesbarn Seller Fees",
    route: "/customer-service/final-value-fees",
  },
  {
    title: "Privacy Policy",
    route: "/customer-service/privacy-policy",
  },
];
class CustomerServiceSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
    };
  }
  componentDidMount() {
    this.activeLinkHandler();
  }

  activeLinkHandler = () => {
    const { path } = this.props.match;
    switch (path.split("/")[2]) {
      case "company-info-and-policy":
        this.setState({
          current: "Company Information and Policy",
        });
        break;
      case "accepted-payments-policy":
        this.setState({
          current: "Accepted Payments Policy",
        });
        break;
      case "intellectual-property-pules":
        this.setState({
          current: "Intellectual Property Rules",
        });
        break;
      case "real-estate-policy":
        this.setState({
          current: "Real Estate Policy",
        });
        break;
      case "tax-policy":
        this.setState({
          current: "Tax Policy",
        });
        break;
      case "identity-screening-policy":
        this.setState({
          current: "TB Identity Screening Policy",
        });
        break;
      case "fraud-policy":
        this.setState({
          current: "Tradesbarn Fraud Policy",
        });
        break;
      case "online-advertising-policy":
        this.setState({
          current: "Tradesbarn Online Advertising Policy",
        });
        break;
      case "final-value-fees":
        this.setState({
          current: "Tradesbarn Seller Fees",
        });
        break;
      case "privacy-policy":
        this.setState({
          current: "Privacy Policy",
        });
        break;
      default:
        this.setState({
          default: true,
        });
    }
  };

  render() {
    const { current } = this.state;

    return (
      <div className="cus-srvs-crd-pstn">
        <div style={{ marginTop: "44px" }}>
          <Widget>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <Link
                          // onClick={() => this.activeLinkHandler(item.title)}
                          to={item.route}
                          style={{
                            color: current === item.title ? "#038fde" : "black",
                          }}
                        >
                          {item.title}
                        </Link>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          </Widget>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerServiceSidebar);
