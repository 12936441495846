import React from "react";
import { Avatar } from "antd";
import moment from "moment";

const ReceivedMessageCell = ({ conversation, user }) => {
  return (
    <div className="gx-chat-item">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={user.thumb}
        alt=""
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          {conversation.message_type === "Message" ? (
            <div className="gx-message">{conversation.message}</div>
          ) : conversation.message_type === "Image" ? (
            <div className="gx-message" style={{ maxWidth: "80%" }}>
              <img alt="img-ReceivedMessageCell" src={conversation.url} />
            </div>
          ) : (
            <div className="gx-message" style={{ maxWidth: "80%" }}>
              <a href={`/products/${conversation.product.id}`}>
                <img
                  alt="img-ReceivedMessageCell"
                  src={conversation.product.image}
                />
                <p>{conversation.product.name}</p>
              </a>
            </div>
          )}
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            {moment(conversation.sentAt).format("llll")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivedMessageCell;
