import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Divider, Col, Row } from "antd";
import "./TopBar.less";
import { userRoles } from "../../constants/Utilities";
import { GetCurrentUser } from "../../services/auth.service";
import { TRADESBARN } from "../../constants";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userJson: null,
      role: null,
    };
  }
  componentDidMount() {
    // getter
    var user = localStorage.getItem("user");
    this.getUserToken();
    if (user) {
      var userJson = JSON.parse(user);
      var role = userJson && userJson.role;
      this.setState({ userJson, role });
    }
  }

  getUserToken = async () => {
    let currentUser = await GetCurrentUser();
    this.setState({ currentUser });
    // return currentUser
  };

  render() {
    const { role, currentUser } = this.state;
    return (
      <div className="site-header navbar-bckg nav-sticky-pstn">
        <div className="gx-container">
          <Row style={{ height: "10px" }}>
            <Col className="ml-auto">
              <div
                style={{
                  paddingLeft: "1.5rem",
                  paddingBottom: "0rem",
                  paddingTop: "0rem",
                }}
                className="mgnnnn"
              >
                <div>
                  <p
                    style={{ color: "black", fontSize: "13px" }}
                    className="fnt-szzz"
                  >
                    {currentUser ? (
                      `Hi ${currentUser.displayName && currentUser.displayName}`
                    ) : (
                      <>
                        Hi <Link to="/login">Login</Link> or{" "}
                        <Link to="/login">Register</Link>
                      </>
                    )}{" "}
                  </p>
                </div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  paddingLeft: "1.5rem",
                  paddingBottom: "0rem",
                  paddingTop: "0rem",
                }}
                className="pdddd mgnnnn"
              >
                <Link to={`/mobileApp`}>
                  <p
                    style={{ color: "black", fontSize: "13px" }}
                    className="fnt-szzz"
                  >
                    App{" "}
                  </p>
                </Link>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  paddingLeft: "1.5rem",
                  paddingBottom: "0rem",
                  paddingTop: "0rem",
                }}
                className="pdddd mgnnnn"
              >
                <Link
                  to={
                    role === userRoles.Buyer ? "/account/seller" : "/dashboard"
                  }
                >
                  <p
                    style={{ color: "black", fontSize: "13px" }}
                    className="fnt-szzz"
                  >
                    {process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
                      ? "Sell on Tradesbarn"
                      : "Seller Dashboard"}
                  </p>
                </Link>
              </div>
            </Col>
            <Col className="mr-4">
              <div
                style={{
                  paddingLeft: "1.5rem",
                  paddingBottom: "0rem",
                  paddingTop: "0rem",
                }}
                className="pdddd mgnnnn"
              >
                <Link to="/help-and-contact">
                  <p
                    style={{ color: "black", fontSize: "13px" }}
                    className="fnt-szzz"
                  >
                    Help & Contact
                  </p>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <Divider style={{ marginBottom: "0px" }} />
      </div>
    );
  }
}

export default withRouter(TopBar);
