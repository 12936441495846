import React from "react";
import {
  Col,
  // Button
} from "antd";
import Widget from "../Widget/Widget";
import "./PopularCategoryCard.less";
import history from "../../../../_helpers/history";

const PopularCategoryCard = ({ categoryName, image, shortName, isLoading }) => {
  return (
    <Col xl={4} lg={4} md={8} sm={8} xs={12} className="mQ-mgn-jr">
      <Widget styleName="gx-card-full gx-text-center bdr-jr populr-cte-hvr mb-0">
        <div
          className="gx-pt-2 gx-px-2 mouse-pointer-jr"
          onClick={() =>
            isLoading ? null : history.push(`/search/${categoryName}`)
          }
        >
          <div className="gx-separator gx-bg-primary popult-cte-hvr-color-bdr" />
          <h6
            className="gx-mb-4 h6-jr gx-text-primary popult-cte-hvr-color"
            style={{ fontWeight: "600" }}
          >
            {shortName}
          </h6>
          <img
            className="gx-mb-4 gx-rounded-circle gx-img-fluid"
            // src={"https://via.placeholder.com/120x120"}
            // src={require(image)}
            // src={require("assets/images/gridList/bike.jpeg")}
            src={image}
            alt="spoons"
          />
        </div>
        {/* <Button
          type="primary"
          className="gx-mt-sm-4 gx-fs-sm gx-btn-block gx-mb-0 gx-text-uppercase gx-border-radius-top-left-0 gx-border-radius-top-right-0"
          size="large"
          htmlType="submit"
          block
        >
          learn more
        </Button> */}
      </Widget>
    </Col>
  );
};

export default PopularCategoryCard;
