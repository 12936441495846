import React from "react";
import Slider from "react-slick";
import "./ProductGallery.less";
import shpmth from "../../../../assets/img/sgp.jpg";
import { Card } from "antd";
import BuyerProtection from "../BuyerProtection/BuyerProtection";
import { IS_ENABLED_BUYER_PROTECTION } from "../../../../constants";

class ProductGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { product } = this.props;
    const { data } = product.data;
    return (
      <div className="d-flex flex-column" style={{ height: "100%" }}>
        <div
          className="single-pro gx-card mb-3"
          // style={{ border: "1px solid #ff6900", overflow: "hidden" }}
          style={{ overflow: "hidden", width: "100%" }}
        >
          <div>
            <Slider
              asNavFor={this.state.nav2}
              infinite={false}
              ref={(slider) => (this.slider1 = slider)}
              className="slider-catch-1"
            >
              {!product.loading && data && data.images
                ? data.images.map((image, i) => {
                    return (
                      <div className="img-wrapper">
                        <img
                          src={image}
                          key={i}
                          alt={"productGallery"}
                          className="hover-zoom"
                        />
                      </div>
                    );
                  })
                : null}
            </Slider>
            <Slider
              asNavFor={this.state.nav1}
              ref={(slider) => (this.slider2 = slider)}
              slidesToShow={5}
              swipeToSlide={true}
              infinite={false}
              focusOnSelect={true}
              arrows={false}
              className="slider-catch-2"
            >
              {!product.loading && data && data.images
                ? data.images.map((image, i) => {
                    return (
                      <div className="">
                        <img src={image} key={i} alt={"productGallery"} />
                      </div>
                    );
                  })
                : null}
            </Slider>
          </div>
        </div>

        <Card className="gx-card mb-0 flex-fill add-mrgin-mob">
          {IS_ENABLED_BUYER_PROTECTION ? (
            <BuyerProtection />
          ) : (
            <div className="">
              <h6 className="">Payment Methods :</h6>
              <img src={shpmth} width="80%" alt="" />
            </div>
          )}
        </Card>
      </div>
    );
  }
}
export default ProductGallery;
