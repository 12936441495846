import React from "react";
import "./TopBrandsCard.less";

const TopBrandsCard = ({ data }) => {
  const { logo } = data;
  return (
    <React.Fragment>
      <div className=" logo-get-cen">
        <img
          className="p-2 mb-1 img-grayscl"
          style={{
            maxHeight: "200px",
            display: "block"
          }}
          alt="Top Brand"
          src={logo}
        />
      </div>
    </React.Fragment>
  );
};
export default TopBrandsCard;
