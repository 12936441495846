import React, { Component } from "react";
import { Col, Row, Divider } from "antd";
import IntlMessages from "util/IntlMessages";
import HotDealCard from "../Card/HotDealCard/HotDealCard";
import CardBox from "../Card/CardBox/CardBox";
import { hotDeals } from "../../../DummyData/HotDeals";
import "./HotDeals.less";

class HotDeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  componentDidMount() {}
  render() {
    const { data } = this.props;
    return (
      <Row>
        <Col span={24} className="tb-popular-categories-container">
          <CardBox>
            <Divider className="mt-0 mb-2">
              <h4 className="nw-jr-fnt-clr">
                <IntlMessages id="hotDeals" />
              </h4>
            </Divider>
            <Row>
              {data.loading
                ? hotDeals.map((topDeal, i) => {
                    return (
                      <Col
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        xs={24}
                        className="mQ-pdng-jr"
                        key={topDeal.id}
                      >
                        <HotDealCard data={topDeal} isLoading={true} />
                      </Col>
                    );
                  })
                : data.data.map((topDeal, i) => {
                    return (
                      <Col
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        xs={24}
                        className="mQ-pdng-jr"
                        key={topDeal.id}
                      >
                        <HotDealCard data={topDeal} isLoading={false} />
                      </Col>
                    );
                  })}
            </Row>
          </CardBox>
        </Col>
      </Row>
    );
  }
}

export default HotDeals;
