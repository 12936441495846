import React from "react";
import "./CategorySelector.less";
import { Select } from "antd";

const { Option } = Select;

class CategorySelector extends React.Component {
  state = {
    value: "All Categories",
  };
  onChange = (value) => {
    this.props.onSelectCategory(value);
    this.setState({ value });
  };

  render() {
    const { allCategories } = this.props;
    return (
      <Select
        defaultValue="All Categories"
        style={{ width: 150 }}
        onChange={this.onChange}
      >
        <Option value="All Categories" key="0">
          All Categories
        </Option>
        {allCategories &&
          allCategories.data &&
          allCategories.data.map((data, index) => (
            <Option value={data.name} key={index + 1}>
              {" "}
              {data.name}
            </Option>
          ))}
      </Select>
    );
  }
}

export default CategorySelector;
