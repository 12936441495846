import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class FraudPolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Fraud Policy" />
          <CSBreadcrumb label={"Fraud Policy"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              Setting clear buyer expectations and then meeting or exceeding
              them is a great way to make your buyer happy and ensure a smooth
              transaction. Some of the simplest things you can do are to provide
              accurate and consistent details about your items and to be clear
              and specific about the terms and conditions of the sale. You
              should also make every effort to provide excellent customer
              service from start to finish, including:
            </p>
            <ul>
              <li>
                <p>Charging reasonable shipping and handling costs</p>
              </li>
              <li>
                <p>
                  Specifying your handling time and return policy in your
                  listing
                </p>
              </li>
              <li>
                <p>Responding to buyers' questions promptly</p>
              </li>
              <li>
                <p>Being professional throughout the transaction</p>
              </li>
              <li>
                <p>
                  Making sure the item is delivered to the buyer as described in
                  your listing
                </p>
              </li>
              <li>
                <p>
                  Frequently reviewing and updating listings to make sure all
                  information such as inventory status and item condition-such
                  as inventory status and item condition is accurate and up to
                  date.
                </p>
              </li>
            </ul>

            <p>
              To help you meet our seller performance standards, we have some
              basic requirements all sellers must meet. We also have tips on how
              you can meet and exceed buyers' expectations.
            </p>
            <br />
            <p>
              <u>
                <b>What are the guidelines?</b>
              </u>
            </p>
            <p>
              Make sure you follow these guidelines. If you don't, you may be
              subject to a range of actions. We may limit, restrict, or suspend
              you from buying, selling, or using site features. All of your
              listings may be removed, displayed lower or not shown in search
              results, without refunding any or all applicable fees. You may
              also forfeit special account status and any discounts. You may be
              subject to the application of fees and recovery of our expenses in
              policy monitoring and enforcement.
            </p>

            <p>
              <i>Shipping service</i>
            </p>
            <p>
              Buyers expect their items to be delivered on time, especially when
              they pay additional fees for expedited shipping services. Using a
              slower shipping service than the one selected by the buyer during
              checkout may result in the item being delivered later than
              expected, and an increase in claims for items not received.
            </p>

            <p>What to do</p>
            <ul>
              <li>
                <p>
                  Offer shipping service options in your listings only after
                  reviewing their costs and delivery speeds
                </p>
              </li>
              <li>
                <p>
                  Ship items using shipping service options with features that
                  meet or exceed those the buyer selected
                </p>
              </li>
            </ul>

            <p>What not to do</p>
            <ul>
              <li>
                <p>
                  Use shipping service options with features that do not meet
                  those selected by the buyer. For example, using "USPS Ground"
                  with a maximum delivery estimate of 7 days when the buyer
                  selected "USPS Priority Mail" with a delivery estimate of 3
                  days.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Shipping and handling costs</b>
              </u>
            </p>
            <p>
              Be sure to specify shipping costs and related service charges in
              your listings.
            </p>
            <br />
            <p>
              <u>
                <b>What to charge</b>
              </u>
            </p>
            <p>
              <i>Actual shipping cost:</i>
            </p>
            <ul>
              <li>
                <p>
                  This is the amount for shipping the item. It should be what
                  you paid the carrier.
                </p>
              </li>
            </ul>

            <p>
              <i>Handling cost:</i>
            </p>
            <ul>
              <li>
                <p>
                  This can include the cost of packaging materials and insurance
                  cost, if any.
                </p>
              </li>
            </ul>

            <p>
              <i>Delivery confirmation and extra services:</i>
            </p>
            <ul>
              <li>
                <p>
                  If you choose to use these options, you must add them to your
                  shipping and handling costs, and you can only charge what they
                  actually cost. Examples of services include:
                </p>
                <ul>
                  <li>
                    <p>Certificate of Mailing</p>
                  </li>
                  <li>
                    <p>Certified Mail</p>
                  </li>
                  <li>
                    <p>
                      Delivery Confirmation - free with Priority Mail and
                      discounted with some other services when you print
                      shipping labels on Tradesbarn LLC
                    </p>
                  </li>
                  <li>
                    <p>Registered Mail</p>
                  </li>
                  <li>
                    <p>Restricted Delivery</p>
                  </li>
                  <li>
                    <p>Return Receipt</p>
                  </li>
                  <li>
                    <p>Signature Confirmation</p>
                  </li>
                  <li>
                    <p>Special Handling</p>
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              <i>Tax and government-imposed fees:</i>
            </p>
            <ul>
              <li>
                <p>
                  Only applicable federal, state, country, city, Value Added Tax
                  (VAT), or equivalent taxes may be charged.
                </p>
              </li>
            </ul>

            <p>
              If your shipping and handling costs are higher than average,
              consider explaining in your listing what the shipping and handling
              costs include. We recommend that you provide tracking information.
              Using tracking information, delivery confirmation, or signature
              confirmation can help protect you if a buyer reports that they
              didn't receive an item.
            </p>
            <br />
            <p>
              <u>
                <b>Categories with maximum shipping costs</b>
              </u>
            </p>
            <p>
              We set maximum shipping costs in some categories. You can only
              charge more than the maximum shipping cost if you use calculated
              shipping and enter an item's actual size and weight with the
              packing materials. When using calculated shipping, make sure the
              calculated cost isn't higher than the actual shipping cost. If so,
              it's considered excessive shipping, which isn't allowed on
              Tradesbarn LLC.
            </p>
            <br />
            <p>
              <u>
                <b>Free shipping</b>
              </u>
            </p>
            <p>
              You can offer free shipping to select or all destinations. For a
              destination with free shipping, no other fees related to shipping,
              handling, or packaging can be charged. You're not allowed to use a
              shipping method that is slower than the slowest one indicated in
              the listing. If you offer free shipping in your listing and we can
              confirm the buyer didn't pay for shipping you will automatically
              receive a rating on your shipping and handling charges detailed
              seller rating.
            </p>
            <br />
            <p>
              <u>
                <b>Shipping and handling time</b>
              </u>
            </p>
            <p>Buyers want to know when they can expect to get their items.</p>
            <br />
            <p>
              <u>What to do</u>
            </p>
            <ul>
              <li>
                <p>
                  Specify in your listing clearly and accurately when the item
                  will be shipped.
                </p>
              </li>
              <li>
                <p>
                  Ship items within the handling time you state in your listing.
                </p>
              </li>
              <li>
                <p>
                  Use tracking, delivery confirmation, or signature
                  confirmation. Using delivery confirmation can help protect you
                  if a buyer claims an item wasn't received.
                </p>
              </li>
              <li>
                <p>
                  We require signature confirmation for transactions of $750 or
                  more. Having signature confirmation can help protect you if a
                  buyer reports that they didn't receive an item.
                </p>
              </li>
              <li>
                <p>
                  You're required to select the shipping option that best
                  matches the shipping service that you offer in the listing.
                  This helps set buyer expectations about when the item will be
                  delivered.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>What not to do</u>
            </p>
            <ul>
              <li>
                <p>
                  If TB doesn't make your funds available immediately from
                  payments for an item you sold, you're not allowed to hold
                  shipment until the funds are released.
                </p>
              </li>
            </ul>
            <p>
              Holding shipment may affect your seller performance standing or
              defect rate and buyer satisfaction, which increases the chances of
              future TB payments being held.
            </p>
            <br />
            <p>
              <u>
                <b>Listing formats without handling time</b>
              </u>
            </p>
            <p>Classified Ads</p>
            <p>Real Estate Ad format</p>
            <p>Categories that don't require handling time</p>
            <br />
            <p>
              <u>
                <b>All Motor Vehicle categories</b>
              </u>
            </p>
            <p>Real Estate</p>
            <p>Specialty Services / Bulk</p>
            <p>Special consideration for specific categories</p>

            <p>
              In certain categories we may require tracking information,
              specific handling times, or both. This is to enhance the buying
              experience and to meet industry standards for some items.
            </p>
            <p>TB will enhance your Seller Status If:</p>
            <ul>
              <li>
                <p>
                  You specify either same business day or 1 business day
                  handling.
                </p>
              </li>
              <li>
                <p>
                  You upload US Postal Service, UPS, or FedEx supported tracking
                  information by the end of the next business day (Pacific Time)
                  after the buyer's payment clears. For same business day
                  handling, upload tracking within 1 business day. A business
                  day is a normal weekday. Saturdays, Sundays, and major
                  holidays aren't business days.
                </p>
              </li>
              <li>
                <p>
                  Tracking data must show the package as having a status of
                  "delivered" with a delivery date within 4 days of payment
                  clear.
                </p>
              </li>
            </ul>
            <p>
              In some instances, there may be a delay in processing your
              shipping details, and buyers may be able to leave you a detailed
              seller rating for shipping time.
            </p>
            <br />
            <p>
              <b>
                <u>Terms and conditions</u>
              </b>
            </p>
            <p>
              It's important that you clearly specify the terms and conditions
              of the sale in your listing so that buyers know what to expect.
            </p>

            <p>
              <u>What to do</u>
            </p>
            <p>
              You're required to include the following details in your listing:
            </p>
            <ul>
              <li>
                <p>Forms of payment you accept</p>
              </li>
              <li>
                <p>Return policy</p>
              </li>
              <li>
                <p>Shipping method, costs, and other information</p>
              </li>
              <li>
                <p>
                  Taxes and any applicable government imposed fees (such as
                  electronic waste disposal fees)
                </p>
              </li>
              <li>
                <p>Terms of the transaction</p>
              </li>
            </ul>
            <p>
              You're required to meet the expectations you've set in your
              listing:
            </p>
            <p>
              Ensure the safe delivery of the item within the timeframe stated
              in your listing Comply with the Presale listings policy where
              applicable
            </p>
            <p>
              Items you list must be in your inventory or you must have an
              existing agreement with a third party to fulfill the delivery of
              the item under the terms of your listing
            </p>
            <br />
            <p>
              <u>
                <b>Tracking</b>
              </u>
            </p>
            <p>
              Providing tracking details for items you've sold is an industry
              standard and something that your buyers expect. Tracking data lets
              buyers know where their item is throughout the shipping process,
              and can be critical in cases where the buyer claims they didn't
              receive an item.
            </p>

            <p>
              <i>More about uploading tracking information</i>.{" "}
            </p>
            <p>
              <u>What to do</u>
            </p>
            <ul>
              <li>
                <p>Upload accurate tracking details</p>
              </li>
              <li>
                <p>
                  Upload tracking details within your specified handling time
                </p>
              </li>
              <li>
                <p>
                  Mark the item as 'Shipped' in My Tradesbarn LLC or Seller Hub
                  once it has been sent<u> What not to do</u>
                </p>
              </li>
              <li>
                <p>
                  Upload information other than valid tracking in the tracking
                  field, including tracking not associated with the transaction
                </p>
              </li>
              <li>
                <p>
                  Upload tracking information later than the handling time
                  window promised in your listing
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Item location misrepresentation</b>
              </u>
            </p>
            <p>
              When a buyer purchases an item, they expect it to be shipped from
              the item location included in the listing. Including false,
              inaccurate, vague, or misleading item location information may
              lead to confusion around delivery time and shipping costs.
            </p>

            <p>
              <u>What to do</u>
            </p>
            <ul>
              <li>
                <p>
                  Include accurate item location descriptions. The city and
                  state, or city and country should match appropriately. For
                  example: Hong Kong, China Sydney, Australia, Omaha, Nebraska,
                  United States
                </p>
              </li>
            </ul>

            <p>
              <u>What not to do</u>
            </p>
            <ul>
              <li>
                <p>
                  Provide incorrect or inaccurate item location State 'U.S.'
                  when the item is actually being shipped from China
                </p>
              </li>
              <li>
                <p>
                  Include inaccurate, vague, or misleading item location
                  descriptions, such as: Beijing, United Kingdom, Hong Kong,
                  United Kingdom Worldwide, Singapore Direct from manufacturer
                  to, United States Somewhere in Florida, United States
                </p>
              </li>
              <li>
                <p>
                  Include item location information in the item description that
                  doesn't match the information in the item location field
                </p>
              </li>
              <li>
                <p>
                  Include inconsistent information in your return policy around
                  the item location which may result in higher return costs or
                  confusion for buyers
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Item description</b>
              </u>
            </p>
            <p>
              The listing page is where buyers get most of their information
              about an item. Information in the listing helps buyers decide what
              to buy and know what to expect when they receive the item. It's
              important to make sure that the listing is only used to describe
              the item for sale and to communicate the terms of the sale in a
              professional way.
            </p>

            <p>
              <i>You're required to:</i>
            </p>
            <ul>
              <li>
                <p>Specify the condition of the item</p>
              </li>
            </ul>
            <p>
              Describe any defects or flaws in the item this helps avoid
              problems or buyer dissatisfaction
            </p>
            <p>
              Note: When selling a used, refurbished, or flawed item, you must
              include photos of the actual item for sale instead of a stock
              photo.
            </p>
            <br />
            <p>
              <u>
                <b>Cancel transactions</b>
              </u>
            </p>
            <p>
              Occasionally, you may need to cancel a transaction because the
              item is broken, you made a mistake in your listing, or the item is
              out of stock. In these cases, you must first contact the buyer and
              let them know that you are canceling the transaction and the
              reason why. Make sure you use the correct process in My Tradesbarn
              or Seller Hub to cancel these transactions.
            </p>

            <p>
              <u>What to do</u>
            </p>
            <ul>
              <li>
                <p>
                  Inform the buyer that you are canceling the transaction with
                  the reason
                </p>
              </li>
              <li>
                <p>Cancel the transaction up to 30 days after the sale</p>
              </li>
              <li>
                <p>
                  Use the correct process in My Tradesbarn or Seller Hub to
                  cancel the transaction:
                </p>
              </li>
              <li>
                <p>
                  Go to My Tradesbarn&gt; Sold and locate the item. From the
                  Actions drop-down menu, select End Choose a reason for the
                  cancellation and follow the on-screen instructions
                </p>
              </li>
            </ul>
            <p>
              <u>What not to do</u>
            </p>
            <ul>
              <li>
                <p>
                  Not informing the buyer that you are canceling the transaction
                </p>
              </li>
              <li>
                <p>
                  Canceling the transaction more than 30 days after the sale
                </p>
              </li>
              <li>
                <p>
                  Not using the correct process in My Tradesbarn LLC or Seller
                  Hub to cancel the transaction
                </p>
              </li>
              <li>
                <p>
                  Select the wrong cancelation reason in order to avoid a defect
                  on your account
                </p>
              </li>
            </ul>

            <p>
              <u>
                <b>Communication</b>
              </u>
            </p>
            <p>
              Do whatever you can to provide excellent customer service. Meeting
              or exceeding buyers' expectations can help you improve your
              performance on Tradesbarn LLC.
            </p>

            <p>
              <u>What to do</u>
            </p>
            <ul>
              <li>
                <p>
                  Respond promptly to any questions during the buying or buying
                  process, as well as after the item has been purchased
                </p>
              </li>
              <li>
                <p>Communicate professionally, including emails</p>
              </li>
              <li>
                <p>Be responsive to any buyer concerns or problems</p>
              </li>
            </ul>
            <p>
              <u>What not to do</u>
            </p>
            <ul>
              <li>
                <p>
                  You're not allowed to use profane or offensive language with
                  anyone on Tradesbarn LLC
                </p>
              </li>
              <li>
                <p>
                  You're not allowed to send anyone on Tradesbarn LLC
                  inappropriate images including nudity, profanity or other
                  general content not related to a Tradesbarn LLC listing
                </p>
              </li>
            </ul>
            <p>
              Most often, transactions without additional communication are a
              sign of great service, and you shouldn't receive low detailed
              seller ratings for communication in these situations.
            </p>

            <p>
              To give you credit for transactions where a buyer doesn't need to
              contact you, you'll automatically receive a communication detailed
              seller rating if:
            </p>
            <ul>
              <li>
                <p>
                  You specify either same business day or one business day
                  handling and upload tracking information within one business
                  day
                </p>
              </li>
              <li>
                <p>
                  There are no buyer- or seller-initiated communications in
                  Tradesbarn LLC Messages
                </p>
              </li>
              <li>
                <p>
                  There are no requests for contact information between you and
                  the buyer
                </p>
              </li>
              <li>
                <p>
                  There are no refund requests or PayPal Purchase Protection
                  cases open for the transaction, and the buyer hasn't reported
                  that the item wasn't received.
                </p>
              </li>
            </ul>
            <p>
              Invoices and invoice requests even if they contain messages added
              by the buyer or seller don't affect automatic detailed seller
              ratings for communication. You'll still qualify for an automatic
              communication detailed seller rating if you or your buyers send
              these types of communications.
            </p>

            <p>
              If Tradesbarn LLC shortens the delivery estimate shown to your
              buyer, we automatically remove any defects related to shipping
              speed when you upload tracking within your stated handling time
              and your item arrives on or before the last day of the standard
              delivery timeframe (you’re stated handling time plus the carrier's
              published timeframe). Communication Communication between a buyer
              and seller isn't undesirable. If you exchange messages – and the
              buyer is satisfied with your response – the buyer may give you
              five stars for communication based on their interaction with you
            </p>
            <br />
            <p>
              <u>
                <b>Returns</b>
              </u>
            </p>
            <p>
              You aren't required to accept returns, but if you do, you must
              honor your stated returns policy. When you choose to accept
              returns, a buyer can return an item for any reason, including if
              they change their mind about a purchase. As long as the return
              meets your stated return requirements, you are required to honor
              the return.
            </p>

            <p>
              <u>What to do</u>
            </p>
            <ul>
              <li>
                <p>
                  If you don't want to accept returns, make sure that both the
                  International and Domestic returns check boxes are unselected
                </p>
              </li>
              <li>
                <p>
                  If you accept returns, you must clearly state the criteria
                  under which you will accept a return. This may include (but
                  isn't limited to):
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  The time period in which a buyer must notify you about a
                  return
                </p>
              </li>
              <li>
                <p>Who pays for return shipping?</p>
              </li>
              <li>
                <p>
                  How the refund is issued (money back, replacement, or
                  exchange)
                </p>
              </li>
            </ul>
            <p>
              Remember, buyers who purchase from you are entering in to a
              contract with you based on the details you included in your
              listing, including what you stated in your returns policy.
              Therefore, you may not add to or alter your returns criteria once
              an item has been purchased. Even if you specify that you don't
              accept returns, a buyer may be able to open a Tradesbarn LLC Money
              Back Guarantee case for eligible items if they didn't receive an
              item or the item doesn't match the listing description. Learn more
              about Seller performance standards.
            </p>
            <br />
            <p>
              <u>
                <b>Photos</b>
              </u>
            </p>
            <p>
              Along with the description, photos are the most important part of
              a listing. Photos help buyers to see exactly what they're getting
              as well as specific details they may be looking for. When selling
              used items, photos are especially important because they let the
              buyer see the actual condition of the item along with other
              details like color.
            </p>
            <br />
            <p>
              <u>
                <b>Pricing promotions terms and conditions</b>
              </u>
            </p>
            <p>
              When you place an item on sale and indicate an original price, you
              are required to follow rules concerning the original price and
              tools that you use to create a sale or promotion. Learn more about
              the pricing and promotions terms and conditions.
            </p>
            <br />
            <p>
              <u>
                <b>Independent pricing</b>
              </u>
            </p>
            <p>
              To maintain a competitive marketplace, Tradesbarn LLC requires
              that you price your items independently from other sellers. You
              may take into account the pricing of other sellers that you
              observe on the Tradesbarn LLC site, but you may not contact
              another seller to coordinate pricing in any way.
            </p>
            <br />
            <p>
              <u>
                <b>Other rules for sellers</b>
              </u>
            </p>
            <p>
              You're responsible for reviewing and understanding our rules for
              sellers, as well as all relevant laws and regulations in your User
              Agreement. Knowing our policies before you list an item can lead
              to smoother, more successful transactions, and it can help you
              avoid breaking rules by mistake.
            </p>
            <br />
            <p>
              <u>
                <b>Invalid email policy</b>
              </u>
            </p>
            <p>
              A member's account must always have a valid, working email address
              so the user can be contacted by other Tradesbarn members and by
              Tradesbarn. If a spam filter prevents messages from Tradesbarn or
              its members from reaching an account, the account is considered to
              have an invalid email address. Members need to take steps to
              ensure their account is valid and working.
            </p>
            <p>
              Violations of this policy may result in a range of actions,
              including:
            </p>
            <ul>
              <li>
                <p>Listing cancellation</p>
              </li>
              <li>
                <p>Limits on account privileges</p>
              </li>
              <li>
                <p>Account suspension</p>
              </li>
              <li>
                <p>Forfeiture of Tradesbarn fees on cancelled listings</p>
              </li>
              <li>
                <p>Loss of Seller status</p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Additional information</b>
              </u>
            </p>

            <p>
              Members who get an error message when they send a message to
              another member's email address are most likely contacting an
              invalid or non-working address. Members are encouraged to report
              this by using the Customer Support link, and including the error
              message in their report.
            </p>
            <br />
            <p>
              <u>
                <b>Why does Tradesbarn have this policy?</b>
              </u>
            </p>
            <p>
              Tradesbarn's success is dependent on open communication between
              members as well as between Tradesbarn and its members. Members'
              valid, working email addresses are essential to that
              communication.
            </p>
          </div>
        </Widget>
      </div>
    );
  }
}

export default FraudPolicy;
