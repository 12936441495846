import { createLogic } from "redux-logic";

import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";

const getSellerStatistics = createLogic({
  type: types.GET_SELLER_STATISTICS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.DASHBOARD_SELLER_STATISTICS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerStatisticsSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getSellerStatisticsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getSellerRevenue = createLogic({
  type: types.GET_SELLER_REVENUE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.DASHBOARD_SELLER_REVENUE)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerRevenueSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getSellerRevenueFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getSellerPopularProducts = createLogic({
  type: types.GET_SELLER_POPULAR_PRODUCTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.DASHBOARD_SELLER_POPULAR_PRODUCT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerPopularProductsSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getSellerPopularProductsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getSellerOrders = createLogic({
  type: types.GET_SELLER_ORDERS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.DASHBOARD_SELLER_ORDERS)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerOrdersSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getSellerOrdersFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getSellerActivities = createLogic({
  type: types.GET_SELLER_ACTIVITIES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.DASHBOARD_SELLER_ACTIVITIES)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerActivitiesSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getSellerActivitiesFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [
  getSellerStatistics,
  getSellerRevenue,
  getSellerPopularProducts,
  getSellerOrders,
  getSellerActivities,
];
