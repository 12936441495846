export const allCountriesJson = [
  {
    name: "Afghanistan",
    alpha_2: "AF",
    alpha_3: "AFG",
    country_code: "004",
    iso_3166_2: "ISO 3166-2:AF",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+93",
    flag: "https://www.countryflags.io/AF/flat/64.png",
  },
  {
    name: "Åland Islands",
    alpha_2: "AX",
    alpha_3: "ALA",
    country_code: "248",
    iso_3166_2: "ISO 3166-2:AX",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+358",
    flag: "https://www.countryflags.io/AX/flat/64.png",
  },
  {
    name: "Albania",
    alpha_2: "AL",
    alpha_3: "ALB",
    country_code: "008",
    iso_3166_2: "ISO 3166-2:AL",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+355",
    flag: "https://www.countryflags.io/AL/flat/64.png",
  },
  {
    name: "Algeria",
    alpha_2: "DZ",
    alpha_3: "DZA",
    country_code: "012",
    iso_3166_2: "ISO 3166-2:DZ",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+213",
    flag: "https://www.countryflags.io/DZ/flat/64.png",
  },
  {
    name: "American Samoa",
    alpha_2: "AS",
    alpha_3: "ASM",
    country_code: "016",
    iso_3166_2: "ISO 3166-2:AS",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+1684",
    flag: "https://www.countryflags.io/AS/flat/64.png",
  },
  {
    name: "Andorra",
    alpha_2: "AD",
    alpha_3: "AND",
    country_code: "020",
    iso_3166_2: "ISO 3166-2:AD",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+376",
    flag: "https://www.countryflags.io/AD/flat/64.png",
  },
  {
    name: "Angola",
    alpha_2: "AO",
    alpha_3: "AGO",
    country_code: "024",
    iso_3166_2: "ISO 3166-2:AO",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+244",
    flag: "https://www.countryflags.io/AO/flat/64.png",
  },
  {
    name: "Anguilla",
    alpha_2: "AI",
    alpha_3: "AIA",
    country_code: "660",
    iso_3166_2: "ISO 3166-2:AI",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1264",
    flag: "https://www.countryflags.io/AI/flat/64.png",
  },
  {
    name: "Antarctica",
    alpha_2: "AQ",
    alpha_3: "ATA",
    country_code: "010",
    iso_3166_2: "ISO 3166-2:AQ",
    region: "",
    sub_region: "",
    intermediate_region: "",
    region_code: "",
    sub_region_code: "",
    intermediate_region_code: "",
    dialCode: "+672",
    flag: "https://www.countryflags.io/AQ/flat/64.png",
  },
  {
    name: "Antigua and Barbuda",
    alpha_2: "AG",
    alpha_3: "ATG",
    country_code: "028",
    iso_3166_2: "ISO 3166-2:AG",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1268",
    isoCode: "AG",
    flag: "https://www.countryflags.io/AG/flat/64.png",
  },
  {
    name: "Argentina",
    alpha_2: "AR",
    alpha_3: "ARG",
    country_code: "032",
    iso_3166_2: "ISO 3166-2:AR",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+54",
    isoCode: "AR",
    flag: "https://www.countryflags.io/AR/flat/64.png",
  },
  {
    name: "Armenia",
    alpha_2: "AM",
    alpha_3: "ARM",
    country_code: "051",
    iso_3166_2: "ISO 3166-2:AM",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+374",
    isoCode: "AM",
    flag: "https://www.countryflags.io/AM/flat/64.png",
  },
  {
    name: "Aruba",
    alpha_2: "AW",
    alpha_3: "ABW",
    country_code: "533",
    iso_3166_2: "ISO 3166-2:AW",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+297",
    isoCode: "AW",
    flag: "https://www.countryflags.io/AW/flat/64.png",
  },
  {
    name: "Australia",
    alpha_2: "AU",
    alpha_3: "AUS",
    country_code: "036",
    iso_3166_2: "ISO 3166-2:AU",
    region: "Oceania",
    sub_region: "Australia and New Zealand",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "053",
    intermediate_region_code: "",
    dialCode: "+61",
    isoCode: "AU",
    flag: "https://www.countryflags.io/AU/flat/64.png",
  },
  {
    name: "Austria",
    alpha_2: "AT",
    alpha_3: "AUT",
    country_code: "040",
    iso_3166_2: "ISO 3166-2:AT",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+43",
    isoCode: "AT",
    flag: "https://www.countryflags.io/AT/flat/64.png",
  },
  {
    name: "Azerbaijan",
    alpha_2: "AZ",
    alpha_3: "AZE",
    country_code: "031",
    iso_3166_2: "ISO 3166-2:AZ",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+994",
    isoCode: "AZ",
    flag: "https://www.countryflags.io/AZ/flat/64.png",
  },
  {
    name: "Bahamas",
    alpha_2: "BS",
    alpha_3: "BHS",
    country_code: "044",
    iso_3166_2: "ISO 3166-2:BS",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1242",
    isoCode: "BS",
    flag: "https://www.countryflags.io/BS/flat/64.png",
  },
  {
    name: "Bahrain",
    alpha_2: "BH",
    alpha_3: "BHR",
    country_code: "048",
    iso_3166_2: "ISO 3166-2:BH",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+973",
    isoCode: "BH",
    flag: "https://www.countryflags.io/BH/flat/64.png",
  },
  {
    name: "Bangladesh",
    alpha_2: "BD",
    alpha_3: "BGD",
    country_code: "050",
    iso_3166_2: "ISO 3166-2:BD",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+880",
    isoCode: "BD",
    flag: "https://www.countryflags.io/BD/flat/64.png",
  },
  {
    name: "Barbados",
    alpha_2: "BB",
    alpha_3: "BRB",
    country_code: "052",
    iso_3166_2: "ISO 3166-2:BB",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1246",
    isoCode: "BB",
    flag: "https://www.countryflags.io/BB/flat/64.png",
  },
  {
    name: "Belarus",
    alpha_2: "BY",
    alpha_3: "BLR",
    country_code: "112",
    iso_3166_2: "ISO 3166-2:BY",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+375",
    isoCode: "BY",
    flag: "https://www.countryflags.io/BY/flat/64.png",
  },
  {
    name: "Belgium",
    alpha_2: "BE",
    alpha_3: "BEL",
    country_code: "056",
    iso_3166_2: "ISO 3166-2:BE",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+32",
    isoCode: "BE",
    flag: "https://www.countryflags.io/BE/flat/64.png",
  },
  {
    name: "Belize",
    alpha_2: "BZ",
    alpha_3: "BLZ",
    country_code: "084",
    iso_3166_2: "ISO 3166-2:BZ",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+501",
    isoCode: "BZ",
    flag: "https://www.countryflags.io/BZ/flat/64.png",
  },
  {
    name: "Benin",
    alpha_2: "BJ",
    alpha_3: "BEN",
    country_code: "204",
    iso_3166_2: "ISO 3166-2:BJ",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+229",
    isoCode: "BJ",
    flag: "https://www.countryflags.io/BJ/flat/64.png",
  },
  {
    name: "Bermuda",
    alpha_2: "BM",
    alpha_3: "BMU",
    country_code: "060",
    iso_3166_2: "ISO 3166-2:BM",
    region: "Americas",
    sub_region: "Northern America",
    intermediate_region: "",
    region_code: "019",
    sub_region_code: "021",
    intermediate_region_code: "",
    dialCode: "+1441",
    isoCode: "BM",
    flag: "https://www.countryflags.io/BM/flat/64.png",
  },
  {
    name: "Bhutan",
    alpha_2: "BT",
    alpha_3: "BTN",
    country_code: "064",
    iso_3166_2: "ISO 3166-2:BT",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+975",
    isoCode: "BT",
    flag: "https://www.countryflags.io/BT/flat/64.png",
  },
  {
    name: "Bolivia (Plurinational State of)",
    alpha_2: "BO",
    alpha_3: "BOL",
    country_code: "068",
    iso_3166_2: "ISO 3166-2:BO",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+591",
    isoCode: "BO",
    flag: "https://www.countryflags.io/BO/flat/64.png",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha_2: "BQ",
    alpha_3: "BES",
    country_code: "535",
    iso_3166_2: "ISO 3166-2:BQ",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+599",
    isoCode: "BQ",
    flag: "https://www.countryflags.io/BQ/flat/64.png",
  },
  {
    name: "Bosnia and Herzegovina",
    alpha_2: "BA",
    alpha_3: "BIH",
    country_code: "070",
    iso_3166_2: "ISO 3166-2:BA",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+387",
    isoCode: "BA",
    flag: "https://www.countryflags.io/BA/flat/64.png",
  },
  {
    name: "Botswana",
    alpha_2: "BW",
    alpha_3: "BWA",
    country_code: "072",
    iso_3166_2: "ISO 3166-2:BW",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Southern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "018",
    dialCode: "+267",
    isoCode: "BW",
    flag: "https://www.countryflags.io/BW/flat/64.png",
  },
  {
    name: "Bouvet Island",
    alpha_2: "BV",
    alpha_3: "BVT",
    country_code: "074",
    iso_3166_2: "ISO 3166-2:BV",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+47",
    isoCode: "BV",
    flag: "https://www.countryflags.io/BV/flat/64.png",
  },
  {
    name: "Brazil",
    alpha_2: "BR",
    alpha_3: "BRA",
    country_code: "076",
    iso_3166_2: "ISO 3166-2:BR",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+55",
    isoCode: "BR",
    flag: "https://www.countryflags.io/BR/flat/64.png",
  },
  {
    name: "British Indian Ocean Territory",
    alpha_2: "IO",
    alpha_3: "IOT",
    country_code: "086",
    iso_3166_2: "ISO 3166-2:IO",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+246",
    isoCode: "IO",
    flag: "https://www.countryflags.io/IO/flat/64.png",
  },
  {
    name: "Brunei Darussalam",
    alpha_2: "BN",
    alpha_3: "BRN",
    country_code: "096",
    iso_3166_2: "ISO 3166-2:BN",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+673",
    isoCode: "BN",
    flag: "https://www.countryflags.io/BN/flat/64.png",
  },
  {
    name: "Bulgaria",
    alpha_2: "BG",
    alpha_3: "BGR",
    country_code: "100",
    iso_3166_2: "ISO 3166-2:BG",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+359",
    isoCode: "BG",
    flag: "https://www.countryflags.io/BG/flat/64.png",
  },
  {
    name: "Burkina Faso",
    alpha_2: "BF",
    alpha_3: "BFA",
    country_code: "854",
    iso_3166_2: "ISO 3166-2:BF",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+226",
    isoCode: "BF",
    flag: "https://www.countryflags.io/BF/flat/64.png",
  },
  {
    name: "Burundi",
    alpha_2: "BI",
    alpha_3: "BDI",
    country_code: "108",
    iso_3166_2: "ISO 3166-2:BI",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+257",
    isoCode: "BI",
    flag: "https://www.countryflags.io/BI/flat/64.png",
  },
  {
    name: "Cabo Verde",
    alpha_2: "CV",
    alpha_3: "CPV",
    country_code: "132",
    iso_3166_2: "ISO 3166-2:CV",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+238",
    isoCode: "CV",
    flag: "https://www.countryflags.io/CV/flat/64.png",
  },
  {
    name: "Cambodia",
    alpha_2: "KH",
    alpha_3: "KHM",
    country_code: "116",
    iso_3166_2: "ISO 3166-2:KH",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+855",
    isoCode: "KH",
    flag: "https://www.countryflags.io/KH/flat/64.png",
  },
  {
    name: "Cameroon",
    alpha_2: "CM",
    alpha_3: "CMR",
    country_code: "120",
    iso_3166_2: "ISO 3166-2:CM",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+237",
    isoCode: "CM",
    flag: "https://www.countryflags.io/CM/flat/64.png",
  },
  {
    name: "Canada",
    alpha_2: "CA",
    alpha_3: "CAN",
    country_code: "124",
    iso_3166_2: "ISO 3166-2:CA",
    region: "Americas",
    sub_region: "Northern America",
    intermediate_region: "",
    region_code: "019",
    sub_region_code: "021",
    intermediate_region_code: "",
    dialCode: "+1",
    isoCode: "CA",
    flag: "https://www.countryflags.io/CA/flat/64.png",
  },
  {
    name: "Cayman Islands",
    alpha_2: "KY",
    alpha_3: "CYM",
    country_code: "136",
    iso_3166_2: "ISO 3166-2:KY",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1345",
    isoCode: "KY",
    flag: "https://www.countryflags.io/KY/flat/64.png",
  },
  {
    name: "Central African Republic",
    alpha_2: "CF",
    alpha_3: "CAF",
    country_code: "140",
    iso_3166_2: "ISO 3166-2:CF",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+236",
    isoCode: "CF",
    flag: "https://www.countryflags.io/CF/flat/64.png",
  },
  {
    name: "Chad",
    alpha_2: "TD",
    alpha_3: "TCD",
    country_code: "148",
    iso_3166_2: "ISO 3166-2:TD",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+235",
    isoCode: "TD",
    flag: "https://www.countryflags.io/TD/flat/64.png",
  },
  {
    name: "Chile",
    alpha_2: "CL",
    alpha_3: "CHL",
    country_code: "152",
    iso_3166_2: "ISO 3166-2:CL",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+56",
    isoCode: "CL",
    flag: "https://www.countryflags.io/CL/flat/64.png",
  },
  {
    name: "China",
    alpha_2: "CN",
    alpha_3: "CHN",
    country_code: "156",
    iso_3166_2: "ISO 3166-2:CN",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+86",
    isoCode: "CN",
    flag: "https://www.countryflags.io/CN/flat/64.png",
  },
  {
    name: "Christmas Island",
    alpha_2: "CX",
    alpha_3: "CXR",
    country_code: "162",
    iso_3166_2: "ISO 3166-2:CX",
    region: "Oceania",
    sub_region: "Australia and New Zealand",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "053",
    intermediate_region_code: "",
    dialCode: "+61",
    isoCode: "CX",
    flag: "https://www.countryflags.io/CX/flat/64.png",
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha_2: "CC",
    alpha_3: "CCK",
    country_code: "166",
    iso_3166_2: "ISO 3166-2:CC",
    region: "Oceania",
    sub_region: "Australia and New Zealand",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "053",
    intermediate_region_code: "",
    dialCode: "+61",
    isoCode: "CC",
    flag: "https://www.countryflags.io/CC/flat/64.png",
  },
  {
    name: "Colombia",
    alpha_2: "CO",
    alpha_3: "COL",
    country_code: "170",
    iso_3166_2: "ISO 3166-2:CO",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+57",
    isoCode: "CO",
    flag: "https://www.countryflags.io/CO/flat/64.png",
  },
  {
    name: "Comoros",
    alpha_2: "KM",
    alpha_3: "COM",
    country_code: "174",
    iso_3166_2: "ISO 3166-2:KM",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+269",
    isoCode: "KM",
    flag: "https://www.countryflags.io/KM/flat/64.png",
  },
  {
    name: "Congo",
    alpha_2: "CG",
    alpha_3: "COG",
    country_code: "178",
    iso_3166_2: "ISO 3166-2:CG",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+242",
    isoCode: "CG",
    flag: "https://www.countryflags.io/CG/flat/64.png",
  },
  {
    name: "Congo, Democratic Republic of the",
    alpha_2: "CD",
    alpha_3: "COD",
    country_code: "180",
    iso_3166_2: "ISO 3166-2:CD",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+243",
    isoCode: "CD",
    flag: "https://www.countryflags.io/CD/flat/64.png",
  },
  {
    name: "Cook Islands",
    alpha_2: "CK",
    alpha_3: "COK",
    country_code: "184",
    iso_3166_2: "ISO 3166-2:CK",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+682",
    isoCode: "CK",
    flag: "https://www.countryflags.io/CK/flat/64.png",
  },
  {
    name: "Costa Rica",
    alpha_2: "CR",
    alpha_3: "CRI",
    country_code: "188",
    iso_3166_2: "ISO 3166-2:CR",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+506",
    isoCode: "CR",
    flag: "https://www.countryflags.io/CR/flat/64.png",
  },
  {
    name: "Côte d'Ivoire",
    alpha_2: "CI",
    alpha_3: "CIV",
    country_code: "384",
    iso_3166_2: "ISO 3166-2:CI",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+225",
    isoCode: "CI",
    flag: "https://www.countryflags.io/CI/flat/64.png",
  },
  {
    name: "Croatia",
    alpha_2: "HR",
    alpha_3: "HRV",
    country_code: "191",
    iso_3166_2: "ISO 3166-2:HR",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+385",
    isoCode: "HR",
    flag: "https://www.countryflags.io/HR/flat/64.png",
  },
  {
    name: "Cuba",
    alpha_2: "CU",
    alpha_3: "CUB",
    country_code: "192",
    iso_3166_2: "ISO 3166-2:CU",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+53",
    isoCode: "CU",
    flag: "https://www.countryflags.io/CU/flat/64.png",
  },
  {
    name: "Curaçao",
    alpha_2: "CW",
    alpha_3: "CUW",
    country_code: "531",
    iso_3166_2: "ISO 3166-2:CW",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+599",
    isoCode: "CW",
    flag: "https://www.countryflags.io/CW/flat/64.png",
  },
  {
    name: "Cyprus",
    alpha_2: "CY",
    alpha_3: "CYP",
    country_code: "196",
    iso_3166_2: "ISO 3166-2:CY",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+357",
    isoCode: "CY",
    flag: "https://www.countryflags.io/CY/flat/64.png",
  },
  {
    name: "Czechia",
    alpha_2: "CZ",
    alpha_3: "CZE",
    country_code: "203",
    iso_3166_2: "ISO 3166-2:CZ",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+420",
    isoCode: "CZ",
    flag: "https://www.countryflags.io/CZ/flat/64.png",
  },
  {
    name: "Denmark",
    alpha_2: "DK",
    alpha_3: "DNK",
    country_code: "208",
    iso_3166_2: "ISO 3166-2:DK",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+45",
    isoCode: "DK",
    flag: "https://www.countryflags.io/DK/flat/64.png",
  },
  {
    name: "Djibouti",
    alpha_2: "DJ",
    alpha_3: "DJI",
    country_code: "262",
    iso_3166_2: "ISO 3166-2:DJ",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+253",
    isoCode: "DJ",
    flag: "https://www.countryflags.io/DJ/flat/64.png",
  },
  {
    name: "Dominica",
    alpha_2: "DM",
    alpha_3: "DMA",
    country_code: "212",
    iso_3166_2: "ISO 3166-2:DM",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1767",
    isoCode: "DM",
    flag: "https://www.countryflags.io/DM/flat/64.png",
  },
  {
    name: "Dominican Republic",
    alpha_2: "DO",
    alpha_3: "DOM",
    country_code: "214",
    iso_3166_2: "ISO 3166-2:DO",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1849",
    isoCode: "DO",
    flag: "https://www.countryflags.io/DO/flat/64.png",
  },
  {
    name: "Ecuador",
    alpha_2: "EC",
    alpha_3: "ECU",
    country_code: "218",
    iso_3166_2: "ISO 3166-2:EC",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+593",
    isoCode: "EC",
    flag: "https://www.countryflags.io/EC/flat/64.png",
  },
  {
    name: "Egypt",
    alpha_2: "EG",
    alpha_3: "EGY",
    country_code: "818",
    iso_3166_2: "ISO 3166-2:EG",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+20",
    isoCode: "EG",
    flag: "https://www.countryflags.io/EG/flat/64.png",
  },
  {
    name: "El Salvador",
    alpha_2: "SV",
    alpha_3: "SLV",
    country_code: "222",
    iso_3166_2: "ISO 3166-2:SV",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+503",
    isoCode: "SV",
    flag: "https://www.countryflags.io/SV/flat/64.png",
  },
  {
    name: "Equatorial Guinea",
    alpha_2: "GQ",
    alpha_3: "GNQ",
    country_code: "226",
    iso_3166_2: "ISO 3166-2:GQ",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+240",
    isoCode: "GQ",
    flag: "https://www.countryflags.io/GQ/flat/64.png",
  },
  {
    name: "Eritrea",
    alpha_2: "ER",
    alpha_3: "ERI",
    country_code: "232",
    iso_3166_2: "ISO 3166-2:ER",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+291",
    isoCode: "ER",
    flag: "https://www.countryflags.io/ER/flat/64.png",
  },
  {
    name: "Estonia",
    alpha_2: "EE",
    alpha_3: "EST",
    country_code: "233",
    iso_3166_2: "ISO 3166-2:EE",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+372",
    isoCode: "EE",
    flag: "https://www.countryflags.io/EE/flat/64.png",
  },
  {
    name: "Eswatini",
    alpha_2: "SZ",
    alpha_3: "SWZ",
    country_code: "748",
    iso_3166_2: "ISO 3166-2:SZ",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Southern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "018",
    dialCode: "+268",
    isoCode: "SZ",
    flag: "https://www.countryflags.io/SZ/flat/64.png",
  },
  {
    name: "Ethiopia",
    alpha_2: "ET",
    alpha_3: "ETH",
    country_code: "231",
    iso_3166_2: "ISO 3166-2:ET",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+251",
    isoCode: "ET",
    flag: "https://www.countryflags.io/ET/flat/64.png",
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha_2: "FK",
    alpha_3: "FLK",
    country_code: "238",
    iso_3166_2: "ISO 3166-2:FK",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+500",
    isoCode: "FK",
    flag: "https://www.countryflags.io/FK/flat/64.png",
  },
  {
    name: "Faroe Islands",
    alpha_2: "FO",
    alpha_3: "FRO",
    country_code: "234",
    iso_3166_2: "ISO 3166-2:FO",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+298",
    isoCode: "FO",
    flag: "https://www.countryflags.io/FO/flat/64.png",
  },
  {
    name: "Fiji",
    alpha_2: "FJ",
    alpha_3: "FJI",
    country_code: "242",
    iso_3166_2: "ISO 3166-2:FJ",
    region: "Oceania",
    sub_region: "Melanesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "054",
    intermediate_region_code: "",
    dialCode: "+679",
    isoCode: "FJ",
    flag: "https://www.countryflags.io/FJ/flat/64.png",
  },
  {
    name: "Finland",
    alpha_2: "FI",
    alpha_3: "FIN",
    country_code: "246",
    iso_3166_2: "ISO 3166-2:FI",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+358",
    isoCode: "FI",
    flag: "https://www.countryflags.io/FI/flat/64.png",
  },
  {
    name: "France",
    alpha_2: "FR",
    alpha_3: "FRA",
    country_code: "250",
    iso_3166_2: "ISO 3166-2:FR",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+33",
    isoCode: "FR",
    flag: "https://www.countryflags.io/FR/flat/64.png",
  },
  {
    name: "French Guiana",
    alpha_2: "GF",
    alpha_3: "GUF",
    country_code: "254",
    iso_3166_2: "ISO 3166-2:GF",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+594",
    isoCode: "GF",
    flag: "https://www.countryflags.io/GF/flat/64.png",
  },
  {
    name: "French Polynesia",
    alpha_2: "PF",
    alpha_3: "PYF",
    country_code: "258",
    iso_3166_2: "ISO 3166-2:PF",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+689",
    isoCode: "PF",
    flag: "https://www.countryflags.io/PF/flat/64.png",
  },
  {
    name: "French Southern Territories",
    alpha_2: "TF",
    alpha_3: "ATF",
    country_code: "260",
    iso_3166_2: "ISO 3166-2:TF",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+262",
    isoCode: "TF",
    flag: "https://www.countryflags.io/TF/flat/64.png",
  },
  {
    name: "Gabon",
    alpha_2: "GA",
    alpha_3: "GAB",
    country_code: "266",
    iso_3166_2: "ISO 3166-2:GA",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+241",
    isoCode: "GA",
    flag: "https://www.countryflags.io/GA/flat/64.png",
  },
  {
    name: "Gambia",
    alpha_2: "GM",
    alpha_3: "GMB",
    country_code: "270",
    iso_3166_2: "ISO 3166-2:GM",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+220",
    isoCode: "GM",
    flag: "https://www.countryflags.io/GM/flat/64.png",
  },
  {
    name: "Georgia",
    alpha_2: "GE",
    alpha_3: "GEO",
    country_code: "268",
    iso_3166_2: "ISO 3166-2:GE",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+995",
    isoCode: "GE",
    flag: "https://www.countryflags.io/GE/flat/64.png",
  },
  {
    name: "Germany",
    alpha_2: "DE",
    alpha_3: "DEU",
    country_code: "276",
    iso_3166_2: "ISO 3166-2:DE",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+49",
    isoCode: "DE",
    flag: "https://www.countryflags.io/DE/flat/64.png",
  },
  {
    name: "Ghana",
    alpha_2: "GH",
    alpha_3: "GHA",
    country_code: "288",
    iso_3166_2: "ISO 3166-2:GH",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+233",
    isoCode: "GH",
    flag: "https://www.countryflags.io/GH/flat/64.png",
  },
  {
    name: "Gibraltar",
    alpha_2: "GI",
    alpha_3: "GIB",
    country_code: "292",
    iso_3166_2: "ISO 3166-2:GI",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+350",
    isoCode: "GI",
    flag: "https://www.countryflags.io/GI/flat/64.png",
  },
  {
    name: "Greece",
    alpha_2: "GR",
    alpha_3: "GRC",
    country_code: "300",
    iso_3166_2: "ISO 3166-2:GR",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+30",
    isoCode: "GR",
    flag: "https://www.countryflags.io/GR/flat/64.png",
  },
  {
    name: "Greenland",
    alpha_2: "GL",
    alpha_3: "GRL",
    country_code: "304",
    iso_3166_2: "ISO 3166-2:GL",
    region: "Americas",
    sub_region: "Northern America",
    intermediate_region: "",
    region_code: "019",
    sub_region_code: "021",
    intermediate_region_code: "",
    dialCode: "+299",
    isoCode: "GL",
    flag: "https://www.countryflags.io/GL/flat/64.png",
  },
  {
    name: "Grenada",
    alpha_2: "GD",
    alpha_3: "GRD",
    country_code: "308",
    iso_3166_2: "ISO 3166-2:GD",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1473",
    isoCode: "GD",
    flag: "https://www.countryflags.io/GD/flat/64.png",
  },
  {
    name: "Guadeloupe",
    alpha_2: "GP",
    alpha_3: "GLP",
    country_code: "312",
    iso_3166_2: "ISO 3166-2:GP",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+590",
    isoCode: "GP",
    flag: "https://www.countryflags.io/GP/flat/64.png",
  },
  {
    name: "Guam",
    alpha_2: "GU",
    alpha_3: "GUM",
    country_code: "316",
    iso_3166_2: "ISO 3166-2:GU",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+1671",
    isoCode: "GU",
    flag: "https://www.countryflags.io/GU/flat/64.png",
  },
  {
    name: "Guatemala",
    alpha_2: "GT",
    alpha_3: "GTM",
    country_code: "320",
    iso_3166_2: "ISO 3166-2:GT",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+502",
    isoCode: "GT",
    flag: "https://www.countryflags.io/GT/flat/64.png",
  },
  {
    name: "Guernsey",
    alpha_2: "GG",
    alpha_3: "GGY",
    country_code: "831",
    iso_3166_2: "ISO 3166-2:GG",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "Channel Islands",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "830",
    dialCode: "+44",
    isoCode: "GG",
    flag: "https://www.countryflags.io/GG/flat/64.png",
  },
  {
    name: "Guinea",
    alpha_2: "GN",
    alpha_3: "GIN",
    country_code: "324",
    iso_3166_2: "ISO 3166-2:GN",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+224",
    isoCode: "GN",
    flag: "https://www.countryflags.io/GN/flat/64.png",
  },
  {
    name: "Guinea-Bissau",
    alpha_2: "GW",
    alpha_3: "GNB",
    country_code: "624",
    iso_3166_2: "ISO 3166-2:GW",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+245",
    isoCode: "GW",
    flag: "https://www.countryflags.io/GW/flat/64.png",
  },
  {
    name: "Guyana",
    alpha_2: "GY",
    alpha_3: "GUY",
    country_code: "328",
    iso_3166_2: "ISO 3166-2:GY",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+592",
    isoCode: "GY",
    flag: "https://www.countryflags.io/GY/flat/64.png",
  },
  {
    name: "Haiti",
    alpha_2: "HT",
    alpha_3: "HTI",
    country_code: "332",
    iso_3166_2: "ISO 3166-2:HT",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+509",
    isoCode: "HT",
    flag: "https://www.countryflags.io/HT/flat/64.png",
  },
  // {
  //   name: "Heard Island and McDonald Islands",
  //   alpha_2: "HM",
  //   alpha_3: "HMD",
  //   country_code: "334",
  //   iso_3166_2: "ISO 3166-2:HM",
  //   region: "Oceania",
  //   sub_region: "Australia and New Zealand",
  //   intermediate_region: "",
  //   region_code: "009",
  //   sub_region_code: "053",
  //   intermediate_region_code: "",
  //   dialCode: "",
  //   isoCode: "HM",
  //   flag: "https://www.countryflags.io/HM/flat/64.png",
  // },
  {
    name: "Holy See",
    alpha_2: "VA",
    alpha_3: "VAT",
    country_code: "336",
    iso_3166_2: "ISO 3166-2:VA",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+379",
    isoCode: "VA",
    flag: "https://www.countryflags.io/VA/flat/64.png",
  },
  {
    name: "Honduras",
    alpha_2: "HN",
    alpha_3: "HND",
    country_code: "340",
    iso_3166_2: "ISO 3166-2:HN",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+504",
    isoCode: "HN",
    flag: "https://www.countryflags.io/HN/flat/64.png",
  },
  {
    name: "Hong Kong",
    alpha_2: "HK",
    alpha_3: "HKG",
    country_code: "344",
    iso_3166_2: "ISO 3166-2:HK",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+852",
    isoCode: "HK",
    flag: "https://www.countryflags.io/HK/flat/64.png",
  },
  {
    name: "Hungary",
    alpha_2: "HU",
    alpha_3: "HUN",
    country_code: "348",
    iso_3166_2: "ISO 3166-2:HU",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+36",
    isoCode: "HU",
    flag: "https://www.countryflags.io/HU/flat/64.png",
  },
  {
    name: "Iceland",
    alpha_2: "IS",
    alpha_3: "ISL",
    country_code: "352",
    iso_3166_2: "ISO 3166-2:IS",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+354",
    isoCode: "IS",
    flag: "https://www.countryflags.io/IS/flat/64.png",
  },
  {
    name: "India",
    alpha_2: "IN",
    alpha_3: "IND",
    country_code: "356",
    iso_3166_2: "ISO 3166-2:IN",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+91",
    isoCode: "IN",
    flag: "https://www.countryflags.io/IN/flat/64.png",
  },
  {
    name: "Indonesia",
    alpha_2: "ID",
    alpha_3: "IDN",
    country_code: "360",
    iso_3166_2: "ISO 3166-2:ID",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+62",
    isoCode: "ID",
    flag: "https://www.countryflags.io/ID/flat/64.png",
  },
  {
    name: "Iran (Islamic Republic of)",
    alpha_2: "IR",
    alpha_3: "IRN",
    country_code: "364",
    iso_3166_2: "ISO 3166-2:IR",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+98",
    isoCode: "IR",
    flag: "https://www.countryflags.io/IR/flat/64.png",
  },
  {
    name: "Iraq",
    alpha_2: "IQ",
    alpha_3: "IRQ",
    country_code: "368",
    iso_3166_2: "ISO 3166-2:IQ",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+964",
    isoCode: "IQ",
    flag: "https://www.countryflags.io/IQ/flat/64.png",
  },
  {
    name: "Ireland",
    alpha_2: "IE",
    alpha_3: "IRL",
    country_code: "372",
    iso_3166_2: "ISO 3166-2:IE",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+353",
    isoCode: "IE",
    flag: "https://www.countryflags.io/IE/flat/64.png",
  },
  {
    name: "Isle of Man",
    alpha_2: "IM",
    alpha_3: "IMN",
    country_code: "833",
    iso_3166_2: "ISO 3166-2:IM",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+44",
    isoCode: "IM",
    flag: "https://www.countryflags.io/IM/flat/64.png",
  },
  {
    name: "Israel",
    alpha_2: "IL",
    alpha_3: "ISR",
    country_code: "376",
    iso_3166_2: "ISO 3166-2:IL",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+972",
    isoCode: "IL",
    flag: "https://www.countryflags.io/IL/flat/64.png",
  },
  {
    name: "Italy",
    alpha_2: "IT",
    alpha_3: "ITA",
    country_code: "380",
    iso_3166_2: "ISO 3166-2:IT",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+39",
    isoCode: "IT",
    flag: "https://www.countryflags.io/IT/flat/64.png",
  },
  {
    name: "Jamaica",
    alpha_2: "JM",
    alpha_3: "JAM",
    country_code: "388",
    iso_3166_2: "ISO 3166-2:JM",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1876",
    isoCode: "JM",
    flag: "https://www.countryflags.io/JM/flat/64.png",
  },
  {
    name: "Japan",
    alpha_2: "JP",
    alpha_3: "JPN",
    country_code: "392",
    iso_3166_2: "ISO 3166-2:JP",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+81",
    isoCode: "JP",
    flag: "https://www.countryflags.io/JP/flat/64.png",
  },
  {
    name: "Jersey",
    alpha_2: "JE",
    alpha_3: "JEY",
    country_code: "832",
    iso_3166_2: "ISO 3166-2:JE",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "Channel Islands",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "830",
    dialCode: "+44",
    isoCode: "JE",
    flag: "https://www.countryflags.io/JE/flat/64.png",
  },
  {
    name: "Jordan",
    alpha_2: "JO",
    alpha_3: "JOR",
    country_code: "400",
    iso_3166_2: "ISO 3166-2:JO",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+962",
    isoCode: "JO",
    flag: "https://www.countryflags.io/JO/flat/64.png",
  },
  {
    name: "Kazakhstan",
    alpha_2: "KZ",
    alpha_3: "KAZ",
    country_code: "398",
    iso_3166_2: "ISO 3166-2:KZ",
    region: "Asia",
    sub_region: "Central Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "143",
    intermediate_region_code: "",
    dialCode: "+77",
    isoCode: "KZ",
    flag: "https://www.countryflags.io/KZ/flat/64.png",
  },
  {
    name: "Kenya",
    alpha_2: "KE",
    alpha_3: "KEN",
    country_code: "404",
    iso_3166_2: "ISO 3166-2:KE",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+254",
    isoCode: "KE",
    flag: "https://www.countryflags.io/KE/flat/64.png",
  },
  {
    name: "Kiribati",
    alpha_2: "KI",
    alpha_3: "KIR",
    country_code: "296",
    iso_3166_2: "ISO 3166-2:KI",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+686",
    isoCode: "KI",
    flag: "https://www.countryflags.io/KI/flat/64.png",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha_2: "KP",
    alpha_3: "PRK",
    country_code: "408",
    iso_3166_2: "ISO 3166-2:KP",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+850",
    isoCode: "KP",
    flag: "https://www.countryflags.io/KP/flat/64.png",
  },
  {
    name: "Korea, Republic of",
    alpha_2: "KR",
    alpha_3: "KOR",
    country_code: "410",
    iso_3166_2: "ISO 3166-2:KR",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+82",
    isoCode: "KR",
    flag: "https://www.countryflags.io/KR/flat/64.png",
  },
  {
    name: "Kuwait",
    alpha_2: "KW",
    alpha_3: "KWT",
    country_code: "414",
    iso_3166_2: "ISO 3166-2:KW",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+965",
    isoCode: "KW",
    flag: "https://www.countryflags.io/KW/flat/64.png",
  },
  {
    name: "Kyrgyzstan",
    alpha_2: "KG",
    alpha_3: "KGZ",
    country_code: "417",
    iso_3166_2: "ISO 3166-2:KG",
    region: "Asia",
    sub_region: "Central Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "143",
    intermediate_region_code: "",
    dialCode: "+996",
    isoCode: "KG",
    flag: "https://www.countryflags.io/KG/flat/64.png",
  },
  {
    name: "Lao People's Democratic Republic",
    alpha_2: "LA",
    alpha_3: "LAO",
    country_code: "418",
    iso_3166_2: "ISO 3166-2:LA",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+856",
    isoCode: "LA",
    flag: "https://www.countryflags.io/LA/flat/64.png",
  },
  {
    name: "Latvia",
    alpha_2: "LV",
    alpha_3: "LVA",
    country_code: "428",
    iso_3166_2: "ISO 3166-2:LV",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+371",
    isoCode: "LV",
    flag: "https://www.countryflags.io/LV/flat/64.png",
  },
  {
    name: "Lebanon",
    alpha_2: "LB",
    alpha_3: "LBN",
    country_code: "422",
    iso_3166_2: "ISO 3166-2:LB",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+961",
    isoCode: "LB",
    flag: "https://www.countryflags.io/LB/flat/64.png",
  },
  {
    name: "Lesotho",
    alpha_2: "LS",
    alpha_3: "LSO",
    country_code: "426",
    iso_3166_2: "ISO 3166-2:LS",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Southern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "018",
    dialCode: "+266",
    isoCode: "LS",
    flag: "https://www.countryflags.io/LS/flat/64.png",
  },
  {
    name: "Liberia",
    alpha_2: "LR",
    alpha_3: "LBR",
    country_code: "430",
    iso_3166_2: "ISO 3166-2:LR",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+231",
    isoCode: "LR",
    flag: "https://www.countryflags.io/LR/flat/64.png",
  },
  {
    name: "Libya",
    alpha_2: "LY",
    alpha_3: "LBY",
    country_code: "434",
    iso_3166_2: "ISO 3166-2:LY",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+218",
    isoCode: "LY",
    flag: "https://www.countryflags.io/LY/flat/64.png",
  },
  {
    name: "Liechtenstein",
    alpha_2: "LI",
    alpha_3: "LIE",
    country_code: "438",
    iso_3166_2: "ISO 3166-2:LI",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+423",
    isoCode: "LI",
    flag: "https://www.countryflags.io/LI/flat/64.png",
  },
  {
    name: "Lithuania",
    alpha_2: "LT",
    alpha_3: "LTU",
    country_code: "440",
    iso_3166_2: "ISO 3166-2:LT",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+370",
    isoCode: "LT",
    flag: "https://www.countryflags.io/LT/flat/64.png",
  },
  {
    name: "Luxembourg",
    alpha_2: "LU",
    alpha_3: "LUX",
    country_code: "442",
    iso_3166_2: "ISO 3166-2:LU",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+352",
    isoCode: "LU",
    flag: "https://www.countryflags.io/LU/flat/64.png",
  },
  {
    name: "Macao",
    alpha_2: "MO",
    alpha_3: "MAC",
    country_code: "446",
    iso_3166_2: "ISO 3166-2:MO",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+853",
    isoCode: "MO",
    flag: "https://www.countryflags.io/MO/flat/64.png",
  },
  {
    name: "Madagascar",
    alpha_2: "MG",
    alpha_3: "MDG",
    country_code: "450",
    iso_3166_2: "ISO 3166-2:MG",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+261",
    isoCode: "MG",
    flag: "https://www.countryflags.io/MG/flat/64.png",
  },
  {
    name: "Malawi",
    alpha_2: "MW",
    alpha_3: "MWI",
    country_code: "454",
    iso_3166_2: "ISO 3166-2:MW",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+265",
    isoCode: "MW",
    flag: "https://www.countryflags.io/MW/flat/64.png",
  },
  {
    name: "Malaysia",
    alpha_2: "MY",
    alpha_3: "MYS",
    country_code: "458",
    iso_3166_2: "ISO 3166-2:MY",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+60",
    isoCode: "MY",
    flag: "https://www.countryflags.io/MY/flat/64.png",
  },
  {
    name: "Maldives",
    alpha_2: "MV",
    alpha_3: "MDV",
    country_code: "462",
    iso_3166_2: "ISO 3166-2:MV",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+960",
    isoCode: "MV",
    flag: "https://www.countryflags.io/MV/flat/64.png",
  },
  {
    name: "Mali",
    alpha_2: "ML",
    alpha_3: "MLI",
    country_code: "466",
    iso_3166_2: "ISO 3166-2:ML",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+223",
    isoCode: "ML",
    flag: "https://www.countryflags.io/ML/flat/64.png",
  },
  {
    name: "Malta",
    alpha_2: "MT",
    alpha_3: "MLT",
    country_code: "470",
    iso_3166_2: "ISO 3166-2:MT",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+356",
    isoCode: "MT",
    flag: "https://www.countryflags.io/MT/flat/64.png",
  },
  {
    name: "Marshall Islands",
    alpha_2: "MH",
    alpha_3: "MHL",
    country_code: "584",
    iso_3166_2: "ISO 3166-2:MH",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+692",
    isoCode: "MH",
    flag: "https://www.countryflags.io/MH/flat/64.png",
  },
  {
    name: "Martinique",
    alpha_2: "MQ",
    alpha_3: "MTQ",
    country_code: "474",
    iso_3166_2: "ISO 3166-2:MQ",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+596",
    isoCode: "MQ",
    flag: "https://www.countryflags.io/MQ/flat/64.png",
  },
  {
    name: "Mauritania",
    alpha_2: "MR",
    alpha_3: "MRT",
    country_code: "478",
    iso_3166_2: "ISO 3166-2:MR",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+222",
    isoCode: "MR",
    flag: "https://www.countryflags.io/MR/flat/64.png",
  },
  {
    name: "Mauritius",
    alpha_2: "MU",
    alpha_3: "MUS",
    country_code: "480",
    iso_3166_2: "ISO 3166-2:MU",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+230",
    isoCode: "MU",
    flag: "https://www.countryflags.io/MU/flat/64.png",
  },
  {
    name: "Mayotte",
    alpha_2: "YT",
    alpha_3: "MYT",
    country_code: "175",
    iso_3166_2: "ISO 3166-2:YT",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+262",
    isoCode: "YT",
    flag: "https://www.countryflags.io/YT/flat/64.png",
  },
  {
    name: "Mexico",
    alpha_2: "MX",
    alpha_3: "MEX",
    country_code: "484",
    iso_3166_2: "ISO 3166-2:MX",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+52",
    isoCode: "MX",
    flag: "https://www.countryflags.io/MX/flat/64.png",
  },
  {
    name: "Micronesia (Federated States of)",
    alpha_2: "FM",
    alpha_3: "FSM",
    country_code: "583",
    iso_3166_2: "ISO 3166-2:FM",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+691",
    isoCode: "FM",
    flag: "https://www.countryflags.io/FM/flat/64.png",
  },
  {
    name: "Moldova, Republic of",
    alpha_2: "MD",
    alpha_3: "MDA",
    country_code: "498",
    iso_3166_2: "ISO 3166-2:MD",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+373",
    isoCode: "MD",
    flag: "https://www.countryflags.io/MD/flat/64.png",
  },
  {
    name: "Monaco",
    alpha_2: "MC",
    alpha_3: "MCO",
    country_code: "492",
    iso_3166_2: "ISO 3166-2:MC",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+377",
    isoCode: "MC",
    flag: "https://www.countryflags.io/MC/flat/64.png",
  },
  {
    name: "Mongolia",
    alpha_2: "MN",
    alpha_3: "MNG",
    country_code: "496",
    iso_3166_2: "ISO 3166-2:MN",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+976",
    isoCode: "MN",
    flag: "https://www.countryflags.io/MN/flat/64.png",
  },
  {
    name: "Montenegro",
    alpha_2: "ME",
    alpha_3: "MNE",
    country_code: "499",
    iso_3166_2: "ISO 3166-2:ME",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+382",
    isoCode: "ME",
    flag: "https://www.countryflags.io/ME/flat/64.png",
  },
  {
    name: "Montserrat",
    alpha_2: "MS",
    alpha_3: "MSR",
    country_code: "500",
    iso_3166_2: "ISO 3166-2:MS",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1664",
    isoCode: "MS",
    flag: "https://www.countryflags.io/MS/flat/64.png",
  },
  {
    name: "Morocco",
    alpha_2: "MA",
    alpha_3: "MAR",
    country_code: "504",
    iso_3166_2: "ISO 3166-2:MA",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+212",
    isoCode: "MA",
    flag: "https://www.countryflags.io/MA/flat/64.png",
  },
  {
    name: "Mozambique",
    alpha_2: "MZ",
    alpha_3: "MOZ",
    country_code: "508",
    iso_3166_2: "ISO 3166-2:MZ",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+258",
    isoCode: "MZ",
    flag: "https://www.countryflags.io/MZ/flat/64.png",
  },
  {
    name: "Myanmar",
    alpha_2: "MM",
    alpha_3: "MMR",
    country_code: "104",
    iso_3166_2: "ISO 3166-2:MM",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+95",
    isoCode: "MM",
    flag: "https://www.countryflags.io/MM/flat/64.png",
  },
  {
    name: "Namibia",
    alpha_2: "NA",
    alpha_3: "NAM",
    country_code: "516",
    iso_3166_2: "ISO 3166-2:NA",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Southern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "018",
    dialCode: "+264",
    isoCode: "NA",
    flag: "https://www.countryflags.io/NA/flat/64.png",
  },
  {
    name: "Nauru",
    alpha_2: "NR",
    alpha_3: "NRU",
    country_code: "520",
    iso_3166_2: "ISO 3166-2:NR",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+674",
    isoCode: "NR",
    flag: "https://www.countryflags.io/NR/flat/64.png",
  },
  {
    name: "Nepal",
    alpha_2: "NP",
    alpha_3: "NPL",
    country_code: "524",
    iso_3166_2: "ISO 3166-2:NP",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+977",
    isoCode: "NP",
    flag: "https://www.countryflags.io/NP/flat/64.png",
  },
  {
    name: "Netherlands",
    alpha_2: "NL",
    alpha_3: "NLD",
    country_code: "528",
    iso_3166_2: "ISO 3166-2:NL",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+31",
    isoCode: "NL",
    flag: "https://www.countryflags.io/NL/flat/64.png",
  },
  {
    name: "New Caledonia",
    alpha_2: "NC",
    alpha_3: "NCL",
    country_code: "540",
    iso_3166_2: "ISO 3166-2:NC",
    region: "Oceania",
    sub_region: "Melanesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "054",
    intermediate_region_code: "",
    dialCode: "+687",
    isoCode: "NC",
    flag: "https://www.countryflags.io/NC/flat/64.png",
  },
  {
    name: "New Zealand",
    alpha_2: "NZ",
    alpha_3: "NZL",
    country_code: "554",
    iso_3166_2: "ISO 3166-2:NZ",
    region: "Oceania",
    sub_region: "Australia and New Zealand",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "053",
    intermediate_region_code: "",
    dialCode: "+64",
    isoCode: "NZ",
    flag: "https://www.countryflags.io/NZ/flat/64.png",
  },
  {
    name: "Nicaragua",
    alpha_2: "NI",
    alpha_3: "NIC",
    country_code: "558",
    iso_3166_2: "ISO 3166-2:NI",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+505",
    isoCode: "NI",
    flag: "https://www.countryflags.io/NI/flat/64.png",
  },
  {
    name: "Niger",
    alpha_2: "NE",
    alpha_3: "NER",
    country_code: "562",
    iso_3166_2: "ISO 3166-2:NE",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+227",
    isoCode: "NE",
    flag: "https://www.countryflags.io/NE/flat/64.png",
  },
  {
    name: "Nigeria",
    alpha_2: "NG",
    alpha_3: "NGA",
    country_code: "566",
    iso_3166_2: "ISO 3166-2:NG",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+234",
    isoCode: "NG",
    flag: "https://www.countryflags.io/NG/flat/64.png",
  },
  {
    name: "Niue",
    alpha_2: "NU",
    alpha_3: "NIU",
    country_code: "570",
    iso_3166_2: "ISO 3166-2:NU",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+683",
    isoCode: "NU",
    flag: "https://www.countryflags.io/NU/flat/64.png",
  },
  {
    name: "Norfolk Island",
    alpha_2: "NF",
    alpha_3: "NFK",
    country_code: "574",
    iso_3166_2: "ISO 3166-2:NF",
    region: "Oceania",
    sub_region: "Australia and New Zealand",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "053",
    intermediate_region_code: "",
    dialCode: "+672",
    isoCode: "NF",
    flag: "https://www.countryflags.io/NF/flat/64.png",
  },
  {
    name: "North Macedonia",
    alpha_2: "MK",
    alpha_3: "MKD",
    country_code: "807",
    iso_3166_2: "ISO 3166-2:MK",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+389",
    isoCode: "MK",
    flag: "https://www.countryflags.io/MK/flat/64.png",
  },
  {
    name: "Northern Mariana Islands",
    alpha_2: "MP",
    alpha_3: "MNP",
    country_code: "580",
    iso_3166_2: "ISO 3166-2:MP",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+1670",
    isoCode: "MP",
    flag: "https://www.countryflags.io/MP/flat/64.png",
  },
  {
    name: "Norway",
    alpha_2: "NO",
    alpha_3: "NOR",
    country_code: "578",
    iso_3166_2: "ISO 3166-2:NO",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+47",
    isoCode: "NO",
    flag: "https://www.countryflags.io/NO/flat/64.png",
  },
  {
    name: "Oman",
    alpha_2: "OM",
    alpha_3: "OMN",
    country_code: "512",
    iso_3166_2: "ISO 3166-2:OM",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+968",
    isoCode: "OM",
    flag: "https://www.countryflags.io/OM/flat/64.png",
  },
  {
    name: "Pakistan",
    alpha_2: "PK",
    alpha_3: "PAK",
    country_code: "586",
    iso_3166_2: "ISO 3166-2:PK",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+92",
    isoCode: "PK",
    flag: "https://www.countryflags.io/PK/flat/64.png",
  },
  {
    name: "Palau",
    alpha_2: "PW",
    alpha_3: "PLW",
    country_code: "585",
    iso_3166_2: "ISO 3166-2:PW",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+680",
    isoCode: "PW",
    flag: "https://www.countryflags.io/PW/flat/64.png",
  },
  {
    name: "Palestine, State of",
    alpha_2: "PS",
    alpha_3: "PSE",
    country_code: "275",
    iso_3166_2: "ISO 3166-2:PS",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+970",
    isoCode: "PS",
    flag: "https://www.countryflags.io/PS/flat/64.png",
  },
  {
    name: "Panama",
    alpha_2: "PA",
    alpha_3: "PAN",
    country_code: "591",
    iso_3166_2: "ISO 3166-2:PA",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Central America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "013",
    dialCode: "+507",
    isoCode: "PA",
    flag: "https://www.countryflags.io/PA/flat/64.png",
  },
  {
    name: "Papua New Guinea",
    alpha_2: "PG",
    alpha_3: "PNG",
    country_code: "598",
    iso_3166_2: "ISO 3166-2:PG",
    region: "Oceania",
    sub_region: "Melanesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "054",
    intermediate_region_code: "",
    dialCode: "+675",
    isoCode: "PG",
    flag: "https://www.countryflags.io/PG/flat/64.png",
  },
  {
    name: "Paraguay",
    alpha_2: "PY",
    alpha_3: "PRY",
    country_code: "600",
    iso_3166_2: "ISO 3166-2:PY",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+595",
    isoCode: "PY",
    flag: "https://www.countryflags.io/PY/flat/64.png",
  },
  {
    name: "Peru",
    alpha_2: "PE",
    alpha_3: "PER",
    country_code: "604",
    iso_3166_2: "ISO 3166-2:PE",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+51",
    isoCode: "PE",
    flag: "https://www.countryflags.io/PE/flat/64.png",
  },
  {
    name: "Philippines",
    alpha_2: "PH",
    alpha_3: "PHL",
    country_code: "608",
    iso_3166_2: "ISO 3166-2:PH",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+63",
    isoCode: "PH",
    flag: "https://www.countryflags.io/PH/flat/64.png",
  },
  {
    name: "Pitcairn",
    alpha_2: "PN",
    alpha_3: "PCN",
    country_code: "612",
    iso_3166_2: "ISO 3166-2:PN",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+872",
    isoCode: "PN",
    flag: "https://www.countryflags.io/PN/flat/64.png",
  },
  {
    name: "Poland",
    alpha_2: "PL",
    alpha_3: "POL",
    country_code: "616",
    iso_3166_2: "ISO 3166-2:PL",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+48",
    isoCode: "PL",
    flag: "https://www.countryflags.io/PL/flat/64.png",
  },
  {
    name: "Portugal",
    alpha_2: "PT",
    alpha_3: "PRT",
    country_code: "620",
    iso_3166_2: "ISO 3166-2:PT",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+351",
    isoCode: "PT",
    flag: "https://www.countryflags.io/PT/flat/64.png",
  },
  {
    name: "Puerto Rico",
    alpha_2: "PR",
    alpha_3: "PRI",
    country_code: "630",
    iso_3166_2: "ISO 3166-2:PR",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1939",
    isoCode: "PR",
    flag: "https://www.countryflags.io/PR/flat/64.png",
  },
  {
    name: "Qatar",
    alpha_2: "QA",
    alpha_3: "QAT",
    country_code: "634",
    iso_3166_2: "ISO 3166-2:QA",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+974",
    isoCode: "QA",
    flag: "https://www.countryflags.io/QA/flat/64.png",
  },
  {
    name: "Réunion",
    alpha_2: "RE",
    alpha_3: "REU",
    country_code: "638",
    iso_3166_2: "ISO 3166-2:RE",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+262",
    isoCode: "RE",
    flag: "https://www.countryflags.io/RE/flat/64.png",
  },
  {
    name: "Romania",
    alpha_2: "RO",
    alpha_3: "ROU",
    country_code: "642",
    iso_3166_2: "ISO 3166-2:RO",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+40",
    isoCode: "RO",
    flag: "https://www.countryflags.io/RO/flat/64.png",
  },
  {
    name: "Russian Federation",
    alpha_2: "RU",
    alpha_3: "RUS",
    country_code: "643",
    iso_3166_2: "ISO 3166-2:RU",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+7",
    isoCode: "RU",
    flag: "https://www.countryflags.io/RU/flat/64.png",
  },
  {
    name: "Rwanda",
    alpha_2: "RW",
    alpha_3: "RWA",
    country_code: "646",
    iso_3166_2: "ISO 3166-2:RW",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+250",
    isoCode: "RW",
    flag: "https://www.countryflags.io/RW/flat/64.png",
  },
  {
    name: "Saint Barthélemy",
    alpha_2: "BL",
    alpha_3: "BLM",
    country_code: "652",
    iso_3166_2: "ISO 3166-2:BL",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+590",
    isoCode: "BL",
    flag: "https://www.countryflags.io/BL/flat/64.png",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha_2: "SH",
    alpha_3: "SHN",
    country_code: "654",
    iso_3166_2: "ISO 3166-2:SH",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+290",
    isoCode: "SH",
    flag: "https://www.countryflags.io/SH/flat/64.png",
  },
  {
    name: "Saint Kitts and Nevis",
    alpha_2: "KN",
    alpha_3: "KNA",
    country_code: "659",
    iso_3166_2: "ISO 3166-2:KN",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1869",
    isoCode: "KN",
    flag: "https://www.countryflags.io/KN/flat/64.png",
  },
  {
    name: "Saint Lucia",
    alpha_2: "LC",
    alpha_3: "LCA",
    country_code: "662",
    iso_3166_2: "ISO 3166-2:LC",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1758",
    isoCode: "LC",
    flag: "https://www.countryflags.io/LC/flat/64.png",
  },
  {
    name: "Saint Martin (French part)",
    alpha_2: "MF",
    alpha_3: "MAF",
    country_code: "663",
    iso_3166_2: "ISO 3166-2:MF",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+590",
    isoCode: "MF",
    flag: "https://www.countryflags.io/MF/flat/64.png",
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha_2: "PM",
    alpha_3: "SPM",
    country_code: "666",
    iso_3166_2: "ISO 3166-2:PM",
    region: "Americas",
    sub_region: "Northern America",
    intermediate_region: "",
    region_code: "019",
    sub_region_code: "021",
    intermediate_region_code: "",
    dialCode: "+508",
    isoCode: "PM",
    flag: "https://www.countryflags.io/PM/flat/64.png",
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha_2: "VC",
    alpha_3: "VCT",
    country_code: "670",
    iso_3166_2: "ISO 3166-2:VC",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1784",
    isoCode: "VC",
    flag: "https://www.countryflags.io/VC/flat/64.png",
  },
  {
    name: "Samoa",
    alpha_2: "WS",
    alpha_3: "WSM",
    country_code: "882",
    iso_3166_2: "ISO 3166-2:WS",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+685",
    isoCode: "WS",
    flag: "https://www.countryflags.io/WS/flat/64.png",
  },
  {
    name: "San Marino",
    alpha_2: "SM",
    alpha_3: "SMR",
    country_code: "674",
    iso_3166_2: "ISO 3166-2:SM",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+378",
    isoCode: "SM",
    flag: "https://www.countryflags.io/SM/flat/64.png",
  },
  {
    name: "Sao Tome and Principe",
    alpha_2: "ST",
    alpha_3: "STP",
    country_code: "678",
    iso_3166_2: "ISO 3166-2:ST",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Middle Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "017",
    dialCode: "+239",
    isoCode: "ST",
    flag: "https://www.countryflags.io/ST/flat/64.png",
  },
  {
    name: "Saudi Arabia",
    alpha_2: "SA",
    alpha_3: "SAU",
    country_code: "682",
    iso_3166_2: "ISO 3166-2:SA",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+966",
    isoCode: "SA",
    flag: "https://www.countryflags.io/SA/flat/64.png",
  },
  {
    name: "Senegal",
    alpha_2: "SN",
    alpha_3: "SEN",
    country_code: "686",
    iso_3166_2: "ISO 3166-2:SN",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+221",
    isoCode: "SN",
    flag: "https://www.countryflags.io/SN/flat/64.png",
  },
  {
    name: "Serbia",
    alpha_2: "RS",
    alpha_3: "SRB",
    country_code: "688",
    iso_3166_2: "ISO 3166-2:RS",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+381",
    isoCode: "RS",
    flag: "https://www.countryflags.io/RS/flat/64.png",
  },
  {
    name: "Seychelles",
    alpha_2: "SC",
    alpha_3: "SYC",
    country_code: "690",
    iso_3166_2: "ISO 3166-2:SC",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+248",
    isoCode: "SC",
    flag: "https://www.countryflags.io/SC/flat/64.png",
  },
  {
    name: "Sierra Leone",
    alpha_2: "SL",
    alpha_3: "SLE",
    country_code: "694",
    iso_3166_2: "ISO 3166-2:SL",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+232",
    isoCode: "SL",
    flag: "https://www.countryflags.io/SL/flat/64.png",
  },
  {
    name: "Singapore",
    alpha_2: "SG",
    alpha_3: "SGP",
    country_code: "702",
    iso_3166_2: "ISO 3166-2:SG",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+65",
    isoCode: "SG",
    flag: "https://www.countryflags.io/SG/flat/64.png",
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha_2: "SX",
    alpha_3: "SXM",
    country_code: "534",
    iso_3166_2: "ISO 3166-2:SX",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1721",
    isoCode: "SX",
    flag: "https://www.countryflags.io/SX/flat/64.png",
  },
  {
    name: "Slovakia",
    alpha_2: "SK",
    alpha_3: "SVK",
    country_code: "703",
    iso_3166_2: "ISO 3166-2:SK",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+421",
    isoCode: "SK",
    flag: "https://www.countryflags.io/SK/flat/64.png",
  },
  {
    name: "Slovenia",
    alpha_2: "SI",
    alpha_3: "SVN",
    country_code: "705",
    iso_3166_2: "ISO 3166-2:SI",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+386",
    isoCode: "SI",
    flag: "https://www.countryflags.io/SI/flat/64.png",
  },
  {
    name: "Solomon Islands",
    alpha_2: "SB",
    alpha_3: "SLB",
    country_code: "090",
    iso_3166_2: "ISO 3166-2:SB",
    region: "Oceania",
    sub_region: "Melanesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "054",
    intermediate_region_code: "",
    dialCode: "+677",
    isoCode: "SB",
    flag: "https://www.countryflags.io/SB/flat/64.png",
  },
  {
    name: "Somalia",
    alpha_2: "SO",
    alpha_3: "SOM",
    country_code: "706",
    iso_3166_2: "ISO 3166-2:SO",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+252",
    isoCode: "SO",
    flag: "https://www.countryflags.io/SO/flat/64.png",
  },
  {
    name: "South Africa",
    alpha_2: "ZA",
    alpha_3: "ZAF",
    country_code: "710",
    iso_3166_2: "ISO 3166-2:ZA",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Southern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "018",
    dialCode: "+27",
    isoCode: "ZA",
    flag: "https://www.countryflags.io/ZA/flat/64.png",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha_2: "GS",
    alpha_3: "SGS",
    country_code: "239",
    iso_3166_2: "ISO 3166-2:GS",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+500",
    isoCode: "GS",
    flag: "https://www.countryflags.io/GS/flat/64.png",
  },
  {
    name: "South Sudan",
    alpha_2: "SS",
    alpha_3: "SSD",
    country_code: "728",
    iso_3166_2: "ISO 3166-2:SS",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+211",
    isoCode: "SS",
    flag: "https://www.countryflags.io/SS/flat/64.png",
  },
  {
    name: "Spain",
    alpha_2: "ES",
    alpha_3: "ESP",
    country_code: "724",
    iso_3166_2: "ISO 3166-2:ES",
    region: "Europe",
    sub_region: "Southern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "039",
    intermediate_region_code: "",
    dialCode: "+34",
    isoCode: "ES",
    flag: "https://www.countryflags.io/ES/flat/64.png",
  },
  {
    name: "Sri Lanka",
    alpha_2: "LK",
    alpha_3: "LKA",
    country_code: "144",
    iso_3166_2: "ISO 3166-2:LK",
    region: "Asia",
    sub_region: "Southern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "034",
    intermediate_region_code: "",
    dialCode: "+94",
    isoCode: "LK",
    flag: "https://www.countryflags.io/LK/flat/64.png",
  },
  {
    name: "Sudan",
    alpha_2: "SD",
    alpha_3: "SDN",
    country_code: "729",
    iso_3166_2: "ISO 3166-2:SD",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+249",
    isoCode: "SD",
    flag: "https://www.countryflags.io/SD/flat/64.png",
  },
  {
    name: "Suriname",
    alpha_2: "SR",
    alpha_3: "SUR",
    country_code: "740",
    iso_3166_2: "ISO 3166-2:SR",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+597",
    isoCode: "SR",
    flag: "https://www.countryflags.io/SR/flat/64.png",
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha_2: "SJ",
    alpha_3: "SJM",
    country_code: "744",
    iso_3166_2: "ISO 3166-2:SJ",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+47",
    isoCode: "SJ",
    flag: "https://www.countryflags.io/SJ/flat/64.png",
  },
  {
    name: "Sweden",
    alpha_2: "SE",
    alpha_3: "SWE",
    country_code: "752",
    iso_3166_2: "ISO 3166-2:SE",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+46",
    isoCode: "SE",
    flag: "https://www.countryflags.io/SE/flat/64.png",
  },
  {
    name: "Switzerland",
    alpha_2: "CH",
    alpha_3: "CHE",
    country_code: "756",
    iso_3166_2: "ISO 3166-2:CH",
    region: "Europe",
    sub_region: "Western Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "155",
    intermediate_region_code: "",
    dialCode: "+41",
    isoCode: "CH",
    flag: "https://www.countryflags.io/CH/flat/64.png",
  },
  {
    name: "Syrian Arab Republic",
    alpha_2: "SY",
    alpha_3: "SYR",
    country_code: "760",
    iso_3166_2: "ISO 3166-2:SY",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+963",
    isoCode: "SY",
    flag: "https://www.countryflags.io/SY/flat/64.png",
  },
  {
    name: "Taiwan, Province of China",
    alpha_2: "TW",
    alpha_3: "TWN",
    country_code: "158",
    iso_3166_2: "ISO 3166-2:TW",
    region: "Asia",
    sub_region: "Eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "030",
    intermediate_region_code: "",
    dialCode: "+886",
    isoCode: "TW",
    flag: "https://www.countryflags.io/TW/flat/64.png",
  },
  {
    name: "Tajikistan",
    alpha_2: "TJ",
    alpha_3: "TJK",
    country_code: "762",
    iso_3166_2: "ISO 3166-2:TJ",
    region: "Asia",
    sub_region: "Central Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "143",
    intermediate_region_code: "",
    dialCode: "+992",
    isoCode: "TJ",
    flag: "https://www.countryflags.io/TJ/flat/64.png",
  },
  {
    name: "Tanzania, United Republic of",
    alpha_2: "TZ",
    alpha_3: "TZA",
    country_code: "834",
    iso_3166_2: "ISO 3166-2:TZ",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+255",
    isoCode: "TZ",
    flag: "https://www.countryflags.io/TZ/flat/64.png",
  },
  {
    name: "Thailand",
    alpha_2: "TH",
    alpha_3: "THA",
    country_code: "764",
    iso_3166_2: "ISO 3166-2:TH",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+66",
    isoCode: "TH",
    flag: "https://www.countryflags.io/TH/flat/64.png",
  },
  {
    name: "Timor-Leste",
    alpha_2: "TL",
    alpha_3: "TLS",
    country_code: "626",
    iso_3166_2: "ISO 3166-2:TL",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+670",
    isoCode: "TL",
    flag: "https://www.countryflags.io/TL/flat/64.png",
  },
  {
    name: "Togo",
    alpha_2: "TG",
    alpha_3: "TGO",
    country_code: "768",
    iso_3166_2: "ISO 3166-2:TG",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Western Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "011",
    dialCode: "+228",
    isoCode: "TG",
    flag: "https://www.countryflags.io/TG/flat/64.png",
  },
  {
    name: "Tokelau",
    alpha_2: "TK",
    alpha_3: "TKL",
    country_code: "772",
    iso_3166_2: "ISO 3166-2:TK",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+690",
    isoCode: "TK",
    flag: "https://www.countryflags.io/TK/flat/64.png",
  },
  {
    name: "Tonga",
    alpha_2: "TO",
    alpha_3: "TON",
    country_code: "776",
    iso_3166_2: "ISO 3166-2:TO",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+676",
    isoCode: "TO",
    flag: "https://www.countryflags.io/TO/flat/64.png",
  },
  {
    name: "Trinidad and Tobago",
    alpha_2: "TT",
    alpha_3: "TTO",
    country_code: "780",
    iso_3166_2: "ISO 3166-2:TT",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1868",
    isoCode: "TT",
    flag: "https://www.countryflags.io/TT/flat/64.png",
  },
  {
    name: "Tunisia",
    alpha_2: "TN",
    alpha_3: "TUN",
    country_code: "788",
    iso_3166_2: "ISO 3166-2:TN",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+216",
    isoCode: "TN",
    flag: "https://www.countryflags.io/TN/flat/64.png",
  },
  {
    name: "Turkey",
    alpha_2: "TR",
    alpha_3: "TUR",
    country_code: "792",
    iso_3166_2: "ISO 3166-2:TR",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+90",
    isoCode: "TR",
    flag: "https://www.countryflags.io/TR/flat/64.png",
  },
  {
    name: "Turkmenistan",
    alpha_2: "TM",
    alpha_3: "TKM",
    country_code: "795",
    iso_3166_2: "ISO 3166-2:TM",
    region: "Asia",
    sub_region: "Central Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "143",
    intermediate_region_code: "",
    dialCode: "+993",
    isoCode: "TM",
    flag: "https://www.countryflags.io/TM/flat/64.png",
  },
  {
    name: "Turks and Caicos Islands",
    alpha_2: "TC",
    alpha_3: "TCA",
    country_code: "796",
    iso_3166_2: "ISO 3166-2:TC",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1649",
    isoCode: "TC",
    flag: "https://www.countryflags.io/TC/flat/64.png",
  },
  {
    name: "Tuvalu",
    alpha_2: "TV",
    alpha_3: "TUV",
    country_code: "798",
    iso_3166_2: "ISO 3166-2:TV",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+688",
    isoCode: "TV",
    flag: "https://www.countryflags.io/TV/flat/64.png",
  },
  {
    name: "Uganda",
    alpha_2: "UG",
    alpha_3: "UGA",
    country_code: "800",
    iso_3166_2: "ISO 3166-2:UG",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+256",
    isoCode: "UG",
    flag: "https://www.countryflags.io/UG/flat/64.png",
  },
  {
    name: "Ukraine",
    alpha_2: "UA",
    alpha_3: "UKR",
    country_code: "804",
    iso_3166_2: "ISO 3166-2:UA",
    region: "Europe",
    sub_region: "Eastern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "151",
    intermediate_region_code: "",
    dialCode: "+380",
    isoCode: "UA",
    flag: "https://www.countryflags.io/UA/flat/64.png",
  },
  {
    name: "United Arab Emirates",
    alpha_2: "AE",
    alpha_3: "ARE",
    country_code: "784",
    iso_3166_2: "ISO 3166-2:AE",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+971",
    isoCode: "AE",
    flag: "https://www.countryflags.io/AE/flat/64.png",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha_2: "GB",
    alpha_3: "GBR",
    country_code: "826",
    iso_3166_2: "ISO 3166-2:GB",
    region: "Europe",
    sub_region: "Northern Europe",
    intermediate_region: "",
    region_code: "150",
    sub_region_code: "154",
    intermediate_region_code: "",
    dialCode: "+44",
    isoCode: "GB",
    flag: "https://www.countryflags.io/GB/flat/64.png",
  },
  {
    name: "United States of America",
    alpha_2: "US",
    alpha_3: "USA",
    country_code: "840",
    iso_3166_2: "ISO 3166-2:US",
    region: "Americas",
    sub_region: "Northern America",
    intermediate_region: "",
    region_code: "019",
    sub_region_code: "021",
    intermediate_region_code: "",
    dialCode: "+1",
    isoCode: "US",
    flag: "https://www.countryflags.io/US/flat/64.png",
  },
  {
    name: "United States Minor Outlying Islands",
    alpha_2: "UM",
    alpha_3: "UMI",
    country_code: "581",
    iso_3166_2: "ISO 3166-2:UM",
    region: "Oceania",
    sub_region: "Micronesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "057",
    intermediate_region_code: "",
    dialCode: "+246",
    isoCode: "UM",
    flag: "https://www.countryflags.io/UM/flat/64.png",
  },
  {
    name: "Uruguay",
    alpha_2: "UY",
    alpha_3: "URY",
    country_code: "858",
    iso_3166_2: "ISO 3166-2:UY",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+598",
    isoCode: "UY",
    flag: "https://www.countryflags.io/UY/flat/64.png",
  },
  {
    name: "Uzbekistan",
    alpha_2: "UZ",
    alpha_3: "UZB",
    country_code: "860",
    iso_3166_2: "ISO 3166-2:UZ",
    region: "Asia",
    sub_region: "Central Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "143",
    intermediate_region_code: "",
    dialCode: "+998",
    isoCode: "UZ",
    flag: "https://www.countryflags.io/UZ/flat/64.png",
  },
  {
    name: "Vanuatu",
    alpha_2: "VU",
    alpha_3: "VUT",
    country_code: "548",
    iso_3166_2: "ISO 3166-2:VU",
    region: "Oceania",
    sub_region: "Melanesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "054",
    intermediate_region_code: "",
    dialCode: "+678",
    isoCode: "VU",
    flag: "https://www.countryflags.io/VU/flat/64.png",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha_2: "VE",
    alpha_3: "VEN",
    country_code: "862",
    iso_3166_2: "ISO 3166-2:VE",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "South America",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "005",
    dialCode: "+58",
    isoCode: "VE",
    flag: "https://www.countryflags.io/VE/flat/64.png",
  },
  {
    name: "Viet Nam",
    alpha_2: "VN",
    alpha_3: "VNM",
    country_code: "704",
    iso_3166_2: "ISO 3166-2:VN",
    region: "Asia",
    sub_region: "South-eastern Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "035",
    intermediate_region_code: "",
    dialCode: "+84",
    isoCode: "VN",
    flag: "https://www.countryflags.io/VN/flat/64.png",
  },
  {
    name: "Virgin Islands (British)",
    alpha_2: "VG",
    alpha_3: "VGB",
    country_code: "092",
    iso_3166_2: "ISO 3166-2:VG",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1284",
    isoCode: "VG",
    flag: "https://www.countryflags.io/VG/flat/64.png",
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha_2: "VI",
    alpha_3: "VIR",
    country_code: "850",
    iso_3166_2: "ISO 3166-2:VI",
    region: "Americas",
    sub_region: "Latin America and the Caribbean",
    intermediate_region: "Caribbean",
    region_code: "019",
    sub_region_code: "419",
    intermediate_region_code: "029",
    dialCode: "+1340",
    isoCode: "VI",
    flag: "https://www.countryflags.io/VI/flat/64.png",
  },
  {
    name: "Wallis and Futuna",
    alpha_2: "WF",
    alpha_3: "WLF",
    country_code: "876",
    iso_3166_2: "ISO 3166-2:WF",
    region: "Oceania",
    sub_region: "Polynesia",
    intermediate_region: "",
    region_code: "009",
    sub_region_code: "061",
    intermediate_region_code: "",
    dialCode: "+681",
    isoCode: "WF",
    flag: "https://www.countryflags.io/WF/flat/64.png",
  },
  {
    name: "Western Sahara",
    alpha_2: "EH",
    alpha_3: "ESH",
    country_code: "732",
    iso_3166_2: "ISO 3166-2:EH",
    region: "Africa",
    sub_region: "Northern Africa",
    intermediate_region: "",
    region_code: "002",
    sub_region_code: "015",
    intermediate_region_code: "",
    dialCode: "+212",
    flag: "https://www.countryflags.io/EH/flat/64.png",
  },
  {
    name: "Yemen",
    alpha_2: "YE",
    alpha_3: "YEM",
    country_code: "887",
    iso_3166_2: "ISO 3166-2:YE",
    region: "Asia",
    sub_region: "Western Asia",
    intermediate_region: "",
    region_code: "142",
    sub_region_code: "145",
    intermediate_region_code: "",
    dialCode: "+967",
    isoCode: "YE",
    flag: "https://www.countryflags.io/YE/flat/64.png",
  },
  {
    name: "Zambia",
    alpha_2: "ZM",
    alpha_3: "ZMB",
    country_code: "894",
    iso_3166_2: "ISO 3166-2:ZM",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+260",
    isoCode: "ZM",
    flag: "https://www.countryflags.io/ZM/flat/64.png",
  },
  {
    name: "Zimbabwe",
    alpha_2: "ZW",
    alpha_3: "ZWE",
    country_code: "716",
    iso_3166_2: "ISO 3166-2:ZW",
    region: "Africa",
    sub_region: "Sub-Saharan Africa",
    intermediate_region: "Eastern Africa",
    region_code: "002",
    sub_region_code: "202",
    intermediate_region_code: "014",
    dialCode: "+263",
    isoCode: "ZW",
    flag: "https://www.countryflags.io/ZW/flat/64.png",
  },

  // {
  //   name: "Ascension Island",
  //   dialCode: "+247",
  //   isoCode: "AC",
  //   flag: "https://cdn.kcak11.com/flags/AC_64.png",
  // },
  // {
  //   name: "Cape Verde",
  //   dialCode: "+238",
  //   isoCode: "CV",
  //   flag: "https://www.countryflags.io/CV/flat/64.png",
  // },
  // {
  //   name: "Democratic Republic of the Congo",
  //   dialCode: "+243",
  //   isoCode: "CD",
  //   flag: "https://www.countryflags.io/CD/flat/64.png",
  // },
  // {
  //   name: "Ivory Coast / Cote d'Ivoire",
  //   dialCode: "+225",
  //   isoCode: "CI",
  //   flag: "https://www.countryflags.io/CI/flat/64.png",
  // },
  // {
  //   name: "Kosovo",
  //   dialCode: "+383",
  //   isoCode: "XK",
  //   flag: "https://www.countryflags.io/XK/flat/64.png",
  // },
  // {
  //   name: "Netherlands Antilles",
  //   dialCode: "+599",
  //   isoCode: "AN",
  //   flag: "https://www.countryflags.io/AN/flat/64.png",
  // },
];
