import { BUCKET_NAME } from './../constants';

/**
 * Get Public URL from Storage Key
 * @param key Storage Key
 */
export const GetPublicURL = (key) => {
  if (key && key.startsWith('https://')) return key;
  return key ? `https://firebasestorage.googleapis.com/v0/b/${BUCKET_NAME}/o/${encodeURIComponent(key)}?alt=media` : null;
};

/**
 * Check URL is Storage Public URL
 * @param url Public URL to be checked
 */
export const IsStorageURL = (url) => {
  return url ? url.startsWith(`https://firebasestorage.googleapis.com/v0/b/${BUCKET_NAME}/o/`) : false;
};

export async function uploadWithSignURL(url, blobData, content_type='application/octet-stream') {
    return new Promise( (resolve, reject) => {  
      fetch( url, {
            method: 'PUT',
            headers: {
                'Cache-Control': 'public,max-age=3600',
                'Content-Type': content_type
            },
            body: blobData
        })
        .then(response => {
          if (response.status / 100 === 2) resolve({ message: 'Success' });
          reject(response);
        });
    });
}
