import React from 'react'

function SendMessageBox({ _handleKeyPress, updateMessageValue, submitComment, message }) {
    return (
        <div className="gx-chat-main-footer">
            <div
                className="gx-flex-row gx-align-items-center"
                style={{ maxHeight: 51 }}
            >
                <div className="gx-col">
                    <div className="gx-form-group">
                        <textarea
                            id="required"
                            className="gx-border-0 ant-input gx-chat-textarea"
                            onKeyUp={_handleKeyPress}
                            onChange={updateMessageValue}
                            value={message}
                            placeholder="Type and hit enter to send message"
                        />
                    </div>
                </div>
                <span
                    className="gx-icon-btn snd-btn-jr"
                    onClick={submitComment}
                >
                    <i className="fas fa-paper-plane"></i>
                </span>
            </div>
        </div>
    )
}

export default SendMessageBox
