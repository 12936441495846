import React from "react";
import { Button, Spin } from "antd";
import "./OrderSummaryCard.less";
import { DEFAULT_CURRENCY } from "../../../../constants";

export default function OrderSummaryCard({ selectedItems, checkoutItems }) {
  const { selectedRows, subTotal, shipping, total } = selectedItems;
  return (
    <div className="perant-sumry">
      <div
        // style={{ backgroundColor: "rgb(250, 250, 250)" }}
        className="gx-card p-4 sumry-crd-pstn-new"
      >
        <Spin spinning={selectedItems.pending}>
          <h5>Order Summary</h5>

          <div className="gx-media-body mt-3">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Selected Items
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {selectedRows.length}
            </p>
          </div>

          <div className="gx-media-body">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Subtotal
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {`${DEFAULT_CURRENCY} ${subTotal}`}
            </p>
          </div>

          <div className="gx-media-body">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Shipping
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {`${DEFAULT_CURRENCY} ${shipping}`}
            </p>
          </div>
          <hr className="mt-2 mb-2"></hr>
          <div className="gx-media-body">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Total
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {`${DEFAULT_CURRENCY} ${total}`}
            </p>
          </div>
          <Button onClick={checkoutItems} className="buybtn">
            Buy Now
          </Button>
        </Spin>
      </div>
    </div>
  );
}
