import React from "react";
import { Badge } from "antd";
import "./HotDealCard.less";
import Widget from "../../../Common/Widget/Widget";
import { Link } from "react-router-dom";

const HotDealCard = ({ data, isLoading }) => {
  return (
    <Link to={isLoading ? "" : `/product/${data.id}`}>
      <Widget styleName="gx-card-full gx-dot-arrow-hot-deals-hover bdr-jr mb-0 mQ-hegt-jr">
        <div className="gx-media gx-align-items-center gx-flex-nowrap">
          <div className="gx-px-3 gx-build-box-lay">
            <img
              alt="..."
              src={data && data.images && data.images[0]}
              className=""
              style={{ height: "65px", borderRadius: "5px", minWidth: "65px" }}
            />
          </div>
          <div className="gx-media-body gx-py-3 gx-pr-3 gx-build-box-lay-content title-crd-jr">
            <Badge
              className="gx-badge-radius-sm gx-mb-1 bdg-bdr"
              count={`${data && data.discount_percentage}% OFF`}
              style={{ backgroundColor: "#52c41a" }}
            />
            <h6 className="jr-text-truncate gx-mb-0 mt-1 h6-jr nw-jr-fnt-clr">
              {data && data.name}
            </h6>

            <p className="gx-mb-0 gx-fs-sm">
              {data && data.seller && data.seller.name}
            </p>

            <div className="gx-dot-arrow-hot-deals">
              <div className="new-color-cicle gx-hover-arrow-hot-deals">
                <i className="fad fa-external-link-alt icn-styl"></i>
              </div>
              <div className="gx-dot-hot-deals">
                <i className="icon icon-ellipse-v gx-text-white" />
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </Link>
  );
};

export default HotDealCard;
