import React from "react";
import { Avatar } from "antd";

function ChatHeader({ onToggleDrawer, selectedUser }) {
  return (
    <div className="gx-chat-main-header">
      <span className="gx-d-block gx-d-lg-none gx-chat-btn">
        <i className="gx-icon-btn icon icon-chat" onClick={onToggleDrawer} />
      </span>
      <div className="gx-chat-main-header-info">
        <div className="gx-chat-avatar gx-mr-2">
          <div className="gx-status-pos">
            <Avatar
              src={selectedUser.thumb}
              className="gx-rounded-circle gx-size-60"
              alt=""
            />
            {/* <span className={`gx-status gx-small gx-${selectedUser.status}`} /> */}
          </div>
        </div>

        <div className="gx-chat-contact-name">{selectedUser.name}</div>
      </div>
    </div>
  );
}

export default ChatHeader;
