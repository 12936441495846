const report_header = "resolution_center/";
// Types
export default {
  GET_REPORTITEM_DETAILS: report_header + "GET_REPORTITEM_DETAILS",
  GET_REPORTITEM_SUCCESS: report_header + "GET_REPORTITEM_SUCCESS",
  GET_REPORTITEM_FAILED: report_header + "GET_REPORTITEM_FAILED",

  SELECT_REPORT_iTEM: report_header + "SELECT_REPORT_iTEM",

  OPEN_NEW_CASE: report_header + "OPEN_NEW_CASE",
  OPEN_NEW_CASE_SUCCESS: report_header + "OPEN_NEW_CASE_SUCCESS",
  OPEN_NEW_CASE_FAIL: report_header + "OPEN_NEW_CASE_FAIL",

  GET_CASE: report_header + "GET_CASE",
  GET_CASE_SUCCESS: report_header + "GET_CASE_SUCCESS",
  GET_CASE_FAIL: report_header + "GET_CASE_FAIL",

  UPDATE_CASE: report_header + "UPDATE_CASE",
  UPDATE_CASE_SUCCESS: report_header + "UPDATE_CASE_SUCCESS",
  UPDATE_CASE_FAIL: report_header + "UPDATE_CASE_FAIL",

  GET_RESOLUTION_CENTER_CASES: report_header + "GET_RESOLUTION_CENTER_CASES",
  GET_RESOLUTION_CENTER_CASES_SUCCESS:
    report_header + "GET_RESOLUTION_CENTER_CASES_SUCCESS",
  GET_RESOLUTION_CENTER_CASES_FAIL:
    report_header + "GET_RESOLUTION_CENTER_CASES_FAIL",

  HANDLE_REPORT_ITEM_IMAGE: report_header + "HANDLE_REPORT_ITEM_IMAGE",
  HANDLE_UPDATE_CASE_IMAGE: report_header + "HANDLE_UPDATE_CASE_IMAGE",

  //clear single case data
  CLEAR_CASE: report_header + "CLEAR_CASE",
};
