import React, { Component } from "react";
import { Card, Table, Button } from "antd";

class OrderOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const columns = [
      {
        title: "Order No.",
        width: 100,
        dataIndex: "orderno",
        key: "orderno",
        fixed: "left",
      },
      { title: "Order Date", width: 200, dataIndex: "orderdate", key: "1" },
      { title: "Order item id", dataIndex: "orderitemid", key: "2" },
      { title: "Seller SKU", dataIndex: "sellersku", key: "3" },
      { title: "Unit Price", dataIndex: "unitprice", key: "4" },
      { title: "Commission", dataIndex: "commission", key: "5" },
      { title: "Fees", dataIndex: "fees", key: "6" },
      { title: "Other Credits", dataIndex: "other", key: "7" },
      { title: "VAT", dataIndex: "vat", key: "8" },
      { title: "WHT", dataIndex: "wht", key: "9" },
      { title: "Payout Amount", dataIndex: "payout", key: "10" },
      { title: "Operational Status", dataIndex: "operational", key: "11" },
      { title: "Payout Status", dataIndex: "payoutstatus", key: "12" },
    ];

    const data = [
      {
        orderno: "1",
        transactiontype: "1",
        orderitemid: "1",
        sellersku: "1",
        transactionnum: "1",
        detail: "Detail",
        orderdate: "2020-10-12",
        commission: "10%",
        unitprice: "10",
        ordernum: "1",
        vat: "2%",
        wht: "3%",
        fees: "45",
        payout: "32",
        payoutstatus: "ok",
        operational: "position",
        other: "null",
      },
      {
        orderno: "2",
        transactiontype: "2",
        orderitemid: "2",
        sellersku: "2",
        transactionnum: "2",
        detail: "Detail",
        orderdate: "2020-01-12",
        commission: "20%",
        unitprice: "20",
        ordernum: "2",
        vat: "6%",
        wht: "8%",
        fees: "50",
        payout: "26",
        payoutstatus: "ok",
        operational: "position",
        other: "null",
      },
    ];

    const title = (
      <div>
        <h5 style={{ display: "inline-block" }}>Order Overview</h5>
        <Button
          type="primary"
          style={{ display: "inline-block", float: "right" }}
        >
          Export
        </Button>
      </div>
    );

    return (
      <Card title={title}>
        <Table columns={columns} dataSource={data} scroll={{ x: 1300 }} />
      </Card>
    );
  }
}

export default OrderOverview;
