import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import QnACard from "../QnACard/QnACard";
import { Input, Col, Row, Button, Card } from "antd";
import moment from "moment";
import { cartActions } from "../../../../../modules/Cart/ducks";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import AlgoliaFooter from "../../../../Common/AlgoliaFooter/AlgoliaFooter";
import { Pagination } from "react-instantsearch-dom";
const { TextArea } = Input;

class QnAList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      hits: [],
      user: null,
    };
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser = async () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({ user });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { hits } = this.props;
    if (JSON.stringify(hits) !== JSON.stringify(prevState.hits)) {
      this.setState({ hits: hits });
    }
  }

  handleChange = (e) => {
    this.setState({ content: e.target.value, hits: [...this.state.hits] });
  };

  addProductFAQS = () => {
    const { content } = this.state;
    const { cartActions, match } = this.props;
    let productID = match && match.params && match.params.id;
    if (content && content.length !== 0) {
      cartActions.addProductFAQS({
        productID,
        content: content,
        callback: this.addProductFAQSCallBack,
      });
    } else {
      NotificationManager.error(
        <IntlMessages id="validation.addQnA.content" />,
        <IntlMessages id="validation.addQnA.title" />
      );
    }
  };
  addProductFAQSCallBack = (newContent) => {
    let newContentObj = {
      answer: null,
      answered: false,
      asked_by: "my_self",
      created_at: moment().format(),
      id: moment().format(),
      objectID: moment().format(),
      product: null,
      product_id: "a1ed1870-a268-11ea-a691-cfca0ccbdc51",
      question: newContent,
      seller_id: "5fed78e1-9cdf-11ea-b30b-95e78254a84d",
    };
    this.setState({ content: null, hits: [newContentObj, ...this.state.hits] });
  };
  render() {
    const { isMine, productFAQS, isOwnProduct } = this.props;
    const { hits, content } = this.state;
    return (
      <div id="product">
        {isMine && !isOwnProduct ? (
          <Card className="gx-card reduce-pdng">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="display-web-s mt-1 ml-2">Ask your question</div>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <TextArea
                  className="mt-1"
                  onChange={this.handleChange}
                  placeholder="Question"
                  rows={3}
                  value={content}
                />
                <Button
                  loading={productFAQS.pending}
                  onClick={this.addProductFAQS}
                  type="primary"
                  style={{
                    float: "right",
                    marginTop: "10px",
                    marginBottom: "0px",
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Card>
        ) : null}
        {isMine && !isOwnProduct && hits.length !== 0 ? (
          <React.Fragment>
            <h6 className="mb-3 ml-2">{"My questions"}</h6>

            {hits.map((data) => (
              <QnACard
                data={data}
                key={data.objectID}
                styleName="gx-card-list"
              />
            ))}
            <AlgoliaFooter>
              {hits.length !== 0 && <Pagination showLast={true} />}
            </AlgoliaFooter>
          </React.Fragment>
        ) : !isOwnProduct && hits.length !== 0 ? (
          <React.Fragment>
            <h6 className="mb-3 ml-2">{"Questions"}</h6>

            {hits.map((data) => (
              <QnACard
                data={data}
                key={data.objectID}
                styleName="gx-card-list"
              />
            ))}
            <AlgoliaFooter>
              {hits.length !== 0 && <Pagination showLast={true} />}
            </AlgoliaFooter>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.Cart.product,
    productFAQS: state.Cart.productFAQS,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QnAList)
);
