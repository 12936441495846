import React from "react";
import { Col, Row, Divider } from "antd";
import IntlMessages from "util/IntlMessages";
import TopSellerCard from "../../Card/TopSellerCard/TopSellerCard";
import CardBox from "../../Card/CardBox/CardBox";
import "./TopSeller.less";

import { topSellers } from "../CarouselData";

const TopSeller = ({ data }) => {
  return (
    <Row>
      <Col span={24}>
        <CardBox styleName="gx-text-center">
          <Divider className="mt-0 mb-2">
            <h4 className="nw-jr-fnt-clr">
              <IntlMessages id="topsellers" />
            </h4>
          </Divider>
          <Row>
            {data.loading
              ? topSellers.map((seller, i) => {
                  return (
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      sm={8}
                      xs={12}
                      key={i}
                      className="mmgnnnnnn"
                    >
                      <TopSellerCard
                        key={i}
                        data={seller}
                        loading={data.loading}
                      />
                    </Col>
                  );
                })
              : data.data.slice(0, 6).map((seller, i) => {
                  return (
                    <Col
                      xl={4}
                      lg={4}
                      md={6}
                      sm={8}
                      xs={12}
                      key={i}
                      className="mmgnnnnnn"
                    >
                      <TopSellerCard
                        key={i}
                        data={seller}
                        loading={data.loading}
                      />
                    </Col>
                  );
                })}
          </Row>
        </CardBox>
      </Col>
    </Row>
  );
};

export default TopSeller;
