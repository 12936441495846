import React from "react";
import "./LoginLoader.less";

export default function LoginLoader() {
  return (
    // <div className="tr-login-loading">
    //   <div className="uil-ring-css" style={{ transform: "scale(0.79)" }}>
    //     <div />
    //   </div>
    // </div>
    <div className="tr-login-loader-container">
      <div className="loadingspinner"></div>
    </div>
  );
}
