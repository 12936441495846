import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Card, Select, Form } from "antd";
import { FormItemLayout } from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import { FormItemLayout2, serviceTypes } from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";

const Option = Select.Option;
const ASelectField = makeField(Select);
const FormItem = Form.Item;

class Services extends Component {
  render() {
    return (
      <div className="mb-3">
        <div style={{ display: "flex", alignItems: "center" }} className="mb-3">
          <h6 className="mb-0 mr-2">Service</h6>

          <ExtraInfo
            placement={"top"}
            title={extraInfoDetails.packageService}
          />
        </div>
        <Card className="jr-fld-catch " style={{ backgroundColor: "#fafafa" }}>
          <FormItem {...FormItemLayout} label="Warranty" required id="warranty">
            <Field
              formItemLayout={FormItemLayout2}
              name="warranty"
              component={ASelectField}
              style={{ width: "100%" }}
              hasFeedback
              addonAfter=".com"
            >
              {serviceTypes.map((serviceType, i) => {
                return (
                  <Option key={i} value={serviceType.value}>
                    {serviceType.label}
                  </Option>
                );
              })}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-35px" }}
            >
              <ExtraInfo title={extraInfoDetails.packageWarranty} />
            </span>
          </FormItem>
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Services = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(Services);

// You have to connect() to any reducers that you wish to connect to yourself
Services = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(Services);

export default Services;
