import React from "react";
import { Table, Popconfirm, Button } from "antd";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userManagementActions } from "../../../../modules/SellerDashboard/UserManagement/ducks";
import "../../TransactionOverview/TransactionContainer/TransactionList.less";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
    };
  }
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  userViewHandler = (type, record) => {
    this.props.history.push(
      `/seller/dashboard/userManagement/${type}/details/${"view"}/${
        record.objectID
      }`
    );
  };

  userActiveStatusHandler = (record) => {
    var id = record && record.objectID;
    this.props.userManagementActions.changeUserActiveStatus({ id: id });
  };

  userDeleteHandler = (record) => {
    var id = record && record.objectID;
    this.props.userManagementActions.deleteUser({ id: id });
  };
  render() {
    // let { sortedInfo, filteredInfo } = this.state;
    //   const { match } = this.props;

    let { hits, match } = this.props;
    const type = match.params && match.params.type;

    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};

    //------------------Admin Columns-----------------------------------
    const adminColumns = [
      {
        title: "Name",
        // width: "30%",

        render: (text, record) => (
          <div
          // style={{
          //   width: "400px",
          // }}
          >
            {record && record.first_name + " " + record.last_name}{" "}
            {record.active ? " Active" : " Deactive"}
            <br />
          </div>
        ),
      },
      {
        title: "Email",
        // width: "30%",

        render: (text, record) => (
          <div>
            {record && record.email}
            {record.email_verified ? " Verified" : null}
            <br />
          </div>
        ),
      },
      {
        title: "Create Date",
        // width: "30%",
        render: (text, record) => (
          <div>
            {moment(record.created_at).format("LLLL")}
            <br />
          </div>
        ),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <React.Fragment>
            <Button
              block
              className="gx-btn-outline-secondary mb-1 mt-0"
              onClick={() => this.userViewHandler(type, record)}
              // style={{ marginRight: "10px", marginBottom: "0" }}
            >
              View
            </Button>
            <Popconfirm
              title={`Sure to ${!record.active ? " Active" : " Deactive"} ?`}
              onConfirm={() => this.userActiveStatusHandler(record)}
            >
              <Button
                block
                className={
                  "mb-1 mt-0 " +
                  (!record.active
                    ? "gx-btn-outline-primary"
                    : " gx-btn-outline-danger")
                }
              >
                {!record.active ? " Active" : " Deactive"}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Sure to Delete ?`}
              onConfirm={() => this.userDeleteHandler(record)}
            >
              <Button className="gx-btn-danger mb-0 mt-0">Delete</Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    //------------------Moderators Columns-----------------------------------
    const moderatorColumns = [
      {
        title: "Name",
        // width: "30%",

        render: (text, record) => (
          <div
          // style={{
          //   width: "400px",
          // }}
          >
            {record && record.first_name + " " + record.last_name}
            {record.active ? " Active" : " Deactive"}
            <br />
          </div>
        ),
      },
      {
        title: "Email",
        // width: "30%",

        render: (text, record) => (
          <div>
            {record && record.email}
            {record.email_verified ? " Verified" : null}
            <br />
          </div>
        ),
      },
      {
        title: "Create Date",
        // width: "30%",
        render: (text, record) => (
          <div>
            {moment(record.created_at).format("LLLL")}
            <br />
          </div>
        ),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <React.Fragment>
            <Button
              block
              className="gx-btn-outline-secondary mb-1 mt-0"
              onClick={() => this.userViewHandler(type, record)}
              // style={{ marginRight: "10px", marginBottom: "0" }}
            >
              View
            </Button>
            <Popconfirm
              title={`Sure to ${!record.active ? " Active" : " Deactive"} ?`}
              onConfirm={() => this.userActiveStatusHandler(record)}
            >
              <Button
                block
                className={
                  "mb-1 mt-0 " +
                  (!record.active
                    ? "gx-btn-outline-primary"
                    : " gx-btn-outline-danger")
                }
              >
                {!record.active ? " Active" : " Deactive"}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Sure to Delete ?`}
              onConfirm={() => this.userDeleteHandler(record)}
            >
              <Button className="gx-btn-danger mb-0 mt-0">Delete</Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    //------------------Buyer Columns-----------------------------------
    const buyerColumns = [
      {
        title: "Name",
        // width: "30%",

        render: (text, record) => (
          <div
          // style={{
          //   width: "400px",
          // }}
          >
            {record && record.first_name + " " + record.last_name}
            {record.active ? " Active" : " Deactive"}
            <br />
          </div>
        ),
      },
      {
        title: "Email",
        // width: "30%",

        render: (text, record) => (
          <div>
            {record && record.email}
            {record.email_verified ? " Verified" : null}
            <br />
          </div>
        ),
      },
      {
        title: "Create Date",
        // width: "30%",
        render: (text, record) => (
          <div>
            {moment(record.created_at).format("LLLL")}
            <br />
          </div>
        ),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <React.Fragment>
            <Button
              block
              className="gx-btn-outline-secondary mb-1 mt-0"
              onClick={() => this.userViewHandler(type, record)}
              // style={{ marginRight: "10px", marginBottom: "0" }}
            >
              View
            </Button>
            <Popconfirm
              title={`Sure to ${!record.active ? " Active" : " Deactive"} ?`}
              onConfirm={() => this.userActiveStatusHandler(record)}
            >
              <Button
                block
                className={
                  "mb-1 mt-0 " +
                  (!record.active
                    ? "gx-btn-outline-primary"
                    : " gx-btn-outline-danger")
                }
              >
                {!record.active ? " Active" : " Deactive"}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Sure to Delete ?`}
              onConfirm={() => this.userDeleteHandler(record)}
            >
              <Button className="gx-btn-danger mb-0 mt-0">Delete</Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];

    //------------------Seller Columns-----------------------------------
    const sellerColumns = [
      {
        title: "Name",
        width: 250,

        render: (text, record) => (
          <div
          // style={{
          //   width: "400px",
          // }}
          >
            {record && record.name} {record.active ? " Active" : " Deactive"}
            <br />
          </div>
        ),
      },
      {
        title: "Email",
        width: 250,

        render: (text, record) => (
          <div>
            {record && record.email}
            {record.email_verified ? " Verified" : null}
            <br />
          </div>
        ),
      },
      {
        title: "Create Date",
        width: 250,
        render: (text, record) => (
          <div>
            {moment(record.created_at).format("LLLL")}
            <br />
          </div>
        ),
      },
      {
        title: "Seller Category",
        width: 250,

        render: (text, record) => (
          <div>
            {record.seller_category}
            <br />
          </div>
        ),
      },
      {
        title: "Action",
        key: "operation",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <React.Fragment>
            <Button
              block
              className="gx-btn-outline-secondary mb-1 mt-0"
              onClick={() => this.userViewHandler(type, record)}
              // style={{ marginRight: "10px", marginBottom: "0" }}
            >
              View
            </Button>
            <Popconfirm
              title={`Sure to ${!record.active ? " Active" : " Deactive"} ?`}
              onConfirm={() => this.userActiveStatusHandler(record)}
            >
              <Button
                block
                className={
                  "mb-1 mt-0 " +
                  (!record.active
                    ? "gx-btn-outline-primary"
                    : " gx-btn-outline-danger")
                }
              >
                {!record.active ? " Active" : " Deactive"}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Sure to Delete ?`}
              onConfirm={() => this.userDeleteHandler(record)}
            >
              <Button className="gx-btn-danger mb-0 mt-0">Delete</Button>
            </Popconfirm>
          </React.Fragment>
        ),
      },
    ];
    //-----------------------------------------------------------
    return (
      <React.Fragment>
        <Table
          className="gx-table-responsive trns-tbl-scrl"
          columns={
            type === "admin"
              ? adminColumns
              : type === "moderators"
              ? moderatorColumns
              : type === "buyers"
              ? buyerColumns
              : sellerColumns
          }
          dataSource={hits}
          scroll={{ x: 1500 }}
          onChange={this.handleChange}
          pagination={false}
          bordered
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getUser: state.UserManagement.getUser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserList)
);
