import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  userActiveStatus: {
    ...initialStateModal,
  },
  createUser: {
    ...initialStateModal,
  },
  getUser: {
    ...initialStateModal,
  },
  updateUser: {
    ...initialStateModal,
  },
  deleteUser: {
    ...initialStateModal,
  },
  userCount: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(

  // ************************************************************//
  {
    [types.CLEAR_USER]: (state, { payload }) => ({
      ...state,
      getUser: {
        ...initialStateModal,
      },
    }),
    // ************************************************************//
    [types.CHANGE_USER_ACTIVE_STATUS]: (state, { payload }) => ({
      ...state,
      userActiveStatus: {
        ...state.userActiveStatus,
        loading: true,
        pending: true,
      },
    }),
    [types.CHANGE_USER_ACTIVE_STATUS_SUCCESS]: (state, { payload }) => ({
      ...state,
      userActiveStatus: {
        ...state.userActiveStatus,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.CHANGE_USER_ACTIVE_STATUS_FAIL]: (state, { payload }) => ({
      ...state,
      userActiveStatus: {
        ...state.userActiveStatus,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------------------------------------------------- //
    // ************************************************************//
    [types.CREATE_USER]: (state, { payload }) => ({
      ...state,
      createUser: {
        ...state.createUser,
        loading: true,
        pending: true,
      },
    }),
    [types.CREATE_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      createUser: {
        ...state.createUser,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.CREATE_USER_FAIL]: (state, { payload }) => ({
      ...state,
      createUser: {
        ...state.createUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------------------------------------------------- //
    // ************************************************************//
    [types.GET_USER]: (state, { payload }) => ({
      ...state,
      getUser: {
        ...state.getUser,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      getUser: {
        ...state.getUser,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_USER_FAIL]: (state, { payload }) => ({
      ...state,
      getUser: {
        ...state.getUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------------------------------------------------- //
    // ************************************************************//
    [types.UPDATE_USER]: (state, { payload }) => ({
      ...state,
      updateUser: {
        ...state.updateUser,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      updateUser: {
        ...state.updateUser,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.UPDATE_USER_FAIL]: (state, { payload }) => ({
      ...state,
      updateUser: {
        ...state.updateUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------------------------------------------------- //
    // ************************************************************//
    [types.DELETE_USER]: (state, { payload }) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_USER_SUCCESS]: (state, { payload }) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.DELETE_USER_FAIL]: (state, { payload }) => ({
      ...state,
      deleteUser: {
        ...state.deleteUser,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------------------------------------------------- //
    // ************************************************************//
    [types.GET_USER_COUNT]: (state, { payload }) => ({
      ...state,
      userCount: {
        ...state.userCount,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_USER_COUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      userCount: {
        ...state.userCount,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_USER_COUNT_FAIL]: (state, { payload }) => ({
      ...state,
      userCount: {
        ...state.userCount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ------------------------------------------------------------- //
  },
  initialState
);
