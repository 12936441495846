import React from "react";
import ReviewComments from "../ReviewComments/ReviewComments";

const ReviewList = ({ hits }) => {
  return (
    <div id="product">
      {hits.map((review) => (
        <ReviewComments
          data={review}
          key={review.objectID}
          styleName="gx-card-list p-3"
        />
      ))}
    </div>
  );
};
export default ReviewList;
