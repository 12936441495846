import { Col, Row } from "antd";
import React from "react";

const ReviewComments = ({ styleName, data }) => {
  const { reviewer_name, content, reviewer_country, value, replied, reply } =
    data;
  return (
    <div
      className={`gx-user-list ${styleName} mb-2`}
      style={{ borderRadius: "10px" }}
    >
      <Row>
        <Col span={24}>
          <div className="gx-flex-row">
            <h6 className="mb-0">{reviewer_name}</h6>
            <span className="gx-d-inline-block gx-toolbar-separator">
              &nbsp;
            </span>
            <span className="mt-1" style={{ fontSize: "13px" }}>
              {reviewer_country}
            </span>
            <span className="gx-d-inline-block gx-toolbar-separator">
              &nbsp;
            </span>
            <p className="gx-text-grey mb-0 mt-1" style={{ fontSize: "13px" }}>
              Rating : {value}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <p className="mb-0 mt-1" style={{ fontSize: "13px" }}>
            {content}
          </p>
        </Col>
        {replied ? (
          <>
            <Col span={5}>
              <p
                className="gx-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                Reply :
              </p>
            </Col>
            <Col span={19}>
              <p className="gx-text" style={{ fontSize: "13px" }}>
                {reply}
              </p>
            </Col>
          </>
        ) : null}
      </Row>
    </div>
  );
};

export default ReviewComments;
