// Types
const action_header = "profile/";

export default {
  GET_SHIPPING_ADDRESS: action_header + "GET_SHIPPING_ADDRESS",
  GET_SHIPPING_ADDRESS_SUCCESS: action_header + "GET_SHIPPING_ADDRESS_SUCCESS",
  GET_SHIPPING_ADDRESS_FAILED: action_header + "GET_SHIPPING_ADDRESS_FAILED",

  ADD_SHIPPING_ADDRESS: action_header + "ADD_SHIPPING_ADDRESS",
  ADD_SHIPPING_ADDRESS_SUCCESS: action_header + "ADD_SHIPPING_ADDRESS_SUCCESS",
  ADD_SHIPPING_ADDRESS_FAILED: action_header + "ADD_SHIPPING_ADDRESS_FAILED",

  UPDATE_SHIPPING_ADDRESS: action_header + "UPDATE_SHIPPING_ADDRESS",
  UPDATE_SHIPPING_ADDRESS_SUCCESS:
    action_header + "UPDATE_SHIPPING_ADDRESS_SUCCESS",
  UPDATE_SHIPPING_ADDRESS_FAILED:
    action_header + "UPDATE_SHIPPING_ADDRESS_FAILED",

  DELETE_SHIPPING_ADDRESS: action_header + "DELETE_SHIPPING_ADDRESS",
  DELETE_SHIPPING_ADDRESS_SUCCESS:
    action_header + "DELETE_SHIPPING_ADDRESS_SUCCESS",
  DELETE_SHIPPING_ADDRESS_FAILED:
    action_header + "DELETE_SHIPPING_ADDRESS_FAILED",

  ADD_ORDER_ADDRESS: action_header + "ADD_ORDER_ADDRESS",
  REMOVE_ORDER_ADDRESS: action_header + "REMOVE_ORDER_ADDRESS",
  EDIT_ORDER_ADDRESS: action_header + "EDIT_ORDER_ADDRESS",

  GET_PROFILE_DETAILS: action_header + "GET_PROFILE_DETAILS",
  GET_PROFILE_DETAILS_SUCCESS: action_header + "GET_PROFILE_DETAILS_SUCCESS",
  GET_PROFILE_DETAILS_FAILED: action_header + "GET_PROFILE_DETAILS_FAILED",

  ADD_ORDER_REVIEW: action_header + "ADD_ORDER_REVIEW",
  ADD_ORDER_REVIEW_SUCCESS: action_header + "ADD_ORDER_REVIEW_SUCCESS",
  ADD_ORDER_REVIEWSS_FAILED: action_header + "ADD_ORDER_REVIEWSS_FAILED",

  GET_BUYER_ORDER_COUNT: action_header + "GET_BUYER_ORDER_COUNT",
  GET_BUYER_ORDER_COUNT_SUCCESS: action_header + "GET_BUYER_ORDER_COUNT_SUCCESS",
  GET_BUYER_ORDER_COUNT_FAILED: action_header + "GET_BUYER_ORDER_COUNT_FAILED",

  SELECTED_SHIPPING_ADDRESS_HANDLER:
    action_header + "SELECTED_SHIPPING_ADDRESS_HANDLER",
};
