import React from "react";
import { Radio } from "antd";
import { withRouter } from "react-router-dom";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const OrderSelections = ({ ordersCount, history, type }) => {
  function onChange(e) {
    history.push(`/seller/dashboard/orderManagement/${e.target.value}`);
  }

  return (
    <div className="">
      <RadioGroup onChange={onChange} defaultValue={type} buttonStyle="solid">
        <RadioButton className="mb-0" value="all">
          All&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.all_orders_count})`
            : ""}
        </RadioButton>
        {/* <RadioButton className="mb-0" value="unpaid">
          Unpaid&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.unpaid_orders_count})`
            : ""}
        </RadioButton> */}
        <RadioButton className="mb-0" value="neworder">
          New Order&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.orders_to_ship_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="shipped">
          Shipped&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.shipped_orders_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="delivered">
          Delivered&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.delivered_orders_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="cancelled">
          Cancelled&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.cancelled_orders_count})`
            : ""}
        </RadioButton>
        <RadioButton className="mb-0" value="returned">
          Returned&nbsp;
          {!ordersCount.loading && ordersCount.data.data
            ? `(${ordersCount.data.data.returned_orders_count})`
            : ""}
        </RadioButton>
      </RadioGroup>
    </div>
  );
};

export default withRouter(OrderSelections);
