import React, { Component } from "react";
import { Row, Col, message } from "antd";
import ShippingInfoModal from "../../Shipping/ShippingInfoModal/ShippingInfoModal";
import OrderItemSummery from "./OrderItemSummery";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import QuantityManager from "../../../Common/QuantityManager/QuantityManager";
import "./OrderItem.less";
import {
  getDefaultShippingAddress,
  getSelectedProductPrice,
  toTitleCase,
} from "../../../../util/services";
import { CHECKOUT_CART } from "../../../../constants/Utilities";
import {
  DEFAULT_CURRENCY,
  IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST,
} from "../../../../constants";

class OrderItem extends Component {
  quantityIncreaseHandler = (cartItem, avalability, index) => {
    const { shippingAddress } = this.props;

    const { quantity } = cartItem.userSelections;
    let key = "quantity";
    let value = quantity + 1;
    if (value < 0) {
      message.info(`minimum is 1`);
    } else if (value > avalability) {
      message.info(`Only ${avalability} `);
    } else {
      this.props.cartActions.productUserSelectionsHandler({
        key: key,
        value: value,
        isCheckout: true,
        product_id: cartItem.id,
        updatedIndex: index,
      });
      let quantityChangedProoduct = {
        key: key,
        value: value,
        isCheckout: true,
        product_id: cartItem.id,
        updatedIndex: index,
      };

      let updatedSelectedItems = [];
      updatedSelectedItems.push(cartItem);

      let defaultShippingAddress = getDefaultShippingAddress(shippingAddress);

      this.props.cartActions.productQuantityChangeInCart({
        quantityChangedProoduct,
        updatedSelectedItems,
        defaultShippingAddress,
        cartType: CHECKOUT_CART,
      });
    }
  };

  quantityDecreaseHandler = (cartItem, avalability, index) => {
    const { shippingAddress } = this.props;

    const { quantity } = cartItem.userSelections;
    let key = "quantity";
    let value = quantity - 1;
    if (value < 0) {
      message.info(`minimum is 1`);
    } else if (value > avalability) {
      message.info(`Only ${avalability} `);
    } else {
      this.props.cartActions.productUserSelectionsHandler({
        key: key,
        value: value,
        isCheckout: true,
        product_id: cartItem.id,
        updatedIndex: index,
      });

      let quantityChangedProoduct = {
        key: key,
        value: value,
        isCheckout: true,
        product_id: cartItem.id,
        updatedIndex: index,
      };

      let updatedSelectedItems = [];
      updatedSelectedItems.push(cartItem);

      let defaultShippingAddress = getDefaultShippingAddress(shippingAddress);

      this.props.cartActions.productQuantityChangeInCart({
        quantityChangedProoduct,
        updatedSelectedItems,
        defaultShippingAddress,
        cartType: CHECKOUT_CART,
      });
    }
  };

  quantityHandler = (quantity, cartItem, avalability, index) => {
    this.setState({ selectedQuantity: quantity });
    let key = "quantity";
    let value = quantity;
    if (value < 0) {
      message.info(`minimum is 1`);
    } else if (value > avalability) {
      message.info(`Only ${avalability} `);
    } else {
      this.props.cartActions.productUserSelectionsHandler({
        key: key,
        value: quantity,
        isCheckout: true,
        product_id: cartItem.id,
        updatedIndex: index,
      });
    }
  };

  render() {
    const { styleName, data, index } = this.props;
    const { name, variations, userSelections } = data;
    const { selectedShippingMethod } = data;

    var selectedProductVariations = getSelectedProductPrice(
      variations,
      userSelections.variations,
      data
    );
    var userSelectionsVariationsArray =
      userSelections.variations &&
      Object.keys(userSelections.variations).map((key) => [
        key,
        userSelections.variations[key],
      ]);

    // let totalShippingCost =
    //   selectedShippingMethod?.rate_additional_item != 0
    //     ? parseFloat(selectedShippingMethod?.rate) +
    //       (parseFloat(userSelections?.quantity) - 1) *
    //         parseFloat(selectedShippingMethod?.rate_additional_item)
    //     : parseFloat(selectedShippingMethod?.rate) *
    //       parseFloat(userSelections?.quantity);

    let totalShippingCost = IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST
      ? parseFloat(selectedShippingMethod?.rate) +
        (parseFloat(userSelections?.quantity) - 1) *
          parseFloat(selectedShippingMethod?.rate_additional_item)
      : parseFloat(selectedShippingMethod?.rate) *
        parseFloat(userSelections?.quantity);

    return (
      <React.Fragment>
        <div className={`gx-user-list mb-0 pb-0 ${styleName}`}>
          <div className="col-12">
            <Row>
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <div className="in-mob-checkt">
                  <img
                    alt="avatar"
                    src={data.images[0]}
                    style={{ border: "1px solid #efefef", borderRadius: "5px" }}
                  />
                </div>
              </Col>
              <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                <div>
                  <span>
                    <b>Seller : </b>
                  </span>
                  {data.seller ? (
                    <Link
                      to={`/seller/${data.seller.id}`}
                      className="crd-fnt-jr"
                    >
                      <span>{data.seller && data.seller.name}</span>
                    </Link>
                  ) : (
                    <span>{data.seller && data.seller.name}</span>
                  )}

                  <span style={{ fontSize: "12px", fontStyle: "italic" }}>
                    {" "}
                  </span>
                </div>
                <div className="">
                  <div className="mt-1">
                    <span>
                      <b>Product : </b>
                    </span>
                    <span>{name}</span>
                  </div>
                  <div>
                    {userSelectionsVariationsArray &&
                      userSelectionsVariationsArray.map((variation, i) => {
                        return (
                          <h6 className="mb-1" style={{ fontSize: "12px" }}>
                            <b>{toTitleCase(variation[0])}</b> : {variation[1]}
                          </h6>
                        );
                      })}
                  </div>
                  <div className="mt-1 mb-2">
                    <span>
                      <b>Price : </b>
                    </span>
                    <span>
                      {`${DEFAULT_CURRENCY}
                      ${selectedProductVariations?.price}`}
                    </span>
                  </div>

                  <div className="mb-2">
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <QuantityManager
                          quantityDecreaseHandler={() =>
                            this.quantityDecreaseHandler(
                              data,
                              selectedProductVariations.qty,
                              index
                            )
                          }
                          quantityIncreaseHandler={() =>
                            this.quantityIncreaseHandler(
                              data,
                              selectedProductVariations.qty,
                              index
                            )
                          }
                          selectedQuantity={
                            data.userSelections && data.userSelections.quantity
                          }
                          available={
                            selectedProductVariations &&
                            selectedProductVariations.qty
                          }
                          quantityHandler={(quantity) =>
                            this.quantityHandler(
                              quantity,
                              data,
                              selectedProductVariations.qty,
                              index
                            )
                          }
                        />
                      </Col>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        className="gx-d-none gx-d-sm-block"
                      >
                        <ShippingInfoModal
                          data={data}
                          product_id={data.id}
                          selectedShippingMethod={data.selectedShippingMethod}
                        />
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <div
                        className="p-3"
                        style={{
                          border: "1px solid #efefef",
                          borderRadius: "5px",
                        }}
                      >
                        <OrderItemSummery
                          data={data}
                          selectedProductVariations={selectedProductVariations}
                          totalShippingCost={totalShippingCost}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    checkoutItems: state.Cart.checkoutItems,
    shippingAddress: state.Profile.shippingAddress,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderItem)
);
