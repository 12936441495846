import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class IdentityScreeningPolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Identity Screening Policy" />
          <CSBreadcrumb label={"Identity Screening Policy"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              We're committed to keeping our marketplace a safe place for all
              our members. To help validate the identity of our buyers and
              sellers, we require our users to adhere to certain policies. When
              you sign up with Tradesbarn LLC, you're agreeing to the terms of
              our user agreement and privacy notice, as well as our identity
              policies. To learn more about our various identity policies,
              please see the detailed guidelines below.
            </p>
            <br />
            <p>
              <b>Multiple accounts policy</b>
            </p>

            <p>
              Members can have different accounts for buying and selling, or for
              different product lines, with valid business reasons, but all
              accounts are subject to our buying and selling policies.We
              understand that Tradesbarn LLC members might want different
              accounts for buying or selling, or to focus on different product
              lines.
            </p>
            <p>
              However, in order to maintain a safe and trusted environment for
              all our users, all accounts that a member uses are held to our
              buying and selling policies.
            </p>
            <br />
            <p>
              <u>
                <b>Frequently Asked Questions</b>
              </u>
            </p>
            <p>
              Are there circumstances when creating another account isn't
              allowed?
            </p>
            <ul>
              <li>
                <p>
                  Registering new accounts to avoid buying and selling
                  restrictions or limits, or other policy consequences, is not
                  allowed on Tradesbarn LLC.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>
                  Can I use one account for buying and one account for selling?
                  Do the same policies apply to each?
                </b>
              </u>
            </p>
            <ul>
              <li>
                <p>
                  Yes, you can use the accounts in whatever way works best for
                  you. Buying and selling policies apply equally to all accounts
                  though, so you'll need to meet our buyer and seller standards
                  across all your accounts.
                </p>
              </li>
            </ul>

            <p>
              There are many reasons why users would choose to have more than
              one Tradesbarn account. For example, users who like to buy and
              sell may want separate accounts for each activity. Other users who
              maintain businesses on Tradesbarn LLC may prefer to manage
              separate accounts for different product lines.Whether you have one
              account or more, Tradesbarn LLC expects users to manage each of
              their accounts effectively in order to meet the highest buyer and
              seller standards. These standards and rules regarding performance,
              risk, and best practices are applicable for each account and help
              ensure a safe and positive experience on Tradesbarn LLC.Users are
              expected to resolve issues as they arise, and follow the steps
              provided by Tradesbarn LLC to bring accounts back into good
              standing before co<font face="Arial, serif">ntinuing</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">to</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">buy</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">or</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">sell</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">with</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">other</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">accounts.</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">Make</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">sure</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">you</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">follow</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">these</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">guidelines.</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">If</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">you</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">don’t,</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">you</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">may</font>
              <font face="Arial, serif"></font>
              <font face="Arial, serif">be </font>subject to a range of actions,
              including limits of your buying and selling privileges and
              suspension of your account.
            </p>
            <p>
              Please note that in some cases where a member has two accounts and
              one becomes suspended, their second account may also be subject to
              restriction.
            </p>
            <br />
            <p>
              <u>
                <b>What are the guidelines?</b>
              </u>
            </p>
            <p>
              <u>Allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  Users may have multiple accounts on Tradesbarn LLC. We
                  understand that there are various reasons why someone would
                  choose to have more than one account.
                </p>
              </li>
            </ul>
            <p>
              <u>Not allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  Registering new accounts or using other existing accounts to
                  avoid buying and selling restrictions or limits or other
                  policy consequences.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <b>Age limits policy</b>
            </p>
            <p>
              Listings on Tradesbarn LLC involve entering into a contract so all
              our members must be aged 18 years or older.This is to help protect
              both our community, as well as underage users
            </p>

            <p>
              You must be at least 18 years old to be a Tradesbarn LLC member. A
              person under 18 can use an adult's account with the permission of
              the account holder. However, the account holder is responsible for
              everything done with that account. Make sure you follow these
              guidelines. If you don’t, you may be subject to a range of
              actions, including limits of your buying and selling privileges
              and suspension of your account. If you think another member is
              under 18, please report them to us. Make sure to include any
              relevant information about why you think the member is underage.
              If you have an email from the member, please be sure to include a
              copy of it.
            </p>
            <br />
            <p>
              <u>
                <b>Username policy</b>
              </u>
            </p>
            <p>
              We have some criteria to make sure your identity is protected when
              you create a username. Find out what you can and can't do when you
              create or change yours.A username is the unique name used to
              identify yourself on Tradesbarn LLC. We have a few rules on what
              you can and can't include in your username. Please see our full
              policy guidelines below for all the details, including examples of
              the types of usernames that are and are not allowed. When you
              register your account, choose a username that you like, and that
              you'll find easy to remember. We don't allow usernames that
              include profane or obscene language, third-party trademarks, email
              or web addresses, or the word "Tradesbarn LLC".
            </p>

            <ul>
              <li>
                <p>
                  Can't be obscene or profaneCan't contain an email address or
                  web address (URL), or the word Tradesbarn
                </p>
              </li>
              <li>
                <p>Must be at least 6 characters long</p>
              </li>
              <li>
                <p>Can't contain a third party's trademark or brand</p>
              </li>
            </ul>

            <p>
              There are also a few formatting guidelines you need to follow. For
              more specific information and examples of what is and is not
              allowed, please take a look at the guidelines below. Make sure you
              follow these guidelines. If you don't, you may be subject to a
              range of actions, including limits of your buying and selling
              privileges and suspension of your account. If you see a username
              on the site that you think violates this policy, report it. if
              you're having trouble signing in to Tradesbarn.com or can't
              remember your username or password, get help.
            </p>

            <p>
              <u>Allowed</u>
            </p>
            <p>
              <i>Usernames can contain:</i>
            </p>
            <ul>
              <li>
                <p>Letters (a-z), numbers (0-9), and some symbols</p>
              </li>
              <li>
                <p>
                  An element of an email address or web address (URL) that
                  identifies you or your brand. For example, if your website is
                  www.rohan_sawmil.com, your username can be rohan_sawmil.
                </p>
              </li>
            </ul>

            <p>
              <u>Not allowed</u>
            </p>
            <p style={{ marginLeft: "0.07in", marginTop: "0in" }}>
              <i>Usernames can't contain:</i>
            </p>
            <ul>
              <li>
                <p>
                  The @, &amp;, ', (, ), &lt;, or &gt; character. Note:
                  Tradesbarn LLC Store names can contain some symbols, such as
                  &amp;.
                </p>
              </li>
              <li>
                <p>
                  Spaces or tabs. Note: Tradesbarn LLC Store names can contain
                  spaces.
                </p>
              </li>
              <li>
                <p>
                  Elements that imply an email address or web address (for
                  example, xyz.com), including but not limited to .com, .net,
                  .org, .edu or any variation of such (for example, _com or
                  -com).
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>Consecutive underscores __.</p>
              </li>
              <li>
                <p>
                  An underscore _, hyphen -, or period. At the beginning of a
                  username (for example, -pezcollector).
                </p>
              </li>
              <li>
                <p>
                  The word Tradesbarn (only Tradesbarn employees can use the
                  word Tradesbarn in their usernames).
                </p>
              </li>
              <li>
                <p>The letter e followed by numbers.</p>
              </li>
            </ul>
            <br />
            <p>
              <b>
                Obscene or profane words that violate Tradesbarn guidelines.
              </b>
            </p>
            <p>
              Inappropriate language and profanity policy overviewFor a pleasant
              experience on Tradesbarn LLC, we don't allow using hateful,
              offensive, profane, or vulgar language in public areas of the
              website, including:
            </p>
            <ul>
              <li>
                <p>Profiles</p>
              </li>
              <li>
                <p>Chat rooms</p>
              </li>
              <li>
                <p>Discussion boards</p>
              </li>
              <li>
                <p>Tradesbarn LLC Motors</p>
              </li>
              <li>
                <p>Tradesbarn LLC Stores</p>
              </li>
              <li>
                <p>Feedback</p>
              </li>
              <li>
                <p>Listings</p>
              </li>
              <li>
                <p style={{ marginTop: "0.05in" }}>Products</p>
              </li>
              <li>
                <p>Collections</p>
              </li>
              <li>
                <p>Other areas of the site that people see</p>
              </li>
            </ul>

            <p>
              To find out more about our profanity policy, see the guidelines
              below.
            </p>
            <p>
              Make sure your listings and products follow these guidelines. If
              they don't, they may be removed, and you may be subject to a range
              of other actions, including restrictions of your buying and
              selling privileges and suspension of your account.
            </p>
            <br />
            <p>
              <u>
                <b>What are the guidelines?</b>
              </u>
            </p>
            <p>
              <u>Allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  An item listed in the Adult Only category that includes a
                  profane word as part of its official title or description
                </p>
              </li>
              <li>
                <p>
                  Music CD, movie DVD, or other media items with titles that
                  include profanity. The profane word has to be part of an
                  official title (for example, a song title) that was originally
                  released by the company that made the item
                </p>
              </li>
              <li>
                <p>
                  Officially released product with a brand name that includes a
                  profane word
                </p>
              </li>
            </ul>

            <p>
              <u>Restricted Allowed in some situations</u>
            </p>
            <ul>
              <li>
                <p>
                  Novelty items (hats, T-shirts, and so on) with profanity have
                  to meet the following criteria:
                </p>
              </li>
              <li>
                <p>
                  The majority of the profane word has to be replaced with
                  asterisks (for example, s*** or f***)
                </p>
              </li>
              <li>
                <p>
                  Any image showing the profane word needs to be blurred out as
                  well
                </p>
              </li>
            </ul>

            <p>
              <u>Not allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  Hateful, obscene, offensive, racist, sexual, or violent words
                  in any public area of Tradesbarn.com
                </p>
              </li>
              <li>
                <p>Offensive usernames</p>
              </li>
              <li>
                <p>Profane language to describe an item for sale</p>
              </li>
              <li>
                <p>Profanity in a listing or product title or description</p>
              </li>
              <li>
                <p>
                  The same username as another seller or similar to a Tradesbarn
                  LLC Store name.
                </p>
              </li>
              <li>
                <p>
                  A term that is similar to, or might be confused with, a third
                  party's trademark or brand (for example, Costco Seller).
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Additional information</b>
              </u>
            </p>
            <p>
              You can only change your username once in a 30-day period. This is
              to prevent confusion for other Tradesbarn LLC members as well as
              potential fraud. Keep in mind that your username is a valuable
              asset on Tradesbarn LLC, like your Feedback score. Buyers and
              sellers will recognize you by your username, so it's a good idea
              to choose one that you'll want to use for the long term.
              Information for members whose current username is
              <font face="Arial, serif">—</font>or represents
              <font face="Arial, serif">—</font>an email address or web address
              (URL): If you have a current Tradesbarn LLC username registered
              after MARCH 2019 that is also an email address, a web address
              (URL), or is a look-alike for either, you must change your
              username to comply with this policy.
            </p>
            <br />
            <p>
              <u>
                <b>Misrepresentation of identity policy</b>
              </u>
            </p>
            <p>
              Learn what's allowed and not allowed when representing your
              identity on Tradesbarn LLC.Tradesbarn LLC does not permit members
              to misrepresent themselves as Tradesbarn LLC employees or as other
              Tradesbarn LLC members. Violations of this policy may result in a
              range of actions, including:
            </p>
            <ul>
              <li>
                <p>Listing cancellation</p>
              </li>
              <li>
                <p>Limits on account privileges</p>
              </li>
              <li>
                <p>Account suspension</p>
              </li>
              <li>
                <p>Forfeit of Tradesbarn LLC fees on canceled listings</p>
              </li>
              <li>
                <p>Loss of Seller status</p>
              </li>
            </ul>
            <p>
              To report a member who is attempting to misrepresent himself or
              herself as a Tradesbarn LLC employee or as another Tradesbarn LLC
              member, please contact Tradesbarn LLC with the member's username
              or email address. Due to privacy issues, Tradesbarn LLC will be
              unable to discuss the result of the investigation.
            </p>
            <br />
            <p>
              <u>
                <b>Some examples</b>
              </u>
            </p>
            <p>
              Mr. Thenuwara is selling a collectible Art on Tradesbarn LLC.
              Upendra is the Buyer, and buys Mr. T's Art. Shortly after
              confirming, Upendra receives this email from someone pretending to
              be Mr. T: "Hi, Upendra. Congratulations on buying the statue!
              Please send me a cashier's check, payable to Kumudu Sumanasiri".
              Upendra is suspicious, because the email address of the sender
              doesn't match Mr. T's Tradesbarn.com-registered email address. So,
              Upendra contacts Mr. T at Mr. T's official address. Mr. T tells
              Upendra that Kumudu sumanasiri must be impersonating him, and that
              Upendra should not send Kumudu any money. Instead, Mr. T and
              Upendra work out the correct payment details, using their
              officially registered email addresses.
            </p>
            <br />
            <p>
              <u>
                <b>Additional information</b>
              </u>
            </p>
            <p>
              Personal information - such as names, addresses, passwords, credit
              card numbers - are sensitive and valuable. Personal information
              can also be valuable to individuals trying to defraud Tradesbarn
              LLC members. To try to get this type of information from a
              Tradesbarn LLC member, one of these individuals might send a
              member an email claiming to be Tradesbarn LLC or another
              Tradesbarn LLC member and requesting personal information. These
              requests might say it is important for members to reply with
              sensitive information by email, or contain links to Web pages that
              request members to sign in and submit information.
            </p>

            <p>
              Tradesbarnwill never ask a member to send Tradesbarnsensitive
              information through email. If Tradesbarnever does request
              sensitive information, Tradesbarn LLC will always direct its
              members to the Tradesbarnsite. With very few exceptions, members
              can submit the requested information through their "My Tradesbarn"
              page.If a member receives an email message requesting passwords,
              credit card numbers or other sensitive information, they should
              refer to our policy below
            </p>

            <p>
              If you receive an email that appears to be from Tradesbarn and
              requests sensitive personal information, be cautious. The email
              may be a "spoof" or "phishing" email. Fraudsters send fake emails,
              which appear to come from well- known companies, in the hope that
              recipients will reply or click on a link contained in the email,
              and then provide confidential information including passwords or
              bank or credit card details.As well as instructions that will
              allow them to report the email. If they have already submitted
              information as a result of an email message they received, they
              should review the securing your account for additional steps they
              should take as well as information about how to contact Tradesbarn
              LLC for assistance.
            </p>
            <br />
            <p>
              <u>
                <b>How to report spoof and phishing emails</b>
              </u>
            </p>
            <p>
              If you receive a suspicious email, you should report it to us
              immediately. Here's how:
            </p>
            <ul>
              <li>
                <p>
                  Don't click any links in the email or open any attachments.
                </p>
              </li>
              <li>
                <p>
                  Forward the message to us as an attachment at{" "}
                  <a href="mailto:spoof@Tradesbarn.com">
                    spoof@Tradesbarn.com.
                  </a>
                </p>
              </li>
              <li>
                <p>We'll let you know that we received the email.</p>
              </li>
              <li>
                <p>
                  To help us investigate the source of a spoof or phishing
                  email, please ensure you include the message as an attachment.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Characteristics of spoof or phishing emails and websites</b>
              </u>
            </p>
            <ul>
              <li>
                <p>
                  A phishing email pretending to be from Tradesbarn typically
                  contains a link that takes you to a fake website. There,
                  you'll usually be asked to sign in, and submit personal and
                  account information.
                </p>
              </li>
              <li>
                <p>
                  These emails often include the Tradesbarn logo and a fake
                  Tradesbarn address in the "From" line. Pay attention to signs
                  that the email might be a spoof.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>
                  Here are some typical traits of spoof emails and websites:
                </b>
              </u>
            </p>
            <ul>
              <li>
                <p>Asking for confidential information</p>
              </li>
              <li>
                <p>An urgent tone that asks you to act quickly</p>
              </li>
              <li>
                <p>Unsolicited attachments</p>
              </li>
              <li>
                <p>A generic greeting, like "Attention Tradesbarn member"</p>
              </li>
              <li>
                <p>
                  A web address that looks like Tradesbarn, but which may have a
                  typo or extra numbers and letters (like
                  <a href="http://signin-Tradesbarn.com/">
                    http://signin-Tradesbarn.com{" "}
                  </a>
                  or http://signin.Tradesbarn.com@11.12.14.15)
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>
                  If you receive a legitimate email from Tradesbarn, you can
                  expect that:
                </b>
              </u>
            </p>
            <ul>
              <li>
                <p>It will typically contain your Tradesbarn username</p>
              </li>
              <li>
                <p>
                  We won't ask you to provide confidential information like your
                  password or credit card details via email or over the phone
                </p>
              </li>
              <li>
                <p>
                  We'll only include links for convenience. No link will require
                  you to submit confidential information on the next page
                </p>
              </li>
              <li>
                <p>
                  We won't include attachments. If you receive an unsolicited
                  email with an attachment, don't open it
                </p>
              </li>
              <li>
                <p>
                  We won't ever use threatening language or include specific
                  threats
                </p>
              </li>
            </ul>
            <br />
            <p>
              <b>False or missing contact information policy</b>
            </p>
            <p>
              Tradesbarn members are required to provide accurate contact
              information such as their name, address, or telephone number when
              registering on Tradesbarn. Tradesbarn members are not allowed to
              use a fax or disconnected number as a telephone number. Make sure
              you follow these guidelines. If you don't, you may be subject to a
              range of actions, including restrictions of your buying and
              selling privileges and suspension of your account.
            </p>
            <br />
            <p>
              <u>
                <b>What are the guidelines?</b>
              </u>
            </p>
            <p>
              <u>Allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  Providing your correct name, address, and telephone number
                </p>
              </li>
              <li>
                <p>
                  Keeping your contact information up-to-date. You can update
                  your personal information in My Tradesbarn and address. Learn
                  more about changing your account information.
                </p>
              </li>
            </ul>

            <p>
              <u>Not allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  Falsifying or omitting contact information such as a name,
                  address, or telephone number
                </p>
              </li>
              <li>
                <p>
                  Failing to register a contact name along with the business
                  name, for a business account
                </p>
              </li>
              <li>
                <p>Using a fax number as a telephone number</p>
              </li>
              <li>
                <p>Using a disconnected telephone number</p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>Why does Tradesbarn have this policy?</b>
              </u>
            </p>
            <p>
              Buyers and sellers need to be able to communicate with each other.
              If you provide incorrect contact information, it makes it
              difficult for your buyer or seller to get in touch with you. This
              makes it more likely that you'll have problems like unpaid items,
              Tradesbarn Money Back Guarantee cases, and negative feedback.
            </p>
          </div>
        </Widget>
      </div>
    );
  }
}

export default IdentityScreeningPolicy;
