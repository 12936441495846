import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class TaxPolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Company Infor And Policy" />
          <CSBreadcrumb label={"Company Infor And Policy"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              As a condition of using Tradesbarn LLC, you agree to comply with
              all applicable laws, statutes, ordinances and regulations
              regarding:
            </p>
            <ul>
              <li>
                <p>The use of Tradesbarn LLC's website and services</p>
              </li>
              <li>
                <p>Your listings</p>
              </li>
              <li>
                <p>Your solicitation of offers to purchase</p>
              </li>
              <li>
                <p>The sale of your items</p>
              </li>
            </ul>

            <p>
              Sellers are responsible for paying all fees and taxes associated
              with using Tradesbarn LLC. Please review the Tradesbarn LLC User
              Agreement for more details regarding your responsibilities as a
              Tradesbarn LLC member. Tradesbarn LLC will share your Tradesbarn
              LLC information with government authorities if we are required to
              do so by law. Make sure you follow these guidelines. If you don't,
              you may be subject to a range of actions. We may limit, restrict,
              or suspend you from buying, selling, or using site features. Your
              listings may be removed, displayed lower or not shown in search
              results, without refunding any or all applicable fees. You may
              also forfeit special account status and any discounts.
            </p>
            <br />
            <p>
              <u>
                <b>What are the guidelines?</b>
              </u>
            </p>
            <p>
              If you are selling on Tradesbarn LLC as a business, we strongly
              recommend seeking professional advice about your tax obligations.
            </p>
            <br />
            <p>
              <u>
                <b>Income Tax</b>
              </u>
            </p>
            <p>
              You're required by law to declare and pay taxes on income you've
              earned from your Tradesbarn LLC sales. For tax advice about your
              Tradesbarn LLC earnings, we recommend consulting a tax
              professional and reviewing tax regulations.
            </p>
            <br />
            <p>
              <u>
                <b>Tax on Tradesbarn LLC fees</b>
              </u>
            </p>
            <p>
              Depending on tax legislation where you live, local Value Added Tax
              (VAT), Goods and Services Tax (GST), or similar consumption tax
              might apply to your Tradesbarn LLC seller fees.
            </p>
            <p>
              If a tax applies in your country of residence, Tradesbarn LLC will
              add the tax as a separate charge on your seller invoice or include
              the tax in our fees as noted for each jurisdiction where
              Tradesbarn LLC is required to collect such taxes.Please ensure
              that your registration details are up to date, so that we know
              where you are based.
            </p>
            <br />
            <p>
              <u>
                <b>Applying for tax exemption on your Tradesbarn LLC fees</b>
              </u>
            </p>
            <p>
              If you use your Tradesbarn LLC account exclusively for business
              purposes, you can apply for tax exemption on your Tradesbarn LLC
              fees by providing us with your tax registration number to (
              <a href="mailto:taxes@tradesbarn.com">
                <font color="#0462c1">
                  <u>taxes@Tradesbarn.com</u>
                </font>
              </a>
              ) If you apply for exemption from tax on your Tradesbarn LLC fees,
              all information related to your request{" "}
              <font face="Arial, serif">–</font>including personal information
              and your tax or business registration number{" "}
              <font face="Arial, serif">– </font>will be transferred to, stored
              in, and processed in the United States and elsewhere in accordance
              with the terms of the Tradesbarn LLC User Agreement and User
              Privacy Notice. Tradesbarn LLC reserves the right to request
              additional verification regarding your taxable status from you or
              government authorities as permitted by law. Such information will
              be processed in accordance with the terms of the Tradesbarn LLC
              User Agreement and User Privacy Notice.
            </p>
            <br />
            <p>
              <u>
                <b>Sales tax for items sold on Tradesbarn LLC</b>
              </u>
            </p>
            <p>
              Depending on tax legislation where you live and where your buyer
              lives, sales tax, Value Added Tax (VAT), Goods and Services Tax
              (GST), or similar consumption tax may apply to items you sell on
              Tradesbarn LLC. Whether it's included in the listing price or
              added at checkout depends on both the item's location and the
              buyer's shipping address. You must not misrepresent the location
              of your items on Tradesbarn LLC and you must comply with
              Tradesbarn LLC's policy.
            </p>
            <br />
            <p>
              <u>
                <b>Items sold within the US</b>
              </u>
            </p>
            <p>
              Tradesbarn LLC does not provides a basic tax calculator however
              Tradesbarn Offers A Space, So sellers will need to enter the
              appropriate tax rate for each state where the seller is authorized
              to collect tax. We can't guarantee will meet all of your tax
              requirements.
            </p>
            <br />
            <p>
              <u>Allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  You may charge tax in states where you are authorized to
                  collect tax
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>Not allowed</u>
            </p>
            <ul>
              <li>
                <p>
                  You may not collect sales tax in states where you are not
                  authorized
                </p>
              </li>
              <li>
                <p>
                  You may not collect sales tax beyond what is legally
                  authorized
                </p>
              </li>
              <li>
                <p>
                  You may not include sales tax information in your item
                  description
                </p>
              </li>
            </ul>
            <br />
            <p
              style={{
                marginLeft: "0.07in",
                marginTop: "0in",
                lineHeight: "0.19in",
              }}
            >
              <u>
                <b>Items sold overseas (imported or exported)</b>
              </u>
            </p>
            <p>
              Local consumer tax and/or customs duty may apply to goods imported
              from overseas, and the parcel's recipient may need to pay these as
              part of clearing the parcel through customs. These may not apply
              if the order's under a certain value, but the threshold varies
              between countries.
            </p>
            <p
              style={{
                marginLeft: "0.07in",
                marginRight: "0.91in",
                lineHeight: "100%",
              }}
            >
              Contact your local tax office for information about paying sales
              tax on items exported overseas. Remember that you may also have
              tax obligations in other countries if you are doing business
              overseas.
              <font face="Arial, serif">
                <font size={2} style={{ fontSize: "9pt" }}>
                  <i>EXAMPLE:</i>
                </font>
              </font>
            </p>
            <p style={{ marginLeft: "0.07in", marginTop: "0.01in" }}>
              <i>
                European Union: If you're selling to buyers located in the EU,
                you’re obliged to VAT obligations in the EU.
              </i>
            </p>

            <p style={{ marginLeft: "0.07in", marginTop: "0.01in" }}>
              <i>
                VAT in the EU is a tax on consumer spending. It is collected by
                VAT-registered traders on their sales within the EU territory,
                and passed on to the national tax authorities via VAT return
                filings. Most EU countries allow you to register for VAT online,
                through the local tax authority’s website. In practice, the
                following three situations will normally trigger mandatory VAT
                registration:
              </i>
            </p>

            <p style={{ marginLeft: "0.07in", marginTop: "0.01in" }}>
              <i>
                If you are an EU established seller and your business is trading
                over the legal thresholds defined by your home country you must
                ensure that you are registered for VAT. The thresholds vary
                between EU countries and are currently anything from 0 EUR in
                Spain to 85,000 GBP in UK. In practice, this means that you must
                register for VAT immediately if you are a Spanish business (no
                minimum threshold), whereas you are required to register for VAT
                as an UK seller only after you have made over 85,000 GBP sales
                within 12 consecutive months.
              </i>
            </p>

            <p style={{ marginLeft: "0.07in", marginTop: "0.01in" }}>
              <i>
                If you are selling goods that are warehoused/pre-deployed in any
                other country than your home country, then you must register for
                VAT immediately in the EU country where your goods are located.
                There is no minimal threshold and this rule also applies for
                sellers from outside of the EU with inventory pre-deployed in
                the EU.
              </i>
            </p>

            <p style={{ marginLeft: "0.07in", marginTop: "0.01in" }}>
              <i>
                If you sell a lot of goods located in one EU country to private
                buyers in another EU country (distance selling), you may also be
                required to register for and collect VAT in the country of your
                buyer if you exceed specific thresholds for that country. These
                range from 26,135 EUR for distance sales to Romania to 100,000
                EUR for Germany
              </i>
            </p>

            <p>
              It is mandatory that you display your VAT number on the Tradesbarn
              LLC site in connection with your listings. This can be done by
              updating your business seller information page.Failure to meet
              your VAT obligations is a serious criminal offence. If you do not
              appear to be meeting your VAT obligations on EU sites, your
              account may be blocked from selling and your active listings
              removed. You will need to update your account with a valid VAT
              number and contact us (
              <a href="mailto:taxes@tradesbarn.com">
                <font color="#0462c1">
                  <u>taxes@Tradesbarn.com</u>
                </font>
              </a>
              ) before we can remove any selling restriction.
            </p>
            <br />
            <p>
              <u>
                <b>Selling policies</b>
              </u>
            </p>
            <p
              style={{
                marginLeft: "0.07in",
                marginTop: "0in",
                lineHeight: "98%",
              }}
            >
              Our policies are designed to help create a fair and safe trading
              experience for all Tradesbarn.com users. As a seller, you're
              responsible for regularly reviewing and complying with Tradesbarn
              LLC's selling policies, and for meeting your
            </p>
            <p
              style={{
                marginLeft: "0.07in",
                marginRight: "0.08in",
                marginTop: "0.03in",
                pageBreakBefore: "always",
              }}
            >
              obligations as set out in our User Agreement. This helps you avoid
              accidentally breaking any rules. If you fail to follow the rules,
              to protect the integrity of our marketplace, we may take certain
              actions, such as removing your listings or products and limiting
              your buying and selling privileges. For more details, please see
              the individual policy guidelines below.
            </p>

            <p style={{ marginLeft: "0.07in", marginTop: "0in" }}>
              <font face="Arial, serif">
                By keeping some basic selling practices in mind, you’ll be able
                to create a great experience for your buy
              </font>
              ers.
            </p>
            <p>
              Our full selling practice guidelines below provide a range of tips
              for helping ensure you keep your buyers happy. They cover areas
              such as the item description, your terms and conditions, shipping
              and handling charges, delivery times, communication, returns, and
              photos.
            </p>
            <br />
            <p>
              <u>
                <b>Frequently Asked Questions</b>
              </u>
            </p>

            <p style={{ marginLeft: "0.07in", marginTop: "0.04in" }}>
              Why do I need to specify the location of the item in my listing?
            </p>
            <ul>
              <li>
                <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
                  Buyers like to know where the item is being shipped from as it
                  helps to give them an idea of how much shipping will cost and
                  how long it might take for the item to arrive. Having an
                  inaccurate item location can cause confusion and lead to a
                  poor experience for your buyer.
                </p>
              </li>
            </ul>

            <p>What should I include in my terms and conditions?</p>
            <ul>
              <li>
                <p style={{ marginRight: "0.08in", marginTop: "0in" }}>
                  Be very clear and thorough about the terms of the transaction
                  and include information about the forms of payment you accept,
                  any taxes or other applicable fees, shipping and handling, and
                  your return policy. Being upfront and honest about these terms
                  helps build trust and provides a positive buying experience
                  for your customers.
                </p>
              </li>
            </ul>

            <p>Do I have to accept returns?</p>
            <ul>
              <li>
                <p style={{ marginRight: "0.08in" }}>
                  You don't have to accept returns, but we highly recommend you
                  do <font face="Arial, serif">–</font>it might help you attract
                  more buyers. If you do accept returns, make sure you specify
                  the time period in which you accept returns, who pays for the
                  return postage, and how the refund is issued.
                </p>
              </li>
            </ul>
          </div>
        </Widget>
      </div>
    );
  }
}

export default TaxPolicy;
