// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getExportDetail: createAction(types.GET_EXPORT_DETAILS),
  getExportDetailSuccess: createAction(types.GET_EXPORT_DETAILS_SUCCESS),
  getExportDetailFail: createAction(types.GET_EXPORT_DETAILS_FAILED),

};
