import React, { useState } from "react";
import { Card, Modal, Button } from "antd";
import { signOutWithRedirect } from "../../../services/auth.service";

const AccountContent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    signOutWithRedirect("/login?redirect_to=/account/change-password");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Card className="gx-card" style={{ backgroundColor: "rgb(250, 250, 250)" }}>
      <h5 className="mb-3 ml-2">Security Information Settings</h5>
      <Button type="primary" onClick={showModal}>
        {" "}
        Change Password{" "}
      </Button>
      <Modal
        title="Re-Login Required"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Click Ok to continue</p>
      </Modal>
    </Card>
  );
};

export default AccountContent;
