import React, { Component } from "react";
import { Tabs } from "antd";

import ChangePassword from "../../../../components/Seller/Dashboard/ChangePassword/ChangePassword";
import ChangeProfile from "../../../../components/Seller/Dashboard/ChangeProfile/ChangeProfile";

const TabPane = Tabs.TabPane;

class AccountSettings extends Component {
  render() {
    return (
      <div>
        <h3 className="mb-4">Account Settings</h3>
        <div className="gx-card p-3">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Account Settings" key="1">
              <ChangeProfile />
            </TabPane>
            <TabPane tab="Change Password" key="2">
              <ChangePassword />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default AccountSettings;
