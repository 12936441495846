import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Button, Form, Icon, Upload } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;

class ChangeProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = (values) => {
    
  };

  render() {
    const { handleSubmit } = this.props;

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };

    return (
      <div>
        <h5>Change Profile Photo</h5>
        <p>Please select your photo.</p>
        <React.Fragment>
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="dropbox">
              <Upload.Dragger name="files" action="/upload.do">
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </div>
            {/* </Field> */}

            <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px", marginBottom: "0" }}
              >
                Submit
              </Button>
            </FormItem>
          </Form>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(
  reduxForm({
    form: "ChangeProfile",
  })(connect(null, null)(ChangeProfile))
);
