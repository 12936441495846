import React, { Component } from "react";
import { Button, message, Popconfirm, Col, Row } from "antd";
import Widget from "../../../Common/Widget/Widget";
// import AddCreditCardModal from "../../Modal/AddCreditCard/AddCreditCardModal";

export default class CreditCard extends Component {
  render() {
    const { creditCard, creditCardDeleteHandler } = this.props;
    return (
      <Widget>
        <div className="ant-card-head-title">
          {creditCard.bill_to.first_name} {creditCard.bill_to.last_name}
        </div>
        <hr />
        <Row>
          <Col span={14}>
            <p className="mb-1">
              <b>Credit Card Number : </b>
              {creditCard.credit_card_number}
            </p>
            <p className="mb-1">
              <b>Address Line 1 : </b>
              {creditCard.bill_to.address1}
            </p>
            <p className="mb-1">
              <b>Address Line 2 : </b>
              {creditCard.bill_to.address2}
            </p>
            <p className="mb-1">
              <b>City : </b>
              {creditCard.bill_to.city}
            </p>
            <p className="mb-1">
              <b>State : </b>
              {creditCard.bill_to.state}
            </p>
            <p className="mb-1">
              <b>Country : </b>
              {creditCard.bill_to.country}
            </p>
            <p className="mb-4">
              <b>Zip Code : </b>
              {creditCard.bill_to.zip}
            </p>
          </Col>
          <Col span={10}>
            <div className="" style={{ marginTop: "80px" }}>
              <Popconfirm
                title="Are you sure delete this task?"
                onConfirm={() =>
                  creditCardDeleteHandler(creditCard.payment_profile_id)
                }
                onCancel={() => message.error("Delete Canceled")}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    backgroundColor: "#ff4d4f",
                    border: "1px solid #ff4d4f",
                    color: "white",
                  }}
                >
                  <i className="fas fa-trash-alt"></i>&nbsp; Delete
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Widget>
    );
  }
}
