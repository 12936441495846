import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Input } from "antd";
import { makeField } from "components/Common/Controls/MakeField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;

const AInputField = makeField(Input);
class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = (values) => {
    
  };

  render() {
    const { handleSubmit } = this.props;
    const formItemLayout = {
      labelCol: { xs: 24, sm: 6 },
      wrapperCol: { xs: 24, sm: 14 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };

    return (
      <div>
        <h5>Change Password</h5>
        <p>
          The password should be at least 8 characters long. It must contain
          upper and lower case characters and at least one number.
        </p>
        <React.Fragment>
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <FormItem {...formItemLayout} label="Email Address">
              <Field
                formItemLayout={formItemLayout}
                // label="First Name"
                name="email"
                component={AInputField}
                placeholder="Email Address"
                hasFeedback
              />
            </FormItem>

            <FormItem {...formItemLayout} label="Old Password">
              <Field
                formItemLayout={formItemLayout}
                // label="First Name"
                name="email"
                component={AInputField}
                placeholder="Old Password"
                hasFeedback
              />
            </FormItem>
            <FormItem {...formItemLayout} label="New Password">
              <Field
                formItemLayout={formItemLayout}
                // label="First Name"
                name="newPassword"
                component={AInputField}
                placeholder="New Password"
                hasFeedback
              />
            </FormItem>

            <FormItem {...formItemLayout} label="Repeat Password">
              <Field
                formItemLayout={formItemLayout}
                // label="First Name"
                name="confirmPassword"
                component={AInputField}
                placeholder="Repeat Password"
                hasFeedback
              />
            </FormItem>
            {/* </Field> */}

            <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: "10px", marginBottom: "0" }}
              >
                Submit
              </Button>
            </FormItem>
          </Form>
        </React.Fragment>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = "New password is required";
  }
  if (values && values.newPassword && values.newPassword.length < 6) {
    errors.newPassword = "Password should be minumum 6 Character";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Repeat password is required";
  }
  if (values.confirmPassword && values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Password mismatch..!";
  }

  return errors;
};

function mapStateToProps(state) {
  return null;
}
function mapDispatchToProps(dispatch) {
  return null;
}

export default withRouter(
  reduxForm({
    form: "ChangePassword",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))
);
