import React, { Component } from "react";
import { reduxForm, Field, FieldArray } from "redux-form";
import { connect } from "react-redux";
import { Input, Card, Select, Form, Alert } from "antd";
import { FormItemLayout } from "../../../../constants/FormUtility";
import ProductVariationsTable from "../../../../components/Seller/ProductManagement/ProductVariationsTable/ProductVariationsTable";
import RenderVariations from "../../../../components/Seller/ProductManagement/RenderVariations/RenderVariations";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import { FormItemLayout2 } from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";
import { DEFAULT_CURRENCY } from "../../../../constants";

const Option = Select.Option;
const FormItem = Form.Item;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class PriceAndStock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isEnableProductVariable,
      productVariations,
      variations,
      handleAddRow,
      handleRemoveSpecificRow,
    } = this.props;
    return (
      <div className="mb-3">
        <div style={{ display: "flex", alignItems: "center" }} className="mb-3">
          <h6 className="mb-0 mr-2">Price & Stock</h6>

          <ExtraInfo placement={"top"} title={extraInfoDetails.productStock} />
        </div>
        <Alert
          message="Informational Notes"
          description="If your product has more than one variant please add those as variable products.
                        Ex: T-shirts of different sizes."
          type="info"
          showIcon
        />
        <FormItem
          {...FormItemLayout}
          label="Is this product a variable one : "
          required
          id="isProductVariable"
        >
          <Field
            formItemLayout={FormItemLayout2}
            name="isProductVariable"
            component={ASelectField}
            style={{ width: "30%" }}
            placeholder="Select"
            hasFeedback
          >
            <Option value="enable">Yes</Option>
            <Option value="disable">No</Option>
          </Field>
          <span
            style={{ position: "absolute", right: "10px", bottom: "-37px" }}
          >
            <ExtraInfo title={extraInfoDetails.variableOne} />
          </span>
        </FormItem>

        <React.Fragment>
          {isEnableProductVariable && (
            <Alert
              message="Informational Notes"
              description="If yes, please add at least one variant of your product by clicking “Add Attributes” button, You should type the attribute name and provide attribute values in the options field. Please make sure you press enter after adding each option."
              type="info"
              showIcon
            />
          )}
        </React.Fragment>

        <React.Fragment>
          {isEnableProductVariable && (
            <FieldArray name="variations" component={RenderVariations} />
          )}

          {isEnableProductVariable && (
            <React.Fragment>
              <Alert
                message="Informational Notes"
                description="Here you can provide specific variants of your product by using the attributes combinations you have added. use this table to add available variants separately."
                type="info"
                showIcon
              />
            </React.Fragment>
          )}
          <ProductVariationsTable
            productVariations={productVariations}
            variations={variations}
            isEnableProductVariable={isEnableProductVariable}
            handleAddRow={handleAddRow}
            handleRemoveSpecificRow={(idx) => handleRemoveSpecificRow(idx)}
          />
        </React.Fragment>
        <React.Fragment>
          <Card style={{ backgroundColor: "#fafafa" }}>
            <div className="jr-fld-catch">
              {!isEnableProductVariable && (
                <React.Fragment>
                  <FormItem
                    {...FormItemLayout}
                    label="Price"
                    required={!isEnableProductVariable}
                  >
                    <Field
                      formItemLayout={FormItemLayout2}
                      name="productPrice"
                      component={AInputField}
                      placeholder="Price"
                      hasFeedback
                      disabled={isEnableProductVariable}
                      addonBefore={DEFAULT_CURRENCY}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "-37px",
                      }}
                    >
                      <ExtraInfo title={extraInfoDetails.packagePrice} />
                    </span>
                  </FormItem>

                  <FormItem
                    {...FormItemLayout}
                    label="Availability"
                    required={!isEnableProductVariable}
                  >
                    <Field
                      formItemLayout={FormItemLayout2}
                      name="productStock"
                      component={AInputField}
                      placeholder="Availability"
                      type="text"
                      normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                      hasFeedback
                      disabled={isEnableProductVariable}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "-37px",
                      }}
                    >
                      <ExtraInfo title={extraInfoDetails.packageAvailability} />
                    </span>
                  </FormItem>
                </React.Fragment>
              )}
              <FormItem {...FormItemLayout} label="Discount" required={false}>
                <Field
                  formItemLayout={FormItemLayout2}
                  type="number"
                  // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                  name="discount"
                  component={AInputField}
                  placeholder="Discount"
                  hasFeedback
                  addonAfter="%"
                  onWheel={(e) => e.target.blur()}
                  min="0"
                />
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "-37px",
                  }}
                >
                  <ExtraInfo title={extraInfoDetails.packageDiscount} />
                </span>
              </FormItem>
            </div>
          </Card>
        </React.Fragment>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PriceAndStock = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(PriceAndStock);

// You have to connect() to any reducers that you wish to connect to yourself
PriceAndStock = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(PriceAndStock);

export default PriceAndStock;
