import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

function CSBreadcrumb({ label, isRoot }) {
  return (
    <div className="px-2 mb-1">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        {isRoot ? (
          <Breadcrumb.Item>Customer Service</Breadcrumb.Item>
        ) : (
          <>
            <Breadcrumb.Item>Customer Service</Breadcrumb.Item>
            <Breadcrumb.Item>{label}</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
    </div>
  );
}

export default CSBreadcrumb;
