// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getReportItems: createAction(types.GET_REPORTITEM_DETAILS),
  getReportItemsSuccess: createAction(types.GET_REPORTITEM_SUCCESS),
  getReportItemsFail: createAction(types.GET_REPORTITEM_FAILED),

  selectReportItems: createAction(types.SELECT_REPORT_iTEM),

  openNewCase: createAction(types.OPEN_NEW_CASE),
  openNewCaseSuccess: createAction(types.OPEN_NEW_CASE_SUCCESS),
  openNewCaseFail: createAction(types.OPEN_NEW_CASE_FAIL),

  getCase: createAction(types.GET_CASE),
  getCaseSuccess: createAction(types.GET_CASE_SUCCESS),
  getCaseFail: createAction(types.GET_CASE_FAIL),

  updateCase: createAction(types.UPDATE_CASE),
  updateCaseSuccess: createAction(types.UPDATE_CASE_SUCCESS),
  updateCaseFail: createAction(types.UPDATE_CASE_FAIL),

  getResolutionCenterCases: createAction(types.GET_RESOLUTION_CENTER_CASES),
  getResolutionCenterCasesSuccess: createAction(
    types.GET_RESOLUTION_CENTER_CASES_SUCCESS
  ),
  getResolutionCenterCasesFail: createAction(
    types.GET_RESOLUTION_CENTER_CASES_FAIL
  ),

  handleReportItemImage: createAction(types.HANDLE_REPORT_ITEM_IMAGE),
  handleUpdateCaseImage: createAction(types.HANDLE_UPDATE_CASE_IMAGE),

  clearCase: createAction(types.CLEAR_CASE),
};
