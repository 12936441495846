import React, { Component } from "react";
import { Card } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreditCardModal from "../../components/Buyer/Modal/AddCreditCard/AddCreditCardModal";
import { creditCardActions } from "./ducks";
import CreditCard from "../../components/Buyer/Card/CreditCard/CreditCard";
import CardUnderDevelopment from "./CardUnderDevelopment";

class CardsAddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
    };
  }
  componentDidMount = () => {
    this.props.creditCardActions.getCreditCard();
  };

  creditCardDeleteHandler = (id) => {
    this.props.creditCardActions.deleteCreditCard({
      payment_profile_id: id,
    });
  };

  render() {
    const { creditCard } = this.props;

    const CardList = (props) => (
      <div className="card-list">
        {creditCard.data &&
          creditCard.data.data &&
          creditCard.data.data.map((creditCard, i) => (
            <CreditCard
              creditCard={creditCard}
              creditCardDeleteHandler={this.creditCardDeleteHandler}
              {...creditCard}
            />
          ))}
      </div>
    );

    if (true) {
      return <CardUnderDevelopment />;
    } else {
      return (
        <Card
          className="gx-card"
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          <h5 className="mb-3 ml-2">Cards & Bank Accounts</h5>

          {/* <Spin spinning={creditCard.loading}> */}
          <div>
            <CreditCardModal isCreate={true} />
          </div>
          {creditCard.loading ? (
            //loader....
            <div className="tr-address-loader-container">
              {/* <div className="tr-address-loader"> </div> */}
              <div className="loadingspinner"> </div>
            </div>
          ) : (
            //loader....
            <CardList profiles={this.state.profiles} />
          )}
          {/* </Spin> */}
        </Card>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    creditCard: state.CredirCardDetail.creditCard,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardsAddPage)
);
