import { Button } from "antd";
import React, { Component } from "react";
import "./FloatingFeedbackButton.less";
import feedImg from "../../../assets/img/newshxmt.svg";
import OnShopFeedImg from "../../../assets/onshop/images/feedback/feedback.png";

import Feedback from "../Feedback/Feedback";
import { withRouter } from "react-router-dom";
import { TRADESBARN } from "../../../constants";

class FloatingFeedbackButton extends Component {
  state = { visible: false };

  feedBackModalHandler = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { pathname } = this.props.location;
    if (pathname !== "/login") {
      return (
        <React.Fragment>
          <img
            className="fltngImg"
            src={
              process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
                ? feedImg
                : OnShopFeedImg
            }
            alt=""
          />
          <Button
            className={
              process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
                ? "fltngFdbkBtn gx-btn-success"
                : "fltngFdbkBtn"
            }
            type="primary"
            onClick={this.feedBackModalHandler}
          >
            Feedback
          </Button>
          <Feedback
            visible={this.state.visible}
            feedBackModalHandler={this.feedBackModalHandler}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(FloatingFeedbackButton);
