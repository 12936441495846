import React, { Component } from "react";
import { Table, Button } from "antd";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { resolutionCenterActions } from "../../ducks";

class ReportCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the CRM column
      loading: false,
      myCartObj: null,
      isDashboard: false,
      filteredInfo: null,
      sortedInfo: null,
    };
    this.columns = [
      {
        title: "Product",
        width: "140px",
        render: (text, record) => (
          <img
            alt="avatar"
            src={(record.product && record.product.image) || record.product.img}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          />
        ),
      },
      {
        title: "Details",
        dataIndex: "status",
        render: (text, record) => {
          return (
            <div>
              <div>
                <b>Seller : </b>
                {record.seller ? (
                  <Link
                    className="crd-fnt-jr"
                    to={`/seller/${record.seller.id}`}
                  >
                    {record.seller && record.seller.name}
                  </Link>
                ) : null}
              </div>
              <div>
                <b>Ordered Date : </b>
                {moment(record.created_at).format("LLLL")}
              </div>
              <div>
                <b>Ordered ID : </b>
                {record.id}
              </div>
              <div>
                <b>Product Name : </b>
                {record.product && record.product.name ? (
                  <Link
                    className="crd-fnt-jr"
                    to={`/product/${record.product.id}`}
                  >
                    {record.product.name}
                  </Link>
                ) : null}
              </div>
              <div>
                <b>Quantity : </b>
                {record.transaction && record.transaction.qty}
              </div>
              <div>
                <b>Overall Status : </b>
                <span
                  className={
                    record.status === "In Progress"
                      ? "inprogs-clr"
                      : record.status === "Paid"
                      ? "paid-clr"
                      : record.status === "Cancelled"
                      ? "cancle-clr"
                      : record.status === "Delivered"
                      ? "fnshed-clr"
                      : record.status === "Returned"
                      ? "retn-clr"
                      : "shpp-clr"
                  }
                >
                  {record.status}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        title: "Amount",
        width: "17%",
        render: (text, record) => (
          <div>
            <span style={{ color: "#ff6900", fontSize: "20px" }}>
              ${record.transaction && record.transaction.grand_total}
            </span>
            <div>(Price + Shipping)</div>
          </div>
        ),
      },
      {
        title: "Action",
        width: "17%",
        render: (text, record) => (
          <div className="">
            <Button
              className="gx-btn-outline-danger"
              onClick={() => this.itemSelectHandler(record)}
            >
              <i className="fad fa-exclamation-circle mr-1"></i>
              Open Dispute
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.props.resolutionCenterActions.getResolutionCenterCases();
    const { pathname } = this.props.location;
    var isDashboard = false;

    if (pathname.includes("/seller/dashboard/resolution-center")) {
      isDashboard = true;
    }
    this.setState({
      isDashboard,
    });
  }

  renderProductVariations = (product) => {
    return (
      product &&
      product.variations &&
      Object.entries(product.variations).map(([key, value]) => {
        return (
          <div>
            {key} : {value}
          </div>
        );
      })
    );
  };

  itemSelectHandler = (record) => {
    const { isDashboard } = this.state;
    var itemObj = record;
    var itemString = JSON.stringify(itemObj);
    // setter
    localStorage.setItem("rcr_item", itemString);

    if (isDashboard) {
      this.props.history.push("/seller/dashboard/resolution-center/open-case");
    } else {
      this.props.history.push("/resolution/report/detail");
    }
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleClickProduct = (data) => {
    this.props.resolutionCenterActions.selectReportItems(data);
  };

  render() {
    const { resolutionCenterCases } = this.props;
    if (resolutionCenterCases.loading) {
      return (
        <div className="gx-card p-4">
          <div
            className="tr-wishlist-notify-container"
            style={{ margin: "100px" }}
          >
            <div className="tr-wishlist-notify-loading-spinner"> </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="gx-card p-4 mb-0">
          <Table
            className=""
            pagination={false}
            columns={this.columns}
            dataSource={
              resolutionCenterCases &&
              resolutionCenterCases.data &&
              resolutionCenterCases.data.data
            }
            onChange={this.handleChange}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    resolutionCenterCases: state.ResolutionCenter.resolutionCenterCases,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportCardList)
);
