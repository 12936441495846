import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductSearchBar from "../../../../components/Seller/ProductManagement/ProductSearchBar/ProductSearchBar";
import ProductSelections from "../../../../components/Seller/ProductManagement/ProductSelections/ProductSelections";
import ProductContainer from "../../../../components/Seller/ProductManagement/ProductContainer/ProductContainer";
import { productManagementActions } from "../ducks";
import { Card, Col, Row } from "antd";

class ProductManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
    };
  }
  componentDidMount() {
    const { productManagementActions, productCount } = this.props;

    productCount.loading && productManagementActions.getProductCount();
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    this.setState({
      algolia,
    });
  }
  render() {
    const { match, productCount } = this.props;
    const { algolia } = this.state;

    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    if (algolia) {
      switch (type) {
        case "all":
          apiKey = algolia.all_products.api_key;
          index = algolia.all_products.index;

          break;
        case "live":
          apiKey = algolia.active_products.api_key;
          index = algolia.active_products.index;

          break;
        case "imageMissing":
          apiKey = algolia.image_missing_products.api_key;
          index = algolia.image_missing_products.index;

          break;
        case "poorQuality":
          apiKey = algolia.poor_quality_products.api_key;
          index = algolia.poor_quality_products.index;

          break;
        case "soldOut":
          apiKey = algolia.sold_out_products.api_key;
          index = algolia.sold_out_products.index;

          break;
        case "inactive":
          apiKey = algolia.inactive_products.api_key;
          index = algolia.inactive_products.index;

          break;
        case "policyVialation":
          apiKey = algolia.all_products.api_key;
          index = algolia.all_products.index;

          break;
        default:
          apiKey = algolia.all_products.api_key;
          index = algolia.all_products.index;
      }

      return (
        <Row>
          <Col span={24}>
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <h3>Product Management</h3>
              </div>

              <Card className="gx-card">
                {/* Product selections */}
                <ProductSelections productCount={productCount} type={type} />
                <ProductContainer apiKey={apiKey} index={index} productCount={productCount} type={type} />{" "}
              </Card>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={24}>
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <h3>Product Management</h3>
              </div>

              {/* Product selections */}
              <Card className="gx-card">
                <ProductSelections productCount={productCount} type={type} />

                {/* Product Searchbar */}
                <ProductSearchBar />
              </Card>
            </div>
          </Col>
        </Row>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    productCount: state.ProductManagement.productCount,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductManagement)
);
