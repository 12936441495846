import React from "react";
import Auxiliary from "../../../../util/Auxiliary";
import moment from "moment";

const ActivityItem = ({ task }) => {
  return (
    <Auxiliary>
      <p className="gx-mb-0" style={{ fontWeight: "bold" }}>
        {task.title}
      </p>
      <p className="gx-mb-0" style={{ fontSize: "13px" }}>
        {task.message}
      </p>

      <small className="gx-text-grey">
        {moment(task.created_at).format("llll")}
      </small>
    </Auxiliary>
  );
};

export default ActivityItem;
