import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { profileActions } from "../ducks";

// import MybarnSideBar from "../../../components/Buyer/MybarnSideBar/MybarnSideBar";
import MybarnHeader from "../../../components/Buyer/MybarnHeader/MybarnHeader";
import { auth } from "../../../firebase/firebase";

class Mybarn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      currentUser: null,
      userJson: null,
      role: null,
    };
  }

  componentDidMount() {
    this.getCurrentUser();
    this.props.profileActions.getProfileDetails();
    // getter
    var user = localStorage.getItem("user");
    if (user) {
      var userJson = JSON.parse(user);
      var role = userJson && userJson.role;
      this.setState({ userJson, role });
    }
  }

  getCurrentUser = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await this.setCurrentUserDetails(user);
        // User is signed in.
      } else {
        // No user is signed in.
        // alert("Please log in");
      }
    });
  };
  setCurrentUserDetails = (currentUser) => {
    this.setState({ currentUser });
  };
  render() {
    const { currentUser, role } = this.state;
    const { profileDetails } = this.props;
    return (
      <div
        // className="gx-main-content-wrapper"
        className="pt-4"
        style={{ minHeight: "600px" }}
        // className="gx-main-content"
      >
        {/* <Row>
          <Col xl={6} lg={7} md={24} sm={24} xs={24}>
            <MybarnSideBar />
          </Col>

          <Col xl={18} lg={17} md={24} sm={24} xs={24}> */}
        <MybarnHeader
          currentUser={currentUser}
          profileDetails={profileDetails}
          role={role}
        />
        {/* </Col>
        </Row> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profileDetails: state.Profile.profileDetails,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mybarn));
