import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { homeActions } from "./ducks/index";
import ProductsGrid from "../../components/Buyer/Home/ProductsGrid/ProductsGrid";
import TopSeller from "../../components/Buyer/Carousel/TopSeller/TopSeller";
import PopularCategories from "../../components/Buyer/PopularCategories/PopularCategories";
import TopBrands from "../../components/Buyer/Carousel/TopBrands/TopBrands";
// import NewComers from "../../components/Buyer/NewComers/NewComers";
import LatestProductCarousel from "../../components/Buyer/Carousel/LatestProductCarousel/LatestProductCarousel";
import HotDeals from "../../components/Buyer/HotDeals/HotDeals";
import PopularProductCarousel from "../../components/Buyer/Carousel/PopularProductCarousel/PopularProductCarousel";
import { cartActions } from "../Cart/ducks";
import "./Home.less";
import {
  IS_ENABLED_HOT_DEAL_SECTION_HOME_PAGE,
  IS_ENABLED_PLATFORM_FEATURES_BAR,
  IS_ENABLED_PROMOTION_MODULE,
  IS_ENABLED_TOP_BRAND_SECTION_HOME_PAGE,
  IS_ENABLED_TOP_SELLERS_SECTION_HOME_PAGE,
} from "../../constants";
import PlatformFeatures from "../../components/Buyer/Carousel/PlatformFeatures/PlatformFeatures";
import PromotionCardSingle from "../Promotions/Home/PromotionCardSingle";
import PromotionsThripple from "../Promotions/Home/PromotionsThripple";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      showFeedback: false,
      feedBack: {},
    };
  }

  componentDidMount() {
    const { homeActions, cartActions } = this.props;
    homeActions.getDiscountedProducts();
    homeActions.getTopSellers();
    homeActions.getAllCategories();
    homeActions.getPopularCategories();
    cartActions.getHomeProducts();
    homeActions.getLatestProductCarousel();
    homeActions.getPopularProductCarousel();
    homeActions.getTopBrands();
  }

  render() {
    const {
      popularCarouselProducts,
      latestCarouselProducts,
      discountedProducts,
      topSellers,
      homeProducts,
      popularCategories,
      topBrands,
    } = this.props;

    return (
      <div className="gx-container">
        {IS_ENABLED_HOT_DEAL_SECTION_HOME_PAGE && (
          <HotDeals data={discountedProducts} />
        )}
        <PopularCategories data={popularCategories} />

        {IS_ENABLED_PROMOTION_MODULE && (
          <PromotionCardSingle imageName={"full_row_image_1.jpg"} />
        )}

        <div className="hide-mobile-view">
          <PopularProductCarousel data={popularCarouselProducts} />
        </div>

        {IS_ENABLED_PROMOTION_MODULE && <PromotionsThripple />}

        <ProductsGrid data={homeProducts} />

        {IS_ENABLED_PROMOTION_MODULE && (
          <PromotionCardSingle imageName={"full_row_image_2.jpg"} />
        )}

        <div className="hide-mobile-view">
          <LatestProductCarousel data={latestCarouselProducts} />
        </div>

        {topSellers.data.length !== 0 &&
        IS_ENABLED_TOP_BRAND_SECTION_HOME_PAGE ? (
          <TopSeller data={topSellers} />
        ) : null}
        {topBrands.data.length !== 0 &&
        IS_ENABLED_TOP_SELLERS_SECTION_HOME_PAGE ? (
          <TopBrands data={topBrands} />
        ) : null}

        {/* <NewComers /> */}
        {IS_ENABLED_PLATFORM_FEATURES_BAR && <PlatformFeatures />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.Home,
    allProducts: state.Home.allProducts,
    homeProducts: state.Cart.homeProducts,
    popularCarouselProducts: state.Home.popularCarouselProducts,
    latestCarouselProducts: state.Home.latestCarouselProducts,
    signoutUser: state.AuthStore.signoutUser,
    discountedProducts: state.Home.discountedProducts,
    topSellers: state.Home.topSellers,
    allCategories: state.Home.allCategories,
    popularCategories: state.Home.popularCategories,
    topBrands: state.Home.topBrands,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    homeActions: bindActionCreators(homeActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
