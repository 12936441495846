import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";
import _ from "lodash";

const initialState = {
  creditCard: {
    ...initialStateModal,
    pending: false,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.GET_CREDIT_CARD]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_CREDIT_CARD_SUCCESS]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_CREDIT_CARD_FAILED]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    [types.ADD_CREDIT_CARD]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_CREDIT_CARD_SUCCESS]: (state, { payload }) => {
      const { data } = payload;
      var source = state.creditCard.data && state.creditCard.data.data;
      // source.push(data);
      source = [data].concat(source);
      return {
        ...state,
        addCreditCard: {
          ...state.addCreditCard,
          loading: false,
          pending: false,
          data: payload,
        },

        creditCard: {
          loading: false,
          pending: false,
          data: {
            ...state.creditCard,
            data: [...source],
          },
        },
      };
    },

    [types.ADD_CREDIT_CARD_FAILED]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    [types.DELETE_CREDIT_CARD]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_CREDIT_CARD_SUCCESS]: (state, { payload }) => {
      const updatedID = payload;
      var source = state.creditCard.data && state.creditCard.data.data;
      // lodash.js
      source = _.reject(source, function (el) {
        return el.payment_profile_id === updatedID;
      });
      // source.splice(updatedIndex, 1, updatedData);
      return {
        ...state,
        deleteCreditCard: {
          ...state.deleteCreditCard,
          loading: false,
          pending: false,
          data: payload,
        },
        creditCard: {
          loading: false,
          pending: false,
          data: {
            ...state.creditCard,
            data: [...source],
          },
        },
      };
    },
    [types.DELETE_CREDIT_CARD_FAILED]: (state, { payload }) => ({
      ...state,
      creditCard: {
        ...state.creditCard,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
