import React from "react";
import { Button, Card, Table } from "antd";

const products = [
  {
    id: "a1ed3fa1-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101559015384%2F0-small.jpg?alt=media",
    ],
    name:
      "SAPPHIRE FRINGE Necklace - 18k White Gold Sapphire And Diamond Fringe Necklace",
    ratings_count: 0,
    price: "67500.00",
    brand: null,
    discount_percentage: 50,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3fa0-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101559015014%2F0-small.jpg?alt=media",
    ],
    name:
      "CHANEL - COCO Attitude Earrings - 18k White Gold Diamond Swirl Earrings",
    ratings_count: 0,
    price: "30000.00",
    brand: "Chanel",
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f9f-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101559014328%2F0-small.jpg?alt=media",
    ],
    name: "ROLEX SUBMARINER 18K Yellow Gold",
    ratings_count: 0,
    price: "40000.00",
    brand: "Rolex",
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f9e-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772907%2F0-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772907%2F1-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772907%2F2-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772907%2F3-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772907%2F4-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772907%2F5-small.jpg?alt=media",
    ],
    name: "Audemars Piguet Royal Oak Offshore Chronograph",
    ratings_count: 0,
    price: "54000.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f9d-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558772454%2F0-small.jpg?alt=media",
    ],
    name: "TIFFANY & CO. 18k Yellow Gold Tanzanite Brooch",
    ratings_count: 0,
    price: "11000.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f9c-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558771690%2F0-small.jpg?alt=media",
    ],
    name: "BUCCELLATI 18K EMERALD & Diamond Ring Luxury designer",
    ratings_count: 0,
    price: "9750.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f9b-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558770707%2F0-small.jpg?alt=media",
    ],
    name: "BUCCELLATI 18K Gold, Ruby & Diamond Cuff Bracelet",
    ratings_count: 0,
    price: "97500.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f9a-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558770473%2F0-small.jpg?alt=media",
    ],
    name: 'PAUL JENKINS "PHENOMENA HEAVEN HAMMER" Watercolor Painting',
    ratings_count: 0,
    price: "120000.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f99-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558764947%2F0-small.jpg?alt=media",
    ],
    name: "VERA ARUTYUNYAN Oil Painting For Mental Relaxation",
    ratings_count: 0,
    price: "7000.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f98-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558764784%2F0-small.jpg?alt=media",
    ],
    name:
      "BLOOM Necklace - PAVÉ Diamond Flower Pendant Necklace - 14K ROSE GOLD",
    ratings_count: 0,
    price: "540.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f97-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558764125%2F0-small.jpg?alt=media",
    ],
    name: "HUGS AND KISSES Ring - 14K Gold Diamond XOXO Ring",
    ratings_count: 0,
    price: "520.00",
    brand: null,
    discount_percentage: 0,
    ratings_value: 0,
    condition: "new",
  },
  {
    id: "a1ed3f96-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725687%2F0-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725687%2F1-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725687%2F2-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725687%2F3-small.jpg?alt=media",
    ],
    name: "FULFILLED Emerald And Diamond Ring - Right Hand Ring Chunky Band",
    ratings_count: 0,
    price: "2250.00",
    brand: null,
    discount_percentage: 65,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f95-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725432%2F0-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725432%2F1-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725432%2F2-small.jpg?alt=media",
    ],
    name: "Platinum Vintage Style Blue Sapphire And Diamond Ring",
    ratings_count: 0,
    price: "7500.00",
    brand: null,
    discount_percentage: 50,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f94-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725022%2F0-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558725022%2F1-small.jpg?alt=media",
    ],
    name: "Yellow Diamond Stacking Bands - 18k Yellow Gold Stacker 4 Rings",
    ratings_count: 0,
    price: "1900.00",
    brand: null,
    discount_percentage: 70,
    ratings_value: 0,
    condition: "used",
  },
  {
    id: "a1ed3f93-a268-11ea-a691-cfca0ccbdc51",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558722490%2F0-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558722490%2F1-small.jpg?alt=media",
      "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000101558722490%2F2-small.jpg?alt=media",
    ],
    name: "PINK RING STACK -18K Rose Gold Diamond 4 Sets Pairs Band Rings",
    ratings_count: 0,
    price: "2100.00",
    brand: null,
    discount_percentage: 70,
    ratings_value: 0,
    condition: "new",
  },
];

class SellerReviewTable extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  exportHandler = () => {
    alert("Downloading...");
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "Order Number",
        width: "300px",
        render: (text, record) => <React.Fragment>{record.id}</React.Fragment>,
      },
      {
        title: "Review",
        dataIndex: "name",
        key: "name",
        filters: [
          { text: "Joe", value: "Joe" },
          { text: "Jim", value: "Jim" },
        ],
        filteredValue: filteredInfo.name || null,
        onFilter: (value, record) => record.name.includes(value),
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      },
      {
        title: "Reply",
        dataIndex: "name",
        key: "age",
        sorter: (a, b) => a.age - b.age,
        sortOrder: sortedInfo.columnKey === "price" && sortedInfo.order,
      },
      // {
      //   title: "Availability",
      //   dataIndex: "ratings_count",
      //   key: "address",
      //   filters: [
      //     { text: "London", value: "London" },
      //     { text: "New York", value: "New York" },
      //   ],
      //   filteredValue: filteredInfo.address || null,
      //   onFilter: (value, record) => record.address.includes(value),
      //   sorter: (a, b) => a.address.length - b.address.length,
      //   sortOrder: sortedInfo.columnKey === "ratings_count" && sortedInfo.order,
      // },
    ];
    return (
      <Card>
        <div className="table-operations">
          {/* <Button onClick={this.setAgeSort}>Sort age</Button> */}
          <Button onClick={this.clearFilters}>Clear filters</Button>
          <Button onClick={this.clearAll}>Clear filters and sorters</Button>
          <Button onClick={this.exportHandler}>Export</Button>
        </div>
        <Table
          className="gx-table-responsive"
          columns={columns}
          dataSource={products}
          onChange={this.handleChange}
        />
      </Card>
    );
  }
}

export default SellerReviewTable;
