import React from "react";
import { Avatar } from "antd";
import Metrics from "../Metrics/Metrics";
import "./NewCustomers.less";
// import { newCustomers } from "../../../../DummyData/SellerDashboard";

const NewCustomers = ({ sellerStatistics }) => {
  let newCustomers =
    (!sellerStatistics.loading ?
      sellerStatistics.data &&
      sellerStatistics.data.new_customers : [])

  return (
    <Metrics
      title="NEW CUSTOMERS"
      icon="fad fa-users-medical mr-1"
      styleName="nc-top-catch"
    >
      {newCustomers && newCustomers.length !== 0 ? (
        <div className="gx-customers newcme-scrl nc-crd-catch">
          <ul className="gx-list-inline gx-customers-list jr-list-inline  gx-mb-0">
            {newCustomers.map((user, index) => (
              <li className="gx-mb-2 pl-2 pr-0" key={index}>
                <Avatar
                  src={user.image}
                  style={{ width: "48px", height: "48px" }}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
          <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
            <span className="gx-text-grey">No new customers yet</span>
          </div>
        )}
    </Metrics>
  );
};

export default NewCustomers;
