import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  addFeedBack: {
    ...initialStateModal,
  },
  feedbackImages: {
    image_keys: [],
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.FEEDBACK_FORM_SUBMIT]: (state, { payload }) => ({
      ...state,
      addFeedBack: {
        ...state.addFeedBack,
        loading: true,
        pending: true,
      },
    }),
    [types.FEEDBACK_FORM_SUBMIT_SUCCESS]: (state, { payload }) => ({
      ...state,
      addFeedBack: {
        ...state.addFeedBack,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.FEEDBACK_FORM_SUBMIT_FAILED]: (state, { payload }) => ({
      ...state,
      addFeedBack: {
        ...state.addFeedBack,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *************************HANDLE_FEEDBACK_IMAGES********************************//
    [types.HANDLE_FEEDBACK_IMAGES]: (state, { payload }) => {
      return {
        ...state,
        feedbackImages: {
          image_keys: [...state.feedbackImages.image_keys, ...payload],
        },
      }
    },
    // *********************************************************//
    // *************************CLEAR_FEEDBACK_IMAGES********************************//
    [types.CLEAR_FEEDBACK_IMAGES]: (state, { payload }) => {
      // debugger
      return {
        ...state,
        feedbackImages: {
          image_keys: [],
        },
      }
    },
    // *********************************************************//
    // *********************************************************//
    [types.REMOVE_FEEDBACK_IMAGES]: (state, { payload }) => {
      var feedbackImages = state.feedbackImages.image_keys;
      feedbackImages.splice(payload.idx, 1);
      return {
        ...state,
        feedbackImages: {
          image_keys: feedbackImages,
        },
      };
    },
    // *********************************************************//


  },
  initialState
);
