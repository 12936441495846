import React from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row } from "antd";
import Metrics from "../Metrics/Metrics";
import "./GrowthCard.less";
// import { sales } from "../../../../DummyData/SellerDashboard";

const GrowthCard = ({ sellerStatistics }) => {
  const trafficDatas = [];
  var Growth = 0;

  const sellerStatisticsSale =
    sellerStatistics && sellerStatistics.data && sellerStatistics.data.sales;

  // const sellerStatisticsSale = sales

  if (sellerStatisticsSale != null) {
    sellerStatisticsSale.forEach((data, index) => {
      trafficDatas.push({ name: data.month, value: data.this_year });
    });

    if (trafficDatas.length > 1) {
      const first = trafficDatas[trafficDatas.length - 1];
      const second = trafficDatas[trafficDatas.length - 2];
      Growth = ((second["value"] - first["value"]) / second["value"]) * 100;
      if (second["value"] === 0) {
        Growth = 0;
      }
    }
  }

  return (
    <Metrics title="Growth" icon="fad fa-chart-area mr-1" className="flex-fill">
      {/* <Metrics styleName={`gx-card-full`} title="Growth" className="flex-fill"> */}
      <Row>
        <Col lg={9} md={24} sm={9} xs={9}>
          <div className="gx-p-0">
            <h2 className="gx-fs-xxl gx-font-weight-medium gx-mb-0 gx-chart-up">
              {Growth > 0 ? (
                <div>
                  {Growth.toFixed(2)}
                  <i className="icon icon-menu-up gx-fs-xl" />
                </div>
              ) : (
                <div>
                  {Growth.toFixed(2)}
                  <i className="icon icon-menu-down gx-fs-xl" />
                </div>
              )}
            </h2>
            <p className="gx-mb-0 gx-text-grey">In this year</p>
          </div>
        </Col>
        <Col lg={15} md={24} sm={15} xs={15}>
          <ResponsiveContainer
            className="gx-barchart charts-bdr-btm"
            width="100%"
            height={70}
          >
            <AreaChart
              data={trafficDatas}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip />
              <defs>
                <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                  <stop offset="5%" stopColor="#FF55AA" stopOpacity={0.9} />
                  <stop offset="95%" stopColor="#6757DE" stopOpacity={0.9} />
                </linearGradient>
              </defs>
              <Area
                dataKey="value"
                strokeWidth={0}
                stackId="2"
                stroke="#867AE5"
                fill="url(#color1)"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Metrics>
  );
};

export default GrowthCard;
