import React, { Component } from "react";
import { Card, Table, Button } from "antd";

class AccountStatement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const columns = [
      {
        title: "Account Id",
        width: 100,
        dataIndex: "accountid",
        key: "accountid",
        fixed: "left",
      },
      { title: "Product", width: 200, dataIndex: "product", key: "1" },
      { title: "Trastion date", dataIndex: "trastiondate", key: "2" },
      { title: "Country", dataIndex: "country", key: "3" },
      { title: "Payment Method", dataIndex: "paymentmethod", key: "4" },
      { title: "Shipping Method", dataIndex: "shippingmethod", key: "5" },
      { title: "State", dataIndex: "state", key: "12" },
    ];

    const data = [
      {
        accountid: "1",
        product: {
          id: 'a1ed1873-a268-11ea-a691-cfca0ccbdc51',
          returns_accepted: '1',
          description: '<p>Colombo Nippon Hotel&#39;s - Mutton Chinese Roll - Unfried The mutton rolls (minced goat meat filled crumb fried rolled pancakes) of Nippon Hotel Colombo (Sri Lanka) has been written about in T. B. llangaratne&#39;s famous 1950&#39;s Sinhala Novel &quot;Amba Yahaluwo&quot;. The Nippon restaurant (what The Kafe was called then) is the place you have heard about the &quot;out of this world&quot; taste of the Nippon mutton rolls. It has been told the taste of those yesteryear rolls is still found in the ones Kafe makes today. You are not disappointed with the taste. They were the best you will ever had. Cannot stop eating one roll, two rolls or three rolls. The rolls are shipped from Colombo in hygienic foiled iced status in regiform box. The rolls are to be fried in deep oil in a pan for 10-15 minutes.</p>',
          hscode: null,
          domestic_shipping: 3,
          created_at: '2020-06-15T20:39:36.068Z',
          seller: {
            id: '5fed78e1-9cdf-11ea-b30b-95e78254a84d',
            name: 'PennsylvaniaBay',
            image: 'https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/sellers%2F50000006.jpg?alt=media',
            ratings_value: 0,
            ratings_count: 0
          },
          height: 6,
          width: 6,
          country_of_origin: 'Sri Lanka',
          collect_tax: false,
          images: [
            'https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000011554200240%2F0.jpg?alt=media',
            'https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000011554200240%2F1.jpg?alt=media',
            'https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000011554200240%2F2.jpg?alt=media',
            'https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000011554200240%2F3.jpg?alt=media'
          ],
          country: 'Sri Lanka',
          available: 4,
          category: {
            id: '438294c6-9ce0-11ea-8e84-a112b8f334ec',
            name: 'Snacks'
          },
          zip: null,
          domestic_flat_rate: null,
          international_flat_rate: null,
          upc: null,
          name: 'Nippon Mutton Chinese Rolls (Unfried 10 Pc pack)',
          condition_desc: null,
          ratings_count: 81,
          ratings_value: 4,
          ratings: {
            ratings_value: 4.2,
            ratings_percentage: [
              1,
              1,
              3,
              9,
              86
            ]
          },
          price: '0.01',
          old_price: '0.01',
          length: 5,
          local_pickup: '1',
          brand: null,
          discount_percentage: 10,
          city: null,
          variation: [],
          weight: 52.911,
          weight_in_grams: true,
          wholesale_prices: [],
          condition_status: 'new',
          specifications: {
            'Sugar Free': false,
            Organic: 'Yes',
            'Coffie Free': 'Yes',
            Height: 6,
            Width: 6,
            Weight: '52.911g',
            Length: 5,
            'Country of Origin': 'Sri Lanka'
          },
          is_wishlisted: true,
          userSelections: {
            quantity: 1
          },
          selectedShippingMethod: {
            id: 'rate_1cd721431a00456ea1d09a22c14ba876',
            service: 'INTERNATIONAL_FIRST',
            carrier: 'FedEx',
            carrier_image: 'https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/shipping-carriers%2FDHL.png?alt=media',
            rate: '153.25',
            currency: 'USD',
            delivery_days: 3,
            delivery_date: '2020-07-08T08:00:00Z',
            delivery_date_guaranteed: true,
            est_delivery_days: 3,
            tracking: true
          }
        },
        country: "Sri lanka",
        shippingmethod: "1",
        paymentmethod: "Detail",
        trastiondate: "2020-10-12",
        state: "position",
      },
      {
        accountid: "2",
        product: "2",
        country: "Sri lanka",
        shippingmethod: "2",
        paymentmethod: "Detail",
        trastiondate: "2020-01-12",
        state: "position",
      },
    ];

    const title = (
      <div>
        <h5 style={{ display: "inline-block" }}>Account Statement</h5>
        <Button
          type="primary"
          style={{ display: "inline-block", float: "right" }}
        >
          Export
        </Button>
      </div>
    );

    return (
      <Card title={title}>
        <Table columns={columns} dataSource={data} scroll={{ x: 1300 }} />
      </Card>
    );
  }
}

export default AccountStatement;
