import React, { Component } from "react";
import { Button, Form, Select, Input, Row, Col, Radio, Switch } from "antd";
import { Field, reduxForm, getFormValues } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeField } from "components/Common/Controls/MakeField";
import { creditCardActions } from "../../../../modules/CardsAddPage/ducks";
import Widget from "../../../Common/Widget/Widget";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};
const formItemZipLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};

class AddCreditCardModalBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      focus: "",
      switchDisable: false,
      radiaValue: [],
    };
  }

  onChange = (e) => {
    this.setState({ radiaValue: e.target.value });
  };

  handleSubmit = (values) => {
    var { cardNumber, cvc, expire } = values;

    var dateObj = new Date();
    var currentyear = dateObj.getUTCFullYear();
    var bill_to = null;
    var month = parseInt(expire.split("/")[0]);
    var year = values.expire.split("/")[1];

    var expire_year = parseInt(String(currentyear).slice(0, 2) + year);

    cardNumber = cardNumber.replace(/^\D+/g, "").replace(/\s/g, "");
    cvc = cvc.replace(/^\D+/g, "").replace(/\s/g, "");

    // expire_year  = expire_year.match(numberPattern)
    // expire_month  = expire_month.match(numberPattern)
    // cvv  = cvv.match(numberPattern)
    // console.log(credit_card_number, expire_year, expire_month, cvv)

    var credit_card_details = {
      credit_card_number: cardNumber,
      expire_year: expire_year,
      expire_month: month,
      cvv: cvc,
      // credit_card_number: 4007000000027,
      // expire_year: 2022,
      // expire_month: 12,
      // cvv: 123,
    };

    if (this.state.switchDisable) {
      const {
        first_name,
        last_name,
        zip,
        city,
        state,
        address1,
        address2,
        country,
      } = this.state.radiaValue;
      bill_to = {
        first_name: first_name,
        last_name: last_name,
        zip: zip,
        city: city,
        state: state,
        address1: address1,
        address2: address2,
        country: country,
      };
    } else {
      const {
        firstName,
        lastName,
        zip,
        city,
        state,
        addressLine1,
        addressLine2,
        country,
      } = values;
      bill_to = {
        first_name: firstName,
        last_name: lastName,
        zip: zip,
        city: city,
        state: state,
        address1: addressLine1,
        address2: addressLine2,
        country: country,
      };
    }
    this.props.creditCardActions.addCreditCard({
      credit_card_details,
      bill_to,
    });

    this.props.handleOk();
  };
  render() {
    const { handleSubmit, pristine, reset, submitting, address } = this.props;
    const { switchDisable } = this.state;

    const addressValue =
      address.data && address.data.data && address.data.data.shipping_addresses;

    const AddressCard = ({ address }) => (
      <Widget>
        <div>
          <div className="ant-card-head-title">
            {address.first_name} {address.last_name}
          </div>
          <hr />
          <Row>
            <Col span={14}>
              <p className="mb-1">
                <b>Address Line 1 : </b>
                {address.address1}
              </p>
              <p className="mb-1">
                <b>Address Line 2 : </b>
                {address.address2}
              </p>
              <p className="mb-1">
                <b>City : </b>
                {address.city}
              </p>
              <p className="mb-1">
                <b>State : </b>
                {address.state}
              </p>
              <p className="mb-1">
                <b>Country : </b>
                {address.country}
              </p>
              <p className="mb-4">
                <b>Zip Code : </b>
                {address.zip}
              </p>
              <p className="mb-0">
                <b>Contact Number : </b>
                {address.phone}
              </p>
            </Col>
            <Col span={10}>
              <div className="" style={{ marginTop: "80px" }}>
                <p className="mb-4">
                  <b>Updated at : </b>
                  <span className="fnshed-clr">
                    {moment(address.modified_at).format("LLLL")}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Widget>
    );

    const toggle = (isShow, event) => {
      this.setState({ switchDisable: isShow });
    };
    return (
      <React.Fragment>
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Field
                formItemLayout={formItemLayout}
                name="cardHodler"
                component={AInputField}
                placeholder="Card Holder"
                onFocus={this.handleInputFocus}
                hasFeedback
              />
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Field
                formItemLayout={formItemLayout}
                name="cardNumber"
                component={AInputField}
                placeholder="0000 0000 0000 0000"
                onFocus={this.handleInputFocus}
                hasFeedback
                maxLength={23}
                // validate={[ maxLength19(19) ]}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Field
                formItemLayout={formItemLayout}
                name="expire"
                component={AInputField}
                placeholder="MM/YY"
                onFocus={this.handleInputFocus}
                max="12"
                min="1"
                hasFeedback
                maxLength={5}
                // validate={[ maxLength(5) ]}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={24}>
              <Field
                formItemLayout={formItemLayout}
                name="cvc"
                component={AInputField}
                placeholder="000"
                onFocus={this.handleInputFocus}
                hasFeedback
                maxLength={3}
              />
            </Col>
          </Row>
          <div style={{ marginBottom: "20px" }}>
            <span>Add Shooping Address as Bulk Address </span>
            <Switch onChange={toggle} disabled={!addressValue} />
            <br />
          </div>
          {switchDisable ? (
            <Radio.Group onChange={this.onChange}>
              {address.data &&
                address.data.data &&
                address.data.data.shipping_addresses &&
                address.data.data.shipping_addresses.map((address, i) => (
                  <Radio value={address}>
                    <AddressCard style={{ width: "100%" }} address={address} />
                    <br />
                  </Radio>
                ))}
            </Radio.Group>
          ) : (
            <div>
              <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    // label="First Name"
                    name="firstName"
                    component={AInputField}
                    placeholder="First Name"
                    hasFeedback
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    // label="First Name"
                    name="lastName"
                    component={AInputField}
                    placeholder="Last Name"
                    hasFeedback
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="addressLine1"
                    component={AInputField}
                    placeholder="Address Line 1"
                    hasFeedback
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="addressLine2"
                    component={AInputField}
                    placeholder="Address Line 2"
                    // hasFeedback
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="city"
                    component={AInputField}
                    placeholder="City"
                    hasFeedback
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="state"
                    component={AInputField}
                    placeholder="State/Province/Region"
                    hasFeedback
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="country"
                    component={ASelectField}
                    hasFeedback
                  >
                    <Option value="LKA">Sri Lanka</Option>
                    <Option value="uk">UK</Option>
                    <Option value="USA">USA</Option>
                    <Option value="canada">Canada</Option>
                    <Option value="india">India</Option>
                    <Option value="AUS">Australia</Option>
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col xl={9} lg={9} md={9} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemZipLayout}
                    name="zip"
                    component={AInputField}
                    placeholder="Zip Code"
                    hasFeedback
                  />
                </Col>
                <Col xl={15} lg={15} md={15} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="contactNumber"
                    component={AInputField}
                    placeholder="contactNumber"
                    hasFeedback
                  />
                </Col>
              </Row>
            </div>
          )}
          <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={pristine || submitting}
              style={{ marginRight: "10px", marginBottom: "0" }}
            >
              Confirm
            </Button>
            <Button
              disabled={pristine || submitting}
              onClick={reset}
              style={{ marginBottom: "0" }}
            >
              Clear Form
            </Button>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  var dateObj = new Date();
  var currentmonth = dateObj.getUTCMonth() + 1;
  var currentyear = dateObj.getUTCFullYear();
  var pattern = new RegExp(/^\d*[1-9]\d*$/);
  const errors = {};

  if (!values.cardNumber) {
    errors.cardNumber = "Card number is required";
  }

  if (
    values.cardNumber &&
    values.cardNumber.replace(/\s/g, "").search(pattern)
  ) {
    errors.cardNumber = "Invalid Card Number";
  }

  if (
    (values.cardNumber && values.cardNumber.length < 9) ||
    (values.cardNumber && values.cardNumber.length > 23)
  ) {
    errors.cardNumber = "Number Length Does not Match";
  }

  if (!values.expire) {
    errors.expire = "Card expire is required";
  }

  if (values.expire) {
    if (values.expire.includes("/")) {
      var month = parseInt(values.expire.split("/")[0]);
      var year = parseInt(values.expire.split("/")[1]);

      if (month < 0 || month > 12) {
        errors.expire = "Invalid Month";
      }
      if (year < parseInt(String(currentyear).slice(currentyear.length, -2))) {
        errors.expire = "Card Is Expired";
      }

      if (
        +year === parseInt(String(currentyear).slice(currentyear.length, -2)) &&
        month < currentmonth
      ) {
        errors.expire = "Card Is Expired";
      }
    }
    // errors.expire = "Card expire is required";
  }

  if (!values.cardHodler) {
    errors.cardHodler = "Card holder is required";
  }
  // if (!values.expireDateYear) {
  //   errors.expireDateYear = "Card expire year is required";
  // }
  // if (!values.expireDateMonth) {
  //   errors.expireDateMonth = "Card expire month is required";
  // }
  if (!values.cvc) {
    errors.cvc = "CVV  is required";
  }

  if (values.cvc && values.cvc.length !== 3) {
    errors.cvc = "Invalid CVV";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues("CreditCard")(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
  };
}
export default withRouter(
  reduxForm({
    form: "CreditCard",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(AddCreditCardModalBody))
);
