import React from "react";
import PolicyDes from "components/Buyer/Policy/policy"

const policy = () => {

  return (
    <div className="gx-container" style={{ paddingTop: 10 }}>
      <PolicyDes />
    </div>
  );
};

export default policy;


