import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import errorManagerActions from "../../ErrorManager/ducks/actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";
import { NotificationManager } from "react-notifications";
import history from "../../../_helpers/history";
import {
  GetWishListedStatusByProductIDs,
  GetFeaturedProducts,
  GetProductByID,
} from "../../../firebase/functions";
import IntlMessages from "util/IntlMessages";
import { IS_ENABLED_SHIPPING_COST_API } from "../../../constants";

//get single product
const getProduct = createLogic({
  type: types.GET_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    GetProductByID(action.payload.id)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getProductSuccess(data));

        IS_ENABLED_SHIPPING_COST_API &&
          dispatch(
            actions.getShippingCost({
              id: action.payload.id,
            })
          );

        dispatch(actions.addUserSelections({ key: "quantity", value: 1 }));
        dispatch(
          actions.getRelatedProductsForProduct({ id: action.payload.id })
        );
      })
      .catch((err) => {
        let errMessage = err || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        NotificationManager.error(errMessage, "Get Product Fail");
        dispatch(
          actions.getProductFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get related products for product
const getRelatedProductsForProduct = createLogic({
  type: types.GET_RELATED_PRODUCTS_FOR_PRODUCT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_PRODUCT + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getRelatedProductsForProductSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.getRelatedProductsForProductFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get all Products
const getHomeProducts = createLogic({
  type: types.GET_HOME_PRODUCTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetFeaturedProducts()
      .then(async (data) => {
        const wishlisted = await GetWishListedStatusByProductIDs(
          data.map((product) => product.id)
        );
        data.forEach(async (product) => {
          product.is_wishlisted = wishlisted[product.id];
        });
        dispatch(actions.getHomeProductsSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        NotificationManager.error(errMessage, "Home Product Fail");
        dispatch(
          actions.getHomeProductsFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getShippingCost = createLogic({
  type: types.GET_SHIPPING_COST,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    HTTPClient.Get(
      endPoints.GET_SHIPPING_COST + `?product_id=${action.payload.id}`
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(
          actions.getShippingCostSuccess({
            data: data,
            requestObj: action.payload,
          })
        );
      })
      .catch((err) => {
        if (err && err.status === 524) {
          dispatch(
            errorManagerActions.serverSideErrorHandler({
              title: "Error!",
              message: err,
            })
          );
        }
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        console.log(
          "🚀 ~ file: service.js ~ line 159 ~ process ~ errMessage",
          errMessage
        );
        dispatch(
          actions.getShippingCostFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const makePayment = createLogic({
  type: types.MAKE_PAYMENT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    // debugger;
    HTTPClient.Post(endPoints.MAKE_PAYMENT, action.payload.paymentDto)
      .then((resp) => resp.data)
      .then((data) => {
        let needSecureAcceptance =
          (data.data &&
            data.data.response &&
            data.data.response.need_secure_acceptance) ||
          false;
        let navigateURL =
          (data.data && data.data.response && data.data.response.url) || null;
        if (needSecureAcceptance && navigateURL !== null) {
          const win = window.open(navigateURL, "_self");
          dispatch(
            actions.secureAcceptanceHandler({
              needSecureAcceptance: needSecureAcceptance,
            })
          );
          dispatch(
            actions.makePaymentSuccess({
              data: data,
              needSecureAcceptance: needSecureAcceptance,
            })
          );
          navigateURL && win.focus();
        } else if (!needSecureAcceptance) {
          dispatch(
            actions.secureAcceptanceHandler({
              needSecureAcceptance: needSecureAcceptance,
            })
          );
          dispatch(
            actions.makePaymentSuccess({
              data: data,
              needSecureAcceptance: needSecureAcceptance,
            })
          );
          NotificationManager.success(
            <IntlMessages id="notification.cart.service.makePayment.success.content" />,
            <IntlMessages id="notification.cart.service.makePayment.success.title" />
          );
          history.replace("/");
          history.push("/checkout/success");
        } else {
          NotificationManager.error(
            <IntlMessages id="notification.cart.service.makePayment.fail.content" />,
            <IntlMessages id="notification.cart.service.makePayment.fail.title" />
          );
        }
      })
      .catch((err) => {
        var { data } = err.response;
        var errMessage = (data && data.message) || (
          <IntlMessages id="notification.cart.service.makePayment.fail.content" />
        );
        dispatch(
          actions.makePaymentFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const makeAthorizePayment = createLogic({
  type: types.MAKE_AUTHORIZE_PAYMENT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    // debugger;
    HTTPClient.Post(
      endPoints.MAKE_AUTHORIZE_PAYMENT,
      action.payload.authorizePaymentDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        let needSecureAcceptance =
          (data.data &&
            data.data.response &&
            data.data.response.need_secure_acceptance) ||
          false;
        if (!needSecureAcceptance) {
          dispatch(
            actions.secureAcceptanceHandler({
              needSecureAcceptance: needSecureAcceptance,
            })
          );
          dispatch(
            actions.makePaymentSuccess({
              data: data,
              needSecureAcceptance: needSecureAcceptance,
            })
          );
          NotificationManager.success(
            <IntlMessages id="notification.cart.service.makePayment.success.content" />,
            <IntlMessages id="notification.cart.service.makePayment.success.title" />
          );
        } else {
          NotificationManager.error(
            <IntlMessages id="notification.cart.service.makePayment.fail.title" />,
            <IntlMessages id="notification.cart.service.makePayment.fail.title" />
          );
        }
      })
      .catch((err) => {
        var { data } = err.response;
        var errMessage = (data && data.message) || (
          <IntlMessages id="notification.cart.service.makePayment.fail.content" />
        );
        dispatch(
          actions.makePaymentFail({
            title: (
              <IntlMessages id="notification.cart.service.makePayment.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const updateShippingCost = createLogic({
  type: types.UPDATE_SHIPPING_COST,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    // debugger;
    const { items } = action.payload.checkoutItems;
    let costResponse = [];
    let promises = [];
    // var dummyId = "a1ed1881-a268-11ea-a691-cfca0ccbdc51";

    items.map((item, i) => {
      promises.push(
        HTTPClient.Post(endPoints.GET_SHIPPING_COST_BY_ADDRESS, {
          product_id: item.id,
          ship_to: action.payload.shippingInfoDto,
        }).then((response) => {
          // do something with response
          var responseData = response.data;
          var updatedObj = {
            ...responseData,
            id: item.id,
          };
          costResponse.push(updatedObj);
        })
      );
      return null;
    });

    Promise.all(promises)
      .then(() => {
        dispatch(actions.updateShippingCostSuccess(costResponse));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.cart.service.updateShippingCost.fail.content" />
        );
        dispatch(
          actions.updateShippingCostFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addWishlistItem = createLogic({
  type: types.ADD_WISHLIST_ITEM,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.WISHLIST, action.payload.wishlistDto)
      .then((resp) => resp.data)
      .then((data) => {
        const suucessMessage = data.message || (
          <IntlMessages id="notification.cart.service.wishlist.success.content" />
        );
        NotificationManager.success(
          suucessMessage,
          <IntlMessages id="notification.cart.service.wishlist.success.title" />
        );
        dispatch(
          actions.addWishlistItemSucess({
            data: data,
            wishlistDto: action.payload.wishlistDto,
            isHome: action.payload.isHome,
            isSingleProduct: action.payload.isSingleProduct,
          })
        );
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.addWishlistItemFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const removeWishlistItem = createLogic({
  type: types.REMOVE_WISHLIST_ITEM,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    // debugger;
    HTTPClient.Delete(endPoints.WISHLIST + `/${action.payload.wishlist_id}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.removeWishlistItemSucess(data));
        action.payload.callback(action.payload.wishlist_id);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.removeWishlistItemFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getRelatedProducts = createLogic({
  type: types.GET_RELATED_PRODUCTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    // debugger;
    HTTPClient.Post(endPoints.RELATED_PRODUCTS, {
      product_ids: action.payload.product_ids,
    })
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getRelatedProductsSucess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.getRelatedProductsFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const addProductFAQS = createLogic({
  type: types.ADD_PRODUCT_FAQS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    let productFAQSDto = {
      content: action.payload.content,
    };
    HTTPClient.Post(
      endPoints.ADD_PRODUCT_FAQS + `/${action.payload.productID}`,
      productFAQSDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.cart.service.FAQ.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.cart.service.FAQ.success.title" />
        );
        dispatch(actions.addProductFAQSSucess(data));
        action.payload.callback(action.payload.content);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.cart.service.FAQ.fail.title" />
        );
        dispatch(
          actions.addProductFAQSFail({
            title: (
              <IntlMessages id="notification.cart.service.FAQ.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const productsSelectInCart = createLogic({
  type: types.PRODUCTS_SELECT_IN_CART,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    let shippingDetails = action.payload.shippingDetails;
    var ship_to = null;
    if (shippingDetails) {
      ship_to = {};
      ship_to["first_name"] = shippingDetails["first_name"];
      ship_to["last_name"] = shippingDetails["last_name"];
      ship_to["zip"] = shippingDetails.zip;
      ship_to["city"] = shippingDetails.city;
      ship_to["address1"] = shippingDetails.address1;
      ship_to["state"] = shippingDetails.state;
      ship_to["country"] = shippingDetails.country;
    }

    let changedQuantityList = {
      cart: [],
      ship_to: ship_to,
    };
    var selectedProducts = action.payload.selectedRows;
    selectedProducts.forEach((product) => {
      let productDetails = {};
      productDetails["product_id"] = product.id;
      productDetails["qty"] = product.userSelections.quantity;
      productDetails["shipping_rate_id"] =
        product.selectedShippingMethod.rate_id;
      productDetails["shipment_id"] =
        product.selectedShippingMethod.shipment_id;
      productDetails["variations"] = product.userSelections.variations;

      changedQuantityList["cart"].push(productDetails);
    });

    HTTPClient.Post(endPoints.PRODUCTS_SELECT_IN_CART, changedQuantityList)
      .then((resp) => resp.data)
      .then((data) => {
        data.data["selectedRows"] = selectedProducts;
        dispatch(actions.productsSelectCartSuccess(data));
      })
      .catch((err) => {
        var errMessage = err?.response?.data?.message;
        dispatch(
          actions.productsSelectChangeInCartFail({
            title: "Error",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const productQuantityChangeInCart = createLogic({
  type: types.PRODUCT_QUANTITY_CHANGE_IN_CART,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    let quantityChangedProduct = action.payload.quantityChangedProoduct;

    let selectedProducts = action.payload.updatedSelectedItems;

    let cartType = action.payload.cartType;

    if (selectedProducts) {
      var item = selectedProducts.find((product) => {
        return product.id === quantityChangedProduct.product_id;
      });
      if (item) {
        item.userSelections.quantity = quantityChangedProduct.value;
      }

      let shippingDetails = action.payload.defaultShippingAddress;
      var ship_to = null;
      if (shippingDetails) {
        ship_to = {};
        ship_to["first_name"] = shippingDetails["first_name"];
        ship_to["last_name"] = shippingDetails["last_name"];
        ship_to["zip"] = shippingDetails.zip;
        ship_to["city"] = shippingDetails.city;
        ship_to["address1"] = shippingDetails.address1;
        ship_to["state"] = shippingDetails.state;
        ship_to["country"] = shippingDetails.country;
      }

      let changedQuantityList = {
        cart: [],
        ship_to: ship_to,
      };

      selectedProducts.forEach((product) => {
        let productDetails = {};
        productDetails["product_id"] = product.id;
        productDetails["qty"] = product.userSelections.quantity;
        productDetails["shipping_rate_id"] =
          product.selectedShippingMethod.rate_id;
        productDetails["shipment_id"] =
          product.selectedShippingMethod.shipment_id;
        productDetails["variations"] = product.userSelections.variations;

        changedQuantityList["cart"].push(productDetails);
      });

      HTTPClient.Post(endPoints.CHANGED_PRODUCT_QUANTITY, changedQuantityList)
        .then((resp) => resp.data)
        .then((data) => {
          dispatch(
            actions.productQuantityChangeInCartSuccess({
              data: data,
              cartType: cartType,
            })
          );
        })
        .catch((err) => {
          let errMessage = err?.response?.data?.message || (
            <IntlMessages id="notification.error.somethingwentwrong" />
          );
          dispatch(
            actions.productQuantityChangeInCartFail({
              title: "Operation Fail",
              message: errMessage,
              cartType: cartType,
            })
          );
        })
        .then(() => done());
    } else {
    }
  },
});

const productRemoveFromCart = createLogic({
  type: types.REMOVE_PRODUCT_FROM_CART,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    let item = action.payload.record;
    let selectedProducts = action.payload.selectedProducts;

    let item1 = action.payload.record;
    let selectedProducts1 = [];
    Object.assign(selectedProducts1, selectedProducts);

    dispatch(actions.removeCartItem({ item1, selectedProducts1 }));

    const index = selectedProducts.findIndex(
      (product) => product.id === item.id
    );

    if (index !== undefined) {
      selectedProducts.splice(index, 1);

      // if(selectedProducts){
      //   var item = selectedProducts.find(product => {
      //     return product.id == quantityChangedProduct.product_id;
      // });
      //  if (item) {
      //    item.userSelections.quantity = quantityChangedProduct.value
      //   }

      let shippingDetails = action.payload.defaultShippingAddress;
      var ship_to = null;
      if (shippingDetails) {
        ship_to = {};
        ship_to["first_name"] = shippingDetails["first_name"];
        ship_to["last_name"] = shippingDetails["last_name"];
        ship_to["zip"] = shippingDetails.zip;
        ship_to["city"] = shippingDetails.city;
        ship_to["address1"] = shippingDetails.address1;
        ship_to["state"] = shippingDetails.state;
        ship_to["country"] = shippingDetails.country;
      }

      let changedQuantityList = {
        cart: [],
        ship_to: ship_to,
      };

      selectedProducts.forEach((product) => {
        let productDetails = {};
        productDetails["product_id"] = product.id;
        productDetails["qty"] = product.userSelections.quantity;
        productDetails["shipping_rate_id"] =
          product.selectedShippingMethod.rate_id;
        productDetails["shipment_id"] =
          product.selectedShippingMethod.shipment_id;
        productDetails["variations"] = product.userSelections.variations;

        changedQuantityList["cart"].push(productDetails);
      });

      HTTPClient.Post(endPoints.CHANGED_PRODUCT_QUANTITY, changedQuantityList)
        .then((resp) => resp.data)
        .then((data) => {
          data.data["selectedRows"] = selectedProducts;
          dispatch(actions.removeProductFromCartSuccess(data));
          // dispatch(actions.removeCartItem({ item }));
        })
        .catch((err) => {
          let errMessage = err?.response?.data?.message || (
            <IntlMessages id="notification.error.somethingwentwrong" />
          );
          dispatch(
            actions.removeProductFromCartCartFail({
              title: "Operation Failed",
              message: errMessage,
            })
          );
        })
        .then(() => done());
    } else {
      // dispatch(actions.removeCartItem({ item }));
      
    }

    // }else{

    // }
  },
});

const checkOutCalculation = createLogic({
  type: types.CHECKOUT_CALCULATION,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    let shippingDetails = action.payload.defaultShippingAddress;
    var ship_to = null;
    if (shippingDetails) {
      ship_to = {};
      ship_to["first_name"] = shippingDetails["first_name"];
      ship_to["last_name"] = shippingDetails["last_name"];
      ship_to["zip"] = shippingDetails.zip;
      ship_to["city"] = shippingDetails.city;
      ship_to["address1"] = shippingDetails.address1;
      ship_to["state"] = shippingDetails.state;
      ship_to["country"] = shippingDetails.country;
    }

    let changedQuantityList = {
      cart: [],
      ship_to: ship_to,
    };
    var selectedProducts = action.payload.checkoutItemsObj;
    selectedProducts.forEach((product) => {
      let productDetails = {};
      productDetails["product_id"] = product.id;
      productDetails["qty"] = product.userSelections.quantity;
      productDetails["shipping_rate_id"] =
        product.selectedShippingMethod.rate_id;
      productDetails["shipment_id"] =
        product.selectedShippingMethod.shipment_id;
      productDetails["variations"] = product.userSelections.variations;

      changedQuantityList["cart"].push(productDetails);
    });

    HTTPClient.Post(endPoints.PRODUCTS_SELECT_IN_CART, changedQuantityList)
      .then((resp) => resp.data)
      .then((data) => {
        data.data["selectedRows"] = selectedProducts;
        dispatch(actions.checkOutCalulationSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.checkOutCalulationFail({
            title: "Operation Failed",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const checkOrderQuantity = createLogic({
  type: types.CHECK_ORDER_QUANTITY,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    let shippingDetails = action.payload.defaultShippingAddress;
    var ship_to = null;
    if (shippingDetails) {
      ship_to = {};
      ship_to["first_name"] = shippingDetails["first_name"];
      ship_to["last_name"] = shippingDetails["last_name"];
      ship_to["zip"] = shippingDetails.zip;
      ship_to["city"] = shippingDetails.city;
      ship_to["address1"] = shippingDetails.address1;
      ship_to["state"] = shippingDetails.state;
      ship_to["country"] = shippingDetails.country;
    }

    let checkOrderQuantityDto = {
      cart: [],
      ship_to: ship_to,
    };
    var selectedProducts = action.payload.cartObj;
    selectedProducts.forEach((product) => {
      let productDetails = {};
      productDetails["product_id"] = product.id;
      productDetails["qty"] = product.userSelections.quantity;
      productDetails["shipping_rate_id"] =
        product.selectedShippingMethod.rate_id;
      productDetails["shipment_id"] =
        product.selectedShippingMethod.shipment_id;
      productDetails["variations"] = product.userSelections.variations;

      checkOrderQuantityDto["cart"].push(productDetails);
    });

    HTTPClient.Post(endPoints.CHECK_ORDER_QTY, checkOrderQuantityDto)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.checkOrderQuantitySucess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.checkOrderQuantityFail({
            title: "Operation Failed",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [
  getProduct,
  getShippingCost,
  makePayment,
  makeAthorizePayment,
  updateShippingCost,
  addWishlistItem,
  removeWishlistItem,
  getRelatedProducts,
  getHomeProducts,
  addProductFAQS,
  getRelatedProductsForProduct,
  productQuantityChangeInCart,
  productsSelectInCart,
  productRemoveFromCart,
  checkOutCalculation,
  checkOrderQuantity,
];
