import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

export default class IntellectualPropertyRules extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Intellectual Property Rules" />
          <CSBreadcrumb isRoot={true} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              <u>
                <b>Tradesbarn Program:</b>
              </u>
            </p>
            <p>
              We take protecting intellectual property rights, and maintaining a
              safe environment for our buyers and sellers, very seriously. As
              such, certain types of items, such as bootleg recordings, are not
              allowed on Tradesbarn, and others, such as celebrity autographs,
              have a range of restrictions around how they can be sold. To
              provide further support in this area, <i>Team Tradesbarn </i>
              Constantly Monitor copyright.gov guidelines and design new ways to
              protect copyright laws. Our Teams offers intellectual property
              owners a way to report listings that they believe infringe on
              their rights.
            </p>
            <p>
              Please see our full guidelines below for more details on the
              Copyright Program, and the rules we have in place to help protect
              intellectual property rights.
            </p>
            <br />
            <p>
              <u>
                <b>Frequently Asked Questions</b>
              </u>
            </p>

            <p>
              Can I list with a disclaimer that I don't know if some of my items
              are authentic?
            </p>
            <ul>
              <li>
                <p>
                  No, replicas, counterfeit items and unauthorized copies are
                  not allowed, even with a disclaimer. You're responsible for
                  the authenticity of the items you list so you should contact
                  the manufacturer to check your items' authenticity.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>
                  Someone's using the descriptions from my listings word for
                  word. What can I do to stop them?
                </b>
              </u>
            </p>

            <ul>
              <li>
                <p>
                  When you create a listing or product page you give TB and its
                  member’s permission, through our user agreement, to use your
                  images and product details. Your content may be added to
                  theTradesbarn product catalog, at our discretion. Content
                  added to the catalog may be used by other sellers in their
                  Tradesbarn listings.
                </p>
              </li>
              <li>
                <p>
                  You may contact the member and ask them to remove text from
                  their listing if it is not part of the Tradesbarn product
                  catalog.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>
                  What is copyright, and how do I know if I am violating
                  someone's rights?
                </b>
              </u>
            </p>

            <ul>
              <li>
                <p>
                  Copyright is a form of intellectual property law that protects
                  original works of authorship, such as books, music, videos,
                  photos, paintings, and software. Copyright covers both
                  published and unpublished works, and the work is protected as
                  soon as it's in a tangible form on paper or on video, for
                  example. The copyright prevents others from using the work
                  without permission.
                </p>
              </li>
              <li>
                <p>
                  Under copyright laws, the owner of a particular, authorized
                  copy of a copyrighted work is generally entitled to resell the
                  particular copy they own. For example, if you buy a DVD movie,
                  you're allowed to resell that particular DVD. However, you
                  can't duplicate and sell, or even give away, unauthorized
                  copies of the DVD, unless you're the copyright owner or have
                  permission from the copyright owner to do so.
                </p>
              </li>
            </ul>
            <br />
            <p>
              <u>
                <b>
                  What is a trademark, and what might be considered trademark
                  infringement?
                </b>
              </u>
            </p>

            <ul>
              <li>
                <p>
                  A trademark is a name or logo used by a company to identify
                  its goods or services. For example, Tradesbarn® is the name of
                  our company, and it's also a trademark used on our site and on
                  various Tradesbarn products and services. Trademark laws are
                  primarily designed to protect people from confusing one
                  company's goods or services with those of another.
                </p>
              </li>
            </ul>

            <ul>
              <li>
                <p>
                  Trademark infringement usually involves using someone's
                  trademark on goods or services in a way that may confuse
                  others about the source or affiliation of those goods or
                  services. For example, if a seller
                </p>
              </li>
            </ul>
            <p>
              unauthorized by or unaffiliated with Nike® sells sports clothes
              called "Nikestuff," the seller is probably infringing Nike's
              trademark.
            </p>
            <ul>
              <li>
                <p>
                  Learn more about copyright and trademarks from Copyright.gov
                  and the United States Patent and Trademark Office.
                </p>
              </li>
            </ul>
            <p>
              We're committed to providing a safer place to buy and sell items,
              as well as protecting intellectual property rights. Using
              intellectual property, such as copyrighted material and
              trademarks, in an unlawful way is considered infringement, which
              is against the law and Tradesbarn policies.
            </p>
            <p>
              Before listing items on Tradesbarn, be sure to read and follow our
              rules below and our Listing policies.
            </p>
            <br />
            <p>
              <u>
                <b>What are the guidelines?</b>
              </u>
            </p>
            <p>
              <i>
                Rules that affect what you can list on Tradesbarn Rules that
                affect how you can list items on Tradesbarn
              </i>
            </p>
            <p>
              <u>
                TradesbarnCopyright Program: Reporting violations in listings or
                products
              </u>
            </p>

            <p>
              We created the <i>Team Tradesbarn </i>so intellectual property
              owners could report listings or products that infringe their
              intellectual property rights. It is in Tradesbarn's interest to
              ensure that infringing items are removed from the site, as they
              erode the trust of our buyers and good sellers.
            </p>
            <br />
            <p>
              <u>
                <b>Here are some of our basic guidelines on what to do:</b>
              </u>
            </p>

            <p>
              <i>If you're an intellectual property rights owner</i>
            </p>
            <p>
              Only intellectual property rights owners can report Tradesbarn
              listings that infringe their copyright, trademark or other
              intellectual property rights. If you are a rights owner, you can
              directly contact the TradesbarnTeam Tradesbarn and file a report,
              reporting intellectual property infringements
              (Copyrights@Tradesbarn.com)
            </p>
            <p>
              <i>If you're not the rights owner</i>
            </p>
            <p>
              Even if you're not the rights owner, you can still help by getting
              in touch with the owner and encouraging them to contact us. If
              your listing or product was removed if you believe your listing or
              product was removed in error, contact (
              <a href="mailto:Copyrights@tradesbarn.com">
                <font color="#0462c1">
                  <u>Copyrights@Tradesbarn.com</u>
                </font>
              </a>
              )
            </p>
          </div>
        </Widget>
      </div>
    );
  }
}
