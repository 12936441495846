import React, { Component } from "react";
import Widget from "../../../components/Common/Widget/Widget";
import { List } from "antd";
import { Link, withRouter } from "react-router-dom";

const data = [
  {
    title: "Buyer Registration",
    route: "/support-center/buyer-registration",
  },
  {
    title: "Seller Registration",
    route: "/support-center/seller-registration",
  },
  {
    title: "Product Listing",
    route: "/support-center/product-listing",
  },
  {
    title: "Resolution Center",
    route: "/support-center/resolution-center",
  },
];

class SupportCenterSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
    };
  }
  componentDidMount() {
    this.activeLinkHandler();
  }

  activeLinkHandler = () => {
    const { path } = this.props.match;
    switch (path.split("/")[2]) {
      case "buyer-registration":
        this.setState({
          current: "Buyer Registration",
        });
        break;
      case "seller-registration":
        this.setState({
          current: "Seller Registration",
        });
        break;
      case "product-listing":
        this.setState({
          current: "Product Listing",
        });
        break;
      case "resolution-center":
        this.setState({
          current: "Resolution Center",
        });
        break;

      default:
        this.setState({
          default: true,
        });
    }
  };

  render() {
    const { current } = this.state;

    return (
      <div className="cus-srvs-crd-pstn">
        <div style={{ marginTop: "44px" }}>
          <Widget>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => {
                return (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        <Link
                          // onClick={() => this.activeLinkHandler(item.title)}
                          to={item.route}
                          style={{
                            color: current === item.title ? "#038fde" : "black",
                          }}
                        >
                          {item.title}
                        </Link>
                      }
                    />
                  </List.Item>
                );
              }}
            />
          </Widget>
        </div>
      </div>
    );
  }
}

export default withRouter(SupportCenterSidebar);
