import React from "react";
import Auxiliary from "util/Auxiliary";
import { Row, Divider } from "antd";
import PopularProductCard from "./PopularProductCard";
import "./PopularProductCardContainer.less";
// import ShowcaseCarousel from "../../Carousel/ShowcaseCarousel/ShowcaseCarousel";

const PopularProductCardContainer = ({ data, isLoading }) => {
  const { name, products } = data;
  return (
    <Auxiliary>
      {/* <h4 className="mb-0 h4-jr nw-jr-fnt-clr"></h4> */}
      <Divider orientation="left" className="mt-0 mb-2">
        <h5 className="nw-jr-fnt-clr">{`Popular ${name}`}</h5>
      </Divider>
      {/* <hr className="mt-2" /> */}

      {/* <p className="gx-text-grey">{subTitle}</p> */}
      {/* <p>{desc}</p> */}
      <Row>
        {products &&
          products.map((product, i) => {
            if (i < 4) {
              return (
                <PopularProductCard
                  productDetails={product}
                  key={i}
                  isLoading={isLoading}
                />
              );
            } else {
              return null;
            }
          })}
      </Row>
      {/* <ShowcaseCarousel /> */}
      {/* <h2 className="h3 gx-mb-2">{name}</h2> */}
    </Auxiliary>
  );
};

export default PopularProductCardContainer;
