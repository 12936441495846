export function scrollIntoFirstError(errors) {
  // # Focus on first error

  // When Immutable:
  // The errors are _not_ in `REGISTER_FIELD` order so we cant just "use" the first one..
  // (possibly requires an ordered list reviver when getting errors?)

  // so we try to get the first error element in another way, using a DOM query:

  // Note: You can't query a possible error class in `onSubmitFail` as the `render` may not have yet happened.

  // We do this using a DOM selector of all the fields that have error'ed and use [`querySelector`](https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector) which
  // Returns the first Element within the document that matches the specified selector, or group of selectors.
  const errorEl = document.querySelector(
    // flattenObject: https://github.com/hughsk/flat/issues/52
    // Object.keys(flattenObject(errors))
    //   .map((fieldName) => `[name="${fieldName}"]`)
    //   .join(",")

    Object.keys(flattenObject(errors))
      .map((fieldName) =>
        complexFields.includes(fieldName)
          ? `[for="${fieldName}"]`
          : `[name="${fieldName}"]`
      )
      .join(",")
  );
  if (errorEl) {
    // npe
    // if (errorEl.scrollIntoView) {
    //   errorEl.scrollIntoView(); // fails as the site has a fixed/floating header
    // }
    errorEl.scrollIntoView({ block: "center" });
    errorEl.focus(); // this scrolls without visible scroll
  }
}

function flattenObject(obj) {
  var tmp,
    k,
    res = {};
  for (k in obj) {
    tmp = obj[k];
    while (typeof tmp == "object" && k in tmp) {
      tmp = tmp[k];
    }
    res[k] = tmp;
  }
  return res;
}

const complexFields = [
  "productDescripton",
  "productCondition",
  "isProductVariable",
  "warranty",
  "packageType",
  "domesticShippingMethod",
  "domesticShippingTrackingAvailable",
  "internationalShippingMethod",
  "internationalShippingCarriers",
  "internationalShippingTrackingAvailable",
  "itemCountry",
  "itemCountry",
  "returnAcceptedMethod",
];
