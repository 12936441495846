import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Card, Select, Form, Input } from "antd";
import {
  FormItemLayout,
  ItemFormItemLayout,
} from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";
import { returnTypes } from "../../../../constants/Utilities";

const Option = Select.Option;
const FormItem = Form.Item;

const ASelectField = makeField(Select);
const AInputField = makeField(Input);

class ReturnPolicies extends Component {
  render() {
    return (
      <div className="mb-3">
        <h6 className="mb-3">Return Policies</h6>
        <Card style={{ backgroundColor: "#fafafa" }} className="jr-fld-catch ">
          <FormItem
            {...FormItemLayout}
            label="Shipping Handling Days "
            required
          >
            <Field
              formItemLayout={ItemFormItemLayout}
              name="shippingHandlingDays"
              component={AInputField}
              placeholder="Shipping Handling Days"
              hasFeedback
              style={{ width: "100%" }}
              normalize={(val) => (val || "").replace(/[^\d]/g, "")}
            />
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.shippingHandlingDay} />
            </span>
          </FormItem>

          <FormItem
            {...FormItemLayout}
            label="Return Accepted Method"
            required
            id="returnAcceptedMethod"
          >
            <Field
              formItemLayout={FormItemLayout}
              name={"returnAcceptedMethod"}
              component={ASelectField}
              style={{ width: "100%" }}
              placeholder="Return Accepted Method"
              hasFeedback
            >
              {returnTypes.map((returnType, i) => {
                return (
                  <Option key={i} value={returnType.value}>
                    {returnType.label}
                  </Option>
                );
              })}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.returnShpingAccepted} />
            </span>
          </FormItem>
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ReturnPolicies = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(ReturnPolicies);

// You have to connect() to any reducers that you wish to connect to yourself
ReturnPolicies = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(ReturnPolicies);

export default ReturnPolicies;
