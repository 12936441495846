import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Card, Form } from "antd";
import RenderTextEditor from "../../../../components/Seller/ProductManagement/RenderTextEditor/RenderTextEditor";
import {
  FormItemLayout2,
  FormItemLayoutDescription,
} from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";

const FormItem = Form.Item;

class ProductDescription extends Component {
  render() {
    return (
      <div className="mb-3">
        <h6 className="mb-3">Product Description</h6>
        {/* <ExtraInfo title={extraInfoDetails.descriptionSection} /> */}
        <Card style={{ backgroundColor: "#fafafa" }}>
          {/* <div className="mb-1 fnshed-clr">Product Highlight : </div> */}
          {/* <ExtraInfo title={extraInfoDetails.heighlights} /> */}

          <FormItem {...FormItemLayoutDescription} label="Product Highlight ">
            <Field
              formItemLayout={FormItemLayout2}
              name="productHighlight"
              component={RenderTextEditor}
              hasFeedback
            />
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo
                placement={"top"}
                title={extraInfoDetails.heighlights}
              />
            </span>
          </FormItem>

          {/* <div className="mb-1 fnshed-clr">Product Description : </div> */}
          {/* <ExtraInfo title={extraInfoDetails.description} /> */}

          <FormItem
            {...FormItemLayoutDescription}
            label="Product Description "
            required
            id="productDescripton"
          >
            <Field
              formItemLayout={FormItemLayout2}
              name="productDescripton"
              component={RenderTextEditor}
              hasFeedback
            />
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo
                placement={"top"}
                title={extraInfoDetails.description}
              />
            </span>
          </FormItem>
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ProductDescription = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(ProductDescription);

// You have to connect() to any reducers that you wish to connect to yourself
ProductDescription = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(ProductDescription);

export default ProductDescription;
