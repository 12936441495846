import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Input, Tabs, Tooltip, Icon, Spin } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { authActions } from "../ducks";
import { makeField } from "../../../components/Common/Controls/MakeField";
import Register from "../Register/Register";
import "./Login.scss";
import IntlMessages from "util/IntlMessages";
import { clearHTTPClient } from "../../../util/HTTPClient";
import { IS_ENABLED_APPLE_LOGIN } from "../../../constants";

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const EmailField = makeField(Input);
const passwordField = makeField(Input.Password);
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultActiveKey: "1",
    };
  }

  componentDidMount() {
    clearHTTPClient();

    const body = document.getElementsByTagName("body")[0];
    body.classList.add("bckgrnd-img-2");
    const { path } = this.props.match;
    let defaultActiveKey = "1";
    switch (path) {
      case "/login":
        defaultActiveKey = "1";
        break;
      case "/register":
        defaultActiveKey = "2";
        break;
      default:
        defaultActiveKey = "1";
    }

    this.setState({
      defaultActiveKey,
    });
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("bckgrnd-img-2");
  }

  handleHomeNavigator = () => {
    this.props.history.push("/");
  };

  //google User
  googleUserHandler = () => {
    const { authActions } = this.props;
    const redirect_to = new URLSearchParams(this.props.location.search).get(
      "redirect_to"
    );
    authActions.signinGoogleUser({ redirect_to: redirect_to });
  };

  facebookUserHandler = () => {
    const { authActions } = this.props;
    const redirect_to = new URLSearchParams(this.props.location.search).get(
      "redirect_to"
    );
    authActions.signinFacebookUser({ redirect_to: redirect_to });
  };

  twitterUserHandler = () => {
    const { authActions } = this.props;
    const redirect_to = new URLSearchParams(this.props.location.search).get(
      "redirect_to"
    );
    authActions.signinTwitterUser({ redirect_to: redirect_to });
  };

  appleUserHandler = () => {
    const { authActions } = this.props;
    const redirect_to = new URLSearchParams(this.props.location.search).get(
      "redirect_to"
    );
    authActions.signinAppleUser({ redirect_to: redirect_to });
  };

  handleSubmit = (values) => {
    const { email, password } = values;
    const redirect_to = new URLSearchParams(this.props.location.search).get(
      "redirect_to"
    );
    
    // e.preventDefault();
    const { authActions } = this.props;
    authActions.signinEmailUser({
      email: email,
      password: password,
      redirect_to: redirect_to,
    });
  };

  render() {
    const { handleSubmit, currentUser, signupUser, signinUser } = this.props;
    let loading =
      (signinUser.authType === "EMAIL_PASSWORD" &&
        currentUser.loginProcessPending) ||
      signupUser.pending;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container pdng-jr">
          <div className="gx-app-login-content mrgn-jr bckgrnd-lg">
            <div
              className="mb-3 login-logo"
              onClick={() => this.handleHomeNavigator()}
            >
              <img
                style={{ height: "45px" }}
                alt=""
                // src="/static/media/logo.fd441063.png"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
              />
            </div>
            <Spin spinning={loading}>
              <Tabs className="" /*defaultActiveKey={"2"}*/>
                {/* Login Tab */}
                <TabPane tab="LOGIN" key="1" onClick={() => alert("login")}>
                  <Form
                    onSubmit={handleSubmit(this.handleSubmit)}
                    className="gx-signin-form gx-form-row0 mt-4 log-placehldr"
                  >
                    <Field
                      formItemLayout={formItemLayout}
                      name="email"
                      suffix={
                        <Tooltip title="Please type your email address">
                          <Icon
                            type="info-circle"
                            style={{ color: "rgba(0,0,0,.45)" }}
                          />
                        </Tooltip>
                      }
                      component={EmailField}
                      placeholder="Email"
                      hasFeedback
                      autocomplete="on"
                      autoSave
                    />
                    <Field
                      formItemLayout={formItemLayout}
                      name="password"
                      component={passwordField}
                      placeholder="Password"
                      type="password"
                      hasFeedback
                      autocomplete="on"
                      autoSave
                    />
                    <FormItem>
                      <Button
                        style={{ width: "80%" }}
                        type="primary"
                        className="gx-mb-0"
                        htmlType="submit"
                      >
                        <IntlMessages id="app.userAuth.login" />
                      </Button>
                    </FormItem>
                    <br />
                  </Form>
                  <div style={{ marginBottom: "20px" }}>
                    <Link to="/forgot-password">
                      <a href> Forgot Password?</a>
                    </Link>
                  </div>
                </TabPane>

                {/* Register Tab */}
                <TabPane tab="REGISTER" key="2" id="register">
                  <Register />
                </TabPane>
              </Tabs>
              <hr className="mt-2 hr-lgn"></hr>
              <div className="mb-2 lgn-clr">
                Log in with your social network
              </div>
              <div className="gx-flex-row gx-justify-content-between">
                <div className="social-container-lgn">
                  <ul className="social-icons-lgn">
                    <li>
                      <a
                        href
                        onClick={() => {
                          this.facebookUserHandler();
                        }}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href
                        onClick={() => {
                          this.googleUserHandler();
                        }}
                      >
                        <i className="fab fa-google"></i>
                      </a>
                    </li>
                    {IS_ENABLED_APPLE_LOGIN && (
                      <li>
                        <a
                          onClick={() => {
                            this.appleUserHandler();
                          }}
                          href
                        >
                          <i className="fab fa-apple"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Spin>
          </div>
          {/* {signinUser.authType === "EMAIL_PASSWORD" && (currentUser.loginProcessPending || signupUser.pending) ? (
              <div className="gx-loader-view">
                <LoginLoader />
              </div>
            ) : null} */}
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    signinUser: state.AuthStore.signinUser,
    signupUser: state.AuthStore.signupUser,
    currentUser: state.AuthStore.currentUser,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "Login_form",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(Login))
);
