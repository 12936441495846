// Types

const action_header = "customerService/"

export default {
  FEEDBACK_FORM_SUBMIT: `${action_header}FEEDBACK_FORM_SUBMIT`,
  FEEDBACK_FORM_SUBMIT_SUCCESS: `${action_header}FEEDBACK_FORM_SUBMIT_SUCCESS`,
  FEEDBACK_FORM_SUBMIT_FAILED: `${action_header}FEEDBACK_FORM_SUBMIT_FAILED`,

  HANDLE_FEEDBACK_IMAGES: `${action_header}HANDLE_FEEDBACK_IMAGES`,
  CLEAR_FEEDBACK_IMAGES: `${action_header}CLEAR_FEEDBACK_IMAGES`,
  REMOVE_FEEDBACK_IMAGES: `${action_header}REMOVE_FEEDBACK_IMAGES`,
};
