import { API_BASE_URL } from "./../constants";

const base_url = API_BASE_URL;
export default {
  //auth
  GET_CURRENT_USER: base_url + "user",
  SIGN_UP: base_url + "auth/register",
  UPGRADE_ACCOUNT: base_url + "auth/upgrade",
  REGISTER: base_url + "auth/register",
  FORGET_PASSWORD: base_url + "auth/reset",
  SELLER_PROFILE_IMAGE: base_url + "auth/user_image",

  //profile
  GET_PROFILE: base_url + "profile",
  SHIPPING_ADDRESS: base_url + "shipping_address",
  WISHLIST: base_url + "wishlists",
  ORDERREVIEW: base_url + "dashboard/order/review",
  CREDIT_CARD: base_url + "payment/credit_card",
  GET_BUYER_ORDER_COUNT: base_url + "count/orders",

  //home page
  GETPRODUCTS: base_url + `landing`,
  GET_PRODUCT: base_url + "products",
  DISCOUNTED_PRODUCTS: base_url + `landing/discounted_products`,
  TOP_SELLERS: base_url + `landing/top_sellers`,
  ADD_PRODUCT_FAQS: base_url + `products-faqs`,

  GET_SELLER_STORE: base_url + `sellers`,

  //cart
  GET_SHIPPING_COST: base_url + `checkout/cost/country`,
  GET_SHIPPING_COST_BY_ADDRESS: base_url + `checkout/cost`,
  MAKE_PAYMENT: base_url + "checkout/pay",
  MAKE_AUTHORIZE_PAYMENT: base_url + "checkout/paypal_authorize",
  RELATED_PRODUCTS: base_url + "checkout/related_products",
  CHANGED_PRODUCT_QUANTITY: base_url + "checkout/order_summery",
  PRODUCTS_SELECT_IN_CART: base_url + "checkout/order_summery",
  CHECK_ORDER_QTY: base_url + "checkout/order_qty",

  //Resolution-center
  RESOLUTION: base_url + `resolution`,
  RESOLUTION_ORDERS: base_url + `resolution/orders`,
  RESOLUTION_UPLOAD_IMAGE: base_url + `resolution/upload_image`,

  //Dashboard  - seller
  DASHBOARD_SELLER_STATISTICS: base_url + `dashboard/statistics`,
  DASHBOARD_SELLER_REVENUE: base_url + `dashboard/statistics/revenue`,
  DASHBOARD_SELLER_POPULAR_PRODUCT:
    base_url + `dashboard/statistics/popular_products`,
  DASHBOARD_SELLER_ORDERS: base_url + `dashboard/statistics/orders`,
  DASHBOARD_SELLER_ACTIVITIES: base_url + `dashboard/statistics/activities`,
  ORDERS_COUNT: base_url + `dashboard/count/orders`,
  GET_SHIPPING_BUY_LABEL: base_url + `dashboard/order/buy_label`,
  ORDERS_EXPORT: base_url + `dashboard/orders/export`,
  GET_SELLER_LOGO_SIGNURL: base_url + `dashboard/settings/user_image`,
  EXPORT_DETAIL: base_url + `dashboard/reports/export`,
  TRANSACTION_REPORT: base_url + `dashboard/transactions/export`,
  DASHBOARD_ORDER_TRACKING: base_url + `dashboard/order/tracking`,

  //Dashboard - Product Management
  GET_PRODUCT_COUNT: base_url + `dashboard/count/products`,
  CRUD_PRODUCT: base_url + `dashboard/products`,
  GET_PRODUCT_CATEGORIES: base_url + `dashboard/products-categories`,
  REPLY_QUESTION: base_url + "dashboard/faqs/{review_id}",
  GET_PRODUCT_IMAGE_SIGNURL: base_url + "dashboard/product_img",

  //Dashboard - Profile
  SELLER_DETAILS: base_url + `dashboard/settings`,
  UPDATE_SELLER_BUSINESS_INFO: base_url + `dashboard/settings/bussiness`,
  UPDATE_SELLER_INFO: base_url + `dashboard/settings/seller`,
  UPDATE_SELLER_PAYMENT_INFO: base_url + `dashboard/settings/payments`,

  //Dashboard - QnA
  PRODUCT_FAQS: base_url + `dashboard/faqs`,

  //Get Shipping Method
  SHIPPING_METHOD: base_url + `dashboard/carriers/all`,

  //Dashboard - Review Management
  REPLY_TO_REVIEW: base_url + "dashboard/review",

  //Dashboard Moderator and Admin

  USERS: base_url + "dashboard/users",
  USERS_COUNT: base_url + "dashboard/count/users",

  //Contact page
  ADD_CONTACT: base_url + "contact",

  //Feedback Form
  ADD_FEEDBACK: base_url + "feedback",
  GET_FEEDBACK_IMAGE_SIGNURL: base_url + "feedback/upload_image",
};
