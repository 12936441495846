import React from "react";
import { TRADESBARN } from "../../../constants";
import FooterComponent from "./FooterComponent";
import OnShopFooter from "./OnShopFooter";

export default function SiteFooter() {
  return (
    <div>
      {process.env.REACT_APP_BUSINESS_NAME === TRADESBARN ? (
        <FooterComponent />
      ) : (
        <OnShopFooter />
      )}
    </div>
  );
}
