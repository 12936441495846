import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Form, Select, Input } from "antd";
import { makeField } from "../../Common/Controls/MakeField";
import { withRouter } from "react-router-dom";
import { authActions } from "../../../modules/Auth/ducks";
import { allCountriesJson } from "../../../DummyData/AllCountry";
import SellerLogo from "../../../modules/SellerDashboard/SellerAccount/Profile/SellerLogo/SellerLogo";
// import { makeFieldSelect } from "./../../Common/Controls/MakeFieldSelect";
// import { FormItemLayout2 } from "../../../constants/Utilities";
import { IS_ENABLED_INTERNATIONAL_MODE } from "../../../constants";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

// const DisplayContainer = ({ data }) => {
//   return (
//     <table style={{ width: "100%" }}>
//       <tbody>
//         <tr>
//           <td style={{ textAlign: "start" }}>
//             <img
//               // className="gx-mb-4 gx-rounded-circle gx-img-fluid gx-object-cover"
//               src={data.flag}
//               className="flag-select"
//               alt="spoons"
//             />
//           </td>
//           <td style={{ textAlign: "end" }}>
//             <span>{data.dialCode}</span>
//           </td>
//         </tr>
//       </tbody>
//     </table>
//   );
// };
class SellerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValify: !false,
    };
  }
  handleSubmit = (values) => {
    var upgradeDto = {
      store_name: values && values.storeName,
      seller_category: values && values.storecatagory,
      contactNumber: values && values.contactNumber,
      // contactNumber: values && `${values.countryCode}  ${values.contactNumber}`,
      address1: values && values.address1,
      address2: values && values.address2,
      city: values && values.city,
      state: values && values.state,
      country: values && values.country,
      zip_code: values && values.zipCode,
      image: values && values.sellerImage,
    };

    this.props.authActions.upgradeBuyerAccount(upgradeDto);
  };

  render() {
    const { handleSubmit } = this.props;
    const formItemLayout = {
      labelCol: { xs: 24, sm: 8, md: 8, lg: 10, xl: 10 },
      wrapperCol: { xs: 24, sm: 16, md: 16, lg: 14, xl: 14 },
    };

    const formItemLayout2 = {
      //labelCol: { xs: 24, sm: 6, md: 6, lg: 8, xl: 8 },
      wrapperCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
    };

    const tailFormItemLayout = {
      wrapperCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
    };

    return (
      <React.Fragment>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          className="feild-catcher-reg"
        >
          <FormItem
            {...formItemLayout}
            label={`Display Name / ${process.env.REACT_APP_SHOP_NAMING} Name`}
            required
          >
            <Field
              formItemLayout={formItemLayout2}
              name="storeName"
              component={AInputField}
              placeholder={`Display Name / ${process.env.REACT_APP_SHOP_NAMING} Name`}
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Category" required>
            <Field
              formItemLayout={formItemLayout2}
              name="storecatagory"
              placeholder="Select Category"
              component={ASelectField}
              hasFeedback
            >
              <Option value="Individual">Individual</Option>
              <Option value="SME">SME</Option>
              {/* <Option value="Producer">Producter</Option> */}
              <Option value="Manufacturer">Manufacturer</Option>
              {/* <Option value="Trading Business">Trading Business</Option> */}
            </Field>
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Number" required>
            <Field
              className="cont-with-flag"
              formItemLayout={formItemLayout2}
              name="contactNumber"
              component={AInputField}
              // addonBefore={
              //   <Field
              //     formItemLayout={FormItemLayout2}
              //     className="flag-selector"
              //     name="countryCode"
              //     component={ASelectField}
              //     hasFeedback
              //     style={{ width: 120 }}
              //     showSearch
              //     defaultValue="+94"
              //   >
              //     {allCountriesJson.map((country, i) => {
              //       return (
              //         <Option value={country.dialCode} key={i}>
              //           <DisplayContainer data={country} />
              //         </Option>
              //       );
              //     })}
              //   </Field>
              // }
              placeholder="Contact Number"
              onFocus={this.handleInputFocus}
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Address 1" required>
            <Field
              formItemLayout={formItemLayout2}
              // label="First Name"
              name="address1"
              component={AInputField}
              placeholder="Address 1"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Address 2">
            <Field
              formItemLayout={formItemLayout2}
              // label="First Name"
              name="address2"
              component={AInputField}
              placeholder="Address 2"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="City" required>
            <Field
              formItemLayout={formItemLayout2}
              name="city"
              component={AInputField}
              placeholder="City"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="State / Province" required>
            <Field
              formItemLayout={formItemLayout2}
              name="state"
              component={AInputField}
              placeholder="State"
              hasFeedback
            />
          </FormItem>
          {IS_ENABLED_INTERNATIONAL_MODE && (
            <FormItem {...formItemLayout} label="Country" required>
              <Field
                showSearch
                formItemLayout={formItemLayout2}
                name="country"
                component={ASelectField}
                style={{ width: "100%" }}
                placeholder="Select"
                hasFeedback
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {allCountriesJson.map((country, i) => {
                  return (
                    <Option key={i} value={country.alpha_3}>
                      {country.name}
                    </Option>
                  );
                })}
              </Field>
            </FormItem>
          )}
          <FormItem {...formItemLayout} label="ZIP Code" required>
            <Field
              formItemLayout={formItemLayout2}
              name="zipCode"
              component={AInputField}
              placeholder="ZIP Code"
              hasFeedback
            />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={`${process.env.REACT_APP_SHOP_NAMING} Logo`}
          >
            <SellerLogo isUpgradeAcc={true} />
          </FormItem>
          {/* </Field> */}

          <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="mb-0 mt-5"
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const SHOP_NAMING = process.env.REACT_APP_SHOP_NAMING;
  if (!values.storeName) {
    errors.storeName = SHOP_NAMING + " name is required";
  }
  if (!values.storecatagory) {
    errors.storecatagory = SHOP_NAMING + " catagory is required";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "Contact Number is required";
  }
  // if (!values.countryCode) {
  //   errors.countryCode = "Country code is required";
  // }
  if (!values.address1) {
    errors.address1 = "Address line 1 is required";
  }
  // if (!values.address2) {
  //   errors.address2 = "Address line 2 is required";
  // }
  if (!values.city) {
    errors.city = "City is required";
  }
  if (!values.state) {
    errors.state = "State is required";
  }
  if (!values.country) {
    errors.country = "Country is required";
  }
  if (!values.zipCode) {
    errors.zipCode = "ZIP Code is required";
  }

  if (!values.sellerImage) {
    errors.sellerImage = SHOP_NAMING + " logo is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    upgradeAccount: state.AuthStore.upgradeAccount,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "SellerForm",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(SellerForm))
);
