import React from "react";
import { Avatar, Popover } from "antd";
import "./index.less";
import { TRADESBARN } from "../../../constants";

const UserInfo = ({
  logoutHandler,
  currentUserObj,
  loginHandler,
  profileHandler,
  orderHandler,
  wishListHandler,
  chatHandler,
}) => {
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={profileHandler}>
        {process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
          ? "My Barn"
          : "My Profile"}
      </li>
      <li onClick={orderHandler}>My Order</li>
      <li onClick={chatHandler}>Message Center</li>
      <li onClick={wishListHandler}>Wishlist</li>
      {currentUserObj ? (
        <li onClick={logoutHandler}>Logout</li>
      ) : (
        <li onClick={loginHandler}>Login</li>
      )}
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        // src={currentUserObj.photoURL}
        src={
          currentUserObj && currentUserObj.photoURL
            ? currentUserObj.photoURL
            : null
          // require("assets/images/avatar/temp_avatar.png")
        }
        className="gx-avatar gx-pointer avatar-styl"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;
