import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import WishlistNotifyItem from "./WishlistNotifyItem";
import { GetWishlistedProducts } from "../../../../firebase/functions";
import "./WishlistNotify.less";

const WishlistNotify = () => {
  const [wishlistItem, setWishlistItem] = useState([]);

  useEffect(() => {
    GetWishlistedProducts((products) => {
      setWishlistItem(products);
    });
  }, []);

  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h5 className="gx-mb-0">Wishlist</h5>
        <Link to={true ? "/wishlist" : "/login"}>
          <div
            className=""
            style={{
              backgroundColor: "#038fde",
              padding: "5px 15px",
              color: "white",
              borderRadius: "5px",
            }}
          >
            My Wishlist
          </div>
        </Link>
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        {true ? (
          <ul className="gx-sub-popover">
            {wishlistItem.length === 0 ? (
              //loader....
              <div style={{ padding: "100px 25px 5px", textAlign: "center" }}>
                Look like you are not watching any item yet...
              </div>
            ) : (
              wishlistItem.map((item, index) => (
                <WishlistNotifyItem wishlist={item} key={index} />
              ))
            )}
          </ul>
        ) : (
          <div className="tr-wishlist-notify-container">
            {/* <div className="tr-address-loader"> </div> */}
            <div className="gx-algolia-content-inner">
              <div className="gx-algolia-no-results">
                Sign in to see your user information
              </div>
            </div>
            {/* <div className="tr-wishlist-notify-loading-spinner"> </div> */}
          </div>
        )}
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default WishlistNotify;
