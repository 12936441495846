import { createLogic } from "redux-logic";

import actions from "./actions";
import types from "./types";
// import endPoints from "../../../util/EndPoints";
// import API from "../../../util/HTTPClient";
import {
  GetDiscountedProducts,
  GetTopSellers,
  GetFeaturedProducts,
  GetLatestProducts,
  GetPopularProducts,
  GetAllCategories,
  GetPopularCategories,
  GetLandingPageBrands
} from "./../../../firebase/functions";

//get all Products
const getProducts = createLogic({
  type: types.GET_PRODUCTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    // let HTTPClient;
    // if (MockHTTPClient) {
    //   HTTPClient = MockHTTPClient;
    // } else {
    //   HTTPClient = API;
    // }
    GetFeaturedProducts()
      .then((data) => {
        dispatch(actions.getProductsSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getProductsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get latest Products Carousel
const getLatestProductCarousel = createLogic({
  type: types.GET_LATEST_PRODUCT_CAROUSEL,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetLatestProducts()
      .then((data) => {
        dispatch(actions.getLatestProductCarouselSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getLatestProductCarouselFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get popular Products Carousel
const getPopularProductCarousel = createLogic({
  type: types.GET_POPULAR_PRODUCT_CAROUSEL,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetPopularProducts()
      .then((data) => {
        dispatch(actions.getPopularProductCarouselSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getPopularProductCarouselFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get discount Products
const getDiscountedProducts = createLogic({
  type: types.GET_DISCOUNTED_PRODUCTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetDiscountedProducts()
      .then((data) => {
        dispatch(actions.getDiscountedProductsSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getDiscountedProductsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get top sellers
const getTopSellers = createLogic({
  type: types.GET_TOP_SELLERS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetTopSellers()
      .then((data) => {
        dispatch(actions.getTopSellersSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getTopSellersFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

//Get Popular Categories
const getPopularCategories = createLogic({
  type: types.GET_POPULAR_CATEGORIES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetPopularCategories()
      .then((data) => {
        dispatch(actions.getPopularCategoriesSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getPopularCategoriesFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});


//Get All Categories
const getAllCategories = createLogic({
  type: types.GET_ALL_CATEGORIES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    // debugger
    GetAllCategories()
      .then((data) => {
        dispatch(actions.getAllCategoriesSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getAllCategoriesFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

//get top brands
const getTopBrands = createLogic({
  type: types.GET_TOP_BRANDS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient }, dispatch, done) {
    GetLandingPageBrands()
      .then((data) => {
        dispatch(actions.getTopBrandsSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getTopBrandsFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [
  getProducts,
  getLatestProductCarousel,
  getPopularProductCarousel,
  getDiscountedProducts,
  getTopSellers,
  getAllCategories,
  getPopularCategories,
  getTopBrands
];
