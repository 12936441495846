import React, { Component } from "react";
import { Col, Row } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import "bootstrap/dist/css/bootstrap.css";
import { cartActions } from "./ducks";
import SimilarProductCarousel from "../../components/Buyer/Carousel/SimilarProductCarousel/SimilarProductCarousel";
import OrderSummaryCard from "../../components/Buyer/Cart/OrderSummaryCard/OrderSummaryCard";
import ShoppingCart from "../../components/Buyer/Cart/ShoppingCart/ShoppingCart";
import IntlMessages from "util/IntlMessages";

class Cart extends Component {
  componentDidMount() {
    var product_ids = [];
    // getter
    this.props.cartActions.clearSelectItem();
    var myCartString = localStorage.getItem("myCart");
    if (myCartString) {
      var myCartObj = JSON.parse(myCartString);
      // this.setState({ myCartObj });
      if (myCartObj.length !== 0) {
        this.props.cartActions.initializeReduxCart({ myCartObj });
        this.props.cartActions.checkOrderQuantity({
          defaultShippingAddress: null,
          cartObj: myCartObj,
        });
        myCartObj.map((product, i) => {
          product_ids.push(product.id);
          return null;
        });
        this.props.cartActions.getRelatedProducts({ product_ids: product_ids });
      }
    }
  }

  checkoutItemsHandler = () => {
    const { selectedItems } = this.props;
    const { selectedRows } = selectedItems;
    if (selectedRows.length === 0) {
      NotificationManager.error(
        <IntlMessages id="notification.validation.cart.checkoutItem.selectAtLeaseOne" />,
        <IntlMessages id="notification.validation.cart.checkoutItem.selectProduct" />
      );
    } else {
      this.props.cartActions.checkoutItems({
        item: selectedItems.selectedRows,
      });
      this.props.history.push("/checkout");
    }
  };
  render() {
    const { selectedItems, relatedProducts, myCart } = this.props;
    return (
      <div className="gx-container pt-4">
        <Row>
          <Col xl={18} lg={17} md={24} sm={24} xs={24}>
            <ShoppingCart />
          </Col>
          <Col xl={6} lg={7} md={24} sm={24} xs={24}>
            <OrderSummaryCard
              selectedItems={selectedItems}
              checkoutItems={this.checkoutItemsHandler}
            />
          </Col>
        </Row>

        {myCart && myCart.items && myCart.items.length !== 0 ? (
          <SimilarProductCarousel
            relatedProducts={relatedProducts}
            isCart={true}
            loading={relatedProducts.loading}
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    relatedProducts: state.Cart.relatedProducts,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
