import React from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip } from "recharts";
import { Col, Row } from "antd";
import Metrics from "../Metrics/Metrics";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";
// import { totalRevenueData } from "../../../../DummyData/SellerDashboard";

const TotalRevenueCard = ({ sellerStatistics }) => {
  return (
    <Metrics
      title="TOTAL REVENUE"
      icon="fad fa-sack-dollar mr-1"
      className="flex-fill"
    >
      <Row>
        <Col xl={11} lg={12} md={24} sm={12} xs={12}>
          <h5 className="gx-mb-1 ">{`${DEFAULT_CURRENCY_SHORT} ${
            !sellerStatistics.loading && sellerStatistics.data.total_revenue
              ? sellerStatistics.data.total_revenue
              : "0.00"
          }`}</h5>
          <p className="gx-mb-md-0 gx-text-light">YTD revenue</p>
        </Col>
        <Col xl={13} lg={12} md={24} sm={12} xs={12}>
          <ResponsiveContainer
            className="gx-barchart charts-bdr-btm"
            width="100%"
            height={70}
          >
            <BarChart
              data={!sellerStatistics.loading && sellerStatistics.data.sales}
              // data={totalRevenueData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <Tooltip />
              <Bar
                dataKey="last_year"
                stackId="a"
                fill="#038fde"
                barSize={675 <= 575 ? 4 : 8}
              />
              <Bar
                dataKey="this_year"
                stackId="a"
                fill="#52c41a"
                barSize={675 <= 575 ? 4 : 8}
              />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Metrics>
  );
};

export default TotalRevenueCard;
