import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import "./AlgoliaHeaderBox.less"

const AlgoliaHeader = ({customStyle}) =>{
  return (
    <div className="gx-algolia-header">
      <div className="srch-input-jr">
        <ConnectedSearchBox  customStyle={customStyle}
          />
      </div>
    </div>
  )
};

const CustomSearchBox = ({ currentRefinement, refine, customStyle }) => {
  return(
    <div className="gx-search-bar gx-lt-icon-search-bar">
      <div className="gx-form-group">
        <input
          type="search"
          placeholder="Search here"
          value={currentRefinement}
          onChange={(e) => refine(e.target.value)}
          autoComplete="off"
          className={`ant-input form-control`}
          style={customStyle}
          id="q"
        />
        <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" />
        </span>
      </div>
    </div>
  )
};
const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

export default AlgoliaHeader;
