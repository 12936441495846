// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getSellerStore: createAction(types.GET_SELLER_STORE),
  getSellerStoreSuccess: createAction(types.GET_SELLER_STORE_SUCCESS),
  getSellerStoreFail: createAction(types.GET_SELLER_STORE_FAILED),

  clearSellerStore: createAction(types.CLEAR_SELLER_STORE),
};
