import React, { Component } from "react";
import { Result, Button } from "antd";
import { withRouter } from "react-router-dom";

class PageNotFound extends Component {
  homeHandler = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button type="primary" onClick={this.homeHandler}>Back Home</Button>}
        />
      </div>
    );
  }
}

export default withRouter(PageNotFound);
