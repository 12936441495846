import React from "react";
import { Tooltip, Icon } from "antd";

function ExtraInfo({ title, placement }) {
  return (
    <Tooltip placement={placement} title={title}>
      <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
    </Tooltip>
  );
}

export default ExtraInfo;
