import React, { Component } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import UserInfo from "./UserInfo";
import Auxiliary from "util/Auxiliary";
import { authActions } from "modules/Auth/ducks";
import { cartActions } from "modules/Cart/ducks";

const { Header } = Layout;
class Topbar extends Component {
  handleSidebarToggle = () => {
    this.props.cartActions.sellerSidebarToggle();
  };
  render() {
    const { userJson } = this.props;
    return (
      <Header
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1000",
        }}
      >
        <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              this.handleSidebarToggle();
            }}
          />
        </div>
        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
          <img
            alt=""
            src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/w-logo.png`)}
            className="imgggggg"
          />
        </Link>

        <ul className="gx-header-notifications gx-ml-auto">
          {userJson && (
            <div>
              <b>
                <span>{userJson.name}</span>
              </b>
              <span className="ml-2 mr-4">
                <span
                  style={{
                    backgroundColor: "rgb(249 249 249)",
                    padding: "2px 10px",
                    borderRadius: "6px",
                    color: "rgb(157 157 157)",
                  }}
                >
                  {userJson.role}
                </span>
              </span>
            </div>
          )}

          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo userJson={userJson} />
            </li>
          </Auxiliary>
        </ul>
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.Home,
    allProducts: state.Home.allProducts,
    popularCarouselProducts: state.Home.popularCarouselProducts,
    latestCarouselProducts: state.Home.latestCarouselProducts,
    myCart: state.Cart.myCart,
    settingsUpdate: state.Cart.settingsUpdate,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
