import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { authActions } from "../ducks";
import { makeField } from "../../../components/Common/Controls/MakeField";
import { Button, Col, Form, Input, Row } from "antd";
import "./Register.scss";

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const AInputField = makeField(Input);
const ApasswordField = makeField(Input.Password);

class Register extends Component {
  handleBecomeASeller = () => {
    this.props.history.push("/account/seller/register");
  };

  handleSubmit = (values) => {
    const { first_name, last_name, email, password, confirmPassword } = values;
    var registerDto = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      role: "Buyer",
    };
    const { authActions } = this.props;
    authActions.signupUser({
      registerDto: registerDto,
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          className="gx-signin-form gx-form-row0 mrgn-catch"
        >
          <Row>
            <Col span={12} style={{ padding: "0px" }}>
              <Field
                formItemLayout={formItemLayout}
                name="first_name"
                component={AInputField}
                placeholder="First Name"
                autocomplete="on"
                hasFeedback
              />
            </Col>
            <Col span={12} style={{ padding: "0px" }}>
              <Field
                formItemLayout={formItemLayout}
                name="last_name"
                component={AInputField}
                placeholder="Last Name"
                autocomplete="on"
                hasFeedback
              />
            </Col>
          </Row>

          <Field
            formItemLayout={formItemLayout}
            name="email"
            component={AInputField}
            placeholder="Email"
            autocomplete="on"
            hasFeedback
          />

          <Row>
            <Col span={12} style={{ padding: "0px" }}>
              <Field
                formItemLayout={formItemLayout}
                name="password"
                component={ApasswordField}
                placeholder="Password"
                type="password"
                autocomplete="on"
                hasFeedback
              />
            </Col>
            <Col span={12} style={{ padding: "0px" }}>
              <Field
                formItemLayout={formItemLayout}
                name="confirmPassword"
                component={ApasswordField}
                placeholder="Confirm Password"
                type="password"
                autocomplete="on"
                hasFeedback
              />
            </Col>
          </Row>

          <FormItem>
            <Button
              style={{ width: "80%" }}
              type="primary"
              className="gx-mb-0 gx-mt-3"
              htmlType="submit"
            >
              {/* <IntlMessages id="app.userAuth.signup" /> */}
              Register
            </Button>
          </FormItem>
          <FormItem>
            <Link
              style={{ fontSize: "16px" }}
              type="primary"
              className="gx-mb-0"
              onClick={() => this.handleBecomeASeller()}
            >
              {/* <IntlMessages id="app.userAuth.signup" /> */}
              <i className="fas fa-user-plus"></i>&nbsp; Become a Seller
            </Link>
          </FormItem>

          <FormItem className="term-catch">
            <p className="mb-0">
              By creating an account, you agree to the Tradesbarn.com &nbsp;
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = "First name is required";
  }
  if (!values.last_name) {
    errors.last_name = "Last name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  }
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password is mismatch";
  }

  return errors;
};

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "Register_form",
    validate,
  })(connect(null, mapDispatchToProps)(Register))
);
