import React, { Component } from "react";
import CardBox from "../../../components/Buyer/Card/CardBox/CardBox";
import { Col, Row } from "antd";

class PromotionsThripple extends Component {
  render() {
    return (
      <CardBox>
        {process.env.REACT_APP_IS_ENABLED_PROMOTION_MODULE === "ENABLED" && (
          <Row>
            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <img
                className="d-block mx-auto"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/promotions/size_3/Amex_banners_1.png`)}
                alt="Moto 360"
              />
            </Col>

            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <img
                className="d-block mx-auto"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/promotions/size_3/power-tools_3.jpg`)}
                alt="Moto 360"
              />
            </Col>

            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
              <img
                className="d-block mx-auto"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/promotions/size_3/power-banks_1.jpg`)}
                alt="Moto 360"
              />
            </Col>
          </Row>
        )}
      </CardBox>
    );
  }
}

export default PromotionsThripple;
