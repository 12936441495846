import React, { Component } from "react";
import { Card, Col, Row } from "antd";
import ResolutionTypes from "../../../components/Buyer/ResolutionTypes/ResolutionTypes";
import ResolveProblem from "../../../components/Buyer/ResolveProblem/ResolveProblem";
import ResolutionHomeContainer from "../../../components/Buyer/ResolutionHomeContainer/ResolutionHomeContainer";
import { withRouter } from "react-router-dom";

class ResolutionCenterHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      algolia: null,
      resolutionAPIKey: null,
      resolutionIndex: null,
    };
  }
  componentDidMount() {
    const user = localStorage.getItem("user");

    if (!user) {
      this.props.history.push("/login");
    } else {
      const userObj = JSON.parse(user);
      const resolutionAPIKey =
        userObj.algolia.all_buyer_resolution_cases.api_key;
      const resolutionIndex = userObj.algolia.all_buyer_resolution_cases.index;
      const algolia = userObj.algolia;

      this.setState({
        algolia,
        user,
        resolutionAPIKey,
        resolutionIndex,
      });
    }
  }
  render() {
    const { user, algolia } = this.state;
    const { match } = this.props;

    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    if (algolia) {
      switch (type) {
        case "all":
          apiKey = algolia.all_buyer_resolution_cases.api_key;
          index = algolia.all_buyer_resolution_cases.index;

          break;
        case "attentionNeeded":
          apiKey = algolia.buyer_resolution_cases_needs_attention.api_key;
          index = algolia.buyer_resolution_cases_needs_attention.index;
          break;
        case "opened":
          apiKey = algolia.opened_buyer_resolution_cases.api_key;
          index = algolia.opened_buyer_resolution_cases.index;
          break;
        case "closed":
          apiKey = algolia.closed_buyer_resolution_cases.api_key;
          index = algolia.closed_buyer_resolution_cases.index;
          break;
        default:
          apiKey = algolia.all_buyer_resolution_cases.api_key;
          index = algolia.all_buyer_resolution_cases.index;
      }

      return (
        <div className="gx-container pt-4">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                style={{ backgroundColor: "rgb(250, 250, 250)" }}
              >
                <h5 className="mb-3 ml-2">Resolution Center</h5>
                <ResolveProblem />

                <div className="gx-card p-4 mt-4 mb-0">
                  <h6 className="mb-3">Your disputes</h6>
                  <ResolutionTypes type={type} />
                  {/* <RequestAndCaseTable /> */}
                  <ResolutionHomeContainer
                    user={user}
                    apiKey={apiKey}
                    index={index}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="gx-container">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <ResolveProblem />

              <Card
                className="gx-card"
                style={{ backgroundColor: "rgb(250, 250, 250)" }}
              >
                <ResolutionTypes type={type} />
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default withRouter(ResolutionCenterHome);
