import React from "react";
import { Link } from "react-router-dom";
import { Col } from "antd";

const OrderItem = ({ data }) => {
  const { product } = data;

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div style={{ height: "100%", paddingBottom: "15px" }}>
        <div
          style={{
            height: "100%",
            border: "1px solid rgb(235, 235, 235)",
            borderRadius: "5px",
            // alignItems: "center",
          }}
          className="gx-news-item p-2 shpnHovr msPntr content-jr-grd"
        >
          <div className="gx-news-thumb">
            <Link to="/seller/dashboard/orderManagement/all">
              <img
                className="gx-rounded-lg"
                style={{ width: "100px" }}
                src={product && product.img}
                alt="..."
              />
            </Link>
          </div>
          <div className="gx-news-content-jr">
            <Link to="/seller/dashboard/orderManagement/all">
              <div className="gx-mt-0 crd-fnt-jr">
                {product && product.name}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default OrderItem;
