import React from "react";
import { Tabs } from "antd";
import EventItem from "./EventItem";
import Widget from "../../../Common/Widget/Widget";

const TabPane = Tabs.TabPane;

const eventList = [
  {
    id: 1,
    image: "https://via.placeholder.com/575x480",
    title: "Sundance Film Festival.",
    address: "Downsview Park, Toronto, Ontario",
    date: "Feb 23, 2019",
  },
  {
    id: 2,
    image: "https://via.placeholder.com/575x480",
    title: "Underwater Musical Festival.",
    address: "Street Sacramento, Toronto, Ontario",
    date: "Feb 24, 2019",
  },
  {
    id: 3,
    image: "https://via.placeholder.com/575x480",
    title: "Village Feast Fac",
    address: "Union Street Eureka",
    date: "Oct 25, 2019",
  },
];

const Events = () => {
  return (
    <div>
      <Widget
        title="Orders"
        styleName="gx-card-tabs gx-card-tabs-right gx-card-profile"
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Delivered" key="1">
            <div className="gx-pt-md-3">
              {eventList.map((data, index) => (
                <EventItem key={index} data={data} />
              ))}
            </div>
          </TabPane>
          <TabPane tab="In Progress" key="2">
            <div className="gx-pt-md-3">
              {eventList.length > 0 ? (
                eventList.map((data, index) => (
                  <EventItem key={index} data={data} />
                ))
              ) : (
                <h2>add photo</h2>
              )}
            </div>
          </TabPane>
          <TabPane tab="Canceled" key="3">
            <div className="gx-pt-md-3">
              {eventList.map((data, index) => (
                <EventItem key={index} data={data} />
              ))}
            </div>
          </TabPane>
        </Tabs>
      </Widget>
    </div>
  );
};

export default Events;
