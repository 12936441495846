import React, { Component } from "react";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";

class DashboardProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  componentDidMount() {
    this.activeLinkHandler();
  }

  activeLinkHandler = () => {
    const { path } = this.props.match;
    if (path.includes("/seller/dashboard/profile/general")) {
      this.setState({
        current: "general",
      });
    } else if (path.includes("/seller/dashboard/profile/sellerlogo")) {
      this.setState({
        current: "sellerlogo",
      });
    }
  };

  render() {
    const { current } = this.state;
    return (
      <div className="site-header navbar-sticky navbar-bckg scrollable-container my-acc-eff mt-1">
        <div style={{ textAlign: "center" }}>
          <Menu selectedKeys={[current]} mode="horizontal">
            <Menu.Item key="general">
              <Link
                style={{ marginLeft: "30px", marginRight: "30px" }}
                to="/seller/dashboard/profile/general/selleraccount"
              >
                <i className="fad fa-list-alt icon  mr-2"></i>
                General Information
              </Link>
            </Menu.Item>
            <Menu.Item key="sellerlogo">
              <Link
                style={{ marginLeft: "30px", marginRight: "30px" }}
                to="/seller/dashboard/profile/sellerlogo"
              >
                <i className="fad fa-image-polaroid icon mr-2"></i>
                Seller Logo
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardProfileHeader);
