import React from "react";

function CSHeader({ title }) {
  return (
    <div className="px-2 mb-1">
      <h5 className="mb-0">{title}</h5>
    </div>
  );
}

export default CSHeader;
