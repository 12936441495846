import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { message, Icon, Modal, Upload } from "antd";
import { uploadWithSignURL } from "./../../../../services/storage.service";
import endPoints from "./../../../../util/EndPoints";
import API from "./../../../../util/HTTPClient";
import { productManagementActions } from "../../../../modules/SellerDashboard/ProductManagement/ducks";
import { resolutionCenterActions } from "../../../../modules/ResolutionCenter/ducks";
import { customerServiceActions } from "../../../../modules/CustomerService/ducks";
class UploadPictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      isInitializeImages: true,
      fileList: [],
      image_keys: [],
      variationImageList: [],
      isUpdated: false,
      productImages: {
        image_keys: [],
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { getProduct, productImages, feedbackImages } = this.props;
    if (
      getProduct &&
      getProduct.data &&
      getProduct.data.data &&
      JSON.stringify(getProduct) !== JSON.stringify(prevState.getProduct)
    ) {
      var imageList = [];
      productImages.image_keys.forEach((image, i) =>
        imageList.push({
          uid: i,
          name: `image_${i}`,
          status: "done",
          url: image,
        })
      );
      this.setState({
        fileList: imageList,
        getProduct: getProduct,
      });
    }
    if (
      productImages &&
      productImages.image_keys &&
      productImages.image_keys.length !== 0 &&
      JSON.stringify(productImages.image_keys) !==
        JSON.stringify(prevState.productImages.image_keys)
    ) {
      let imageList = [];
      productImages.image_keys.map((image, i) =>
        imageList.push({
          uid: i,
          name: `image_${i}`,
          status: "done",
          url: image,
        })
      );
      this.setState({
        fileList: imageList,
        productImages: productImages,
      });
    } else if (
      JSON.stringify(feedbackImages.image_keys) !==
      JSON.stringify(prevProps.feedbackImages.image_keys)
    ) {
      let imageList = [];
      feedbackImages.image_keys.map((image, i) =>
        // need to help ************************
        imageList.push({
          uid: i,
          name: `image_${i}`,
          status: "done",
          url: image,
        })
      );
      this.setState({
        fileList: imageList,
        productImages: feedbackImages,
      });
    }
  }

  beforeUpload = (file, show_err_msgs = true) => {
    const isImage = [
      "image/jpeg",
      "image/jpg",
      "image/bmp",
      "image/png",
    ].includes(file.type);
    if (!isImage && show_err_msgs) {
      message.error("You can only upload Image file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M && show_err_msgs) {
      message.error("Image must smaller than 5MB!");
    }
    return isImage && isLt5M;
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  handleUpload = ({ onSuccess, onError, file }) => {
    const {
      isResolutionCenter,
      isUpdateCase,
      isVariationPhotos,
      selectedVariation,
      isFeedback,
    } = this.props;
    var uploadAPI = null;
    if (isResolutionCenter || isUpdateCase) {
      uploadAPI = endPoints.RESOLUTION_UPLOAD_IMAGE;
    } else if (isFeedback) {
      uploadAPI = endPoints.GET_FEEDBACK_IMAGE_SIGNURL;
    } else {
      uploadAPI = endPoints.GET_PRODUCT_IMAGE_SIGNURL;
    }
    API.Get(uploadAPI)
      .then((res) => res.data)
      .then(async (res) => {
        await uploadWithSignURL(
          res.data.signed_url,
          new Blob([file]),
          file.type
        );
        return res.data;
      })
      .then((data) => {
        const image_keys = [...this.state.image_keys, data.image_url];
        let imageURL = [data.image_url];
        if (isResolutionCenter) {
          this.props.resolutionCenterActions.handleReportItemImage(image_keys);
        } else if (isUpdateCase) {
          this.props.resolutionCenterActions.handleUpdateCaseImage(image_keys);
        } else if (isVariationPhotos) {
          this.props.productManagementActions.handleVariationsImages({
            [selectedVariation]: image_keys[0],
          });
        } else if (isFeedback) {
          this.props.customerServiceActions.handleFeedbackImages(imageURL);
        } else {
          this.props.productManagementActions.handleProductImages(imageURL);
        }
        this.setState({
          image_keys: image_keys,
        });
        onSuccess(null, file);
      })
      .catch((err) => {
        console.error(err);
        onError(err);
      });
  };

  handleChange = ({ fileList }) => {
    this.setState({
      fileList: fileList.filter((file) => this.beforeUpload(file, false)),
    });
  };

  variationPhotosRemoveHandler = (file) => {
    const { selectedVariation } = this.props;
    this.props.productManagementActions.removeVariationsImages({
      selectedVariation: selectedVariation,
    });
  };

  handleRemove = (file) => {
    // debugger;
    const {
      isResolutionCenter,
      isUpdateCase,
      isVariationPhotos,
      selectedVariation,
      isFeedback,
    } = this.props;
    const removed_img_idx = this.state.fileList.findIndex(
      (img) => file.uid === img.uid
    );

    if (isResolutionCenter) {
      alert("should be implemented");
    } else if (isUpdateCase) {
      alert("should be implemented");
    } else if (isVariationPhotos) {
      this.props.productManagementActions.removeVariationsImages({
        selectedVariation: selectedVariation,
      });
    } else if (isFeedback) {
      this.props.customerServiceActions.removeFeedbackImages({
        idx: removed_img_idx,
      });
    } else {
      this.props.productManagementActions.removeProductImages({
        idx: removed_img_idx,
      });
    }

    const image_keys = this.state.fileList;
    image_keys.splice(removed_img_idx, 1);
    this.setState({
      image_keys: image_keys,
    });
  };

  render() {
    const {
      previewVisible,
      previewImage,
      fileList,
      // variationImage,
    } = this.state;
    const { noOfPhotos, isVariationPhotos, variationImage, mode, isCrop } =
      this.props;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    if (isVariationPhotos && mode === "UPDATE") {
      return (
        <React.Fragment>
          <Upload
            listType="picture-card"
            fileList={variationImage.status === "done" ? [variationImage] : []}
            beforeUpload={this.beforeUpload}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            customRequest={this.handleUpload}
            onRemove={this.variationPhotosRemoveHandler}
          >
            {variationImage.status === "done"
              ? [variationImage].length >= noOfPhotos
                ? null
                : uploadButton
              : [].length >= noOfPhotos
              ? null
              : uploadButton}
            {/* {[].length >= noOfPhotos ? null : uploadButton} */}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </React.Fragment>
      );
    } else if (isCrop) {
      return (
        <React.Fragment>
          <ImgCrop grid>
            <Upload
              listType="picture-card"
              fileList={fileList}
              beforeUpload={this.beforeUpload}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              customRequest={this.handleUpload}
              onRemove={this.handleRemove}
            >
              {fileList.length >= noOfPhotos ? null : uploadButton}
            </Upload>
          </ImgCrop>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Upload
            listType="picture-card"
            fileList={fileList}
            beforeUpload={this.beforeUpload}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
            customRequest={this.handleUpload}
            onRemove={this.handleRemove}
          >
            {fileList.length >= noOfPhotos ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    productCategories: state.ProductManagement.productCategories,
    getProduct: state.ProductManagement.getProduct,
    productImages: state.ProductManagement.productImages,
    variationsImages: state.ProductManagement.variationsImages,
    feedbackImages: state.FeedBack.feedbackImages,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
    customerServiceActions: bindActionCreators(
      customerServiceActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadPictures)
);
