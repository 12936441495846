import React from "react";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../../../util/services";

const CartNotifyItem = (props) => {
  var userSelectionsVariationsArray =
    props.cart &&
    props.cart.userSelections.variations &&
    Object.keys(props.cart.userSelections.variations).map((key) => [
      key,
      props.cart.userSelections.variations[key],
    ]);
  return (
    <Link to={`/product/${props.cart.id}`}>
      <li className="gx-media wishlist-itm-crd">
        <div className="gx-user-thumb gx-mr-3">
          <img
            alt="Cart_Notify"
            className="gx-size-40 mt-2"
            style={{ borderRadius: "5px" }}
            src={props.cart.images[0]}
          />
        </div>
        <div className="gx-media-body">
          <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
            <div
              style={{ fontSize: "12px" }}
              className="gx-text-capitalize gx-text-muted gx-mb-0"
            >
              {props.cart.name}
            </div>
          </div>
          <div
            className="gx-text-muted"
            style={{ fontSize: "12px", color: "rgb(18, 156, 48)" }}
          >
            {props.cart &&
              props.cart.userSelections &&
              props.cart.userSelections.quantity && (
                <span style={{ color: "rgb(95, 95, 95)" }}>
                  Quantity :{" "}
                  <span style={{ color: "rgb(18, 156, 48)" }}>
                    {props.cart.userSelections.quantity}
                  </span>
                </span>
              )}
            <br />
            {userSelectionsVariationsArray &&
              userSelectionsVariationsArray.map((variation, i) => {
                return (
                  <span style={{ color: "rgb(95, 95, 95)" }}>
                    {toTitleCase(variation[0])}
                    <span style={{ color: "rgb(18, 156, 48)" }}>
                      &nbsp; {variation[1]}
                    </span>
                    &nbsp;|&nbsp;
                  </span>
                );
              })}
          </div>
          <div className="">
            <span className="">
              $ {props.cart.price}{" "}
              {props.cart &&
                props.cart.selectedShippingMethod &&
                props.cart.selectedShippingMethod.rate && (
                  <span className="gx-meta-date">
                    <small>
                      + $ {props.cart.selectedShippingMethod.rate} Shipping Cost
                    </small>{" "}
                  </span>
                )}
            </span>
          </div>
        </div>
      </li>
    </Link>
  );
};

export default CartNotifyItem;
