import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SellerRecentOrdersContainer from "./SellerRecentOrdersContainer/SellerRecentOrdersContainer";

class DashboardResolutionCaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userObj: null,
      algolia: null,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;

    if (!user) {
      this.props.history.push("/login");
    } else {
      this.setState({
        userObj: userObj,
        algolia: algolia,
        apiKey: algolia.seller_recent_orders_for_resolution.api_key,
        index: algolia.seller_recent_orders_for_resolution.index,
      });
    }
  }

  render() {
    const { apiKey, index } = this.state;
    return (
      <div className="mb-5">
        <h3 className="mb-4">Resolution Center</h3>

        <div className="gx-card p-3 pl-4 mb-3">
          <Link
            className="crd-fnt-jr"
            to="/seller/dashboard/resolution-center/all"
          >
            Resolution Center
          </Link>
          {" > "}Recent Orders
        </div>
        <SellerRecentOrdersContainer apiKey={apiKey} index={index} />
      </div>
    );
  }
}

export default withRouter(DashboardResolutionCaseList);
