import React from "react";
import { Button, Spin } from "antd";
import "./PlaceOrder.less";
import { DEFAULT_CURRENCY } from "../../../../constants";

export default function PlaceOrder({
  checkoutItemsSummery,
  makePaymentHandler,
}) {
  const { total_product_cost, shipping_cost, total_cost } =
    checkoutItemsSummery.data;

  return (
    <div className="perant-sumry">
      <div
        // style={{ backgroundColor: "rgb(250, 250, 250)" }}
        className="gx-card p-4 sumry-crd-pstn-new"
      >
        <Spin spinning={checkoutItemsSummery.pending}>
          <h5>Order Summary</h5>

          <div className="gx-media-body mt-3">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Selected Items
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {/* {selectedRows.length} */}
            </p>
          </div>

          <div className="gx-media-body">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Subtotal
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {`${DEFAULT_CURRENCY} ${total_product_cost || 0}`}
            </p>
          </div>

          <div className="gx-media-body">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Shipping
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {`${DEFAULT_CURRENCY} ${shipping_cost || 0}`}
            </p>
          </div>
          <hr className="mt-2 mb-2"></hr>
          <div className="gx-media-body">
            <span
              style={{ display: "inline-block", fontSize: "14px" }}
              className="gx-mb-0 gx-text-grey gx-fs-sm"
            >
              Total
            </span>
            <p
              style={{ display: "inline-block", float: "right" }}
              className="gx-mb-0"
            >
              {`${DEFAULT_CURRENCY} ${total_cost || 0}`}
            </p>
          </div>
          <Button onClick={makePaymentHandler} className="buybtn">
            Checkout
          </Button>
        </Spin>
      </div>
    </div>
  );

  // return (
  //   <div className="perant-sumry">
  //     <div className="gx-card p-4 sumry-crd-pstn">
  //       <h5 className="mb-4">Order Summary</h5>
  //       <div className="mb-4">
  //         <div className="gx-mb-0 gx-text-grey mb-1">Total</div>
  //         <p
  //           style={{
  //             color: "#ff6900",
  //             fontSize: "20px",
  //           }}
  //           className="gx-mb-0"
  //         >
  //           US ${total}
  //         </p>
  //       </div>
  //       <Button
  //         block
  //         onClick={makePaymentHandler}
  //         className="gx-btn-secondary mb-0"
  //       >
  //         Place Order
  //       </Button>
  //     </div>
  //   </div>
  // );
}
