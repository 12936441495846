import React, { Component } from "react";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import "./AccountMainNavBar.less";
import { TRADESBARN } from "../../../constants";

class AccountMainNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // current: "mybarn",
    };
    // this.activeLinkHandler();
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  componentDidMount() {
    this.activeLinkHandler();
  }

  activeLinkHandler = () => {
    const { path } = this.props.match;
    switch (path.split("/")[1]) {
      case "mybarn":
        this.setState({
          current: "mybarn",
        });
        break;
      case "orders":
        this.setState({
          current: "order",
        });
        break;
      case "chat":
        this.setState({
          current: "chat",
        });
        break;
      case "wishlist":
        this.setState({
          current: "wishlist",
        });
        break;
      case "account":
        this.setState({
          current: "account",
        });
        break;
      case "payment-settings":
        this.setState({
          current: "payment-settings",
        });
        break;
      case "shipping-address":
        this.setState({
          current: "shipping-address",
        });
        break;
      default:
        this.setState({
          default: true,
        });
    }
  };

  render() {
    return (
      <div className="site-header navbar-sticky navbar-bckg scrollable-container my-acc-eff">
        <div style={{ textAlign: "center" }}>
          <Menu
            // onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            mode="horizontal"
          >
            <Menu.Item key="mybarn">
              <Link to="/my-profile">
                <i className="fad fa-user mr-2"></i>
                {process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
                  ? "My Barn"
                  : "My Profile"}
              </Link>
            </Menu.Item>
            <Menu.Item key="order">
              <Link to="/orders/all">
                <i className="fad fa-hand-holding-box mr-2"></i>
                My Orders
              </Link>
            </Menu.Item>
            <Menu.Item key="chat">
              <Link to="/chat">
                <i className="fad fa-comments-alt mr-2"></i>
                Message Center
              </Link>
            </Menu.Item>
            <Menu.Item key="wishlist">
              <Link to="/wishlist">
                <i className="fad fa-heart-circle mr-2"></i>
                Wishlist
              </Link>
            </Menu.Item>
            <Menu.Item key="account">
              <Link to="/account">
                <i className="fad fa-cog mr-2"></i>
                Account Settings
              </Link>
            </Menu.Item>
            <Menu.Item key="payment-settings">
              <Link to="/payment-settings">
                <i className="fad fa-credit-card mr-2"></i>
                Payment Settings
              </Link>
            </Menu.Item>
            <Menu.Item key="shipping-address">
              <Link to="/shipping-address">
                <i className="fad fa-map-marker-alt mr-2"></i>
                Shipping Address
              </Link>
            </Menu.Item>
          </Menu>
        </div>
        {/* <nav className="site-menu background"> */}

        {/* </nav> */}
      </div>
    );
  }
}

export default withRouter(AccountMainNavBar);
