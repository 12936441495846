import React, { Component } from "react";
import { Col, Row } from "antd";
// import IntlMessages from "util/IntlMessages";
import Widget from "../../components/Common/Widget/Widget";

class SellerHelpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="gx-main-content-wrapper gx-container">
        <Row>
          <Col className="d-flex" xl={18} lg={18} md={24} sm={24} xs={24}>
            <Widget
            // title={<IntlMessages id="sidebar.documents.installation" />}
            >
              <div>
                <h1>Setup Seller Envrionment</h1>
                <div className="adocs-section-content ng-binding">
                  <p>
                    Whether you're starting a business or just want to make some
                    extra cash, selling on Tradesbarn is a fun way to do it. To
                    create items for listing in Tradesbarn, just click Sell at
                    the top of the home page. But there's a couple of things you
                    might like to do first to help your chances of a sale. You
                    need to set up a seller account to receive payments for your
                    sales.
                  </p>
                  <h5>Listing Conditions</h5>
                  <p>
                    When listing an item, you agree to comply with the rules for
                    listing and selling practices policy and that.
                  </p>

                  <ul>
                    <li>
                      <p>
                        You are responsible for the accuracy, content and
                        legality of the item listed and agree to list in
                        accordance with the prohibited and restricted Items
                        policy.
                      </p>
                    </li>
                    <li>
                      <p>
                        Your listing may not be immediately searchable by
                        keyword or category for up to 24 hours. Trades barn does
                        not guarantee exact listing durations.
                      </p>
                    </li>
                    <li>
                      <p>
                        Content that violates any of trades barn’s policies may
                        be deleted at trades barn’s discretion.
                      </p>
                    </li>
                    <li>
                      <p>
                        Where your listing appears in search and browse results
                        may be based on certain factors such as listing format,
                        title, bidding activity, end time, keywords, price and
                        postage cost, Feedback, and detailed seller ratings.
                        Learn more about finding an item you listed for sale.
                      </p>
                    </li>
                    <li>
                      <p>
                        Trades barn’s duplicate listing policy may also affect
                        whether your listing appears in search results.
                      </p>
                    </li>
                    <li>
                      <p>
                        Some optional upgrade features as 3D Item Viewing will
                        only be visible on certain trades barn Services.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </Widget>
          </Col>
          <Col className="d-flex" xl={6} lg={6} md={12} sm={24} xs={24}>
            <div className="gx-card p-3 slr-bcgd">
              <img
                className=""
                style={{ width: "100%", borderRadius: "5px" }}
                alt="example"
                src={require("assets/img/sidw123.jpg")}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SellerHelpPage;
