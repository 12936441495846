import React, { Component } from "react";
import { Col, Row } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dashboardActions } from "./ducks";
import SiteVisitors from "../../components/Seller/Dashboard/SiteVisitors/SiteVisitors";
import Auxiliary from "../../util/Auxiliary";
import TotalRevenueCard from "../../components/Seller/Card/TotalRevenueCard/TotalRevenueCard";
import NewCustomers from "../../components/Seller/Card/NewCustomers/NewCustomers";
import GrowthCard from "../../components/Seller/Card/GrowthCard/GrowthCard";
import { taskList } from "../../DummyData/SellerDashboard";
import RecentActivity from "../../components/Seller/Dashboard/RecentActivity/RecentActivity";
// import DashboardSummeryCard from "../../components/Seller/Card/DashboardSummeryCard/DashboardSummeryCard";
import OrderSummery from "../../components/Seller/Dashboard/OrderSummery/OrderSummery";
// import RecentShippingDetails from "../../components/Seller/Dashboard/RecentShippingDetails/RecentShippingDetails";
import OrdersChart from "../../components/Seller/Dashboard/OrdersChart/OrdersChart";
// import Overview from "../../components/Seller/Dashboard/Overview/Overview";
import PopularProducts from "../../components/Seller/Dashboard/PopularProducts/PopularProducts";
import { userRoles } from "../../constants/Utilities";
import SummerySection from "./SummerySection";
import { orderManagementActions } from "../SellerDashboard/OrderManagement/ducks";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdminRole: false,
      isModerator: false,
    };
  }
  componentDidMount() {
    const {
      dashboardActions,
      sellerStatistics,
      sellerRevenue,
      sellerPopularProducts,
      sellerOrders,
      sellerActivities,
      ordersCount,
      orderManagementActions,
    } = this.props;

    ordersCount.loading && orderManagementActions.getOrdersCount();

    // getter
    var user = localStorage.getItem("user");
    if (user) {
      var userJson = JSON.parse(user);
      var role = null;
      role = userJson && userJson.role;
      var isAdminRole =
        role === userRoles.Moderator || role === userRoles.Admin ? true : false;
      var isModerator = role === userRoles.Moderator;

      sellerStatistics.loading && dashboardActions.getSellerStatistics();
      !isModerator &&
        sellerRevenue.loading &&
        dashboardActions.getSellerRevenue();
      !isAdminRole &&
        sellerPopularProducts.loading &&
        dashboardActions.getSellerPopularProducts();

      !isAdminRole &&
        sellerOrders.loading &&
        dashboardActions.getSellerOrders();
      sellerActivities.loading && dashboardActions.getSellerActivities();

      this.setState({
        isAdminRole,
        isModerator,
      });
    }
  }
  render() {
    const {
      sellerStatistics,
      // sellerRevenue,
      sellerPopularProducts,
      sellerOrders,
      sellerActivities,
      ordersCount,
    } = this.props;
    const { isAdminRole, isModerator } = this.state;

    var newOrders = sellerOrders.loading ? [] : sellerOrders.data.new_orders;

    var shippedOrders = sellerOrders.loading
      ? []
      : sellerOrders.data.shipped_orders;
    var deliveredOrders = sellerOrders.loading
      ? []
      : sellerOrders.data.delivered_orders;
    var cencelOrders = sellerOrders.loading
      ? []
      : sellerOrders.data.cancelled_orders;

    var newOrdersNum = ordersCount.loading
      ? []
      : ordersCount.data &&
        ordersCount.data.data &&
        ordersCount.data.data.orders_to_ship_count;

    var shippedOrdersNum = sellerOrders.loading
      ? []
      : ordersCount.data &&
        ordersCount.data.data &&
        ordersCount.data.data.shipped_orders_count;
    var deliveredOrdersNum = sellerOrders.loading
      ? []
      : ordersCount.data &&
        ordersCount.data.data &&
        ordersCount.data.data.delivered_orders_count;
    var cencelOrdersNum = sellerOrders.loading
      ? []
      : ordersCount.data &&
        ordersCount.data.data &&
        ordersCount.data.data.cancelled_orders_count;

    return (
      <Auxiliary>
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                {!isAdminRole && (
                  <SummerySection
                    newOrders={newOrdersNum}
                    shippedOrders={shippedOrdersNum}
                    deliveredOrders={deliveredOrdersNum}
                    cencelOrders={cencelOrdersNum}
                  />
                )}
                <SiteVisitors sellerStatistics={sellerStatistics} />
              </Col>
            </Row>

            <Row>
              {!isModerator && (
                <Col
                  xl={8}
                  lg={24}
                  md={8}
                  sm={24}
                  xs={24}
                  className="d-flex flex-column"
                >
                  <TotalRevenueCard sellerStatistics={sellerStatistics} />
                </Col>
              )}
              <Col
                xl={8}
                lg={12}
                md={8}
                sm={24}
                xs={24}
                className="d-flex flex-column"
              >
                <NewCustomers sellerStatistics={sellerStatistics} />
              </Col>
              <Col
                xl={8}
                lg={12}
                md={8}
                sm={24}
                xs={24}
                className="d-flex flex-column"
              >
                <GrowthCard sellerStatistics={sellerStatistics} />
              </Col>
            </Row>

            <Row>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="gx-order-sm-1"
              >
                <Row>
                  {!isAdminRole && (
                    <React.Fragment>
                      <Col
                        xl={16}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        className="d-flex flex-column"
                      >
                        <OrderSummery
                          task_list={taskList}
                          newOrders={newOrders}
                          shippedOrders={shippedOrders}
                          deliveredOrders={deliveredOrders}
                          cencelOrders={cencelOrders}
                        />
                        <PopularProducts
                          sellerPopularProducts={sellerPopularProducts}
                        />
                      </Col>
                      <Col
                        xl={8}
                        lg={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="d-flex flex-column"
                      >
                        <OrdersChart
                          newOrders={newOrdersNum}
                          shippedOrders={shippedOrdersNum}
                          deliveredOrders={deliveredOrdersNum}
                          cencelOrders={cencelOrdersNum}
                        />

                        <RecentActivity
                          sellerActivities={sellerActivities}
                          shape="circle"
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    sellerStatistics: state.SellerDashboard.sellerStatistics,
    sellerRevenue: state.SellerDashboard.sellerRevenue,
    sellerPopularProducts: state.SellerDashboard.sellerPopularProducts,
    sellerOrders: state.SellerDashboard.sellerOrders,
    sellerActivities: state.SellerDashboard.sellerActivities,
    ordersCount: state.OrderManagement.ordersCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
    orderManagementActions: bindActionCreators(
      orderManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
