import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  product: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.GET_PRODUCT]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_PRODUCT_FAILED]: (state, { payload }) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    [types.SELECT_PRODUCT_DETAIL]: (state, { payload }) => {
      return {
        ...state,
        SingleProduct: {
          ...state.SingleProduct,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },
    //-------------------------------------------------------
  },
  initialState
);
