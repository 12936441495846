import DumyImg from "assets/images/products/dummyProducts.gif";

export const hotDeals = [
  {
    id: "1",
    images: [DumyImg],
    name: "Loading...",
    seller_id: "1",
    brand: "Loading...",
    discount_percentage: 0,
    seller: { name: "Loading..." },
  },
  {
    id: "2",
    images: [DumyImg],
    name: "Loading...",
    seller_id: "1",
    brand: "Loading...",
    discount_percentage: 0,
    seller: { name: "Loading..." },
  },
  {
    id: "3",
    images: [DumyImg],
    name: "Loading...",
    seller_id: "1",
    brand: "Loading...",
    discount_percentage: 0,
    seller: { name: "Loading..." },
  },
  {
    id: "4",
    images: [DumyImg],
    name: "Loading...",
    seller_id: "1",
    brand: "Loading...",
    discount_percentage: 0,
    seller: { name: "Loading..." },
  },
];
