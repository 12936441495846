const chat_header = "chat/";

// Types
export default {
  // GET_COVID19_DETAILS: chat_header+"GET_COVID19_DETAILS",
  // GET_COVID19_DETAILS_SUCCESS: chat_header+"GET_COVID19_DETAILS_SUCCESS",
  // GET_COVID19_DETAILS_FAILED: chat_header+"GET_COVID19_DETAILS_FAILED",

  SELECTED_SELLER_HANDLER: chat_header + "SELECTED_SELLER_HANDLER",
};
