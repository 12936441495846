import React from "react";
import Widget from "../../../Common/Widget/Widget";
import PopularProductItem from "./PopularProductItem";
import { Col, Row } from "antd";

const PopularProducts = ({ sellerPopularProducts }) => {
  const { loading, data } = sellerPopularProducts;

  return (
    <Widget styleName="flex-fill">
      <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-dash-search">
        <h6 className="gx-mb-3 gx-mb-sm-1 gx-mr-6">Popular Products</h6>
      </div>
      {loading ? (
        <React.Fragment>
          <div
            className="tr-wishlist-notify-container"
            style={{ margin: "200px 0" }}
          >
            <div className="tr-wishlist-notify-loading-spinner"> </div>
          </div>
        </React.Fragment>
      ) : (
        <Row>
          {data.payload.length > 0 ? (
            data.payload.map((data, index) => (
              <PopularProductItem key={index} data={data} />
            ))
          ) : (
            <Col span={24}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "30px",
                }}
              >
                No orders yet
              </div>
            </Col>
          )}
        </Row>
      )}
    </Widget>
  );
};

export default PopularProducts;
