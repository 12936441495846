import React from "react";
import { connectSearchBox, SortBy } from "react-instantsearch-dom";
import "./ProductsHeader.less";
import {
  ALGOLIA_FEATURED_PRODUCTS_INDEX,
  ALGOLIA_POPULAR_PRODUCTS_INDEX,
  ALGOLIA_LATEST_PRODUCTS_INDEX,
  ALGOLIA_PRICE_ASC_PRODUCTS_INDEX,
  ALGOLIA_PRICE_DESC_PRODUCTS_INDEX,
} from "./../../../../constants";

const AlgoliaHeader = () => (
  <div className="gx-algolia-header">
    <div className="srch-input-jr">
      <ConnectedSearchBox />
    </div>
    <div className="gx-algolia-sort-by">
      <label>Sort by</label>
      <SortBy
        items={[
          { value: ALGOLIA_FEATURED_PRODUCTS_INDEX, label: "Featured" },
          { value: ALGOLIA_POPULAR_PRODUCTS_INDEX, label: "Most Popular" },
          { value: ALGOLIA_LATEST_PRODUCTS_INDEX, label: "Latest Added" },
          { value: ALGOLIA_PRICE_ASC_PRODUCTS_INDEX, label: "Price asc." },
          { value: ALGOLIA_PRICE_DESC_PRODUCTS_INDEX, label: "Price desc." },
        ]}
        defaultRefinement={ALGOLIA_FEATURED_PRODUCTS_INDEX}
      />
    </div>
  </div>
);
const CustomSearchBox = ({ currentRefinement, refine }) => (
  <div className="gx-search-bar gx-lt-icon-search-bar">
    <div className="gx-form-group">
      <input
        type="search"
        placeholder="Search here"
        value={currentRefinement}
        onChange={(e) => refine(e.target.value)}
        autoComplete="off"
        className="ant-input form-control"
        id="q"
      />
      <span className="gx-search-icon gx-pointer">
        <i className="icon icon-search" />
      </span>
    </div>
  </div>
);
const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

export default AlgoliaHeader;
