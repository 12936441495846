import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  product: {
    ...initialStateModal,
  },
  openNewCase: {
    ...initialStateModal,
  },
  getCase: {
    ...initialStateModal,
  },
  updateCase: {
    ...initialStateModal,
  },
  resolutionCenterCases: {
    ...initialStateModal,
  },
  reportProductImages: {
    image_keys: [],
  },
  updateCaseImages: {
    image_keys: [],
  },
  reportItem: {
    data: {
      id: "235611436330028285723026",
      status: "In Progress",
      modified_at: "2020-07-22T12:02:40.204Z",
      seller: {
        id: "5fed78e7-9cdf-11ea-b30b-95e78254a84d",
        image:
          "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/default-seller.png?alt=media",
        name: "CoolStuff",
      },
      product: {
        name: "Moringa Oleifera Leaf Capsules",
        img:
          "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content-dev/o/products%2F400000081553575875%2F0.jpg?alt=media",
        wholesale: {},
        variations: {},
        id: "a1ed1871-a268-11ea-a691-cfca0ccbdc51",
      },
      shipping: {
        status: null,
      },
      transaction: {
        shipping_cost: "75.50",
        grand_total: "114.50",
        status: "Finished",
        tax: "0.00",
      },
    },
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    // *************************CLEAR_CASE********************************//
    [types.CLEAR_CASE]: (state, { payload }) => ({
      ...state,
      getCase: {
        ...initialStateModal,
      },
    }),
    // *************************HANDLE_PRODUCT_IMAGES********************************//
    [types.HANDLE_REPORT_ITEM_IMAGE]: (state, { payload }) => ({
      ...state,
      reportProductImages: {
        image_keys: payload,
      },
    }),
    // *********************************************************//
    // *************************HANDLE_UPDATE_CASE_IMAGE********************************//
    [types.HANDLE_UPDATE_CASE_IMAGE]: (state, { payload }) => ({
      ...state,
      updateCaseImages: {
        image_keys: payload,
      },
    }),
    // *********************************************************//
    [types.GET_REPORTITEM_DETAILS]: (state, { payload }) => ({
      ...state,
      reportItem: {
        ...state.reportItem,
        loading: true,
        pending: true,
      },
    }),
    [types.SELECT_REPORT_iTEM]: (state, { payload }) => {
      return {
        ...state,
        reportItem: {
          ...state.reportItem,
          loading: false,
          pending: false,
          data: payload,
        },
      };
    },

    //------------------ GET NEW CASE ---------------------
    [types.OPEN_NEW_CASE]: (state, { payload }) => ({
      ...state,
      openNewCase: {
        ...state.openNewCase,
        loading: true,
        pending: true,
      },
    }),
    [types.OPEN_NEW_CASE_SUCCESS]: (state, { payload }) => ({
      ...state,
      openNewCase: {
        ...state.openNewCase,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.OPEN_NEW_CASE_FAIL]: (state, { payload }) => ({
      ...state,
      openNewCase: {
        ...state.openNewCase,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    //------------------ GET  CASE ---------------------
    [types.GET_CASE]: (state, { payload }) => ({
      ...state,
      getCase: {
        ...state.getCase,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_CASE_SUCCESS]: (state, { payload }) => ({
      ...state,
      getCase: {
        ...state.getCase,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_CASE_FAIL]: (state, { payload }) => ({
      ...state,
      getCase: {
        ...state.getCase,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    //------------------ GET UPDATE CASE ---------------------
    [types.UPDATE_CASE]: (state, { payload }) => ({
      ...state,
      updateCase: {
        ...state.updateCase,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_CASE_SUCCESS]: (state, { payload }) => ({
      ...state,
      updateCase: {
        ...state.updateCase,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.UPDATE_CASE_FAIL]: (state, { payload }) => ({
      ...state,
      updateCase: {
        ...state.updateCase,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------

    //------------------ GET RESOLUTION CENTER ---------------------
    [types.GET_RESOLUTION_CENTER_CASES]: (state, { payload }) => ({
      ...state,
      resolutionCenterCases: {
        ...state.resolutionCenterCases,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_RESOLUTION_CENTER_CASES_SUCCESS]: (state, { payload }) => ({
      ...state,
      resolutionCenterCases: {
        ...state.resolutionCenterCases,
        loading: false,
        pending: false,
        data: payload,
      },
    }),
    [types.GET_RESOLUTION_CENTER_CASES_FAIL]: (state, { payload }) => ({
      ...state,
      resolutionCenterCases: {
        ...state.resolutionCenterCases,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //------------------ ---------------- ---------------------
  },
  initialState
);
