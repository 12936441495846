import React from "react";
import "./ProductCardCarousel.less";

const ProductItemCard = ({ data }) => {
  // const { image, image2, title, desc } = data;
  return (
    <div className="gx-slider tb-product-card-carousel">
      <div className="gx-slider-img pl-2 pt-2 pr-2 pb-0">
        <img
          alt="example"
          src={data}
          style={{
            maxHeight: 185,
            // border: "1px solid rgb(235, 235, 235)",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
          }}
        />
      </div>
    </div>
  );
};

export default ProductItemCard;
