import firebase from "firebase";
import { FIREBASE_CONFIG_APIKEY, FIREBASE_CONFIG_AUTHDOMAIN, FIREBASE_CONFIG_DBURL, FIREBASE_CONFIG_PROJECTID, FIREBASE_CONFIG_STORAGE_BUCKET, FIREBASE_CONFIG_MESSAGE_SENDERID, FIREBASE_CONFIG_APPID, FIREBASE_CONFIG_MESSAGE_MEASUREMENTID } from "./../constants";

const Config = {
  apiKey: FIREBASE_CONFIG_APIKEY,
  authDomain: FIREBASE_CONFIG_AUTHDOMAIN,
  databaseURL: FIREBASE_CONFIG_DBURL,
  projectId: FIREBASE_CONFIG_PROJECTID,
  storageBucket: FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_CONFIG_MESSAGE_SENDERID,
  appId: FIREBASE_CONFIG_APPID,
  measurementId: FIREBASE_CONFIG_MESSAGE_MEASUREMENTID,
};

firebase.initializeApp(Config);
firebase.analytics();
const auth = firebase.auth();
const db = firebase.firestore();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");
const EmailAuthProvider = new firebase.auth.EmailAuthProvider();

const database = firebase.database();

const currentUser = auth.onAuthStateChanged((user) => {
  if (!!user) {
    return user;
  }
});

export {
  database,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  appleAuthProvider, 
  currentUser,
  EmailAuthProvider,
  db
};
