import React from "react";
import { Layout } from "antd";
import HorizontalDark from "../containers/Topbar/HorizontalDark";
import MainNavBar from "../components/MainNavBar/MainNavBar";
import ProfileHeader from "components/Buyer/profile/ProfileHeader/index";
import history from "../_helpers/history";
import Sidebar from "../containers/Sidebar";
import SiteFooter from "../components/Buyer/FooterComponent/SiteFooter";

const { Content } = Layout;

const DefaultLayout = (ViewComponent) => {
  return class extends React.Component {
    componentDidMount() {
      // getter
      const user = localStorage.getItem("user");
      if (!user) {
        history.push(`login`);
      }
    }

    render() {
      return (
        <Layout className="gx-app-layout">
          <Sidebar />
          <Layout>
            <HorizontalDark />
            <MainNavBar />
            <Content>
              <div
                style={{
                  marginTop: "30px",
                  marginLeft: "10%",
                  marginRight: "10%",
                }}
              >
                <ProfileHeader />
                <div className="gx-profile-content">
                  <>
                    <ViewComponent />
                  </>
                </div>
              </div>
              <SiteFooter />
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export default DefaultLayout;
