import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Badge } from "antd";
import Widget from "../../../Common/Widget/Widget";

class ShippingAddressSelectModalBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressData: null,
    };
  }

  handleSubmit = (values) => {
    this.props.handleOk();
    this.props.setSelectedAddress(values);
  };

  render() {
    const { shippingAddress } = this.props;
    let defaultShippingAddressId =
      (shippingAddress &&
        shippingAddress.data &&
        shippingAddress.data.data &&
        shippingAddress.data.data.default_shipping_address_id) ||
      null;
    return (
      <div>
        <p>
          As a user, you can keep multiple shipping addresses. So you don't need
          to add details over and over Here you can select the shipping address
          for this particular order.
        </p>
        {shippingAddress.data.data.shipping_addresses.map((address, index) => {
          let isDefaultShippingAddress =
            defaultShippingAddressId === address.id;

          return (
            <div onClick={() => this.handleSubmit(address)} key={index}>
              <Widget>
                <div className="gx-news-item p-2 mb-2">
                  <div className="gx-news-content" style={{ width: "100%" }}>
                    {isDefaultShippingAddress ? (
                      <Badge
                        count="Default Address"
                        style={{
                          backgroundColor: "#038fde",
                          color: "#fff",
                          boxShadow: "0 0 0 1px #d9d9d9 inset",
                          marginLeft: "10px",
                        }}
                      />
                    ) : null}
                    <h6 className="gx-mt-0 mb-3">
                      <i className="fad fa-user mr-2"></i>
                      {address.first_name + " " + address.last_name}
                    </h6>

                    {/* <p className="gx-mb-2">{subTitle}</p> */}
                    <div className="gx-news-tags-row">
                      <div className="gx-news-tags-left">
                        <p className="gx-text-grey gx-mb-0">
                          <i className="fad fa-map-marker-alt mr-2"></i>
                          {address.address1}
                        </p>
                        <p
                          className="gx-text-grey gx-mb-0"
                          style={{ marginLeft: "19px" }}
                        >
                          {address.address2}
                        </p>
                        <p
                          className="gx-text-grey gx-mb-0"
                          style={{ marginLeft: "19px" }}
                        >
                          {address.city}
                        </p>
                        <p
                          className="gx-text-grey gx-mb-0"
                          style={{ marginLeft: "19px" }}
                        >
                          {address.country}
                        </p>
                        <p
                          className="gx-text-grey gx-mb-0"
                          style={{ marginLeft: "19px" }}
                        >
                          {address.state}
                        </p>
                        <p
                          className="gx-text-grey gx-mb-3"
                          style={{ marginLeft: "19px" }}
                        >
                          {address.zip}
                        </p>
                        <p className="gx-text-grey gx-mb-0">
                          <i className="fad fa-phone-alt mr-2"></i>
                          {address.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Widget>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shippingCost: state.Cart.shippingCost,
    checkoutItems: state.Cart.checkoutItems,
    selectedShippingAdress: state.Profile.selectedShippingAdress,
  };
}
function mapDispatchToProps(dispatch) {
  return null;
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingAddressSelectModalBody)
);
