import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { resolutionCenterActions } from "../../ResolutionCenter/ducks";
import ResolutionConversationList from "../../../components/Buyer/ResolutionConversationList/ResolutionConversationList";

class DashboardSingleCase extends Component {
  constructor(props) {
    // debugger;
    super(props);
    this.state = {
      imageList: [],
    };
  }

  componentDidMount() {
    var id = this.props.match.params && this.props.match.params.id;
    this.props.resolutionCenterActions.getCase({ id: id });
  }

  componentWillUnmount() {
    this.props.resolutionCenterActions.clearCase();
  }

  render() {
    const { getCase } = this.props;

    if (getCase.loading) {
      return (
        <div className="">
          <h3 className="mb-4">Resolution Center</h3>

          <div className="gx-card p-3 pl-4 mb-3">
            <Link
              className="crd-fnt-jr"
              to="/seller/dashboard/resolution-center/all"
            >
              Resolution Center
            </Link>
            {" > "}Single Dispute
          </div>

          <div className="gx-card p-4">
            <div
              className="tr-wishlist-notify-container"
              style={{ margin: "100px" }}
            >
              <div className="tr-wishlist-notify-loading-spinner"> </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mb-5">
          <h3 className="mb-4">Resolution Center</h3>

          <div className="gx-card p-3 pl-4 mb-3">
            <Link
              className="crd-fnt-jr"
              to="/seller/dashboard/resolution-center/all"
            >
              Resolution Center
            </Link>
            {" > "}Single Dispute
          </div>

          <ResolutionConversationList />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    getCase: state.ResolutionCenter.getCase,
    updateCaseImages: state.ResolutionCenter.updateCaseImages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardSingleCase)
);
