import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import { profileActions } from "../../../../modules/ProfilePage/ducks";
import { allCountriesJson } from "../../../../DummyData/AllCountry";
import "./ShippingAddressModalBody.less";

const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const formItemZipLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const formItemCheckboxLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const AInputField = makeField(Input);
const ASelectField = makeField(Select);
const ACheckboxField = makeField(Checkbox);
class ShippingAddressModalBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedShippingAdress } = this.props;
    if (
      selectedShippingAdress.data &&
      selectedShippingAdress.data !== this.state.data
    ) {
      const recievedObj = {
        firstName: selectedShippingAdress.data.first_name,
        lastName: selectedShippingAdress.data.last_name,
        zip: selectedShippingAdress.data.zip,
        city: selectedShippingAdress.data.city,
        state: selectedShippingAdress.data.state,
        addressLine1: selectedShippingAdress.data.address1,
        addressLine2: selectedShippingAdress.data.address2,
        country: selectedShippingAdress.data.country,
        contactNumber: selectedShippingAdress.data.phone,
        default_address: selectedShippingAdress.data.is_default_address,
      };

      //initialize redux form values
      this.props.initialize(recievedObj);
      this.setState({ data: selectedShippingAdress.data });
    }
  }
  handleSubmit = (values) => {
    const { isUpdate, data } = this.props;
    const {
      firstName,
      lastName,
      zip,
      city,
      state,
      addressLine1,
      addressLine2,
      country,
      contactNumber,
      default_address,
    } = values;
    var shippingInfoDto = {
      first_name: firstName,
      last_name: lastName,
      zip: zip,
      city: city,
      state: state,
      address1: addressLine1,
      address2: addressLine2,
      country: country,
      phone: contactNumber,
      default_address: default_address,
    };

    isUpdate
      ? this.props.profileActions.updateShippingAddress({
          shippingInfoDto: shippingInfoDto,
          shipping_address_id: data.id,
        })
      : this.props.profileActions.addShippingAddress({
          shippingInfoDto: shippingInfoDto,
        });

    this.props.handleOk();
  };

  countryHandler = (value) => {
     // { key: "lucy", label: "Lucy (101)" }
  };
  render() {
    const { handleSubmit, pristine, reset, submitting, isUpdate } = this.props;

    return (
      <React.Fragment>
        <Form
          className="feild-catcher-sh-add"
          onSubmit={handleSubmit(this.handleSubmit)}
        >
          <Card
            className="crd-bdy-fd mb-3 pb-3 pt-3"
            style={{ backgroundColor: "#fafafa" }}
          >
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  // label="First Name"
                  name="firstName"
                  component={AInputField}
                  placeholder="First Name"
                  hasFeedback
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  // label="First Name"
                  name="lastName"
                  component={AInputField}
                  placeholder="Last Name"
                  hasFeedback
                />
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  name="addressLine1"
                  component={AInputField}
                  placeholder="Address Line 1"
                  hasFeedback
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  name="addressLine2"
                  component={AInputField}
                  placeholder="Address Line 2"
                  // hasFeedback
                />
              </Col>
            </Row>

            <Row>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  name="city"
                  component={AInputField}
                  placeholder="City"
                  hasFeedback
                />
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  name="state"
                  component={AInputField}
                  placeholder="State/Province/Region"
                  hasFeedback
                />
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <Field
                  showSearch
                  formItemLayout={formItemLayout}
                  name="country"
                  component={ASelectField}
                  hasFeedback
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allCountriesJson.map((country, i) => {
                    return (
                      <Option key={i} value={country.alpha_3}>
                        {country.name}
                      </Option>
                    );
                  })}
                </Field>
              </Col>
            </Row>

            <Row>
              <Col xl={9} lg={9} md={9} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemZipLayout}
                  name="zip"
                  component={AInputField}
                  placeholder="Zip Code"
                  hasFeedback
                />
              </Col>
              <Col xl={15} lg={15} md={15} sm={24} xs={24}>
                <Field
                  formItemLayout={formItemLayout}
                  name="contactNumber"
                  component={AInputField}
                  placeholder="Contact Number"
                  hasFeedback
                />
              </Col>
            </Row>

            <Field
              formItemLayout={formItemCheckboxLayout}
              // label="Set as default"
              name="default_address"
              id="setAsDefault"
              component={ACheckboxField}
              type="checkbox"
            >
              Set as default{" "}
            </Field>
          </Card>
          <div style={{ textAlign: "end" }}>
            {isUpdate ? null : (
              <Button
                style={{ marginBottom: "0px" }}
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            )}
            <Button
              type="primary"
              disabled={pristine || submitting}
              htmlType="submit"
              style={{
                marginBottom: "0px",
              }}
            >
              {isUpdate ? "Update" : "Confirm"}
            </Button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "Contact number is required";
  }
  if (!values.addressLine1) {
    errors.addressLine1 = "Address line 1 is required";
  }
  if (!values.city) {
    errors.city = "City is required";
  }
  if (!values.state) {
    errors.state = "State is required";
  }
  if (!values.country) {
    errors.country = "Country is required";
  }
  if (!values.zip) {
    errors.zip = "Zip code is required";
  }
  return errors;
};

function mapStateToProps(state) {
  return {
    shippingCost: state.Cart.shippingCost,
    checkoutItems: state.Cart.checkoutItems,
    selectedShippingAdress: state.Profile.selectedShippingAdress,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "ShippingAddressModalBody",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ShippingAddressModalBody))
);
