import { Col } from "antd";
import React from "react";
import { DEFAULT_CURRENCY } from "../../../../constants";

const PopularProductItem = ({ data }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(
      `/product/${url}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const { images, name, price, views, id } = data;
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <div style={{ height: "100%", paddingBottom: "15px" }}>
        <div
          style={{
            height: "100%",
            border: "1px solid rgb(235, 235, 235)",
            borderRadius: "5px",
            // alignItems: "center",
          }}
          onClick={() => {
            openInNewTab(id);
          }}
          className="gx-news-item p-2 shpnHovr msPntr content-jr-grd"
        >
          <div className="gx-news-thumb">
            <img
              className="gx-rounded-lg"
              style={{ width: "100px" }}
              src={images[0]}
              alt="..."
            />
          </div>
          <div className="gx-news-content-jr">
            <div className="gx-mt-0">{name}</div>
            <div className="">
              <div className="">
                <p className="fnshed-clr gx-mb-0">
                  Price :{`${DEFAULT_CURRENCY} ${price}`}
                </p>
                <p className="fnshed-clr gx-mb-0">Views : {views}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default PopularProductItem;
