import React from "react";

const Specifications = ({ product }) => {
  const specifications =
    product &&
    product.data &&
    product.data.data &&
    product.data.data.specifications;
  const specificationsArray = Object.keys(specifications).map((key) => {
    return { key: key, value: specifications[key] };
  });
  return (
    <div className="gx-card p-3 mb-0 bot-line-catch">
      <ul classname="list-unstyled ">
        {specificationsArray.map((specification, i) => {
          return (
            <li>
              <strong>{specification.key}:</strong> {specification.value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Specifications;
