import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Button, Card, Form, Input, DatePicker } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";

const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const AInputField = makeField(Input);
const ARangePickerField = makeField(RangePicker);

class UserSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "UserSearchBar",
    };
  }
  componentDidMount() {
    // To disabled submit button at the beginning.
    // this.props.form.validateFields();
  }

  handleSubmit = () => {
    alert("Searching....");
  };

  handleRangePicker = (dates, dateStrings) => {
    
    
  };

  addNewUserHandler = () => {
    const { match } = this.props;
    const type = match.params && match.params.type;
    this.props.history.push(`/seller/dashboard/userManagement/${type}/create`);
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card style={{ backgroundColor: "#fafafa" }}>
        <Form
          layout="inline"
          style={{ marginLeft: "0" }}
          className="mt-2"
          onSubmit={handleSubmit(this.handleSubmit)}
        >
          <Button
            className="gx-mb-0"
            type="primary"
            onClick={() => this.addNewUserHandler()}
            //   disabled={hasErrors(getFieldsError())}
          >
            Add New
          </Button>
          <Field
            formItemLayout={formItemLayout}
            name="userName"
            component={AInputField}
            placeholder="Name"
            hasFeedback
          />
          <Field
            formItemLayout={formItemLayout}
            name="userCategory"
            component={AInputField}
            placeholder="Category"
            hasFeedback
          />

          <Field
            formItemLayout={formItemLayout}
            name="dateRange"
            component={ARangePickerField}
            ranges={{
              Today: [moment(), moment()],
              "This Month": [moment(), moment().endOf("month")],
            }}
            onChange={this.handleRangePicker}
            hasFeedback
          />
          <FormItem>
            <Button
              className="gx-mb-0"
              type="primary"
              htmlType="submit"
              //   disabled={hasErrors(getFieldsError())}
            >
              Search
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (
    !values.productName &&
    !values.productBrandName &&
    (!values.productBrandName || !values.productName)
  ) {
    errors.productBrandName = "Product Name or Brand Name required";
    errors.productName = "Product Name or Brand Name required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    signinUser: state.AuthStore.signinUser,
    signupUser: state.AuthStore.signupUser,
    currentUser: state.AuthStore.currentUser,
  };
};

export default withRouter(
  reduxForm({
    form: "UserSearchBar_form",
    validate,
  })(connect(mapStateToProps, null)(UserSearchBar))
);
