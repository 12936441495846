import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductItemCard from "./ProductItemCard";
import "./ProductCardCarousel.less";

const ProductCardCarousel = ({ data }) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    marginLeft: 10,
    marginRight: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    // <CardBox styleName="gx-card" heading={''}>
    <Slider
      className="gx-slick-slider jr-slick-slider tb-product-card-carousel"
      {...settings}
    >
      {data.slice(0, 5).map((media, index) => (
        <ProductItemCard
          key={index}
          data={media}
          // data={data}
        />
      ))}
    </Slider>
    // </CardBox>
  );
};

export default ProductCardCarousel;
