import algoliasearch from "algoliasearch";
import { ALGOLIA_APP_ID } from "../constants";
import { signOut } from "./auth.service";

export const initAlgoliaClient = (apiKey) => {
  if (
    apiKey !== null &&
    apiKey !== undefined &&
    !checkAlgoliaAPIKeyIsValidity(apiKey)
  ) {
    signOut(true).then();
  }
  
  return algoliasearch(ALGOLIA_APP_ID, apiKey);
};

const checkAlgoliaAPIKeyIsValidity = (apiKey, marginDays = 2) => {
  console.log(
    "🚀 ~ file: algolia.service.js ~ line 14 ~ checkAlgoliaAPIKeyIsValidity ~ apiKey",
    apiKey
  );
  try {
    if (!apiKey) throw Error("Invalid API Key");
    const decodedKey = atob(apiKey);

    const regex = /(validUntil=)(\d{1,})/g;
    const match = regex.exec(decodedKey);
    if (!match || match.length < 3) return true;
    const validTill = match[2];
    const now = parseInt(+new Date() / 1000);
    return now < validTill - marginDays * 24 * 60 * 60;
  } catch (err) {
    return false;
  }
};
