import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  serverError: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.SERVER_SIDE_ERROR_HANDLER]: (state, { payload }) => ({
      ...state,
      serverError: {
        ...state.serverError,
        hasError: true,
        error: payload
      },
    }),
  },
  initialState
);
