import React, { Component } from "react";
import { connect } from "react-redux";
import { Result, Button, Spin, Card } from "antd";
import { withRouter } from "react-router-dom";
import { cartActions } from "../Cart/ducks";
import { bindActionCreators } from "redux";
import { DEFAULT_CURRENCY } from "../../constants";

class CheckoutSuccess extends Component {
  componentDidMount() {
    const { cartActions } = this.props;
    const token = new URLSearchParams(this.props.location.search).get("token");
    const payerID = new URLSearchParams(this.props.location.search).get(
      "PayerID"
    );

    if (token && payerID) {
      var authorizePaymentDto = {
        token: token,
        payer_id: payerID,
      };
      cartActions.makeAuthorizePayment({ authorizePaymentDto });
    }
  }

  handlenavigationHistory = () => {
    const { match } = this.props;
    if (match.url === "/checkout/success") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  };

  homeHandler = () => {
    this.props.history.push("/");
  };

  orderHandler = () => {
    this.props.history.push("/orders/all");
  };

  render() {
    const { makePayemt } = this.props;
    let paymentData =
      !makePayemt.loading && makePayemt.data && makePayemt.data.data;
    let paymentDataResponse =
      !makePayemt.loading &&
      makePayemt.data &&
      makePayemt.data.data &&
      makePayemt.data.data.response;

    if (makePayemt.pending) {
      return (
        <div
          className="gx-container"
          style={{ paddingTop: "10vh", paddingBottom: "7vh" }}
        >
          <Card className="mb-0">
            <div style={{ textAlign: "center", padding: "90px 10px" }}>
              <Spin size="large" className="mr-0" />
              <h5> Waiting Payment</h5>
            </div>
          </Card>
        </div>
      );
    } else {
      return (
        // <Spin spinning={true}>
        <div
          className="gx-container"
          style={{ paddingTop: "10vh", paddingBottom: "7vh" }}
        >
          <Card className="mb-0">
            <Result
              status="success"
              title="Thank you for your payment!"
              subTitle="Tradesbarn has received your payment."
              extra={[
                <div className="mb-4 mt-3">
                  <div>
                    Payment Success{" "}
                    {paymentData.total_cost &&
                      `${DEFAULT_CURRENCY} ${paymentData.total_cost.toFixed(
                        2
                      )}`}{" "}
                  </div>
                  <div>
                    {paymentDataResponse &&
                      `${paymentDataResponse.message}  Transaction ID : ${paymentDataResponse.transaction_id}`}{" "}
                  </div>
                </div>,
                <Button
                  type="primary"
                  key="console"
                  onClick={this.orderHandler}
                >
                  My Orders
                </Button>,
                <Button type="primary" key="console" onClick={this.homeHandler}>
                  Home
                </Button>,
              ]}
            />
          </Card>
        </div>
        // </Spin>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    makePayemt: state.Cart.makePayemt,
    makeAuthorizePayemt: state.Cart.makeAuthorizePayemt,
    needSecureAcceptance: state.Cart.needSecureAcceptance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccess)
);
