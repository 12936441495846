import React from "react";
import { Radio } from "antd";
import { withRouter } from "react-router-dom";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const MyOrderSelections = ({ orderCount, history, type }) => {
    let orderCountData = orderCount && orderCount.data && orderCount.data.data
    function onChange(e) {
        history.push(`/orders/${e.target.value}`);
    }
    return (
        <div className="">
            <RadioGroup onChange={onChange} defaultValue={type} buttonStyle="solid">
                <RadioButton className="mb-0" value="all">
                    All&nbsp; {orderCountData ? `(${orderCountData.all_orders_count})` : null}
                </RadioButton>

                {/* buyer_orders_to_ship: Order purchased. Seller not shipped yet. */}
                <RadioButton className="mb-0" value="orders-to-ship">
                    Paid&nbsp; {orderCountData ? `(${orderCountData.orders_to_ship_count})` : null}
                </RadioButton>

                {/* buyer_unpaid_orders: Buyer bought the item. but payment Failed. */}
                <RadioButton className="mb-0" value="unpaid-orders">
                    Unpaid&nbsp; {orderCountData ? `(${orderCountData.unpaid_orders_count})` : null}
                </RadioButton>

                {/* buyer_shipped_orders */}
                <RadioButton className="mb-0" value="buyer-shipped-orders">
                    Shipped&nbsp; {orderCountData ? `(${orderCountData.shipped_orders_count})` : null}
                </RadioButton>

                {/* buyer_cancelled_orders: Order is canceled. */}
                <RadioButton className="mb-0" value="cancel-orders">
                    Cancelled&nbsp; {orderCountData ? `(${orderCountData.cancelled_orders_count})` : null}
                </RadioButton>

                {/* buyer_delivered_orders */}
                <RadioButton className="mb-0" value="delivered-orders">
                    Delivered&nbsp; {orderCountData ? `(${orderCountData.delivered_orders_count})` : null}
                </RadioButton>

                {/* buyer_returned_orders */}
                <RadioButton className="mb-0" value="buyer-returned-orders">
                    Return&nbsp; {orderCountData ? `(${orderCountData.returned_orders_count})` : null}
                </RadioButton>
            </RadioGroup>
        </div>
    );
};

export default withRouter(MyOrderSelections);
