import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  sellerStore: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    // ----------- CLEAR_SELLER_STORE -----------
    [types.CLEAR_SELLER_STORE]: (state, { payload }) => ({
      ...state,
      sellerStore: {
        ...initialStateModal,
        pending: true,
      },
    }),

    // ----------- GET_SELLER_STORE -----------
    [types.GET_SELLER_STORE]: (state, { payload }) => ({
      ...state,
      sellerStore: {
        ...state.sellerStore,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_STORE_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerStore: {
        ...state.sellerStore,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_SELLER_STORE_FAILED]: (state, { payload }) => ({
      ...state,
      sellerStore: {
        ...state.sellerStore,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
