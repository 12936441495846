import React, { Component } from "react";
import ProductReviewContainer from "../../../../components/Seller/ProductReviews/ProductReviewContainer/ProductReviewContainer";

class ProductReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
    };
  }
  componentDidMount() {
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    this.setState({
      algolia,
    });
  }
  render() {
    const { algolia } = this.state;

    if (algolia) {
      return (
        <div>
          <div style={{ paddingBottom: "20px" }}>
            <h3>Product Review</h3>
          </div>

          {/* Product Review Searchbar */}
          {/* <ProductReviewSearchbar /> */}

          {/* Product Review Table */}
          <ProductReviewContainer
            apiKey={algolia.product_reviews && algolia.product_reviews.api_key}
            index={algolia.product_reviews && algolia.product_reviews.index}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ paddingBottom: "20px" }}>
            <h3>Product Review</h3>
          </div>

          {/* Product Review Searchbar */}
          {/* <ProductReviewSearchbar /> */}
        </div>
      );
    }
  }
}

export default ProductReview;
