import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Button } from "antd";
// import IntlMessages from "util/IntlMessages";
import StarRatingComponent from "react-star-rating-component";
import AllProductsCarousel from "../../Carousel/AllProductsCarousel/AllProductsCarousel";
import { Link, withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import "./ProductCard.less";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";

class ProductCard extends Component {
  productNavigationHandler = () => {};

  buyNowHandler = (item) => {
    // this.props.cartActions.addCartItem({ item: item, displaySucess: true });
    this.props.history.push(`/product/${item.id}`);
  };

  addWishlistHandler = (id) => {
    this.props.updateWishlistHandler(id);
    var wishlistDto = {
      product_id: id,
    };
    this.props.cartActions.addWishlistItem({
      wishlistDto: wishlistDto,
      isAllProduct: true,
    });
  };
  render() {
    const { item } = this.props;
    const {
      objectID,
      name,
      old_price,
      price,
      discount_percentage,
      id,
      is_wishlisted,
      ratings_count,
      ratings_value,
      sold,
    } = item;
    return (
      <div className="gx-product-item pdct-crd-ef-jr-2 bdr-jr gx-product-vertical">
        <Link to={`/product/${objectID}`} style={{ height: "100%" }}>
          <div className="gx-product-image">
            <div className="gx-grid-thumb-equal">
              <span className="gx-link gx-grid-thumb-cover">
                <Row>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div
                      className="perant-hvr"
                      style={{ position: "relative" }}
                    >
                      <AllProductsCarousel
                        data={item.images}
                        // data={dummyImages}
                      />
                      {discount_percentage !== 0 ? (
                        <span className="tb-product-card-discount-tag">
                          <p
                            style={{ marginRight: "5px", marginBottom: "0px" }}
                          >
                            {`${discount_percentage}%`}
                          </p>
                        </span>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </span>
            </div>
          </div>

          <div className="gx-product-body p-2 pl-3 pr-3 txt-alng-pg-jr">
            <p className="gx-product-title tb-product-title crd-fnt-jr mb-1">
              {name}
            </p>

            <div>
              <div style={{ display: "inline-block", width: "70%" }}>
                <div style={{ display: "inline-block" }}>
                  <div
                    style={{ fontWeight: "bold" }}
                    className="gx-product-price"
                  >
                    {`${DEFAULT_CURRENCY_SHORT}${price}`}
                  </div>
                </div>
                {discount_percentage ? (
                  <p
                    style={{
                      display: "inline-block",
                      marginLeft: "10px",
                      fontSize: "14px",
                    }}
                  >
                    <del>{`${DEFAULT_CURRENCY_SHORT} ${old_price}`}</del>
                  </p>
                ) : null}
              </div>
              <div style={{ display: "inline-block", width: "30%" }}>
                <p
                  className="pd-crd-othr-fnt"
                  style={{ fontSize: "13px", textAlign: "right" }}
                >
                  {sold > 0 ? `${sold} Solds` : null}
                </p>
              </div>
            </div>

            {ratings_count > 0 && (
              <div className="ant-row-flex">
                <StarRatingComponent
                  name=""
                  value={parseFloat(ratings_value)}
                  // value={4}
                  starCount={5}
                  editing={false}
                  emptyStarColor={"#4b505e"}
                />

                <span className="gx-d-inline-block gx-ml-2 pd-crd-othr-fnt">
                  {ratings_value}
                </span>
              </div>
            )}
          </div>
        </Link>

        <div
          className="txt-alng-pg-jr pt-0 pr-3 pb-3 pl-3 m-0"
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          <div
            className="crd-hvr"
            style={{
              display: "inline-block",
              marginRight: "0px",
              width: "70%",
            }}
          >
            <Button
              onClick={() => this.buyNowHandler(item)}
              style={{ width: "90%" }}
              type="primary"
              className="gx-mb-0"
              htmlType="submit"
            >
              Buy Now
            </Button>
          </div>
          <div
            className="crd-hvr-3"
            style={{
              display: "inline-block",
              marginRight: "0px",
              width: "30%",
              position: "absolute",
            }}
          >
            <button
              className="like-button-2"
              onClick={() => this.addWishlistHandler(id)}
            >
              {is_wishlisted ? (
                <span className="like-clicked">
                  <i className="fas fa-heart like-icon-2"></i>
                </span>
              ) : (
                <span className="like-unclicked">
                  <i className="fas fa-heart like-icon-2"></i>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCart: state.Cart.myCart,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCard)
);
