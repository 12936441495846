import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  exportTransactions: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {

    [types.GET_TRANSACTION_RECORD]: (state, { payload }) => ({
      ...state,
      exportTransactions: {
        ...state.exportTransactions,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_TRANSACTION_RECORD_SUCCESS]: (state, { payload }) => ({
      ...state,
      exportTransactions: {
        ...state.exportTransactions,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_TRANSACTION_RECORD_FAILED]: (state, { payload }) => ({
      ...state,
      exportTransactions: {
        ...state.exportTransactions,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //*********************************************/
  },


  initialState
);
