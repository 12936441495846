import React from "react";
import axios from "axios";
import { GetIdToken } from "./../services/auth.service";
import { NotificationManager } from "react-notifications";
import { auth } from "firebase";
import IntlMessages from "util/IntlMessages";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
});

axios.defaults.headers.post["Content-Type"] = "application/json";

// Create axios instance for api calls
let instance = null;
const headers = {
  "Content-Type": "application/json",
};

export const setAuth = async () => {
  // debugger;
  const idToken = await GetIdToken();
  if (!!idToken) {
    headers["authorization"] = `Bearer ${idToken}`;
  }

  instance = axios.create({
    baseURL: "",
    timeout: 120000,
    headers: headers,
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // debugger
      console.log(
        "🚀 ~ file: HTTPClient.js ~ line 100 ~ setAuth ~ error",
        error
      );
      let response = error.response;

      

      Sentry.withScope((scope) => {
        Sentry.captureException(response);
      });
      // if (err && err.code === "ECONNABORTED") {
      //   errorMessage = "Please check your internet connection.";
      // }
      if (
        error.response &&
        error.response.status !== undefined &&
        error.response.status === 400
      ) {
        let errMessage = error?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        NotificationManager.error(errMessage, "Operation Failed");
      }
      if (
        error.response &&
        error.response.status !== undefined &&
        error.response.status === 401
      ) {
        NotificationManager.error(
          <IntlMessages id="notification.httpClient.reLoginToTheSystem" />,
          <IntlMessages id="notification.httpClient.reLogin" />
        );

        // localStorage.clear();
        // clearHTTPClient();
        // window.location = "/login";
      }
      if (error.response && error.response.status === 500) {
        let error = {
          status: 500,
          message: (
            <IntlMessages id="notification.httpClient.contactServiceProvider" />
          ),
        };
        NotificationManager.error(
          error.message,
          <IntlMessages id="notification.httpClient.attentionNeeded" />
        );

        return Promise.reject(error);
      }
      if (error.response && error.response.status === 400) {
        return Promise.reject(error);
      } else {
        let error = {
          status: 524,
          message: "Something went wrong..!",
        };
        return Promise.reject(error);
      }
    }
  );
};

export const clearHTTPClient = () => {
  instance = null;
};

export const checkToken = () => {
  auth.onIdTokenChanged = (user) => {
    
    if (user) {
      
      // alert("Token refresh handled")
      // User is signed in or token was refreshed.
      clearHTTPClient();
    } else {
      return instance;
    }
  };
};

export default {
  Get: async (route, data) => {
    // debugger;
    checkToken() || (await setAuth());
    // instance || (await setAuth());
    return instance.get(route, data === {} ? null : JSON.stringify(data));
  },
  Post: async (route, data) => {
    // debugger;
    checkToken() || (await setAuth());
    // instance || (await setAuth());
    return instance.post(route, JSON.stringify(data));
  },
  Put: async (route, data) => {
    checkToken() || (await setAuth());
    // instance || (await setAuth());
    return instance.put(route, JSON.stringify(data));
  },
  Delete: async (route, data) => {
    checkToken() || (await setAuth());
    // instance || (await setAuth());
    return instance.delete(route, { data: data });
  },
  Patch: async (route, data) => {
    checkToken() || (await setAuth());
    // instance || (await setAuth());
    return instance.patch(route, JSON.stringify(data));
  },
  Download: async (route, data) => {
    return axios
      .create({
        baseURL: "",
        timeout: 30000,
        // headers: {
        //   authorization: `${localStorage.token}`,
        // },
      })
      .get(route, data === {} ? null : JSON.stringify(data));
  },
};
