//basic state
export const initialStateModal = {
  loading: true,
  pending: false,
  hasError: false,
  data: [],
  error: {},
};

//multi-language-suport-setting
export const locale = {
  languageId: "english",
  locale: "en",
  name: "English",
  icon: "us",
};

//side bar settings
export const sidebarSettings = {
  themeType: "THEME_TYPE_SEMI_DARK",
  navStyle: "NAV_STYLE_FIXED",
  width: 991,
  navCollapsed: false,
  pathname: "/",
};

//side bar settings
export const sellerSidebarSettings = {
  themeType: "THEME_TYPE_SEMI_DARK",
  navStyle: "NAV_STYLE_FIXED",
  width: 1920,
  navCollapsed: false,
  pathname: "/",
};
//user roles
export const userRoles = {
  Buyer: "Buyer",
  Seller: "Seller",
  Moderator: "Moderator",
  Admin: "Admin",
};

//payment methods
export const payment_methods = {
  credit_card: "credit_card",
  paypal: "paypal",
  saved_credit_card: "saved_credit_card",
};

export const sellerCategories = [
  {
    label: "Individual",
    value: "Individual",
  },
  {
    label: "SME",
    value: "SME",
  },
  {
    label: "Producer",
    value: "Producer",
  },
  {
    label: "Manufacturer",
    value: "Manufacturer",
  },
  {
    label: "Trading Business",
    value: "Trading Business",
  },
];

export const currencyTypes = [
  {
    label: "U.S. Dollar",
    value: "USD",
  },
  {
    label: "European Euro",
    value: "EUR",
  },
  {
    label: "Japanese Yen",
    value: "JPY",
  },
  {
    label: "British Pound",
    value: "GBP",
  },
  {
    label: "Swiss Franc",
    value: "CHF",
  },
  {
    label: "Canadian Dolla",
    value: "CAD",
  },
  {
    label: "Sri Lanka Rps",
    value: "LKR",
  },
  {
    label: "South African Rand",
    value: "ZAR",
  },
];

export const serviceTypes = [
  {
    label: "No Warranty",
    value: "no_warranty",
  },
  {
    label: "International Manufacturer Warranty",
    value: "international_manufacturer_warranty",
  },
  {
    label: "Local seller Warranty",
    value: "local_seller_warranty",
  },
  {
    label: " International seller Warranty",
    value: "international_seller_warranty",
  },
  {
    label: "Brand Warranty",
    value: "brand_warranty",
  },
  {
    label: "Agent Warranty",
    value: "agent_warranty",
  },
];

export const packageTypes = [
  {
    label: "Letter",
    value: "letter",
  },
  {
    label: "Large Envelope",
    value: "envelope",
  },
  {
    label: "Package (or thick envelope)",
    value: "package",
  },
  {
    label: "Large Package",
    value: "large_package",
  },
  {
    label: "Box",
    value: "box",
  },
];

export const tradesbarnDomesticShippingTypes = [
  {
    label: "Free Shipping",
    value: "free_shipping",
  },
  {
    label: " Flat Rate : Same cost to all buyers",
    value: "flat_rated",
  },
  {
    label: "No shipping : Local pickup only",
    value: "no_shipping",
  },
];

export const onShopDomesticShippingTypes = [
  {
    label: "Free Shipping",
    value: "free_shipping",
  },
  {
    label: "OnShop.lk Delivery Service",
    value: "onshop_delivery",
  },
];

export const internationalShippingTypes = [
  {
    label: "Calculated",
    value: "calculated",
  },
  {
    label: "Free Shipping",
    value: "free_shipping",
  },
  {
    label: "Flat Rate : Same cost to all buyers",
    value: "flat_rated",
  },
  {
    label: "No shipping : Local pickup only",
    value: "no_shipping",
  },
];

export const returnTypes = [
  {
    label: "Paid by Seller",
    value: "paid_by_seller",
  },
  {
    label: "Paid by Buyer",
    value: "paid_by_buyer",
  },
  {
    label: "No Return",
    value: "no_returns",
  },
];

export const conditionTypes = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Used",
    value: "Used",
  },
  {
    label: "Open box",
    value: "Open box",
  },
  {
    label: "Certified refurbished",
    value: "Certified refurbished",
  },
  {
    label: "Seller refurbished",
    value: "Seller refurbished",
  },
  {
    label: "For parts or not working",
    value: "For parts or not working",
  },
];

export const FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};

export const FormItemLayout2 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
};

export const TailFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 16 },
    xl: { span: 16 },
  },
};

export const FormItemLayoutDescription = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 6 },
    xl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 18 },
    xl: { span: 18 },
  },
};

// feedback form --------------

export const feedbackFormContent = `Are you enjoying Tradesbarn? We're the World's Newest e-Commerce Platform! Let us know What More You Expect From Us. Your Suggestions, Comments and Reviews help spread the word for us to Grow And Serve you better. Whether you love what we have done so far or feel we could be doing better, we'd love your feedback. We are humbled & grateful.
  Appreciate it.
  `;

export const feedbackFormTypes = [
  {
    label: "Suggestions",
    value: "suggestions",
  },
  {
    label: "Comments",
    value: "comments",
  },
  {
    label: "Questions",
    value: "questions",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const RCReasons = [
  {
    label: "Product no received",
    value: "Product no received",
  },
  {
    label: "Doesn't fit",
    value: "Doesn't fit",
  },
  {
    label: "Changes my mind",
    value: "Changes my mind",
  },
  {
    label: "Just didn't like it",
    value: "Just didn't like it",
  },
  {
    label: "Ordered by mistake",
    value: "Ordered by mistake",
  },
  {
    label: "Doesn't work or detective",
    value: "Doesn't work or detective",
  },
  {
    label: "Doesn't match description or photos",
    value: "Doesn't match description or photos",
  },
  {
    label: "Wrong item sent",
    value: "Wrong item sent",
  },
  {
    label: "Missing parts or pieces",
    value: "Missing parts or pieces",
  },
  {
    label: "Arrived parts or pieces",
    value: "Arrived parts or pieces",
  },
  {
    label: "Doesn't seem authentic",
    value: "Doesn't seem authentic",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const SHOPPING_CART = "SHOPPING_CART";
export const CHECKOUT_CART = "CHECKOUT_CART";

export const weightMeasurements = [
  {
    label: "(oz)",
    value: "oz",
  },
  {
    label: "(kg)",
    value: "kg",
  },
  {
    label: "(g)",
    value: "g",
  },
];

export const lengthMeasurements = [
  {
    label: "(in)",
    value: "in",
  },
  {
    label: "(cm)",
    value: "cm",
  },
  {
    label: "(m)",
    value: "m",
  },
];
