import React from "react";
import { createLogic } from "redux-logic";
import { NotificationManager } from "react-notifications";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../../util/EndPoints";
import API from "../../../../util/HTTPClient";
import IntlMessages from "util/IntlMessages";

const replyToReview = createLogic({
  type: types.REPLY_TO_REVIEW,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    // debugger;
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(
      endPoints.REPLY_TO_REVIEW + `/${action.payload.id}`,
      action.payload.replyDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        NotificationManager.success(data.message, "Success");
        dispatch(actions.replyToReviewSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.replyToReviewFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteReview = createLogic({
  type: types.DELETE_REVIEW,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.REPLY_TO_REVIEW + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.deleteReviewSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.deleteReviewFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteReviewReply = createLogic({
  type: types.DELETE_REVIEW_REPLY,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.REPLY_TO_REVIEW + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.deleteReviewReplySuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.deleteReviewReplyFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [replyToReview, deleteReview, deleteReviewReply];
