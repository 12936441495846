import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import SellerRegisterForm from "../../components/Buyer/SellerRegisterForm/SellerRegisterForm";
import "./SellerRegisterPage.less";
import { Col, Row, Card } from "antd";
class SellerRegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
  render() {
    return (
      <div className="gx-container" style={{ paddingTop: 20 }}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card
              className="gx-card"
              style={{ backgroundColor: "rgb(250, 250, 250)" }}
            >
              <div className="">
                <Row>
                  <div className="gdrd-jr-car" style={{ width: "100%" }}>
                    <div className="remove-sldr" style={{ height: "100%" }}>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className="">
                          <Carousel>
                            <Carousel.Item>
                              <img
                                style={{
                                  borderRadius: "5px",
                                  // height: "1326px",
                                }}
                                className=""
                                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/sellerRegistration/seller_registration_01.jpg`)}
                                alt="Moto 360"
                              />
                            </Carousel.Item>
                            <Carousel.Item>
                              <img
                                style={{
                                  borderRadius: "5px",
                                  // height: "1326px",
                                }}
                                className=""
                                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/sellerRegistration/seller_registration_02.jpg`)}
                                alt="Moto 360"
                              />
                            </Carousel.Item>
                            <Carousel.Item>
                              <img
                                style={{
                                  borderRadius: "5px",
                                  // height: "1326px",
                                }}
                                className=""
                                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/sellerRegistration/seller_registration_03.jpg`)}
                                alt="Moto 360"
                              />
                            </Carousel.Item>
                          </Carousel>
                        </div>
                      </Col>
                    </div>
                    <div>
                      <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ height: "100%" }}
                        className="d-flex flex-column"
                      >
                        <div className="gx-card p-4 mb-0 flex-fill">
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <h5>Registration</h5>
                          </div>
                          <Card
                            style={{
                              backgroundColor: "#fafafa",
                              marginBottom: "0px",
                            }}
                          >
                            <SellerRegisterForm />
                          </Card>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SellerRegisterPage;
