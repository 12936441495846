import React from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";

const ProfileHeader = () => {
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar
                className="gx-size-90"
                alt="..."
                src={"https://via.placeholder.com/150x150"}
              />
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                Kiley Brown
              </h2>
              <p className="gx-mb-0 gx-fs-lg">Florida, USA</p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right"></div>
        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul className="gx-navbar-nav">
              <Link to="/profile/account">
                <span className="gx-link">Profile</span>
              </Link>
              <Link style={{ marginLeft: "10px" }} to="/profile/wishlist">
                <span className="gx-link">Wishlist</span>
              </Link>
              <Link style={{ marginLeft: "10px" }} to="/profile/chat">
                <span className="gx-link">Chat</span>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
