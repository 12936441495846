import React from "react";
import { Layout, Card, Row, Col } from "antd";
import {
  Configure,
  connectHits,
  connectStateResults,
  InstantSearch,
  Pagination,
} from "react-instantsearch-dom";
import "instantsearch.css/themes/algolia.css";
import WishlistCardList from "../WishlistCardList/WishlistCardList";
import AlgoliaFooter from "../../../components/Common/AlgoliaFooter/AlgoliaFooter";
import AlgoliaSearch from "../../../components/Common/Algolia/AlgoliaSearch";
import { initAlgoliaClient } from "../../../services/algolia.service";

const { Content } = Layout;

const WishlistContainer = (props) => {
  return (
    <InstantSearch
      className="gx-main-content"
      indexName={props.wishlistIndex}
      searchState={props.searchState}
      createURL={props.createURL}
      searchClient={initAlgoliaClient(props.wishlistAPIKey)}
      onSearchStateChange={props.onSearchStateChange}
    >
      <Configure hitsPerPage={5} />

      <Layout className="gx-algolia-layout-has-sider">
        <Card
          className="gx-card wish-top-pdng"
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <h5 className="mb-3 mt-2 ml-2">Wishlist</h5>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="float-right wish-tag-jr">
                <AlgoliaSearch />
              </div>
            </Col>
          </Row>

          <Content>
            <CustomResults />
          </Content>
          <AlgoliaFooter>
            <Pagination showLast={true} />
          </AlgoliaFooter>
        </Card>
      </Layout>
    </InstantSearch>
  );
};

const CustomResults = connectStateResults(({ searchState, searchResult }) => {
  if (searchResult && searchResult.nbHits === 0) {
    return (
      <div className="gx-algolia-content-inner">
        <div className="gx-algolia-no-results">
          No results found matching
          <span className="gx-algolia-query">{searchState.query}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="gx-algolia-content-inner">
        <ConnectedProducts />
      </div>
    );
  }
});

const ConnectedProducts = connectHits(WishlistCardList);

export default WishlistContainer;
