import React from "react";
import { createLogic } from "redux-logic";
import { NotificationManager } from "react-notifications";
import { reset } from "redux-form";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../../util/EndPoints";
import API from "../../../../util/HTTPClient";
import history from "../../../../_helpers/history";
import IntlMessages from "util/IntlMessages";

const changeUserActiveStatus = createLogic({
  type: types.CHANGE_USER_ACTIVE_STATUS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Patch(endPoints.USERS + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.userManagement.service.updateUserStatus.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.userManagement.service.updateUserStatus.success.title" />
        );
        dispatch(actions.changeUserActiveStatusSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.userManagement.service.updateUserStatus.fail.content" />
        );
        dispatch(
          actions.changeUserActiveStatusFail({
            title: (
              <IntlMessages id="notification.userManagement.service.updateUserStatus.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const createUser = createLogic({
  type: types.CREATE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(endPoints.USERS, action.payload.userDto)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.userManagement.service.createUser.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.userManagement.service.createUser.success.title" />
        );
        dispatch(actions.createUserSuccess(data));
        dispatch(reset(action.payload.form));
        setTimeout(function () {
          history.push(
            `/seller/dashboard/userManagement/${action.payload.type}`
          );
        }, 3000);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.userManagement.service.createUser.fail.content" />
        );
        dispatch(
          actions.createUserFail({
            title: (
              <IntlMessages id="notification.userManagement.service.createUser.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getUser = createLogic({
  type: types.GET_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.USERS + `/${action.payload.user_id}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getUserSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.getUserFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});
const updateUser = createLogic({
  type: types.UPDATE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Put(
      endPoints.USERS + `/${action.payload.id}`,
      action.payload.userDto
    )
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.userManagement.service.updateUser.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.userManagement.service.updateUser.success.title" />
        );
        dispatch(actions.updateUserSuccess(data));
        dispatch(reset(action.payload.form));
        setTimeout(function () {
          history.push(
            `/seller/dashboard/userManagement/${action.payload.type}`
          );
        }, 3000);
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.userManagement.service.updateUser.fail.content" />
        );

        dispatch(
          actions.updateUserFail({
            title: (
              <IntlMessages id="notification.userManagement.service.updateUser.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const deleteUser = createLogic({
  type: types.DELETE_USER,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Delete(endPoints.USERS + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = (data && data.message) || (
          <IntlMessages id="notification.userManagement.service.deleteUser.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.userManagement.service.deleteUser.success.title" />
        );
        dispatch(actions.deleteUserSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.userManagement.service.deleteUser.fail.content" />
        );

        dispatch(
          actions.deleteUserFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const getUserCount = createLogic({
  type: types.GET_USER_COUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.USERS_COUNT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getUserCountSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );

        dispatch(
          actions.getUserCountFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [
  changeUserActiveStatus,
  createUser,
  getUser,
  updateUser,
  deleteUser,
  getUserCount,
];
