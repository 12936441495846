import React, { Component } from "react";
import { Result, Button, Card } from "antd";
import { withRouter } from "react-router-dom";

class SomethingWentWrongPage extends Component {
  homeHandler = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div
        className="gx-container"
        style={{ paddingTop: "10vh", paddingBottom: "7vh" }}
      >
        <Card className="mb-0">
          <Result
            status="warning"
            title="There are some problems with your operation."
            extra={
              <Button type="primary" key="console" onClick={this.homeHandler}>
                Home
              </Button>
            }
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(SomethingWentWrongPage);
