import React from "react";
import { Avatar, Popover } from "antd";
import { signOut } from "../../../services/auth.service";
import { Link } from "react-router-dom";

const UserInfo = ({ userJson }) => {
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link style={{color:"#545454"}} to="/seller/dashboard/profile/general/selleraccount">Business Profile</Link> </li>
      <li><Link style={{color:"#545454"}} to="/">Back to Buyer</Link> </li>
      <li onClick={() => signOut()}>Logout</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        src={userJson && userJson.image}
        className="gx-avatar gx-pointer"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;
