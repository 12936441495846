import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Button, Card, Form, Input, DatePicker, Select } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";

const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const AInputField = makeField(Input);
const ARangePickerField = makeField(RangePicker);
const ASelectField = makeField(Select);

class SellerReviewSearchbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "searchbar",
    };
  }
  componentDidMount() {
    // To disabled submit button at the beginning.
    // this.props.form.validateFields();
  }

  handleSubmit = () => {
    alert("Searching....");
  };

  handleRangePicker = (dates, dateStrings) => {
    
    
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card className="gx-card">
        <Form layout="inline" onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            component={ASelectField}
            formItemLayout={formItemLayout}
            style={{ width: 200 }}
            placeholder="Select a person"
            optionFilterProp="children"
            // hasFeedback    
          >
            <Option value="a">1</Option>
            <Option value="b">2</Option>
            <Option value="c">3</Option>
            <Option value="d">4</Option>
            <Option value="e">5</Option>
          </Field>

          <Field
            component={ASelectField}
            formItemLayout={formItemLayout}
            style={{ width: 200 }}
            placeholder="Select a person"
            optionFilterProp="children"
            // hasFeedback
          >
            <Option value="a">Replied</Option>
            <Option value="b">Not Replied</Option>
          </Field>

          <Field
            formItemLayout={formItemLayout}
            name="dateRange"
            component={ARangePickerField}
            ranges={{
              Today: [moment(), moment()],
              "This Month": [moment(), moment().endOf("month")],
            }}
            onChange={this.handleRangePicker}
            hasFeedback
          />
          <Field
            formItemLayout={formItemLayout}
            name="orderNumber"
            component={AInputField}
            placeholder="Order Number"
            hasFeedback
          />

          <FormItem>
            <Button
              className="gx-mb-0"
              type="primary"
              htmlType="submit"
              //   disabled={hasErrors(getFieldsError())}
            >
              Search
            </Button>
            <Button
              className="gx-mb-0"
              // type="primary"
              // htmlType="submit"
              //   disabled={hasErrors(getFieldsError())}
            >
              Clear All
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (
    !values.productName &&
    !values.productBrandName &&
    (!values.productBrandName || !values.productName)
  ) {
    errors.productBrandName = "Product Name or Brand Name required";
    errors.productName = "Product Name or Brand Name required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    signinUser: state.AuthStore.signinUser,
    signupUser: state.AuthStore.signupUser,
    currentUser: state.AuthStore.currentUser,
  };
};

export default withRouter(
  reduxForm({
    form: "SellerReviewSearchbar_form",
    validate,
  })(connect(mapStateToProps, null)(SellerReviewSearchbar))
);
