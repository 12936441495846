import React from "react";
import { Layout } from "antd";
import history from "../_helpers/history";
import Sidebar from "../containers/Sidebar";
import CheckoutHeader from "../components/Common/Header/CheckoutHeader/CheckoutHeader";
import SiteFooter from "../components/Buyer/FooterComponent/SiteFooter";

const { Content } = Layout;

const CheckoutLayout = (ViewComponent) => {
  return class extends React.Component {
    componentDidMount() {
      // getter
      const user = localStorage.getItem("user");
      if (!user) {
        history.push(`/login?redirect_to=/checkout`);
      }
    }
    render() {
      return (
        <Layout className="gx-app-layout">
          <Sidebar />
          <Layout>
            <CheckoutHeader />
            <Content>
              <React.Fragment>
                <ViewComponent />
              </React.Fragment>
              <SiteFooter />
            </Content>
          </Layout>
        </Layout>
      );
    }
  };
};

export default CheckoutLayout;
