const action_header = "dashboardProfile/";

// Types
export default {
  GET_SELLER_DETAILS: action_header + "GET_SELLER_DETAILS",
  GET_SELLER_DETAILS_SUCCESS: action_header + "GET_SELLER_DETAILS_SUCCESS",
  GET_SELLER_DETAILS_FAIL: action_header + "GET_SELLER_DETAILS_FAIL",

  UPDATE_SELLER_BUSINESS_INFO: action_header + "UPDATE_SELLER_BUSINESS_INFO",
  UPDATE_SELLER_BUSINESS_INFO_SUCCESS:
    action_header + "UPDATE_SELLER_BUSINESS_INFO_SUCCESS",
  UPDATE_SELLER_BUSINESS_INFO_FAIL:
    action_header + "UPDATE_SELLER_BUSINESS_INFO_FAIL",

  UPDATE_SELLER_INFO: action_header + "UPDATE_SELLER_INFO",
  UPDATE_SELLER_INFO_SUCCESS: action_header + "UPDATE_SELLER_INFO_SUCCESS",
  UPDATE_SELLER_INFO_FAIL: action_header + "UPDATE_SELLER_INFO_FAIL",

  UPDATE_SELLER_PAYMENT_INFO: action_header + "UPDATE_SELLER_PAYMENT_INFO",
  UPDATE_SELLER_PAYMENT_INFO_SUCCESS:
    action_header + "UPDATE_SELLER_PAYMENT_INFO_SUCCESS",
  UPDATE_SELLER_PAYMENT_INFO_FAIL:
    action_header + "UPDATE_SELLER_PAYMENT_INFO_FAIL",
};
