import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const SellerProfile = ({ product, visitStoreHandler, contactHandler }) => {
  const { data } = product.data;
  return (
    <React.Fragment>
      <h6 className="mb-3">
        <i className="fas fa-user"></i> Seller Information
      </h6>

      {/* <img
        className="p-2 gx-card mb-2"
        alt="example"
        src={data && data.seller && data.seller.image}
      /> */}
      <div className="top-slr-crd-bdy pb-0">
        <div className="">
          Name :
          <span style={{ color: "#8a8a8a" }}>
            {data && data.seller && data.seller.name}
          </span>
        </div>
        <div>
          Join Date :
          <span style={{ color: "#8a8a8a" }}>
            {data && data.seller && moment(data.seller.joined_at).format("ll")}
          </span>
        </div>
        <div>
          Rating Count :{" "}
          <span style={{ color: "#8a8a8a" }}>
            {data && data.seller && data.seller.ratings_count}
          </span>
        </div>
        <div>
          Rating Value :{" "}
          <span style={{ color: "#8a8a8a" }}>
            {data && data.seller && data.seller.ratings_value}
          </span>
        </div>

        <div className="mt-2">
          <Link
            className="crd-fnt-jr"
            type="primary"
            block
            onClick={visitStoreHandler}
          >
            {/* <i class="fas fa-store-alt mr-1" style={{ fontSize: "12px" }}></i> */}
            Vist Store <i className="fad fa-link mr-2"></i>
          </Link>
          <span className="mr-2">|</span>
          <Link
            className="crd-fnt-jr"
            type="primary"
            block
            onClick={() =>
              contactHandler(data && data.seller && data.seller.id)
            }
          >
            {/* <i class="fas fa-phone-alt mr-1" style={{ fontSize: "12px" }}></i> */}
            Contact <i className="fad fa-link"></i>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SellerProfile;
