const action_header = "dashboard/";

// Types
export default {
  GET_SELLER_STATISTICS: action_header + "GET_SELLER_STATISTICS",
  GET_SELLER_STATISTICS_SUCCESS:
    action_header + "GET_SELLER_STATISTICS_SUCCESS",
  GET_SELLER_STATISTICS_FAIL: action_header + "GET_SELLER_STATISTICS_FAIL",

  GET_SELLER_REVENUE: action_header + "GET_SELLER_REVENUE",
  GET_SELLER_REVENUE_SUCCESS: action_header + "GET_SELLER_REVENUE_SUCCESS",
  GET_SELLER_REVENUE_FAIL: action_header + "GET_SELLER_REVENUE_FAIL",

  GET_SELLER_POPULAR_PRODUCTS: action_header + "GET_SELLER_POPULAR_PRODUCTS",
  GET_SELLER_POPULAR_PRODUCTS_SUCCESS:
    action_header + "GET_SELLER_POPULAR_PRODUCTS_SUCCESS",
  GET_SELLER_POPULAR_PRODUCTS_FAIL:
    action_header + "GET_SELLER_POPULAR_PRODUCTS_FAIL",

  GET_SELLER_ORDERS: action_header + "GET_SELLER_ORDERS",
  GET_SELLER_ORDERS_SUCCESS: action_header + "GET_SELLER_ORDERS_SUCCESS",
  GET_SELLER_ORDERS_FAIL: action_header + "GET_SELLER_ORDERS_FAIL",

  GET_SELLER_ACTIVITIES: action_header + "GET_SELLER_ACTIVITIES",
  GET_SELLER_ACTIVITIES_SUCCESS:
    action_header + "GET_SELLER_ACTIVITIES_SUCCESS",
  GET_SELLER_ACTIVITIES_FAIL: action_header + "GET_SELLER_ACTIVITIES_FAIL",
};
