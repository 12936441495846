import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class CategaryDatail extends Component {
  render() {
    const { data, path } = this.props;
    return (
      <li
        className={`has-megamenu ${path.includes(data.name) ? "active" : null}`}
      >
        <Link to={`/search/${data.name}`}>{data.name}</Link>

        <ul className="mega-menu">
          <li>
            <span className="mega-menu-title">Top Categories</span>
            <ul className="sub-menu" style={{ marginTop: "20px" }}>
              {data.sub_categories.map((category, index) => (
                <li className="col-md-6" key={index}>
                  <Link to={`/search/${data.name}/${category.name}`}>
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li style={{ width: "50%" }}>
            <Link to={`/search/${data.name}`}>
              <img
                className="d-block"
                style={{ marginTop: "40px" }}
                alt="img"
                src={data.banner}
              />
            </Link>
          </li>
        </ul>
      </li>
    );
  }
}

export default withRouter(CategaryDatail);
