import React from "react";
import { Radio } from "antd";
import { withRouter } from "react-router-dom";
import { userRoles } from "../../../../constants/Utilities";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const UserSelections = ({ history, userRole, userCount, type }) => {
  function onChange(e) {
    history.push(`/seller/dashboard/userManagement/${e.target.value}`);
  }
  let usersCount = userCount && userCount.data && userCount.data.data && userCount.data.data
  return (
    <div className="mb-4">
      <RadioGroup onChange={onChange} defaultValue={type}>
        <RadioButton className="mb-0" value="sellers">
          Sellers {usersCount && `(${usersCount.sellers_count})`}
        </RadioButton>
        <RadioButton className="mb-0" value="buyers">
          Buyers {usersCount && `(${usersCount.buyers_count})`}
        </RadioButton>
        {userRole === userRoles.Admin && (
          <React.Fragment>
            <RadioButton className="mb-0" value="moderators">
              Moderators {usersCount && `(${usersCount.moderators_count})`}
            </RadioButton>
            <RadioButton className="mb-0" value="admin">
              Admin {usersCount && `(${usersCount.admins_count})`}
            </RadioButton>
          </React.Fragment>
        )}
      </RadioGroup>
    </div>
  );
};

export default withRouter(UserSelections);
