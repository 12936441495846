const home_header = "home/";

// Types
export default {
  GET_PRODUCTS: home_header + "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: home_header + "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILED: home_header + "GET_PRODUCTS_FAILED",

  GET_LATEST_PRODUCT_CAROUSEL: home_header + "GET_LATEST_PRODUCT_CAROUSEL",
  GET_LATEST_PRODUCT_CAROUSEL_SUCCESS:
    home_header + "GET_LATEST_PRODUCT_CAROUSEL_SUCCESS",
  GET_LATEST_PRODUCT_CAROUSEL_FAILED:
    home_header + "GET_LATEST_PRODUCT_CAROUSEL_FAILED",

  GET_POPULAR_PRODUCT_CAROUSEL: home_header + "GET_POPULAR_PRODUCT_CAROUSEL",
  GET_POPULAR_PRODUCT_CAROUSEL_SUCCESS:
    home_header + "GET_POPULAR_PRODUCT_CAROUSEL_SUCCESS",
  GET_POPULAR_PRODUCT_CAROUSEL_FAILED:
    home_header + "GET_POPULAR_PRODUCT_CAROUSEL_FAILED",

  GET_DISCOUNTED_PRODUCTS: home_header + "GET_DISCOUNTED_PRODUCTS",
  GET_DISCOUNTED_PRODUCTS_SUCCESS:
    home_header + "GET_DISCOUNTED_PRODUCTS_SUCCESS",
  GET_DISCOUNTED_PRODUCTS_FAILED:
    home_header + "GET_DISCOUNTED_PRODUCTS_FAILED",

  GET_TOP_SELLERS: home_header + "GET_TOP_SELLERS",
  GET_TOP_SELLERS_SUCCESS: home_header + "GET_TOP_SELLERS_SUCCESS",
  GET_TOP_SELLERS_FAILED: home_header + "GET_TOP_SELLERS_FAILED",

  GET_ALL_CATEGORIES: home_header + "GET_ALL_CATEGORIES",
  GET_ALL_CATEGORIES_SUCCESS: home_header + "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILED: home_header + "GET_ALL_CATEGORIES_FAILED",

  GET_POPULAR_CATEGORIES: home_header + "GET_POPULAR_CATEGORIES",
  GET_POPULAR_CATEGORIES_SUCCESS: home_header + "GET_POPULAR_CATEGORIES_SUCCESS",
  GET_POPULAR_CATEGORIES_FAILED: home_header + "GET_POPULAR_CATEGORIES_FAILED",

  GET_TOP_BRANDS: home_header + "GET_TOP_BRANDS",
  GET_TOP_BRANDS_SUCCESS: home_header + "GET_TOP_BRANDS_SUCCESS",
  GET_TOP_BRANDS_FAILED: home_header + "GET_TOP_BRANDS_FAILED",
};
