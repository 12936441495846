import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class OnlineAdvertisingPolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Online Advertising Policy" />
          <CSBreadcrumb label={"Online Advertising Policy"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              <font face="Carlito, serif">
                <b>
                  To help maintain the integrity of Tradesbarn advertising,
                  please review and accept our non-discrimination policy.
                </b>
              </font>
            </p>
            <p>
              Tradesbarn's Advertising Policies prohibit advertisers from using
              our ads products to discriminate against individuals or groups of
              people.
              <b>Ads are discriminatory when they deny opportunities</b> to
              individuals or groups of people based on certain personal
              attributes such as race, ethnicity, national origin, religion,
              age, sex, sexual orientation, gender identity, family/marital
              status, disability or medical or genetic condition.
            </p>
            <p>
              Our non-discrimination policy hasn't changed, but we've added
              examples about what it means for advertisers. While discrimination
              is prohibited, you may target ads to a specific audience based on
              known interests that align with the product or service you're
              advertising.
            </p>
            <table width={692} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={348} />
                <col width={343} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td style={{ margin: "20px" }} width={330} height={64}>
                    <p>
                      <font face="Carlito, serif">
                        <b>Acceptable Ad Targeting</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for a job at a clothing store to all
                      individuals of eligible working age, regardless of gender
                    </p>
                  </td>
                  <td width={343}>
                    <p>
                      <font face="Carlito, serif">
                        <b>Ad Discrimination</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for a job at a clothing store in a way to
                      exclude women and discourage them from applying
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <b>
              <u>OurNon-Discrimination Policy</u>
            </b>
            <p>
              <br />
            </p>
            <p>
              <font face="Carlito, serif">
                <b>Policy</b>
              </font>
            </p>
            <p>
              Ads must not discriminate or encourage discrimination against
              people based on personal attributes such as race, ethnicity,
              color, national origin, religion, age, sex, sexual orientation,
              gender identity, family status, and disability, medical or genetic
              condition.
            </p>
            <p>
              Tradesbarn prohibits advertisers from using our ads products to
              discriminate against people. This means that advertisers may not
              (1) use our audience selection tools to (a) wrongfully target
              specific groups of people for advertising (see Advertising Policy
              7.1 on Targeting), or (b) wrongfully exclude specific groups of
              people from seeing their ads; or (2) include discriminatory
              content in their ads. Advertisers are also required to comply with
              applicable laws that prohibit discrimination (see Advertising
              Policy on Illegal Products or Services). These include laws that
              prohibit discriminating against groups of people in connection
              with, for example, offers of housing, employment, and credit.
            </p>
            <ul>
              <li>
                <p>
                  <a href="http://www.hud.gov/">
                    <font color="#0462c1">
                      <u>www.hud.gov</u>
                    </font>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="http://www.eeoc.gov/">
                    <font color="#0462c1">
                      <u>http://www.eeoc.gov</u>
                    </font>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.consumerfinance.gov/">
                    <font color="#0462c1">
                      <u>www.consumerfinance.gov</u>q
                    </font>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.aclu.org/">
                    <font color="#0462c1">
                      <u>www.aclu.org/</u>
                    </font>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://civilrights.org/">
                    <font color="#0462c1">
                      <u>www.civilrights.org</u>
                    </font>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://www.justice.gov/">
                    <font color="#0462c1">
                      <u>www.justice.gov</u>
                    </font>
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <a href="https://fairhousingresourcecenter.wordpress.com/">
                    <font color="#0462c1">
                      <u>www.fairhousingresourcecenter.wordpress.com/</u>
                    </font>
                  </a>
                </p>
              </li>
            </ul>
            <p>
              Disclaimer: This guide is not a substitute for legal advice.
              Consult a legal professional for specific advice about your
              situation.
            </p>
            <b>
              <u>Tradesbarn Advertising Policies</u>
            </b>
            <p>
              <br />
            </p>
            <p>Specific considerations for</p>
            <p>U.S. Housing, Employment and Credit Advertisers</p>
            <p>
              While we are asking all advertisers to review and accept our
              non-discrimination policy, it's especially important for
              advertisers running housing, employment or credit ads.
            </p>
            <p>
              Opportunities presented in these types of ads must be inclusive
              and extended to all groups of people, regardless of certain
              personal attributes like those listed above. Many locations have
              laws that specifically prohibit discrimination in these
              categories.
            </p>
            <table width={723} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={347} />
                <col width={376} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={347} height={272}>
                    <p>
                      <font face="Carlito, serif">
                        <b>Acceptable Ad Targeting</b>
                      </font>
                    </p>
                    <p>
                      <font face="Carlito, serif">
                        <b>Housing Targeting</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for an apartment rental to people who live
                      or work in the general area around the location of the
                      listing
                    </p>
                    <p>
                      <font face="Carlito, serif">
                        <b>Employment Targeting</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for a job as a salesperson to people
                      interested in sales and marketing
                    </p>
                    <p>
                      <font face="Carlito, serif">
                        <b>Credit Targeting</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for a credit card to all individuals of
                      eligible age
                    </p>
                  </td>
                  <td width={376}>
                    <p>
                      <font face="Carlito, serif">
                        <b>Ad Discrimination</b>
                      </font>
                    </p>
                    <p>
                      <font face="Carlito, serif">
                        <b>Housing Discrimination</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for an apartment rental that excludes
                      certain ZIP codes with the intent to deny it to people of
                      a certain race
                    </p>
                    <p>
                      <font face="Carlito, serif">
                        <b>Employment Discrimination</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for a job as a salesperson in a way
                      intended to exclude women
                    </p>
                    <p>
                      <font face="Carlito, serif">
                        <b>Credit Discrimination</b>
                      </font>
                    </p>
                    <p>
                      Targeting an ad for a credit card that attempts to exclude
                      individuals of a specific national origin
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              While some of these examples are specific to U.S. advertisers,
              Tradesbarn's non-discrimination policy is a global policy. Since
              non-discrimination laws vary by region, be sure to comply with our
              policy and relevant laws in your location and the location you're
              targeting.
            </p>
            <ol>
              <li>
                <p>Community Standards</p>
                <b>Policy</b>
              </li>
            </ol>
            <p>Ads must not violate our Community Standards.</p>
            <ol start={2}>
              <li>
                <p>Illegal Products or Services</p>
              </li>
            </ol>
            <b>Policy</b>
            <p>
              Ads must not constitute, facilitate, or promote illegal products,
              services or activities. Ads targeted to minors must not promote
              products, services, or content that are inappropriate, illegal, or
              unsafe, or that exploit, mislead, or exert undue pressure on the
              age groups targeted
            </p>
            <ol start={3}>
              <li>
                <p>Tobacco Products</p>
              </li>
            </ol>
            <b>Policy</b>
            <p>
              Ads must not promote the sale or use of tobacco products and
              related paraphernalia.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>Blogs or groups connecting people with</p>
            <table width={608} cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr valign="TOP">
                  <td width={298} height={115}>
                    <p>
                      tobacco-related interests, as long as the service does not
                      lead to the sale of tobacco or tobacco-related products
                    </p>
                    <p>
                      Anti-smoking campaigns and e-books, counseling services
                      for smoking addiction, and smoking rehabilitation programs
                      or facilities
                    </p>
                    <p>
                      Tobacco or tobacco-related products, including cigarettes,
                      cigars, chewing tobacco, tobacco pipes, hookahs, hookah
                      lounges, rolling papers, vaporized delivery devices and
                      electronic cigarettes
                    </p>
                  </td>
                  <p>
                    <br />
                  </p>

                  <td width={298} height={100}>
                    <p>"Buy cigarettes and e-cigarettes here today!"</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <center>
              <table width={608} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr valign="TOP">
                    <td width={310}>
                      <p>
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={298} height={185}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy1.png")}
                        />
                        <p>
                          This image is compliant because, it promotes an
                          anti-smoking campaign.
                        </p>
                      </div>
                    </td>
                    <td width={310}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy2.jpg")}
                        />
                        <p>
                          This image promotes an e-cigarette and is non-
                          compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={298} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy3.jpg")}
                        />
                        <p>
                          This image shows hookah pipe imagery and is
                          non-compliant.
                        </p>
                      </div>
                    </td>
                    <td width={310}>
                      <p>
                        <br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
            <ol start={4}>
              <li>
                <p>Drugs &amp; Drug-Related Products</p>
              </li>
            </ol>
            <b>Policy</b>
            <p>
              Ads must not promote the sale or use of illegal, prescription, or
              recreational drugs.
            </p>
            <b>Examples</b>
            <p>
              Drug-related paraphernalia, such as bongs, rolling papers, and
              vaporized delivery devices
            </p>
            <p />
            <center>
              <table dir="LTR" width={588} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy4.jpg")}
                        />
                        <p>
                          Avoid using images of smoking-related accessories
                          (like bongs and rolling papers)
                        </p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy5.jpg")}
                        />
                        <p>
                          Avoid using images of either recreational or medical
                          marijuana.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy6.jpg")}
                        />
                        <p>
                          Avoid using images that imply the use of a
                          recreational drug.
                        </p>
                      </div>
                    </td>
                    <td width={299}></td>
                  </tr>
                </tbody>
              </table>
            </center>
            <br />
            <p />
            <ol start={5}>
              <li>
                <p>Unsafe Supplements</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not promote the sale or use of unsafe supplements, as
              determined by Tradesbarn in its sole discretion.
            </p>
            <b>Examples</b>
            <p>
              Unsafe supplements, including but not limited to anabolic
              steroids, chitosan, comfrey, dehydroepiandrosterone, ephedra, and
              human growth hormones.
            </p>
            <ol start={6}>
              <li>
                <p>Weapons, Ammunition, or Explosives Policy</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not promote the sale or use of weapons, ammunition, or
              explosives. This includes ads for weapon modification accessories.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>
              Blogs or groups connecting people with weapon-related interests,
              as long as the service doesn't lead to the sale of these products
            </p>
            <p>
              Safety courses for firearm training or licenses, and books and
              videos about firearm safety Plastic guns, swords and toy weapons
            </p>
            <p>
              Mounted flashlights for firearms (must set ad audience minimum age
              to 18 years old or over) Scopes and sights for firearms (must set
              ad audience minimum age to 18 years old or over)
            </p>
            <p>
              Hunting, self-defense, and military clothing and gear such as
              shooting targets and clay throwers (must set ad audience minimum
              age to 18 years old or over)
            </p>
            <p>
              Holsters and belt accessories (must set ad audience minimum age to
              18 years old or over)
            </p>
            <p>
              Gun safes, mounts (including bipods), gun cases, and slings (must
              set ad audience minimum age to 18 years old or over)
            </p>
            <p>
              Equipment and protective clothing (including vests) (must set ad
              audience minimum age to 18 years old or over)
            </p>
            <p>
              Paint, coatings or wraps for weapons and magazines (must set ad
              audience minimum age to 18 years old or over) NO Firearms,
              including firearms parts, ammunition, paintball guns and bb guns
            </p>
            <p>NO Firearm silencers or suppressors</p>
            <p>
              NO Weapons of any kind, including pepper spray, non-culinary
              knives/blades/spears, Tasers, nun chucks, batons, or weapons
              intended for self-defense
            </p>
            <p>NO Fireworks and explosives</p>
            <p>NO Ads promoting the brandishing of firearms</p>
            <ol start={7}>
              <li>
                <p>Adult Products or Services Policy</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not promote the sale or use of adult products or
              services, except for ads for family planning and contraception.
              Ads for contraceptives must focus on the contraceptive features of
              the product, and not on sexual pleasure or sexual enhancement, and
              must be targeted to people 18 years or older.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>
              "Free condoms at your local student health center." This example
              text is compliant, as long as it's targeted to people 18 years or
              older.
            </p>
            <p>
              "Practice safe sex with our brand of condoms.” This text is
              compliant, as long as it's targeted to people 18 years or older.
            </p>
            <p>NO “Condoms to enhance your pleasure."</p>
            <p>
              NO “Use our gel to dramatically improve your sex life.” NO “Buy
              our sex toys for your adult pleasure.”
            </p>
            <p>
              NO Sex toys, or products focused explicitly on sexual pleasure.
            </p>
            <ol start={8}>
              <li>
                <p>Adult Content</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain adult content. This includes nudity,
              depictions of people in explicit or suggestive positions, or
              activities that are overly suggestive or sexually provocative.
            </p>
            <p>Sexually Suggestive Content</p>
            <p>
              <b>Examples</b>
            </p>
            <p>Nudity or implied nudity</p>
            <p>
              Excessive visible skin or cleavage, even if not explicitly sexual
              in nature
            </p>
            <p>
              Images focused on individual body parts, such as abs, buttocks or
              chest, even if not explicitly sexual in nature
            </p>
            <center>
              <table dir="LTR" width={588} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy7.png")}
                        />
                        <p>
                          This image shows artistic implied nudity and is
                          non-compliant.
                        </p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy8.png")}
                        />
                        <p>
                          This image shows a woman in a sexually suggestive pose
                          and is non-compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy9.jpg")}
                        />
                        <p
                          style={{
                            marginLeft: "0.14in",
                            marginRight: "0.51in",
                          }}
                        >
                          This image shows someone in a sexually suggestive pose
                          and is non-compliant.
                        </p>
                      </div>
                    </td>
                    <td width={299}></td>
                  </tr>
                </tbody>
              </table>
            </center>
            <p>Sexually Explicit Content</p>
            <p>
              <b>Examples</b>
            </p>
            <center>
              <table width={609} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr valign="TOP">
                    <p>
                      Content portraying excessive nudity or alluding to sexual
                      activity
                    </p>
                  </tr>
                  <tr valign="TOP">
                    <td width={292} height={188}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy10.png")}
                        />
                        <p>
                          This image is sexually provocative and non- compliant.
                        </p>
                      </div>
                    </td>
                    <td width={317}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy11.jpg")}
                        />
                        <p>
                          This image alludes to sexual activity and is non-
                          compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={292} height={117}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy12.jpg")}
                        />
                        <p>
                          This image is sexual in nature and non- compliant.
                        </p>
                      </div>
                    </td>
                    <td width={317}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy13.jpg")}
                        />
                        <p>
                          This image shows nudity in a statue and is compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
            <ol start={9}>
              <li>
                <p>Third-Party Infringement Policy</p>
              </li>
            </ol>
            <p>
              <b>policy</b>
            </p>
            <p>
              Ads must not contain content that infringes upon or violates the
              rights of any third party, including copyright, trademark,
              privacy, publicity, or other personal or proprietary rights. To
              report content that you feel may infringe upon or violate your
              rights, please visit our Intellectual Property
            </p>
            <b>
              <br />
            </b>
            <b>
              <u>Intellectual Property</u>
            </b>
            <p>
              Tradesbarn is committed to helping people and organizations
              protect their intellectual property rights. The Tradesbarn
              Statement of Rights and Responsibilities does not allow posting
              content that violates someone else’s intellectual property rights,
              including copyright and trademark.
            </p>
            <b>
              <u>Copyright</u>
            </b>
            <p>
              Copyright is a legal right that seeks to protect original works of
              authorship (ex: books, music, film, art). Generally, copyright
              protects original expression such as words or images. It does not
              protect facts and ideas, although it may protect the original
              words or images used to describe an idea. Copyright also doesn’t
              protect things like names, titles and slogans; however, another
              legal right called a trademark might protect those. Learn more
              about reporting copyright violations.
            </p>
            <b>
              <u>Trademark</u>
            </b>
            <p>
              A trademark is a word, slogan, symbol or design (example: brand
              name, logo) that distinguishes the products or services offered by
              one person, group or company from another. Generally, trademark
              law seeks to prevent confusion among consumers about who provides
              or is affiliated with a product or service. Learn more about
              reporting trademark violations.)
            </p>
            <ol start={10}>
              <li>
                <p>Sensational Content</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain shocking, sensational, disrespectful or
              excessively violent content.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <center>
              <table width={590} cellPadding={0} cellSpacing={0}>
                <colgroup>
                  <col width={299} />
                  <col width={291} />
                </colgroup>
                <tbody>
                  <tr valign="TOP">
                    <td width={299} height={26}>
                      <p>Images that may shock or scare viewers</p>
                    </td>
                    <td width={291}>
                      <p>
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={299} height={38}>
                      <p>Images that are scary, gory or sensational</p>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={299} height={100}>
                      <p>Ads depicting violence or threats of violence</p>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={299} height={108}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy14.jpg")}
                        />
                        <p>
                          This image of a car crash is shocking and non-
                          compliant.
                        </p>
                      </div>
                    </td>
                    <td width={291}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy15.jpg")}
                        />
                        <p>
                          This image shows a gun pointing toward the viewer and
                          is non-compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
            <ol start={11}>
              <li>
                <p>Personal Attributes</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain content that asserts or implies personal
              attributes. This includes direct or indirect assertions or
              implications about a person’s race, ethnic origin, religion,
              beliefs, age, sexual orientation or practices, gender identity,
              disability, medical condition (including physical or mental
              health), financial status, membership in a trade union, criminal
              record, or name.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>
              Ad creative that describes or shows the promoted product or
              service
            </p>
            <p>
              Text referencing or or alluding to personal attributes or
              characteristics of the targeted group or individual
            </p>
            <p>
              Using the word "other" to reference a personal characteristic Race
            </p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>“Meet Hispanic men online now!”</p>
                  </td>
                  <td width={291}>
                    <p> “Meet other black singles near you!”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“Find black singles today.”</p>
                  </td>
                  <td width={291}>
                    <p>
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Religion or Philosophical Beliefs</p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>“Date Christian singles!”</p>
                  </td>
                  <td width={291}>
                    <p>“Are you Christian?”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“Looking for Buddhists near you?”</p>
                  </td>
                  <td width={291}>
                    <p>“Meet other Buddhists”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“Meet Hindu singles online now!”</p>
                  </td>
                  <td width={291}>
                    <p>
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Age</p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>"Meet Seniors"</p>
                  </td>
                  <td width={291}>
                    <p>“Meet other Seniors”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“A service for teens”</p>
                  </td>
                  <td width={291}>
                    <p>"Car insurance for 18-24 year olds"</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Sexual Orientation or Sexual Behavior</p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>“Atlantis Gay Cruises”</p>
                  </td>
                  <td width={291}>
                    <p>“Are you gay?”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“Gay Dance Clubs"</p>
                  </td>
                  <td width={291}>
                    <p>“Meet other lesbians now!”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“Gay dating online now!”</p>
                  </td>
                  <td width={291}>
                    <p>
                      <br />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Gender Identity</p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>“Come meet transsexual singles”</p>
                  </td>
                  <td width={291}>
                    <p>“Questioning your gender identity?”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“A monthly meetup for trans persons and trans allies”</p>
                  </td>
                  <td width={291}>
                    <p>“Meet other trans women at our monthly meetup”</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Disability or Medical Condition</p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>“Bulimia counseling available”</p>
                  </td>
                  <td width={291}>
                    <p>“Do you have diabetes?”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“Depression counseling”</p>
                  </td>
                  <td width={291}>
                    <p>“Depression getting you down? Get help now.”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={299} height={38}>
                    <p>“New diabetes treatment available”</p>
                  </td>
                  <td width={291}>
                    <p>“Treat your anxiety with these helpful meditations.”</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Financial Status or Information</p>
            <p>
              <b>Examples</b>
            </p>
            <table width={590} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={299} />
                <col width={291} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={299} height={26}>
                    <p>
                      “We have financial services to cover every financial
                      need.”
                    </p>
                  </td>
                  <td width={291}>
                    <p>“Broke? Bankrupt? Check out our services.”</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Membership in a Trade Union</p>
            <p>
              <b>Examples</b>
            </p>
            <div id="Section3" dir="LTR">
              <table width={590} cellPadding={0} cellSpacing={0}>
                <colgroup>
                  <col width={299} />
                  <col width={291} />
                </colgroup>
                <tbody>
                  <tr valign="TOP">
                    <td width={299} height={26}>
                      <p>"Be a part of our Union today"</p>
                    </td>
                    <td width={291}>
                      <p>“Dislike your Union rep? Join our union today.”</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>Criminal Record</p>
              <p>
                <b>Examples</b>
              </p>
              <table width={590} cellPadding={0} cellSpacing={0}>
                <colgroup>
                  <col width={299} />
                  <col width={291} />
                </colgroup>
                <tbody>
                  <tr valign="TOP">
                    <td width={299} height={26}>
                      <p>"Services to clean up any previous offenses"</p>
                    </td>
                    <td width={291}>
                      <p>"Are you a convicted felon?"</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p> Name</p>
              <p>
                <b>Examples</b>
              </p>
              <table width={590} cellPadding={0} cellSpacing={0}>
                <colgroup>
                  <col width={299} />
                  <col width={291} />
                </colgroup>
                <tbody>
                  <tr valign="TOP">
                    <td width={299} height={26}>
                      <p>
                        “We print customizable t-shirts and stickers with your
                        name.”
                      </p>
                    </td>
                    <td width={291}>
                      <p>
                        “Billy Taylor, get this t-shirt with your name in
                        print!”
                      </p>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={299} height={26}>
                      <p>“It's a Billy thing” (if their name is Billy).</p>
                    </td>
                    <td width={291}>
                      <p>
                        <br />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ol start={12}>
                <li>
                  <p>Misleading or False Content</p>
                </li>
              </ol>
              <p>Policy</p>
              <p>
                Ads, landing pages, and business practices must not contain
                deceptive, false, or misleading content, including deceptive
                claims, offers, or methods.
              </p>
              <p>
                <b>Examples</b>
              </p>
              <p>“Learn to Lose Belly Fat.” “Tips to lose weight”</p>
              <p>“Tips to lose weight”</p>
              <p>“Earn profits every 2 weeks”</p>
              <p>“3 Shocking Tips to Lose All Your Belly Fat”</p>
              <p>"1 crazy tip to lose weight!"</p>
            </div>
            <p>“Earn 15% profits every 2 weeks”</p>
            <p>“You won't believe what you can eat to cure diabetes.”</p>
            <p>Exaggerated claims, including exaggerated "tips" or "tricks"</p>
            <p>Claims of unrealistic results within specific timeframes</p>
            <p>Claims of cures for uncorrectable diseases</p>
            <p>
              False or misleading claims about product attributes, quality, or
              functionality
            </p>
            <p>
              Setting confusing or misleading expectations for delivery times
            </p>
            <p>False or misleading claims about return processes</p>
            <p>False or misleading claims about the services provided</p>
            <ol start={13}>
              <li>
                <p>Controversial Content</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain content that exploits controversial political
              or social issues for commercial purposes
            </p>
            <ol start={14}>
              <li>
                <p>Non-Functional Landing Page</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not direct people to non-functional landing pages. This
              includes landing page content that interferes with a person’s
              ability to navigate away from the page.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>Error pages or sites that are under construction</p>
            <p>Landing pages that are PDF or JPEG files</p>
            <p>
              Landing pages that don't match the product or service promoted in
              the ad
            </p>
            <p>
              Landing pages that automatically download files to a person's
              computer
            </p>
            <p>
              Landing pages that require you to download additional programs or
              software to access the content on the page
            </p>
            <p>Landing pages with a low Web of Trust rating</p>
            <p>Directing people to a closed or secret Tradesbarn group</p>
            <p>
              Sending people to{" "}
              <a href="http://www.facebook.com/">
                <font color="#0462c1">
                  <u>www.Tradesbarn.com</u>
                </font>
              </a>
            </p>
            <ol start={15}>
              <li>
                <p>Surveillance Equipment Policy</p>
              </li>
            </ol>
            <p>
              Ads may not promote the sale of spy cams, mobile phone trackers or
              other hidden surveillance equipment.
            </p>
            <ol start={16}>
              <li>
                <p>Grammar &amp; Profanity</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain profanity or bad grammar and punctuation.
              Symbols, numbers and letters must be used properly without the
              intention of circumventing our ad review process or other
              enforcement systems.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <table width={594} cellPadding={0} cellSpacing={0}>
              <colgroup>
                <col width={304} />
                <col width={290} />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width={304} height={46}>
                    <p>
                      Correct grammar and punctuation in the text you use for
                      your ad.
                    </p>
                  </td>
                  <td width={290}>
                    <p>Excessive symbols, characters, or punctuation</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={304} height={57}>
                    <p>“Click here for more information!”</p>
                  </td>
                  <td width={290}>
                    <p>
                      Misused capitalization, like using all capital letters in
                      your ad's text.
                    </p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={304} height={56}>
                    <p>
                      Profane language, including profanity that is partially
                      obscured by asterisks or symbols
                    </p>
                  </td>
                  <td width={290}>
                    <p>“Get the best f*&amp;%ing t-shirts here.”</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={304} height={59}>
                    <p>
                      “♧♥♡♢♢ Buy Now{" "}
                      <font face="Noto Sans Symbols, serif">♔♕♚♛⚜</font>”
                    </p>
                  </td>
                  <td width={290}>
                    <p>
                      “Tired of shitty cell service? Switch to a provider with
                      better coverage.”
                    </p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width={304}>
                    <p>Why won’t you clîck mé?</p>
                  </td>
                  <td width={290}>
                    <p>Buy Ga.rcin-ia Camb,ogia here</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <center>
              <table>
                <tbody>
                  <tr valign="TOP">
                    <td width={304}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy16.jpg")}
                        />
                        <p>
                          This image replaces letters for symbols to portray
                          profanity and is non-compliant.
                        </p>
                      </div>
                    </td>
                    <td width={290}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy17.jpg")}
                        />
                        <p>
                          This image shows a person making a profane gesture and
                          is non-compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
            <ol start={17}>
              <li>
                <p>Nonexistent Functionality</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain images that portray nonexistent
              functionality.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>
              Button imagery in ad images that accurately show where you'll be
              taken, like a{" "}
              <font face="Carlito, serif">
                <b>Shop Now</b>
              </font>
              button on an ad directing people to a shopping site
            </p>
            <p>
              Non-existent functionality on ad images, such as play buttons or
              notification jewels, or checkboxes
            </p>
            <p>Ads emulating Tradesbarn's features or functionality</p>
            <img
              alt="img-online-advertisements"
              width={623}
              height={168}
              className="d-block mx-auto"
              src={require("assets/policy/policy18.jpg")}
            />
            <p>This image of a video "play" button is non-compliant.</p>
            <ol start={18}>
              <li>
                <p>Policy</p>
              </li>
            </ol>
            <p>
              <b>Examples</b>
            </p>
            <p>
              Ads must not contain "before-and-after" images or images that
              contain unexpected or unlikely results. Ad content must not imply
              or attempt to generate negative self-perception in order to
              promote diet, weight loss, or other health related products.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <center>
              <table dir="LTR" width={588} cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr valign="TOP">
                    <td width={289}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <p>Images including full or non-zoomed figures</p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <p>Ad images focusing on zoomed body parts</p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={289}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy19.jpg")}
                        />
                        <p>
                          This image of a person lifting up their shirt is
                          zoomed out and compliant.
                        </p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy20.jpg")}
                        />
                        <p>
                          This image of a person's abs is zoomed in and
                          non-compliant.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy21.png")}
                        />
                        <p>
                          This image focuses on physical fitness and is
                          compliant.
                        </p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy22.jpg")}
                        />
                        <p>
                          This image implies weight loss and would be
                          non-compliant if used to promote a diet or weight loss
                          product.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy23.png")}
                        />
                        <p>
                          This image promotes healthy eating habits and is
                          compliant.
                        </p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy24.png")}
                        />
                        <p>
                          This image of a person on a scale would be
                          non-compliant, if used to promote a diet or weight
                          loss product.
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr valign="TOP">
                    <td width={289} height={112}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy18.jpg")}
                        />
                        <p>
                          This image of a person drinking vegetable juice is
                          compliant.
                        </p>
                      </div>
                    </td>
                    <td width={299}>
                      <div
                        style={{ marginLeft: "0.13in", marginRight: "0.13in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy25.png")}
                        />
                        <p>
                          This is a non-compliant image of a person before and
                          after weight loss.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </center>
            <center>
              <table width={590} cellPadding={0} cellSpacing={0}>
                <colgroup>
                  <col width={299} />
                  <col width={291} />
                </colgroup>
                <tbody>
                  <tr valign="TOP">
                    <td width={299} height={108}>
                      <div
                        style={{ marginLeft: "0.14in", marginRight: "0.22in" }}
                      >
                        <img
                          alt="img-online-advertisements"
                          width={623}
                          height={168}
                          className="d-block mx-auto"
                          src={require("assets/policy/policy26.jpg")}
                        />
                        <p>
                          This is a non-compliant image of a person before and
                          after hair growth treatment.
                        </p>
                      </div>
                    </td>
                    <td width={291}></td>
                  </tr>
                </tbody>
              </table>
            </center>

            <ol start={19}>
              <li>
                <p>Payday Loans, Paycheck Advances, and Bail Bonds</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads may not promote payday loans, paycheck advances, bail bonds,
              or any short-term loans intended to cover someone's expenses until
              their next payday.
            </p>
            <ol start={20}>
              <li>
                <p>Multilevel Marketing</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads promoting income opportunities must fully describe the
              associated product or business model, and must not promote
              business models offering quick compensation for little investment,
              including multilevel marketing opportunities.
            </p>
            <p>Examples</p>
            <p>"Get-rich-quick" schemes</p>
            <ol start={21}>
              <li>
                <p>Penny Auctions</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads may not promote penny auctions, bidding fee auctions, or other
              similar business models.
            </p>
            <ol start={22}>
              <li>
                <p>Counterfeit Documents</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads may not promote fake documents, such as counterfeit degrees,
              passports or immigration papers.
            </p>
            <ol start={23}>
              <li>
                <p>Low Quality or Disruptive Content</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain content leading to external landing pages
              that provide an unexpected or disruptive experience. This includes
              misleading ad positioning, such as overly sensationalized
              headlines or prompts for users to in authentically interact with
              the ad, and leading people to landing pages that contain minimal
              original content and a majority of unrelated or low quality ad
              content. For more information on what we consider low quality,
              visit our Ads Help Center.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>
              Link to landing pages that include significant and original
              content that is relevant to your ad
            </p>
            <p>
              Minimize text that blocks or prevents people from viewing the
              original text on your landing page Display entire article on the
              primary landing page
            </p>
            <p>
              NO Use ad images that are excessively cropped or require people to
              click the ad to view the full image
            </p>
            <p>
              NO Include deceptive or exaggerated ad copy that incentivizes
              people to click on your ad
            </p>
            <p>
              NO Feature sexually suggestive or shocking content on your landing
              page
            </p>
            <p>NO Display malicious or deceptive ads on your landing page</p>
            <p>
              NO Have a high ratio of ads relative to content on your landing
              page
            </p>
            <p>
              NO Use popup ads, interstitial ads or other ad formats that
              disrupt the user experience on your landing page
            </p>
            <ol start={24}>
              <li>
                <p>Spyware or Malware</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain spyware, malware, or any software that
              results in an unexpected or deceptive experience. This includes
              links to sites containing these products.
            </p>
            <ol start={25}>
              <li>
                <p>Automatic Animation</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not contain audio or flash animation that plays
              automatically without a person's interaction or expands within
              Tradesbarn after someone clicks on the ad.
            </p>
            <ol start={26}>
              <li>
                <p>Unauthorized Streaming Devices</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not promote products or items that facilitate or
              encourage unauthorized access to digital media.
            </p>
            <ol start={27}>
              <li>
                <p>Circumventing Systems</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not use tactics intended to circumvent our ad review
              process or other enforcement systems. This includes techniques
              that attempt to disguise the ad's content or destination page.
            </p>
            <p>
              <b>Examples</b>
            </p>
            <p>
              NO Use Unicode characters or symbols in ad text with the intent to
              obfuscate words or phrases
            </p>
            <p>NO Restrict Tradesbarn’s access to an ad's destination page</p>
            <p>
              NO Attempt to create new advertising accounts after we enforce on
              existing accounts for violations of our policies
            </p>
            <ol start={28}>
              <li>
                <p>Prohibited Financial Products and Services</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>
              Ads must not promote financial products and services that are
              frequently associated with misleading or deceptive promotional
              practices.
            </p>
            <p>Not Allowed:</p>
            <p>Initial Coin Offerings</p>
            <p>Binary Options</p>
            <ol start={29}>
              <li>
                <p>Sale of Body Parts</p>
              </li>
            </ol>
            <p>
              <b>Policy</b>
            </p>
            <p>Ads must not promote the sale of human body parts or fluids</p>
          </div>
        </Widget>
      </div>
    );
  }
}

export default OnlineAdvertisingPolicy;
