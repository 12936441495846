import React, { Component } from "react";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { ANDROID_APP, IOS_APP } from "../../../constants";
import appModImg from "../../../assets/img/mobmodal.jpg";

class MobileAppAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      visible: false,
    };
  }

  componentWillMount() {
    let width = window.screen.width;
    if (width < 1000) {
      setTimeout(() => {
        this.setState({
          width: width,
          visible: true,
        });
      }, 500);
    }
  }

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const { visible } = this.state;
    const titleIcon = (
      <span>
        <i className="fad fa-mobile mr-1"></i> Get Mobile App
      </span>
    );

    return (
      <div>
        <Modal
          title={titleIcon}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div style={{ textAlign: "center" }}>
            <p>
              Want to feel the happiness of a unique shopping experience, Try
              tradesbarns mobile app
            </p>

            <img src={appModImg} alt="" style={{ borderRadius: "5px" }} />

            <div className="mt-4">
              <Button className="gx-btn-outline-warning">
                <Link to={{ pathname: ANDROID_APP }} target="_blank">
                  <i className="fad fa-download mr-1"></i>
                  Android
                </Link>
              </Button>
              <Button className="gx-btn-outline-warning">
                <Link to={{ pathname: IOS_APP }} target="_blank">
                  <i className="fad fa-download mr-1"></i>
                  IOS
                </Link>
              </Button>
              {/* <Button className="gx-btn-outline-warning">
                <Link to={{ pathname: APP_GALLERY }} target="_blank">
                  <i className="fad fa-download mr-1"></i>
                  App Gallery
                </Link>
              </Button> */}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MobileAppAlert;
