import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { userManagementActions } from "./ducks";
import UserSelections from "../../../components/Seller/UserManagement/UserSelections/UserSelections";
import SellerDetailsForm from "./SellerDetailsForm";
import BuyerDetailsForm from "./BuyerDetailsForm";
import ModeratorDetailsForm from "./ModeratorDetailsForm";
import AdminDetailsForm from "./AdminDetailsForm";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: null,
      type: null,
    };
  }
  componentDidMount() {
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const userRole = userObj.role;
    const { params } = this.props.match;
    const type = params && params.type;

    this.props.userManagementActions.getUserCount();
    this.setState({
      userRole,
      type,
    });
  }
  render() {
    const { userCount, match } = this.props;
    const { userRole } = this.state;
    const { params } = match;
    const type = params && params.type;

    return (
      <div>
        <div style={{ paddingBottom: "20px" }}>
          <h3>User Management</h3>
        </div>
        {/* User selections */}
        <UserSelections userRole={userRole} userCount={userCount} type={type} />

        {/* Details Form */}
        {type === "sellers" ? (
          <SellerDetailsForm userRole={userRole} />
        ) : type === "buyers" ? (
          <BuyerDetailsForm userRole={userRole} />
        ) : type === "moderators" ? (
          <ModeratorDetailsForm />
        ) : type === "admin" ? (
          <AdminDetailsForm />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    getUser: state.UserManagement.getUser,
    userCount: state.UserManagement.userCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
