import React from "react";

function BuyerProtection() {
  return (
    <div>
      <img
        className=""
        style={{ width: "100%", borderRadius: "5px" }}
        alt="example"
        src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/policies/buyer_protection.jpeg`)}
      />
    </div>
  );
}

export default BuyerProtection;
