// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getProductCount: createAction(types.GET_PRODUCTS_COUNT),
  getProductCountSuccess: createAction(types.GET_PRODUCTS_COUNT_SUCCESS),
  getProductCountFail: createAction(types.GET_PRODUCTS_COUNT_FAIL),

  createProduct: createAction(types.CREATE_PRODUCT),
  createProductSuccess: createAction(types.CREATE_PRODUCT_SUCCESS),
  createProductFail: createAction(types.CREATE_PRODUCT_FAIL),

  clearCreateProduct: createAction(types.CLEAR_CREATE_PRODUCT),

  getProduct: createAction(types.GET_PRODUCT),
  getProductSuccess: createAction(types.GET_PRODUCT_SUCCESS),
  getProductFail: createAction(types.GET_PRODUCT_FAIL),

  updateProduct: createAction(types.UPDATE_PRODUCT),
  updateProductSuccess: createAction(types.UPDATE_PRODUCT_SUCCESS),
  updateProductFail: createAction(types.UPDATE_PRODUCT_FAIL),

  deleteProduct: createAction(types.DELETE_PRODUCT),
  deleteProductSuccess: createAction(types.DELETE_PRODUCT_SUCCESS),
  deleteProductFail: createAction(types.DELETE_PRODUCT_FAIL),

  clearDeleteProductData: createAction(types.CLEAR_DELETE_PRODUCT_DATA),

  changeProductActiveStatus: createAction(types.CHANGE_PRODUCT_ACTIVE_STATUS),
  changeProductActiveStatusSuccess: createAction(
    types.CHANGE_PRODUCT_ACTIVE_STATUS_SUCCESS
  ),
  changeProductActiveStatusFail: createAction(
    types.CHANGE_PRODUCT_ACTIVE_STATUS_FAIL
  ),

  clearProductActiveStatusData: createAction(
    types.CLEAR_PRODUCT_ACTIVE_STATUS_DATA
  ),


  getProductCategories: createAction(types.GET_PRODUCT_CATEGORIES),
  getProductCategoriesSuccess: createAction(
    types.GET_PRODUCT_CATEGORIES_SUCCESS
  ),
  getProductCategoriesFail: createAction(types.GET_PRODUCT_CATEGORIES_FAIL),

  replyQuestion: createAction(types.REPLY_QUESTION),
  replyQuestionSuccess: createAction(types.REPLY_QUESTION_SUCCESS),
  replyQuestionFail: createAction(types.REPLY_QUESTION_FAIL),

  handleProductImages: createAction(types.HANDLE_PRODUCT_IMAGES),
  clearProductImages: createAction(types.CLEAR_PRODUCT_IMAGES),

  removeProductImages: createAction(types.REMOVE_PRODUCT_IMAGES),

  handleVariationsImages: createAction(types.HANDLE_VARIATIONS_IMAGES),
  removeVariationsImages: createAction(types.REMOVE_VARIATIONS_IMAGES),

  handleVariationTable: createAction(types.HANDLE_VARIATION_TABLE),

  replyToQnA: createAction(types.REPLY_TO_QNA),
  replyToQnASuccess: createAction(types.REPLY_TO_QNA_SUCCESS),
  replyToQnAFail: createAction(types.REPLY_TO_QNA_FAIL),

  deleteQnA: createAction(types.DELETE_QNA),
  deleteQnASuccess: createAction(types.DELETE_QNA_SUCCESS),
  deleteQnAFail: createAction(types.DELETE_QNA_FAIL),

  deleteQnAReply: createAction(types.DELETE_QNA_REPLY),
  deleteQnAReplySuccess: createAction(types.DELETE_QNA_REPLY_SUCCESS),
  deleteQnAReplyFail: createAction(types.DELETE_QNA_REPLY_FAIL),

  getShippingMethod: createAction(types.GET_SHIPPING_METHOD),
  getShippingMethodSuccess: createAction(types.GET_SHIPPING_METHOD_SUCCESS),
  getShippingMethodFail: createAction(types.GET_SHIPPING_METHOD_FAILED),
};
