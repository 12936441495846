import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReportItemForm from "../../../components/Buyer/ReturnItemForm/ReturnItemForm";
import reportItemsActions from "../../ResolutionCenter/ducks";

class DashboardResolutionCenterOpenCase extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const rcr_item = localStorage.getItem("rcr_item");
    if (rcr_item) {
      const reportedItem = JSON.parse(rcr_item);
      this.setState({
        reportedItem,
      });
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    const { reportedItem } = this.state;

    return (
      <div className="mb-5">
        <h3 className="mb-4">
          Resolution Center <span></span>
        </h3>

        <div className="gx-card p-3 pl-4 mb-3">
          <Link
            className="crd-fnt-jr"
            to="/seller/dashboard/resolution-center/all"
          >
            Resolution Center
          </Link>
          {" > "}
          <Link
            className="crd-fnt-jr"
            to="/seller/dashboard/resolution-center/item/list"
          >
            Recent Orders
          </Link>
          {" > "}Open Dispute
        </div>

        <ReportItemForm data={reportedItem} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportItem: state.ResolutionCenter.reportItem,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    reportItemsActions: bindActionCreators(reportItemsActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardResolutionCenterOpenCase)
);
