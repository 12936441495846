import React from "react";
import { Card, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import { Divider } from "antd";
import "./MybarnHeader.less";
import { userRoles } from "../../../constants/Utilities";

const MybarnHeader = ({ currentUser, profileDetails, history, role }) => {
  var await_payment_count =
    profileDetails &&
    profileDetails.data &&
    profileDetails.data.data &&
    profileDetails.data.data.await_payment_count
      ? profileDetails.data.data.await_payment_count
      : 0;
  var await_shipment_count =
    profileDetails &&
    profileDetails.data &&
    profileDetails.data.data &&
    profileDetails.data.data.await_shipment_count
      ? profileDetails.data.data.await_shipment_count
      : 0;
  var await_feedback_count =
    profileDetails &&
    profileDetails.data &&
    profileDetails.data.data &&
    profileDetails.data.data.await_feedback_count
      ? profileDetails.data.data.await_feedback_count
      : 0;
  var await_delivery_count =
    profileDetails &&
    profileDetails.data &&
    profileDetails.data.data &&
    profileDetails.data.data.await_delivery_count
      ? profileDetails.data.data.await_delivery_count
      : 0;
  var all_order_count =
    profileDetails &&
    profileDetails.data &&
    profileDetails.data.data &&
    profileDetails.data.data.all_order_count
      ? profileDetails.data.data.all_order_count
      : 0;
  var disputes_count =
    profileDetails &&
    profileDetails.data &&
    profileDetails.data.data &&
    profileDetails.data.data.disputes_count
      ? profileDetails.data.data.disputes_count
      : 0;

  return (
    <>
      <Card
        className="gx-card shww-in-web"
        style={{ backgroundColor: "rgb(250, 250, 250)" }}
      >
        <div className="gx-card p-4 mb-3">
          <div className="gx-user-list pb-0">
            <img
              alt="avatar"
              className="gx-avatar-img gx-avatar-img-lg gx-border-1 mb-0 ml-4"
              src={
                currentUser && currentUser.photoURL
                  ? currentUser.photoURL
                  : require("assets/images/avatar/temp_avatar.png")
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = require("assets/images/avatar/temp_avatar.png");
              }}
            />
            {/* <div className="gx-description"> */}
            <div className="">
              <h3 className="mt-3">{currentUser && currentUser.displayName}</h3>
              <div>
                Email Verification :&nbsp;
                <span className="fnshed-clr">
                  {currentUser && currentUser.emailVerified
                    ? "Verified"
                    : "None"}
                </span>
              </div>
            </div>
          </div>

          {role === userRoles.Buyer ? (
            <React.Fragment>
              <Divider className="mt-4" />
              <div className="mt-3" style={{ textAlign: "center" }}>
                <Button
                  style={{ width: "200px" }}
                  type="primary"
                  className="gx-mb-0"
                  onClick={() => history.push("/account/seller")}
                >
                  {/* <IntlMessages id="app.userAuth.signup" /> */}
                  <i className="fad fa-user-plus"></i>&nbsp; Become a Seller
                </Button>
              </div>
            </React.Fragment>
          ) : null}
        </div>

        <div className="gx-card p-3 mb-0">
          <div className="gx-profile-banner-top-right">
            <ul style={{ flexWrap: "inherit" }} className="gx-follower-list">
              <li>
                <div className="mb-1">
                  <i className="fad fa-boxes fa-2x inprogs-clr"></i>
                </div>
                <span className="gx-fs-sm">All Orders</span>
                <div className="gx-follower-title new-fnt-count gx-font-weight-medium">
                  {all_order_count}
                </div>
              </li>
              <li>
                <div className="mb-1">
                  <i className="fad fa-money-check fa-2x inprogs-clr "></i>
                </div>
                <span className="gx-fs-sm">Awaiting Payment</span>
                <div className="gx-follower-title new-fnt-count gx-font-weight-medium">
                  {await_payment_count}
                </div>
              </li>
              <li>
                <div className="mb-1">
                  <i className="fad fa-ship fa-2x inprogs-clr"></i>
                </div>
                <span className="gx-fs-sm">Awaiting Shipment</span>
                <div className="gx-follower-title new-fnt-count gx-font-weight-medium">
                  {await_shipment_count}
                </div>
              </li>
              <li>
                <div className="mb-1">
                  <i className="fad fa-shipping-timed fa-2x inprogs-clr"></i>
                </div>
                <span className="gx-fs-sm">Awaiting delivery</span>
                <div className="gx-follower-title new-fnt-count gx-font-weight-medium">
                  {await_delivery_count}
                </div>
              </li>
              <li>
                <div className="mb-1">
                  <i className="fad fa-comment-alt-dots fa-2x inprogs-clr"></i>
                </div>
                <span className="gx-fs-sm">Awaiting Feedback</span>
                <div className="gx-follower-title new-fnt-count gx-font-weight-medium">
                  {await_feedback_count}
                </div>
              </li>
              <li>
                <div className="mb-1">
                  <i className="fad fa-exclamation-circle fa-2x inprogs-clr"></i>
                </div>
                <span className="gx-fs-sm">Disputes</span>
                <div className="gx-follower-title new-fnt-count gx-font-weight-medium">
                  {disputes_count}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Card>

      <Card
        className="gx-card shww-in-mob"
        style={{ backgroundColor: "rgb(250, 250, 250)" }}
      >
        <div className="gx-card p-4 mb-3">
          <div className=" pb-0">
            <div className="" style={{ textAlign: "center" }}>
              <img
                alt="avatar"
                className="gx-avatar-img gx-avatar-img-lg gx-border-1 mb-0"
                src={
                  currentUser && currentUser.photoURL
                    ? currentUser.photoURL
                    : require("assets/images/avatar/temp_avatar.png")
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require("assets/images/avatar/temp_avatar.png");
                }}
              />
            </div>

            {/* <div className="gx-description"> */}
            <div className="" style={{ textAlign: "center" }}>
              <h3 className="mt-3">{currentUser && currentUser.displayName}</h3>
              <div>
                Email Verification :&nbsp;
                <span className="fnshed-clr">
                  {currentUser && currentUser.emailVerified
                    ? "Verified"
                    : "None"}
                </span>
              </div>
            </div>
          </div>
          {role === userRoles.Buyer ? (
            <React.Fragment>
              <Divider className="mt-4" />
              <div className="mt-3" style={{ textAlign: "center" }}>
                <Button
                  style={{ width: "200px" }}
                  type="primary"
                  className="gx-mb-0"
                  onClick={() => history.push("/account/seller")}
                >
                  {/* <IntlMessages id="app.userAuth.signup" /> */}
                  <i className="fad fa-user-plus"></i>&nbsp; Become a Seller
                </Button>
              </div>
            </React.Fragment>
          ) : null}
        </div>

        {/* <Divider /> */}
        <div className="gx-card p-3">
          <table className="table table-bordered mb-0">
            <tr>
              <td>
                <i className="fad fa-boxes"></i>&nbsp;&nbsp;
                <span className="gx-fs-sm">All Orders</span>
              </td>
              <td>
                <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                  {all_order_count}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i className="fad fa-money-check"></i>&nbsp;&nbsp;
                <span className="gx-fs-sm">Awaiting Payment</span>
              </td>
              <td>
                <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                  {await_payment_count}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i className="fad fa-ship"></i>&nbsp;&nbsp;
                <span className="gx-fs-sm">Awaiting Shipment</span>
              </td>
              <td>
                <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                  {await_shipment_count}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i className="fad fa-shipping-timed"></i>&nbsp;&nbsp;
                <span className="gx-fs-sm">Awaiting delivery</span>
              </td>
              <td>
                <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                  {await_delivery_count}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i className="fad fa-comment-alt-dots"></i>&nbsp;&nbsp;
                <span className="gx-fs-sm">Awaiting Feedback</span>
              </td>
              <td>
                <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                  {await_feedback_count}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i className="fad fa-exclamation-circle"></i>&nbsp;&nbsp;
                <span className="gx-fs-sm">Disputes</span>
              </td>
              <td>
                <span className="gx-follower-title gx-fs-lg gx-font-weight-medium">
                  {disputes_count}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </Card>

      <Card
        className="gx-card"
        style={{ backgroundColor: "rgb(250, 250, 250)" }}
      >
        <div className="gx-card p-4 mb-0">
          <img
            src={require("assets/images/appmybrn.jpg")}
            width="100%"
            alt=""
          />
          <Link to="/mobileApp">
            <div className="qr-code-area"></div>
          </Link>
        </div>
      </Card>
    </>
  );
};

export default withRouter(MybarnHeader);
