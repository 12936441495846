import React, { Component } from "react";
import HelpContactForm from "../../components/HelpAndContact/HelpAndContact";
import { Card } from "antd";

export class HelpPage extends Component {
  render() {
    return (
      <div className="gx-container" style={{ marginTop: "30px" }}>
        <Card
          className="gx-card mt-4"
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          <h5 className="mb-3 ml-2">Contact Us</h5>
          <HelpContactForm />
        </Card>
      </div>
    );
  }
}

export default HelpPage;
