import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Select, Input, Col, Row, Card, Spin } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dashboardProfileActions } from "../../../ducks";
import { makeField } from "components/Common/Controls/MakeField";
import { allCountriesJson } from "../../../../../../DummyData/AllCountry";
import { sellerCategories } from "../../../../../../constants/Utilities"
import { FormItemLayout, FormItemLayout2, TailFormItemLayout } from "../../../../../../constants/Utilities";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class BusinessInfomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerDetails: null,
    };
  }

  componentDidMount() {
    const { dashboardProfileActions, sellerDetails } = this.props;
    sellerDetails.loading && dashboardProfileActions.getSellerDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { sellerDetails } = this.props;

    if (
      sellerDetails &&
      sellerDetails.data &&
      sellerDetails.data.data &&
      sellerDetails.data.data.account_info &&
      JSON.stringify(sellerDetails) !== JSON.stringify(prevState.sellerDetails)
    ) {
      var receivedObj = {
        legalName: sellerDetails.data.data.bussiness_info.legal_name,
        seller_category: sellerDetails.data.data.bussiness_info.seller_category,
        address1: sellerDetails.data.data.bussiness_info.address1,
        address2: sellerDetails.data.data.bussiness_info.address2,
        city: sellerDetails.data.data.bussiness_info.city,
        state: sellerDetails.data.data.bussiness_info.state,
        country: sellerDetails.data.data.bussiness_info.country,
        postcode: sellerDetails.data.data.bussiness_info.postal_code,
      };

      this.props.initialize(receivedObj);
      this.setState({
        sellerDetails: sellerDetails,
      });
    }
  }

  handleSubmit = (values) => {
    const { dashboardProfileActions } = this.props;

    var businessInformationDto = {
      legal_name: values && values.legalName,
      seller_category: values && values.seller_category,
      address1: values && values.address1,
      address2: values && values.address2,
      city: values && values.city,
      state: values && values.state,
      country: values && values.country,
      postal_code: values && values.postcode,
    };
    dashboardProfileActions.updateSellerBusinessInfo(businessInformationDto);
  };

  render() {
    const { handleSubmit, updateSellerBusinessInfo, sellerDetails } = this.props;

    return (
      <div className="gx-card p-3 pt-4">
        <Spin spinning={updateSellerBusinessInfo.pending || sellerDetails.loading}>
          <Form
            onSubmit={handleSubmit(this.handleSubmit)}
            className="jr-fld-catch-2"
          >
            <Card className="mb-3" style={{ backgroundColor: "rgb(250, 250, 250)" }}>
              <FormItem {...FormItemLayout} label="Legal Name / Business Owner">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="legalName"
                  component={AInputField}
                  placeholder="Legal Name / Business Owner"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Seller Category">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="seller_category"
                  component={ASelectField}
                  hasFeedback
                >
                  {sellerCategories.map((category, i) => {
                    return (
                      <Option key={i} value={category.value}>
                        {category.label}
                      </Option>
                    );
                  })}
                </Field>
              </FormItem>

              <FormItem {...FormItemLayout} label="Address 1">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="address1"
                  component={AInputField}
                  placeholder="Address 1"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Address 2">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="address2"
                  component={AInputField}
                  placeholder="Address 2"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="City / Town">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="city"
                  component={AInputField}
                  placeholder="City / Town"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="State">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="state"
                  component={AInputField}
                  placeholder="State"
                  hasFeedback
                />
              </FormItem>

              <FormItem {...FormItemLayout} label="Country / Region">
                <Field
                  formItemLayout={FormItemLayout2}
                  name="country"
                  component={ASelectField}
                  hasFeedback
                >
                  {allCountriesJson.map((country, i) => {
                    return (
                      <Option key={i} value={country.alpha_3}>
                        {country.name}
                      </Option>
                    );
                  })}
                </Field>
              </FormItem>

              <FormItem {...FormItemLayout} label="Postcode">
                <Field
                  formItemLayout={FormItemLayout2}
                  // label="First Name"
                  name="postcode"
                  component={AInputField}
                  placeholder="Postcode"
                  hasFeedback
                />
              </FormItem>
            </Card>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="float-right">
                  <FormItem {...TailFormItemLayout} style={{ marginBottom: "0" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        marginRight: "0px",
                        marginBottom: "0px",
                        width: "160px",
                      }}
                    >
                      Submit
                  </Button>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>

        </Spin>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.legalName) {
    errors.legalName = "Legal Name / Business Owner is required";
  }
  if (!values.seller_category) {
    errors.seller_category = "Seller category is required";
  }
  if (!values.address1) {
    errors.address1 = "Address line 1 is required";
  }
  if (!values.city) {
    errors.city = "City / Town is required";
  }
  if (!values.country) {
    errors.country = "Country is required";
  }
  if (!values.state) {
    errors.state = "Seller state is required";
  }
  if (!values.postcode) {
    errors.postcode = "Seller Postcode is required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    sellerDetails: state.DashboardProfile.sellerDetails,
    updateSellerBusinessInfo: state.DashboardProfile.updateSellerBusinessInfo
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dashboardProfileActions: bindActionCreators(
      dashboardProfileActions,
      dispatch
    ),
  };
}
export default withRouter(
  reduxForm({
    form: "BusinessInfomation",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(BusinessInfomation))
);

// export default BusinessInfomation;
