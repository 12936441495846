import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Input, Cascader, Card, Form, Select } from "antd";
import { FormItemLayout } from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import {
  FormItemLayout2,
  conditionTypes,
} from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";

const Option = Select.Option;
const FormItem = Form.Item;
const AInputField = makeField(Input);
const ACascaderField = makeField(Cascader);
const ASelectField = makeField(Select);

class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      combinationsArray: props.productVariations,
    };
  }
  filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };
  render() {
    const { productCategories, categoryChangeHandler } = this.props;
    return (
      <div className="jr-fld-catch mb-3">
        <h6 className="mb-3">Basic Information</h6>
        <Card style={{ backgroundColor: "#fafafa" }}>
          <FormItem {...FormItemLayout} label="Product Name " required>
            <Field
              formItemLayout={FormItemLayout2}
              name="productName"
              component={AInputField}
              placeholder="Product name"
              hasFeedback
              // suffix={<ExtraInfo title={extraInfoDetails.productName} />}
            />
            <span
              style={{
                position: "absolute",
                right: "10px",
                bottom: "-37px",
              }}
            >
              <ExtraInfo title={extraInfoDetails.productName} />
            </span>
          </FormItem>

          <FormItem {...FormItemLayout} label="Category" required>
            <Field
              formItemLayout={FormItemLayout2}
              component={ACascaderField}
              options={productCategories.data && productCategories.data.data}
              onChange={categoryChangeHandler}
              // suffix={<ExtraInfo title={extraInfoDetails.productCategory} />}
              name="productCategory"
              placeholder="Product Category"
              expandTrigger="hover"
              showSearch={this.filter}
              hasFeedback
              className="cascader-catcher"
            />
            <span
              style={{
                position: "absolute",
                right: "10px",
                bottom: "-37px",
              }}
            >
              <ExtraInfo title={extraInfoDetails.productCategory} />
            </span>
          </FormItem>

          <FormItem {...FormItemLayout} label="Product Brand ">
            <Field
              formItemLayout={FormItemLayout2}
              name="productBrand"
              component={AInputField}
              placeholder="Product Brand"
              hasFeedback
            />
            <span
              style={{
                position: "absolute",
                right: "10px",
                bottom: "-37px",
              }}
            >
              <ExtraInfo title={extraInfoDetails.productBrand} />
            </span>
          </FormItem>

          <FormItem
            {...FormItemLayout}
            label="Product Condition"
            required
            id="productCondition"
          >
            <Field
              formItemLayout={FormItemLayout}
              name={"productCondition"}
              component={ASelectField}
              style={{ width: "100%" }}
              placeholder="Product Condition"
              hasFeedback
            >
              {conditionTypes.map((returnType, i) => {
                return (
                  <Option key={i} value={returnType.value}>
                    {returnType.label}
                  </Option>
                );
              })}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.productCondition} />
            </span>
          </FormItem>
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
BasicInfo = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(BasicInfo);

// You have to connect() to any reducers that you wish to connect to yourself
BasicInfo = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(BasicInfo);

export default BasicInfo;
//399
