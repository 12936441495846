import React from "react";
import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "../../../../util/EndPoints";
import API from "../../../../util/HTTPClient";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";

const getOrdersCount = createLogic({
  type: types.GET_ORDERS_COUNT,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.ORDERS_COUNT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getOrdersCountSuccess(data));
      })
      .catch((err) => {
        var errorMessage = "Failed to get regions";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }
        dispatch(
          actions.getOrdersCountFail({
            title: "Error!",
            message: errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

const buyShippingLabel = createLogic({
  type: types.BUY_SHIPPING_LABEL,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_SHIPPING_BUY_LABEL + `/${action.payload.id}`)
      .then((resp) => resp.data)
      .then((data) => {
        const successMessage = data.message || (
          <IntlMessages id="notification.orderManagement.service.getShippingLabel.success.content" />
        );
        NotificationManager.success(
          successMessage,
          <IntlMessages id="notification.orderManagement.service.getShippingLabel.success.title" />
        );
        dispatch(actions.buyShippingLabelSuccess(data));
      })
      .catch((err) => {
        let errMessage = err?.response?.data?.message || (
          <IntlMessages id="notification.orderManagement.service.getShippingLabel.fail.title" />
        );

        dispatch(
          actions.buyShippingLabelFail({
            title: (
              <IntlMessages id="notification.orderManagement.service.getShippingLabel.fail.title" />
            ),
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const exportOrders = createLogic({
  type: types.EXPORT_ORDERS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.ORDERS_EXPORT)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.exportOrdersSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err &&
          err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.exportOrdersFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

const orderTracking = createLogic({
  type: types.ORDER_TRACKING,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(
      endPoints.DASHBOARD_ORDER_TRACKING + `/${action.payload.orderId}`,
      action.payload.orderForm
    )
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.orderTrackingSuccess(data));
      })
      .catch((err) => {
        let errMessage = (err &&
          err.response &&
          err.response.data &&
          err.response.data.message) || (
          <IntlMessages id="notification.error.somethingwentwrong" />
        );
        dispatch(
          actions.orderTrackingFail({
            title: "Error!",
            message: errMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [getOrdersCount, exportOrders, buyShippingLabel, orderTracking];
