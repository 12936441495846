import React from "react";

export const taskList = [
  {
    id: 1,
    title: "Make the home page dynamic",
    completed: false,
    user: {
      projectName: "Chatbull",
      avatar: "https://via.placeholder.com/150x150",
    },
    dueDate: "Tomorrow",
    tags: [1, 2],
  },
  {
    id: 2,
    title: "Design wireframes based on the requirements",
    completed: false,
    user: {
      projectName: "Wieldy",
      avatar: "https://via.placeholder.com/150x150",
    },
    dueDate: "Today",
    tags: [2, 4],
  },
  {
    id: 3,
    title: "Need to define microinteraction on click of signin button",
    completed: false,
    user: {
      projectName: "Jumbo",
      avatar: "https://via.placeholder.com/150x150",
    },
    dueDate: "21 Jul",
    tags: [1, 2, 3],
  },
  {
    id: 4,
    title: "New page need to be designed for property listing",
    completed: false,
    user: {
      projectName: "Wieldy",
      avatar: "https://via.placeholder.com/150x150",
    },
    dueDate: "23 Jul",
    tags: [2, 3, 4],
  },
  {
    id: 5,
    title: "Design wireframes based on the requirements",
    completed: false,
    user: {
      projectName: "Wieldy",
      avatar: "https://via.placeholder.com/150x150",
    },
    dueDate: "1 Aug",
    tags: [2, 4],
  },
];

export const recentActivity = [
  {
    id: 1,
    day: "Today",
    tasks: [
      {
        id: 1,
        name: "Mila Alba",
        title: " Mila Alba  Albama’s House",
        avatar: "",
        imageList: [],
      },
      {
        id: 2,
        name: "Bob Builder",
        title: "Callback request from  Bob Builder Dimitri House ",
        avatar: "",
        imageList: [],
      },
      {
        id: 3,
        name: "Tom Moody",
        title: "Congratulations to  Tom Moody for joining 10+ club",
        avatar: "",
        imageList: [],
      },
      {
        id: 4,
        name: "Norman Dolphi",
        title: "Norman Dolphi is looking for a house in New Jersy, USA",
        avatar: "",
        imageList: [],
      },
    ],
  },
  {
    id: 2,
    day: "Yesterday",
    tasks: [
      {
        id: 5,
        name: "Kily Johns",
        title:
          "Agent  Kily Johns has added 7 new photos to the property Albama’s House",
        avatar: "",
        imageList: [
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
        ],
      },
      {
        id: 6,
        name: "Tom Moody",
        title: "Welcome to a new agent Tom Moody in the Company ",
        avatar: "",
        imageList: [],
      },
      {
        id: 7,
        name: "Oliver Shorter",
        title: "Oliver Shorter is looking for an office space in Colorado, USA",
        avatar: "",
        imageList: [],
      },
      {
        id: 8,
        name: "Mila Alba",
        title: " Mila Alba left a 5 star review on Albama’s House",
        avatar: "",
        imageList: [],
      },
    ],
  },
];

export const ticketList = [
  {
    id: 1,
    avatar: "https://via.placeholder.com/150x150",
    title: "Need a quick support on setting",
    description: [
      <span className="gx-link" key={13}>
        Joy Parish
      </span>,
      "  created ticket 15 mins ago",
    ],
    status: 2,
  },
  {
    id: 2,
    avatar: "https://via.placeholder.com/150x150",
    title: "Pre-sale query about the product",
    description: [
      <span key={14} className="gx-link">
        You
      </span>,
      " replied 2 days ago",
    ],
    status: 1,
  },
  {
    id: 3,
    avatar: "https://via.placeholder.com/150x150",
    title: "Regarding customization service",
    description: [
      <span key={15} className="gx-link">
        Joy Parish
      </span>,
      " replied 2 days ago",
    ],
    status: 4,
  },
];
export const taskStatus = [
  {
    id: 1,
    title: "Critical",
    color: "red",
  },
  {
    id: 2,
    title: "High",
    color: "orange",
  },
  {
    id: 3,
    title: "Medium",
    color: "green",
  },
  {
    id: 4,
    title: "Low",
    color: "light-grey",
  },
];
export const taskTags = [
  {
    id: 1,
    name: "HTML",
    color: "red",
  },
  {
    id: 2,
    name: "React",
    color: "green",
  },
  {
    id: 3,
    name: "JavaScript",
    color: "blue",
  },
  {
    id: 4,
    name: "CSS",
    color: "orange",
  },
];
export const siteVisit = [
  { name: "MON", this_week: 0, last_week: 0 },
  { name: "TUE", this_week: 0, last_week: 6 },
  { name: "WED", this_week: 5, last_week: 2 },
  { name: "THU", this_week: 10, last_week: 0 },
  { name: "FRI", this_week: 4, last_week: 1 },
  { name: "SAT", this_week: 16, last_week: 3 },
  { name: "SUN", this_week: 0, last_week: 1 },
];

export const totalSaleData = [
  { name: "JAN", price: 600 },
  { name: "FEB", price: 3398 },
  { name: "MAR", price: 1200 },
  { name: "APR", price: 4908 },
  { name: "MAY", price: 2908 },
];

export const totalRevenueData = [
  { name: "JAN", this_year: 60, last_year: 80 },
  { name: "FEB", this_year: 90, last_year: 60 },
  { name: "MAR", this_year: 50, last_year: 50 },
  { name: "APR", this_year: 75, last_year: 40 },
  { name: "MAY", this_year: 60, last_year: 20 },
  { name: "JUN", this_year: 85, last_year: 100 },
  { name: "JUL", this_year: 20, last_year: 60 },
  { name: "AUG", this_year: 75, last_year: 80 },
  { name: "SEP", this_year: 60, last_year: 60 },
  { name: "OCT", this_year: 40, last_year: 10 },
  { name: "NOV", this_year: 75, last_year: 5 },
  { name: "DEC", this_year: 25, last_year: 90 },
];

export const trafficData = [
  { name: "Page A", value: 0 },
  { name: "Page B", value: 2000 },
  { name: "Page C", value: 600 },
  { name: "Page D", value: 4400 },
  { name: "Page D", value: 900 },
  { name: "Page H", value: 4860 },
];

export const sales = [
  {
    month: "JAN",
    this_year: "358.25",
    last_year: "250"
  },
  {
    month: "FEB",
    this_year: "12.64",
    last_year: "145.45"
  },
  {
    month: "MAR",
    this_year: "342.12",
    last_year: "158.54"
  },
  {
    month: "APR",
    this_year: "459.3",
    last_year: "6.01"
  },
  {
    month: "MAY",
    this_year: "75.32",
    last_year: "156.02"
  },
  {
    month: "JUN",
    this_year: "45",
    last_year: "457"
  },
  {
    month: "JUL",
    this_year: "454",
    last_year: "71"
  },
  {
    month: "AUG",
    this_year: "75",
    last_year: "451"
  },
  {
    month: "SEP",
    this_year: "24",
    last_year: "78"
  },
  {
    month: "OCT",
    this_year: "41",
    last_year: "41"
  },
  {
    month: "NOV",
    this_year: "123",
    last_year: "200"
  },
  {
    month: "DEC",
    this_year: "73",
    last_year: "120"
  }
]

export const newCustomers = [
  {
    id: "46HovCuUeBYV2Nsl9IZUhL0ZnSn1",
    name: "Bhanuka Rathnayaka",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/default-user.jpg?alt=media"
  },
  {
    id: "20Mo1z6UByNox3oc2d8zgqb1oqJ3",
    name: "Udara Wanasinghe",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/Seller%2F20Mo1z6UByNox3oc2d8zgqb1oqJ3%2Fd55017f0-641c-11eb-acea-db9ecc7eefe8.jpg?alt=media"
  },
  {
    id: "Wt0Qr5h27iYJ4O5o2t1K0SsmVTm1",
    name: "Sadheera Mahanama",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/default-user.jpg?alt=media"
  },
  {
    name: "undefined undefined",
    id: "24a5H7aLHdThg1dp6aHB1kVB4qa2",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/Seller%2F24a5H7aLHdThg1dp6aHB1kVB4qa2%2Fa53f3150-6452-11eb-978a-51f9ce89b7a3.jpg?alt=media"
  },
  {
    id: "46HovCuUeBYV2Nsl9IddsZUhL0ZnSn1",
    name: "Bhanuka Rathnayaka",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/default-user.jpg?alt=media"
  },
  {
    id: "20Mo1z6UByNoxdsd3oc2d8zgqb1oqJ3",
    name: "Udara Wanasinghe",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/Seller%2F20Mo1z6UByNox3oc2d8zgqb1oqJ3%2Fd55017f0-641c-11eb-acea-db9ecc7eefe8.jpg?alt=media"
  },
  {
    id: "Wt0Qr5h27iYdsdsJ4O5o2t1K0SsmVTm1",
    name: "Sadheera Mahanama",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/default-user.jpg?alt=media"
  },
  {
    name: "undefined undefined",
    id: "24a5H7aLHdsddThg1dp6aHB1kVB4qa2",
    image: "https://firebasestorage.googleapis.com/v0/b/tradesbarn-content/o/Seller%2F24a5H7aLHdThg1dp6aHB1kVB4qa2%2Fa53f3150-6452-11eb-978a-51f9ce89b7a3.jpg?alt=media"
  }
];

export const popularProducts = [
  {
    id: 1,
    image: "https://via.placeholder.com/575x480",
    title: "10 things you must know before trading in cryptocurrency",
    subTitle:
      "Cras tincidunt sit amet massa at accumsan. Mauris tincidunt tincidunt est, et pulvinar\n" +
      "felis pharetra in vestibulum sed.",
    desc: "BTC, Crypto, Trading, Tips, Cryptocurrency",
  },
  {
    id: 1,
    image: "https://via.placeholder.com/575x480",
    title: "Getting started with cryptocurrency - what is blockchain",
    subTitle:
      "Cras tincidunt sit amet massa at accumsan. Mauris tincidunt tincidunt est, et pulvinar\n" +
      "felis pharetra in vestibulum sed.",
    desc: "BTC, Crypto, Trading, Tips, Cryptocurrency",
  },
  {
    id: 1,
    image: "https://via.placeholder.com/575x480",
    title: "Is cryptocurrency investment a trap or opportunity?",
    subTitle:
      "Cras tincidunt sit amet massa at accumsan. Mauris tincidunt tincidunt est, et pulvinar\n" +
      "felis pharetra in vestibulum sed.",
    desc: "BTC, Crypto, Trading, Tips, Cryptocurrency",
  },
];
