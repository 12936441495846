import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { sidebarSettings } from "constants/Utilities";
import { getSidebarCategoryIcon } from "../../util/services";
import history from "../../_helpers/history";

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ data, onClose }) => {
  const { navStyle, themeType, pathname } = sidebarSettings;

  const handleSidebarRoute = (categoryName, subCategoryName) => {
    history.push(`/search/${categoryName}/${subCategoryName}`);
    onClose();
  };

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content mt-5">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>

        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "lite"}
            mode="inline"
          >
            {/* Home */}
            <Menu.Item key="/">
              <Link to="/">
                <i className="fad fa-home home-icon-pos"></i>
                Home
              </Link>
            </Menu.Item>

            {data.map((category) => {
              return (
                <SubMenu
                  key={category.name}
                  title={
                    <span>
                      <i className={getSidebarCategoryIcon(category.name)}></i>
                      <span>{category.name}</span>
                    </span>
                  }
                >
                  {category.sub_categories.map((subCategory) => {
                    return (
                      <Menu.Item key={subCategory.id}>
                        <a
                          href
                          onClick={() =>
                            handleSidebarRoute(category.name, subCategory.name)
                          }
                        >
                          {subCategory.name}
                        </a>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            })}

            <SubMenu
              key="accountPage"
              title={
                <span>
                  <i className="fad fa-user-circle"></i>
                  <span>
                    <IntlMessages id="sidebar.accountPage" />
                  </span>
                </span>
              }
            >
              <Menu.Item>
                <Link to="/dashboard/account">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.personalInformation" />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/account">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.Address" />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/account">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.feedback" />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/account">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.paypalAccount" />
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="reportManagment"
              title={
                <span>
                  <i className="fad fa-file-chart-line"></i>
                  <span>
                    <IntlMessages id="sidebar.reportManagment" />
                  </span>
                </span>
              }
            >
              <Menu.Item>
                <Link to="/dashboard/report">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.dialyReport" />
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="orderManagement"
              title={
                <span>
                  <i className="fad fa-dolly"></i>
                  <span>
                    <IntlMessages id="sidebar.orderManagement" />
                  </span>
                </span>
              }
            >
              <Menu.Item>
                <Link to="/dashboard/report">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.productDetail" />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/report">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.siteVisitor" />
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="marketingManagment"
              title={
                <span>
                  <i className="fad fa-handshake"></i>
                  <span>
                    <IntlMessages id="sidebar.marketingManagment" />
                  </span>
                </span>
              }
            >
              <Menu.Item>
                <Link to="/dashboard/market">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.newAd" />
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/dashboard/market">
                  {/* <i className="icon icon-widgets" /> */}
                  <IntlMessages id="sidebar.allAd" />
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
