import React, { Component } from "react";
import { Button, Badge } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Widget from "../../../Common/Widget/Widget";
import ShippingAddressSelectModal from "./ShippingAddressSelectModal";
import ShippingInformation from "../ShippingInformation/ShippingInformation";
import { profileActions } from "../../../../modules/ProfilePage/ducks";
import { cartActions } from "../../../../modules/Cart/ducks";

class ShippingAddressSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInOption: "",
      showAddress: false,
      showForm: true,
    };
  }
  componentDidMount() {
    // let id = "9e6bb300-49a2-11eb-92ec-8bd15dd95e04"
    const { shippingAddress } = this.props;
    let id = shippingAddress.data.default_shipping_address_id;

    let shippingAddresses = shippingAddress.data.shipping_addresses;

    const type =
      Array.isArray(shippingAddresses) &&
      shippingAddresses.find((el) => el.id === id);

    let data = type ? type : shippingAddresses[0];
    this.shippingAddressUpdateHandler(data);

    this.setState({ showInOption: data });
  }

  setSelectedAddress = (value) => {
    this.shippingAddressUpdateHandler(value);
    this.setState({
      showInOption: value,
    });
  };

  shippingAddressUpdateHandler = (data) => {
    let shippingInfoDto = {
      first_name: data.first_name,
      last_name: data.last_name,
      zip: data.zip,
      city: data.city,
      state: data.state,
      address1: data.address1,
      address2: data.address2,
      country: data.country,
      phone: data.phone,
    };

    this.props.cartActions.confirmShippingInfo({
      shippingInfoDto: shippingInfoDto,
    });
    this.props.cartActions.updateShippingCost({
      checkoutItems: this.props.checkoutItems,
      buyer_country: data.country,
      zip_code: data.zip,
      shippingInfoDto: shippingInfoDto,
    });
  };

  showFormHandler = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  setUpdatedAddress = (shippingInfoDto) => {
    this.setState({
      showInOption: shippingInfoDto,
    });
    this.showFormHandler();
  };

  render() {
    const { shippingAddress } = this.props;
    const { showInOption, showForm } = this.state;
    let defaultShippingAddressId =
      (shippingAddress &&
        shippingAddress.data &&
        shippingAddress.data.default_shipping_address_id) ||
      null;

    let isDefaultShippingAddress = defaultShippingAddressId === showInOption.id;

    return (
      <div>
        {showForm ? (
          <Widget>
            <div
              className="gx-news-item p-2 mb-2"
              style={{ justifyContent: "space-between" }}
            >
              <div className="gx-news-content">
                {isDefaultShippingAddress ? (
                  <Badge
                    count="Default Address"
                    style={{
                      backgroundColor: "#038fde",
                      color: "#fff",
                      boxShadow: "0 0 0 1px #d9d9d9 inset",
                      marginLeft: "10px",
                    }}
                  />
                ) : null}
                <h6 className="gx-mt-0 mb-3">
                  <i className="fad fa-user mr-2"></i>
                  {showInOption.first_name + " " + showInOption.last_name}
                </h6>
                {/* <p className="gx-mb-2">{subTitle}</p> */}
                <div className="gx-news-tags-row">
                  <div className="gx-news-tags-left">
                    <p className="gx-text-grey gx-mb-0">
                      <i className="fad fa-map-marker-alt mr-2"></i>
                      {showInOption.address1}
                    </p>
                    <p
                      className="gx-text-grey gx-mb-0"
                      style={{ marginLeft: "19px" }}
                    >
                      {showInOption.address2}
                    </p>
                    <p
                      className="gx-text-grey gx-mb-0"
                      style={{ marginLeft: "19px" }}
                    >
                      {showInOption.city}
                    </p>
                    <p
                      className="gx-text-grey gx-mb-0"
                      style={{ marginLeft: "19px" }}
                    >
                      {showInOption.country}
                    </p>
                    <p
                      className="gx-text-grey gx-mb-0"
                      style={{ marginLeft: "19px" }}
                    >
                      {showInOption.state}
                    </p>
                    <p
                      className="gx-text-grey gx-mb-3"
                      style={{ marginLeft: "19px" }}
                    >
                      {showInOption.zip}
                    </p>
                    <p className="gx-text-grey gx-mb-0">
                      <i className="fad fa-phone-alt mr-2"></i>
                      {showInOption.phone}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                className="gx-btn-outline-primary mr-0"
                onClick={this.showFormHandler}
              >
                <i className="fad fa-edit mr-2"></i> Edit
              </Button>
            </div>
          </Widget>
        ) : (
          <ShippingInformation
            address={showInOption}
            setUpdatedAddress={this.setUpdatedAddress}
            isUpdate={true}
            showFormHandler={this.showFormHandler}
            isDefaultShippingAddress={isDefaultShippingAddress}
          />
        )}
        <ShippingAddressSelectModal
          showForm={showForm}
          setSelectedAddress={this.setSelectedAddress}
          shippingAddress={shippingAddress}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    updateShippingCost: state.Cart.updateShippingCost,
    checkoutItems: state.Cart.checkoutItems,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingAddressSelect)
);
