// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  feedBackFormSubmit: createAction(types.FEEDBACK_FORM_SUBMIT),
  feedBackFormSubmitSuccess: createAction(types.FEEDBACK_FORM_SUBMIT_SUCCESS),
  feedBackFormSubmitFail: createAction(types.FEEDBACK_FORM_SUBMIT_FAILED),

  handleFeedbackImages: createAction(types.HANDLE_FEEDBACK_IMAGES),
  clearFeedbackImages: createAction(types.CLEAR_FEEDBACK_IMAGES),
  removeFeedbackImages: createAction(types.REMOVE_FEEDBACK_IMAGES),
};
