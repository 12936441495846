import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Select, Input, Col, Row } from "antd";
import { makeField } from "components/Common/Controls/MakeField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;
const Option = Select.Option;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class Invoicenumber extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = (values) => {
    
  };

  render() {
    const { handleSubmit } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 8 },
        xl: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 16 },
        xl: { span: 16 },
      },
    };

    const formItemLayout2 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 20 },
        xl: { span: 20 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 20 },
        xl: { span: 20 },
      },
    };

    const tailFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 8 },
        xl: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12 },
        lg: { span: 16 },
        xl: { span: 16 },
      },
    };

    return (
      <div className="gx-card p-3 pt-5">
        <React.Fragment>
          <Form onSubmit={handleSubmit(this.handleSubmit)}>
            <FormItem {...formItemLayout} label="Generation Type">
              <Field
                formItemLayout={formItemLayout2}
                name="generationtype"
                component={ASelectField}
                hasFeedback
              >
                <Option value="product">Provide number manually</Option>
                <Option value="order">Use Order Number</Option>
                <Option value="autoincrement">Use Autoincrement Number</Option>
              </Field>
            </FormItem>

            <FormItem {...formItemLayout} label="Invoice Prefix">
              <Field
                formItemLayout={formItemLayout2}
                // label="First Name"
                name="invoiceprefix"
                component={AInputField}
                placeholder="Invoice Prefix"
                hasFeedback
              />
            </FormItem>

            <FormItem
              {...formItemLayout}
              label="Next Number (Insert numbers only)"
            >
              <Field
                formItemLayout={formItemLayout2}
                // label="First Name"
                name="nextnymber"
                component={AInputField}
                placeholder="Next Number (Insert numbers only)"
                hasFeedback
              />
            </FormItem>

            <hr></hr>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="mb-2 float-right">
                  <FormItem
                    {...tailFormItemLayout}
                    style={{ marginBottom: "0" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: "10px", marginBottom: "0" }}
                    >
                      Submit
                    </Button>
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Form>
        </React.Fragment>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.generationtype) {
    errors.firstName = "Generation Type is required";
  }

  return errors;
};

function mapStateToProps(state) {
  return null;
}
function mapDispatchToProps(dispatch) {
  return null;
}
export default withRouter(
  reduxForm({
    form: "Invoicenumber",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(Invoicenumber))
);
