import React from "react";
import { Rate, Col, Row, Card } from "antd";
import ProgressBar from "../../../../Common/ProgressBar/ProgressBar";

function ReviewCard({ product }) {
  const { ratings, ratings_value } = product.data.data;
  const { ratings_count, ratings_percentage } = ratings;
  const ratingsCount = ratings_count.reverse();
  const ratingsPercentage = ratings_percentage.reverse();

  return (
    <React.Fragment>
      <Card className="gx-card mb-3">
        <Row>
          <Col span={2}></Col>
          <Col span={6}>
            <div style={{ marginTop: "20px" }}>
              <div className="d-inline align-baseline display-3 mr-1">
                {ratings_value}
              </div>
              <div className="d-inline align-baseline text-sm text-warning mr-1">
                <div className="rating-stars">
                  <Rate allowHalf defaultValue={ratings_value} disabled />
                </div>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div>
              {ratingsCount.map((rating, i) => {
                return (
                  <ProgressBar
                    key={`${rating}/${i}`}
                    rate={5 - i}
                    ratingCount={rating}
                    ratingPrecentage={ratingsPercentage[i]}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
}

export default ReviewCard;
