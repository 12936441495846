import React from "react";
import { Col } from "antd";
import "./PopularProductCard.less";
import { Link } from "react-router-dom";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";

const PopularProductCard = ({ productDetails, isLoading }) => {
  const { images, name, price, id } = productDetails;

  return (
    <Col xl={6} lg={12} md={12} sm={12} xs={24}>
      <Link to={isLoading ? "" : `/product/${id}`}>
        <div
          className="gx-product-item gx-product-vertical bdr-jr populr-pdct-hvr prdct-crd-min-hgt prdcd-mn-jr"
          style={{ display: "grid", gridTemplateRows: "auto 72.8px" }}
        >
          <div className="gx-product-image p-2">
            <img
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                height: "100%",
              }}
              src={images[0]}
              alt="PopularProduct"
            />
          </div>
          <div className="bdy-pdng-jr" style={{ height: "72.8px" }}>
            <div className="gx-product-name tb-product-title crd-fnt-jr">
              {name}
            </div>
            <div className="gx-product-price">{`${DEFAULT_CURRENCY_SHORT} ${price}`}</div>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default PopularProductCard;
