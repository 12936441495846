import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Spin } from "antd";
import { homeActions } from "../Home/ducks/index";
import { chatActions } from "../ChatPage/ducks";
import { sellerStoreActions } from "./ducks";
// import SellerHeader from "../../components/Buyer/SellerStore/SellerHeader/SellerHeader";
import SellerStoreProfile from "../../components/Buyer/SellerStore/SellerStoreProfile/SellerStoreProfile";
// import SellerDescription from "../../components/Buyer/SellerStore/SellerDescription/SellerDescription"; {/* Temparary removed store banners  */}
// import SellerStoreCarousel from "../../components/Buyer/Carousel/SellerStoreCarousel/SellerStoreCarousel"; {/* Temparary removed store banners  */}
import { GetConversationIdByUserID } from "../../services/message.service";
import SellerProductsContainer from "../../components/Buyer/SellerStore/SellerProductsContainer/SellerProductsContainer";
import moment from "moment";
import sellerImg from "assets/images/asadaad.jpg";
import SEO from "../SEO/SEO";

class SellerStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    const { sellerStoreActions } = this.props;
    const { sellerId } = this.props.match.params;
    sellerStoreActions.getSellerStore({
      sellerId: sellerId,
    });
  }

  componentWillUnmount() {
    this.props.sellerStoreActions.clearSellerStore();
  }

  enterLoading = () => {
    this.setState({ loading: true });
  };

  contactSellerHandler = () => {
    const { sellerId } = this.props.match.params;
    this.getConversationID();
    this.props.chatActions.selectedSellerHandler({ sellerId });
  };

  getConversationID = async () => {
    /**
     * GetConversationIdByUserID
     */
    const { sellerId } = this.props.match.params;

    await GetConversationIdByUserID(sellerId).then((conversationID) => {
      this.props.history.push(`/chat/${conversationID}`);
    });
  };

  render() {
    const { sellerStore } = this.props;
    let index =
      sellerStore &&
      sellerStore.data.algolia &&
      sellerStore.data.algolia.all_products.index;
    let apiKey =
      sellerStore &&
      sellerStore.data.algolia &&
      sellerStore.data.algolia.all_products.api_key;

    return (
      <div className="gx-container pt-4">
        {/* <SellerStoreCarousel /> */} {/* Temparary removed store banners  */}
        <div
          className="gx-card p-3"
          style={{ backgroundColor: "rgb(250, 250, 250)" }}
        >
          <SEO
            title={
              sellerStore?.data?.name
                ? `${sellerStore?.data?.name} | ${process.env.REACT_APP_BUSINESS_NAME}`
                : `${process.env.REACT_APP_BUSINESS_NAME}`
            }
            image={sellerStore?.data?.image}
          />
          <Spin spinning={sellerStore.pending} className="gx-card p-4 mb-0">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "250px", verticalAlign: "top" }}>
                    <img
                      style={{
                        width: "220px",
                        height: "220px",
                        borderRadius: "5px",
                      }}
                      alt="example"
                      src={
                        sellerStore &&
                        sellerStore.data &&
                        sellerStore.data.image
                          ? sellerStore.data.image
                          : sellerImg
                      }
                    />
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <div>
                      <div>
                        <h4 className="mb-0">{`Welcome ${
                          (sellerStore &&
                            sellerStore.data &&
                            sellerStore.data.name) ||
                          ""
                        } `}</h4>
                      </div>
                      <hr />
                      <div>
                        <i className="fad fa-calendar-alt mr-2  "></i>
                        {`Joined Date - ${
                          sellerStore &&
                          sellerStore.data &&
                          moment(sellerStore.data.joined_at).format(
                            "DD MMMM YYYY"
                          )
                        }`}
                      </div>
                    </div>{" "}
                    <SellerStoreProfile
                      data={sellerStore}
                      contactSellerHandler={this.contactSellerHandler}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Spin>
        </div>
        {/* <SellerDescription data={sellerStore} /> */}{" "}
        {/* Temparary removed store banners  */}
        {apiKey && <SellerProductsContainer apiKey={apiKey} index={index} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.Home,
    allProducts: state.Home.allProducts,
    sellerStore: state.SellerStore.sellerStore,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    homeActions: bindActionCreators(homeActions, dispatch),
    sellerStoreActions: bindActionCreators(sellerStoreActions, dispatch),
    chatActions: bindActionCreators(chatActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SellerStore)
);
