import React from "react";
import ReviewCard from "./ReviewCard/ReviewCard";
import ProductReviewContainer from "./ProductReviewContainer/ProductReviewContainer";

function ProductReview({ algolia_api_key, product }) {
  return (
    <React.Fragment>
      <div className="mt-3">
        <ReviewCard className="gx-d-none gx-d-sm-block" product={product} />

        <div>
          <h5 className="mb-3 ml-2">Latest Reviews</h5>

          <ProductReviewContainer algolia_api_key={algolia_api_key} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductReview;
