import React, { Component } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, Input, Row, Select, Checkbox } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";
import { cartActions } from "../../../../modules/Cart/ducks";
import { profileActions } from "../../../../modules/ProfilePage/ducks";
import { allCountriesJson } from "../../../../DummyData/AllCountry";

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    md: {
      span: 24,
      offset: 0,
    },
    lg: {
      span: 24,
      offset: 0,
    },
    xl: {
      span: 24,
      offset: 0,
    },
  },
};

const formItemZipLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
};

const formItemCheckboxLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const AInputField = makeField(Input);
const ASelectField = makeField(Select);
const ACheckboxField = makeField(Checkbox);

class ShippingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
  }

  componentDidMount() {
    const { address, isDefaultShippingAddress } = this.props;
    if (address) {
      const recievedObj = {
        firstName: address.first_name,
        lastName: address.last_name,
        zip: address.zip,
        city: address.city,
        state: address.state,
        addressLine1: address.address1,
        addressLine2: address.address2,
        country: address.country,
        contactNumber: address.phone,
        default_address: isDefaultShippingAddress,
      };

      //initialize redux form values
      this.props.initialize(recievedObj);
    }
  }
  updateShippingAddress = () => {
    const { fieldValues, address } = this.props;
    const {
      firstName,
      lastName,
      zip,
      city,
      state,
      addressLine1,
      addressLine2,
      country,
      contactNumber,
      default_address,
    } = fieldValues;

    let updatedAddress = {
      first_name: firstName,
      last_name: lastName,
      zip: zip,
      city: city,
      state: state,
      address1: addressLine1,
      address2: addressLine2,
      country: country,
      phone: contactNumber,
      default_address: default_address,
    };

    if (address) {
      this.props.profileActions.updateShippingAddress({
        shipping_address_id: address.id,
        shippingInfoDto: updatedAddress,
      });
    }
  };

  handleSubmit = (values) => {
    this.updateShippingAddress();
    const { isUpdate, setUpdatedAddress, address } = this.props;

    const {
      firstName,
      lastName,
      zip,
      city,
      state,
      addressLine1,
      addressLine2,
      country,
      contactNumber,
    } = values;
    var shippingInfoDto = {
      id: address ? address.id : undefined,
      first_name: firstName,
      last_name: lastName,
      zip: zip,
      city: city,
      state: state,
      address1: addressLine1,
      address2: addressLine2,
      country: country,
      phone: contactNumber,
    };

    this.props.cartActions.confirmShippingInfo({
      shippingInfoDto: shippingInfoDto,
    });

    this.props.cartActions.updateShippingCost({
      checkoutItems: this.props.checkoutItems,
      buyer_country: country,
      zip_code: zip,
      shippingInfoDto: shippingInfoDto,
    });

    if (isUpdate) {
      setUpdatedAddress(shippingInfoDto);
    }

    // when update the address call -> order summery API
    this.props.cartActions.checkOutCalulation({
      checkoutItemsObj: this.props.checkoutItems?.items ?? [],
      defaultShippingAddress: shippingInfoDto,
    });
  };
  render() {
    const { handleSubmit, pristine, reset, submitting, isUpdate } = this.props;
    return (
      <div className="gx-wall-content">
        {/* <h5 className="ml-2 mb-3">Shopping Information</h5> */}

        <div className="gx-card p-3 mb-0">
          <React.Fragment>
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
              <Row>
                <Col xl={9} lg={9} md={9} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    // label="First Name"
                    name="firstName"
                    component={AInputField}
                    placeholder="First Name"
                    hasFeedback
                  />
                </Col>
                <Col xl={9} lg={9} md={9} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    // label="First Name"
                    name="lastName"
                    component={AInputField}
                    placeholder="Last Name"
                    hasFeedback
                  />
                </Col>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="contactNumber"
                    component={AInputField}
                    placeholder="Contact Number"
                    hasFeedback
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="addressLine1"
                    component={AInputField}
                    placeholder="Address Line 1"
                    hasFeedback
                  />
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="addressLine2"
                    component={AInputField}
                    placeholder="Address Line 2"
                    // hasFeedback
                  />
                </Col>
              </Row>

              <Row>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="city"
                    component={AInputField}
                    placeholder="City"
                    hasFeedback
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemLayout}
                    name="state"
                    component={AInputField}
                    placeholder="State/Province/Region"
                    hasFeedback
                  />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Field
                    showSearch
                    formItemLayout={formItemLayout}
                    name="country"
                    component={ASelectField}
                    hasFeedback
                    placeholder="Country"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allCountriesJson.map((country, i) => {
                      return (
                        <Option key={i} value={country.alpha_3}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Field>
                </Col>
              </Row>

              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Field
                    formItemLayout={formItemZipLayout}
                    name="zip"
                    component={AInputField}
                    placeholder="Zip Code"
                    hasFeedback
                  />
                </Col>
              </Row>

              {isUpdate && (
                <Field
                  formItemLayout={formItemCheckboxLayout}
                  // label="Set as default"
                  name="default_address"
                  id="setAsDefault"
                  component={ACheckboxField}
                  type="checkbox"
                >
                  Set as default{" "}
                </Field>
              )}

              <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
                <Button
                  className="float-right"
                  type="primary"
                  disabled={pristine || submitting}
                  htmlType="submit"
                  style={{ marginBottom: "0" }}
                >
                  Save
                </Button>

                <Button
                  className="float-right"
                  disabled={pristine || submitting}
                  onClick={reset}
                  style={{ marginBottom: "0", marginRight: "10px" }}
                >
                  Clear Form
                </Button>

                {isUpdate && (
                  <Button
                    className="float-right"
                    onClick={this.props.showFormHandler}
                    style={{ marginBottom: "0", marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                )}
              </FormItem>
            </Form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "First name is required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "Contact number is required";
  }
  if (!values.addressLine1) {
    errors.addressLine1 = "Address line 1 is required";
  }
  if (!values.city) {
    errors.city = "City is required";
  }
  if (!values.state) {
    errors.state = "State is required";
  }
  if (!values.country) {
    errors.country = "Country is required";
  }
  if (!values.zip) {
    errors.zip = "Zip code is required";
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    fieldValues: getFormValues("ShippingInformation")(state),
    shippingCost: state.Cart.shippingCost,
    checkoutItems: state.Cart.checkoutItems,
    shippingAddress: state.Profile.shippingAddress,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "ShippingInformation",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(ShippingInformation))
);
