import React from "react";
import PropTypes from "prop-types";
import "./CardBox.less";

const CardBox = ({ heading, children, styleName, childrenStyle }) => {
  return (
    <div
      className={`gx-card ${styleName}`}
      style={{ backgroundColor: "rgb(250, 250, 250)" }}
    >
      {heading && (
        <div className="gx-card-head" style={{ padding: "16px" }}>
          <h3 className="gx-card-title h3-jr nw-jr-fnt-clr">{heading}</h3>
        </div>
      )}
      <div className={`gx-card-body ${childrenStyle}`} style={{padding: "16px"}}>{children}</div>
    </div>
  );
};

export default CardBox;

CardBox.propTypes = {
  children: PropTypes.node.isRequired,
};

CardBox.defaultProps = {
  styleName: "",
  childrenStyle: "",
};
