import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Table, Button, Spin, Modal } from "antd";
import AlgoliaSearch from "../../../Common/Algolia/AlgoliaSearch";
import { productManagementActions } from "../../../../modules/SellerDashboard/ProductManagement/ducks";
import { DEFAULT_CURRENCY } from "../../../../constants";
const { confirm } = Modal;
class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      hits: [],
      filteredInfo: null,
      sortedInfo: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { hits, createProduct, productManagementActions } = this.props;
    if (JSON.stringify(hits) !== JSON.stringify(prevProps.hits)) {
      this.setState({ hits: hits });
    }
    if (
      createProduct.data &&
      JSON.stringify(createProduct.data) !==
        JSON.stringify(prevProps.createProduct.data) &&
      createProduct.data.success &&
      createProduct.data.data
    ) {
      this.setState(
        { hits: [createProduct.data.data, ...this.state.hits] },
        function () {
          productManagementActions.clearCreateProduct();
        }
      );
    }
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  handleDeleteProduct = (data) => {
    const { productManagementActions } = this.props;
    confirm({
      title: "Are you sure delete this product?",
      content: data.name,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        productManagementActions.deleteProduct({
          id: data.objectID,
          callback: this.updatedDeleteProductHandler,
        });
      },
      onCancel: () => {
        
      },
    });
  };

  updatedDeleteProductHandler = (id) => {
    const { hits } = this.state;
    let prevHits = Object.assign([], hits);
    let index = prevHits.findIndex((product) => product.objectID === id);
    prevHits.splice(index, 1);
    this.setState({ hits: prevHits });
  };

  handleChangeProductActiveStatus = (id) => {
    const { productManagementActions } = this.props;
    productManagementActions.changeProductActiveStatus({
      id: id,
      callback: this.updateProductStatus,
    });
  };

  updateProductStatus = (id) => {
    const { hits } = this.state;
    let prevHits = Object.assign([], hits);
    prevHits.forEach((product, index) => {
      if (product.objectID === id) {
        let prevProductStatus = product.active;
        product.active = !prevProductStatus;
      }
    });
    this.setState({ hits: prevHits });
  };

  navigateProduct = (id) => {
    const win = window.open(`/product/${id}`, "_blank");
    win.focus();
  };

  navigateToEditProduct = (id) => {
    const win = window.open(`/seller/dashboard/product/update/${id}`, "_blank");
    win.focus();
  };

  render() {
    const { changeProductActiveStatus, deleteProduct } = this.props;

    const columns = [
      {
        title: "Product",
        width: "110px",
        render: (text, record) => {
          return (
            <img
              alt="avatar"
              src={record.images && record.images[0]}
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            />
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Price",
        width: "150px",
        render: (text, record) => (
          <span className="texi-clr-org">{`${DEFAULT_CURRENCY} ${record.price}`}</span>
        ),
      },
      {
        title: "Availability",
        width: "80px",
        dataIndex: "total_qty",
        key: "address",
      },
      {
        title: "Actions",
        width: "290px",
        render: (text, record) => {
          return (
            <>
              {record.active ? (
                <Button
                  style={{ width: "120px" }}
                  className="gx-btn-outline-primary mb-2 mr-2"
                  onClick={() => this.navigateProduct(record.id)}
                >
                  <i className="fad fa-eye mr-1"></i>
                  View
                </Button>
              ) : (
                <Button
                  style={{ width: "120px" }}
                  className="gx-btn-outline-primary mb-2 mr-2"
                  onClick={() =>
                    this.props.history.push(`/product/${record && record.id}`)
                  }
                  disabled
                >
                  <i className="fad fa-eye mr-1"></i>
                  View
                </Button>
              )}

              <Button
                style={{ width: "120px" }}
                className="gx-btn-outline-secondary mb-2 mr-2"
                onClick={() => this.navigateToEditProduct(record.id)}
              >
                <i className="fad fa-sync-alt mr-1"></i>
                Update
              </Button>

              <Button
                style={{ width: "120px" }}
                className={
                  record.active
                    ? "gx-btn-outline-sepia mb-0 mr-2"
                    : "gx-btn-outline-success mb-0 mr-2"
                }
                onClick={() =>
                  this.handleChangeProductActiveStatus(record.objectID)
                }
              >
                <i
                  className={
                    record.active
                      ? "fad fa-lightbulb mr-1"
                      : "fad fa-lightbulb-on mr-1"
                  }
                ></i>
                {record.active ? "Inactive" : "Active"}
              </Button>

              <Button
                style={{ width: "120px" }}
                className="gx-btn-danger mb-0 mr-2"
                onClick={() => this.handleDeleteProduct(record)}
              >
                <i className="fad fa-trash-alt mr-1"></i>
                Delete
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <Spin
        spinning={changeProductActiveStatus.pending || deleteProduct.pending}
        className=" mt-3"
      >
        <div className="table-operations">
          <AlgoliaSearch customStyle={{ width: "auto" }} />
        </div>

        <Table
          className=""
          style={{ minWidth: "500px" }}
          columns={columns}
          // dataSource={this.props.hits.length === 0 ? [] : hits}
          dataSource={this.state.hits}
          onChange={this.handleChange}
          pagination={false}
          bordered
          TableRow
        />
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    createProduct: state.ProductManagement.createProduct,
    changeProductActiveStatus:
      state.ProductManagement.changeProductActiveStatus,
    deleteProduct: state.ProductManagement.deleteProduct,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    productManagementActions: bindActionCreators(
      productManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductList)
);
