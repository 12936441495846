// import React from "react";
// import { Helmet } from "react-helmet";

// export const SEO = ({ title, description, meta = [] }) => {
//   return (
//     <Helmet
//       title={title}
//       htmlAttributes={{ lang: "en" }}
//       meta={[
//         {
//           name: `description`,
//           content: description,
//         },
//       ]}
//     />
//   );
// };

import { Helmet } from "react-helmet";
import React from "react";
const SEO = ({ title, description, pathSlug, keywords, image }) => {
  // const url = `https://malikgabroun.com/${pathSlug}`;
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        // {
        //   name: "keywords",
        //   content: keywords.join(),
        // },
        {
          name: "keywords",
          content:
            "Buy & sell electronics, Fashion, Business & Industrial, Collectibles & Art, Home & Garden, Motors, Music, Sporting Goods, Toys & Hobbies & more on Treadesbarn, the world's online marketplace. Top brands, low prices & free shipping on many items.",
        },

        {
          property: "og:image",
          content: image,
        },
        {
          property: "twitter:image",
          content: image,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "twitter:title",
          content: title,
        },
        {
          property: "twitter:site",
          content: `${process.env.REACT_APP_BUSINESS_NAME}`,
        },
        {
          property: "og:site_name",
          content: `@${process.env.REACT_APP_BUSINESS_NAME}`,
        },
        {
          property: "og:url",
          content: `${process.env.REACT_APP_BASE_URL}`,
        },
      ]}
      // links={[
      //   {
      //     rel: "canonical",
      //     href: url,
      //   },
      // ]}
    />
  );
};
export default SEO;

// SEO.defaultProps = {
//   image: "Rahul",
//   eyeColor: "deepblue",
//   age: "45"
// }

// // OpenGraph tags (marked with og:) are crawled by Facebook whenever you share a link through Messenger/ Facebook:
// meta={[
//   {
//     property: "og:url",
//     content: someUrl
//   },
//   {
//     property: "og:type",
//     content: someArticle
//   },
//   {
//     property: "og:title",
//     content: someTitle
//   },
//   {
//     property: "og:description",
//     content: someDescription
//   },
//   {
//     property: "og:image",
//     content: someImage
//   },
//   {
//     property: "fb:app_id",
//     content: someFbAppId
//   }
// ]

// // Twitter tags (marked with twitter) are crawled by Twitter accordingly:
// meta={[
//   {
//     property: "twitter:card",
//     content: someSummary
//   },
//   {
//     property: "twitter:creator",
//     content: someAuthorName
//   },
//   {
//     property: "twitter:title",
//     content: someTitle
//   },
//   {
//     property: "twitter:description",
//     content: someDescription
//   },
//   {
//     property: "twitter:image",
//     content: someImage
//   }
// ]
