import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Form, Select, Input, Spin } from "antd";

import { makeField } from "../../Common/Controls/MakeField";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import ReactSwipeButton from "react-swipe-button";
import "./SellerRegisterForm.less";
import { allCountriesJson } from "../../../DummyData/AllCountry";

import { authActions } from "../../../modules/Auth/ducks";
import SellerRegisterImage from "./SellerRegisterImage";
import { IS_ENABLED_INTERNATIONAL_MODE } from "../../../constants";
// import "antd-country-phone-input/dist/index.css";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: { xs: 24, sm: 8, md: 8, lg: 10, xl: 10 },
  wrapperCol: { xs: 24, sm: 16, md: 16, lg: 14, xl: 14 },
};

const formItemLayout2 = {
  //labelCol: { xs: 24, sm: 6, md: 6, lg: 8, xl: 8 },
  wrapperCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
};

const tailFormItemLayout = {
  wrapperCol: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
};

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class SellerRegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValify: false,
      loading: false,
      isCountdown: false,
      imageUrl: "",
    };
  }

  handleSuccess = () => {
    this.setState({ isValify: true });
  };

  handleImageUrl = (imageUrl) => {
    this.setState({ imageUrl });
  };

  enterLoading = () => {
    this.setState({ loading: true });
    this.setState({ isCountdown: true });
  };

  isCountdownFinish = () => {
    this.setState({ loading: false });
    this.setState({ isCountdown: false });
  };

  handleSubmit = (values) => {
    const { imageUrl } = this.state;
    var registerSellerDto = {
      store_name: values && values.storeName,
      email: values && values.email,
      password: values && values.password,
      password_confirmation: values && values.confirmPassword,
      seller_category: values && values.storecatagory,
      // contactNumber: values && `${values.countryCode}  ${values.contactNumber}`,
      contactNumber: values && values.contactNumber,
      address1: values && values.address1,
      address2: values && values.address2,
      city: values && values.city,
      state: values && values.state,
      country: values && values.country,
      zip_code: values && values.zipCode,
      image: imageUrl,
      role: "Seller",
    };

    this.props.authActions.sellerRegister(registerSellerDto);
  };
  render() {
    const { handleSubmit, sellerRegister } = this.props;
    return (
      <Spin spinning={sellerRegister.pending}>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          className="feild-catcher-reg"
          initialValues={{
            lowerCase: {
              short: "us",
            },
            undefined: undefined,
          }}
        >
          {IS_ENABLED_INTERNATIONAL_MODE && (
            <FormItem {...formItemLayout} label="Country" required>
              <Field
                showSearch
                formItemLayout={formItemLayout2}
                name="country"
                component={ASelectField}
                placeholder="Select Country"
                hasFeedback
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {allCountriesJson.map((country, i) => {
                  return (
                    <Option key={i} value={country.alpha_3}>
                      {country.name}
                    </Option>
                  );
                })}
              </Field>
            </FormItem>
          )}

          <FormItem {...formItemLayout} label="Category" required>
            <Field
              formItemLayout={formItemLayout2}
              name="storecatagory"
              placeholder="Select Category"
              component={ASelectField}
              hasFeedback
            >
              <Option value="Individual">Individual</Option>
              <Option value="SME">SME</Option>
              <Option value="Manufacturer">Manufacturer</Option>
            </Field>
          </FormItem>

          <FormItem {...formItemLayout} label="Shop Name" required>
            <Field
              formItemLayout={formItemLayout2}
              name="storeName"
              component={AInputField}
              placeholder="Display Name / Shop Name"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Email" required>
            <Field
              formItemLayout={formItemLayout2}
              name="email"
              component={AInputField}
              placeholder="Email"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Number" required>
            <Field
              className="cont-with-flag"
              formItemLayout={formItemLayout2}
              name="contactNumber"
              component={AInputField}
              // addonBefore={
              //   <Field
              //     formItemLayout={formItemLayout2}
              //     className="flag-selector"
              //     name="countryCode"
              //     component={ASelectField}
              //     hasFeedback
              //     style={{ width: 120 }}
              //     showSearch
              //     defaultValue="+94"
              //   >
              //     {allCountriesJson.map((country, i) => {
              //       return (
              //         <Option value={country.dialCode} key={i}>
              //           <DisplayContainer data={country} />
              //         </Option>
              //       );
              //     })}
              //   </Field>
              // }
              placeholder="Contact Number"
              onFocus={this.handleInputFocus}
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Password" required>
            <Field
              formItemLayout={formItemLayout2}
              name="password"
              component={AInputField}
              placeholder="Password"
              type="password"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Confirm Password" required>
            <Field
              formItemLayout={formItemLayout2}
              name="confirmPassword"
              component={AInputField}
              placeholder="Confirm Password"
              type="password"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Address 1" required>
            <Field
              formItemLayout={formItemLayout2}
              // label="First Name"
              name="address1"
              component={AInputField}
              placeholder="Address 1"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Address 2">
            <Field
              formItemLayout={formItemLayout2}
              // label="First Name"
              name="address2"
              component={AInputField}
              placeholder="Address 2"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="City" required>
            <Field
              formItemLayout={formItemLayout2}
              name="city"
              component={AInputField}
              placeholder="City"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="State / Province" required>
            <Field
              formItemLayout={formItemLayout2}
              name="state"
              component={AInputField}
              placeholder="State"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="ZIP Code" required>
            <Field
              formItemLayout={formItemLayout2}
              name="zipCode"
              component={AInputField}
              placeholder="ZIP Code"
              hasFeedback
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Seller Store Image">
            <div className="dropbox mb-5">
              <SellerRegisterImage handleImageUrl={this.handleImageUrl} />
            </div>
          </FormItem>
          {!this.state.isValify ? (
            <ReactSwipeButton
              text="Slide to verify"
              color="#f00"
              onSuccess={this.handleSuccess}
            />
          ) : null}
          <FormItem {...tailFormItemLayout} style={{ marginBottom: "0" }}>
            <Button
              type="primary"
              block
              htmlType="submit"
              style={{ marginTop: "55px", marginBottom: "0" }}
              disabled={!this.state.isValify}
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.country) {
    errors.country = "Country is required";
  }
  if (!values.storecatagory) {
    errors.storecatagory = "Store catagory is required";
  }
  if (!values.storeName) {
    errors.storeName = "Store name is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "Contact number is required";
  }
  // if (!values.countryCode) {
  //   errors.countryCode = "Country code is required";
  // }
  if (!values.password) {
    errors.password = "Password is required";
  }
  if (values && values.password && values.password.length < 6) {
    errors.password = "Password should be minumum 6 Character";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm password is required";
  }
  if (values.confirmPassword && values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password mismatch..!";
  }
  if (!values.address1) {
    errors.address1 = "Address line 1 is required";
  }
  if (!values.city) {
    errors.city = "City is required";
  }
  if (!values.state) {
    errors.state = "State is required";
  }
  if (!values.zipCode) {
    errors.zipCode = "ZIP Code is required";
  }

  if (!values.sellerImage) {
    errors.sellerImage = "Seller Image is required";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    upgradeAccount: state.AuthStore.upgradeAccount,
    sellerRegister: state.AuthStore.sellerRegister,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
}

export default withRouter(
  reduxForm({
    form: "SellerRegisterForm",
    validate,
  })(connect(mapStateToProps, mapDispatchToProps)(SellerRegisterForm))
);
