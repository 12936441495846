import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu } from "antd";

class DashboardAccountGenaralNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // current: "mybarn",
    };
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  componentDidMount() {
    this.activeLinkHandler();
  }

  activeLinkHandler = () => {
    const { path } = this.props.match;
    switch (path.split("/")[5]) {
      case "businessinformation":
        this.setState({
          current: "business_information",
        });
        break;
      case "selleraccount":
        this.setState({
          current: "seller_account",
        });
        break;
      case "bankaccount":
        this.setState({
          current: "bank_account",
        });
        break;
      default:
        this.setState({
          default: true,
        });
    }
  };

  render() {
    return (
      <div className="gx-card mb-1 pl-1 pr-1" style={{ overflow: "hidden" }}>
        <div className="gx-profile-banner-top-right my-acc-eff">
          <Menu
            // onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            mode="horizontal"
            style={{ flexWrap: "inherit" }}
            className="gx-follower-list"
          >
            <Menu.Item key="seller_account">
              <Link to="/seller/dashboard/profile/general/selleraccount">
                <i className="icon icon-avatar mr-2" />
                Seller Account
              </Link>
            </Menu.Item>
            <Menu.Item key="business_information">
              <Link to="/seller/dashboard/profile/general/businessinformation">
                <i className="icon icon-auth-screen mr-2" />
                Business Information
              </Link>
            </Menu.Item>
            <Menu.Item key="bank_account">
              <Link to="/seller/dashboard/profile/general/bankaccount">
                <i className="icon icon-chat mr-2" />
                Bank Account
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardAccountGenaralNav);
