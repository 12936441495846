import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./AllProductsBannerCarousel.less";

class AllProductsBannerCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      banners: [],
    };
  }

  componentDidMount() {
    this.handleBanners();
  }

  handleBanners = () => {
    const { categorylvl0 } = this.props.params;
    const Banners = {
      Electronics: [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Electronics/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Electronics/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Electronics/carousel3.jpg`),
        },
      ],
      Fashion: [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Fashion/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Fashion/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Fashion/carousel3.jpg`),
        },
      ],
      "Homen-Gardern": [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/HomenGarden/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/HomenGarden/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/HomenGarden/carousel3.jpg`),
        },
      ],
      "Business-Industrial": [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/BusinessnIndustrial/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/BusinessnIndustrial/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/BusinessnIndustrial/carousel3.jpg`),
        },
      ],
      "Collectibles-Art": [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/CollectiblesnArt/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/CollectiblesnArt/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/CollectiblesnArt/carousel3.jpg`),
        },
      ],
      "Toys-Hobbies": [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/ToysnHobbies/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/ToysnHobbies/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/ToysnHobbies/carousel3.jpg`),
        },
      ],
      "Sporting-Goods": [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/SportingGoods/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/SportingGoods/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/SportingGoods/carousel3.jpg`),
        },
      ],
      Music: [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Music/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Music/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Music/carousel3.jpg`),
        },
      ],
      Motors: [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Motors/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Motors/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/AllProducts/Motors/carousel3.jpg`),
        },
      ],
      Other: [
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel2.jpg`),
        },
        {
          img: require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/carousel/carousel3.jpg`),
        },
      ],
    };
    this.setState({
      banners: Banners[categorylvl0] || Banners["Other"],
    });
  };
  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  };
  render() {
    const { index, banners } = this.state;
    return (
      <Carousel fade={false} activeIndex={index} onSelect={this.handleSelect}>
        {banners.map((banner, i) => {
          return (
            <Carousel.Item key={i}>
              <img
                className="d-block mx-auto"
                src={banner.img}
                alt="Moto 360"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}

export default AllProductsBannerCarousel;
