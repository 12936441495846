import types from "./types";
import { handleActions } from "redux-actions";
// import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  selectedSeller: {
    selectedSeller: null,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.SELECTED_SELLER_HANDLER]: (state, { payload }) => ({
      ...state,
      selectedSeller: {
        selectedSeller: payload.sellerId,
      },
    }),
  },
  initialState
);
