import React, { Component } from "react";
import SellerReviewSearchbar from "../../../../components/Seller/SellerReview/SellerReviewSearchbar/SellerReviewSearchbar";
import SellerReviewTable from "../../../../components/Seller/SellerReview/SellerReviewTable/SellerReviewTable";

class SellerReview extends Component {
  render() {
    return (
      <div>
        <div style={{ paddingBottom: "20px" }}>
          {" "}
          <h3>Seller Review</h3>{" "}
        </div>

        {/* Seller Review Search bar */}
        <SellerReviewSearchbar />
        {/* Seller Review Table */}
        <SellerReviewTable />
      </div>
    );
  }
}

export default SellerReview;
