import React from "react";
import { Link } from "react-router-dom";
import CartNotifyItem from "./CartNotifyItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";

const CartNotify = (props) => {
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h5 className="gx-mb-0">Shopping Cart</h5>
        <Link to="/cart">
          <div
            className=""
            style={{
              backgroundColor: "#038fde",
              padding: "5px 15px",
              color: "white",
              borderRadius: "5px",
            }}
          >
            My Cart
          </div>
        </Link>
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {props.cart.items.length > 0 ? (
            props.cart.items.map((cart, index) => (
              <CartNotifyItem cart={cart} key={index} />
            ))
          ) : (
            <div style={{ padding: "100px 25px 5px", textAlign: "center" }}>
              Look like you are not adding any item to cart yet...
            </div>
          )}
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default CartNotify;
