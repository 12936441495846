// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getCreditCard: createAction(types.GET_CREDIT_CARD),
  getCreditCardSuccess: createAction(types.GET_CREDIT_CARD_SUCCESS),
  getCreditCardFail: createAction(types.GET_CREDIT_CARD_FAILED),

  addCreditCard: createAction(types.ADD_CREDIT_CARD),
  addCreditCardSuccess: createAction(types.ADD_CREDIT_CARD_SUCCESS),
  addCreditCardFail: createAction(types.ADD_CREDIT_CARD_FAILED),

  deleteCreditCard: createAction(types.DELETE_CREDIT_CARD),
  deleteCreditCardSuccess: createAction(types.DELETE_CREDIT_CARD_SUCCESS),
  deleteCreditCardFail: createAction(types.DELETE_CREDIT_CARD_FAILED),
};
