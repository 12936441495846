import React from "react";
import { Link } from "react-router-dom";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";

const WishlistNotifyItem = (props) => {
  return (
    <Link
      to={`/product/${props.wishlist.product_id}`}
      style={{ height: "100%" }}
    >
      <li className="gx-media wishlist-itm-crd">
        <div className="gx-user-thumb gx-mr-3">
          <img
            alt="Notify"
            className="gx-size-40"
            style={{ borderRadius: "5px" }}
            src={props.wishlist?.product?.images[0]}
          />
        </div>
        <div className="gx-media-body">
          <div className="gx-flex-row gx-justify-content-between gx-align-items-center">
            <div
              style={{ fontSize: "12px" }}
              className="gx-text-capitalize gx-text-muted gx-mb-0"
            >
              {props.wishlist?.product?.name}
            </div>
          </div>
          <div className="">
            <span className="">{`${DEFAULT_CURRENCY_SHORT} ${props.wishlist?.product?.price}`}</span>
          </div>
        </div>
      </li>
    </Link>
  );
};

export default WishlistNotifyItem;
