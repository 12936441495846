import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "antd";
import { orderManagementActions } from "../../../../modules/SellerDashboard/OrderManagement/ducks";
import OrderTrackModelBody from "./OrderTrackModelBody";

class OrderTrackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
      shippingAddress: "",
      imageLoad: false,
    };
  }
  getBuyShipping = () => {
    const { record } = this.props;
    this.setState({ imageLoad: true });
    this.props.orderManagementActions.buyShippingLabel({
      id: record.objectID,
    });
    this.setState({ shippingAddress: this.props.shippingAddressList });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      shippingAddress: "",
      visible: false,
    });
  };

  render() {
    const { shippingLoad, record } = this.props;
    return (
      <form className="add-card">
        <Button
          block
          className="gx-btn-outline-cyan mb-0 mt-2"
          onClick={this.showModal}
        >
          <i className="fad fa-dolly mr-1"></i>
          Tracking Details
        </Button>
        <Modal
          title="Tracking Details"
          style={{ top: 30, height: "auto" }}
          wrapClassName="vertical-center-modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          footer={null}
          disable={shippingLoad}
          loading={true}
        >
          <OrderTrackModelBody
            imageLoad={this.state.imageLoad}
            onCancel={this.handleCancel}
            buttonClick={this.getBuyShipping}
            record={record}
            shippingDetail={this.state.shippingAddress}
            shippingAddress={this.props.shippingAddress}
          />
        </Modal>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    shippingAddressList: state.OrderManagement.shippingAddressList,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    orderManagementActions: bindActionCreators(
      orderManagementActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderTrackModal)
);
