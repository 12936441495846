import React from "react";
import { Col, Row } from "antd";
import AboutItem from "./AboutItem";
import Widget from "../../../Common/Widget/Widget";

const aboutList = [
  {
    id: 1,
    title: "Works at",
    icon: "company",
    userList: "",
    desc: ["G-axon Tech Pvt. Ltd."],
  },
  {
    id: 2,
    title: "Birthday",
    icon: "birthday-new",
    userList: "",
    desc: ["Oct 25, 1984"],
  },
];

const About = () => {
  return (
    <Widget
      title="About"
      styleName="gx-card-tabs gx-card-tabs-right gx-card-profile"
    >
      <div className="gx-mb-2">
        <Row>
          {aboutList.map((about, index) => (
            <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
              <AboutItem data={about} />
            </Col>
          ))}
        </Row>
      </div>
    </Widget>
  );
};

export default About;
