const action_header = "orderManagement/";

// Types
export default {
  GET_ORDERS_COUNT: action_header + "GET_ORDERS_COUNT",
  GET_ORDERS_COUNT_SUCCESS: action_header + "GET_ORDERS_COUNT_SUCCESS",
  GET_ORDERS_COUNT_FAIL: action_header + "GET_ORDERS_COUNT_FAIL",

  BUY_SHIPPING_LABEL: action_header + "BUY_SHIPPING_LABEL",
  BUY_SHIPPING_LABEL_SUCCESS: action_header + "BUY_SHIPPING_LABEL_SUCCESS",
  BUY_SHIPPING_LABEL_FAIL: action_header + "BUY_SHIPPING_LABEL_FAIL",

  EXPORT_ORDERS: action_header + "EXPORT_ORDERS",
  EXPORT_ORDERS_SUCCESS: action_header + "EXPORT_ORDERS_SUCCESS",
  EXPORT_ORDERS_FAIL: action_header + "EXPORT_ORDERS_FAIL",

  ORDER_TRACKING: action_header + "ORDER_TRACKING",
  ORDER_TRACKING_SUCCESS: action_header + "ORDER_TRACKING_SUCCESS",
  ORDER_TRACKING_FAIL: action_header + "ORDER_TRACKING_FAIL",
};
