import React from "react";
import { Layout } from "antd";
import {
  ClearRefinements,
  HierarchicalMenu,
  Panel,
  RangeInput,
  RatingMenu,
  RefinementList,
} from "react-instantsearch-dom";
import "./ProductsSideBar.less";

const { Sider } = Layout;
const Sidebar = () => (
  <Sider
    className="gx-algolia-sidebar"
    style={{ backgroundColor: "rgb(252, 252, 252)" }}
  >
    <div className="gx-algolia-sidebar-content">
      <ClearRefinements
        translations={{
          reset: "Clear all filters",
        }}
        cssClasses={{ backgroundColor: "red" }}
      />

      <div className="gx-algolia-category-item">
        <div className="gx-algolia-category-title pdct-sdbr-fnt">
          Show results for
        </div>
        <div className="heircymnu-jr">
          <HierarchicalMenu
            attributes={[
              "hierarchicalCategories.lvl0",
              "hierarchicalCategories.lvl1",
              "hierarchicalCategories.lvl2",
            ]}
          />
        </div>
      </div>

      <div className="gx-algolia-category-item">
        <div className="gx-algolia-category-title pdct-sdbr-fnt">Refine By</div>
        <div className="heircymnu-jr-2">
          <Panel header={<span>Brand</span>}>
            <RefinementList
              className="gx-algolia-refinementList"
              attribute="brand"
              operator="or"
              limit={5}
              searchable
            />
          </Panel>
          <hr></hr>

          <Panel header={<span>Country of Origin</span>}>
            <RefinementList
              className="gx-algolia-refinementList"
              attribute="country_of_origin"
              operator="or"
              limit={5}
              searchable
            />
          </Panel>
          <hr></hr>

          <Panel header={<span>Rating</span>}>
            {
              <RatingMenu
                className="gx-algolia-refinementList"
                attribute="ratings_value"
                max={5}
              />
            }
          </Panel>
          <hr></hr>

          <Panel header={<span>Price</span>}>
            {
              <RangeInput
                className="gx-algolia-refinementList"
                attribute="price"
              />
            }
          </Panel>
          <hr></hr>
        </div>
      </div>
    </div>
  </Sider>
);

export default Sidebar;
