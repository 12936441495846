import React from "react";
import { Col, Row, Divider } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IntlMessages from "util/IntlMessages";
import CardBox from "../../Card/CardBox/CardBox";
import TopBrandsCard from "../../Card/TopBrandsCard/TopBrandsCard";
import "./TopBrands.less";

const TopBrands = (props) => {
  const { data } = props;
  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    marginLeft: 10,
    marginRight: 10,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Row>
      <Col span={24}>
        <CardBox styleName="gx-text-center slidr-styl">
          <Divider className="mt-0 mb-2">
            <h4 className="nw-jr-fnt-clr">
              <IntlMessages id="topBrands" />
            </h4>
          </Divider>
          <Slider {...options}>
            {data &&
              data.data.map((brand, i) => {
                return (
                  <Col key={i} xl={4} lg={4} md={6} sm={8} xs={12}>
                    <TopBrandsCard data={brand} />
                  </Col>
                );
              })}
          </Slider>
        </CardBox>
      </Col>
    </Row>
  );
};

export default TopBrands;
