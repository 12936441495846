import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../../constants/ThemeSetting";
import { sidebarSettings } from "constants/Utilities";

const SidebarLogo = () => {
  const dispatch = useDispatch();
  // const {width, themeType, navCollapsed} = useSelector(({settings}) => settings);
  const { width, themeType, navCollapsed } = sidebarSettings;

  // let navStyle = useSelector(({ settings }) => settings.navStyle);
  var { navStyle } = sidebarSettings;

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              navStyle === NAV_STYLE_MINI_SIDEBAR ? "menu-unfold" : "menu-fold"
            } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else if (navStyle === NAV_STYLE_FIXED) {
                dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              } else {
                dispatch(onNavStyleChange(NAV_STYLE_FIXED));
              }
            }}
          />
        </div>
      ) : null}

      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ? (
          <img
            alt=""
            src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/w-logo.png`)}
            className="imgggggg"
          />
        ) : themeType === THEME_TYPE_LITE ? (
          <img
            alt=""
            src={require("assets/images/logo-white.png")}
            className="imgggggg"
          />
        ) : (
          <img
            alt="logo"
            src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/logo.png`)}
            className="imgggggg"
          />
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
