import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import { toTitleCase } from "../../../../util/services";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";

class ModalShoppingCard extends Component {
  removeHandler = () => {
    this.props.cartActions.removeWishlistItem({
      wishlist_id: this.props.data.id,
    });
  };

  buyNowHandler = (id) => {
    this.props.history.push(`/product/${id}`);
  };

  render() {
    const {
      // data,
      // grid,
      product,
      productIndex,
    } = this.props;
    const {
      // id,
      images,
      name,
      // ratings_value,
      // ratings_count,
      price,
      // old_price,
      discount_percentage,
      brand,
      seller,
      userSelections,
    } = product;
    // const {id,name,image}=seller
    var userSelectionsVariationsArray =
      userSelections.variations &&
      Object.keys(userSelections.variations).map((key) => [
        key,
        userSelections.variations[key],
      ]);
    return (
      <React.Fragment>
        <div
          style={{
            border: "1px solid rgb(235, 235, 235)",
            borderRadius: "5px",
            // alignItems: "center",
            marginBottom: "10px",
          }}
          className="gx-news-item p-3 shpnHovr msPntr content-jr-grd"
        >
          <div className="gx-news-thumb">
            {/* <Link to="/seller/dashboard/orderManagement/all"> */}
            {productIndex === 0 ? (
              <span className="new-lable-cart">New</span>
            ) : null}

            <img
              style={{ borderRadius: "5px", width: "100px" }}
              alt="Remy Sharp"
              src={images[0]}
            />
            {/* </Link> */}
          </div>
          <div className="gx-news-content-jr">
            {/* <Link to="/seller/dashboard/orderManagement/all"> */}
            <div>
              <b>Product : </b>
              {name}
            </div>
            {/* </Link> */}
            <div>
              <b>Brand : </b>
              {brand}
            </div>
            <div>
              <b>Price : </b>
              {`${DEFAULT_CURRENCY_SHORT} ${price}`}
              {discount_percentage ? (
                <span className="fnshed-clr ml-2">
                  {discount_percentage}% off
                </span>
              ) : (
                ""
              )}
            </div>
            <div>
              {userSelections && userSelections.quantity && (
                <span>
                  <b>Quantity : </b>
                  {userSelections.quantity}
                </span>
              )}
            </div>
            <div>
              {userSelectionsVariationsArray &&
                userSelectionsVariationsArray.map((variation, i) => {
                  return (
                    <span>
                      <b>{toTitleCase(variation[0])} </b> : {variation[1] + " "}
                    </span>
                  );
                })}
            </div>
            <div>
              <b>Seller : </b>
              {seller.name}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalShoppingCard)
);
