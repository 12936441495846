import React, { Component } from "react";
import { Col, Row } from "antd";
import { withRouter } from "react-router-dom";
import WishlistContainer from "./WishlistContainer/WishlistContainer";

class WishlistPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      wishlistAPIKey: null,
      wishlistIndex: null,
    };
  }
  componentDidMount() {
    const user = localStorage.getItem("user");
    // const userObj = JSON.parse(user);

    if (!user) {
      this.props.history.push("/login");
    } else {
      const userObj = JSON.parse(user);
      const wishlistAPIKey = userObj.algolia.wishlist.api_key;
      const wishlistIndex = userObj.algolia.wishlist.index;
      this.setState({
        user: user,
        wishlistAPIKey: wishlistAPIKey,
        wishlistIndex: wishlistIndex,
      });
    }
  }
  render() {
    const { user, wishlistAPIKey, wishlistIndex } = this.state;
    return (
      <Row>
        <Col span={24}>
          <div className="mt-4">
            <WishlistContainer
              user={user}
              wishlistAPIKey={wishlistAPIKey}
              wishlistIndex={wishlistIndex}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(WishlistPage);
