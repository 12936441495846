import React, { Component } from "react";
import SellerForm from "../../components/Buyer/SellerForm/SellerForm";
import { Carousel } from "react-bootstrap";
import "./SellerPage.less";
import { Col, Row, Card } from "antd";

class SellerPage extends Component {
  render() {
    return (
      <div className="gx-container" style={{ paddingTop: 20 }}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card
              className="gx-card"
              style={{ backgroundColor: "rgb(250, 250, 250)" }}
            >
              <div className="">
                <Row>
                  <div className="gdrd-jr-car" style={{ width: "100%" }}>
                    <div className="remove-sldr" style={{ height: "100%" }}>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className="">
                          <Carousel>
                            <Carousel.Item>
                              <img
                                style={{
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/sellerRegistration/seller_registration_01.jpg`)}
                                alt="Moto 360"
                              />
                            </Carousel.Item>
                            <Carousel.Item>
                              <img
                                style={{
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/sellerRegistration/seller_registration_02.jpg`)}
                                alt="Moto 360"
                              />
                            </Carousel.Item>
                            <Carousel.Item>
                              <img
                                style={{
                                  borderRadius: "5px",
                                  width: "100%",
                                }}
                                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/sidebarBanners/sellerRegistration/seller_registration_03.jpg`)}
                                alt="Moto 360"
                              />
                            </Carousel.Item>
                          </Carousel>
                        </div>
                      </Col>
                    </div>
                    <div>
                      <Col
                        xl={24}
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ height: "100%" }}
                        className="d-flex flex-column"
                      >
                        <div className="gx-card p-4 mb-0 flex-fill">
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <h5>Become a seller</h5>
                          </div>
                          <Card
                            style={{
                              backgroundColor: "#fafafa",
                              marginBottom: "0px",
                            }}
                          >
                            <SellerForm />
                          </Card>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SellerPage;
