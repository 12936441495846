import React, { useState, useEffect } from "react";
import { Avatar, Timeline } from "antd";
import ActivityItem from "./ActivityItem";
import Widget from "../../../Common/Widget/Widget";
const TimeLineItem = Timeline.Item;

function getName(task, shape) {
  if (task.image === "") {
    let nameSplit = task.title.split(" ");
    if (task.title.split(" ").length === 1) {
      const initials = nameSplit[0].charAt(0).toUpperCase();
      return (
        <Avatar shape={shape} className="gx-size-40 gx-bg-primary">
          {initials}
        </Avatar>
      );
    } else {
      const initials =
        nameSplit[0].charAt(0).toUpperCase() +
        nameSplit[1].charAt(0).toUpperCase();
      return (
        <Avatar shape={shape} className="gx-size-40 gx-bg-cyan">
          {initials}
        </Avatar>
      );
    }
  } else {
    return <Avatar shape={shape} className="gx-size-40" src={task.image} />;
  }
}

const RecentActivity = (props) => {
  const [limit, setLimit] = useState(3);
  const [shape, setShape] = useState(props.shape);

  useEffect(() => {
    setShape(props.shape);
    if (window.innerWidth < 575) {
      setLimit(1);
    }
  }, [props.shape]);

  const { sellerActivities } = props;
  return (
    <Widget styleName="flex-fill">
      <div className="gx-entry-sec">
        <h6 className="">Recent Activities</h6>
        {sellerActivities.data.slice(0, limit).map((activity, index) => (
          <div className="gx-timeline-info" key={"activity" + index}>
            <h4 className="gx-timeline-info-day">{activity.day}</h4>
            <Timeline>
              {activity.activities.map((task, index) => {
                return (
                  <div
                    className=""
                    style={{
                      height: "100%",
                      border: "1px solid rgb(235, 235, 235)",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <TimeLineItem
                      key={"timeline" + index}
                      mode="alternate"
                      dot={getName(task, shape)}
                      className="shpnHovr p-2 msPntr"
                    >
                      <ActivityItem task={task} />
                    </TimeLineItem>
                  </div>
                );
              })}
            </Timeline>
          </div>
        ))}
      </div>
    </Widget>
  );
};

export default RecentActivity;
