import React from "react";
import Auxiliary from "util/Auxiliary";
import { Row, Divider } from "antd";
import LatestProductCard from "./LatestProductCard";

const LatestProductCardContainer = ({ data, isLoading }) => {
  const {
    name,
    // subTitle,
    // desc,
    products,
  } = data;
  return (
    <Auxiliary>
      <Divider orientation="left" className="mt-0 mb-2">
        <h5 className="nw-jr-fnt-clr">{`Latest ${name}`}</h5>
      </Divider>

      <Row>
        {products &&
          products.map((product, i) => {
            if (i < 4) {
              return (
                <LatestProductCard
                  latestProductDetails={product}
                  key={i}
                  isLoading={isLoading}
                />
              );
            } else {
              return null;
            }
          })}
      </Row>
    </Auxiliary>
  );
};

export default LatestProductCardContainer;
