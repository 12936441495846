import { createLogic } from "redux-logic";

import actions from "./actions";
import types from "./types";
import endPoints from "../../../util/EndPoints";
import API from "../../../util/HTTPClient";

const getSellerStore = createLogic({
  type: types.GET_SELLER_STORE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_SELLER_STORE + `/${action.payload.sellerId}`)
      .then((resp) => resp.data)
      .then((data) => {
        dispatch(actions.getSellerStoreSuccess(data.data));
      })
      .catch((err) => {
        var errorMessage = "Something went wrong..!";
        if (err && err.code === "ECONNABORTED") {
          errorMessage = "Please check your internet connection.";
        }

        dispatch(
          actions.getSellerStoreFail({
            title: "Error!",
            message: err.response || errorMessage,
          })
        );
      })
      .then(() => done());
  },
});

export default [getSellerStore];
