import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Input, Card, Select, Col, Form } from "antd";
import {
  dangerousGoods,
  FormItemLayout,
} from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import {
  FormItemLayout2,
  lengthMeasurements,
  packageTypes,
  weightMeasurements,
} from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";

const Option = Select.Option;
const InputGroup = Input.Group;
const FormItem = Form.Item;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class PackageDetails extends Component {
  render() {
    return (
      <div className="mb-3">
        <div style={{ display: "flex", alignItems: "center" }} className="mb-3">
          <h6 className="mb-0 mr-2">Package Details</h6>

          <ExtraInfo placement={"top"} title={extraInfoDetails.packageDetail} />
        </div>
        <Card className="jr-fld-catch " style={{ backgroundColor: "#fafafa" }}>
          <FormItem
            {...FormItemLayout}
            label="Package Type"
            required
            id="packageType"
          >
            <Field
              formItemLayout={FormItemLayout2}
              name="packageType"
              component={ASelectField}
              style={{ width: "100%" }}
              placeholder="Select"
              hasFeedback
              suffix={<ExtraInfo title={extraInfoDetails.dangerousGoods} />}
            >
              {packageTypes.map((packageType, i) => {
                return (
                  <Option key={i} value={packageType.value}>
                    {packageType.label}
                  </Option>
                );
              })}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.dangerousGoods} />
            </span>
          </FormItem>
          <span
            style={{ position: "absolute", right: "25px", bottom: "48px" }}
          ></span>

          <FormItem {...FormItemLayout} label="Package Weight" required>
            <Field
              formItemLayout={FormItemLayout2}
              name="packageWieght"
              component={AInputField}
              placeholder="Weight "
              type="number"
              min="0"
              // normalize={(val) => (val || "").replace(/[^\d]/g, "")}
              hasFeedback
              style={{ width: "100%" }}
              // addonAfter="(oz)"
              addonAfter={
                <Field
                  // formItemLayout={FormItemLayout2}
                  // className="flag-selector"
                  name="packageWieghtMeasurement"
                  component={ASelectField}
                  hasFeedback
                  // style={{ width: 120 }}
                  // showSearch
                  initialValue="kg"
                >
                  {weightMeasurements.map((measurement, i) => {
                    return (
                      <Option value={measurement.value} key={i}>
                        {measurement.label}
                      </Option>
                    );
                  })}
                </Field>
              }
              onWheel={(e) => e.target.blur()}
            />
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.packageWieght} />
            </span>
          </FormItem>

          <hr />
          <div className="mb-3 fnshed-clr">
            Package Dimensions <i>(Inch)</i>
          </div>
          <InputGroup size="large" className="gx-mb-3">
            <Col sm={8} xs={12}>
              <FormItem {...FormItemLayout} label="Length" required>
                <Field
                  formItemLayout={FormItemLayout2}
                  name="packageLength"
                  component={AInputField}
                  placeholder="Length"
                  style={{ width: "100%" }}
                  hasFeedback
                  // addonAfter="(in)"
                  addonAfter={
                    <Field
                      // formItemLayout={FormItemLayout2}
                      // className="flag-selector"
                      name="packageLengthMeasurement"
                      component={ASelectField}
                      hasFeedback
                      // style={{ width: 120 }}
                      // showSearch
                      initialValue="cm"
                    >
                      {lengthMeasurements.map((measurement, i) => {
                        return (
                          <Option value={measurement.value} key={i}>
                            {measurement.label}
                          </Option>
                        );
                      })}
                    </Field>
                  }
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min="0"

                  // suffix={<ExtraInfo title={extraInfoDetails.packageLength} />}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "8px",
                    bottom: "-37px",
                  }}
                >
                  <ExtraInfo title={extraInfoDetails.packageLength} />
                </span>
              </FormItem>
            </Col>
            <Col sm={8} xs={12}>
              <FormItem {...FormItemLayout} label="Width" required>
                <Field
                  formItemLayout={FormItemLayout2}
                  name="packageWidth"
                  component={AInputField}
                  placeholder="Width (in)"
                  style={{ width: "100%" }}
                  hasFeedback
                  // addonAfter="(in)"
                  addonAfter={
                    <Field
                      // formItemLayout={FormItemLayout2}
                      // className="flag-selector"
                      name="packageLengthMeasurement"
                      component={ASelectField}
                      hasFeedback
                      // style={{ width: 120 }}
                      // showSearch
                      initialValue="cm"
                    >
                      {lengthMeasurements.map((measurement, i) => {
                        return (
                          <Option value={measurement.value} key={i}>
                            {measurement.label}
                          </Option>
                        );
                      })}
                    </Field>
                  }
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min="0"

                  // suffix={<ExtraInfo title={extraInfoDetails.packageWidth} />}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "-37px",
                  }}
                >
                  <ExtraInfo title={extraInfoDetails.packageWidth} />
                </span>
              </FormItem>
            </Col>
            <Col sm={8} xs={12}>
              <FormItem {...FormItemLayout} label="Height" required>
                <Field
                  formItemLayout={FormItemLayout2}
                  name="packageHeight"
                  component={AInputField}
                  placeholder="Height (in)"
                  style={{ width: "100%" }}
                  hasFeedback
                  // addonAfter="(in)"
                  addonAfter={
                    <Field
                      // formItemLayout={FormItemLayout2}
                      // className="flag-selector"
                      name="packageLengthMeasurement"
                      component={ASelectField}
                      hasFeedback
                      // style={{ width: 120 }}
                      // showSearch
                      initialValue="cm"
                    >
                      {lengthMeasurements.map((measurement, i) => {
                        return (
                          <Option value={measurement.value} key={i}>
                            {measurement.label}
                          </Option>
                        );
                      })}
                    </Field>
                  }
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min="0"

                  // suffix={<ExtraInfo title={extraInfoDetails.packageHeight} />}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "-37px",
                  }}
                >
                  <ExtraInfo title={extraInfoDetails.packageHeight} />
                </span>
              </FormItem>
            </Col>
          </InputGroup>

          <FormItem
            {...FormItemLayout}
            label="Dangerous Goods"
            id="dangerousGoods"
          >
            <Field
              mode="multiple"
              formItemLayout={FormItemLayout2}
              name={"dangerousGoods"}
              component={ASelectField}
              style={{ width: "100%" }}
              placeholder="Select Dangerous goods"
              hasFeedback
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              // suffix={<ExtraInfo title={extraInfoDetails.dangerousGoods} />}
            >
              {dangerousGoods.map((dangergood, i) => {
                return (
                  <Option key={i} value={dangergood.value}>
                    {dangergood.label}
                  </Option>
                );
              })}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.dangerousGoods} />
            </span>
          </FormItem>
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PackageDetails = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(PackageDetails);

// You have to connect() to any reducers that you wish to connect to yourself
PackageDetails = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(PackageDetails);

export default PackageDetails;
