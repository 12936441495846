import React from "react";
import ProductQnAContainer from "./ProductQnAContainer/ProductQnAContainer";

const ProductQnA = ({
  algolia_api_key,
  algolia_api_key_my_faqs,
  isFaqsExists,
  token,
  isMine,
  isOwnProduct,
}) => {
  return (
    <React.Fragment>
      <h5 className="mt-3 mb-3 ml-2 display-web-s">
        Buyer Questions & Answers
      </h5>
      <h5 className="mt-3 mb-3 ml-2 display-mob-s">Buyer Q & A</h5>

      {token && (
        <ProductQnAContainer
          algolia_api_key={algolia_api_key_my_faqs}
          isMine={true}
          isOwnProduct={isOwnProduct}
        />
      )}
      {isFaqsExists && (
        <ProductQnAContainer algolia_api_key={algolia_api_key} />
      )}
    </React.Fragment>
  );
};

export default ProductQnA;
