import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, Col, Row, Spin } from "antd";
import { NotificationManager } from "react-notifications";
import { postList, user } from "./data";
import { cartActions } from "../Cart/ducks";
import { profileActions } from "../ProfilePage/ducks";
import { payment_methods } from "../../constants/Utilities";
import PlaceOrder from "../../components/Buyer/Checkout/PlaceOrder/PlaceOrder";
import CheckoutDetailsContainer from "../../components/Buyer/Checkout/CheckoutDetailsContainer/CheckoutDetailsContainer";
import { reduxForm, getFormValues } from "redux-form";
import IntlMessages from "util/IntlMessages";
import { getDefaultShippingAddress } from "../../util/services";

class Checkout extends Component {
  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      this.props.profileActions.getShippingAddress();
      var checkoutItemsString = localStorage.getItem("checkoutItems");
      if (checkoutItemsString) {
        var checkoutItemsObj = JSON.parse(checkoutItemsString);
        if (checkoutItemsObj.length !== 0) {
          this.props.cartActions.initializeReduxCheckoutCart({
            checkoutItemsObj,
          });
        }
        // getter
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shippingAddress !== this.props.shippingAddress) {
      const { shippingAddress } = this.props;
      let defaultShippingAddress = getDefaultShippingAddress(shippingAddress);

      // debugger;
      var checkoutItemsString = localStorage.getItem("checkoutItems");
      if (checkoutItemsString) {
        var checkoutItemsObj = JSON.parse(checkoutItemsString);
        if (checkoutItemsObj.length !== 0) {
          this.props.cartActions.checkOutCalulation({
            checkoutItemsObj,
            defaultShippingAddress,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    // remove
    var user = localStorage.getItem("user");
    if (!!user) {
      this.props.cartActions.resetCheckoutItems();
      this.props.cartActions.clearUserSelections();
      localStorage.removeItem("checkoutItems");
    }
  }

  makePaymentHandler = () => {
    const { checkoutDetails, checkoutItems, paymentMethod } = this.props;
    const { shippingInfo, billInfo } = checkoutDetails;

    if (paymentMethod.method === payment_methods.paypal) {
      if (shippingInfo || checkoutItems.items.length === 0) {
        if (!shippingInfo) {
          NotificationManager.error(
            <IntlMessages id="notification.validation.checkout.shippingInfo.content" />,
            <IntlMessages id="notification.validation.checkout.shippingInfo.title" />
          );
        }
        if (checkoutItems.items.length === 0) {
          NotificationManager.error(
            <IntlMessages id="notification.validation.checkout.item.content" />,
            <IntlMessages id="notification.validation.checkout.item.title" />
          );
        } else {
          var cartDto = checkoutItems.items.map((item, i) => {
            return {
              product_id: item.id,
              qty: item.userSelections.quantity,
              shipping_rate_id: item.selectedShippingMethod.rate_id,
              shipment_id: item.selectedShippingMethod.shipment_id,
              variations: item.userSelections && item.userSelections.variations,
            };
          });

          var paymentDto = {
            payment_method: paymentMethod.method,
            cart: cartDto,
            ship_to: {
              first_name: shippingInfo.first_name,
              last_name: shippingInfo.last_name,
              zip: shippingInfo.zip,
              city: shippingInfo.city,
              state: shippingInfo.state,
              address1: shippingInfo.address1,
              address2: shippingInfo.address2,
              country: shippingInfo.country,
            },
          };

          this.props.cartActions.makePayment({ paymentDto: paymentDto });
        }
      } else {
        let cartDto = checkoutItems.items.map((item, i) => {
          return {
            product_id: item.id,
            qty: item.userSelections.quantity,
            shipping_rate_id: item.selectedShippingMethod.rate_id,
            shipment_id: item.selectedShippingMethod.shipment_id,
            variations: item.userSelections && item.userSelections.variations,
          };
        });

        let paymentDto = {
          payment_method: paymentMethod.method,
          cart: cartDto,
          ship_to: {
            first_name: shippingInfo.first_name,
            last_name: shippingInfo.last_name,
            zip: shippingInfo.zip,
            city: shippingInfo.city,
            state: shippingInfo.state,
            address1: shippingInfo.address1,
            address2: shippingInfo.address2,
            country: shippingInfo.country,
          },
        };

        this.props.cartActions.makePayment({ paymentDto: paymentDto });
      }
    } else if (paymentMethod.method === payment_methods.credit_card) {
      var cardDetails = this.generateCardDetails();

      var { cardInfo } = cardDetails;

      if (!shippingInfo || !cardInfo || checkoutItems.items.length === 0) {
        if (!shippingInfo) {
          NotificationManager.error(
            <IntlMessages id="notification.validation.checkout.shippingInfo.content" />,
            <IntlMessages id="notification.validation.checkout.shippingInfo.title" />
          );
        }
        if (!cardInfo) {
          NotificationManager.error(
            <IntlMessages id="notification.validation.checkout.payment.content" />,
            <IntlMessages id="notification.validation.checkout.payment.title" />
          );
        }
        if (checkoutItems.items.length === 0) {
          NotificationManager.error(
            <IntlMessages id="notification.validation.checkout.item.content" />,
            <IntlMessages id="notification.validation.checkout.item.title" />
          );
        }
      } else {
        const { credit_card_number, expire_year, expire_month, cvv } =
          cardDetails;

        let cartDto = checkoutItems.items.map((item, i) => {
          return {
            product_id: item.id,
            qty: item.userSelections.quantity,
            shipping_rate_id: item.selectedShippingMethod.rate_id,
            shipment_id: item.selectedShippingMethod.shipment_id,
            variations: item.userSelections && item.userSelections.variations,
          };
        });

        let paymentDto = {
          payment_method: paymentMethod.method,
          cart: cartDto,
          ship_to: {
            first_name: shippingInfo.first_name,
            last_name: shippingInfo.last_name,
            zip: shippingInfo.zip,
            city: shippingInfo.city,
            state: shippingInfo.state,
            address1: shippingInfo.address1,
            address2: shippingInfo.address2,
            country: shippingInfo.country,
          },
          credit_card_details: {
            credit_card_number: credit_card_number,
            expire_year: expire_year,
            expire_month: expire_month,
            cvv: cvv,
          },
          bill_to: {
            first_name: billInfo.first_name,
            last_name: billInfo.last_name,
            zip: billInfo.zip,
            city: billInfo.city,
            state: billInfo.state,
            address1: billInfo.address1,
            address2: billInfo.address2,
            country: billInfo.country,
          },
        };
        this.props.cartActions.makePayment({ paymentDto: paymentDto });
      }
    }
  };

  generateCardDetails() {
    var { cardNumber, cvc, expire } = this.props.cardDetails;

    var dateObj = new Date();
    var currentyear = dateObj.getUTCFullYear();
    var month = null;
    var year = null;
    var expire_year = null;
    var cardInfo = true;

    if (expire) {
      month = parseInt(expire.split("/")[0]);
      year = expire.split("/")[1];
      expire_year = parseInt(String(currentyear).slice(0, 2) + year);
    }
    if (cardNumber) {
      cardNumber = cardNumber.replace(/^\D+/g, "").replace(/\s/g, "");
    }
    if (cvc) {
      cvc = cvc.replace(/^\D+/g, "").replace(/\s/g, "");
    }

    if (cardNumber === null || expire_year === null || month === null) {
      cardInfo = false;
    }

    var cardInfoDto = {
      credit_card_number: cardNumber,
      expire_year: expire_year,
      expire_month: month,
      cvv: cvc,
      cardInfo: cardInfo,
    };

    return cardInfoDto;
  }

  render() {
    const {
      // checkoutItems,
      updateShippingCost,
      makePayemt,
      shippingAddress,
      selectedItems,
      checkoutItemsSummery,
    } = this.props;
    let pending = updateShippingCost.pending || makePayemt.pending;
    return (
      <div className="gx-container">
        <Spin spinning={pending}>
          <Row style={{ paddingTop: 20 }}>
            <Col xl={18} lg={16} md={24} sm={24} xs={24}>
              <Card
                className="gx-card"
                style={{ backgroundColor: "rgb(250, 250, 250)" }}
              >
                <CheckoutDetailsContainer
                  shippingAddress={shippingAddress}
                  postList={postList}
                  user={user}
                />
              </Card>
            </Col>

            <Col xl={6} lg={8} md={24} sm={24} xs={24}>
              <PlaceOrder
                checkoutItemsSummery={checkoutItemsSummery}
                selectedItems={selectedItems}
                makePaymentHandler={this.makePaymentHandler}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myCart: state.Cart.myCart,
    checkoutItems: state.Cart.checkoutItems,
    checkoutItemsSummery: state.Cart.checkoutItemsSummery,
    checkoutDetails: state.Cart.checkoutDetails,
    paymentMethod: state.Cart.paymentMethod,
    updateShippingCost: state.Cart.updateShippingCost,
    makePayemt: state.Cart.makePayemt,
    shippingAddress: state.Profile.shippingAddress,
    cardDetails: getFormValues("PaymentMethods")(state),
    selectedItems: state.Cart.selectedItems,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  reduxForm()(connect(mapStateToProps, mapDispatchToProps)(Checkout))
);
