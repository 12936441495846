import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccountHeader from "components/Buyer/AccountHeader/AccountHeader";
import AccountContent from "components/Buyer/AccountContent/AccountContent";
import { auth } from "../../firebase/firebase";

class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      currentUser: null,
    };
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await this.setCurrentUserDetails(user);
        // User is signed in.
      } else {
        // No user is signed in.
        // alert("Please log in");
      }
    });
  };
  setCurrentUserDetails = (currentUser) => {
    this.setState({ currentUser });
  };
  render() {
    const { currentUser } = this.state;
    return (
      <div className="mt-4" style={{ minHeight: "600px" }}>
        {/* <Row>
          <Col xl={6} lg={6} md={8} sm={24} xs={24}>
            <AccountSideBar />
          </Col>
          <Col xl={18} lg={18} md={16} sm={24} xs={24}> */}
        <AccountHeader currentUser={currentUser} />
        <AccountContent currentUser={currentUser} />
        {/* </Col>
        </Row> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.Cart.product,
  };
}

export default withRouter(connect(mapStateToProps, null)(AccountPage));
