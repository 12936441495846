import React, { Component } from "react";
import { Button, Table } from "antd";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import OrderReviewModal from "../../../Modal/OrderReviewModal/OrderReviewModal";

import "./MyOrdersList.less";

class MyOrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the CRM column
      loading: false,
      myCartObj: null,
      filteredInfo: null,
      sortedInfo: null,
    };
  }

  renderProductVariations = (product) => {
    return (
      product &&
      product.variations &&
      Object.entries(product.variations).map(([key, value], index) => {
        return (
          <span>
            <b>{key.charAt(0).toUpperCase() + key.substring(1)}</b> : {value}
            {Object.entries(product.variations).length !== index + 1 ? (
              <span className="pl-2 pr-2">|</span>
            ) : (
              " "
            )}
          </span>
        );
      })
    );
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    });
  };

  processToPaymentHandler = (data) => {
    const { id } = data.product;
    this.props.history.push(`/product/${id}`);
  };
  render() {
    const { hits } = this.props;
    this.columns = [
      {
        title: "Product",
        width: "140px",
        render: (text, record) => (
          <img
            alt="avatar"
            src={record.product && record.product.img}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          />
        ),
      },
      {
        title: "Details",
        dataIndex: "status",
        render: (text, record) => {
          var first_name =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.first_name;
          var last_name =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.last_name;

          var address1 =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.address1;

          var address2 =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.address2;

          var city =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.city;

          var state =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.state;

          var country =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.country;

          var zip =
            record.shipping &&
            record.shipping.address &&
            record.shipping.address.zip;

          return (
            <React.Fragment>
              <div>
                <div>
                  <b>Seller : </b>
                  {record.seller ? (
                    <Link
                      className="crd-fnt-jr"
                      to={`/seller/${record.seller.id}`}
                    >
                      {record.seller && record.seller.name}
                    </Link>
                  ) : null}
                </div>
                <div>
                  <b>Ordered Date : </b>
                  {moment(record.created_at).format("LLLL")}
                </div>
                <div>
                  <b>Ordered ID : </b>
                  {record.objectID}
                </div>
                <div>
                  <b>Product Name : </b>
                  {record.product && record.product.name ? (
                    <Link
                      className="crd-fnt-jr"
                      to={`/product/${record.product.id}`}
                    >
                      {record.product.name}
                    </Link>
                  ) : null}
                </div>
                <div>
                  <b>Overall Status : </b>
                  <span
                    className={
                      record.status === "In Progress"
                        ? "inprogs-clr"
                        : record.status === "Paid"
                        ? "paid-clr"
                        : record.status === "Cancelled"
                        ? "cancle-clr"
                        : record.status === "Delivered"
                        ? "fnshed-clr"
                        : record.status === "Returned"
                        ? "retn-clr"
                        : "shpp-clr"
                    }
                  >
                    {record.status}
                  </span>
                </div>
              </div>
              <div>{this.renderProductVariations(record.product)}</div>
              <div>
                <b>Quantity : </b>
                {record.transaction && record.transaction.qty}
              </div>
              <div>
                <b>Shipping To : </b>
                {first_name + " " + last_name + ", "}
                {`${address1}, ${address2}, ${city}, ${state}, ${country}, ${zip}`}
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: "Amount",
        width: "200px",
        className: "",
        render: (text, record) => {
          return (
            <React.Fragment>
              <div>
                <span style={{ color: "#ff6900", fontSize: "20px" }}>
                  ${record.transaction && record.transaction.grand_total}
                </span>
                {/* <div>(Price + Shipping)</div> */}
              </div>
              <hr />
              <div>
                Product Price :{" "}
                <span className="paid-clr">
                  ${record.transaction && record.transaction.product_price}
                </span>
              </div>
              <div>
                Shipping Cost :{" "}
                <span className="paid-clr">
                  ${record.transaction && record.transaction.shipping_cost}
                </span>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        title: "Action",
        width: "250px",
        render: (text, record) => {
          const { status } = record;
          return (
            <React.Fragment>
              {record.shipping && record.shipping.tracking_url ? (
                <div>
                  <Button
                    block
                    className="gx-btn-outline-cyan"
                    rel="noopener noreferrer"
                    href={record.shipping.tracking_url}
                    target="_blank"
                  >
                    <i className="fad fa-location mr-1"></i>
                    Track Order
                  </Button>
                </div>
              ) : null}

              {status === "In Progress" || status === "Shipped" ? (
                <Button
                  block
                  className="gx-btn-outline-cyan"
                  rel="noopener noreferrer"
                  onClick={() => this.processToPaymentHandler(record)}
                >
                  <i className="fad fa-location mr-1"></i>
                  {status === "Shipped" ? "View" : "Proceed to pavement"}
                </Button>
              ) : (
                <div>
                  <OrderReviewModal
                    canReview={record.can_review}
                    orderID={record.objectID}
                    ordarAddressItemsHandler={this.ordarAddressItemsHandler}
                    isCreate={true}
                  />
                </div>
              )}
            </React.Fragment>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <Table
          pagination={false}
          columns={this.columns}
          dataSource={hits}
          onChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MyOrdersList);
