import React from "react";
import { Col, Row } from "antd";
import WelComeCard from "../../Card/WelcomeCard/WelcomeCard";
import SiteVisit from "../../Card/SiteVisit/SiteVisit";

function SiteVisitors({ sellerStatistics }) {
  return (
    <div className="gx-card">
      <div className="gx-card-body">
        <Row>
          <Col xl={9} lg={9} md={9} sm={9} xs={9}>
            <WelComeCard sellerStatistics={sellerStatistics} />
          </Col>

          <Col xl={15} lg={15} md={15} sm={15} xs={15} className="gx-visit-col">
            <SiteVisit sellerStatistics={sellerStatistics} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SiteVisitors;
