import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Input, Card, Select, Row, Col, Form } from "antd";
import { ItemFormItemLayout } from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import { allCountriesJson } from "../../../../DummyData/AllCountry";
import { FormItemLayout } from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";

const FormItem = Form.Item;
const Option = Select.Option;
const InputGroup = Input.Group;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);

class ShippingDetails extends Component {
  render() {
    const { fieldValues } = this.props;
    return (
      <div className="mb-3">
        <h6 className="mb-3">Shipping Details</h6>
        <Card style={{ backgroundColor: "#fafafa" }}>
          {fieldValues &&
          fieldValues.internationalShippingMethod === "calculated" ? (
            <React.Fragment>
              <div className="fnshed-clr mb-2">Return Address:</div>
              <div className="single-row-address">
                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Name" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="name"
                        component={AInputField}
                        placeholder="Name"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Address 1" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="address1"
                        component={AInputField}
                        placeholder="Address 1"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Address 2">
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="address2"
                        component={AInputField}
                        placeholder="Address 2"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="City" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="itemCity"
                        component={AInputField}
                        placeholder="City"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem
                      {...FormItemLayout}
                      label="State / Province"
                      required
                    >
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="state"
                        component={AInputField}
                        placeholder="State / Province"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>

                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem
                      {...FormItemLayout}
                      label="Country/ Region"
                      required
                      id="itemCountry"
                    >
                      <Field
                        showSearch
                        formItemLayout={ItemFormItemLayout}
                        name="itemCountry"
                        component={ASelectField}
                        style={{ width: "100%" }}
                        placeholder="Select"
                        hasFeedback
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allCountriesJson.map((country, i) => {
                          return (
                            <Option key={i} value={country.alpha_3}>
                              {country.name}
                            </Option>
                          );
                        })}
                      </Field>
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="ZIP Code" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="itemZipCode"
                        component={AInputField}
                        placeholder="ZIP Code"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>

                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Phone" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="phone"
                        component={AInputField}
                        placeholder="Phone"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                </Row>
              </div>

              <div className="double-row-address">
                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Name" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="name"
                        component={AInputField}
                        placeholder="Name"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem
                      {...FormItemLayout}
                      label="State / Province"
                      required
                    >
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="state"
                        component={AInputField}
                        placeholder="State / Province"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Address 1" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="address1"
                        component={AInputField}
                        placeholder="Address 1"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem
                      {...FormItemLayout}
                      label="Country/ Region"
                      required
                      id="itemCountry"
                    >
                      <Field
                        showSearch
                        formItemLayout={ItemFormItemLayout}
                        name="itemCountry"
                        component={ASelectField}
                        style={{ width: "100%" }}
                        placeholder="Select"
                        hasFeedback
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allCountriesJson.map((country, i) => {
                          return (
                            <Option key={i} value={country.alpha_3}>
                              {country.name}
                            </Option>
                          );
                        })}
                      </Field>
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Address 2">
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="address2"
                        component={AInputField}
                        placeholder="Address 2"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>

                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="ZIP Code" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="itemZipCode"
                        component={AInputField}
                        placeholder="ZIP Code"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row>
                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="City" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="itemCity"
                        component={AInputField}
                        placeholder="City"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>

                  <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                    <FormItem {...FormItemLayout} label="Phone" required>
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="phone"
                        component={AInputField}
                        placeholder="Phone"
                        hasFeedback
                      />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="fnshed-clr mb-2">Item Location : </div>
              <InputGroup size="large" className="">
                <Col sm={24} xs={24} md={24} lg={10} xl={10}>
                  <div className="jr-fld-catch ">
                    <FormItem
                      {...FormItemLayout}
                      label="Country/ region"
                      required
                      id="itemCountry"
                    >
                      <Field
                        showSearch
                        formItemLayout={ItemFormItemLayout}
                        name="itemCountry"
                        component={ASelectField}
                        style={{ width: "100%" }}
                        placeholder="Select"
                        hasFeedback
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        suffix={
                          <ExtraInfo title={extraInfoDetails.productStock} />
                        }
                      >
                        {allCountriesJson.map((country, i) => {
                          return (
                            <Option key={i} value={country.alpha_3}>
                              {country.name}
                            </Option>
                          );
                        })}
                      </Field>
                    </FormItem>
                  </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={8} xl={8}>
                  <FormItem {...FormItemLayout} label="City/ State" required>
                    <Field
                      formItemLayout={ItemFormItemLayout}
                      name="itemCity"
                      component={AInputField}
                      placeholder="City/ State"
                      hasFeedback
                      suffix={<ExtraInfo title={extraInfoDetails.itemCity} />}
                    />
                  </FormItem>
                </Col>
                <Col sm={24} xs={24} md={24} lg={6} xl={6}>
                  <FormItem {...FormItemLayout} label="ZIP Code" required>
                    <Field
                      formItemLayout={ItemFormItemLayout}
                      name="itemZipCode"
                      component={AInputField}
                      placeholder="ZIP Code"
                      hasFeedback
                    />
                  </FormItem>
                </Col>
              </InputGroup>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ShippingDetails = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(ShippingDetails);

// You have to connect() to any reducers that you wish to connect to yourself
ShippingDetails = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(ShippingDetails);

export default ShippingDetails;
