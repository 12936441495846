import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Card } from "antd";
import { resolutionCenterActions } from "../ducks";
import ReportCardList from "./ReportCardList/ReportCardList";

class ReportItemPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      reportItemAPIKey: null,
      Index: null,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    if (!user) {
      this.props.history.push("/login");
    } else {
      const userObj = JSON.parse(user);
      const reportItemAPIKey = userObj.algolia.buyer_orders.api_key;
      const Index = userObj.algolia.buyer_orders.index;
      this.setState({
        user: user,
        reportItemAPIKey: reportItemAPIKey,
        Index: Index,
      });
    }
  }

  render() {
    return (
      <div className="gx-container pt-4">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card
              className="gx-card"
              style={{
                backgroundColor: "rgb(250, 250, 250)",
                minHeight: "500px",
              }}
            >
              <h5 className="mb-3 ml-2">
                <Link className="crd-fnt-jr" to="/resolution-center/all">
                  Resolution Center
                </Link>
                {" > "}Recent Orders
              </h5>
              <ReportCardList />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportItem: state.Report,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    resolutionCenterActions: bindActionCreators(
      resolutionCenterActions,
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportItemPage)
);
