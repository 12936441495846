import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  productCount: {
    ...initialStateModal,
  },
  createProduct: {
    ...initialStateModal,
  },
  getProduct: {
    ...initialStateModal,
  },
  updateProduct: {
    ...initialStateModal,
  },
  deleteProduct: {
    ...initialStateModal,
  },
  changeProductActiveStatus: {
    ...initialStateModal,
  },
  productCategories: {
    ...initialStateModal,
  },
  replyQuestion: {
    ...initialStateModal,
  },
  replyQnA: {
    ...initialStateModal,
  },
  deleteQnA: {
    ...initialStateModal,
  },
  deleteQnAReply: {
    ...initialStateModal,
  },
  shippingMethod: {
    ...initialStateModal,
  },
  productImages: {
    image_keys: [],
  },
  variationsImages: {},
  variationTableDto: {},
};

// Reducers from redux-actions
export default handleActions(
  {
    // *************************CLEAR_PRODUCT_ACTIVE_STATUS_DATA********************************//
    [types.CLEAR_PRODUCT_ACTIVE_STATUS_DATA]: (state, { payload }) => {
      return {
        ...state,
        changeProductActiveStatus: {
          ...initialStateModal,
        },
      }
    },
    // *************************CLEAR_DELETE_PRODUCT_DATA********************************//
    [types.CLEAR_DELETE_PRODUCT_DATA]: (state, { payload }) => {
      return {
        ...state,
        deleteProduct: {
          ...initialStateModal,
        },
      }
    },
    // *************************CLEAR_CREATE_PRODUCT********************************//
    [types.CLEAR_CREATE_PRODUCT]: (state, { payload }) => {
      return {
        ...state,
        createProduct: {
          ...initialStateModal,
        },
      }
    },
    // *************************HANDLE_PRODUCT_IMAGES********************************//
    [types.HANDLE_PRODUCT_IMAGES]: (state, { payload }) => {
      return {
        ...state,
        productImages: {
          image_keys: [...state.productImages.image_keys, ...payload],
        },
      }
    },
    // *********************************************************//
    // *************************CLEAR_PRODUCT_IMAGES********************************//
    [types.CLEAR_PRODUCT_IMAGES]: (state, { payload }) => {
      // debugger
      return {
        ...state,
        productImages: {
          image_keys: [],
        },
      }
    },
    // *********************************************************//
    // *********************************************************//
    [types.REMOVE_PRODUCT_IMAGES]: (state, { payload }) => {
      var productImages = state.productImages.image_keys;
      productImages.splice(payload.idx, 1);
      return {
        ...state,
        productImages: {
          image_keys: productImages,
        },
      };
    },
    // *********************************************************//
    // *************************HANDLE_VARIATIONS_IMAGES********************************//
    [types.HANDLE_VARIATIONS_IMAGES]: (state, { payload }) => ({
      ...state,
      variationsImages: {
        ...state.variationsImages,
        ...payload,
      },
    }),
    // *********************************************************//
    // *******************REMOVE_VARIATIONS_IMAGES**************************************//
    [types.REMOVE_VARIATIONS_IMAGES]: (state, { payload }) => {
      // debugger;
      var variationsImages = state.variationsImages;
      delete variationsImages[payload.selectedVariation]; // or use => delete test['blue'];
      return {
        ...state,
        variationsImages: {
          ...state.variationsImages,
        },
      };
    },
    // *********************************************************//
    // *********************************************************//
    [types.HANDLE_VARIATION_TABLE]: (state, { payload }) => ({
      ...state,
      variationTableDto: {
        ...payload.variationTableDto,
      },
    }),
    // *********************************************************//
    // *********************************************************//
    [types.GET_PRODUCTS_COUNT]: (state, { payload }) => ({
      ...state,
      productCount: {
        ...state.productCount,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_PRODUCTS_COUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      productCount: {
        ...state.productCount,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_PRODUCTS_COUNT_FAIL]: (state, { payload }) => ({
      ...state,
      productCount: {
        ...state.productCount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//
    [types.CREATE_PRODUCT]: (state, { payload }) => ({
      ...state,
      createProduct: {
        ...state.createProduct,
        loading: true,
        pending: true,
      },
    }),
    [types.CREATE_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      createProduct: {
        ...state.createProduct,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.CREATE_PRODUCT_FAIL]: (state, { payload }) => ({
      ...state,
      createProduct: {
        ...state.createProduct,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//
    [types.GET_PRODUCT]: (state, { payload }) => ({
      ...state,
      getProduct: {
        ...state.getProduct,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      getProduct: {
        ...state.getProduct,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_PRODUCT_FAIL]: (state, { payload }) => ({
      ...state,
      getProduct: {
        ...state.getProduct,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//
    [types.UPDATE_PRODUCT]: (state, { payload }) => ({
      ...state,
      updateProduct: {
        ...state.updateProduct,
        loading: true,
        pending: true,
      },
    }),
    [types.UPDATE_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      updateProduct: {
        ...state.updateProduct,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.UPDATE_PRODUCT_FAIL]: (state, { payload }) => ({
      ...state,
      updateProduct: {
        ...state.updateProduct,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//

    [types.DELETE_PRODUCT]: (state, { payload }) => ({
      ...state,
      deleteProduct: {
        ...state.deleteProduct,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      deleteProduct: {
        ...state.deleteProduct,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.DELETE_PRODUCT_FAIL]: (state, { payload }) => ({
      ...state,
      deleteProduct: {
        ...state.deleteProduct,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//
    [types.CHANGE_PRODUCT_ACTIVE_STATUS]: (state, { payload }) => ({
      ...state,
      changeProductActiveStatus: {
        ...state.changeProductActiveStatus,
        loading: true,
        pending: true,
      },
    }),
    [types.CHANGE_PRODUCT_ACTIVE_STATUS_SUCCESS]: (state, { payload }) => ({
      ...state,
      changeProductActiveStatus: {
        ...state.changeProductActiveStatus,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.CHANGE_PRODUCT_ACTIVE_STATUS_FAIL]: (state, { payload }) => ({
      ...state,
      changeProductActiveStatus: {
        ...state.changeProductActiveStatus,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//

    [types.GET_PRODUCT_CATEGORIES]: (state, { payload }) => ({
      ...state,
      productCategories: {
        ...state.productCategories,
        loading: true,
        pending: true,
        data: payload,
      },
    }),
    [types.GET_PRODUCT_CATEGORIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      productCategories: {
        ...state.productCategories,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_PRODUCT_CATEGORIES_FAIL]: (state, { payload }) => ({
      ...state,
      productCategories: {
        ...state.productCategories,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // *********************************************************//
    [types.REPLY_QUESTION]: (state, { payload }) => ({
      ...state,
      replyQuestion: {
        ...state.replyQuestion,
        loading: true,
        pending: true,
      },
    }),
    [types.REPLY_QUESTION_SUCCESS]: (state, { payload }) => ({
      ...state,
      replyQuestion: {
        ...state.replyQuestion,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.REPLY_QUESTION_FAIL]: (state, { payload }) => ({
      ...state,
      replyQuestion: {
        ...state.replyQuestion,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // ****************************REPLY_TO_QNA*****************************//
    [types.REPLY_TO_QNA]: (state, { payload }) => ({
      ...state,
      replyQnA: {
        ...state.replyQnA,
        loading: true,
        pending: true,
      },
    }),
    [types.REPLY_TO_QNA_SUCCESS]: (state, { payload }) => ({
      ...state,
      replyQnA: {
        ...state.replyQnA,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.REPLY_TO_QNA_FAIL]: (state, { payload }) => ({
      ...state,
      replyQnA: {
        ...state.replyQnA,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // **************************DELETE_QNA*******************************//
    [types.DELETE_QNA]: (state, { payload }) => ({
      ...state,
      deleteQnA: {
        ...state.deleteQnA,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_QNA_SUCCESS]: (state, { payload }) => ({
      ...state,
      deleteQnA: {
        ...state.deleteQnA,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.DELETE_QNA_FAIL]: (state, { payload }) => ({
      ...state,
      deleteQnA: {
        ...state.deleteQnA,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
    // **************************DELETE_QNA_REPLY*******************************//
    [types.DELETE_QNA_REPLY]: (state, { payload }) => ({
      ...state,
      deleteQnAReply: {
        ...state.deleteQnAReply,
        loading: true,
        pending: true,
      },
    }),
    [types.DELETE_QNA_REPLY_SUCCESS]: (state, { payload }) => ({
      ...state,
      deleteQnAReply: {
        ...state.deleteQnAReply,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.DELETE_QNA_REPLY_FAIL]: (state, { payload }) => ({
      ...state,
      deleteQnAReply: {
        ...state.deleteQnAReply,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //**********************************************************//
    [types.GET_SHIPPING_METHOD]: (state, { payload }) => ({
      ...state,
      shippingMethod: {
        ...state.shippingMethod,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SHIPPING_METHOD_SUCCESS]: (state, { payload }) => ({
      ...state,
      shippingMethod: {
        ...state.shippingMethod,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_SHIPPING_METHOD_FAILED]: (state, { payload }) => ({
      ...state,
      shippingMethod: {
        ...state.shippingMethod,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *********************************************************//
  },
  initialState
);
