import { Col, Row } from "antd";
import React, { Component } from "react";
import ProductReviewContainer from "../../../../components/Seller/ProductQnAContainer/ProductQnAContainer";

class ProductQnA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
    };
  }
  componentDidMount() {
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    this.setState({
      algolia,
    });
  }
  render() {
    const { algolia } = this.state;

    if (algolia) {
      return (
        <Row>
          <Col span={24}>
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <h3>Q&A Management</h3>
              </div>

              {/* Product Review Table */}
              <ProductReviewContainer
                apiKey={algolia.product_faqs && algolia.product_faqs.api_key}
                index={algolia.product_faqs && algolia.product_faqs.index}
              />
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col span={24}>
            <div>
              <div style={{ paddingBottom: "20px" }}>
                <h3>Q&A Management</h3>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }
}

export default ProductQnA;
