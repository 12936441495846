import bestBrandBrandImg from "assets/images/brands/best.png";
import bravoBrandImg from "assets/images/brands/bravo.png";
import bestBrandImg from "assets/images/brands/best.png";
import congratsBrandImg from "assets/images/brands/congrats.png";
import dreamBrandImg from "assets/images/brands/dream.png";
import easterBrandImg from "assets/images/brands/easter.png";
import kingBrandImg from "assets/images/brands/king.png";
import loveBrandImg from "assets/images/brands/love.png";
import originalBrandImg from "assets/images/brands/original.png";
import retroBrandImg from "assets/images/brands/retro.png";
import sBrandImg from "assets/images/brands/s.png";
import specialBrandImg from "assets/images/brands/special.png";
import theBrandImg from "assets/images/brands/the.png";
import withBrandImg from "assets/images/brands/with.png";
//Top seller dummy
// import GIMServicesImg from "assets/images/topSellers/GIMServices.jpg";
// import naturalReflectsImg from "assets/images/topSellers/naturalReflects.jpg";
// import RivisaraDrumsImg from "assets/images/topSellers/RivisaraDrums.jpg";
// import seneviImg from "assets/images/topSellers/senevi.jpg";
// import shopGoodnewssImg from "assets/images/topSellers/shopGoodnewss.jpg";
// import theDinaCollectionsImg from "assets/images/topSellers/theDinaCollections.jpg";
//Latest Product
import ElectronicsImg from "assets/tradesbarn/images/latestProducts/electronics.jpg";
import BusinessnIndustrialImg from "assets/tradesbarn/images/latestProducts/businesnIndustries.jpg";
import CollectiblesnArtsImg from "assets/tradesbarn/images/latestProducts/collectiblesnArt.jpg";
import FashionsImg from "assets/tradesbarn/images/latestProducts/fashions.jpg";
import HomenGardernImg from "assets/tradesbarn/images/latestProducts/homenGardern.jpg";
import MotorsImg from "assets/tradesbarn/images/latestProducts/motors.jpg";
import OtherImg from "assets/tradesbarn/images/latestProducts/other.jpg";
import MusicImg from "assets/tradesbarn/images/latestProducts/music.jpg";
import SportsImg from "assets/tradesbarn/images/latestProducts/sports.jpg";
import ToysnHobbiesImg from "assets/tradesbarn/images/latestProducts/toysnHobbies.jpg";

import OnShopElectronicsImg from "assets/onshop/images/latestProducts/electronics.jpg";
import OnShopBusinessnIndustrialImg from "assets/onshop/images/latestProducts/businesnIndustries.jpg";
import OnShopCollectiblesnArtsImg from "assets/onshop/images/latestProducts/collectiblesnArt.jpg";
import OnShopFashionsImg from "assets/onshop/images/latestProducts/fashions.jpg";
import OnShopHomenGardernImg from "assets/onshop/images/latestProducts/homenGardern.jpg";
import OnShopMotorsImg from "assets/onshop/images/latestProducts/motors.jpg";
import OnShopOtherImg from "assets/onshop/images/latestProducts/other.jpg";
import OnShopMusicImg from "assets/onshop/images/latestProducts/music.jpg";
import OnShopSportsImg from "assets/onshop/images/latestProducts/sports.jpg";
import OnShopToysnHobbiesImg from "assets/onshop/images/latestProducts/toysnHobbies.jpg";

import DummyProduct from "assets/images/products/dummyProducts.gif";
import { TRADESBARN } from "../../../constants";

export const topSellers = [
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    image: DummyProduct,
    name: "Loading...",
    country: "Sri Lanka",
    seller_category: "Loading...",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    image: DummyProduct,
    name: "Loading...",
    country: "United States",
    seller_category: "Loading...",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    image: DummyProduct,
    name: "Loading...",
    country: "Sri Lanka",
    seller_category: "Loading...",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    image: DummyProduct,
    name: "Loading...",
    country: "Sri Lanka",
    seller_category: "Loading...",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    image: DummyProduct,
    name: "Loading...",
    country: "Sri Lanka",
    seller_category: "Loading...",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    image: DummyProduct,
    name: "Loading...",
    country: "Sri Lanka",
    seller_category: "Loading...",
  },
];

export const brandsDummyData = [
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: bestBrandBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: bravoBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: bestBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: dreamBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: congratsBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: easterBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: kingBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: loveBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: originalBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: retroBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: sBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: specialBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: theBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    brand: withBrandImg,
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
];

export const testimonialsData = [
  {
    content:
      "All the Lorem Ipsum generators on the Internet tend to repeat, making this the first true generator on the Internet.",
    avatar: "https://via.placeholder.com/150x150",
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
    avatar: "https://via.placeholder.com/150x150",
    name: "Domnic Brown",
    title: "Product Head",
  },
  {
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
    avatar: "https://via.placeholder.com/150x150",
    name: "Jeson Born",
    title: "Director, Abc LLC",
  },
  {
    content:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, ",
    avatar: "https://via.placeholder.com/150x150",
    name: "John Smith",
    title: "Chief Engineer",
  },
  {
    content:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
    avatar: "https://via.placeholder.com/150x150",
    name: "Min Chan",
    title: "Director, Abc LLC",
  },
  {
    content:
      "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures",
    avatar: "https://via.placeholder.com/150x150",
    name: "Stella Johnson",
    title: "Engineer Lead",
  },
  {
    content:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections ",
    avatar: "https://via.placeholder.com/150x150",
    name: "Steve Smith",
    title: "Director, Abc LLC",
  },
];
export const basicTestimonialsData = [
  {
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    avatar: "https://via.placeholder.com/150x150",
    name: "Alex Dolgove",
    title: "BDM G-axon",
  },
  {
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
    avatar: "https://via.placeholder.com/150x150",
    name: "Domnic Brown",
    title: "Product Head",
  },
  {
    content:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
    avatar: "https://via.placeholder.com/150x150",
    name: "Jeson Born",
    title: "Director, Abc LLC",
  },
  {
    content:
      " All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures",
    avatar: "https://via.placeholder.com/150x150",
    name: "John Smith",
    title: "Chief Engineer",
  },
  {
    content:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    avatar: "https://via.placeholder.com/150x150",
    name: "Min Chan",
    title: "Director, Abc LLC",
  },
  {
    content:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur",
    avatar: "https://via.placeholder.com/150x150",
    name: "Stella Johnson",
    title: "Engineer Lead",
  },
  {
    content:
      "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections ",
    avatar: "https://via.placeholder.com/150x150",
    name: "Steve Smith",
    title: "Director, Abc LLC",
  },
];

export const latestProducts = [
  {
    id: 1,
    title: "Latest Electronics",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? ElectronicsImg
        : OnShopElectronicsImg,
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 2,
    title: "Latest Fashion",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? FashionsImg
        : OnShopFashionsImg,
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 3,
    title: "Latest Home & Garden",
    // image: "https://via.placeholder.com/575x692",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? HomenGardernImg
        : OnShopHomenGardernImg,
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 4,
    title: "Latest Business & Industrial",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? BusinessnIndustrialImg
        : OnShopBusinessnIndustrialImg,
    subTitle: "Theory of origami",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 5,
    title: "Latest Collectibles & Art",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? CollectiblesnArtsImg
        : OnShopCollectiblesnArtsImg,
    subTitle: "Theory of Coffee",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 6,
    title: "Latest Toys & Hobbies",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? ToysnHobbiesImg
        : OnShopToysnHobbiesImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 7,
    title: "Latest Sporting Goods",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? SportsImg
        : OnShopSportsImg,
    subTitle: "Theory of origami",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 8,
    title: "Latest Music",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? MusicImg
        : OnShopMusicImg,
    subTitle: "Theory of Coffee",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 9,
    title: "Latest Motors",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? MotorsImg
        : OnShopMotorsImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
  {
    id: 10,
    title: "Other",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? OtherImg
        : OnShopOtherImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "2",
      },
    ],
  },
];

export const popularProducts = [
  {
    id: 1,
    name: "Popular Electronics",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? ElectronicsImg
        : OnShopFashionsImg,
    subTitle: "Theory of origami",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 2,
    name: "Popular Fashion",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? FashionsImg
        : OnShopFashionsImg,
    subTitle: "Theory of Coffee",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 3,
    name: "Popular Home & Garden",
    // image: "https://via.placeholder.com/575x692",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? HomenGardernImg
        : OnShopHomenGardernImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 4,
    name: "Popular Business & Industrial",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? BusinessnIndustrialImg
        : OnShopBusinessnIndustrialImg,
    subTitle: "Theory of origami",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 5,
    name: "Popular Collectibles & Art",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? CollectiblesnArtsImg
        : OnShopCollectiblesnArtsImg,
    subTitle: "Theory of Coffee",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 6,
    name: "Popular Toys & Hobbies",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? ToysnHobbiesImg
        : OnShopToysnHobbiesImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 7,
    name: "Popular Sporting Goods",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? SportsImg
        : OnShopSportsImg,
    subTitle: "Theory of origami",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 8,
    name: "Popular Music",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? MusicImg
        : OnShopMusicImg,
    subTitle: "Theory of Coffee",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 9,
    name: "Popular Motors",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? MotorsImg
        : OnShopMotorsImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
  {
    id: 10,
    title: "Other",
    image:
      process.env.REACT_APP_BUSINESS_NAME === TRADESBARN
        ? OtherImg
        : OnShopOtherImg,
    subTitle: "Theory of green",
    desc: "",
    products: [
      {
        id: "f13b9d7f-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
      {
        id: "f13b9d80-9472-11ea-814b-73a670a13ab7",
        images: [DummyProduct],
        name: "Loading...",
        price: "0.00",
        seller_id: "3c608ce9-9362-11ea-ad8c-5b1ef51a2449",
      },
    ],
  },
];
