import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "antd";
import ResolveProblem from "../../../components/Buyer/ResolveProblem/ResolveProblem";
import ResolutionTypes from "../../../components/Buyer/ResolutionTypes/ResolutionTypes";
import ResolutionHomeContainer from "../../../components/Buyer/ResolutionHomeContainer/ResolutionHomeContainer";
import { userRoles } from "../../../constants/Utilities";

class DashboardResolutionCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      algolia: null,
      resolutionAPIKey: null,
      resolutionIndex: null,
    };
  }
  componentDidMount() {
    const user = localStorage.getItem("user");
    // const userObj = JSON.parse(user);

    if (!user) {
      this.props.history.push("/login");
    } else {
      const userObj = JSON.parse(user);
      const resolutionAPIKey =
        userObj.algolia.all_buyer_resolution_cases.api_key;
      const resolutionIndex = userObj.algolia.all_buyer_resolution_cases.index;
      const algolia = userObj.algolia;

      var role = null;
      role = userObj && userObj.role;
      var isAdminRole =
        role === userRoles.Moderator || role === userRoles.Admin ? true : false;
      this.setState({
        algolia,
        user,
        resolutionAPIKey,
        resolutionIndex,
        isAdminRole,
        role,
      });
    }
  }
  render() {
    const {
      resolutionAPIKey,
      resolutionIndex,
      algolia,
      isAdminRole,
      role,
    } = this.state;
    const { match } = this.props;

    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    if (role === userRoles.Seller && algolia) {
      switch (type) {
        case "all":
          apiKey = algolia.all_seller_resolution_cases.api_key;
          index = algolia.all_seller_resolution_cases.index;
          break;
        case "attentionNeeded":
          apiKey = algolia.seller_resolution_cases_needs_attention.api_key;
          index = algolia.seller_resolution_cases_needs_attention.index;
          break;
        case "opened":
          apiKey = algolia.opened_seller_resolution_cases.api_key;
          index = algolia.opened_seller_resolution_cases.index;
          break;
        case "closed":
          apiKey = algolia.closed_seller_resolution_cases.api_key;
          index = algolia.closed_seller_resolution_cases.index;
          break;
        default:
          apiKey = algolia.all_seller_resolution_cases.api_key;
          index = algolia.all_seller_resolution_cases.index;
      }

      return (
        <React.Fragment>
          <h3 className="mb-4">Resolution Center</h3>
          <ResolveProblem isDashboard={true} />

          <Card className="gx-card mt-4">
            <ResolutionTypes isDashboard={true} type={type} />

            <ResolutionHomeContainer apiKey={apiKey} index={index} />
          </Card>
        </React.Fragment>
      );
    } else if (isAdminRole && algolia) {
      apiKey = algolia.resolution_cases.api_key;
      index = algolia.resolution_cases.index;
      return (
        <React.Fragment>
          <h3 className="mb-4">Resolution Center</h3>
          <Card className="gx-card">
            <ResolutionTypes isDashboard={true} type={type} />
            <ResolutionHomeContainer apiKey={apiKey} index={index} />
          </Card>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h3 className="mb-4">Resolution Center</h3>
          {isAdminRole ? null : <ResolveProblem />}

          <Card className="gx-card mt-4">
            {isAdminRole ? null : (
              <ResolutionTypes isDashboard={true} type={type} />
            )}
            {/* <RequestAndCaseTable /> */}
            <ResolutionHomeContainer
              resolutionAPIKey={resolutionAPIKey}
              resolutionIndex={resolutionIndex}
            />
          </Card>
        </React.Fragment>
      );
    }
  }
}

export default withRouter(DashboardResolutionCenter);
