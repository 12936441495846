import React from "react";
import { Button, Modal } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { cartActions } from "../../../../modules/Cart/ducks";
import ModalShoppingCard from "../../Card/ModalShoppingCard/ModalShoppingCard";
import CustomScrollbars from "../../../../util/CustomScrollbars";
import "./CartNotifyModal.less";
class CartNotifyModal extends React.Component {
  state = {
    loading: false,
    visible: false,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };

  continueShoppingHandler = () => {
    this.props.cartActions.cartSettingsHandler({ displaySucess: false });
  };

  viewShopingCartHandler = () => {
    this.props.history.push("/cart");
    this.continueShoppingHandler();
  };

  render() {
    const { loading } = this.state;
    const { cartSettings, myCart } = this.props;

    return (
      <Modal
        visible={cartSettings.displaySucess}
        title="Shopping Cart"
        // width="60%"
        // style={{ width: "800px" }}
        onOk={this.continueShoppingHandler}
        onCancel={this.continueShoppingHandler}
        footer={[
          <Button
            style={{ margin: "2px 5px 2px 5px" }}
            key="back"
            onClick={this.viewShopingCartHandler}
          >
            View Shopping Cart
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ margin: "2px 5px 2px 5px" }}
            loading={loading}
            onClick={this.continueShoppingHandler}
          >
            Continue Shopping
          </Button>,
        ]}
      >
        <CustomScrollbars className="tb-cart-notify-container-scroll">
          <div className="pl-2 pr-2">
            {myCart &&
              myCart.items &&
              myCart.items.map((product, i) => {
                return (
                  <ModalShoppingCard
                    product={product}
                    key={i}
                    productIndex={i}
                  />
                );
              })}
          </div>
        </CustomScrollbars>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedItems: state.Cart.selectedItems,
    myCart: state.Cart.myCart,
    cartSettings: state.Cart.cartSettings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartNotifyModal)
);
