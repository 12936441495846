import React from "react";
import { Tooltip } from "antd";

function ProgressBar({ rate, ratingCount, ratingPrecentage }) {
  const ratingPrecentatgeString = ratingPrecentage.toString().concat("%");
  return (
    <Tooltip placement="right" title={ratingPrecentatgeString}>
      <label style={{ fontSize: "14px" }} className="mb-0">
        {`${rate} stars`}{" "}
        <span className="text-muted">{`- ${ratingCount}`}</span>
      </label>
      <div style={{ height: "6px" }} className="progress mb-2">
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: ratingPrecentatgeString, height: "100%" }}
          aria-valuenow={{ ratingPrecentage }}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </Tooltip>
  );
}

export default ProgressBar;
