import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card } from "antd";
import MyOrdersContainer from "../../components/Buyer/profile/MyOrders/MyOrdersContainer/MyOrdersContainer";
import MyOrderSelections from "./MyOrderSelections";
import { profileActions } from "../ProfilePage/ducks";

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      orderAPIKey: null,
      orderIndex: null,
    };
  }
  componentDidMount() {
    const user = localStorage.getItem("user");
    if (!user) {
      this.props.history.push("/login");
    } else {
      this.props.profileActions.getBuyerOrderCount();
      const userObj = JSON.parse(user);
      const algolia = userObj.algolia;
      this.setState({
        algolia,
      });
    }
  }

  render() {
    const { match, buyerOrderCount } = this.props;
    const { algolia } = this.state;
    const type = match.params && match.params.type;
    var apiKey = "";
    var index = "";
    if (algolia) {
      switch (type) {
        case "all":
          apiKey = algolia.buyer_all_orders.api_key;
          index = algolia.buyer_all_orders.index;
          break;
        case "unpaid-orders":
          apiKey = algolia.buyer_unpaid_orders.api_key;
          index = algolia.buyer_unpaid_orders.index;
          break;
        case "orders-to-ship":
          apiKey = algolia.buyer_orders_to_ship.api_key;
          index = algolia.buyer_orders_to_ship.index;
          break;
        case "cancel-orders":
          apiKey = algolia.buyer_cancelled_orders.api_key;
          index = algolia.buyer_cancelled_orders.index;
          break;
        case "delivered-orders":
          apiKey = algolia.buyer_delivered_orders.api_key;
          index = algolia.buyer_delivered_orders.index;
          break;
        case "buyer-shipped-orders":
          apiKey = algolia.buyer_shipped_orders.api_key;
          index = algolia.buyer_shipped_orders.index;
          break;
        case "buyer-returned-orders":
          apiKey = algolia.buyer_returned_orders.api_key;
          index = algolia.buyer_returned_orders.index;
          break;
        default:
          apiKey = algolia.buyer_all_orders.api_key;
          index = algolia.buyer_all_orders.index;
      }

      return (
        <React.Fragment>
          <Card
            className="gx-card mt-4"
            style={{ backgroundColor: "rgb(250, 250, 250)" }}
          >
            <h5 className="mb-3 ml-2">Orders</h5>
            <MyOrderSelections type={type} orderCount={buyerOrderCount} />
            <br /><br />
            <MyOrdersContainer
              apiKey={apiKey}
              index={index}
            />
          </Card>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Card
            className="gx-card mt-4"
            style={{ backgroundColor: "rgb(250, 250, 250)" }}
          >
            <h5 className="mb-3 ml-2">Orders</h5>
            <MyOrderSelections type={type} orderCount={buyerOrderCount} />
          </Card>
        </React.Fragment>
      );

    }
  }
}

function mapStateToProps(state) {
  return {
    buyerOrderCount: state.Profile.buyerOrderCount,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyOrders));
