import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Form, Input } from "antd";
import { makeField } from "../../../Common/Controls/MakeField";

const FormItem = Form.Item;

// const formItemLayout = {
//   labelCol: {
//     xs: { span: 24 },
//     sm: { span: 24 },
//     md: { span: 24 },
//     lg: { span: 24 },
//     xl: { span: 24 },
//   },
//   wrapperCol: {
//     xs: { span: 24 },
//     sm: { span: 24 },
//     md: { span: 24 },
//     lg: { span: 24 },
//     xl: { span: 24 },
//   },
// };

const AInputField = makeField(Input);

class ProductSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: "searchbar",
    };
  }
  componentDidMount() {
    // To disabled submit button at the beginning.
    // this.props.form.validateFields();
  }

  handleSubmit = () => {
    alert("Searching....");
  };

  addProductHandler = () => {
    this.props.history.push("/seller/dashboard/productManagement/product/add");
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Card className="gx-card">
        <Form layout="inline">
          <FormItem className="mb-0">
            <Button
              className="mb-0"
              type="primary"
              onClick={() => this.addProductHandler()}
              //   htmlType="submit"
              //   disabled={hasErrors(getFieldsError())}
            >
              Add Product
            </Button>
          </FormItem>
        </Form>
        <hr />
        <Form layout="inline" onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            style={{ width: "250px" }}
            name="productName"
            component={AInputField}
            placeholder="Name"
            hasFeedback
          />
          <Field
            style={{ width: "250px" }}
            name="productBrandName"
            component={AInputField}
            placeholder="Bramd Name"
            hasFeedback
          />
          <FormItem className="mb-0">
            <Button
              className="mb-0"
              type="primary"
              htmlType="submit"
              //   disabled={hasErrors(getFieldsError())}
            >
              Search
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (
    !values.productName &&
    !values.productBrandName &&
    (!values.productBrandName || !values.productName)
  ) {
    errors.productBrandName = "Product Name or Brand Name required";
    errors.productName = "Product Name or Brand Name required";
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    signinUser: state.AuthStore.signinUser,
    signupUser: state.AuthStore.signupUser,
    currentUser: state.AuthStore.currentUser,
  };
};

export default withRouter(
  reduxForm({
    form: "ProductSearchBar_form",
    validate,
  })(connect(mapStateToProps, null)(ProductSearchBar))
);
