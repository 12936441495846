import React, { Component } from "react";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ANDROID_APP, APP_GALLERY, IOS_APP } from "../../constants";
import "./MobileApp.less";

export class MobileApp extends Component {
  render() {
    return (
      <div className="gx-container">
        <Row>
          <Col span={24} style={{ minHeight: "75vh" }}>
            <Card
              className="gx-card mt-4"
              style={{ backgroundColor: "rgb(250, 250, 250)" }}
            >
              <div className="gx-card mb-0 dynmc-pdng">
                <img
                  src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/mobileApp/mobapp.jpg`)}
                  // src={appImg}
                  alt="app"
                  style={{ borderRadius: "5px" }}
                />
                <Link to={{ pathname: ANDROID_APP }} target="_blank">
                  <div className="google-play-btn"></div>
                </Link>

                <Link to={{ pathname: IOS_APP }} target="_blank">
                  <div className="app-store-btn"></div>
                </Link>

                <Link to={{ pathname: APP_GALLERY }} target="_blank">
                  <div className="app-gallery-btn"></div>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MobileApp;
