import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "antd";
import ShippingAddressSelectModalBody from "./ShippingAddressSelectModalBody";

class ShippingAddressSelectModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 500);
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { isUpdate, shippingAddress, data } = this.props;
    return (
      <form className="add-card">
        {this.props.showForm ? (
          <Button type="primary" onClick={this.showModal} className="mb-0">
            {/* <IntlMessages id="eCommerce.readMore" /> */}
            <i className="fad fa-map-marker-edit mr-2"></i>Select Other Address
          </Button>
        ) : null}
        <Modal
          title="Select Shipping Address"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          footer={null}
        >
          <ShippingAddressSelectModalBody
            isUpdate={isUpdate}
            shippingAddress={shippingAddress}
            data={data}
            handleOk={this.handleOk}
            setSelectedAddress={this.props.setSelectedAddress}
          />
        </Modal>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    // selectedItems: state.ShippingAddress.selectedItems,
    orderAddress: state.Profile.orderAddress,
    shippingAddress: state.Profile.shippingAddress,
    selectedShippingAdress: state.Profile.selectedShippingAdress,
  };
}
function mapDispatchToProps(dispatch) {
  return null;
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShippingAddressSelectModal)
);
