import React, { Component } from "react";
import faqs from "./faqs";
import Question from "./Question";
import "./faq.less";
import { IS_DOMESTIC_MODE } from "../../constants";

class FAQ extends Component {
  render() {
    return (
      <div className="gx-container faq-container">
        {/* <img
          className="interactive-box-image"
          id="interactive-box-image"
          // src="../../images/illustration-box-desktop.svg"
          // src="../../assets/onshop/images/faq/illustration-box-desktop.svg"
          src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/faq/illustration-box-desktop.svg`)}
          alt="true"
          aria-hidden="true"
        /> */}
        <section className="faq">
          <div
            className={
              IS_DOMESTIC_MODE
                ? "faq__left_domestic"
                : "faq__left_international"
            }
          >
            <picture className="faq__picture">
              <source
                // srcSet="../../assets/onshop/images/faq/illustration-woman-online-mobile.svg"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/faq/illustration-woman-online-mobile.svg`)}
                media="(max-width: 992px)"
              />
              <img
                // src="../../assets/onshop/images/faq/illustration-woman-online-desktop.svg"
                src={require(`assets/${process.env.REACT_APP_STATIC_FILE_DIR}/images/faq/illustration-woman-online-desktop.svg`)}
                alt="true"
                aria-hidden="true"
              />
            </picture>
          </div>

          <div
            className={
              IS_DOMESTIC_MODE
                ? "faq__right_domestic"
                : "faq__right_international"
            }
          >
            <h1 className="faq__heading">FAQ</h1>
            <dl>
              {faqs.map((faq, index) => (
                <Question
                  index={index}
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </dl>
          </div>
        </section>
      </div>
    );
  }
}

export default FAQ;
