import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  sellerStatistics: {
    ...initialStateModal,
  },
  sellerRevenue: {
    ...initialStateModal,
  },
  sellerPopularProducts: {
    ...initialStateModal,
  },
  sellerOrders: {
    ...initialStateModal,
  },
  sellerActivities: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    // *****************************************************//
    [types.GET_SELLER_STATISTICS]: (state, { payload }) => ({
      ...state,
      sellerStatistics: {
        ...state.sellerStatistics,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_STATISTICS_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerStatistics: {
        ...state.sellerStatistics,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_SELLER_STATISTICS_FAIL]: (state, { payload }) => ({
      ...state,
      sellerStatistics: {
        ...state.sellerStatistics,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *****************************************************//
    // *****************************************************//
    [types.GET_SELLER_REVENUE]: (state, { payload }) => ({
      ...state,
      sellerRevenue: {
        ...state.sellerRevenue,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_REVENUE_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerRevenue: {
        ...state.sellerRevenue,
        loading: false,
        pending: false,
        // data: payload,
        data: [
          { name: "JAN", lastYear: 200, thisYear: 600 },
          { name: "FEB", lastYear: 500, thisYear: 900 },
          { name: "MAR", lastYear: 700, thisYear: 1200 },
          { name: "JUN", lastYear: 500, thisYear: 900 },
          { name: "AUG", lastYear: 200, thisYear: 800 },
          { name: "SEP", lastYear: 400, thisYear: 400 },
          { name: "OCT", lastYear: 400, thisYear: 500 },
          { name: "NOV", lastYear: 400, thisYear: 1200 },
          { name: "DEC", lastYear: 200, thisYear: 800 },
        ],
      },
    }),

    [types.GET_SELLER_REVENUE_FAIL]: (state, { payload }) => ({
      ...state,
      sellerRevenue: {
        ...state.sellerRevenue,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *****************************************************//
    // *****************************************************//
    [types.GET_SELLER_POPULAR_PRODUCTS]: (state, { payload }) => ({
      ...state,
      sellerPopularProducts: {
        ...state.sellerPopularProducts,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_POPULAR_PRODUCTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerPopularProducts: {
        ...state.sellerPopularProducts,
        loading: false,
        pending: false,
        // data:payload,
        data: { payload },
      },
    }),

    [types.GET_SELLER_POPULAR_PRODUCTS_FAIL]: (state, { payload }) => ({
      ...state,
      sellerPopularProducts: {
        ...state.sellerPopularProducts,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *****************************************************//
    // *****************************************************//
    [types.GET_SELLER_ORDERS]: (state, { payload }) => ({
      ...state,
      sellerOrders: {
        ...state.sellerOrders,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_ORDERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerOrders: {
        ...state.sellerOrders,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_SELLER_ORDERS_FAIL]: (state, { payload }) => ({
      ...state,
      sellerOrders: {
        ...state.sellerOrders,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *****************************************************//
    // *****************************************************//
    [types.GET_SELLER_ACTIVITIES]: (state, { payload }) => ({
      ...state,
      sellerActivities: {
        ...state.sellerActivities,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_SELLER_ACTIVITIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      sellerActivities: {
        ...state.sellerActivities,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_SELLER_ACTIVITIES_FAIL]: (state, { payload }) => ({
      ...state,
      sellerActivities: {
        ...state.sellerActivities,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // *****************************************************//
  },
  initialState
);
