import React from "react";
import { Col, Row, Card } from "antd";
import ProductGallery from "../ProductGallery/ProductGallery";
import ProductInfo from "../productInfo/ProductInfo";
import { IS_DOMESTIC_MODE } from "../../../../constants";
import DomesticProductInfo from "../productInfo/DomesticProductInfo";

export default function ProductViewSection({ product }) {
  return (
    <React.Fragment>
      <Card
        className="gx-card reduce-pdng"
        style={{ backgroundColor: "rgb(250, 250, 250)" }}
      >
        <Row>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <ProductGallery product={product} />
          </Col>
          <Col xl={16} lg={16} md={24} sm={24} xs={24}>
            {IS_DOMESTIC_MODE ? <DomesticProductInfo /> : <ProductInfo />}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
}
