import React from "react";
import "./SellerStoreProfile.less";
import { Button } from "antd";

const SellerStoreProfile = ({ data, contactSellerHandler }) => {
  return (
    <div>
      {/* <img
        className="p-2 gx-card mb-2"
        style={{ width: "200px" }}
        alt="example"
        src={data && data.data && data.data.image}
      /> */}
      {data && data.data && (
        <div>
          <i className="fad fa-map-marker-alt mr-2"></i>Location -{" "}
          {data.data.name}, {data.data.seller_category}, {data.data.city},{" "}
          {data.data.country}
        </div>
      )}

      {data?.data?.ratings_count !== 0 && (
        <div>
          <i className="fad fa-star-half mr-1"></i>Rating Count -{" "}
          {data?.data?.ratings_count}
        </div>
      )}

      {data?.data?.ratings_value && (
        <div>
          <i className="fad fa-star-half mr-1"></i>Rating Value -{" "}
          {data?.data?.ratings_value}
        </div>
      )}
      <div className="mt-3">
        <Button
          style={{ marginBottom: "0" }}
          type="primary"
          onClick={() => contactSellerHandler()}
        >
          <i className="fad fa-comments mr-2"></i>Contact Us
        </Button>
      </div>
    </div>
  );
};

export default SellerStoreProfile;
