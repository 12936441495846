import React from "react";
import { DEFAULT_CURRENCY } from "../../../../constants";

export default function OrderItemSummery({
  data,
  selectedProductVariations,
  totalShippingCost,
}) {
  var subTotal;

  if (data.userSelections) {
    const { quantity } = data.userSelections;
    subTotal =
      selectedProductVariations &&
      selectedProductVariations.price &&
      selectedProductVariations.price * quantity;
  } else {
    subTotal = data && data.price && data.price * 1;
  }
  var updatedSubTotal = subTotal && subTotal.toFixed(2);
  var updatedTotal =
    parseFloat(updatedSubTotal) + parseFloat(totalShippingCost);

  return (
    <div>
      <span>
        <b>Sub Total : </b>
        <span
          style={{ color: "#129c30" }}
        >{`${DEFAULT_CURRENCY} ${updatedSubTotal}`}</span>
      </span>
      <br />
      <span>
        <b>Shipping : </b>
        <span
          style={{ color: "#129c30" }}
        >{`${DEFAULT_CURRENCY} ${totalShippingCost}`}</span>
      </span>
      <br />
      <span>
        <b>Total : </b>
        <span
          style={{ color: "#129c30" }}
        >{`${DEFAULT_CURRENCY} ${updatedTotal.toFixed(2)}`}</span>
      </span>
    </div>
  );
}
