import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../constants/Utilities";

const initialState = {
  contactDetail: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.ADD_CONTACT_DETAILS]: (state, { payload }) => ({
      ...state,
      contactDetail: {
        ...state.contactDetail,
        loading: true,
        pending: true,
      },
    }),
    [types.ADD_CONTACT_DETAILS_SUCCESS]: (state, { payload }) => ({
      ...state,
      contactDetail: {
        ...state.contactDetail,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.ADD_CONTACT_DETAILS_FAILED]: (state, { payload }) => ({
      ...state,
      contactDetail: {
        ...state.contactDetail,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
