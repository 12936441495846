import React, { Component } from "react";
import { Button, Modal } from "antd";
import CreditCardModalBody from "./AddCreditCardModalBody";
import { creditCardActions } from "../../../../modules/CardsAddPage/ducks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { profileActions } from "../../../../modules/ProfilePage/ducks";

class AddCreditCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      ModalText: "Content of the modal",
      visible: false,
      confirmLoading: false,
    };
  }

  componentDidMount() {
    this.props.profileActions.getShippingAddress();
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.setState({
      ModalText: "The modal will be closed after two seconds",
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { shippingAddress, isUpdate, isCreate, data } = this.props;
    return (
      <form>
        <Button type="primary" onClick={this.showModal}>
          {isCreate ? (
            <div>
              <i className="fas fa-plus-square"></i>&nbsp; Add Credit Card
            </div>
          ) : null}
        </Button>
        <Modal
          title="Credit Card Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          confirmLoading={this.state.confirmLoading}
          onCancel={this.handleCancel}
          footer={null}
          width={1000}
        >
          <CreditCardModalBody
            orderedit={this.handleform}
            address={shippingAddress}
            isUpdate={isUpdate}
            data={data}
            handleOk={this.handleOk}
          />
        </Modal>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    creditCard: state.CredirCardDetail.creditCard,
    shippingAddress: state.Profile.shippingAddress,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddCreditCardModal)
);
