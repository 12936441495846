// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  replyToReview: createAction(types.REPLY_TO_REVIEW),
  replyToReviewSuccess: createAction(types.REPLY_TO_REVIEW_SUCCESS),
  replyToReviewFail: createAction(types.REPLY_TO_REVIEW_FAIL),

  deleteReview: createAction(types.DELETE_REVIEW),
  deleteReviewSuccess: createAction(types.DELETE_REVIEW_SUCCESS),
  deleteReviewFail: createAction(types.DELETE_REVIEW_FAIL),

  deleteReviewReply: createAction(types.DELETE_REVIEW_REPLY),
  deleteReviewReplySuccess: createAction(types.DELETE_REVIEW_REPLY_SUCCESS),
  deleteReviewReplyFail: createAction(types.DELETE_REVIEW_REPLY_FAIL),
};
