import React from "react";
import { Col, Row } from "antd";
import About from "components/Buyer/profile/About/index";
import Events from "components/Buyer/profile/Events/index";
import Contact from "components/Buyer/profile/Contact/index";
import Chat from "components/Buyer/chat/Chat";

const Profile = () => {
  return (
    <>
      <Row>
        <Col xl={16} lg={14} md={14} sm={24} xs={24}>
          <About />
          {/* <Events/> */}
        </Col>

        <Col xl={8} lg={10} md={10} sm={24} xs={24}>
          <Contact />
          {/* <Row>
            <Col xl={24} lg={24} md={24} sm={12} xs={24}>
              <Wishlist />
            </Col>
            <Col xl={24} lg={24} md={24} sm={12} xs={24}>
              <Photos photoList={photoList}/>
            </Col>
          </Row> */}
        </Col>
        <Chat />
      </Row>
      <Events />
    </>
  );
};

export default Profile;
