import { Col, Row, Upload, message, Modal, Icon } from "antd";
import React, { Component } from "react";
import { uploadWithSignURL } from "../../../services/storage.service";
import EndPoints from "../../../util/EndPoints";
import API from "util/HTTPClient";
import ImgCrop from "antd-img-crop";

export default class SellerRegisterImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      loading: false,
      confirmBefore: false,
    };
  }

  beforeUpload = (file, fileList) => {
    return new Promise(function (resolve, reject) {
      let isImage = [
        "image/jpeg",
        "image/jpg",
        "image/bmp",
        "image/png",
      ].includes(file.type);

      const isLt512K = file.size / 1024 <= 512;

      if (!isImage) {
        message.error("You can only upload Image file!");
        reject();
      }

      if (!isLt512K) {
        message.error("Image must smaller than 512KB!");
        reject();
      } else {
        Modal.confirm({
          title: "Are you sure to upload this picture as your store image ?",
          content:
            "This image will display to buyers. You can add any image but we do recommend to add your logo here",
          okText: "Confirm",
          cancelText: "Cancel",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      }
    });
  };

  handleUpload = ({ onSuccess, onError, file }) => {
    API.Put(EndPoints.SELLER_PROFILE_IMAGE)
      .then((res) => res.data)
      .then(async (res) => {
        await uploadWithSignURL(
          res.data.signed_url,
          new Blob([file]),
          file.type
        );
        return res.data.url;
      })
      .then((image_url) => {
        this.setState({ imageUrl: image_url });
        this.props.handleImageUrl(image_url);
        onSuccess(null, file);
      })
      .catch((err) => {
        console.error(err);
        onError(err);
      });
  };

  render() {
    const imageUrl = this.state.imageUrl;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />

        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div>
        <Row>
          <Col span={24}>
            <ImgCrop>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.beforeUpload}
                customRequest={this.handleUpload}
              >
                {imageUrl ? <img src={imageUrl} alt="" /> : uploadButton}
              </Upload>
            </ImgCrop>
          </Col>
        </Row>
      </div>
    );
  }
}
