import React from "react";
import { Row, Col, Divider } from "antd";
import IntlMessages from "util/IntlMessages";
import CardBox from "../Card/CardBox/CardBox";
import PopularCategoryCard from "../Card/PopularCategoryCard/PopularCategoryCard";
import "./PopularCategories.css";
import Loading from "assets/images/products/dummyProducts.gif";

const PopularCategories = (props) => {
  const { data } = props;
  return (
    <Row>
      <Col span={24}>
        <CardBox
        // styleName="gx-text-center"
        // heading={<IntlMessages id="popularCategories" />}
        >
          <Divider className="mt-0 mb-2">
            <h4 className="nw-jr-fnt-clr">
              <IntlMessages id="popularCategories" />
            </h4>
          </Divider>

          {/* <hr className="mt-0 mb-4"></hr> */}
          <Row>
            {data.loading
              ? Array.from(Array(6).keys()).map((i) => {
                  return (
                    <PopularCategoryCard
                      key={i}
                      categoryName={"#"}
                      image={Loading}
                      shortName={"Loading..."}
                      isLoading={true}
                    />
                  );
                })
              : data.data.slice(0, 6).map((category, i) => {
                  return (
                    <PopularCategoryCard
                      key={i}
                      categoryName={category.name}
                      image={category.image}
                      shortName={category.short_name}
                      isLoading={false}
                    />
                  );
                })}
          </Row>
        </CardBox>
      </Col>
    </Row>
  );
};

export default PopularCategories;
