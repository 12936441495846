import React, { Component } from "react";
import { Link } from "react-router-dom";

class Genaral extends Component {
  render() {
    return (
      <div>
        <div className="gx-card p-3 mb-2">
          <div className="gx-profile-banner-top-right">
            <ul style={{ flexWrap: "inherit" }} className="gx-follower-list">
              <li>
                <Link>
                  <span className="gx-fs-sm">Seller Account</span>
                </Link>
              </li>
              <li>
                <Link>
                  <span className="gx-fs-sm">Business Information</span>
                </Link>
              </li>
              <li>
                <Link>
                  <span className="gx-fs-sm">Bank Account</span>
                </Link>
              </li>
              <li>
                <Link>
                  <span className="gx-fs-sm">Address</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Genaral;
