import { db } from "../firebase/firebase";
import { GetCurrentUserID } from "./auth.service";

const ConversationListner = [];
const ConversationListListner = [];

/**
 * Get All Conversations
 * @param {Callback} callback 
 */
export const GetConversationList = async (callback) => {
  try {
    const user_id = await GetCurrentUserID();
    const listner = db
      .collection("conversations")
      .where("owner", "==", user_id)
      .orderBy("last_seen_at", "desc")
      .onSnapshot((querySnapshot) => {
        const chat_lists = [];
        querySnapshot.forEach((doc) => {
          const conversation = doc.data();
          chat_lists.push({
            id: conversation.id,
            name: conversation.name,
            thumb: conversation.thumb,
            status: conversation.status,
            lastMessage: conversation.last_message,
            unreadMessage: conversation.unread_messages,
            lastMessageTime: conversation.last_seen_at,
          });
        });
        callback(chat_lists);
      });
    ConversationListner.push(listner);
    return ConversationListner.length - 1;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get Conversation's Messages
 * @param {Conversation ID} conversation_id 
 * @param {Pagiation} no_of_messages 
 * @param {Callback} callback 
 */
export const GetConversation = async (
  conversation_id,
  no_of_messages,
  callback
) => {
  try {
    const user_id = await GetCurrentUserID();
    const listner = db
      .collection("messages")
      .where("conversation_id", "==", conversation_id)
      .orderBy("created_at", "desc")
      .limit(no_of_messages)
      .onSnapshot((querySnapshot) => {
        const messages = [];
        querySnapshot.forEach((doc) => {
          const message = doc.data();
          messages.push({
            id: doc.id,
            type: message.from === user_id ? "sent" : "received",
            message: message.message,
            sentAt: message.created_at,
            url: message.url,
            product: message.product || {},
            message_type: message.type
          });
        });
        callback(messages);
      });
    ConversationListListner.push(listner);
    return ConversationListListner.length - 1;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Send Message to Existing Conversation
 * @param {Conversation ID} conversation_id 
 * @param {Message to be sent} message 
 * @callback {send message and status}
 */
export const SendMesage = async (conversation_id, message, callback) => {
  try {
    const user_id = await GetCurrentUserID();
    await db.collection("messages").add({
      from: user_id,
      message: message,
      conversation_id: conversation_id,
      new: true,
      type: 'Message'
    });
    
    callback(true, message)
  } catch (err) {
    console.error(err);
    callback(false, message)
  }
};

/**
 * Send Message with Product Mensioning to Existing Conversation
 * @param {Conversation ID} conversation_id 
 * @param {Product ID to be mensioned} product_id 
 */
export const MensionProduct = async (conversation_id, product_id) => {
  try {
    const user_id = await GetCurrentUserID();
    await db.collection("messages").add({
      from: user_id,
      product_id: product_id,
      product: {
        id: product_id
      },
      conversation_id: conversation_id,
      new: true,
      type: 'Product'
    });
    
  } catch (err) {
    console.error(err);
  }
};

/**
 * Send Image to Existing Conversation
 * @param {Conversation ID} conversation_id 
 * @param {Image's Key to be send} image_key 
 */
export const SendImage = async (conversation_id, image_key) => {
  try {
    const user_id = await GetCurrentUserID();
    await db.collection("messages").add({
      from: user_id,
      url: image_key,
      conversation_id: conversation_id,
      new: true,
      type: 'Image'
    });
    
  } catch (err) {
    console.error(err);
  }
};

/**
 * Create New Conversation
 * @param {Message Receiver ID} receiver_id 
 */
const CreateNewConversation = async (receiver_id) => {
  try {
    const user_id = await GetCurrentUserID();
    const ref = await db.collection("conversations").add({
      user_id: receiver_id,
      owner: user_id,
      new: true,
    });
    
    return ref.id;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get Conversation id by Seller ID
 * @param {Message Receiver ID} receiver_id -> seller_ID
 */
export const GetConversationIdByUserID = async (receiver_id) => {
  const user_id = await GetCurrentUserID();
  const conversation_list = await db.collection("conversations")
    .where("owner", "==", user_id)
    .where("user_id", "==", receiver_id)
    .get()
  if (conversation_list.docs.length) {
    return conversation_list.docs[0].id;
  } else {
    const new_Conversation_id = await CreateNewConversation(receiver_id);
    return new_Conversation_id;
  }
}

/**
 * Stop Currently Active Socket Listner
 * @param {Listner Type} type 
 * @param {Listner Array Indes} index 
 */
export const StopListner = (type, index) => {
  try {
    switch (type) {
      case "conversation":
        if (ConversationListner[index]) ConversationListner[index]();
        break;
      case "conversation_list":
        if (ConversationListListner[index]) ConversationListListner[index]();
        break;
      default:
        if (ConversationListListner[index]) ConversationListListner[index]();
    }
  } catch (err) {
    console.error(err);
  }
};