import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Button, Modal } from "antd";
import { Link, withRouter } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import ProductCardCarousel from "../../Carousel/ProductCardCarousel/ProductCardCarousel";
import { cartActions } from "../../../../modules/Cart/ducks";
import "./ProductCard.less";
import { DEFAULT_CURRENCY_SHORT } from "../../../../constants";
export class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_wishlisted: null,
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.product.is_wishlisted !== prevState.is_wishlisted) {
      this.setState({
        is_wishlisted: this.props.product.is_wishlisted,
      });
    }
  }

  addWishlistHandler = (id) => {
    // getter
    const user = localStorage.getItem("user");
    if (!user) {
      // message.info("Please login...", 3);
      const modal = Modal.info({
        title: "Login required.. ",
        content: "Please log into the system",
      });
      setTimeout(() => modal.destroy(), 3000);

      //wait 3 seconds
      setTimeout(
        function () {
          this.props.history.push(`/login`);
        }.bind(this),
        3000
      );
    } else {
      var wishlistDto = {
        product_id: id,
      };
      this.props.cartActions.addWishlistItem({
        wishlistDto: wishlistDto,
        isHome: true,
      });
      this.setState({
        is_wishlisted: !this.state.is_wishlisted,
      });
    }
  };

  buyNowHandler = (id) => {
    this.props.history.push(`/product/${id}`);
  };

  render() {
    const { product, grid, isLoading } = this.props;
    const { is_wishlisted } = this.state;
    const {
      name,
      old_price,
      price,
      discount_percentage,
      images,
      // color,
      id,
      ratings_value,
      sold,
    } = product;
    return (
      <div
        className={`gx-product-item pdct-crd-ef-jr bdr-jr ${
          grid ? "gx-product-vertical" : "gx-product-horizontal"
        }`}
      >
        <Link to={isLoading ? "" : `/product/${id}`} style={{ height: "100%" }}>
          <div className="gx-product-image">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div style={{ position: "relative" }}>
                  <ProductCardCarousel data={images} />
                  {discount_percentage ? (
                    <span className="tb-product-card-discount-tag">
                      <p
                        style={{ marginRight: "5px", marginBottom: "0px" }}
                      >{`${discount_percentage} %`}</p>
                    </span>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="gx-product-body p-2 pl-3 pr-3 txt-alng-pg-jr"
            style={{ height: "100%" }}
          >
            <p className="gx-product-title tb-product-title crd-fnt-jr mb-1">
              {name}
            </p>

            <div>
              <div style={{ display: "inline-block", width: "70%" }}>
                <div style={{ display: "inline-block" }}>
                  <div
                    style={{ fontWeight: "bold" }}
                    className="gx-product-price"
                  >
                    {`${DEFAULT_CURRENCY_SHORT} ${price}`}
                  </div>
                </div>
                {discount_percentage && old_price ? (
                  <p
                    style={{
                      display: "inline-block",
                      marginLeft: "10px",
                      fontSize: "14px",
                    }}
                  >
                    <del>{`${DEFAULT_CURRENCY_SHORT} ${old_price}`}</del>
                  </p>
                ) : null}
              </div>
              <div style={{ display: "inline-block", width: "30%" }}>
                <p
                  className="pd-crd-othr-fnt"
                  style={{ fontSize: "13px", textAlign: "right" }}
                >
                  {sold > 0 ? `${sold} Solds` : null}
                </p>
              </div>
            </div>

            {ratings_value > 0 && (
              <div className="ant-row-flex">
                <StarRatingComponent
                  name=""
                  value={parseFloat(ratings_value)}
                  starCount={5}
                  editing={false}
                  emptyStarColor={"#4b505e"}
                />

                <span className="gx-d-inline-block gx-ml-2 pd-crd-othr-fnt">
                  {ratings_value}
                </span>
              </div>
            )}
          </div>
        </Link>
        <div
          className="txt-alng-pg-jr pt-0 pr-3 pb-3 pl-3 m-0"
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          <div
            className="crd-hvr"
            style={{
              display: "inline-block",
              marginRight: "0px",
              width: "70%",
            }}
          >
            <Button
              style={{ width: "90%" }}
              type="primary"
              className="gx-mb-0"
              htmlType="submit"
              onClick={() => this.buyNowHandler(id)}
            >
              Buy Now
            </Button>
          </div>

          <div
            className="crd-hvr-3"
            style={{
              display: "inline-block",
              marginRight: "0px",
              width: "30%",
              position: "absolute",
            }}
          >
            <button
              className="like-button-2"
              onClick={() => this.addWishlistHandler(id)}
            >
              {is_wishlisted ? (
                <span className="like-clicked">
                  <i className="fas fa-heart like-icon-2"></i>
                </span>
              ) : (
                <span className="like-unclicked">
                  <i className="fas fa-heart like-icon-2"></i>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(ProductCard));
