import React, { Component } from "react";
import Widget from "../../components/Common/Widget/Widget";
import CSBreadcrumb from "./CSBreadcrumb";
import CSHeader from "./CSHeader";
import "./CustomerService.less";

class RealEstatePolicy extends Component {
  render() {
    return (
      <div>
        <div className="flex-head-container mb-3">
          <CSHeader title="Real Estate Policy" />
          <CSBreadcrumb label={"Real Estate Policy"} />
        </div>

        <Widget styleName="mb-0">
          <div>
            <p>
              To best meet the needs of our users while accommodating the wide
              variety of laws governing the sale of real estate across the
              globe, Tradesbarn offers advertisements of real estate in a
              variety of formats. These Real Estate Rules explain these format
              offerings, clarify Tradesbarn's limited role as a hosting platform
              for advertising and venue- provider, and outline certain
              limitations on Tradesbarn's obligations and liabilities. Your use
              of the Tradesbarn Real Estate Pages confirms your agreement to
              these rules.
            </p>
            <p>
              Real estate advertising services on Tradesbarn.com are offered by
              Tradesbarn LLC. And in these Rules, this company is referred to as
              "Tradesbarn Real Estate." Selling an undivided interest in a land
              real estate property is not permitted. An undivided interest is
              defined as a partial interest by two or more people in the same
              property. This prohibition shall not apply to mineral rights.
            </p>
            <p>
              <i>
                Violations of this policy may result in a range of actions,
                including:
              </i>
            </p>
            <ul>
              <li>
                <p>Listing cancellation</p>
              </li>
              <li>
                <p>Limits on account privileges</p>
              </li>
              <li>
                <p>Account suspension</p>
              </li>
              <li>
                <p>Forfeit of Tradesbarn fees on cancelled listings</p>
              </li>
              <li>
                <p>Loss of Power Seller status</p>
              </li>
            </ul>
            <p>
              Due to the wide variety of laws governing the sale of real estate,
              Tradesbarn Real Estate advertisements of real property do not
              involve legally binding offers to buy and sell. Instead,
              Tradesbarn Real Estate's advertisements are simply a way for
              sellers to advertise their real estate and meet potential buyers.
              Tradesbarn is commonly referred to as an online marketplace site,
              however Tradesbarn Real Estate is not a traditional "Broker."
              Tradesbarn Real Estate is not involved in the actual transaction
              between buyers and sellers and, as a result, has no control over
              the quality, safety or legality of the properties advertised, the
              truth or accuracy of the postings, the ability of sellers to sell
              items or the ability of buyers to buy items. Tradesbarn cannot
              ensure that a buyer or seller will actually complete a
              transaction.
            </p>
            <p>
              TB format advertisements for the sale of real estate are designed
              to assist the parties in identifying the most interested potential
              buyer(s). The listings do not result in binding agreements to buy
              and sell real estate; however, they do allow sellers and buyers to
              use Tradesbarn Real Estate as a venue to determine who may be most
              interested in the property. In this format, potential buyers are
              asked to “Contact” The Seller of the advertised property, to find
              information, subject to customary diligence and contingencies.
              Sellers are not permitted to claim in their listings that a
              Contact, results in a binding obligation to consummate the
              purchase. The sellers and buyers are expected to contact each
              other to prepare and deliver the appropriate paperwork to buy and
              sell the property, and to take such other actions as may be
              required by local laws. As noted below, Tradesbarn Real Estate
              cannot give legal advice to buyers or sellers, and users should
              seek professional advice when selling or buying real estate.
              Depending on the property, sellers may be required to deliver to
              buyers a disclosure statement prior to a sale,
            </p>
            <p>
              And in all cases buyers should conduct their own due diligence
              related to property inspection, title review, building code
              compliance, termite inspection, flood plain, hazardous materials
              and other local issues transparent only to an expert. While
              Tradesbarn Real Estate cannot guarantee consummation of the sale
              to either seller or potential buyers, our rules require sellers
              and potential buyers to follow through in good faith. If the
              parties do follow through in good faith, but the transaction does
              not close, the parties should still leave its feedback for each
              other.
            </p>
            <p>
              If you elect to use the "TB-style" advertisement for certain kinds
              of real estate, you will be charged a "Notice Fee" when the
              TB-style advertisement is over. This fee applies whether or not
              the real estate sale is completed. You will not be charged a
              "final value fee" tied to the sale of the real estate.
            </p>
            <p>
              <i>In Our Ad Format,</i>
            </p>
            <ul>
              <li>
                <p>You can list your asking price.</p>
              </li>
              <li>
                <p>
                  Interested buyers fill out a contact form and their
                  information is sent to you.
                </p>
              </li>
              <li>
                <p>You can advertise your property for 30 days or 90 days.</p>
              </li>
              <li>
                <p>
                  You may revise your item at any time in the advertisement
                  duration.
                </p>
              </li>
            </ul>
            <p>
              Real Estate Ad listings do result in feedback, any purpose other
              than communicating with the potential buyers about the information
              and services referenced in the item description, unless expressly
              permitted to do so. Failure to comply with this policy can result
              in seller expulsion from the Tradesbarn community. Once buyers and
              sellers make contact through these listings, they can, as noted
              above, then proceed to discuss off the website purchase terms,
              including price, inspections, and the like, as is customary in an
              offline real estate transaction.
            </p>
            <br />

            <h6>Additional information</h6>

            <br />

            <p>
              <u>
                <b>Privacy of Information</b>
              </u>
            </p>

            <p>
              Sellers must treat buyers' contact information in full compliance
              with our User Privacy Notice. This policy prohibits sharing the
              buyer's information with third parties as well as use of the
              buyer's information for any purpose other than communicating with
              the buyer about the item, unless expressly permitted to do so by
              the buyer.
            </p>
            <br />
            <p>
              <u>
                <b>Real Estate Licensees</b>
              </u>
            </p>

            <p>
              Sellers who are licensed as an agent, broker, or other licensed
              real estate professional must ensure that their item description
              conforms to all laws and regulations applicable to online
              advertising of properties by such licensees.
            </p>
            <p>
              These requirements may include, among other things, disclosure of
              the licensee's name, any firm with which the licensee is
              affiliated, office location, and the jurisdiction(s) where the
              licensee is licensed.
            </p>
            <br />
            <p>
              <u>
                <b>Trading Assistants</b>
              </u>
            </p>

            <p>
              A user may not list real estate for a third-party unless the user
              is a licensed real estate professional.
            </p>
            <br />
            <p>
              <u>
                <b>Professional Assistance</b>
              </u>
            </p>

            <p>
              The purchase and sale of real estate is a complicated matter that
              is governed by laws, practices and customs that vary significantly
              from locality to locality. Sellers are advised that many
              jurisdictions require that you provide a disclosure statement to
              all prospective buyers regarding the condition of your property.
              In addition, under U.S. federal law, sellers have an obligation to
              disclose information on lead-based paint hazards. Members are
              strongly advised to seek the help of a licensed real estate
              professional and/or a real estate attorney to help in the
              negotiation and sale of any real estate.
            </p>
            <p>
              Members may wish to visit the website of the Association of Real
              Estate License Law Officials, which offers a search tool providing
              access to a database of information about the current license or
              registration status of real estate licensees.
            </p>
            <br />
            <p>
              <u>
                <b>
                  Tradesbarn Real Estate's Role as a Venue Provider / Brokerage
                  Relationship (Agency) Disclosure
                </b>
              </u>
            </p>

            <p>
              The role of Tradesbarn Real Estate in the sale of properties is
              limited to hosting advertisements provided by sellers and
              providing a venue for communications about a property. Tradesbarn
              Real Estate is not responsible for, and does not engage in,
              brokering, selling, purchasing, exchanging, or leasing posted
              properties or providing a "multiple listing service" as that term
              is traditionally used. Tradesbarn Real Estate does not counsel
              sellers or buyers, show properties, negotiate sales contracts, or
              hold a position of trust and confidence, whether or not in
              connection with the Tradesbarn website. Accordingly, no brokerage
              relationship or any agency or fiduciary relationship is intended
              to be or shall be deemed to have been created between Tradesbarn
              Real Estate and any seller or buyer of real estate. Tradesbarn
              Real Estate makes no representations about properties advertised
              on Tradesbarn, about the sellers of such properties, about the
              accuracy of information provided by such sellers, or about the
              financial capabilities of
            </p>
            <p>
              prospective buyers. Prospective buyers are advised to perform all
              due diligence before entering into a contract to purchase
              property, and, as emphasized above, all parties are encouraged to
              seek the help of a licensed real estate professional and real
              estate attorney.
            </p>
            <br />
            <p>
              <u>
                <b>License Information</b>
              </u>
            </p>

            <p>
              Tradesbarn Real Estate does not purport to broker transactions.
            </p>
            <br />
            <p>
              <u>
                <b>Relationship to Tradesbarn's User Agreement</b>
              </u>
            </p>
            <p>
              With respect to real estate items, if these Real Estate Rules
              conflict with Tradesbarn's User Agreement, these rules apply.
            </p>
            <br />
            <p>
              <u>
                <b>Why does Tradesbarn have this policy?</b>
              </u>
            </p>

            <p>
              Tradesbarn and Tradesbarn Real Estate want to ensure that sellers
              and buyers comply with all governmental laws and regulations.
              Since the sale of real estate is regulated, or may cause harm to
              Tradesbarn, Tradesbarn Real Estate or its members, the sale of
              real estate on Tradesbarn is restricted.
            </p>
          </div>
        </Widget>
      </div>
    );
  }
}

export default RealEstatePolicy;
