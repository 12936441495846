// //seller-dashboard views
import DashboardPage from "../modules/Dashboard/Dashboard";

//buyer-dashboard views
import AllProducts from "./AllProducts/AllProducts";
import Home from "./Home/Home";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";
import ProfilePage from "modules/ProfilePage/ProfilePage";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import ForgetPassword from "./Auth/ForgetPassword/ForgetPassword";
import VerifyResetPassword from "./Auth/VerifyResetPassword/VerifyResetPassword";
import PolicyPage from "modules/PoliciesPage/PoliciesPage";
import SingleProduct from "modules/SingleProduct/SingleProduct";
import Wishlist from "modules/WishlistPage/WishlistPage";
import ChatPage from "modules/ChatPage/ChatPage";
import AccountPage from "modules/AccountPage/AccountPage";
import SellerStore from "./SellerStore/SellerStore";
import DeleteOrderPage from "modules/DeleteOrderPage/DeleteOrderPage";
import NatificationPage from "../modules/NatificationPage/NotificationPage";
import OrderTrackPage from "./ProfilePage/OrderTrack/OrderTrack";
import MyOrders from "./MyOrders/MyOrders";
import Mybarn from "./ProfilePage/Mybarn/Mybarn";
import ShippingAddress from "./ProfilePage/ShippingAddress/ShippingAddress";
import ErrorPage from "../modules/UtilitiesPage/UtilitiesPage";
import ResolutionCenter from "./ResolutionCenter/ResolutionCenterHome/ResolutionCenterHome";
import ReportItemPage from "./ResolutionCenter/ReportItem/ReportItemPage";
import ReportItemConfirmPage from "./ResolutionCenter/ReportItemConfirm/ReportItemConfirm";
import SellerHelpPage from "../modules/SellerHelpPage/SellerHelpPage";
import BuyerHelpPage from "../modules/BuyerHelpPage/BuyerHelpPage";
import SellerPage from "../modules/SellerPage/SellerPage";
import SellerRegisterPage from "../modules/SellerRegisterPage/SellerRegisterPage";
import SingleCase from "../modules/ResolutionCenter/SingleCase/SingleCase";
import UserAgreement from "../modules/UserAgreement/UserAgreement";
import CheckoutSuccess from "./ResultsPage/CheckoutSuccess";
import CheckoutFail from "./ResultsPage/CheckoutFail";
import PageNotFound from "./ResultsPage/PageNotFound";
import MobileApp from "./StaticPages/MobileApp";
import HelpPage from "./StaticPages/HelpPage";
import TermAndConditions from "./StaticPages/TermAndConditions";
import CardsAddPage from "./CardsAddPage/CardsAddPage";
import CompanyInforAndPolicy from "./CustomerService/CompanyInforAndPolicy";
import AcceptedPaymentsPolicy from "./CustomerService/AcceptedPaymentsPolicy";
import IntellectualPropertyRules from "./CustomerService/IntellectualPropertyRules";
import RealEstatePolicy from "./CustomerService/RealEstatePolicy";
import TaxPolicy from "./CustomerService/TaxPolicy";
import IdentityScreeningPolicy from "./CustomerService/IdentityScreeningPolicy";
import FraudPolicy from "./CustomerService/FraudPolicy";
import FinalValueFees from "./CustomerService/FinalValueFees";
import OnlineAdvertisingPolicy from "./CustomerService/OnlineAdvertisingPolicy";
import PrivacyPolicy from "./CustomerService/PrivacyPolicy";
import ChangePassword from "../components/Buyer/BuyerProfile/ChangePassword/ChangePassword";
import BuyerRegistration from "./SupportCenter/BuyerRegistration";
import SellerRegistration from "./SupportCenter/SellerRegistration";
import ProductListing from "./SupportCenter/ProductListing";
import ResolutionCenterHelp from "./SupportCenter/ResolutionCenterHelp";
import FAQ from "./FAQ/FAQ";
export default {
  //seller-dashboard
  DashboardView: DashboardPage,

  //seller
  SellerView: SellerPage,
  SellerRegisterView: SellerRegisterPage,
  UserAgreementView: UserAgreement,

  //buyer
  AllProductsView: AllProducts,
  HomeView: Home,
  CartView: Cart,
  CheckoutView: Checkout,
  CheckoutSuccessView: CheckoutSuccess,
  CheckoutFailView: CheckoutFail,
  ProfileView: ProfilePage,
  LoginView: Login,
  RegisterView: Register,
  ForgetPasswordView: ForgetPassword,
  VerifyResetPasswordView: VerifyResetPassword,
  PolicyView: PolicyPage,
  SellerStoreView: SellerStore,
  SingleProductView: SingleProduct,
  WishlistView: Wishlist,
  ChatView: ChatPage,
  AccountView: AccountPage,
  ChangePassword: ChangePassword,
  OrderView: MyOrders,
  MybarnView: Mybarn,
  DeleteOrderView: DeleteOrderPage,
  ShippingAddressView: ShippingAddress,
  CardAddView: CardsAddPage,
  NatificationView: NatificationPage,
  OrderTrackView: OrderTrackPage,
  ErrorView: ErrorPage,
  ResolutionCenterView: ResolutionCenter,
  SingleCaseView: SingleCase,
  ReportItemView: ReportItemPage,
  ReportItemConfirmView: ReportItemConfirmPage,
  SellerHelpView: SellerHelpPage,
  BuyerHelpView: BuyerHelpPage,
  PageNotFoundView: PageNotFound,
  MobileAppView: MobileApp,
  HelpPageView: HelpPage,
  TermAndConditionsView: TermAndConditions,
  CompanyInforAndPolicyView: CompanyInforAndPolicy,
  AcceptedPaymentsPolicyView: AcceptedPaymentsPolicy,
  IntellectualPropertyRulesView: IntellectualPropertyRules,
  RealEstatePolicyView: RealEstatePolicy,
  TaxPolicyView: TaxPolicy,
  IdentityScreeningPolicyView: IdentityScreeningPolicy,
  FraudPolicyView: FraudPolicy,
  FinalValueFeesView: FinalValueFees,
  OnlineAdvertisingPolicyView: OnlineAdvertisingPolicy,
  PrivacyPolicyView: PrivacyPolicy,

  // Suport-center
  BuyerRegistrationView: BuyerRegistration,
  SellerRegistrationView: SellerRegistration,
  ProductListingView: ProductListing,
  ResolutionCenterHelpView: ResolutionCenterHelp,
  FAQView: FAQ,
};
