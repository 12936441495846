import types from "./types";
import { handleActions } from "redux-actions";
import { initialStateModal } from "../../../../constants/Utilities";

const initialState = {
  ordersCount: {
    ...initialStateModal,
  },
  shippingAddressList: {
    ...initialStateModal,
  },
  exportOrders: {
    ...initialStateModal,
  },
};

// Reducers from redux-actions
export default handleActions(
  {
    [types.GET_ORDERS_COUNT]: (state, { payload }) => ({
      ...state,
      ordersCount: {
        ...state.ordersCount,
        loading: true,
        pending: true,
      },
    }),
    [types.GET_ORDERS_COUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      ordersCount: {
        ...state.ordersCount,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.GET_ORDERS_COUNT_FAIL]: (state, { payload }) => ({
      ...state,
      ordersCount: {
        ...state.ordersCount,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    //*************************************************************//
    [types.BUY_SHIPPING_LABEL]: (state, { payload }) => ({
      ...state,
      shippingAddressList: {
        ...state.shippingAddressList,
        loading: true,
        pending: true,
      },
    }),
    [types.BUY_SHIPPING_LABEL_SUCCESS]: (state, { payload }) => ({
      ...state,
      shippingAddressList: {
        ...state.shippingAddressList,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.BUY_SHIPPING_LABEL_FAIL]: (state, { payload }) => ({
      ...state,
      shippingAddressList: {
        ...state.shippingAddressList,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    // ************************************************************//
    [types.EXPORT_ORDERS]: (state, { payload }) => ({
      ...state,
      exportOrders: {
        ...state.exportOrders,
        loading: true,
        pending: true,
      },
    }),
    [types.EXPORT_ORDERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      exportOrders: {
        ...state.exportOrders,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.EXPORT_ORDERS_FAIL]: (state, { payload }) => ({
      ...state,
      exportOrders: {
        ...state.exportOrders,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
    [types.ORDER_TRACKING]: (state, { payload }) => ({
      ...state,
      orderTracking: {
        ...state.orderTracking,
        loading: true,
        pending: true,
      },
    }),
    [types.ORDER_TRACKING_SUCCESS]: (state, { payload }) => ({
      ...state,
      orderTracking: {
        ...state.orderTracking,
        loading: false,
        pending: false,
        data: payload,
      },
    }),

    [types.ORDER_TRACKING_FAIL]: (state, { payload }) => ({
      ...state,
      orderTracking: {
        ...state.orderTracking,
        loading: false,
        pending: false,
        hasError: true,
        error: { payload },
      },
    }),
  },
  initialState
);
