import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Input, Card, Select, Row, Col, Form } from "antd";
import {
  FormItemLayout,
  ItemFormItemLayout,
} from "../../../../constants/FormUtility";
import { makeField } from "../../../../components/Common/Controls/MakeField";
import { allCountriesJson } from "../../../../DummyData/AllCountry";
import { internationalShippingTypes } from "../../../../constants/Utilities";
import ExtraInfo from "./ExtraInfo";
import { extraInfoDetails } from "./ExtraInfoDetails";
import {
  DEFAULT_CURRENCY,
  IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST,
} from "../../../../constants";

const Option = Select.Option;
const { TextArea } = Input;
const FormItem = Form.Item;

const AInputField = makeField(Input);
const ASelectField = makeField(Select);
const ATextAreaField = makeField(TextArea);

class InternationalShipping extends Component {
  render() {
    const {
      shippingMethodList,
      internationalShippingTrackingAvailableHandler,
      fieldValues,
    } = this.props;
    let isFlatRated =
      fieldValues && fieldValues.internationalShippingMethod === "flat_rated"
        ? true
        : false;
    let isNoShipping =
      fieldValues && fieldValues.internationalShippingMethod === "no_shipping"
        ? true
        : false;
    let isFreeShipping =
      fieldValues && fieldValues.internationalShippingMethod === "free_shipping"
        ? true
        : false;
    let isCalculated =
      fieldValues && fieldValues.internationalShippingMethod === "calculated"
        ? true
        : false;

    let isAdditionalShippingCost =
      IS_ADD_PRODUCT_ADDITIONAL_SHIPPING_COST && isFlatRated;

    return (
      <div className="mb-3">
        <div style={{ display: "flex", alignItems: "center" }} className="mb-3">
          <h6 className="mb-0 mr-2">International Shipping</h6>
          <ExtraInfo
            placement={"top"}
            title={extraInfoDetails.internationalShipping}
          />
        </div>
        <Card style={{ backgroundColor: "#fafafa" }} className="jr-fld-catch ">
          <FormItem
            {...FormItemLayout}
            label="International Shipping Method"
            required
            id="internationalShippingMethod"
          >
            <Field
              formItemLayout={FormItemLayout}
              name={"internationalShippingMethod"}
              component={ASelectField}
              style={{ width: "100%" }}
              placeholder="Select"
              hasFeedback
            >
              {internationalShippingTypes.map(
                (internationalShippingType, i) => {
                  return (
                    <Option key={i} value={internationalShippingType.value}>
                      {internationalShippingType.label}
                    </Option>
                  );
                }
              )}
            </Field>
            <span
              style={{ position: "absolute", right: "10px", bottom: "-37px" }}
            >
              <ExtraInfo title={extraInfoDetails.internationShippingMethod} />
            </span>
          </FormItem>

          {(isFreeShipping || isFlatRated) && (
            <React.Fragment>
              <h6 style={{ padding: "0 15px" }}>Estimate Shipping Duration</h6>
              <Row>
                <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                  <div className="jr-fld-catch">
                    <FormItem
                      {...FormItemLayout}
                      label="Min"
                      required={!isNoShipping}
                    >
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="internationalShippingEstimatedDeliveryDaysMin"
                        component={AInputField}
                        type="text"
                        normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                        placeholder="Minimum Days"
                        style={{ width: "100%" }}
                        hasFeedback
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "-37px",
                        }}
                      >
                        <ExtraInfo
                          title={
                            extraInfoDetails.internationalShippingEstimatedDeliveryDaysMin
                          }
                        />
                      </span>
                    </FormItem>
                  </div>
                </Col>
                <Col sm={24} xs={24} md={24} lg={12} xl={12}>
                  <div className="jr-fld-catch">
                    <FormItem
                      {...FormItemLayout}
                      label="Max"
                      required={!isNoShipping}
                    >
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="internationalShippingEstimatedDeliveryDaysMax"
                        component={AInputField}
                        type="text"
                        normalize={(val) => (val || "").replace(/[^\d]/g, "")}
                        placeholder="Maximum Days"
                        style={{ width: "100%" }}
                        hasFeedback
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "-37px",
                        }}
                      >
                        <ExtraInfo
                          title={
                            extraInfoDetails.internationalShippingEstimatedDeliveryDaysMax
                          }
                        />
                      </span>
                    </FormItem>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                  <div className="jr-fld-catch">
                    <FormItem {...FormItemLayout} label="Estimated Note">
                      <Field
                        formItemLayout={ItemFormItemLayout}
                        name="internationalShippingEstimatedDeliveryNote"
                        component={ATextAreaField}
                        placeholder="International Estimated note"
                        rows={4}
                        hasFeedback
                        // suffix={<ExtraInfo title={extraInfoDetails.internationalShippingEstimatedDeliveryNote} />}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "-37px",
                        }}
                      >
                        <ExtraInfo title={extraInfoDetails.estimatedNote} />
                      </span>
                    </FormItem>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}

          {isFlatRated && (
            <FormItem
              {...FormItemLayout}
              label="International Shipping Price"
              required={isFlatRated}
            >
              <Field
                formItemLayout={FormItemLayout}
                name="internationalShippingPrice"
                component={AInputField}
                placeholder="international Shipping Price"
                hasFeedback
                disabled={!isFlatRated}
                type="number"
                addonBefore={DEFAULT_CURRENCY}
                onWheel={(e) => e.target.blur()}
                min="0"
              />
              <span
                style={{ position: "absolute", right: "10px", bottom: "-37px" }}
              >
                <ExtraInfo
                  title={extraInfoDetails.internationalShippingPrice}
                />
              </span>
            </FormItem>
          )}
          {isAdditionalShippingCost && (
            <FormItem
              {...FormItemLayout}
              label="International Shipping Additional Price"
              required={isAdditionalShippingCost}
            >
              <Field
                formItemLayout={FormItemLayout}
                name="internationalShippingAdditionalPrice"
                component={AInputField}
                placeholder="international Shipping Price"
                hasFeedback
                disabled={!isFlatRated}
                type="number"
                addonBefore={DEFAULT_CURRENCY}
                onWheel={(e) => e.target.blur()}
                min="0"
              />
              <span
                style={{ position: "absolute", right: "10px", bottom: "-37px" }}
              >
                <ExtraInfo
                  title={extraInfoDetails.internationalShippingAdditionalPrice}
                />
              </span>
            </FormItem>
          )}

          {isCalculated && (
            <FormItem
              {...FormItemLayout}
              label="International Shipping Carriers"
              required={isCalculated}
              id="internationalShippingCarriers"
            >
              <Field
                mode="multiple"
                formItemLayout={FormItemLayout}
                name={"internationalShippingCarriers"}
                component={ASelectField}
                style={{ width: "100%" }}
                placeholder="Select"
                hasFeedback
                filterOption={(input, method) =>
                  method.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={!isCalculated}
              >
                {shippingMethodList.data.data != null
                  ? shippingMethodList.data.data.map((method, i) => {
                      return (
                        <Option key={i} value={method.value}>
                          {method.label}
                        </Option>
                      );
                    })
                  : null}
              </Field>
              <span
                style={{ position: "absolute", right: "10px", bottom: "-37px" }}
              >
                <ExtraInfo
                  title={extraInfoDetails.internationalShippingCarriers}
                />
              </span>
            </FormItem>
          )}

          {(isFlatRated || isFreeShipping) && (
            <FormItem
              {...FormItemLayout}
              label="International Shipping Tracking"
              required={isCalculated}
              id="internationalShippingTrackingAvailable"
            >
              <Field
                formItemLayout={FormItemLayout}
                name="internationalShippingTrackingAvailable"
                component={ASelectField}
                style={{ width: "100%" }}
                onChange={internationalShippingTrackingAvailableHandler}
                placeholder="Select"
                hasFeedback
                disabled={!isFlatRated && !isFreeShipping}
              >
                <Option value="enable">Enable</Option>
                <Option value="disable">Disable</Option>
              </Field>
              <span
                style={{ position: "absolute", right: "10px", bottom: "-35px" }}
              >
                <ExtraInfo
                  title={extraInfoDetails.internationalShippingTracking}
                />
              </span>
            </FormItem>
          )}

          {!isNoShipping && (
            <FormItem
              {...FormItemLayout}
              label="Exclude shipping locations"
              id="excludeShippingLocations"
            >
              <Field
                mode="multiple"
                formItemLayout={FormItemLayout}
                name={"excludeShippingLocations"}
                component={ASelectField}
                style={{ width: "100%" }}
                placeholder="Select"
                hasFeedback
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={isNoShipping}
              >
                {allCountriesJson.map((country, i) => {
                  return (
                    <Option key={i} value={country.alpha_3}>
                      {country.name}
                    </Option>
                  );
                })}
              </Field>
              <span
                style={{ position: "absolute", right: "10px", bottom: "-35px" }}
              >
                <ExtraInfo title={extraInfoDetails.excludeShippingLocation} />
              </span>
            </FormItem>
          )}
        </Card>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
InternationalShipping = reduxForm({
  form: "AddPoduct_form", // a unique identifier for this form
  enableReinitialize: true,
})(InternationalShipping);

// You have to connect() to any reducers that you wish to connect to yourself
InternationalShipping = connect(
  (state) => ({
    // initialValues: state.account.data // pull initial values from account reducer
    initialValues: state.ProductManagement.variationTableDto,
    // pull initial values from account reducer
  })
  // { load: loadAccount } // bind account loading action creator
)(InternationalShipping);

export default InternationalShipping;
