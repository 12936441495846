import React, { Component } from "react";
import TransactionContainer from "../../../../components/Seller/TransactionOverview/TransactionContainer/TransactionContainer";
import { financeActions } from "../ducks"
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class TransactionOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      algolia: null,
      loading: true,
      firstDate: null,
      secondDate: null,
    };
  }
  componentDidMount() {
    // getter
    const user = localStorage.getItem("user");
    const userObj = JSON.parse(user);
    const algolia = userObj.algolia;
    this.setState({
      algolia,
    });
  }

  onChange = (date, dateString) => {
    this.setState({
      loading: false,
      firstDate: dateString[0],
      secondDate: dateString[1],
    });
  }

  render() {
    const { algolia } = this.state;

    if (algolia) {
      return (
        <div>
          <h3 className="mb-4">Transaction Overview</h3>
          <TransactionContainer
            apiKey={algolia.transactions.api_key}
            index={algolia.transactions.index}
          />
        </div>
      );
    } else {
      return (
        <div className="gx-card p-5">
          <div>Loading...</div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    exportTransactions: state.FinanceManagement.exportTransactions,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    financeActions: bindActionCreators(financeActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionOverview)
);
